<template>
    <node-view-wrapper class="draggable-item">
        <div class="drag-handle" contenteditable="false" draggable="true" data-drag-handle></div>
        <node-view-content />
    </node-view-wrapper>
</template>
<script>
    import { NodeViewContent, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2"

    export default {
        props: nodeViewProps,

        components: {
            NodeViewWrapper,
            NodeViewContent
        }
    }
</script>

<style lang="scss">
    .drag-handle {
        display: none;
        flex: 0 0 auto;
        position: absolute;
        width: 1rem;
        height: 1rem;
        top: 0.25rem;
        left: -2rem;
        z-index: 9999;
        margin-right: 0.5rem;
        cursor: grab;
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .draggable-item {
        display: flex;
        position: relative;
        &:hover > .drag-handle {
            display: flex;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -105px;
            width: 100px;
            background-color: rgba(253, 99, 99, 0);
            z-index: 0;
        }
        // padding: 0.5rem;
        // margin: 0.5rem 0;
        // border-radius: 0.5rem;
        // background: white;
        // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
    }
</style>
