<template>
    <div id="contentcontainter" class="standard-mode">
        <v-container id="allresearches" fluid tag="section">
            <v-row>
                <v-col cols="12" md="3" xl="6" v-for="competitor in competitors" :key="competitor.searches">
                    <v-card class="px-5 py-3" id="kwcountCard">
                        <v-row>
                            <v-sheet color="#42defa" max-height="50" class="pa-12" dark top id="searchsheet" rounded elevation="6">
                                <div class="headingsheet2">
                                    <!-- <v-icon id="sheeticon" large>mdi-numeric-1</v-icon> -->
                                    <v-icon id="sheeticon" large>mdi-magnify</v-icon>

                                    <!-- <v-img
                    src="https://cdn.vuetifyjs.com/images/cards/mountain.jpg"
                    height="50"
                    width="50"
                  ></v-img> -->
                                </div>
                            </v-sheet>
                            <div class="ml-auto mr-1 text-right">
                                <v-chip class="mb-2">
                                    <v-avatar left>
                                        <img :src="getFavicon(competitor.url)" class="tablelogos" />
                                    </v-avatar>
                                    {{ competitor.url }}
                                </v-chip>
                                <div class="body-3 grey--text font-weight-light">Google Searches</div>
                                <div>{{ competitor.searches }}</div>
                            </div>
                        </v-row>

                        <v-col cols="12" class="px-0" id="divider">
                            <v-divider />
                        </v-col>

                        <v-icon size="16" class="ml-2 mr-1"> mdi-clock </v-icon>

                        <span class="caption grey--text font-weight-light"> Updated on {{ updatedDate }}</span>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card class="px-5 py-3 rounded-card">
                        <v-row>
                            <v-sheet color="#42defa" max-height="200" class="pa-12" dark top id="tablesheet" rounded elevation="6">
                                <div class="headingsheet2">
                                    <v-icon id="sheeticon" large>mdi-file-table</v-icon>
                                </div>
                            </v-sheet>
                            <v-card-title>
                                <span id="heading3">Brand Competition Analysis</span>
                                <v-spacer></v-spacer>
                            </v-card-title>
                        </v-row>
                        <v-btn absolute dark fab color="#42defa" top small right @click="clearChart">
                            <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                        <v-card-text>
                            <line-chart :dataChart="dataChart" :countGraph="countGraph" :countGraph2="countGraph2" :options="graphOptions"></line-chart>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import LineChart from "../components/charts/LineChart"
    export default {
        components: {
            LineChart
        },
        data: () => ({
            graphOptions: {
                responsive: true,
                maintainAspectRatio: false,
                annotation: {
                    annotations: [
                        {
                            type: "line",
                            mode: "vertical",
                            scaleID: "x-axis-0",
                            value: "",
                            borderColor: "grey",
                            borderWidth: 1.5,
                            label: {
                                content: String.fromCharCode(parseInt("21E0", 16)) + " 2019 | 2018 " + String.fromCharCode(parseInt("21E2", 16)),
                                enabled: true,
                                position: "top"
                            }
                        }
                    ]
                }
            },
            dataChart: {
                selected: [],
                labels: [],
                datasets: [
                    {
                        label: "Sum of all keywords",
                        backgroundColor: "#EB5E28",
                        borderColor: "grey",
                        pointBackgroundColor: "white",
                        borderWidth: 2,
                        pointBorderColor: "grey",
                        data: [],
                        hidden: false
                    }
                ]
            },
            countGraph: 0,
            countGraph2: 0,
            updatedDate: "",
            competitors: [
                {
                    url: "www.planet-sports.de",
                    searches: "350.000"
                },
                {
                    url: "www.titus.de",
                    searches: "310.000"
                },
                {
                    url: "www.planet-sports.de",
                    searches: "240.000"
                },
                {
                    url: "www.titus.de",
                    searches: "110.000"
                }
            ]
        }),
        methods: {
            resolve_img_url(url) {
                //console.log(url)
                return url
            },
            getFavicon(competitor) {
                return "https://i.olsh.me/icon?url=" + competitor + "&size=30"
            }
        }
    }
</script>

<style scoped>
    .headingsheet {
        margin-top: -15px;
    }
    .headingsheet2 {
        margin-top: -15px;
    }
    #searchsheet {
        margin-top: -30px;
        width: 30px;
        height: 30px;
    }
    #tablesheet {
        margin-top: -30px;
        width: 60px;
        height: 60px;
    }
    #tablesheet2 {
        margin-top: -50px;
        height: 60px;
    }
    .rounded-card {
        margin-top: 50px;
    }
    #sheeticon {
        margin-left: -25px;
        margin-top: -25px;
        margin: auto !important;
        padding-bottom: 50% !important;
        width: 50% !important;
    }
    .inline {
        display: inline !important;
    }
    .shortcol {
        max-width: 30% !important;
    }
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }
    .v-card__text {
        padding: 0px !important;
    }
    .v-card__title {
        padding-top: 0px;
    }
    #kwcountCard {
        padding-bottom: 10px !important;
    }

    .v-application .pa-12 {
        padding: 50px !important;
    }
    .v-application .px-5 {
        padding-right: 20px !important;
        /* padding-left: 20px !important; */
    }
    #divider {
        padding-left: -5px;
    }
    #heading3 {
        font-weight: 300 !important;
        color: rgb(105, 105, 105);
    }
</style>
