<template>
    <div id="contentcontainter" class="standard-mode">
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="info.show">
            <v-icon>{{ info.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <!-- SHARE ITEMS -->
        <v-slide-x-transition>
            <div id="briefing" v-if="project && !loading">
                <div class="mb-7 mt-5">
                    <component-header
                        :active="{ url: 'content-projects', name: 'Content Project', destination: 'briefing' }"
                        :title="project.title"
                        :id="project._id"
                        :device="project.device"
                        :project="project"
                        :language_code="project.langloc.language_code"
                        :share_data="share_data"
                        :shared_extern="shared_extern"
                        :shared_company_id="sharedCompanyId"></component-header>
                </div>

                <v-row>
                    <v-col>
                        <v-card class="quaroshadow rounded_card">
                            <v-slide-x-reverse-transition>
                                <div class="backsheet"></div>
                            </v-slide-x-reverse-transition>
                            <v-card-text>
                                <v-row class="">
                                    <!-- <v-divider inset vertical> </v-divider> -->
                                    <v-col sm="4" md="2">
                                        <div class="selectionCol">
                                            <div class="briefing_card_label">
                                                Number of Words
                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto pb-1" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>The "Number of Words" field specifies the targeted word count for the content of the project.</span>
                                                </v-tooltip>
                                            </div>
                                            <div class="briefing_card_text mt-2">
                                                <v-chip>{{ $helpers.formatNumber(project.settings.word_count) }}</v-chip>
                                            </div>
                                        </div>
                                    </v-col>
                                    <!-- <v-divider inset vertical> </v-divider> -->
                                    <v-col sm="4" md="2">
                                        <div class="selectionCol">
                                            <div class="briefing_card_label">
                                                Number of Images
                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto pb-1" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>The "Number of Images" field specifies the desired number of images to be included in the content of the project.</span>
                                                </v-tooltip>
                                            </div>
                                            <div class="briefing_card_text mt-2">
                                                <v-chip>{{ project.settings.img_count }}</v-chip>
                                            </div>
                                        </div>
                                    </v-col>
                                    <!-- <v-divider inset vertical> </v-divider> -->
                                    <v-col sm="4" md="2">
                                        <div class="selectionCol">
                                            <div class="briefing_card_label">
                                                Readability
                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto pb-1" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>The "Readability" field specifies the desired level of readability for the content of the project.</span>
                                                </v-tooltip>
                                            </div>
                                            <div class="briefing_card_text mt-2">
                                                <v-chip>{{ project.settings.readability.text }}</v-chip>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="12" md="6" xl="6">
                                        <div class="selectionCol">
                                            <div class="briefing_card_label">
                                                TOPIC KEYWORDS
                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto pb-1" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>
                                                        The "Topics" field specifies the selected focus topics or keywords for the content of the project. These topics or keywords help to guide the
                                                        writer and ensure that the content is relevant to the desired subject matter.
                                                    </span>
                                                </v-tooltip>
                                            </div>
                                            <div class="cs_topics" v-if="selectedTopics.length">
                                                <v-chip-group show-arrows>
                                                    <div v-if="selectedTopics[0].keywords">
                                                        <v-chip class="white--text mr-2 mb-1" v-for="(topic, index) in selectedTopics[0].keywords" :key="index" color="primary">{{
                                                            topic.keyword
                                                        }}</v-chip>
                                                    </div>
                                                    <div v-else>
                                                        <v-chip class="white--text mr-2 mb-1" v-for="(topic, index) in selectedTopics" :key="index" color="primary">{{ topic.keyword }}</v-chip>
                                                    </div>
                                                    <!-- <v-chip class="white--text mr-2 mb-1" v-for="(topic, index) in selectedTopics" :key="index" :color="colors[index]">{{ topic.keyword }}</v-chip> -->
                                                </v-chip-group>
                                                <!--  -->
                                            </div>
                                            <div class="cs_topics" v-else>{{ selectedTopics.length }} selected</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <BriefingDescription
                            :shared_extern="shared_extern"
                            :project="project"
                            :user_id="shared_extern ? sharedId : computed_user_id"
                            :company_id="shared_extern ? sharedCompanyId : computed_company_id"></BriefingDescription>
                    </v-col>
                </v-row>
                <v-slide-y-transition>
                    <div v-if="project" class="mt-5">
                        <v-card class="rounded_card my-5 quaroshadow" style="border-top: 4px solid var(--v-primary-base); overflow: hidden">
                            <!-- <div class="backsheet"></div> -->
                            <v-toolbar id="projects-tool-bar" flat :color="$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
                                <v-toolbar-items>
                                    <v-btn
                                        v-for="btn in toolbarButtons"
                                        :key="btn.name"
                                        :class="{ 'active-button': activeButton === btn.name }"
                                        @click="showComponent(btn.name)"
                                        text
                                        :color="activeButton === btn.name ? 'primary' : 'grey darken-2'">
                                        {{ btn.label }}
                                    </v-btn>
                                </v-toolbar-items>
                                <div class="flex-grow-1"></div>

                                <!-- <v-text-field
                            v-if="$vuetify.breakpoint.smAndUp"
                            class="pr-5"
                            rounded
                            dense
                            v-model="search"
                            style="max-width: 400px"
                            filled
                            clearable
                            clear-icon="mdi-close"
                            prepend-inner-icon="mdi-magnify"
                            label="Search..."
                            single-line
                            hide-details></v-text-field> -->

                                <!-- <v-btn rounded @click="initExport" class="" color="primary" filled>
                            <v-icon>mdi-cloud-download</v-icon>
                        </v-btn> -->
                            </v-toolbar>

                            <v-slide-x-transition hide-on-leave>
                                <div v-if="selectedComponent == 'TermsTable'">
                                    <BriefingKeywords
                                        :project="project"
                                        :keywords="keywords"
                                        :shared_extern="shared_extern"
                                        @fetchKeywords="fetchKeywords"
                                        @removeKeyword="removeKeyword"></BriefingKeywords>
                                </div>
                            </v-slide-x-transition>
                            <v-slide-x-transition hide-on-leave>
                                <div v-if="selectedComponent == 'QuestionsTable'">
                                    <BriefingQuestions
                                        :project="project"
                                        :questions="questions"
                                        :shared_extern="shared_extern"
                                        @fetchQuestions="fetchProject"
                                        @removeQuestion="removeQuestion"></BriefingQuestions>
                                </div>
                            </v-slide-x-transition>
                            <v-slide-x-transition hide-on-leave>
                                <div v-if="selectedComponent == 'HeadlinesTable'">
                                    <div style="max-height: 550px; overflow: auto"><BriefingOutline :project="project" :shared_extern="shared_extern"></BriefingOutline></div>
                                </div>
                            </v-slide-x-transition>
                            <v-slide-x-transition hide-on-leave>
                                <div v-if="selectedComponent == 'InspiratonTable'">
                                    <BriefingInspiration :competitors="selectedCompetitors"></BriefingInspiration>
                                </div>
                            </v-slide-x-transition>
                        </v-card>
                    </div>
                </v-slide-y-transition>
                <!-- 
                <v-row>
                    <v-col cols="12">
                        <BriefingDescription
                            :shared_extern="shared_extern"
                            :project="project"
                            :user_id="shared_extern ? sharedId : computed_user_id"
                            :company_id="shared_extern ? sharedCompanyId : computed_company_id"></BriefingDescription>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <BriefingKeywords :project="project" :keywords="keywords" :shared_extern="shared_extern" @fetchKeywords="fetchKeywords" @removeKeyword="removeKeyword"></BriefingKeywords>
                    </v-col>
                    <v-col cols="8">
                        <BriefingQuestions :project="project" :questions="questions" :shared_extern="shared_extern" @fetchQuestions="fetchProject" @removeQuestion="removeQuestion"></BriefingQuestions>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <BriefingOutline :project="project" :shared_extern="shared_extern"></BriefingOutline>
                    </v-col>
                    <v-col cols="6">
                        <BriefingInspiration :competitors="selectedCompetitors"></BriefingInspiration>
                    </v-col>
                </v-row> -->
            </div>
        </v-slide-x-transition>
        <div v-if="!project || loading">
            <div style="display: flex">
                <span class="circularprogress mx-auto">
                    <img id="loader" src="../../assets/quaro-q.svg" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import BriefingKeywords from "../../components/content-suite/briefing/BriefingKeywords.vue"
    import BriefingQuestions from "../../components/content-suite/briefing/BriefingQuestions.vue"
    import BriefingDescription from "../../components/content-suite/briefing/BriefingDescription.vue"
    import BriefingTermPlacements from "../../components/content-suite/briefing/BriefingTermPlacements.vue"
    import BriefingInspiration from "../../components/content-suite/briefing/BriefingInspiration.vue"
    import BriefingOutline from "../../components/content-suite/briefing/BriefingOutline.vue"
    import ComponentHeader from "../../components/content-suite/ComponentHeader.vue"

    import { jsPDF } from "jspdf"
    export default {
        components: {
            BriefingKeywords,
            BriefingQuestions,
            BriefingDescription,
            BriefingTermPlacements,
            BriefingInspiration,
            BriefingOutline,
            ComponentHeader
        },
        data() {
            return {
                loading: true,
                selectedComponent: "TermsTable",
                activeButton: "TermsTable",
                toolbarButtons: [
                    { name: "TermsTable", label: "Terms" },
                    { name: "QuestionsTable", label: "Questions" },
                    { name: "HeadlinesTable", label: "Headlines" },
                    { name: "InspiratonTable", label: "Inspiration" }
                ],
                colors: ["cyan darken-3", "purple darken-3", "lime darken-3", "light-green darken-3", "blue darken-3", "orange darken-3", "red darken-3"],
                briefing_title: "Test Briefing",
                keywords: [],
                project: null,
                questions: [],
                termplacements: [],
                inspirations: [],
                info: {
                    message: null,
                    icon: null,
                    color: null
                },
                showInfo: false,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),

                // share
                sharedCompanyId: null,
                sharedId: null,
                sharedUserId: null,
                shared_extern: false,
                share_data: { briefing: false, editor: false, wdfidf: false, competitors: false }
            }
        },
        computed: {
            computed_company_id() {
                console.log(localStorage.company_id)
                return localStorage.company_id
            },
            computed_user_id() {
                return localStorage.id
            },
            selectedTopics() {
                return this.project.topics.filter((topic) => topic.selected)
            },
            selectedCompetitors() {
                return this.project.competitors.filter((comp) => comp.selected)
            },
            placementHints() {
                let placementHints = []
                if (!this.project || !this.keywords.length) return placementHints
                let treshold = this.project.settings.placement
                let sorted = this.keywords
                    .filter((kw) => kw.termplacementValue > 0)
                    .sort(function (a, b) {
                        return b.termplacementValue - a.termplacementValue
                    })
                sorted.map((kwEntry) => {
                    kwEntry.termplacement.map((placement) => {
                        let data = { keyword: kwEntry.keyword, ...placement }
                        if ((placement.count / this.selectedCompetitors.length) * 100 > 50) {
                            placementHints.push(data)
                        }
                    })
                })
                placementHints = placementHints.sort(function (a, b) {
                    return a.tag.substring(1) - b.tag.substring(1)
                })
                return placementHints
            }
        },
        methods: {
            async removeKeyword(keyword) {
                keyword.selected = false
                const url = process.env.VUE_APP_APIURL + "/cs/update/keywords"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    keywords: [keyword],
                    project_id: this.project._id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
                this.fetchKeywords()
            },
            async removeQuestion(questions) {
                questions.map((question) => {
                    let index = this.project.questions.findIndex((x) => question.question == x.question)
                    if (index > -1) {
                        this.project.questions[index].selected = false
                    }
                    // else {
                    //     this.project.questions.push(question)
                    // }
                })
                const url = process.env.VUE_APP_APIURL + "/cs/update"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: this.project
                }
                await this.$helpers.axiosPost(url, post_data)
                console.log("questions updated!!!!")
                this.fetchProject()
            },
            async openEditor(project) {
                console.log(project)
                /**
                 * fetches editor data of project
                 */
                const url = process.env.VUE_APP_APIURL + "/cs/get/editor/entrycheck"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    project_id: project._id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                // if (response_data.data.editorData && response_data.data.editorData._id) {
                console.log(response_data.data)
                var now = new Date()
                console.log(response_data.data.editorData.user_id)
                console.log(localStorage.id)

                if (response_data.data.editorData.user_id == localStorage.id) {
                    console.log(response_data.data.editorData)
                    this.$router.push("/content-editor/" + project._id)
                } else {
                    console.log("diff : ", Math.floor(now - new Date(response_data.data.editorData.last_updated)) / 1000)

                    if (Math.floor(now - new Date(response_data.data.editorData.last_updated)) / 1000 > 15) {
                        console.log(response_data.data.editorData)
                        this.$router.push("/content-editor/" + project._id)
                    } else {
                        this.info = this.$helpers.createNotification(
                            "This Content Editor is opened by another user. The Content Editors can be used by one user at a time!",
                            "mdi-alert-circle-outline",
                            "#FCBA03"
                        )
                    }
                }
                // } else {
                //     console.log("no editor data yet -> first opening")
                //     this.$router.push("/content-editor/" + project._id)
                // }
            },
            createInfo(message, color, icon) {
                console.log("createinfo")
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },
            /**
             * Fetches Project Data by id
             */
            async fetchProject() {
                return new Promise(async (resolve, reject) => {
                    const url = process.env.VUE_APP_APIURL + "/cs/get/project"
                    let post_data = {
                        company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                        id: this.shared_extern ? this.sharedId : this.$route.params.project_id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.project = response_data.data.project
                    console.log(this.project)
                    this.questions = response_data.data.project.questions.filter((q) => q.selected)
                    this.share_data = {
                        briefing: response_data.data.project.briefing_shared_extern,
                        editor: response_data.data.project.editor_shared_extern,
                        wdfidf: response_data.data.project.wdfidf_shared_extern,
                        competitors: response_data.data.project.competitors_shared_extern
                    }
                    resolve(response_data.data.project.briefing_shared_extern)
                })
            },
            /**
             * id exists if reload happens while in setup
             * @param {*} id
             */
            async fetchKeywords() {
                return new Promise(async (resolve, reject) => {
                    const url = process.env.VUE_APP_APIURL + "/cs/get/keywords"
                    console.log(this.sharedCompanyId)
                    if (this.$route.params.project_id) {
                        let post_data = {
                            company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                            user_id: localStorage.id,
                            project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id,
                            only_selected: true
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        console.log(response_data.data.keywords)
                        this.keywords = response_data.data.keywords.filter((kw) => kw.selected)
                    } else {
                        console.log("no id")
                    }
                    resolve(true)
                })
            },
            exportBriefing(format) {
                /***********
                 * 0 -> PDF
                 * 1 -> PNG
                 **********/
                const canvasEl = document.getElementById("briefing")
                console.log(canvasEl)
                // if (format === 0) {
                //     const doc = new jsPDF(canvasEl)

                //     // const width = canvasEl.width
                //     // const height = canvasEl.height
                //     // doc.addImage(canvasEl, "PNG", 1, 2)
                //     doc.save("Google Search Volume.pdf")
                // } else if (format === 1) {
                //     canvasToImage(canvasEl, {
                //         name: "Google Search Volume",
                //         type: "png",
                //         quality: 1
                //     })
                // }

                var doc = new jsPDF("l", "mm", [1200, 1210])

                var pdfjs = canvasEl

                // Convert HTML to PDF in JavaScript
                doc.html(pdfjs, {
                    callback: function (doc) {
                        doc.save("output.pdf")
                    },
                    x: 10,
                    y: 10
                })
            },
            fetchCompanyStatus() {
                return new Promise(async (resolve, reject) => {
                    this.$http.post(process.env.VUE_APP_APIURL + "/company/status", { company_id: this.sharedCompanyId }, { headers: { Authorization: this.auth } }).then(function (response) {
                        console.log(response.body)

                        if (response.body[0].status !== "test_ended" || response.body[0].status !== "inactive") {
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    })
                })
            },
            showComponent(componentName) {
                this.activeButton = componentName

                if (componentName === "QuestionsTable") {
                    this.selectedComponent = "QuestionsTable"
                } else if (componentName === "InspiratonTable") {
                    this.selectedComponent = "InspiratonTable"
                } else if (componentName === "HeadlinesTable") {
                    this.selectedComponent = "HeadlinesTable"
                } else if (componentName === "TermsTable") {
                    this.selectedComponent = "TermsTable"
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            next(async (vm) => {
                if (vm.$route.params.share_id) {
                    console.log(vm.$route.params.share_id)
                    let encrypted = ""
                    try {
                        encrypted = await vm.CryptoJS.AES.decrypt(vm.$route.params.share_id.replace(new RegExp("Por21Ld", "g"), "/"), process.env.VUE_APP_ENCRYPTION_PW).toString(vm.CryptoJS.enc.Utf8)
                        console.log(encrypted)
                    } catch (error) {
                        console.log(error)
                        vm.info = vm.$helpers.createNotification("Sorry, we could not find what you are looking for!", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = false
                        return
                    }

                    let encryptedSplits = encrypted.split("|")
                    vm.sharedCompanyId = encryptedSplits[0]
                    vm.sharedId = encryptedSplits[1]
                    console.log(vm.sharedId)
                    if (!vm.sharedId) {
                        vm.info = vm.$helpers.createNotification("Sorry, we could not find what you are looking for!", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = false
                        return
                    }

                    vm.shared_extern = true

                    let informations = await Promise.all([vm.fetchProject(), vm.fetchCompanyStatus()])
                    console.log(informations)
                    let shared = informations[0]
                    let companyactive = informations[1]
                    console.log(shared)
                    if (shared && companyactive) {
                        if (localStorage.sharedUserId && localStorage.sharedUserId.includes("extern_")) {
                            vm.sharedUserId = localStorage.sharedUserId
                        } else {
                            vm.sharedUserId = "extern_" + Math.random().toString(36).slice(2)
                            localStorage.sharedUserId = vm.sharedUserId
                        }

                        // await vm.fetchListInformations()

                        await vm.fetchKeywords()
                        await vm.fetchProject()
                        vm.loading = false
                    } else {
                        vm.shared_extern = false

                        vm.info = vm.$helpers.createNotification("The briefing is not shared.", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = false
                    }
                } else {
                    console.log(vm.$store.state.content_suite)
                    if (!vm.$store.state.content_suite) {
                        vm.$router.push("/")
                    } else {
                        await vm.fetchKeywords()
                        await vm.fetchProject()
                        vm.loading = false
                    }
                }
            })
        },
        mounted() {}
    }
</script>

<style>
    #briefing_title {
        font-weight: 300;
    }
    .briefing_card_label {
        font-size: 1rem;
        color: rgb(101, 101, 101);
        text-transform: uppercase;
    }
    .briefing_card_text {
        /* margin-left: 10px; */
    }

    #bitable th,
    #btptable th,
    #bktable th,
    #bqtable th {
        text-transform: uppercase;
        font-weight: 100;
    }
    .circularprogress {
        margin-top: 20vh;
    }
</style>
