<template>
    <div>
        <!-- <v-btn rounded color="white" @click="showsettings = !showsettings"> <v-icon :color="filteractive ? 'primary' : ''">mdi-filter-variant</v-icon> Filter <v-switch small flat></v-switch></v-btn> -->

        <!-- sidebarbutton -->
        <v-card
            id="search-settings"
            class="py-2 px-4"
            color="primary"
            dark
            flat
            link
            min-width="100"
            :style="'position: fixed; top: 200px; right: -35px; border-radius: 25px; z-index: 4;opacity: 0.7;'">
            <!-- <v-badge color="red" left :content="$store.state.active_filter.length"> -->
            <div class="badgeleft badgeleftpulsate" v-if="active_filter.length > 0"></div>
            <v-icon large> mdi-filter-variant</v-icon>
            <!-- </v-badge> -->
        </v-card>

        <!-- menu -->
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            activator="#search-settings"
            bottom
            content-class="v-settings"
            left
            nudge-left="8"
            offset-x
            origin="top right"
            transition="scale-transition">
            <v-card class="text-center mb-0" width="500" id="clipboard">
                <v-card-text class="pa-3">
                    <!-- headline -->
                    <strong id="maintitle" class="d-inline-block"> Filter </strong>
                    <hr id="divider" class="my-3" />
                    <!-- active filter  -->
                    <div class="activefilter mt-5 mb-5">
                        <span v-for="(filter, index) in active_filter">
                            <v-chip close @click:close="clearFilter(index)" @click="selectFilter(filter)" color="primary" class="mr-1 mb-1">
                                <!-- field -->
                                <span class="activefilter-field">{{ filter.field.text }}</span>
                                <!-- action -->
                                <span v-if="filter.action.type == 'number'" class="activefilter-action mx-2">"{{ filter.action.value }}"</span>
                                <span v-else class="activefilter-action mx-2">"{{ filter.action.text }}"</span>
                                <!-- value -->
                                <span v-if="filter.action.type == 'array' && filter.field.id == 7" class="activefilter-value">
                                    <v-icon size="20px">${{ filter.value[0].value }}</v-icon>
                                    <span v-if="filter.value.length > 1">,...</span>
                                </span>
                                <span v-else-if="filter.action.type == 'array' && (filter.field.id == 3 || filter.field.custom)" class="activefilter-value">
                                    <v-chip small>{{ filter.field.id == 3 ? filter.value[0].category_name : filter.value[0].text }}</v-chip>
                                    <span v-if="filter.value.length > 1">,...</span>
                                </span>
                                <span v-else-if="filter.action.type == 'array' && filter.field.id !== 7 && filter.field.id < 12" class="activefilter-value">
                                    {{ filter.value[0] }}
                                    <span v-if="filter.value.length > 1">,...</span>
                                </span>
                                <!-- <span v-else-if="filter.action.type == 'array' && filter.field.id > 9" class="activefilter-value">
                                    <v-chip small>{{ filter.value[0].text }}</v-chip>
                                    <span v-if="filter.value.length > 1">,...</span>
                                </span> -->

                                <span v-else class="activefilter-value">{{ filter.value }}</span>
                            </v-chip>
                        </span>
                    </div>
                    <!-- Add filter -->
                    <!-- field -->
                    <v-select
                        class="mb-3"
                        hide-details
                        dense
                        clearable
                        clear-icon="mdi-close"
                        rounded
                        v-model="new_filter.field"
                        :items="fields"
                        item-text="text"
                        item-value="id"
                        label="Select a field to filter"
                        return-object
                        @input="resetFilterFields()"
                        top
                        filled />
                    <!-- action -->
                    <v-select
                        v-if="new_filter.field"
                        class="mb-3"
                        hide-details
                        dense
                        clearable
                        clear-icon="mdi-close"
                        rounded
                        v-model="new_filter.action"
                        :items="new_filter.field.type == 'text' ? text_actions : new_filter.field.type == 'number' ? number_actions : array_actions"
                        item-text="text"
                        item-value="id"
                        label="Select a filter"
                        return-object
                        top
                        filled />
                    <!-- value  number-->
                    <v-text-field
                        clearable
                        @keyup.enter="applyfilter"
                        clear-icon="mdi-close"
                        rounded
                        filled
                        v-if="new_filter.action && new_filter.action.type == 'number'"
                        v-model="new_filter.number_value"
                        type="number"
                        :step="new_filter.field.id == 4 || new_filter.field.id == 5 ? 0.01 : 1"
                        label="Enter a value"></v-text-field>
                    <!-- value  text-->
                    <v-text-field
                        clearable
                        clear-icon="mdi-close"
                        rounded
                        @keyup.enter="applyfilter"
                        filled
                        v-if="new_filter.action && new_filter.action.type == 'text'"
                        v-model="new_filter.text_value"
                        :label="new_filter.field.id == 1 ? 'Seperate multiple keywords with comma' : 'Enter a value'"></v-text-field>
                    <!-- value array-->
                    <v-combobox
                        ref="el"
                        v-if="new_filter.action && new_filter.action.type == 'array'"
                        dense
                        v-model="new_filter.array_value"
                        rounded
                        hide-details="true"
                        :items="
                            new_filter.field.id == 7
                                ? serpItems
                                : new_filter.field.id == 10
                                ? months
                                : new_filter.field.id == 11
                                ? seasonsOfList
                                : new_filter.field.id == 3
                                ? catItems
                                : new_filter.field.custom
                                ? customObjects[new_filter.field.id]
                                : []
                        "
                        filled
                        @keyup.enter="applyfilter"
                        label="Insert a value and press enter"
                        multiple
                        clearable
                        clear-icon="mdi-close"
                        small-chips
                        :item-text="new_filter.field.id == 3 ? 'category_name' : 'text'"
                        deletable-chips>
                        <template v-if="new_filter.field.id == 7" v-slot:selection="data">
                            <v-chip dark v-bind="data.attrs" :input-value="data.selected">
                                {{ data.item.tooltip.split(":")[0] }}
                            </v-chip>
                        </template>
                        <!-- <template v-slot:item="data">
                                                            {{ data.item.text }}
                                                        </template> -->
                        <template v-if="new_filter.field.id == 7" v-slot:item="data">
                            <v-col cols="1">
                                <v-icon small v-if="data.attrs['aria-selected'] == 'true'" color="grey">mdi-check</v-icon>
                            </v-col>
                            <v-col cols="11">
                                <v-chip small v-bind="data.attrs">
                                    <span>{{ data.item.tooltip.split(":")[0] }} </span> <v-icon class="pl-1" size="20px">${{ data.item.value }}</v-icon>
                                </v-chip>
                            </v-col>
                        </template>
                        <!-- <template v-if="new_filter.field.id == 11" v-slot:selection="data">
                            <v-chip dark v-bind="data.attrs" :input-value="data.selected">
                                {{ data.item.season }}
                            </v-chip>
                        </template>
                    
                        <template v-if="new_filter.field.id == 11" v-slot:item="data">
                            <v-col cols="1">
                                <v-icon small v-if="data.attrs['aria-selected'] == 'true'" color="grey">mdi-check</v-icon>
                            </v-col>
                            <v-col cols="11">
                                <v-chip small v-bind="data.attrs">
                                    <span>{{ data.item.season }} </span>
                                </v-chip>
                            </v-col>
                        </template> -->
                    </v-combobox>
                    <!-- <v-expansion-panels accordion flat>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">Search Volume</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-0 pt-1">
                                        <v-text-field type="number" chips dense rounded hide-details="true" filled label="MIN" v-model="filter.sv_min"></v-text-field>
                                    </v-col>
                                    <v-col class="col-6 pt-1">
                                        <v-text-field dense rounded hide-details="true" filled label="MAX" v-model="filter.sv_max"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">CPC</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.cpc_min"></v-text-field></v-col>
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.cpc_max"></v-text-field></v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">Include keywords</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="cols-12 pt-1">
                                        <v-combobox
                                            dense
                                            rounded
                                            hide-details="true"
                                            filled
                                            v-model="filter.included_keywords"
                                            label="Insert a keyword and press enter"
                                            multiple
                                            small-chips></v-combobox>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">Exclude keywords</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="cols-12 pt-1">
                                        <v-combobox
                                            dense
                                            rounded
                                            hide-details="true"
                                            filled
                                            v-model="filter.excluded_keywords"
                                            label="Insert a keyword and press enter"
                                            multiple
                                            small-chips></v-combobox>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">Word count</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.wordcount_min"></v-text-field></v-col>
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.wordcount_max"></v-text-field></v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">SEA Competiton</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-0 pt-1">
                                        <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.sea_competition_min"></v-text-field>
                                    </v-col>
                                    <v-col class="col-6 pt-1">
                                        <v-text-field dense rounded hide-details="true" filled label="MAX" v-model="filter.sea_competition_max"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">Keyword Difficuly</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.kw_difficulty_min"></v-text-field></v-col>
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.kw_difficulty_max"></v-text-field></v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">Include SERP Items</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="col-12 pt-1">
                                        <v-select
                                            hide-details
                                            dense
                                            rounded
                                            v-model="filter.included_serpItems"
                                            :items="serpItems"
                                            item-text="value"
                                            item-value="id"
                                            label="Select SERP Items to include"
                                            return-object
                                            top
                                            filled />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <span class="settingslabels">Exclude SERP Items</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-4">
                                    <v-col class="col-12 pt-1">
                                        <v-select
                                            hide-details
                                            dense
                                            rounded
                                            v-model="filter.excluded_serpItems"
                                            :items="serpItems"
                                            item-text="value"
                                            item-value="id"
                                            label="Select SERP Items to include"
                                            return-object
                                            top
                                            filled />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels> -->

                    <!-- <v-container>
                        <v-row>
                            <v-col class="col-xl-12">
                                <span class="settingslabels">Search Volume</span>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-0 pt-1">
                                        <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.sv_min"></v-text-field>
                                    </v-col>
                                    <v-col class="col-6 pt-1">
                                        <v-text-field dense rounded hide-details="true" filled label="MAX" v-model="filter.sv_max"></v-text-field>
                                    </v-col>
                                </v-row>
                                <span class="settingslabels">CPC</span>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.cpc_min"></v-text-field></v-col>
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.cpc_max"></v-text-field></v-col>
                                </v-row>
                                <span class="settingslabels">Include keywords</span>
                                <v-row class="pb-4">
                                    <v-col class="cols-12 pt-1">
                                        <v-combobox
                                            dense
                                            rounded
                                            hide-details="true"
                                            filled
                                            v-model="filter.included_keywords"
                                            label="Insert a keyword and press enter"
                                            multiple
                                            small-chips></v-combobox>
                                    </v-col>
                                </v-row>
                                <span class="settingslabels">Word count</span>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.wordcount_min"></v-text-field></v-col>
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.wordcount_max"></v-text-field></v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-xl-12">
                                <span class="settingslabels">SEA Competiton</span>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-0 pt-1">
                                        <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.sea_competition_min"></v-text-field>
                                    </v-col>
                                    <v-col class="col-6 pt-1">
                                        <v-text-field dense rounded hide-details="true" filled label="MAX" v-model="filter.sea_competition_max"></v-text-field>
                                    </v-col>
                                </v-row>
                                <span class="settingslabels">Keyword Difficulty</span>
                                <v-row class="pb-4">
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.kw_difficulty_min"></v-text-field></v-col>
                                    <v-col class="col-6 pt-1"> <v-text-field dense rounded hide-details="true" filled label="MIN" v-model="filter.kw_difficulty_max"></v-text-field></v-col>
                                </v-row>
                                <span class="settingslabels">Exclude keywords</span>
                                <v-row class="pb-4">
                                    <v-col class="cols-12 pt-1">
                                        <v-combobox
                                            dense
                                            rounded
                                            rows="3"
                                            hide-details="true"
                                            filled
                                            v-model="filter.excluded_keywords"
                                            label="Insert a keyword and press enter"
                                            multiple
                                            small-chips></v-combobox>
                                    </v-col>
                                </v-row>
                                <span class="settingslabels">Include SERP Items</span>
                                <v-row class="pb-4">
                                    <v-col class="col-12 pt-1">
                                        <v-select
                                            hide-details
                                            dense
                                            rounded
                                            v-model="filter.included_serpItems"
                                            :items="serpItems"
                                            item-text="loc"
                                            item-value="id"
                                            label="Select SERP Items to include"
                                            return-object
                                            top
                                            filled
                                            attach />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container> -->

                    <!-- buttons -->
                    <v-row class="py-3 pt-5">
                        <v-btn @click="clearFilter(false)" class="ml-auto" rounded color="grey"><v-icon class="mr-2">mdi-close-circle</v-icon> reset filter</v-btn>
                        <v-btn :disabled="!new_filter.field" class="ml-3 mr-5" rounded color="primary" @click="applyfilter"><v-icon class="mr-2">mdi-check-circle</v-icon>Apply filter</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import { all_serp_items } from "./helper/serp_items.js"
    export default {
        props: {
            catsOfList: Array,
            seasonsOfList: Array,
            headers: Array
        },
        data() {
            return {
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),

                menu: false,
                filteractive: false,
                showsettings: false,
                new_filter: {
                    field: null,
                    action: null,
                    text_value: null,
                    number_value: null,
                    array_value: null
                },
                array_actions: [
                    { text: "includes", value: "includes", type: "array" },
                    { text: "excludes", value: "excludes", type: "array" }
                ],
                text_actions: [
                    { text: "includes", value: "includes", type: "text" },
                    { text: "excludes", value: "excludes", type: "text" },
                    { text: "like", value: "like", type: "text" },
                    { text: "not like", value: "not_like", type: "text" }
                ],
                number_actions: [
                    { text: "equals", value: "=", type: "number" },
                    { text: "less than", value: "<", type: "number" },
                    { text: "larger than", value: ">", type: "number" }
                ],
                fields: [
                    { type: "text", text: "Keyword", id: 1 },
                    { type: "number", text: "Search Volume", id: 2 },
                    { type: "array", text: "Category", id: 3 },
                    { type: "number", text: "CPC Lower Range", id: 4 },
                    { type: "number", text: "CPC Upper Range", id: 5 },
                    { type: "number", text: "Word Count", id: 6 },
                    { type: "array", text: "SERP Items", id: 7 },
                    { type: "number", text: "SEA Competition", id: 8 },
                    { type: "number", text: "Keyword Difficulty", id: 9 },
                    { type: "array", text: "Peak Month", id: 10 },
                    { type: "array", text: "Season", id: 11 }
                    // when adding entry, edit line 44, 48, 126, 561, 572, 607 and filterKeywordTable in global.js
                ],
                serpItems: all_serp_items,
                months: ["-", "January", "February", "March", "April", "May", "June", "July", "August", "October", "September", "November", "December"],
                catItems: [],
                allCats: [],
                customObjects: {},
                searchInputComboBox: null
            }
        },
        computed: {
            active_filter: {
                // getter
                get: function () {
                    return this.$store.state.active_filter
                },
                // setter
                set: function (newValue) {
                    this.$store.dispatch("CHANGE_ACTIVE_FILTER", newValue)
                }
            }
        },
        watch: {
            new_filter: {
                handler(val) {
                    if (this.new_filter.array_value !== null) this.$refs.el.internalSearch = ""
                },
                deep: true
            },
            catsOfList: {
                handler(val) {
                    if (val.length == 0) {
                        this.catItems = this.allCats
                    } else {
                        this.catItems = val
                    }
                },
                deep: true
            },
            headers: {
                handler(val) {
                    if (val !== []) this.addCustoms()
                },
                deep: true
            }
            // active_filter: {
            //     handler(val) {
            //         console.log("filter changed")
            //         this.showPulsatingBadge = val.length ? true : false
            //     },
            //     deep: true
            // }
        },
        methods: {
            selectFilter(filter) {
                this.new_filter.field = filter.field
                this.new_filter.action = filter.action
                if (filter.action.type == "text") {
                    this.new_filter.text_value = filter.value
                    this.new_filter.number_value = null
                    this.new_filter.array_value = null
                } else if (filter.action.type == "number") {
                    this.new_filter.number_value = filter.value
                    this.new_filter.text_value = null
                    this.new_filter.array_value = null
                } else {
                    this.new_filter.array_value = filter.value
                    this.new_filter.text_value = null
                    this.new_filter.number_value = null
                }
            },
            resetFilterFields() {
                this.new_filter.action = null
                this.new_filter.text_value = null
                this.new_filter.number_value = null
                this.new_filter.array_value = null
            },
            /**
             * removes active filter
             * if no index is passed -> clear all
             * @param {*} index filter to remove
             */
            clearFilter(index) {
                if (index === false) {
                    this.active_filter.splice(0, this.active_filter.length)
                    return
                }
                this.active_filter.splice(index, 1)
            },
            /**
             * creates filter based on selection
             *
             */
            applyfilter() {
                let filter = { field: this.new_filter.field, action: this.new_filter.action }
                if (this.new_filter.number_value) {
                    filter.value = this.new_filter.number_value
                } else if (this.new_filter.text_value) {
                    filter.value = this.new_filter.text_value
                } else {
                    filter.value = this.new_filter.array_value
                }
                if (!filter.value) return
                let active_filter = this.$store.state.active_filter
                let index = -1
                if (filter.field.id == 1 || filter.field.id == 3 || filter.field.id == 7 || filter.field.id == 10 || filter.field.id == 11 || filter.field.custom) {
                    // for array filters check if filter with same action exists
                    index = active_filter.findIndex((f) => filter.field == f.field && filter.action == f.action)
                    if (index === -1) {
                        // add new filter
                        active_filter.push(filter)
                        this.$store.dispatch("CHANGE_ACTIVE_FILTER", active_filter)

                        return
                    } else {
                        // replace existing filter
                        active_filter.splice(index, 1)
                        active_filter.push(filter)

                        this.$store.dispatch("CHANGE_ACTIVE_FILTER", active_filter)
                        return
                    }
                } else {
                    index = active_filter.findIndex((f) => filter.field == f.field && filter.action == f.action && filter.value == f.value)
                    if (index === -1) {
                        // add new filter
                        active_filter.push(filter)
                        this.$store.dispatch("CHANGE_ACTIVE_FILTER", active_filter)

                        return
                    }
                }

                this.resetFilterFields()
            },
            fetchCategories() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                        this.catItems = cats
                        this.allCats = cats
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            addCustoms() {
                this.customObjects = {}
                let fields = this.fields.filter((x) => !x.custom)

                this.fields = fields

                let customheaders = this.headers.filter((x) => x.value !== undefined && x.value.includes("custom_"))
                customheaders.map((header) => {
                    if (header.customOptions.type == "Dropdown") {
                        let field_id = header.value
                        this.fields.push({ type: "array", text: header.text, id: field_id, custom: true })
                        this.customObjects[field_id] = []
                        header.customOptions.entries.map((entry) => {
                            this.customObjects[field_id].push(entry)
                        })
                    }
                })
            }
        },
        mounted() {
            this.fetchCategories()
            this.serpItems.unshift({ value: "-", tooltip: "-:" })
        }
    }
</script>

<style scoped>
    #divider {
        border: 1px solid rgb(231, 231, 231);
        border-radius: 1px;
    }
    #maintitle {
        padding-top: 3px;
        line-height: 25px;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        font-size: 1em;
    }
    .settingslabels {
        font-family: Nunito;
        text-transform: uppercase;
        font-weight: 500 !important;
        color: var(--v-primary-base);
    }
    .filteractions {
        display: inline-flex;
    }
    .activefilter-field {
        font-weight: bold;
    }
    .activefilter-value {
        font-weight: bold;
    }
    .activefilter {
        text-align: left;
    }
</style>
