<template>
    <div id="contentcontainter" class="standard-mode">
        <SearchSettings :catsOfList="catsOfList" :seasonsOfList="seasonsOfList" :headers="headers.modifiedHeaders"></SearchSettings>

        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>

        <!-- Delete Dialog -->
        <v-dialog v-model="deleteQuestion" max-width="300">
            <v-card>
                <v-card-title
                    style="
                         {
                            text-align: center;
                        }
                    ">
                    Are you sure?</v-card-title
                >
                <v-card-text class="text-center">
                    <v-btn class="mr-3" text @click="deleteQuestion = false"> Nevermind </v-btn>
                    <v-btn color="primary" text @click="deleteKeywords(selected)"> Yes </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- stats cards -->
        <v-row>
            <v-col cols="12" xl="3" md="6" sm="6" v-for="competitor in statsCards" :key="competitor.title">
                <v-card class="quaroshadow px-5 py-3" id="kwcountCard">
                    <v-row>
                        <v-sheet color="primary" max-height="50" class="pa-12" dark top id="searchsheet" rounded elevation="6">
                            <div class="headingsheet2">
                                <v-icon id="sheeticon" large>mdi-magnify</v-icon>
                            </div>
                        </v-sheet>
                        <div v-if="statCardsLoading" class="text-xs-center" id="statcards">
                            <v-progress-circular color="primary" indeterminate></v-progress-circular>
                        </div>
                        <div v-else class="ml-auto mr-1 text-right">
                            <v-chip class="mb-2">
                                <v-avatar left :color="competitor.color">
                                    <!-- <img :src="getFavicon(competitor.url)" class="tablelogos"/> -->
                                </v-avatar>
                                {{ competitor.title }}
                            </v-chip>
                            <div class="body-3 grey--text font-weight-light">Google Searches</div>
                            <div>{{ competitor.searches }}</div>
                        </div>
                    </v-row>

                    <v-icon size="16" class="ml-2 mr-1"> mdi-clock </v-icon>

                    <span class="caption grey--text font-weight-light"> Updated on {{ updatedDate }}</span>
                </v-card>
            </v-col>
        </v-row>

        <!-- linechart -->
        <v-row>
            <v-col cols="12" md="8" sm="12" xs="12">
                <v-card style="height: 100%" class="quaroshadow rounded_card">
                    <div class="backsheet"></div>
                    <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" fab color="primary" top small right absolute>
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list rounded dense>
                            <v-list-item
                                class="pl-2"
                                @click="
                                    chartDownloadDialog.display = true
                                    selectedChart.chart = 'line-chart'
                                    selectedChart.title = 'Volume of all branded searches'
                                ">
                                <v-icon class="mr-2">mdi-cloud-download</v-icon>
                                <v-list-item-title>Download Chart</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                class="pl-2"
                                @click="
                                    chartSettingsDialog.display = true
                                    selectedChart.chart = 'line-chart'
                                    selectedChart.title = 'Volume of all branded searches'
                                ">
                                <v-icon class="mr-2">mdi-cog</v-icon><v-list-item-title>Settings</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-card-title id="cardtitle">
                        {{ graphCardData.title }}
                    </v-card-title>
                    <v-card-subtitle id="cardsubtitle">
                        {{ graphCardData.subtitle }}
                    </v-card-subtitle>
                    <v-card-text id="card-text-p">
                        <line-chart v-if="graphloaded" :dataChart="dataChart" :countGraph="countGraph" :countGraph2="countGraph2" :options="graphOptions"></line-chart>
                        <center v-else class="circularprogress">
                            <v-progress-circular :size="50" color="primary" :indeterminate="true"></v-progress-circular>
                        </center>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- linechart end-->
            <!-- pie chart start -->
            <v-col cols="12" md="4" sm="12" xs="12">
                <v-card style="height: 100%" class="quaroshadow rounded_card">
                    <div class="backsheet"></div>
                    <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" fab color="primary" top small right absolute>
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list rounded dense>
                            <v-list-item
                                class="pl-2"
                                @click="
                                    chartDownloadDialog.display = true
                                    selectedChart.chart = 'polar-chart'
                                    selectedChart.title = 'Brand phrase vs. All branded keywords'
                                ">
                                <v-icon class="mr-2">mdi-cloud-download</v-icon>
                                <v-list-item-title>Download Chart</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                class="pl-2"
                                @click="
                                    chartSettingsDialog.display = true
                                    selectedChart.chart = 'polar-chart'
                                    selectedChart.title = 'Brand phrase vs. All branded keywords'
                                ">
                                <v-icon class="mr-2">mdi-cog</v-icon><v-list-item-title>Settings</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-card-title id="cardtitle">
                        {{ pieChartTitle }}
                    </v-card-title>
                    <v-card-subtitle id="cardsubtitle">
                        {{ pieChartSubtitle }}
                    </v-card-subtitle>
                    <v-card-text id="card-text-p">
                        <div v-if="annotationsLoading" class="text-xs-center" id="pieChartSpinner">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </div>
                        <pie-chart v-else :chartData="pieChartData" :options="pieOptions" :reloadPieChart="reloadPieChart"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- pie chart end -->

        <!-- ---------------------- -->
        <!-- Chart Settings Dialog: -->
        <!-- ---------------------- -->

        <v-dialog v-model="chartSettingsDialog.display" scrollable max-width="350px" id="downloaddialog">
            <v-card>
                <strong id="title" class="d-inline-block">{{ chartSettingsDialog.title }}</strong>
                <v-card-text style="padding: 20px !important">
                    <v-container v-if="selectedChart.chart === 'line-chart'">
                        <span class="ma-5" v-for="(slider, i) in settingsSliderLine" v-bind:key="i">
                            <v-slider v-model="slider.value" class="align-center" :max="slider.max" @change="setChartOptionsLine" :min="slider.min" thumb-label hide-details>
                                <template v-slot:append>
                                    <div>{{ slider.value }}</div>
                                </template>
                                <template v-slot:prepend>
                                    <div class="labeldiv">{{ slider.label }}</div>
                                </template>
                            </v-slider>
                        </span>
                    </v-container>
                    <v-container v-if="selectedChart.chart === 'polar-chart'">
                        <span class="ma-5" v-for="(slider, i) in settingsSliderPie" v-bind:key="i">
                            <v-slider v-model="slider.value" class="align-center" :max="slider.max" @change="setChartOptionsPie" :min="slider.min" thumb-label hide-details>
                                <template v-slot:append>
                                    <div>{{ slider.value }}</div>
                                </template>
                                <template v-slot:prepend>
                                    <div class="labeldiv">{{ slider.label }}</div>
                                </template>
                            </v-slider>
                        </span>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- ---------------------- -->
        <!-- Chart Download Dialog: -->
        <!-- ---------------------- -->

        <v-dialog v-model="chartDownloadDialog.display" scrollable max-width="350px" id="downloaddialog">
            <v-card>
                <strong id="title" class="d-inline-block">{{ chartDownloadDialog.title }}</strong>
                <v-card-text style="padding: 20px !important">
                    <center>
                        <v-btn color="primary" large rounded @click="downloadChart(0)">Download as PDF</v-btn>
                    </center>
                    <br />
                    <center>
                        <v-btn color="primary" large rounded @click="downloadChart(1)">Download as PNG</v-btn>
                    </center>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Data table  -->
        <v-slide-y-transition>
            <KeywordTable
                ref="kw_table"
                class="mb-5 mt-3"
                v-if="true && graphloaded"
                :columnsDisabled="columnsDisabled"
                :keywords="keywords"
                :headers="headers"
                :catsLoading="false"
                :cardData="cardData"
                :catsOfList="catsOfList"
                :serpsOfList="serpsOfList"
                :media_counts="false"
                mountedOn="Brandindex"
                :siteKeywordsLocation="siteKeywordsLocation"
                @removeCat="removeCat"
                @switchCat="switchCat"
                @addToChart="addToChart"
                @deleteKeywords="prepareDeleteKw"
                @filterForCat="filterForCat"
                @addCategories="addCategories"
                @createNotification="createNotification"
                @changedHeaders="changedHeaders"
                @addCustomEntries="addCustomEntries"
                @filterForCustomDropdown="filterForCustomDropdown"
                @modifiedDropDownOptions="modifiedDropDownOptions"
                @filterColumns="filterColumns">
            </KeywordTable>
        </v-slide-y-transition>
    </div>
</template>

<script>
    import PieChart from "../components/charts/PieChart"
    import LineChart from "../components/charts/LineChart"
    import canvasToImage from "canvas-to-image"
    import { jsPDF } from "jspdf"
    import KeywordTable from "../components/keyword-table/KeywordTable"
    // import locs from "../components/helper/locs"
    import { standardHeaders } from "../components/helper/global.js"
    import dataprocessing from "../components/helper/dataprocessing.js"
    import SearchSettings from "../components/SearchSettings"

    export default {
        components: {
            LineChart,
            PieChart,
            KeywordTable,
            SearchSettings
        },
        data() {
            return {
                notification: { show: false },
                columnsDisabled: {},
                catsOfList: [],
                seasonsOfList: [],
                serpsOfList: [],
                selectedChart: {
                    chart: "",
                    title: ""
                },
                chartSettingsDialog: {
                    display: false,
                    title: "Chart Settings"
                },
                chartDownloadDialog: {
                    display: false,
                    title: "Chart Download"
                },
                settingsSliderLine: [
                    {
                        value: 12,
                        min: 10,
                        max: 20,
                        label: "Label Size"
                    },
                    {
                        value: 12,
                        min: 10,
                        max: 20,
                        label: "Axis Label Size"
                    },
                    {
                        value: 12,
                        min: 10,
                        max: 20,
                        label: "Legend Label Size"
                    }
                ],
                settingsSliderPie: [
                    {
                        value: 12,
                        min: 10,
                        max: 20,
                        label: "Legend Label Size"
                    }
                ],
                deleteQuestion: false,
                statCardsLoading: true,
                statCards: [],
                annotationsLoading: true,
                pieOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                pieChartTitle: "Brand phrase vs. All branded keywords",
                pieChartSubtitle: "Ratio google search volume (yearly)",
                reloadPieChart: 0,
                showPieChartTip: true,
                pieChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: [],
                            borderColor: [],
                            borderWidth: 3.5,
                            data: []
                        },
                        {
                            backgroundColor: [],
                            borderColor: [],
                            data: []
                        }
                    ]
                },
                statsCards: [],
                updatedDate: "",
                competitors: [
                    {
                        url: "www.planet-sports.de",
                        searches: "350.000"
                    },
                    {
                        url: "www.titus.de",
                        searches: "310.000"
                    },
                    {
                        url: "www.planet-sports.de",
                        searches: "240.000"
                    },
                    {
                        url: "www.titus.de",
                        searches: "110.000"
                    }
                ],
                selectedFilters: [
                    {
                        tags: [],
                        filtername: "Type",
                        selected: [],
                        condition: "that.selectedFilters[0].selected.includes(row.type)"
                    },
                    {
                        tags: [],
                        filtername: "Brand",
                        selected: [],
                        condition: "that.selectedFilters[1].selected.includes(row.brand)"
                    },
                    {
                        tags: [],
                        filtername: "Preposition",
                        selected: [],
                        condition: "that.selectedFilters[2].selected.includes(row.preposition)"
                    },
                    {
                        tags: [],
                        filtername: "Verb",
                        selected: [],
                        condition: "that.selectedFilters[3].selected.includes(row.verb)"
                    },
                    {
                        tags: [],
                        filtername: "Adjective",
                        selected: [],
                        condition: "that.selectedFilters[4].selected.includes(row.adjective)"
                    },
                    {
                        tags: [],
                        filtername: "Noun",
                        selected: [],
                        condition: "that.selectedFilters[5].selected.includes(row.noun)"
                    },
                    {
                        tags: [],
                        filtername: "Transactional",
                        selected: [],
                        condition: "that.selectedFilters[6].selected.includes(row.transactional)"
                    },
                    {
                        tags: [],
                        filtername: "Informative",
                        selected: [],
                        condition: "that.selectedFilters[7].selected.includes(row.informative)"
                    },
                    {
                        tags: [],
                        filtername: "Color",
                        selected: [],
                        condition: "that.selectedFilters[8].selected.includes(row.color)"
                    },
                    {
                        tags: [],
                        filtername: "Material",
                        selected: [],
                        condition: "that.selectedFilters[9].selected.includes(row.material)"
                    }
                ],
                tableBtnDisabled: true,
                selected: [],
                keywords: [],
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],
                pagination: { sortBy: "avgsv", descending: true },
                search: "",
                headers: {
                    options: {
                        sort: true,
                        active: true
                    },
                    standardHeaders: [],
                    modifiedHeaders: []
                },
                // stateItems: locs.stateItems,
                svminimum: 0,
                graphOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    annotation: {
                        annotations: [
                            {
                                type: "line",
                                mode: "vertical",
                                scaleID: "x-axis-0",
                                value: "",
                                borderColor: "grey",
                                borderWidth: 1.5,
                                label: {
                                    content: String.fromCharCode(parseInt("21E0", 16)) + " 2021 | 2020 " + String.fromCharCode(parseInt("21E2", 16)),
                                    enabled: true,
                                    position: "top"
                                }
                            }
                        ]
                    }
                },
                dataChart: {
                    selected: [],
                    labels: [],
                    datasets: [
                        {
                            label: "Sum of all keywords",
                            backgroundColor: "#EB5E28",
                            borderColor: "grey",
                            pointBackgroundColor: "white",
                            borderWidth: 2,
                            pointBorderColor: "grey",
                            data: [],
                            hidden: false
                        }
                    ]
                },
                get plan() {
                    if (localStorage.getItem("plan")) {
                        if (localStorage.getItem("plan") != "[object Object],[object Object]") {
                            // //console.log(localStorage.getItem('user'))
                            return localStorage.getItem("plan")
                        } else {
                            return
                        }
                    } else {
                        return
                    }
                },
                countGraph: 0,
                countGraph2: 0,
                graphloaded: false,
                cardData: {
                    title: "Collection: ",
                    subtitle: "Analyze google searchvolume of the last 12 month"
                },
                graphCardData: {
                    title: "Volume of all branded searches",
                    subtitle: "Analyze google searchvolume of the last 12 month"
                },
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                deleteHeaders: [],
                downloadheaders: [],
                unfilteredKeywords: [],
                siteKeywordsLocation: {}
            }
        },
        computed: {
            decryptedPlan() {
                if (this.plan.length > 0) {
                    return this.CryptoJS.AES.decrypt(this.plan, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            user_rights() {
                return this.$store.state.user_rights
            }
        },

        methods: {
            modifiedDropDownOptions(option, headerValue) {
                let rowsToModify = []
                this.keywords.map((keywordRow) => {
                    if (keywordRow.customs[headerValue].id == option.id) {
                        keywordRow.customs[headerValue] = option
                        rowsToModify.push(keywordRow)
                    }
                })
                if (rowsToModify.length > 0) {
                    let listname = {}
                    if (rowsToModify[0].listname) {
                        listname = rowsToModify[0].listname
                    } else {
                        listname = this.$route.params.brandindex
                    }
                    this.updateCustomOptions(listname, rowsToModify, option, headerValue)
                }
            },
            updateCustomOptions(listname, keywords, valueToSet, headerValue) {
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/keywords/customs/add",
                        {
                            listname: listname,
                            company_id: localStorage.company_id,
                            keywords: keywords,
                            valueToSet: valueToSet,
                            headerValue: headerValue
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {})
            },
            changedHeaders() {
                this.headers = this.$store.state.header_items
                console.log(this.$store.state.header_items)
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/listdata",
                        {
                            listname: this.$route.params.brandindex,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {
                        if (response.body[0].mdata.status !== "updating" && response.body[0].mdata.status !== "creating") {
                            this.$http
                                .post(
                                    process.env.VUE_APP_APIURL + "/research/updateHeaders",
                                    {
                                        listname: this.$route.params.brandindex,
                                        company_id: localStorage.company_id,
                                        headers: this.headers
                                    },
                                    { headers: { Authorization: this.auth } }
                                )
                                .then((response) => {
                                    this.notification = this.$helpers.createNotification("The headers have been updated", "mdi-check-circle-outline", "#16C96D")
                                })
                        } else {
                            this.notification = this.$helpers.createNotification("This research is currently being updated. Please try again in a few moments!", "mdi-alert-circle-outline", "#fcba03")
                        }
                    })
            },
            createNotification(notification) {
                this.notification = this.$helpers.createNotification(notification.message, notification.icon, notification.color)
            },
            filterColumns() {
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
            },
            filterForCustomDropdown(initialObject, isArray, cat_for_filter) {
                var resultObject = Object.fromEntries(Object.entries(initialObject).filter(([_, v]) => v.length != 0))

                if (Object.keys(resultObject).length === 0 && cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.cardData.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.map((keyword) => {
                    if (Object.keys(resultObject).length > 0) {
                        Object.keys(resultObject).map((entry) => {
                            if (keyword.customs !== undefined && Object.keys(keyword.customs).includes(entry)) {
                                if (
                                    resultObject[entry]
                                        .map((obj) => {
                                            if (isArray) {
                                                return keyword.customs[entry].some((keyEntry) => keyEntry.text === obj.text)
                                            } else {
                                                return keyword.customs[entry].text === obj.text
                                            }
                                        })
                                        .includes(true)
                                ) {
                                    if (!this.keywords.some((k) => k === keyword)) {
                                        this.keywords.push(keyword)
                                    }
                                }
                            }
                        })
                    }
                    if (cat_for_filter.length > 0) {
                        if (keyword.cats) {
                            let catsofKeyword = keyword.cats[keyword.active_cat_index]
                            if (catsofKeyword) {
                                cat_for_filter.forEach((catforfilter) => {
                                    if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                        this.keywords.push(keyword)
                                    }
                                })
                            }
                        } else if (!keyword.cats) {
                            let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                            if (findemptycat) this.keywords.push(keyword)
                        }
                    }
                })
                console.log("filtered")
                // this.cardData.subtitle = this.keywords.length + " keywords"
            },
            filterForCat(cat_for_filter) {
                if (cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.cardData.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.forEach((keyword) => {
                    if (keyword.cats) {
                        let catsofKeyword = keyword.cats[keyword.active_cat_index]
                        if (catsofKeyword) {
                            cat_for_filter.forEach((catforfilter) => {
                                if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                    this.keywords.push(keyword)
                                }
                            })
                        }
                    } else if (!keyword.cats) {
                        let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                        if (findemptycat) this.keywords.push(keyword)
                    }
                })
                this.cardData.subtitle = this.keywords.length + " keywords"
            },

            removeCat(row, cat) {
                // remove cat
                let rowindex = this.keywords.indexOf(row)
                let catindex = this.keywords[rowindex].cats.indexOf(cat)
                if (this.keywords[rowindex].cats.length < 2) {
                    this.keywords[rowindex].cats = null
                    this.rerenderKeywordTable()
                } else {
                    this.keywords[rowindex].cats.splice(catindex, 1)
                    this.rerenderKeywordTable()
                }
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/keyword/categories/add",
                        {
                            listname: this.$route.params.brandindex,
                            company_id: localStorage.company_id,
                            keyword: row.keyword,
                            cats: this.keywords[rowindex].cats
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {})
            },
            fetchAllCats() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        // console.log(cats)
                        this.catData = cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            getTree(category) {
                let tree = []
                tree[0] = category
                var findParent = (category) => {
                    let parent = this.catData.filter((x) => x.category_code == category.category_code_parent)
                    if (parent[0]) {
                        tree.unshift(parent[0])
                        if (parent[0].category_code_parent) {
                            findParent(parent[0])
                        }
                    }
                }
                if (category.category_code_parent) {
                    findParent(category)
                }
                return tree
            },
            getListSerps() {
                let serpsOfList = []
                this.keywords.forEach((keyword) => {
                    // console.log(keyword.serp_items)
                    let serps = keyword.serp_items
                    if (serps) {
                        serps.map((x) => {
                            serpsOfList.push(x)
                        })
                    }
                })
                serpsOfList = new Set(serpsOfList)
                serpsOfList = Array.from(serpsOfList)

                serpsOfList.sort((a, b) => a.value - b.value)
                serpsOfList.unshift({ value: "-", tooltip: "-:" })
                console.log(serpsOfList)
                this.serpsOfList = serpsOfList
            },
            getListCats() {
                let catsOfList = []
                this.keywords.forEach((keyword) => {
                    let cats = keyword.cats
                    if (cats) {
                        if (cats[0]) {
                            if (cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1]) {
                                catsOfList.push(cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1])
                            }
                        }
                    }
                })

                catsOfList.sort((a, b) => a.category_name.localeCompare(b.category_name))
                catsOfList.unshift({ category_name: "-" })

                this.catsOfList = catsOfList
            },
            getListSeasons() {
                let seasonsOfList = []
                this.keywords.forEach((keyword) => {
                    if (keyword.season) {
                        seasonsOfList.push(keyword.season.season)
                    }
                })
                seasonsOfList = new Set(seasonsOfList)
                seasonsOfList = Array.from(seasonsOfList)
                console.log(seasonsOfList)

                seasonsOfList.sort()
                seasonsOfList.unshift("-")
                this.seasonsOfList = seasonsOfList
            },
            addCustomEntries(rows, selectedEntry, headerValue, isBackend, isArray) {
                if (!rows) return
                let listname = {}
                if (rows[0].listname) {
                    listname = rows[0].listname
                } else {
                    listname = this.$route.params.brandindex
                }
                let valueToSet = isArray ? [...selectedEntry] : selectedEntry
                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    if (rowindex > -1) {
                        if (this.keywords[rowindex].customs === undefined) {
                            this.keywords[rowindex].customs = {}
                        }
                        this.keywords[rowindex].customs[headerValue] = valueToSet
                    }
                })
                let obj = rows[0].customs
                // this.keywords.push({})
                // this.keywords.pop()
                this.updateCustomOptions(listname, rows, valueToSet, headerValue)
            },
            addCategories(rows, selectedCats) {
                //adds categories to a keyword
                if (!selectedCats[0]) return

                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    for (let index = 0; index < selectedCats.length; index++) {
                        let tree = this.getTree(selectedCats[index])

                        if (this.keywords[rowindex].cats) {
                            this.keywords[rowindex].cats.unshift(tree)
                        } else {
                            this.keywords[rowindex].cats = [tree]
                        }
                        console.log(row)
                        console.log(tree)
                        this.$http
                            .post(
                                process.env.VUE_APP_APIURL + "/research/keyword/categories/add",
                                {
                                    listname: !row.listname ? this.$route.params.listname : row.brandindex,
                                    company_id: localStorage.company_id,
                                    keyword: row.keyword,
                                    cats: this.keywords[rowindex].cats
                                },
                                { headers: { Authorization: this.auth } }
                            )
                            .then((response) => {})
                    }
                })
                for (let index = 0; index < selectedCats.length; index++) {
                    let tree = this.getTree(selectedCats[index])
                    this.switchCat(rows, tree)
                }

                this.getListCats()
                this.rerenderKeywordTable()
            },

            rerenderKeywordTable() {
                this.$refs.kw_table.rerenderKeywordTable()
            },
            /**
             *  * sets/changes primary category for keyword(s)
             * @param {Array} rows keyword(s) to modify
             * @param {Object} cat category to set
             */
            switchCat(rows, cat) {
                this.$helpers.switchCat(rows, cat, this.keywords)
                this.getListCats()
                this.rerenderKeywordTable()
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/keywords/categories/switch",
                        {
                            listname: this.$route.params.brandindex,
                            company_id: localStorage.company_id,
                            keywords: rows.map((row) => row.keyword),
                            cat: cat
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {
                        console.log(response.body)
                    })
            },
            prepareDeleteKw(selected) {
                this.selected = selected
                console.log(this.selected)
                this.deleteQuestion = true
            },

            deleteKeywords(keywords) {
                if (keywords) {
                    if (this.$route.query.findKeyword) {
                        try {
                            for (var i = 0; i < keywords.length; i++) {
                                const index = this.keywords.indexOf(keywords[i])
                                this.keywords.splice(index, 1)
                            }
                            if (this.unfilteredKeywords.length > 0) {
                                for (var i = 0; i < this.selected.length; i++) {
                                    let index = this.unfilteredKeywords.indexOf(this.selected[i])
                                    this.unfilteredKeywords.splice(index, 1)
                                }
                            }
                            this.$matomo.trackEvent("FindKeywords", "deleteKeywords", "KeywordsCount: " + this.selected.length, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        let kwCount = this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length
                        this.$refs.kw_table.keywordsDeleted(kwCount)
                        let kwForGraph = this.keywords
                        if (this.unfilteredKeywords.length > this.keywords.length) {
                            kwForGraph = this.unfilteredKeywords
                        }
                        if (this.dataChart.datasets[0].label == "Sum of all keywords" && kwForGraph.length > 0) {
                            this.calOverallGraph(kwForGraph)
                            this.getListCats()
                            this.getListSerps()
                            this.getListSeasons()
                        }
                        this.deleteQuestion = false
                        this.selected = []
                        this.notification = this.$helpers.createNotification("The selected keywords have been deleted!", "mdi-check-circle-outline", "#16C96D")
                        this.cardData.subtitle = this.keywords.length + " keywords"
                    } else {
                        this.$http
                            .post(
                                process.env.VUE_APP_APIURL + "/research/listdata",
                                {
                                    listname: this.$route.params.brandindex,
                                    company_id: localStorage.company_id
                                },
                                { headers: { Authorization: this.auth } }
                            )
                            .then((response) => {
                                if (response.body[0].mdata.status !== "updating" && response.body[0].mdata.status !== "creating") {
                                    //console.log(keywords)
                                    try {
                                        this.$matomo.trackEvent("Research", "deleteKeywords", "length: " + this.selected.length, "")
                                    } catch (error) {
                                        console.log(error)
                                        console.log("matomo blocked")
                                    }
                                    let data = {
                                        keywords: [],
                                        listname: this.$route.params.brandindex,
                                        newCount: this.keywords.length - this.selected.length,
                                        company_id: localStorage.company_id
                                    }
                                    for (let keywordscounter = 0; keywordscounter < this.selected.length; keywordscounter++) {
                                        data.keywords.push(this.selected[keywordscounter].keyword)
                                    }

                                    this.$http.post(process.env.VUE_APP_APIURL + "/research/deleteKeyword", data, { headers: { Authorization: this.auth } }).then((response) => {
                                        for (var i = 0; i < this.selected.length; i++) {
                                            const index = this.keywords.indexOf(this.selected[i])
                                            this.keywords.splice(index, 1)
                                        }
                                        if (this.unfilteredKeywords.length > 0) {
                                            for (var i = 0; i < this.selected.length; i++) {
                                                let index = this.unfilteredKeywords.indexOf(this.selected[i])
                                                this.unfilteredKeywords.splice(index, 1)
                                            }
                                        }
                                        let kwCount = this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length

                                        this.$refs.kw_table.keywordsDeleted(kwCount)
                                        let kwForGraph = this.keywords
                                        if (kwForGraph.length == 0) {
                                            kwForGraph = this.unfilteredKeywords
                                        }
                                        if (this.dataChart.datasets[0].label == "Sum of all keywords" && kwForGraph.length > 0) {
                                            this.calOverallGraph(kwForGraph)
                                            this.getListCats()
                                            this.getListSerps()
                                            this.getListSeasons()
                                        }
                                        this.selected = []
                                        this.keywords.map((x) => {
                                            x.selected = false
                                        })
                                        this.notification = this.$helpers.createNotification("The selected keywords have been deleted!", "mdi-check-circle-outline", "#16C96D")
                                        this.$router.go(this.$router.path)
                                    })
                                } else {
                                    this.notification = this.$helpers.createNotification(
                                        "This research is currently being updated. Please try again in a few moments!",
                                        "mdi-alert-circle-outline",
                                        "#fcba03"
                                    )
                                }
                            })
                    }
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            downloadChart(format) {
                /***********
                 * 0 -> PDF
                 * 1 -> PNG
                 **********/
                const canvasEl = document.getElementById(this.selectedChart.chart)
                if (format === 0) {
                    let options = {}
                    this.selectedChart.chart === "polar-chart" ? (options = { orientation: "p", format: "a4" }) : (options = { orientation: "l", format: "a4" })
                    const doc = new jsPDF(options)
                    const ratio = canvasEl.width / canvasEl.height
                    const width = doc.internal.pageSize.getWidth()
                    const height = width / ratio
                    doc.addImage(canvasEl, "PNG", 0, height / 2, width, height)
                    doc.save(this.selectedChart.title + ".pdf")
                } else if (format === 1) {
                    canvasToImage(canvasEl, {
                        name: this.selectedChart.title,
                        type: "png",
                        quality: 1
                    })
                }
            },
            setChartOptionsLine() {
                this.graphOptions.scales = {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: this.settingsSliderLine[0].value
                            },
                            display: true,
                            scaleLabel: {
                                fontSize: this.settingsSliderLine[1].value,
                                display: true,
                                labelString: "Months"
                            }
                        }
                    ],
                    yAxes: [
                        {
                            ticks: {
                                fontSize: this.settingsSliderLine[0].value,
                                beginAtZero: this.beginAtZero,
                                callback: function (value, index, values) {
                                    return value.toLocaleString()
                                }
                            },
                            display: true,
                            scaleLabel: {
                                fontSize: this.settingsSliderLine[1].value,
                                display: true,
                                labelString: "Volume of all branded searches"
                            }
                        }
                    ]
                }
                this.graphOptions.legend = {
                    display: true,
                    labels: {
                        fontSize: this.settingsSliderLine[2].value
                    }
                }
                this.countGraph2++
            },
            setChartOptionsPie() {
                this.pieOptions.legend = {
                    display: true,
                    labels: {
                        fontSize: this.settingsSliderPie[0].value
                    }
                }
                this.reloadPieChart++
            },

            createStatCards() {
                for (let index = 0; index < this.dataChart.datasets.length; index++) {
                    if (this.dataChart.datasets[index].label != "Sum of all keywords") {
                        let groupedCompetitorData = this.dataChart.datasets[index]
                        let label = groupedCompetitorData.label
                        const reducer = (accumulator, currentValue) => accumulator + currentValue
                        let sum = groupedCompetitorData.data.reduce(reducer)
                        let value
                        console.log(this.scaleDown)
                        if (this.scaleDown) {
                            value = (sum / 1000000).toFixed(2)
                        } else {
                            value = sum
                        }

                        var ratio = sum
                        let statcard = {
                            type: "danger",
                            icon: "ti-search",
                            title: label,
                            ratio: [ratio],
                            color: groupedCompetitorData.borderColor,
                            url: groupedCompetitorData.domain,
                            searches: this.scaleDown ? this.$helpers.formatNumber(value) + " million/y" : this.$helpers.formatNumber(value) + "/year",
                            footerText: this.updatedDate,
                            footerIcon: "ti-reload"
                        }
                        let color = this.getGraphColor(label.replace("-", " "))
                        //pieChart
                        this.pieChartData.labels.push(label)
                        this.pieChartData.datasets[0].data.push(value)
                        this.pieChartData.datasets[0].backgroundColor.push("rgba(0,0,0,0)")
                        this.pieChartData.datasets[0].borderColor.push(color)
                        //statCards
                        this.statsCards.push(statcard)
                        this.newStatCardCounter++
                    }
                }
            },

            getFavicon(competitor) {
                return "https://i.olsh.me/icon?url=" + competitor + "&size=30"
            },

            resetFilter() {
                this.table1.subTitle = this.apiRows.length + " branded search phrases"
                this.rows = this.apiRows
                // this.selectedFilters[0].tags = helper.cleanArray(this.apiType).sort();
                // this.selectedFilters[1].tags = helper.cleanArray(this.apiBrand).sort();
                // this.selectedFilters[2].tags = helper.cleanArray(this.apiPreposition).sort();
                // this.selectedFilters[3].tags = helper.cleanArray(this.apiVerb).sort();
                // this.selectedFilters[4].tags = helper.cleanArray(this.apiAdjective).sort();
                // this.selectedFilters[5].tags = helper.cleanArray(this.apiNoun).sort();
                // this.selectedFilters[6].tags = helper.cleanArray(this.apiTransactional).sort();
                // this.selectedFilters[7].tags = helper.cleanArray(this.apiInformative).sort();
                // this.selectedFilters[8].tags = helper.cleanArray(this.apiColor).sort();
                // this.selectedFilters[9].tags = helper.cleanArray(this.apiMaterial).sort();
                for (var apiCounter = 0; apiCounter < this.apiData.length; apiCounter++) {
                    this.selectedFilters[apiCounter].tags = this.$helpers.cleanArray(this.apiData[apiCounter].data).sort()
                }

                for (var selectCounter = 0; selectCounter < this.selectedFilters.length; selectCounter++) {
                    this.selectedFilters[selectCounter].selected.length = 0
                }
            },
            filterKeywords() {
                //console.log(this.selectedFilters)
                if (
                    this.selectedFilters[0].selected.length == 0 &&
                    this.selectedFilters[1].selected.length == 0 &&
                    this.selectedFilters[2].selected.length == 0 &&
                    this.selectedFilters[3].selected.length == 0 &&
                    this.selectedFilters[4].selected.length == 0 &&
                    this.selectedFilters[5].selected.length == 0 &&
                    this.selectedFilters[6].selected.length == 0 &&
                    this.selectedFilters[7].selected.length == 0 &&
                    this.selectedFilters[8].selected.length == 0 &&
                    this.selectedFilters[9].selected.length == 0
                ) {
                    this.table1.subTitle = this.apiRows.length + " branded search phrases"
                    this.rows = this.apiRows
                    for (var tagCounter = 0; tagCounter < this.selectedFilters.length; tagCounter++) {
                        this.selectedFilters[tagCounter].tags = this.$helpers.cleanArray(this.apiData[tagCounter].data).sort()
                    }
                    // this.selectedFilters[0].tags = helper.cleanArray(this.apiType).sort();
                    // this.selectedFilters[1].tags = helper.cleanArray(this.apiBrand).sort();
                    // this.selectedFilters[2].tags = helper.cleanArray(this.apiPreposition).sort();
                    // this.selectedFilters[3].tags = helper.cleanArray(this.apiVerb).sort();
                    // this.selectedFilters[4].tags = helper.cleanArray(this.apiAdjective).sort();
                    // this.selectedFilters[5].tags = helper.cleanArray(this.apiNoun).sort();
                    // this.selectedFilters[6].tags = helper.cleanArray(this.apiTransactional).sort();
                    // this.selectedFilters[7].tags = helper.cleanArray(this.apiInformative).sort();
                    // this.selectedFilters[8].tags = helper.cleanArray(this.apiColor).sort();
                    // this.selectedFilters[9].tags = helper.cleanArray(this.apiMaterial).sort();
                } else {
                    var conditions = []
                    for (var conditionCounter = 0; conditionCounter < this.selectedFilters.length; conditionCounter++) {
                        if (this.selectedFilters[conditionCounter].selected.length > 0) {
                            conditions.push(this.selectedFilters[conditionCounter].condition)
                        }
                    }

                    // if (this.selectedFilters[0].selected.length > 0){
                    //   conditions.push("that.selectedFilters[0].selected.includes(row.type)");
                    // }
                    // if (this.selectedFilters[1].selected.length > 0){
                    //   conditions.push("that.selectedFilters[1].selected.includes(row.brand)");
                    // }
                    // if (this.selectedFilters[2].selected.length > 0){
                    //   conditions.push("that.selectedFilters[2].selected.includes(row.preposition)");
                    // }
                    // if (this.selectedFilters[3].selected.length > 0){
                    //   conditions.push("that.selectedFilters[3].selected.includes(row.verb)");
                    // }
                    // if (this.selectedFilters[4].selected.length > 0){
                    //   conditions.push("that.selectedFilters[4].selected.includes(row.adjective)");
                    // }
                    // if (this.selectedFilters[5].selected.length > 0){
                    //   conditions.push("that.selectedFilters[5].selected.includes(row.noun)");
                    // }
                    // if (this.selectedFilters[6].selected.length > 0){
                    //   conditions.push("that.selectedFilters[6].selected.includes(row.transactional)");
                    // }
                    // if (this.selectedFilters[7].selected.length > 0){
                    //   conditions.push("that.selectedFilters[7].selected.includes(row.informative)");
                    // }
                    // if (this.selectedFilters[8].selected.length > 0){
                    //   conditions.push("that.selectedFilters[8].selected.includes(row.color)");
                    // }
                    // if (this.selectedFilters[9].selected.length > 0){
                    //   conditions.push("that.selectedFilters[9].selected.includes(row.material)");
                    // }
                    var conditionString = ""
                    for (var counter = 0; counter < conditions.length; counter++) {
                        conditionString += conditions[counter]
                        if (counter < conditions.length - 1) {
                            conditionString += "&&"
                        }
                    }
                    //console.log(conditionString)
                    var that = this
                    var filteredRows = this.apiRows.filter(function (row) {
                        return eval(conditionString)
                        // that.selectedFilters[1].selected.includes(row.brand) &&
                        // that.selectedFilters[2].selected.includes(row.preposition) &&
                        // that.selectedFilters[3].selected.includes(row.verb) &&
                        // that.selectedFilters[4].selected.includes(row.adjective) &&
                        // that.selectedFilters[5].selected.includes(row.noun) &&
                        // that.selectedFilters[6].selected.includes(row.transactional) &&
                        // that.selectedFilters[7].selected.includes(row.informative) &&
                        // that.selectedFilters[8].selected.includes(row.color) &&
                        // that.selectedFilters[9].selected.includes(row.material);
                    })
                    this.table1.subTitle = filteredRows.length + " branded search phrases"
                    this.rows = filteredRows

                    this.changeFilters(filteredRows)
                }
            },
            //for table buttons
            disabled() {
                if (this.selected.length > 0) {
                    this.tableBtnDisabled = false
                    return this.$vuetify.theme.themes.light.primary
                } else {
                    this.tableBtnDisabled = true
                    return "grey"
                }
            },

            formatNumber(number) {
                return formatNumber(number)
            },

            addToChart(selected) {
                let graphdata = this.dataChart.datasets
                let selectedNoDoubles = this.$helpers.checkGraphDoubles(selected, graphdata)
                if (selected.length > 0) {
                    if (selectedNoDoubles.length > 0) {
                        if (this.$helpers.checkSelectedSizeForGraph(selectedNoDoubles, 11, graphdata)) {
                            for (var i = 0; i < selectedNoDoubles.length; i++) {
                                let newData = {
                                    label: selectedNoDoubles[i].keyword,
                                    backgroundColor: "rgba(0, 0, 0, 0)",
                                    borderColor: "grey",
                                    pointBackgroundColor: "white",
                                    borderWidth: 3.5,
                                    pointBorderColor: "grey",
                                    data: [
                                        selectedNoDoubles[i].January,
                                        selectedNoDoubles[i].February,
                                        selectedNoDoubles[i].March,
                                        selectedNoDoubles[i].April,
                                        selectedNoDoubles[i].May,
                                        selectedNoDoubles[i].June,
                                        selectedNoDoubles[i].July,
                                        selectedNoDoubles[i].August,
                                        selectedNoDoubles[i].September,
                                        selectedNoDoubles[i].October,
                                        selectedNoDoubles[i].November,
                                        selectedNoDoubles[i].December
                                    ]
                                }
                                this.dataChart.datasets.unshift(newData)
                            }
                            this.dataChart.datasets[this.dataChart.datasets.length - 1].hidden = true
                            // Hilfsvariable wird hochgezählt und Aktion in linechart.js ausgelöst
                            this.countGraph++
                        } else {
                            this.notification = this.$helpers.createNotification("Only 10 keywords can be displayed in graph!", "mdi-alert-circle-outline", "#FCBA03")
                        }
                    } else {
                        this.notification = this.$helpers.createNotification("Selected keywords are displayed in the graph already!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            getSparklineData(ranking) {
                let monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                let svArray = []
                svArray[0] = ranking.January
                svArray[1] = ranking.February
                svArray[2] = ranking.March
                svArray[3] = ranking.April
                svArray[4] = ranking.May
                svArray[5] = ranking.June
                svArray[6] = ranking.July
                svArray[7] = ranking.August
                svArray[8] = ranking.September
                svArray[9] = ranking.October
                svArray[10] = ranking.November
                svArray[11] = ranking.December
                return svArray
            },

            checkIfNew() {
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/listdata",
                        {
                            listname: this.$route.params.brandindex,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(function (response) {
                        console.log("RES ", response)
                        this.listdata = response.body[0]
                        let status = response.body[0].mdata.status
                        this.updatedDate = response.body[0].mdata.updated
                        this.headers = response.body[0].mdata.headers
                        console.log(response.body)
                        // for (let index = 0; index < this.stateItems.length; index++) {
                        //     if (this.stateItems[index].value == response.body[0].mdata.langloc.loc) {
                        //         this.selectedLocation = this.stateItems[index]
                        //         this.siteKeywordsLocation = this.stateItems[index]
                        //     }
                        // }
                        this.selectedLocation = response.body[0].mdata.langloc.loc
                        this.siteKeywordsLocation = response.body[0].mdata.langloc.loc

                        //console.log(response.body[0].mdata.status)
                    })
            },

            fetchResearch() {
                this.cardData.title = "Branded Searches"
                this.rows = []
                let keywords = []
                this.fetchAllCats()
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/",
                        {
                            research: this.$route.params.brandindex,
                            svminimum: this.svminimum,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(async function (response) {
                        // do things with listdata
                        this.listdata = response.body.listResponse[0]
                        let status = response.body.listResponse[0].mdata.status
                        this.updatedDate = response.body.listResponse[0].mdata.updated
                        this.headers = response.body.listResponse[0].mdata.headers
                        console.log(response.body)
                        // for (let index = 0; index < this.stateItems.length; index++) {
                        //     if (this.stateItems[index].value == response.body.listResponse[0].mdata.langloc.loc) {
                        //         this.selectedLocation = this.stateItems[index]
                        //         this.siteKeywordsLocation = this.stateItems[index]
                        //     }
                        // }
                        this.selectedLocation = response.body.listResponse[0].mdata.langloc
                        this.siteKeywordsLocation = response.body.listResponse[0].mdata.langloc

                        console.log(response)
                        this.generateColumnLabels(response)
                        for (let index = 0; index < response.body.keywordResponse.count; index++) {
                            let keyworddata = response.body.keywordResponse.keywords[index]
                            let annual = 0
                            for (let sumindex = 0; sumindex < 12; sumindex++) {
                                annual += parseInt(keyworddata.searchvolume[sumindex].volume)
                            }
                            const row = {
                                keyword: keyworddata.keyword,
                                annualsv: annual,
                                avgsv: parseInt(annual / 12) || 0,
                                search_volumes: [],
                                adwords_cpc: keyworddata.adwords_cpc,
                                adwords_cpc_high: keyworddata.adwords_cpc_high,
                                adwords_cpc_low: keyworddata.adwords_cpc_low,
                                adwords_competition: keyworddata.adwords_competition,
                                serp_items: await dataprocessing.getSERPItemsObject(keyworddata.serp_items),
                                keyword_difficulty: dataprocessing.getDifficultyObject(keyworddata.keyword_difficulty),
                                categories: keyworddata.categories ? keyworddata.categories : null,
                                cats: keyworddata.categories_edited ? keyworddata.categories_edited : null,
                                active_cat_index: keyworddata.active_cat_index ? keyworddata.active_cat_index : 0,
                                [keyworddata.searchvolume[11].month]: parseInt(keyworddata.searchvolume[11].volume),
                                [keyworddata.searchvolume[10].month]: parseInt(keyworddata.searchvolume[10].volume),
                                [keyworddata.searchvolume[9].month]: parseInt(keyworddata.searchvolume[9].volume),
                                [keyworddata.searchvolume[8].month]: parseInt(keyworddata.searchvolume[8].volume),
                                [keyworddata.searchvolume[7].month]: parseInt(keyworddata.searchvolume[7].volume),
                                [keyworddata.searchvolume[6].month]: parseInt(keyworddata.searchvolume[6].volume),
                                [keyworddata.searchvolume[5].month]: parseInt(keyworddata.searchvolume[5].volume),
                                [keyworddata.searchvolume[4].month]: parseInt(keyworddata.searchvolume[4].volume),
                                [keyworddata.searchvolume[3].month]: parseInt(keyworddata.searchvolume[3].volume),
                                [keyworddata.searchvolume[2].month]: parseInt(keyworddata.searchvolume[2].volume),
                                [keyworddata.searchvolume[1].month]: parseInt(keyworddata.searchvolume[1].volume),
                                [keyworddata.searchvolume[0].month]: parseInt(keyworddata.searchvolume[0].volume),
                                ["Sum" + keyworddata.searchvolume[11].month]: parseInt(keyworddata.searchvolume[11].volume),
                                ["Sum" + keyworddata.searchvolume[10].month]: parseInt(keyworddata.searchvolume[10].volume),
                                ["Sum" + keyworddata.searchvolume[9].month]: parseInt(keyworddata.searchvolume[9].volume),
                                ["Sum" + keyworddata.searchvolume[8].month]: parseInt(keyworddata.searchvolume[8].volume),
                                ["Sum" + keyworddata.searchvolume[7].month]: parseInt(keyworddata.searchvolume[7].volume),
                                ["Sum" + keyworddata.searchvolume[6].month]: parseInt(keyworddata.searchvolume[6].volume),
                                ["Sum" + keyworddata.searchvolume[5].month]: parseInt(keyworddata.searchvolume[5].volume),
                                ["Sum" + keyworddata.searchvolume[4].month]: parseInt(keyworddata.searchvolume[4].volume),
                                ["Sum" + keyworddata.searchvolume[3].month]: parseInt(keyworddata.searchvolume[3].volume),
                                ["Sum" + keyworddata.searchvolume[2].month]: parseInt(keyworddata.searchvolume[2].volume),
                                ["Sum" + keyworddata.searchvolume[1].month]: parseInt(keyworddata.searchvolume[1].volume),
                                ["Sum" + keyworddata.searchvolume[0].month]: parseInt(keyworddata.searchvolume[0].volume),
                                brand: keyworddata.brand,
                                domain: keyworddata.domain,
                                customs: keyworddata.customs !== null || keyworddata.customs !== undefined ? keyworddata.customs : null
                            }

                            for (let monthCounter = 0; monthCounter < keyworddata.searchvolume.length; monthCounter++) {
                                let volume = {
                                    count: parseInt(keyworddata.searchvolume[monthCounter].volume) || 0,
                                    year: keyworddata.searchvolume[monthCounter].year,
                                    month: this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                }
                                row.search_volumes.push(volume)

                                let svname = ""
                                if (this.returnMonth(keyworddata.searchvolume[monthCounter].month) < 10) {
                                    svname = keyworddata.searchvolume[monthCounter].year.toString() + "0" + this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                } else {
                                    svname = keyworddata.searchvolume[monthCounter].year.toString() + this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                }
                                if (this.deleteHeaders.indexOf(svname) > -1) {
                                } else {
                                    this.deleteHeaders.push(svname)
                                }
                                row[svname] = parseInt(keyworddata.searchvolume[monthCounter].volume) || 0
                            }
                            keywords.push(row)
                        }
                        keywords.sort(function (a, b) {
                            return b.avgsv - a.avgsv
                        })
                        // keywords.sort(function(a, b){return b.avgsv - a.avgsv});
                        // this.createHeader(keywords)
                        this.keywords = keywords
                        this.fetchCategories(keywords)
                        this.calOverallGraph(keywords)
                        this.calCompetitorGraphs(keywords)
                        this.cardData.subtitle = response.body.keywordResponse.count + " branded search phrases"
                        this.$helpers.addPeakAndSeason(this.keywords)

                        this.graphloaded = true
                        this.annotationsLoading = false
                        this.countGraph2++
                    })
            },
            fetchCategories(keywords) {
                this.catsLoading = true
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories/keywords", { company_id: localStorage.company_id, keywords: keywords }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let catData = response.body
                        let onlykeywords = this.keywords.map((x) => x.keyword)
                        for (let index = 0; index < catData.length; index++) {
                            let pos = onlykeywords.indexOf(catData[index].keyword)
                            if (this.keywords[pos].cats.length == 0) {
                                //no preset Categories
                                this.keywords[pos].cats = this.sortCats(catData[index].categories)
                            }
                        }
                        console.log(this.keywords)
                        this.catsLoading = false
                        this.getListCats()
                        if (this.$store.state.active_filter.length) this.filterColumns()

                        this.rerenderKeywordTable()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },

            sortCats(allCats) {
                if (!allCats) return null
                let sortedCats = []
                let catRoots = []
                let childCats = allCats.filter((x) => x.category_code_parent)
                for (let index = 0; index < allCats.length; index++) {
                    if (!allCats[index].category_code_parent) {
                        allCats[index].catlevel = 0
                        let rootArr = []
                        rootArr.push(allCats[index])
                        catRoots.push(rootArr)
                    }
                }
                var findChildren = (parent, catlevel) => {
                    // let parentCopy = parent
                    let childsOfRoot = childCats.filter((x) => x.category_code_parent == parent[catlevel].category_code)
                    catlevel++
                    if (childsOfRoot[0]) {
                        childsOfRoot[0].catlevel = catlevel
                        parent.push(childsOfRoot[0])
                        findChildren(parent, catlevel)
                        // if (childsOfRoot.length > 1) {
                        //     for (let index = 1; index < childsOfRoot.length; index++) {
                        //         let rootDouble = parentCopy
                        //         rootDouble.push(childsOfRoot[index])
                        //         catRoots.push(rootDouble)
                        //         findChildren(rootDouble, catlevel)
                        //     }
                        // }
                    } // else{
                    //     parent[catlevel].lastlvl = true
                    // }
                }
                for (let index = 0; index < catRoots.length; index++) {
                    findChildren(catRoots[index], 0)
                }
                catRoots.sort((a, b) => b.length - a.length)
                return catRoots
            },
            returnMonth(month) {
                let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

                return months.indexOf(month) + 1
            },

            calcScale(competitorData) {
                let scaleDown = false
                for (let index = 0; index < competitorData.length; index++) {
                    console.log(competitorData[index])
                    const reducer = (accumulator, currentValue) => accumulator + currentValue
                    let sum = competitorData[index].data.reduce(reducer)
                    if (sum > 999999) {
                        scaleDown = true
                    }
                }
                this.scaleDown = scaleDown
            },

            calCompetitorGraphs(data) {
                //groups data for graphs
                var labels = []
                var result = data
                    .reduce(
                        function (res, zeile) {
                            if (zeile.domain != null) {
                                if (!(zeile.domain in res)) res.__array.push((res[zeile.domain] = zeile))
                                else {
                                    res[zeile.domain].SumJanuary += zeile.January
                                    res[zeile.domain].SumFebruary += zeile.February
                                    res[zeile.domain].SumMarch += zeile.March
                                    res[zeile.domain].SumApril += zeile.April
                                    res[zeile.domain].SumMay += zeile.May
                                    res[zeile.domain].SumJune += zeile.June
                                    res[zeile.domain].SumJuly += zeile.July
                                    res[zeile.domain].SumAugust += zeile.August
                                    res[zeile.domain].SumSeptember += zeile.September
                                    res[zeile.domain].SumOctober += zeile.October
                                    res[zeile.domain].SumNovember += zeile.November
                                    res[zeile.domain].SumDecember += zeile.December
                                }
                            }

                            return res
                        },
                        { __array: [] }
                    )
                    .__array.sort(function (a, b) {
                        return a.SumApril - b.SumApril
                    })
                console.log(result)
                for (var i = 0; i < result.length; i++) {
                    //console.log('halloooo')
                    //console.log(result[i])
                    labels.push(result[i].domain)
                    let lineChartData = {
                        label: result[i].domain,
                        backgroundColor: "rgba(0,0,0,0)",
                        borderColor: this.getGraphColor(result[i].domain.replace("-", " ")),
                        pointBackgroundColor: "white",
                        borderWidth: 3.5,
                        pointBorderColor: "grey",
                        domain: result[i].domain,
                        data: [
                            result[i].SumJanuary,
                            result[i].SumFebruary,
                            result[i].SumMarch,
                            result[i].SumApril,
                            result[i].SumMay,
                            result[i].SumJune,
                            result[i].SumJuly,
                            result[i].SumAugust,
                            result[i].SumSeptember,
                            result[i].SumOctober,
                            result[i].SumNovember,
                            result[i].SumDecember
                        ]
                    }

                    this.dataChart.datasets.unshift(lineChartData)
                }
                this.calcScale(this.dataChart.datasets)
                this.createStatCards()
                this.genBrandKwGraph(labels) ///
                this.statCardsLoading = false
                this.dataChart.datasets[this.dataChart.datasets.length - 1].hidden = true
                // Hilfsvariable wird hochgezählt und Aktion in linechart.js ausgelöst
                this.addCompetitorGraph++
                this.loading = false
            },
            getGraphColor: function (competitor) {
                //console.log(competitor)
                let filtered = this.listdata.mdata.brands.filter((brand) => {
                    // let domainsplit = brand.domain.split('.')
                    if (brand.brand) {
                        return brand.brand.replace("-", " ") == competitor
                    }
                    return brand.domain.replace("-", " ") == competitor
                })
                if (filtered[0]) {
                    if (filtered[0].color) {
                        return filtered[0].color
                    }
                }
            },

            getRandomColor() {
                var min = Math.ceil(0)
                var max = Math.floor(255)
                var math1 = Math.floor(Math.random() * (max - min)) + min
                var math2 = Math.floor(Math.random() * (max - min)) + min
                var math3 = Math.floor(Math.random() * (max - min)) + min
                return "rgb(" + math1 + ", " + math2 + ", " + math3 + ")"
            },

            genBrandKwGraph(brands) {
                for (let index = 0; index < brands.length; index++) {
                    if (brands[index].includes("-")) {
                        brands[index] = brands[index].replace("-", " ")
                    }
                }
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/brands/keyword/multiple",
                        {
                            competitors: brands,
                            research: this.$route.params.brandindex,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(function (response) {
                        for (let index = 0; index < response.body.length; index++) {
                            let sum = 0

                            for (let i = 0; i < 12; i++) {
                                if (this.scaleDown) {
                                    sum += response.body[index].searchvolume[i].volume / 1000000
                                } else {
                                    sum += response.body[index].searchvolume[i].volume
                                }
                            }
                            let color = this.getGraphColor(response.body[index].keyword.replace("-", " "))
                            //console.log('Graph' + color)
                            this.pieChartData.datasets[1].data.unshift(sum.toFixed(2))
                            this.pieChartData.datasets[1].backgroundColor.unshift(color)
                            this.pieChartData.datasets[1].borderColor.unshift(color)
                            this.reloadPieChart++
                        }
                    })
            },
            calOverallGraph(rows) {
                let jan = 0,
                    feb = 0,
                    mar = 0,
                    apr = 0,
                    may = 0,
                    jun = 0,
                    jul = 0,
                    aug = 0,
                    sep = 0,
                    oct = 0,
                    nov = 0,
                    dec = 0
                for (let index = 0; index < rows.length; index++) {
                    jan += rows[index].January
                    feb += rows[index].February
                    mar += rows[index].March
                    apr += rows[index].April
                    may += rows[index].May
                    jun += rows[index].June
                    jul += rows[index].July
                    aug += rows[index].August
                    sep += rows[index].September
                    oct += rows[index].October
                    nov += rows[index].November
                    dec += rows[index].December
                }
                this.dataChart.datasets[0].data = [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
                this.totalsv = jan + feb + mar + apr + may + jun + jul + aug + sep + oct + nov + dec
                this.graphloaded = true
            },

            clearChart() {
                this.dataChart.datasets[0] = this.dataChart.datasets[this.dataChart.datasets.length - 1]
                this.dataChart.datasets.length = 1
                this.dataChart.datasets[0].hidden = false
                this.countGraph2++
            },
            generateColumnLabels(response) {
                let columnCounter = 5
                let monthArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                let longMonthArray = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                if (
                    response.body.keywordResponse.keywords[0].searchvolume[0].month !== longMonthArray[1] &&
                    response.body.keywordResponse.keywords[0].searchvolume[0].month !== longMonthArray[longMonthArray.length - 1]
                ) {
                    this.graphOptions.annotation.annotations[0].value =
                        response.body.keywordResponse.keywords[0].searchvolume[0].month + " " + response.body.keywordResponse.keywords[0].searchvolume[0].year
                }

                const researchHeaderIndex = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")

                for (let index = 11; index > -1; index--, columnCounter++) {
                    // console.log(index)
                    let header = {
                        active: true,
                        group: 1,
                        text: "Searches " + response.body.keywordResponse.keywords[0].searchvolume[index].month + "-" + response.body.keywordResponse.keywords[0].searchvolume[index].year,
                        modifiedText: "Searches " + response.body.keywordResponse.keywords[0].searchvolume[index].month + "-" + response.body.keywordResponse.keywords[0].searchvolume[index].year,
                        value: response.body.keywordResponse.keywords[0].searchvolume[index].month,
                        sortable: true,
                        width: "160"
                    }

                    this.headers.standardHeaders[researchHeaderIndex].columns.push(header)
                }
                if (this.headers.modifiedHeaders.length < 1 || this.headers.standardHeaders[researchHeaderIndex][0] !== this.headers.modifiedHeaders[researchHeaderIndex][0]) {
                    this.headers.modifiedHeaders = JSON.parse(JSON.stringify(this.headers.standardHeaders))
                }

                let sortedYears = []
                for (let index2 = 0; index2 < 12; index2++) {
                    if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "January") {
                        sortedYears[0] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "February") {
                        sortedYears[1] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "March") {
                        sortedYears[2] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "April") {
                        sortedYears[3] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "May") {
                        sortedYears[4] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "June") {
                        sortedYears[5] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "July") {
                        sortedYears[6] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "August") {
                        sortedYears[7] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "September") {
                        sortedYears[8] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "October") {
                        sortedYears[9] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "November") {
                        sortedYears[10] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    } else if (response.body.keywordResponse.keywords[0].searchvolume[index2].month == "December") {
                        sortedYears[11] = response.body.keywordResponse.keywords[0].searchvolume[index2].month + " " + response.body.keywordResponse.keywords[0].searchvolume[index2].year
                    }
                }
                this.dataChart.labels = sortedYears

                // this.$helpers.setSettingsDownloadHeader(this.headers);
            },
            setLineChartOptions() {
                this.graphOptions.tooltips = {
                    callbacks: {
                        label: function (tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].label + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toLocaleString()
                        }
                    }
                }
                this.graphOptions.scales = {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: "months"
                            }
                        }
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: false,
                                callback: function (value, index, values) {
                                    return value.toLocaleString()
                                }
                            },
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: "Google SV"
                            }
                        }
                    ]
                }
            }
        },
        watch: {
            totalsv: {
                handler: function () {
                    for (let index = 0; index < this.statsCards.length; index++) {
                        let ratio = (this.statsCards[index].ratio[0] / this.totalsv) * 100
                        let title = Math.round(ratio) + "% share of total SV"
                        this.statsCards[index].ratio.push(title)
                    }
                    this.statCardsLoading = false
                }
            },
            newStatCardCounter: {
                handler: function () {
                    this.totalsv++
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            // if (this.unfilteredKeywords.length) this.keywords = [...this.unfilteredKeywords]
            next()
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.headers.standardHeaders = JSON.parse(JSON.stringify(standardHeaders))

                //test end check
                if (vm.$store.state.company_status === "test_ended") {
                    vm.$router.push("/endoftest")
                }
                // if (vm.keywords.length) {
                //     vm.unfilteredKeywords = [...vm.keywords]
                //     vm.keywords = vm.$helpers.filterKeywordTable(vm.unfilteredKeywords)
                // }
            })
        },
        mounted() {
            this.checkIfNew()
            this.fetchResearch()
            this.setLineChartOptions()
        }
    }
</script>
<style lang="scss">
    .blob1 {
        position: absolute;
        top: 500px;
        left: 400px;
    }

    .blob {
        margin: 15px;
        cursor: pointer;
        background: rgb(66, 222, 250);
        border-radius: 50%;
        margin: 10px;
        height: 8px;
        width: 8px;
        box-shadow: 0 0 0 0 rgba(66, 222, 250, 1);
        transform: scale(1);
        animation-delay: 10s;
        animation: pulse 2s infinite;
        z-index: 1;
    }

    /* Tooltip text */
    .blob .blobtext {
        visibility: hidden;
        background-color: rgba(66, 222, 250, 0.7);
        background-image: linear-gradient(55deg, rgba(66, 152, 250, 0.7) 0%, rgba(66, 222, 250, 0.7) 50%);
        color: #fff;
        text-align: center;
        padding: 5px 5px;
        border-radius: 6px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }
    .blob .blobtext {
        opacity: 1 !important;
        // transition: opacity 1s;
        width: 220px;
        bottom: 170%;
        left: 50%;
        margin-left: -110px; /* Use half of the width (120/2 = 60), to center the tooltip */
    }
    .big {
        width: 300px !important;
        margin-left: -150px !important;
    }
    .xbig {
        width: 380px !important;
        margin-left: -190px !important;
    }
    .blob .blobtext::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: rgba(66, 222, 250, 0.7) transparent transparent transparent;
    }
    /* Show the tooltip text when you mouse over the tooltip container */
    .blob:hover .blobtext {
        opacity: 1;
        visibility: visible;
    }
    .blob:hover {
        box-shadow: 0 0 0 0 rgba(66, 222, 250, 0) !important;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(66, 222, 250, 0.9);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 40px rgba(66, 222, 250, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(66, 222, 250, 0);
        }
    }
</style>
<style scoped>
    #card-text-p {
        padding: 10px !important;
    }
    .headingsheet {
        margin-top: -15px;
    }
    .headingsheet2 {
        margin-top: -15px;
    }
    #searchsheet {
        margin-left: 5px !important;
        margin-top: -30px;
        width: 30px;
        height: 30px;
    }
    #tablesheet {
        padding-left: 5px;
        margin-top: -30px;
        width: 60px;
        height: 60px;
    }
    #tablesheet2 {
        margin-top: -50px;
        height: 60px;
    }
    .rounded-card {
        margin-top: 50px;
    }
    #sheeticon {
        margin-left: -25px;
        margin-top: -25px;
        margin: auto !important;
        padding-bottom: 50% !important;
        width: 50% !important;
    }
    .inline {
        display: inline !important;
    }
    .shortcol {
        max-width: 30% !important;
    }
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }
    .v-card__text {
        padding: 0px !important;
    }
    .v-card__title {
        padding-top: 0px;
    }
    #kwcountCard {
        padding-bottom: 10px !important;
    }

    .v-application .pa-12 {
        padding: 45px !important;
    }
    .v-application .px-5 {
        padding-right: 20px !important;
        /* padding-left: 20px !important; */
    }
    #divider {
        margin-top: 2px;
        border-color: rgb(194, 71, 71);
        border: 0;
        padding-left: -5px;
    }
    #heading3 {
        font-weight: 300 !important;
        color: rgb(105, 105, 105);
    }
    .v-card__subtitle {
        padding-bottom: 0px !important;
    }
    .chartCardHeading {
        padding-top: 23px;
        padding-left: 23px;
    }
    #cardtitle {
        padding-top: 10px;
        margin-left: 5px;
        padding-bottom: 15px;
    }
    #cardsubtitle {
        margin-left: 5px;
        padding-bottom: 10px !important;
    }
    .fabicon {
        margin-left: 10px;
        margin-top: 10px;
    }
    .circularprogress {
        padding-top: 120px;
        padding-bottom: 180px;
    }
    #subtitle {
        margin-top: -35px;
        color: rgb(126, 126, 126);
    }
    #buttonFlex {
        padding-left: 2px;
    }
    #lineChartSpinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37vh;
    }
    #pieChartSpinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 29vh !important;
    }
    #selectfiletype {
        padding-top: 25px !important;
        padding-bottom: 40px !important;
        padding-right: 30px !important;
        padding-left: 20px !important;
    }
    #title {
        padding-top: 16px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        color: #212529;
        font-size: 1em;
    }
    .labeldiv {
        width: 110px;
    }
</style>
