// USED IN BRANDINDEX

import { PolarArea, mixins } from "vue-chartjs"
const { reactiveProp } = mixins

export default {
    extends: PolarArea,
    mixins: [reactiveProp],
    props: ["options", "chartData", "reloadPieChart"],
    mounted() {
        for (let index = 0; index < this.chartData.datasets[0].length; index++) {
            let color = $helpers.getGraphColor(this.chartData.label[index])
            this.chartData.datasets[0].borderColor = color
            this.chartData.datasets[1].borderColor = color
            this.chartData.datasets[1].backgroundColor = color
        }

        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, this.options)
    },
    watch: {
        reloadPieChart: {
            handler: function () {
                // Neuladen der Chart
                this.$data._chart.update()
                this.renderChart(this.chartData, this.options)
            },
            deep: true
        }
    }
}
