<template>
    <v-dialog v-if="show" v-model="show" max-width="500px" transition="slide-x-transition" @click:outside="$emit('close')">
        <v-card>
            <v-card-text>
                <div v-if="loading">
                    <v-row class="loaderimg pt-5 mb-5">
                        <img class="ma-auto" id="loader" src="../../../assets/quaro-q.svg" />
                    </v-row>
                    <v-row class="loaderheading">
                        <span style="font-weight: 300; font-size: 16px" class="mx-auto">Waiting for AI...</span>
                    </v-row>
                    <v-row class="ma-auto">
                        <v-col>
                            <div id="loaderdiv">
                                <v-progress-linear rounded indeterminate height="6px" class="ma-auto" color="primary"></v-progress-linear>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="!loading && data.title && data.description">
                    <div class="serp-preview pt-5">
                        <div id="serp-title">
                            <span id="documentTitle">{{ data.title }}</span>
                        </div>
                        <!-- <div id="serp-url">
                                            {{ serp_preview.url }}
                                            <v-icon size="20" class="pl-0 ml-0">mdi-triangle-small-down</v-icon>
                                        </div> -->
                        <div id="serp-description">
                            <span id="documentDescription">{{ data.description }}</span>
                        </div>
                    </div>
                    <v-row>
                        <div class="d-flex pt-5">
                            <v-btn small class="ml-auto mr-1" text color="primary" @click="pasteContent">
                                <v-icon size="16px" class="mr-1">mdi-check</v-icon>
                                paste</v-btn
                            >
                            <v-btn small class="mr-auto ml-1" text color="primary" @click="generateMetaData()">
                                <v-icon size="16px" class="mr-1">mdi-refresh</v-icon>
                                regenerate</v-btn
                            >
                        </div>
                    </v-row>
                </div>
                <!-- <div v-if="data.title">Title: {{ data.title }}</div>
                <div v-if="data.description">Description: {{ data.description }}</div> -->

                <v-slide-x-transition hide-on-leave>
                    <div class="loaderwrapper" v-if="!loading && !data.title && !data.description">
                        <v-row class="loaderimg pt-7 mb-5">
                            <v-icon size="100" class="mx-auto" color="warning">mdi-alert-circle</v-icon>
                        </v-row>
                        <v-row>
                            <div class="flex">
                                <v-btn class="ml-auto mr-1" outlined color="grey" @click="$emit('close')">Cancel</v-btn>
                                <v-btn class="mr-auto ml-1" outlined color="grey" @click="generateMetaData()">Retry</v-btn>
                            </div>
                        </v-row>
                    </div>
                </v-slide-x-transition>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    //TODO: clear topic
    var intervalId = null
    import aiFunctions from "./aiFunctions"
    export default {
        props: {
            topic: String,
            projectLanguage: String,
            show: Boolean
        },
        data() {
            return {
                loading: false,
                data: {}
            }
        },
        computed: {},
        watch: {
            show(val) {
                console.log(val)
                console.log(this.topic)
                if (val && this.topic) {
                    this.generateMetaData()
                }
            }
        },
        methods: {
            async generateMetaData() {
                try {
                    const assistant = "meta_data_generator"
                    this.loading = true
                    this.data = {}
                    const response = await aiFunctions.adHocAssistantRun("Keyword: " + this.topic + "; Language: " + this.projectLanguage, assistant)
                    if (response.data.thread_id && response.data.run_id) {
                        this.checkRun(response.data.thread_id, response.data.run_id)
                    } else {
                        this.loading = false
                    }

                    console.log(response.data)
                } catch (error) {
                    this.loading = false
                    console.log(error)
                }
            },

            checkRun(currentThread, currentRun) {
                console.time("aifetch")
                if (currentThread && currentRun) {
                    // intervalId =  setInterval(getRun(), 1000)

                    intervalId = setInterval(async () => {
                        const response = await aiFunctions.checkRun(currentThread, currentRun)
                        if (response.data.status === "completed") {
                            console.timeEnd("aifetch")
                            window.clearInterval(intervalId)
                            try {
                                if (response.data.messages && response.data.messages.data[0]) {
                                    this.data = JSON.parse(response.data.messages.data[0].content[0].text.value)
                                }
                            } catch (error) {
                                console.log(error, response.data.messages.data[0].content[0].text.value)
                                this.data = {}
                            }

                            console.log(this.data)
                            this.loading = false
                            console.log("RUN-Status: ", response.data)
                        } else {
                            console.log("RUN-Status: ", response.data.status)
                        }
                    }, 500)
                }
            },

            pasteContent() {
                this.$emit("pasteContent", this.data)
            }
        },
        mounted() {
            this.data = {}
            this.loading = false
        }
    }
</script>

<style></style>
