<template>
    <div id="contentcontainter" class="standard-mode">
        <!-- Loader -->
        <!-- Loader -->
        <!-- Loader -->
        <div v-if="!projects">
            <div style="display: flex">
                <span class="circularprogress mx-auto">
                    <img id="loader" src="../../assets/quaro-q.svg" />
                </span>
            </div>
        </div>

        <v-slide-x-transition>
            <div v-if="true" class="flex mt-10">
                <v-card class="mx-auto" style="width: 100%">
                    <v-toolbar id="projects-tool-bar" flat :color="$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
                        <v-toolbar-items>
                            <v-btn text color="grey darken-2" @click="fetchProjects"> Internal Searches </v-btn>
                        </v-toolbar-items>
                        <div class="flex-grow-1"></div>

                        <template v-if="$vuetify.breakpoint.smAndUp">
                            <v-text-field
                                class="pr-5"
                                v-model="search"
                                rounded
                                dense
                                style="max-width: 400px"
                                filled
                                append-icon="mdi-magnify"
                                label="search..."
                                single-line
                                hide-details
                                id="kwsearch"></v-text-field>
                            <v-btn rounded class="ml-auto" @click="$router.push('/quickseo/internal-searches/setup')" color="primary" filled>
                                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                                new
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn rounded class="ml-auto" @click="$router.push('/quickseo/internal-searches/setup')" color="primary" filled>
                                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-toolbar>
                    <v-row no-gutters class="d-flex" style="flex-wrap: nowrap">
                        <!-- <v-divider v-if="showfolder" vertical style="border-color: rgb(237 239 241); border-width: 2px"></v-divider> -->
                        <v-col style="overflow-x: hidden">
                            <!-- projects -->
                            <v-card flat tile class="flexible-column" style="height: 100%">
                                <v-card-text class="grow pa-0">
                                    <v-data-table
                                        id="internal-searches-table"
                                        class="pl-0"
                                        v-if="true"
                                        fixed-header
                                        :search.sync="search"
                                        @click:row="onRowClick"
                                        :headers="headers"
                                        :loading="loading"
                                        :items="projects"
                                        :items-per-page="20"
                                        :sort-by="'created'"
                                        :sort-desc="true"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 50]
                                        }">
                                        <!-- header slot -->
                                        <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                                            <span :key="index">
                                                <span v-bind:style="{ width: header.width }">
                                                    <!-- Adding tooltips to header -->
                                                    <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="quarotblheader" style="font-size: 14px">
                                                                {{ header.text }}
                                                                <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                                            </span>
                                                        </template>
                                                        <span>
                                                            <v-row no-gutters>
                                                                <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                                            </v-row>
                                                            {{ header.tooltip }}
                                                        </span>
                                                    </v-tooltip>
                                                </span>
                                            </span>
                                        </template>
                                        <!-- Table Body -->
                                        <template v-slot:body="{ items }">
                                            <tbody v-if="items.length" style="color: rgb(60, 60, 60); font-weight: 400 !important">
                                                <tr v-for="(item, itemIndex) in items" :key="itemIndex" @click="onRowClick(item)">
                                                    <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="py-4">
                                                        <!-- ------- -->
                                                        <!-- title -->
                                                        <!-- ------- -->
                                                        <div v-if="header.value === 'domain'" class="pl-5">
                                                            <!-- <span style="font-size: 16px"> {{ item[header.value] }}</span> -->
                                                            <v-chip @click="" class="pl-2">
                                                                <img
                                                                    class="mr-1"
                                                                    style="width: 12; border-radius: 5px"
                                                                    :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item.domain + '&sz=12'"
                                                                    alt="" />
                                                                {{ item.domain }}
                                                            </v-chip>
                                                        </div>

                                                        <!-- -------------- -->
                                                        <!---- keyword_count ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'keyword_count'" style="text-transform: uppercase; white-space: nowrap">
                                                            {{ $helpers.formatNumber(item.keyword_count) }}
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- total_searches ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'total_searches'" style="text-transform: uppercase; white-space: nowrap" class="d-flex">
                                                            <span class="my-auto"> {{ item.monthly_searches ? $helpers.formatNumber(item.monthly_searches[0].search_volume) : 0 }}</span>
                                                            <span
                                                                class=""
                                                                v-if="item.monthly_searches && item.monthly_searches.length > 1"
                                                                v-bind="(data = { change: item.monthly_searches[0].search_volume - item.monthly_searches[1].search_volume })">
                                                                <v-chip class="px-2 ml-1" style="margin-top: 1px" small :color="data.change == 0 ? 'grey' : data.change > 0 ? 'primary' : 'error'">
                                                                    <v-icon color="white" small v-if="data.change > 0">mdi-arrow-up-thin</v-icon>
                                                                    <v-icon color="white" small v-if="data.change < 0">mdi-arrow-down-thin</v-icon>
                                                                    <v-icon color="white" small v-else>mdi-arrow-right-thin</v-icon>
                                                                    <span class="my-auto white--text"> {{ ((data.change / item.monthly_searches[1].search_volume) * 100).toFixed(2) }}%</span>
                                                                </v-chip>
                                                            </span>
                                                        </div>

                                                        <!-- -------------- -->
                                                        <!---- created ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'importDates'">
                                                            <span>{{ item.importDates ? item.importDates[0] : "" }}</span>
                                                        </div>
                                                        <div v-if="header.value === 'actions'" style="max-width: 50px !important">
                                                            <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" icon class="ml-auto">
                                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list rounded dense>
                                                                    <v-list-item
                                                                        @click="
                                                                            uploadDialog = true
                                                                            selectedProject = item
                                                                        ">
                                                                        <v-icon small class="mr-2">mdi-upload</v-icon><v-list-item-title>upload data</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item
                                                                        @click="
                                                                            selectedDeleteItem = item
                                                                            deleteDialog.show = true
                                                                        ">
                                                                        <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>delete</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </v-slide-x-transition>

        <v-dialog v-model="uploadDialog" max-width="600px" transition="dialog-transition">
            <v-card class="rounded_card">
                <v-btn absolute right class="mt-3" color="primary" icon @click="uploadDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-card-title class="quarolbl"> {{ selectedProject ? selectedProject.domain : "" }} </v-card-title>
                <v-card-subtitle>Data Import</v-card-subtitle>
                <v-card-text>
                    <div class="">
                        <div class="pb-4 qgreycard">
                            <div class="quarolbl pb-2 pb-2">Select the month for which you want to import data</div>
                            <v-menu v-model="datepickerMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details class="mt-2" rounded v-model="uploadDate" filled prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker :allowed-dates="disableExistingDates" v-model="uploadDate" color="primary" show-current="false" type="month"></v-date-picker>
                            </v-menu>
                        </div>
                    </div>
                    <div>
                        <FileUpload
                            v-if="uploadDate && uploadDialog"
                            :reset="resetFileUpload"
                            @success="uploadSuccess"
                            :data="uploadData"
                            @createNotification="createNotification"
                            :url="uploadUrl"
                            :project="selectedProject">
                        </FileUpload>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar top v-model="notification.show" :color="notification.color" v-if="notification">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
    </div>
</template>

<script>
    import FileUpload from "../../components/internal-searches/FileUpload.vue"
    export default {
        components: {
            FileUpload
        },
        data() {
            return {
                resetFileUpload: 0,
                datepickerMenu: false,
                uploadDate: "-",
                uploadUrl: process.env.VUE_APP_APIURL + "/internal-searches/upload/keywords",
                uploadDialog: false,
                selectedProject: null,
                projects: [], // Hier könnten deine Projekt-Daten sein
                search: "", // Hier kannst du die Suchbegriffe speichern
                loading: false, // Ein Ladeindikator, falls benötigt
                tableHeight: "400px", // Die Höhe deiner Tabelle
                search: "", // Die Suchanfrage
                showfolder: true, // Ein Boolean-Wert, der angibt, ob der Ordner angezeigt werden soll
                notification: {
                    show: false,
                    color: "",
                    icon: "",
                    message: ""
                },
                headers: [
                    {
                        text: "Domain",
                        value: "domain"
                    },

                    {
                        text: "Total Searches",
                        value: "total_searches"
                    },
                    {
                        text: "Keyword Count",
                        value: "keyword_count"
                    },
                    {
                        text: "Updated",
                        value: "importDates"
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        align: "end",
                        width: "50px"
                    }
                ]
            }
        },
        computed: {
            uploadData() {
                return { uploadDate: this.uploadDate }
            }
        },
        watch: {
            selectedProject(project) {
                let originalString = project.importDates[0]
                var parts = originalString.split("-")
                var year = parseInt(parts[0])
                var month = parseInt(parts[1])

                // Erstelle ein Date-Objekt
                var date = new Date(year, month - 1, 1) // Monate in JavaScript sind 0-basiert

                // Erhöhe den Monat um eins
                date.setMonth(date.getMonth() + 1)

                // Extrahiere das Jahr und den Monat aus dem Date-Objekt
                var newYear = date.getFullYear()
                var newMonth = date.getMonth() + 1 // Monate in JavaScript sind 0-basiert

                // Formatieren Sie Jahr und Monat zurück in einen String
                var newString = newYear + "-" + (newMonth < 10 ? "0" : "") + newMonth
                this.uploadDate = newString
            }
        },
        methods: {
            uploadSuccess() {
                this.resetFileUpload++
                this.uploadDate = ""
                this.uploadDialog = false
                this.fetchProjects()
            },
            disableExistingDates(val) {
                return !this.selectedProject.importDates.includes(val)
            },
            createNotification(message, icon, color) {
                this.$emit("createNotification", message, icon, color)
            },
            onRowClick(row) {
                console.log(row)
                this.$router.push({ name: "Internal Searches Project", params: { project: row } })
            },
            async fetchProjects() {
                try {
                    this.loading = true
                    const url = process.env.VUE_APP_APIURL + "/internal-searches/projects/"
                    let post_data = {
                        company_id: localStorage.company_id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.projects = response_data.data.projects.map((project) => {
                        project.monthly_searches = project.monthly_searches.sort(function (a, b) {
                            const dateA = new Date(a.date)
                            const dateB = new Date(b.date)
                            if (dateA < dateB) return 1
                            if (dateA > dateB) return -1
                            return 0
                        })
                        return project
                    })
                    console.log(this.projects)
                    this.noProjects = this.projects.length ? false : true
                    this.loading = false
                } catch (error) {
                    this.loading = false
                }
            },
            async deleteProject(item) {
                // const url = process.env.VUE_APP_APIURL + "/project/delete/one"
                let post_data = {
                    company_id: localStorage.company_id,
                    project_id: item._id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.status)
                this.fetchProjects()
            }
        },
        mounted() {
            this.fetchProjects()
            let year = new Date().getFullYear()
            let month = new Date().getMonth()
            console.log(month.toString().length)

            month = month.toString().length == 1 ? "0" + month.toString() : month
            this.uploadDate = year + "-" + month
        }
    }
</script>

<style scoped>
    #internal-searches-table th {
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px !important;
    }
    #internal-searches-table table > tbody > tr > td:last-child {
        background: var(--v-keywordcol-base) !important;
        border-left: 2px solid var(--v-primary-base);
    }
    #internal-searches-table table > tbody > tr > td:last-child,
    #internal-searches-table table > thead > tr > th:last-child {
        position: sticky !important;
        position: -webkit-sticky !important;
        right: 0 !important;
    }
    #internal-searches-table > div.v-data-table__wrapper > table > tbody > tr:hover {
        cursor: pointer;
    }
    .theme--dark #internal-searches-table span,
    .theme--dark #internal-searches-table div {
        color: white;
    }
</style>
