<template>
    <v-slide-y-transition>
        <v-combobox
            v-if="showInviteUser"
            v-model="selectedInvitedUser"
            :items="invitedUser"
            :search-input.sync="invitedUserSearch"
            hint='insert email e.g "user@yourdomain.com" - email must contain company domain'
            label="invite colleque"
            rounded
            filled
            prepend-icon="mdi-account-plus"
            multiple
            persistent-hint
            small-chips
            @input="deleteInvalid"
            :error-messages="shareboxError">
            <template v-slot:prepend-item>
                <v-list-item ripple v-if="selectedInvitedUser.length > 0" @click="emptyInviteBox">
                    <v-list-item-action>
                        <v-icon color="primary"> mdi-close-box </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title> delete all </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:no-data>
                <v-list-item v-if="invitedUserSearch">
                    <v-list-item-content>
                        <v-list-item-title>
                            Press <kbd>enter</kbd> to create the "<strong>{{ invitedUserSearch }}</strong
                            >" attribute
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-combobox>
    </v-slide-y-transition>
</template>

<script>
    export default {
        name: "InviteArea",

        props: {
            showInviteUser: Boolean
        },
        data: () => ({
            invitedUser: [],
            selectedInvitedUser: [],
            invitedUserSearch: "",
            shareboxError: "",
            companyUser: [],
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            dataFetched: false
        }),
        methods: {
            emptyInviteBox() {
                this.selectedInvitedUser = []
                this.invitedUserSearch = ""
            },
            async deleteInvalid() {
                console.log(this.selectedInvitedUser)
                if (this.selectedInvitedUser.length > 0) {
                    let user_found = false
                    for (let index = 0; index < this.companyUser.length; index++) {
                        console.log(this.selectedInvitedUser.indexOf(this.companyUser[index].user))
                        console.log(this.companyUser[index].user)
                        if (this.selectedInvitedUser.indexOf(this.companyUser[index].user) > -1) {
                            user_found = true
                            break
                        }
                    }

                    console.log(user_found)
                    if (!user_found) {
                        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.selectedInvitedUser[this.selectedInvitedUser.length - 1])) {
                            this.$http
                                .post(
                                    process.env.VUE_APP_APIURL + "/company/verificate/email",
                                    {
                                        user: this.selectedInvitedUser[this.selectedInvitedUser.length - 1]
                                    },
                                    { headers: { Authorization: this.auth } }
                                )
                                .then(function (response) {
                                    console.log(response)
                                    if (response.body.found == true) {
                                        this.shareboxError = ""
                                        this.$emit("setSelectedInvitedUser", this.selectedInvitedUser)
                                    } else {
                                        this.selectedInvitedUser.splice(this.selectedInvitedUser.length - 1, 1)
                                        this.shareboxError = "You can only invite people with company email!"
                                        // this.createInfo("You can only invite people with company email!", '#fcba03','mdi-alert-circle-outline')
                                    }
                                })
                        } else {
                            this.selectedInvitedUser.splice(this.selectedInvitedUser.length - 1, 1)
                            this.shareboxError = "Please enter a valid email!"
                            // this.createInfo('Please enter a valid email address', '#fcba03','mdi-alert-circle-outline')
                        }
                    } else {
                        this.selectedInvitedUser.splice(this.selectedInvitedUser.length - 1, 1)
                        this.shareboxError = "User is already registered!"
                    }
                }
            },
            fetchUser() {
                this.$http.post(process.env.VUE_APP_APIURL + "/user/companyuser", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } }).then(function (response) {
                    console.log(response)
                    this.companyUser = response.body
                })
            }
        },

        watch: {
            showInviteUser(val) {
                if (!this.dataFetched) {
                    if (val == true) {
                        this.fetchUser()
                        this.dataFetched = true
                    }
                }
            }
        }
    }
</script>
