<template>
    <v-dialog v-model="dialog" max-width="500px" transition="slide-x-transition" @click:outside="$emit('close')">
        <v-card>
            <div class="backsheet"></div>
            <v-btn class="close-btn" absolute top right icon color="primary" @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
            <v-card-text class="pt-5 pb-0">
                <div class="lbl pb-1">ENTER ADDITIONAL QUESTION</div>
                <v-text-field @keyup.enter="addQuestion" class="my-2" rounded filled v-model="question" hint="seperate new keywords with comma" persistent-hint></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn class="mx-auto" color="primary" @click="addQuestion" rounded>ok</v-btn>
            </v-card-actions>
        </v-card>

        <v-snackbar v-if="notification" top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
    </v-dialog>
</template>

<script>
    // import datasources from "../helper/datasources.js"
    // import locs from "../helper/locs"

    export default {
        props: {
            show: Boolean
        },
        data() {
            return {
                // selectedLocation: localStorage.selectedLocation ? JSON.parse(localStorage.selectedLocation) : null,
                // selectedLocation: locs.stateItems[locs.stateItems.findIndex((x) => x.loc == "Germany")],
                // selectedLocation: localStorage.selectedLocation ? JSON.parse(localStorage.selectedLocation) : locs.stateItems[locs.stateItems.findIndex((x) => x.loc == "Germany")],
                question: "",
                notification: null
            }
        },
        computed: {
            dialog() {
                return this.show
            }
        },
        methods: {
            trackEvent(component, action, param1, param2) {
                try {
                    this.$matomo.trackEvent(component, action, param1, param2)
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
            },
            async addQuestion() {
                try {
                    if (this.question == "") return

                    this.$emit("added", this.question)
                    this.$emit("close")

                    this.question = ""
                } catch (error) {
                    console.log(error)
                    this.$emit("failed")
                    this.question = ""
                }
            }
        }
    }
</script>

<style scoped>
    .close-btn {
        top: 5px;
        right: 5px;
    }
</style>
