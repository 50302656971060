<template>
    <v-dialog v-model="show" max-width="700px" @click:outside="$emit('close')">
        <v-card>
            <v-card-title>
                <span class="ml-1" style="text-transform: uppercase; font-weight: 100; color: rgb(59, 59, 59); font-size: 18px"> Add Keywords</span>
                <v-btn right absolute @click="$emit('close')" class="dialog-close-btn" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>
                <v-chip class="mt-1" color="primary" small outlined> {{ project_title }}</v-chip>
            </v-card-subtitle>
            <v-card-text>
                <!-- <v-tabs centered v-model="tab" color="primary" class="white--text">
                    <v-tab>Add to existing </v-tab>
                    <v-tab>Create new</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card-text> -->
                <div class="pb-4 qgreycard">
                    <div class="quarolbl pb-2">
                        Category
                        <v-tooltip max-width="250" color="" top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>Select the category to which the keywords should be added.</span>
                        </v-tooltip>
                    </div>
                    <v-autocomplete
                        placeholder="Select category..."
                        append-icon="mdi-chevron-down"
                        class="mt-2"
                        v-model="selectedCategory"
                        :items="categories"
                        rounded
                        item-text="category_id"
                        filled
                        hint="Type to search..."
                        return-object>
                        <template v-slot:selection="{ item, index }">
                            <span class="">
                                <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                                    <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                    <v-chip small> {{ cat }}</v-chip>
                                </span>
                            </span>
                        </template>
                        <template v-slot:item="data">
                            <span class="">
                                <span v-for="(cat, index) in data.item.category_id.split(';')" :key="cat">
                                    <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                    <v-chip small> {{ cat }}</v-chip>
                                </span>
                            </span>
                        </template>
                    </v-autocomplete>
                </div>

                <div style="position: relative">
                    <v-btn outlined rounded @click="addFromClipboard" small color="primary" text right style="right: 25px; top: 14px" absolute
                        ><v-icon class="mr-1" small>mdi-package-down</v-icon> from clipboard</v-btn
                    >
                </div>
                <div class="pb-4 qgreycard">
                    <div class="quarolbl pb-2">
                        Keywords
                        <v-tooltip max-width="250" color="" top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                            </template>
                            <span
                                ><b>Paste the keywords you want to upload.</b> <br />
                                You can paste them directly from a table or via the integrated quaro clipbloard!</span
                            >
                        </v-tooltip>
                    </div>
                    <v-textarea persistent-hint :hint="cleaned_keywords.length + ' keywords'" class="mt-2" label="Paste keywords here.." v-model="kw_textfield" rounded filled color="primary">
                    </v-textarea>
                </div>

                <div v-if="inputErrors.length">
                    <v-list>
                        <div v-for="(errorEntry, index) in inputErrors" :key="index">
                            <v-list-item dense three-line v-if="index < 3">
                                <v-list-item-content>
                                    <v-list-item-title> <v-icon small color="error" class="pb-1">mdi-alert-circle</v-icon> Row: {{ errorEntry.index + 1 }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ errorEntry.type }}!</v-list-item-subtitle>
                                    <v-list-item-subtitle> keyword: {{ errorEntry.keyword }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                        <div style="color: red" v-if="inputErrors.length > 3">and {{ inputErrors.length - 3 }} more... Rows: {{ inputErrors.map((e) => e.index + 1) }}</div>
                        <!-- <v-row>
                            <v-col>
                                <v-icon color="error">mdi-alert-circle</v-icon>
                                <span class="ml-1"> {{ errorEntry.type }}!</span>

                                <span class="ml-1">Keyword: {{ errorEntry.keyword }}</span>

                                <span class="ml-1">Row: {{ errorEntry.index }}</span>
                            </v-col>
                        </v-row> -->
                    </v-list>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn :disabled="uploadingDisabled" rounded :loading="uploading" class="mx-auto" color="primary" v-on:click="addKeywords">
                    <span v-if="!success">Add Keywords</span>
                    <v-icon class="px-10 mx-9" v-else>mdi-check-circle-outline</v-icon>
                </v-btn>
            </v-card-actions>
            <!-- </v-tab-item>
                    <v-tab-item>
                        <v-card-text>
                            <v-autocomplete
                                placeholder="Select parent category..."
                                append-icon="mdi-chevron-down"
                                class="mt-2"
                                v-model="newCategory.parent"
                                :items="categories"
                                rounded
                                filled
                                hint="Type to search..."
                                item-text="category_id"
                                return-object>
                                <template v-slot:selection="{ item, index }">
                                    <span class="">
                                        <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                                            <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                            <v-chip small> {{ cat }}</v-chip>
                                        </span>
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <span class="">
                                        <span v-for="(cat, index) in data.item.category_id.split(';')" :key="cat">
                                            <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                            <v-chip small> {{ cat }}</v-chip>
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                            <v-text-field placeholder="Category name..." class="mt-2" v-model="newCategory.name" rounded filled></v-text-field>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn rounded :loading="uploading" class="mx-auto" color="primary" v-on:click="createAndAssign()">Create and Assign</v-btn>
                        </v-card-actions>
                    </v-tab-item>
                </v-tabs-items> 
            </v-card-text>-->
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            keywords: Array,
            show: Boolean,
            category_id: String,
            isCategorized: Boolean,
            project_title: String,
            project: Object
        },
        data() {
            return {
                kw_textfield: "",
                categories: [],
                fetchingCats: false,
                uploading: false,
                success: false,
                selectedCategory: null,
                inputErrors: []
            }
        },
        computed: {
            uploadingDisabled() {
                if (this.inputErrors.length) return true
                if (this.cleaned_keywords.length == 0) return true
                if (this.isCategorized && this.selectedCategory == null) return true
                return false
            },
            cleaned_keywords() {
                try {
                    let input = this.splitInput(this.kw_textfield)
                    this.validateInput(input)
                    input = this.$helpers.replaceSpecialCharacters(input)
                    input = input.filter((e) => e.length)
                    return input
                } catch (error) {
                    console.log(error)
                }
            }
        },
        methods: {
            async fetchCategories() {
                try {
                    this.fetchingCats = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/categories"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.categories = response_data.data.categories.sort((a, b) => a.category_id.localeCompare(b.category_id))
                    console.log(this.categories)
                    this.selectedCategory = this.categories.find((c) => c.category_id == this.category_id)
                    this.fetchingCats = false
                } catch (error) {
                    console.log(error)
                }
            },
            async addKeywords() {
                try {
                    console.log(this.cleaned_keywords)
                    this.uploading = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/add/keywords/later"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id,
                        category: this.selectedCategory,
                        keywords: this.cleaned_keywords
                    }
                    console.log(post_data)
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                    if (response_data.data.error == "not_enough_credits") {
                        this.$emit("notify", {
                            label:
                                "You do not have enough credits left. Requested keyword updates: " +
                                (this.project.interval == 28 ? 1 : this.project.interval == 7 ? 4 : 30) * this.cleaned_keywords.length +
                                ". Remaining keyword updates: " +
                                (response_data.data.credits - response_data.data.count),
                            icon: "mdi-alert-circle-outline",
                            color: "#FCBA03"
                        })
                        this.uploading = false
                        return
                    }
                    setTimeout(() => {
                        this.uploading = false
                        this.$emit("uploaded")
                    }, 600)
                } catch (error) {
                    this.uploading = false

                    this.$emit("error", error)
                    console.log(error)
                }
            },
            splitInput(textfield) {
                // Remove new lines and komma
                let inputlines = textfield.split(String.fromCharCode(10))
                //console.log(inputlines)
                let input = []
                for (let inputlinesCounter = 0; inputlinesCounter < inputlines.length; inputlinesCounter++) {
                    let noKomma = inputlines[inputlinesCounter].split(",")
                    for (let noKommaCounter = 0; noKommaCounter < noKomma.length; noKommaCounter++) {
                        input.push(noKomma[noKommaCounter])
                    }
                }
                input = input.filter((e) => {
                    return e != false && e !== null && e !== " " && e !== ""
                })
                input = input.map((v) => v.toLowerCase())
                return input
            },
            validateInput(input) {
                console.log("validate")
                this.inputErrors = this.$helpers.validateUserInput(input)
                console.log(this.inputErrors)
                if (this.inputErrors.length) {
                    console.log(this.inputErrors)
                    let lines = this.inputErrors.reduce((lineString, entry) => lineString + (parseInt(entry.index) + 1) + ",", "")
                    console.log(lines)
                    this.$emit("notify", { label: "Please validate your input. Watch Keywords: " + lines, icon: "mdi-alert-circle-outline", color: "#FCBA03" })

                    return false
                }
                return input
            },
            addFromClipboard() {
                console.log("add from clipboard")
                console.log(this.$store.state.clipboard_keywords.map((k) => k.keyword).join(String.fromCharCode(10)))
                this.kw_textfield = this.$store.state.clipboard_keywords.map((k) => k.keyword).join(String.fromCharCode(10))
            }
        },
        watch: {
            show(val) {
                if (val) {
                    this.success = false
                    this.fetchCategories()
                }
            }
        },
        mounted() {}
    }
</script>

<style></style>
