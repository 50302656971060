<template>
    <div>
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <AddTopicsDialog
            :active="{ process: 'setup' }"
            :show="addTopicsDialogOptions.show"
            :project="project"
            @started="
                topic_fetch_loading = true
                addTopicsDialogOptions.show = false
            "
            @added="addFetchedTopics"
            @close="addTopicsDialogOptions.show = false"
            @failed="
                notification = $helpers.createNotification('Something went wrong fetching your keywords! Please try again or contact support.', 'mdi-alert', 'orange darken-2')
                topic_fetch_loading = false
            "></AddTopicsDialog>
        <div v-if="loading" class="loaderwrapper">
            <v-row class="loaderimg">
                <img id="loader" src="../../../assets/quaro-q.svg" />
            </v-row>
            <v-row class="loaderheading mt-10">
                <span style="font-weight: 300; font-size: 16px" class="mx-auto">{{ statusNotification.message }}...</span>
            </v-row>
            <v-row class="ma-auto">
                <v-col>
                    <div id="loaderdiv">
                        <v-progress-linear
                            :indeterminate="statusNotification.message ? false : true"
                            rounded
                            height="6px"
                            class="ma-auto"
                            color="primary"
                            :value="statusNotification.progress"></v-progress-linear>
                    </div>
                </v-col>
            </v-row>
            <v-col class="d-flex mt-10" v-if="statusNotification.type == 'error'">
                <v-btn color="primary" class="mx-auto" @click="$emit('retryProcess', 1)"> Retry <v-icon>mdi-refresh</v-icon> </v-btn>
            </v-col>
            <v-row class="mt-10">
                <p class="loadersubtext">
                    Note: Based on the main keyword you provided, we will now identify relevant keywords that you can serve with the same landing page. This usually only takes a few seconds.
                </p>
            </v-row>
        </div>
        <div v-else>
            <h2 style="font-weight: 300">Select Focus Keywords</h2>
            <p style="max-width: 1000px">
                Here you can see topics for which a subset of your competitors appear in Google search results. Choose the keyword that you want to cover with your website and for which you want to be
                found in the search results.
            </p>
            <p class="note" v-if="disabled"><v-icon class="pb-1" small color="primary">mdi-alert-circle</v-icon> Once the setup is finished, the selected focus keywords cannot be change!</p>
            <v-fade-transition>
                <div v-if="topic_chips.length" class="mb-5 topicsarea">
                    <h4 class="mb-1">Selected Topics:</h4>
                    <v-chip class="white--text mr-2" close v-for="(topic_chip, index) in topic_chips" :key="index" :color="topic_chip.color" @click:close="removeTopic(index)">{{
                        topic_chip.topic
                    }}</v-chip>
                </div>
            </v-fade-transition>
            <v-row no-gutters v-if="!disabled">
                <v-col cols="12" class="ml-auto">
                    <v-row class="ml-auto" no-gutters>
                        <v-btn rounded class="ml-auto mb-2" color="primary" @click="addTopicsDialogOptions.show = true" :loading="topic_fetch_loading">
                            <v-icon class="mr-1">mdi-plus</v-icon>add keywords</v-btn
                        >
                        <!-- <span class="mt-2 mx-3">or</span>
                        <v-btn rounded color="primary" @click="addFromClipboard">add from clipboard</v-btn> -->
                    </v-row>
                </v-col>
            </v-row>

            <div class="comp-table-area">
                <v-row class="pa-4">
                    <span>
                        <v-chip class="white--text" color="darkgreen" small> {{ selected.length }} selected</v-chip>
                    </span>

                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                        clearable
                        clear-icon="mdi-close"
                        dense
                        rounded
                        filled
                        v-model="search"
                        max-width="300px"
                        prepend-inner-icon="mdi-magnify"
                        label="search keywords..."
                        single-line
                        hide-details></v-text-field>
                </v-row>
                <v-data-table
                    readonly
                    single-expand
                    id="cs_topic_table"
                    single-select
                    :show-select="!disabled"
                    :custom-filter="customFilter"
                    :search="search"
                    v-model="selected"
                    fixed-header
                    height="600px"
                    :loading="!project.topics.length"
                    :headers="headers"
                    show-expand
                    item-key="keyword"
                    :expanded.sync="expanded"
                    :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
                    :items-per-page="25"
                    :items="calculatedTopics"
                    class="elevation-0 comp-table"
                    @input="handleSelection">
                    <!-- :sort-by="'similarity'"
                    :sort-desc="true" -->
                    <!-- header slot -->
                    <template v-slot:header.data-table-select>
                        <!-- Leeres Template für "Select All" Checkbox -->
                    </template>
                    <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                        <span :key="index">
                            <span v-bind:style="{ width: header.width }">
                                <!-- Adding tooltips to header -->
                                <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="quarotblheader">
                                            {{ header.text }}
                                            <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                        </span>
                                    </template>
                                    <span>
                                        <v-row no-gutters>
                                            <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                        </v-row>
                                        {{ header.tooltip }}
                                    </span>
                                </v-tooltip>
                            </span>
                        </span>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5">
                            <div><span class="quarolbl">Included Keywords:</span></div>
                            <span v-for="keyword in item.keywords">
                                <v-chip class="ma-1" color="black" outlined>{{ keyword.keyword }}: {{ $helpers.formatNumber(keyword.avg_search_volume) }}</v-chip>
                            </span>
                        </td>
                    </template>
                    <!-- Table Body -->

                    <!-- Keyword Slot -->
                    <template v-slot:item.keyword="{ item }">
                        <div class="td_keyword mb-1" style="font-size: 18px !important">
                            {{ item.keyword }}
                            <span class="ml-2">
                                <v-chip small outlined @click="expandItem(item)"> {{ item.keywords.length }} {{ item.keywords.length > 1 ? "Topic keywords" : "Topic keyword" }} </v-chip>
                            </span>
                            <!-- <span color="primary" class="qchip ml-3 mr-1" v-if="item.keyword === project.main_keyword">SELECTED TOPIC</span> -->
                        </div>
                        <!-- <div>
                           
                        </div> -->
                    </template>
                    <!-- Avgsv Slot -->
                    <template v-slot:item.calculated_search_volume="{ item }">
                        <v-chip style="background-color: #edeff1 !important" class="qchip" outlined color="primary">
                            <!-- {{ $helpers.formatNumber(item.keyword_info.search_volume) }} -->
                            {{ $helpers.formatNumber(item.calculated_search_volume) }}
                        </v-chip>
                    </template>
                    <!-- serp_info.keyword_difficulty Slot -->
                    <template v-slot:item.serp_info.keyword_difficulty="{ item }">
                        <v-progress-linear
                            v-if="item.serp_info"
                            style="border-radius: 15px; font-size: 14px; max-width: 100px; margin-left: auto"
                            class="white--text"
                            rounded
                            :value="item.serp_info.keyword_difficulty"
                            :color="item.serp_info.keyword_difficulty ? getColor(item.serp_info.keyword_difficulty) : 'green darken-2'"
                            height="30">
                            {{ getDifficulty(item.serp_info.keyword_difficulty) }}
                            <!-- {{ item.serp_info.keyword_difficulty || 0 }}% -->
                        </v-progress-linear>
                    </template>
                    <!-- Similarity Slot -->
                    <template v-slot:item.keyword_intent="{ item }">
                        <div>
                            <div style="display: table; width: 100%" class="py-3">
                                <div v-for="(intent, index) in all_intents" :key="index" style="display: table-row">
                                    <div style="display: table-cell; padding: 4px">{{ intent }}</div>
                                    <div style="display: table-cell; padding: 4px; align-content: center">
                                        <v-progress-linear
                                            style="border-radius: 15px; font-size: 11px; width: 50px; margin: auto"
                                            class="white--text"
                                            rounded
                                            :value="item.search_intent ? (item.search_intent[intent.toLowerCase()] ? item.search_intent[intent.toLowerCase()] * 100 : 0) : 0"
                                            color="primary"
                                            height="14">
                                            {{ item.search_intent ? (item.search_intent[intent.toLowerCase()] ? Math.ceil(item.search_intent[intent.toLowerCase()] * 100) + "%" : "0%") : 0 }}
                                        </v-progress-linear>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <v-chip outlined class="mr-1"> {{ item.keyword_intent.label + ":" + Math.ceil(item.keyword_intent.probability * 100) + "%" }}</v-chip>
                        <v-chip outlined class="mr-1" v-for="intent in item.secondary_keyword_intents" :key="intent.label">
                            {{ intent.label + ":" + Math.ceil(intent.probability * 100) + "%" }}</v-chip
                        > -->
                    </template>
                    <!-- Similarity Slot -->
                    <!-- <template v-slot:item.similarity="{ item }">
                        <v-progress-linear
                            style="border-radius: 15px; font-size: 14px; max-width: 100px; margin-left: auto"
                            class="white--text"
                            rounded
                            :value="Math.round(item.similarity * 100)"
                            :color="getColor(item.similarity)"
                            height="30">
                            {{ Math.round(item.similarity * 100) || 0 }}%
                        </v-progress-linear>
                    </template> -->
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    // import { colorCodes } from "../../helper/global.js"
    import AddTopicsDialog from "../../content-suite/AddTopicsDialog.vue"
    import SERPTypesCell from "../../keyword-table/SERPTypesCell.vue"
    import { all_serp_items } from "../../helper/serp_items.js"
    export default {
        components: {
            AddTopicsDialog,
            SERPTypesCell
        },
        props: {
            project: Object,
            loading: Boolean,
            statusNotification: Object,
            selectedTopics: Array,
            demo_project: Boolean
        },
        data: () => ({
            addTopicsDialogOptions: {
                show: false
            },
            selected: [],
            showAllSERPItems: false,
            topic_fetch_loading: false,
            addTopicDialog: false,
            addManually: false,
            colors: ["cyan darken-3", "purple darken-3", "lime darken-3", "light-green darken-3", "blue darken-3", "orange darken-3", "red darken-3"],
            topic_manual: "",
            topic_chips: [],
            search: "",
            expanded: [],
            headers: [
                {
                    text: "TOPIC",
                    value: "keyword",
                    sortable: true
                },
                {
                    text: "Ø SEARCH VOLUME",
                    value: "calculated_search_volume",
                    sortable: true,
                    align: "end"
                },
                {
                    text: "Difficulty",
                    value: "serp_info.keyword_difficulty",
                    sortable: true,
                    align: "end",
                    width: "200px"
                },
                // {
                //     text: "Topic Similarity",
                //     value: "similarity",
                //     sortable: true,
                //     align: "end"
                //     // width: "200px"
                // },
                {
                    text: "Search Intent",
                    value: "keyword_intent",
                    sortable: false,
                    align: "end",
                    width: "300px"
                },
                { text: "", value: "data-table-expand" }
                // {
                //     sortable: false,
                //     text: "SERP Items",
                //     value: "serp_items",
                //     tooltip: "SERP features represent the elements that constitute the search engine result page.",
                //     width: "150px",
                //     align: "left"
                // }
            ],
            all_intents: ["Informational", "Navigational", "Commercial", "Transactional"],
            notification: { show: false }
        }),

        methods: {
            customFilter(value, search, item) {
                // Standard-Filter auf andere Eigenschaften
                if (value && !Array.isArray(value) && value.toString().toLowerCase().includes(search.toLowerCase())) {
                    return true
                }

                // Durchsuche `item.keywords` Array
                if (item.keywords && Array.isArray(item.keywords)) {
                    return item.keywords.some((keyword) => keyword.keyword && keyword.keyword.toLowerCase().includes(search.toLowerCase()))
                }

                return false
            },
            getDifficulty(difficulty) {
                return difficulty > 70 ? "difficult" : difficulty < 30 ? "easy" : "medium"
            },
            expandItem(item) {
                console.log("expandItem")

                this.expanded.includes(item) ? this.expanded.splice(this.expanded.indexOf(item), 1) : this.expanded.push(item)
            },
            getColor(difficulty) {
                let colors = [
                    "green darken-2",
                    "light-green darken-2",
                    "light-green darken-2",
                    "lime darken-2",
                    "yellow darken-2",
                    "yellow darken-3",
                    "orange darken-2",
                    "orange darken-3",
                    "deep-orange darken-2",
                    "deep-orange darken-3"
                ]
                let index = Math.floor(difficulty / 10)

                // Maximaler Index darf nicht höher als die Länge des Arrays sein
                if (index >= colors.length) {
                    index = colors.length - 1
                }

                return colors[index]
                // if (reverse) {
                //     similarities = similarities.reverse()
                // }
                // // console.log(position)
                // let colorIndex = Math.round(similarity * 10) - 1
                // let color = similarities[colorIndex] ? similarities[colorIndex] : reverse ? similarities[0] : similarities[similarities.length - 1]
                // return color
            },
            getSERPItemsObject(serp_items) {
                console.log(serp_items)
                return serp_items.map((item) => all_serp_items.find((e) => e.value == item))
            },
            addFromClipboard() {
                if (!this.$store.state.clipboard_keywords.length) {
                    this.notification = this.$helpers.createNotification("Your clipboard is empty! Please push keywords to your clipboard first.", "mdi-alert", "orange darken-2")
                    return
                }

                // check if clipboard keywords and topics > 5, if yes unselect all topics
                let selected = this.project.topics.filter((topics) => topics.selected)
                if (selected.length + this.$store.state.clipboard_keywords.length > 5) {
                    this.project.topics = this.project.topics.map((x) => {
                        x.selected = false
                        return x
                    })
                }
                console.log(this.$store.state.clipboard_keywords)
                console.log(this.project.topics)
                let formatted_clipboard_keywords = this.$store.state.clipboard_keywords.map((x, i) => {
                    let keyword_object = {}
                    if (this.project.topics.findIndex((topic) => topic.keyword == x.keyword) == -1) {
                        keyword_object = {
                            keyword: x.keyword,
                            keyword_info: {
                                monthly_searches: x.search_volumes.map((y) => {
                                    y.year = parseInt(y.year)
                                    return y
                                }),
                                search_volume: x.avgsv
                            },
                            serp_info: {
                                keyword_difficulty: x.keyword_difficulty ? x.keyword_difficulty.difficulty : 0
                            },
                            selected: i < 5 ? true : false
                        }

                        keyword_object.keyword_info.monthly_searches.length = 12
                        this.project.topics.push(keyword_object)
                    }
                    return keyword_object
                })
                console.log(formatted_clipboard_keywords)
                this.notification = this.$helpers.createNotification("Keywords added from clipboard!", "mdi-check-circle-outline", "primary")
            },
            async addFetchedTopics(results, method, topic) {
                this.topic_fetch_loading = false
                if (!results.length) {
                    this.notification = this.$helpers.createNotification("We could not find any keywords for our query!", "mdi-alert", "orange darken-2")
                    return
                }

                // wenn auto -> regenerate topics with new keywords
                if (method == "auto") {
                    console.log("adding method auto")
                    // const url = process.env.VUE_APP_APIURL + "/cs/cluster"
                    // let post_data = {
                    //     company_id: localStorage.company_id,
                    //     user_id: localStorage.id,
                    //     langloc: this.project.langloc,
                    //     data: [...this.project.topics, ...results]
                    // }
                    // let response_data = await this.$helpers.axiosPost(url, post_data)
                    // console.log(response_data)
                }

                if (method == "manual") {
                    console.log("adding method manually")
                    let topicToAdd = this.project.topics.find((t) => t.keyword == topic)
                    results.map((result) => {
                        let existing = topicToAdd.keywords.findIndex((t) => t.keyword == result.keyword)
                        if (existing < 0) {
                            topicToAdd.keywords.push({ keyword: result.keyword, avg_search_volume: result.keyword_info.search_volume })
                        }
                    })
                }
                if (method == "new") {
                    let topic = {}
                    results = results.sort((a, b) => b.keyword_info.search_volume - a.keyword_info.search_volume)
                    topic = results[0]
                    //check if topic alrady exist
                    let existing = this.project.topics.findIndex((t) => t.keyword == topic.keyword)
                    console.log(existing)

                    if (existing > -1) {
                        topic = this.project.topics[existing]
                    } else {
                        topic.keywords = []
                        topic.serp_info = {}
                        topic.serp_info.keyword_difficulty = !topic.keyword_properties ? 0 : topic.keyword_properties.keyword_difficulty ? topic.keyword_properties.keyword_difficulty : 0
                        topic.keyword_info.monthly_searches.length = 12
                        topic.selected = false
                    }
                    console.log(topic)

                    results.map((result) => {
                        let existing = topic.keywords ? topic.keywords.findIndex((k) => k.keyword == result.keyword) : -1
                        if (existing < 0) {
                            topic.keywords.push({ keyword: result.keyword, avg_search_volume: result.keyword_info.search_volume })
                        }
                    })
                    console.log(topic)
                    if (existing < 0) {
                        this.project.topics.push(topic)
                    }
                }

                //TODO: wenn manually -> add keywords to selected topic

                // // check if clipboard keywords and topics > 5, if yes unselect all topics
                // let selected = this.project.topics.filter((topics) => topics.selected)
                // if (selected.length + results.length > 5) {
                //     this.project.topics = this.project.topics.map((x) => {
                //         x.selected = false
                //         return x
                //     })
                // }

                // let formatted_fetched_keywords = results.map((x, i) => {
                //     let keyword_object = x
                //     if (this.project.topics.findIndex((topic) => topic.keyword == x.keyword) == -1) {
                //         keyword_object.keyword_info.monthly_searches.length = 12
                //         if (!keyword_object.serp_info) keyword_object.serp_info = {}
                //         keyword_object.selected = i < 5 ? true : false
                //         keyword_object.keywords = [{ keyword: keyword_object.keyword, avg_search_volume: keyword_object.keyword_info.search_volume }]
                //         keyword_object.serp_info.keyword_difficulty = !x.keyword_properties ? 0 : x.keyword_properties.keyword_difficulty ? x.keyword_properties.keyword_difficulty : 0
                //         this.project.topics.push(keyword_object)
                //     }
                //     console.log(keyword_object)
                //     return keyword_object
                // })
                this.$emit("update")
                this.notification = this.$helpers.createNotification("Keywords added!", "mdi-check-circle-outline", "primary")
            },
            removeTopic(index) {
                let topicindex = this.project.topics.indexOf(this.topic_chips[index].topic)
                this.project.topics.splice(topicindex, 1)
            },
            selectItem(index, item) {
                let visibleIndex = this.project.topics.indexOf(item)
                // if (!this.project.topics[visibleIndex].selected) {
                //     this.project.topics[visibleIndex].selected = false
                // }
                if (this.selected.length == 5 && this.project.topics[visibleIndex].selected == false) {
                    this.notification = this.$helpers.createNotification("You cannot selet more than 5 focus topics!", "mdi-alert", "orange darken-2")
                    return
                }
                this.project.topics[visibleIndex].selected = !this.project.topics[visibleIndex].selected
                // this.$emit("updateKeywords", [item])
            },
            handleSelection(selectedItems) {
                if (selectedItems.length > 5) {
                    this.notification = {
                        show: true,
                        color: "warning",
                        icon: "mdi-alert-circle",
                        message: `You can only select up to 5 topics!`
                    }

                    // Entferne das letzte ausgewählte Element, wenn die Grenze überschritten wird
                    selectedItems.pop()
                }

                // Aktualisiere die Auswahl
                this.selected = selectedItems

                // Wenn nötig, aktualisiere die `selected`-Eigenschaft der Topics (wenn du dies synchronisieren möchtest)
                this.syncSelectionWithTopics()
            },
            syncSelectionWithTopics() {
                // Setze `selected: true` für die ausgewählten Items
                this.project.topics.forEach((topic) => {
                    topic.selected = this.selected.some((item) => item.keyword === topic.keyword)
                })
            },
            selectPage(event) {
                const selectedItems = event.items.slice(0, this.maxSelections) // Begrenze die Auswahl auf 5
                this.selected = selectedItems

                this.syncSelectionWithTopics() // Synchronisiere die Auswahl
            }
        },
        computed: {
            disabled() {
                return this.demo_project ? false : this.project ? this.project.setup.finished : true
            },
            // Berechne das Suchvolumen für jedes Thema
            calculatedTopics() {
                return this.project.topics.map((topic) => {
                    const totalSearchVolume = topic.keywords
                        ? topic.keywords.reduce((sum, keyword) => {
                              // Überprüfen, ob avg_search_volume vorhanden und eine Zahl ist
                              const avgVolume = keyword.avg_search_volume || 0 // Setze auf 0, wenn nicht vorhanden oder null
                              return sum + avgVolume // Addiere den Wert
                          }, 0)
                        : topic.avg_search_volume || 0 // Setze auf 0, wenn nicht vorhanden oder null
                    if (topic.keyword_intent) {
                        topic.search_intent = {}
                        topic.search_intent[topic.keyword_intent.label] = topic.keyword_intent.probability
                        if (topic.secondary_keyword_intents) {
                            topic.secondary_keyword_intents.map((sec_int) => (topic.search_intent[sec_int.label] = sec_int.probability))
                        }
                    }
                    return {
                        ...topic,
                        calculated_search_volume: totalSearchVolume // Neues Feld für das berechnete Suchvolumen
                    }
                })
            }
        },
        watch: {
            selected(newValue, oldValue) {
                // Set "selected: true" for newly selected items
                newValue.forEach((item) => {
                    const topic = this.calculatedTopics.find((topic) => topic.keyword === item.keyword)
                    if (topic) {
                        topic.selected = true
                        this.project.main_keyword = topic.keyword
                    }
                })

                // Set "selected: false" for deselected items
                oldValue.forEach((item) => {
                    if (!newValue.includes(item)) {
                        const topic = this.calculatedTopics.find((topic) => topic.keyword === item.keyword)
                        if (topic) {
                            topic.selected = false
                        }
                    }
                })
            },
            project: {
                handler(newValue, oldValue) {
                    // if (this.project.topics.length) {
                    //     console.log(this.project.topics)
                    //     this.selected = this.project.topics.filter((t) => t.selected)
                    // }
                    console.log("WATCHHHHHHHHHHHHHH")
                    // this.topic_chips = this.selectedTopics.map((topic, index) => {
                    //     let chip = { topic: topic.keyword, color: this.colors[index] }
                    //     return chip
                    // })
                },
                deep: true
            }
        }
    }
</script>

<style scoped lang="scss">
    #cs_topic_table > div.v-data-table__wrapper > table > thead > tr > th {
        text-transform: uppercase !important;
    }
    h4 {
        font-weight: 300;
        margin-bottom: 10px;
    }
    .topicsarea {
        padding: 7px 12px;
        border-radius: 5px;
        color: black;
        background-color: #f2f2f2;
        &:hover {
            background-color: #e2e2e2;
        }
    }

    .loaderwrapper {
        padding: 50px;
    }

    .loaderimg {
        margin: auto;
    }
    #loader {
        margin: auto;
    }
    .highlighted {
        background-color: rgb(229, 229, 229);
    }
    .theme--dark .highlighted {
        background-color: rgb(122, 122, 122);
    }
    .note {
        font-size: 12px;
    }
</style>
<style lang="scss">
    #loaderdiv {
        margin: auto;
        max-width: 300px;
    }
    .loadersubtext {
        text-align: center;
        max-width: 700px;
        margin: auto;
        color: grey;
        font-size: 12px;
        &.highlighted {
            color: var(--v-primary-base);
            font-weight: bold;
            font-size: 14px;
        }
    }
    #cs_topic_table .v-progress-linear__background {
        opacity: 0.5 !important;
    }
    .v-data-table__expanded v-data-table__expanded__content {
        box-shadow: none;
    }
</style>
