<template>
    <div class="projects-table">
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon>
            <span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>

        <EditProject :editDialog="editProjectDialog" :all_collections="researches" :project_data="selectedProject" @close="editProjectDialog = false" @renewData="renewData"></EditProject>
        <DeleteDialog
            :deleteDialog="deleteDialog"
            :selectedDeleteItem="selectedDeleteItem ? selectedDeleteItem.project : null"
            deleteLabel="Project"
            :loading="deleteLoading"
            @delete="deleteProject"
            @close="deleteDialog = false"></DeleteDialog>
        <AddProjectsDialog :all_collections="researches" :projectsDialog="projectsDialog" @created="$emit('renewData')" @close="projectsDialog = false"></AddProjectsDialog>
        <ProjectsBanner
            v-if="projects.length < 1"
            class="mt-5 mb-10"
            @showAddDialog="
                {
                    projectsDialog = true
                }
            "></ProjectsBanner>
        <v-card v-else class="rounded_card quaroshadow px-5 py-3 my-8 mb-10">
            <v-row no-gutters>
                <v-sheet color="primary" max-height="200" class="pa-12 quaroshadow rounded_card" dark top id="tablesheet" elevation="6">
                    <div class="headingsheet2">
                        <v-icon :light="this.$vuetify.theme.dark ? true : false" id="sheeticon" large>mdi-folder-table</v-icon>
                    </div>
                </v-sheet>
                <v-card-title id="heading3"> All Projects </v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="projectsSearch"
                    style="max-width: 400px"
                    rounded
                    filled
                    dense
                    append-icon="mdi-magnify"
                    label="Search for project"
                    single-line
                    hide-details
                    id="kwsearch"></v-text-field>
            </v-row>
            <!-- <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn absolute right fab dark small @click="showResearchDialog = true" color="primary" class="fabicon" v-bind="attrs" v-on="on">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>create a project</span>
                </v-tooltip> -->
            <v-tooltip v-if="$helpers.isActionAllowed(30, user_rights)" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" absolute right fab dark small @click="projectsDialog = true" color="primary" class="fabicon">
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>create a project</span>
            </v-tooltip>
            <!-- <v-divider class="mt-5"></v-divider> -->
            <v-data-table
                id="projtable"
                :headers="headers"
                :loading="loading"
                :items="projects"
                :single-expand="singleExpand"
                :search.sync="projectsSearch"
                :expanded.sync="expanded"
                :sort-by.sync="projects_sort_by"
                :sort-desc.sync="projects_sort_desc"
                :items-per-page.sync="projects_items_per_page"
                item-key="_id"
                show-expand
                expand-icon="mdi-chevron-down"
                class="row-pointer mt-5"
                multi-sort>
                <!-- header slot -->
                <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                    <span :key="index">
                        <span v-bind:style="{ width: header.width }">
                            <!-- Adding tooltips to header -->
                            <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="quarotblheader">
                                        {{ header.text }}
                                        <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                    </span>
                                </template>
                                <span>
                                    <v-row no-gutters>
                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                    </v-row>
                                    {{ header.tooltip }}
                                </span>
                            </v-tooltip>
                        </span>
                    </span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="px-0">
                        <SimpleResearchTable
                            @renewData="renewData"
                            @removeCollection="removeCollectionFromTable"
                            @openShareMenu="openShareMenu"
                            @initChangeNameDialog="initChangeNameDialog"
                            :researches="getResearches(item)"></SimpleResearchTable>
                    </td>
                </template>
                <template v-slot:item.description="{ item }">
                    <div style="max-width: 800px" class="py-2">{{ item.description }}</div>
                </template>
                <template v-slot:item.project="{ item }">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="project-title mb-1">{{ item.project }}</v-list-item-title>
                            <v-list-item-subtitle class="updated-subtitle" style="display: flex"
                                ><v-icon x-small class="pr-1">mdi-clock-outline</v-icon>
                                <span>
                                    Updated
                                    {{ item.updated_text }}
                                </span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:item.owner="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar v-on="on" color="primary" size="30" class="pr-0 avat">
                                <span class="white--text">{{ getInitials(item.owner_name) }}</span>
                            </v-avatar>
                        </template>
                        <span>{{ item.owner_name }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-menu rounded="lg" color="primary" transition="slide-y-transition" bottom v-if="item.status != 'creating' && item.status != 'updating'">
                        <template v-slot:activator="{ on }">
                            <v-btn class="ml-auto" v-if="$helpers.isActionAllowed(32, user_rights) || $helpers.isActionAllowed(31, user_rights)" id="cogbtn" icon v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list rounded dense>
                            <!-- <v-list-item
                                v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(4, user_rights)"
                                @click="openShareMenu(item)">
                                <v-icon small class="mr-2">mdi-share-variant</v-icon><v-list-item-title>Share</v-list-item-title>
                            </v-list-item> -->
                            <v-list-item v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(32, user_rights)" @click="showEditProjectDialog(item)">
                                <v-icon small class="mr-2">mdi-pencil</v-icon><v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(31, user_rights)" @click="showDeleteDialog(item)">
                                <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:item.lists="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" small icon class="mr-4"> <v-icon small>mdi-file-multiple-outline</v-icon>{{ item.lists.length }} </v-btn>
                        </template>
                        <span>{{ item.lists.length }} assigned collections</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" small icon class="mr-4"> <v-icon small>mdi-share-variant</v-icon>{{ item.shared_count }} </v-btn>
                        </template>
                        <span>{{ item.shared_count }} shared collections</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" small icon> <v-icon small>mdi-comment-text-outline</v-icon>{{ item.comments_count }}</v-btn>
                        </template>
                        <span>{{ item.comments_count }} comments</span>
                    </v-tooltip>
                    <!-- <v-btn small icon> <v-icon small>mdi-star</v-icon></v-btn> -->
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import ProjectsBanner from "./ProjectsBanner"
    import SimpleResearchTable from "./SimpleResearchTable"
    import EditProject from "./EditProject"
    import DeleteDialog from "../dialogs/DeleteDialog"
    import AddProjectsDialog from "./AddProjects"

    export default {
        name: "ProjectsTable",
        props: {
            researches: Array,
            projects: Array,
            loading: Boolean
        },
        components: {
            ProjectsBanner,
            SimpleResearchTable,
            EditProject,
            DeleteDialog,
            AddProjectsDialog
        },
        data() {
            return {
                projects_sort_desc: [true],
                projects_sort_by: ["count"],
                projects_items_per_page: 10,
                projectsDialog: false,
                notification: { show: false },
                deleteLoading: false,
                selectedDeleteItem: null,
                deleteDialog: false,
                selectedProject: { project: "", description: "" },
                editProjectDialog: false,
                expanded: [],
                singleExpand: true,
                headers: [
                    { text: "", value: "data-table-expand" },
                    {
                        text: "Project",
                        align: "start",
                        sortable: true,
                        value: "project"
                    },
                    { text: "", sortable: false, value: "lists", width: 175 },
                    { text: "Description", value: "description", width: 800 },
                    { text: "Owner", value: "owner" },
                    {
                        sortable: false,
                        text: " ",
                        value: "action",
                        width: 50,
                        align: "end"
                    }
                ],
                projectsSearch: null,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
            }
        },

        watch: {
            projects_sort_desc() {
                console.log(this.projects_sort_desc)
                this.user_settings.collections_projects_sort.sort = this.projects_sort_desc
                this.user_settings.collections_projects_sort.column = this.projects_sort_by

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            projects_sort_by() {
                console.log(this.projects_sort_by)
                // this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            projects_items_per_page() {
                console.log(this.projects_items_per_page)
                this.user_settings.collections_projects_rowsperpage = this.projects_items_per_page

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            }
        },
        methods: {
            initChangeNameDialog(item) {
                this.$emit("initChangeNameDialog", item)
            },
            openShareMenu(item) {
                this.$emit("openShareMenu", item)
            },
            removeCollectionFromTable(list_id, project) {
                this.$emit("removeCollection", list_id, project)
            },
            getInitials(name) {
                let splittedName = name.split(" ")
                let initials = ""
                splittedName.forEach((namepart) => {
                    let firstletter = namepart.substring(0, 1)
                    initials += firstletter
                })
                return initials
            },
            renewData() {
                this.$emit("renewData")
            },
            showDeleteDialog(item) {
                this.selectedDeleteItem = item
                this.deleteDialog = true
            },
            showEditProjectDialog(item) {
                console.log(item)
                this.selectedProject = JSON.parse(JSON.stringify(item))
                this.editProjectDialog = true
            },
            getResearches(project) {
                if (this.expanded.indexOf(project) > -1) {
                    console.log(this.researches)
                    var project_researches = this.researches.filter((research) => project.lists.indexOf(research._id) > -1)
                    console.log(project_researches)
                    return project_researches
                }
            },
            deleteProject() {
                console.log(this.selectedDeleteItem)
                this.deleteLoading = true
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/projects/delete",
                        {
                            company_id: localStorage.company_id,
                            project_id: this.selectedDeleteItem._id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(function (response) {
                        console.log(response)
                        if (response.body.deleted) {
                            this.notification = this.$helpers.createNotification("The Project has been deleted successfully!", "mdi-check-circle-outline", "success")
                            this.$emit("renewData")
                            this.deleteDialog = false
                        } else {
                            this.notification = this.$helpers.createNotification("Something went wrong! Please try again or contact the support.", "mdi-alert", "errorred")
                        }
                        this.deleteLoading = false
                    })
                    .catch((err) => {
                        console.log(err)
                        this.deleteLoading = false
                        this.notification = this.$helpers.createNotification("Something went wrong! Please try again or contact the support.", "mdi-alert", "errorred")
                    })
            }
        },
        computed: {
            user_rights() {
                return this.$store.state.user_rights
            },
            initials() {
                let splittedName = this.decryptedName.split(" ")
                let initials = ""
                splittedName.forEach((namepart) => {
                    let firstletter = namepart.substring(0, 1)
                    initials += firstletter
                })
                return initials
            },
            decryptedName() {
                if (localStorage.username.length > 0) {
                    return this.CryptoJS.AES.decrypt(localStorage.username, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            decryptedId() {
                if (localStorage.id) {
                    return this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            user_settings() {
                return this.$store.state.user_settings
            }
        },
        mounted() {
            // this.fetchProjects()
            this.projects_sort_desc = this.user_settings.collections_projects_sort.sort
            this.projects_sort_by = this.user_settings.collections_projects_sort.column
            this.projects_items_per_page = this.user_settings.collections_projects_rowsperpage
        }
    }
</script>

<style>
    .updated-subtitle {
        font-size: 0.8em;
    }
    .bold {
        font-weight: bold;
    }
    .welcommessage {
        font-family: Nunito;
        font-weight: 100;
        padding-bottom: 0px;
    }
    #welcomecard {
        padding: 15px;
        color: white;
        background: linear-gradient(56deg, rgba(28, 237, 178, 1) 0%, rgba(0, 159, 138, 1) 0%, rgb(28, 237, 178) 100%);
    }
    .welcometitle {
        font-family: Nunito;
        font-weight: 100;
        text-align: center;
    }
    .subtitle {
        text-align: center;
        font-family: Nunito;
    }
    .chiptext {
        font-weight: bold;
        color: var(--v-primary-base);
    }
    .sitoquaro div {
        display: inline;
        padding: 10px;
    }
    .sitoquaro {
        text-align: center;
        margin-left: auto;
    }
    #tablesheet {
        margin-left: 5px;
        margin-top: -30px;
        width: 60px;
        height: 60px;
    }
    #heading3 {
        font-weight: 300 !important;
        color: rgb(105, 105, 105);
    }
    .headingsheet2 {
        margin-top: -15px;
    }
    #sheeticon {
        margin-left: -25px;
        margin-top: -25px;
        margin: auto !important;
        padding-bottom: 50% !important;
        width: 50% !important;
    }
    .project-title {
        font-weight: 100;
        font-size: 1.2em;
    }

    #projtable table > tbody > tr > td:last-child {
        background: var(--v-keywordcol-base) !important;
        border-left: 2px solid var(--v-primary-base);
    }
    #projtable table > tbody > tr > td:last-child,
    #projtable table > thead > tr > th:last-child {
        position: sticky !important;
        position: -webkit-sticky !important;
        right: 0 !important;
    }
    .avat {
        background: linear-gradient(56deg, var(--v-left_nav_gradient1-base) 0%, var(--v-left_nav_gradient3-base) 100%);
        font-size: 0.9em;
    }
</style>
