<template>
    <div>
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="showInfo">
            <v-icon>{{ info.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <v-data-table
            id="simple-projects-table"
            class="row-pointer"
            hide-default-footer
            :footer-props="{ 'items-per-page-options': [50, 100, 250] }"
            :items-per-page="-1"
            :headers="researchHeaders"
            :items="researches"
            :sort-by.sync="project_sort_by"
            :sort-desc.sync="project_sort_desc">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.listname">
                        <td @click="onColumnClick(item)">
                            <div>
                                {{ item.listname }}
                            </div>
                        </td>
                        <td @click="onColumnClick(item)">
                            <div v-if="item.status != 'creating' && item.status != 'updating'">
                                <v-chip small outlined color="primary" :style="'background-color: rgb(237 239 241 /' + ($vuetify.theme.light == false ? '35%)' : '10%)') + '!important'">
                                    <v-icon class="mr-1" color="grey" size="12px">mdi-key</v-icon>
                                    <span class="researchKwCount"> {{ $helpers.formatNumber(item.count) }}</span>
                                </v-chip>
                            </div>
                            <div v-else>
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </div>
                        </td>
                        <td @click="onColumnClick(item)">
                            <div v-if="item.status !== 'creating' && item.status != 'updating'">
                                <v-row no-gutters>
                                    <v-col class="d-flex">
                                        <v-sparkline
                                            class="my-auto"
                                            style="max-width: 110px; max-height: 40px"
                                            id="sparkline"
                                            color="primary"
                                            :value="item.trendData.data"
                                            padding="2"
                                            smooth="20"
                                            gradient-direction="top"
                                            auto-draw
                                            :fill="false"></v-sparkline>
                                        <v-sparkline
                                            class="my-auto"
                                            style="max-width: 110px; max-height: 40px; position: absolute"
                                            id="sparkline"
                                            color="primary"
                                            :value="item.trendData.data"
                                            :gradient="gradient"
                                            padding="2"
                                            smooth="20"
                                            gradient-direction="top"
                                            auto-draw
                                            :fill="true"></v-sparkline>
                                        <v-row outlined small class="qchip my-auto pl-3" v-if="item.trendData.change">
                                            <span :style="'color: ' + item.trendData.icon_color" class="my-auto ml-2">{{ Math.abs(item.trendData.change) + "%" }} </span>

                                            <v-icon size="16px" :color="item.trendData.icon_color">{{ item.trendData.icon }}</v-icon>
                                        </v-row>
                                        <v-row outlined small class="qchip my-auto pl-3" v-else>
                                            <span :style="'color: grey'" class="my-auto ml-2">{{ Math.abs(item.trendData.change) + "%" }} </span>
                                        </v-row>
                                    </v-col></v-row
                                >
                            </div>
                            <div v-else>
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </div>
                        </td>
                        <td @click="onColumnClick(item)">
                            <div v-if="item.status != 'creating' && item.status != 'updating'">
                                <v-row>
                                    <v-col class="ma-auto" style="display: flex">
                                        <v-icon class="my-auto mr-1" small>mdi-map-marker</v-icon>
                                        {{ item.country }}</v-col
                                    >
                                </v-row>
                            </div>
                            <div v-else>
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </div>
                        </td>
                        <td @click="onColumnClick(item)">
                            <div v-if="item.status != 'creating' && item.status != 'updating'" class="d-flex">
                                <v-icon class="my-auto mr-1" small>mdi-clock-time-eight</v-icon>
                                <span v-if="item.created">{{ $helpers.returnResearchDate(item.created) }}</span>
                            </div>
                            <div v-else>
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </div>
                        </td>

                        <td>
                            <!-- <v-btn icon small @click="deleteListFromProject(item._id, item.project)"
                                ><v-icon small>mdi-close</v-icon></v-btn
                            > -->

                            <v-menu rounded="lg" color="primary" transition="slide-y-transition" bottom v-if="item.status != 'creating' && item.status != 'updating'">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="
                                            $helpers.isActionAllowed(2, user_rights) ||
                                            $helpers.isActionAllowed(3, user_rights) ||
                                            $helpers.isActionAllowed(4, user_rights) ||
                                            (decryptedId == item.owner && $helpers.isActionAllowed(28, user_rights)) ||
                                            $helpers.isActionAllowed(34, user_rights)
                                        "
                                        id="cogbtn"
                                        icon
                                        v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list rounded dense>
                                    <v-list-item v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(4, user_rights)" @click="$emit('openShareMenu', item)">
                                        <v-icon small class="mr-2">mdi-share-variant</v-icon><v-list-item-title>Share</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(2, user_rights)" @click="$emit('initChangeNameDialog', item)">
                                        <v-icon small class="mr-2">mdi-square-edit-outline</v-icon><v-list-item-title>Rename</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item
                                        v-if="
                                            item.status != 'creating' &&
                                            item.status != 'updating' &&
                                            ($helpers.isActionAllowed(3, user_rights) || (decryptedId == item.owner && $helpers.isActionAllowed(28, user_rights)))
                                        "
                                        @click="deleteListFromProject(item._id, item.project)">
                                        <v-icon small class="mr-2">mdi-close</v-icon><v-list-item-title>Remove</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    export default {
        name: "SimpleResearchTable",
        props: {
            researches: Array
        },
        data() {
            return {
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],

                researchHeaders: [
                    {
                        text: "RESEARCH",
                        value: "listname"
                    },
                    {
                        text: "KEYWORDS",
                        value: "count"
                    },
                    {
                        text: "TREND (GOOGLE SV)",
                        value: "trendData.change",
                        algin: "center"
                    },
                    {
                        text: "COUNTRY",
                        value: "country"
                    },
                    {
                        text: "CREATED",
                        value: "created"
                    },

                    {
                        sortable: false,
                        text: " ",
                        value: "action"
                    }
                ],
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                info: {
                    message: null,
                    icon: null,
                    color: null
                },
                showInfo: false,
                project_sort_desc: [true],
                project_sort_by: ["count"]
            }
        },
        watch: {
            project_sort_desc() {
                console.log(this.project_sort_desc)
                this.user_settings.collections_projects_project_sort.sort = this.project_sort_desc
                this.user_settings.collections_projects_project_sort.column = this.project_sort_by

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            project_sort_by() {
                console.log(this.project_sort_by)
                // this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            }
        },
        methods: {
            onColumnClick(item) {
                event.stopPropagation()
                if (item.status == "creating") {
                    this.createInfo("This collection is currently being created", "#fcba03", "mdi-alert-circle-outline")
                } else if (item.status == "updating") {
                    this.createInfo("This collection is currently being updated", "#fcba03", "mdi-alert-circle-outline")
                } else {
                    this.$router.push({ path: "/research/" + item.listname })
                }
            },
            deleteListFromProject(list_id, project) {
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/projects/delete/list",
                        {
                            list_id: list_id,
                            project: project,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(function (response) {
                        // console.log(response)
                        this.$emit("removeCollection", list_id, project)
                    })
            },
            createInfo(message, color, icon) {
                console.log("createinfo")
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            }
        },
        computed: {
            user_rights() {
                return this.$store.state.user_rights
            },
            user_settings() {
                return this.$store.state.user_settings
            }
        },
        mounted() {
            console.log(this.user_settings)
            this.project_sort_desc = this.user_settings.collections_projects_project_sort.sort
            this.project_sort_by = this.user_settings.collections_projects_project_sort.column
        }
    }
</script>

<style>
    #simple-projects-table th {
        background-color: rgb(224, 224, 224);
        font-weight: 100;
    }
    #simple-projects-table tr {
        cursor: pointer !important;
    }
    .updated-subtitle {
        font-size: 0.8em;
    }
    .bold {
        font-weight: bold;
    }
    .welcommessage {
        font-family: Nunito;
        font-weight: 100;
        padding-bottom: 0px;
    }
    #welcomecard {
        padding: 15px;
        color: white;
        background: linear-gradient(56deg, rgba(28, 237, 178, 1) 0%, rgba(0, 159, 138, 1) 0%, rgb(28, 237, 178) 100%);
    }
    .welcometitle {
        font-family: Nunito;
        font-weight: 100;
        text-align: center;
    }
    .subtitle {
        text-align: center;
        font-family: Nunito;
    }
    .chiptext {
        font-weight: bold;
        color: var(--v-primary-base);
    }
    .sitoquaro div {
        display: inline;
        padding: 10px;
    }
    .sitoquaro {
        text-align: center;
        margin-left: auto;
    }
    #tablesheet {
        margin-left: 5px;
        margin-top: -30px;
        width: 60px;
        height: 60px;
    }
    #heading3 {
        font-weight: 300 !important;
        color: rgb(105, 105, 105);
    }
    .headingsheet2 {
        margin-top: -15px;
    }
    #sheeticon {
        margin-left: -25px;
        margin-top: -25px;
        margin: auto !important;
        padding-bottom: 50% !important;
        width: 50% !important;
    }
    .project-title {
        font-weight: bolder;
        font-size: 1.1em;
    }
    .avat {
        background: linear-gradient(56deg, var(--v-left_nav_gradient1-base) 0%, var(--v-left_nav_gradient3-base) 100%);
        margin-top: 1px;
    }
</style>
