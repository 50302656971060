var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"pb-4 qgreycard"},[_c('div',{staticClass:"quarolbl pb-2 pb-2"},[_vm._v(" PROJECT TITLE "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The \"Project Title\" field specifies the title of your monitoring project. ")])])],1),_c('v-text-field',{attrs:{"type":"text","rounded":"","filled":"","hint":"e.g. Optimization: KW-Research","persistent-hint":""},model:{value:(_vm.project.title),callback:function ($$v) {_vm.$set(_vm.project, "title", $$v)},expression:"project.title"}})],1),_c('div',{staticClass:"pb-4 qgreycard"},[_c('div',{staticClass:"quarolbl pb-2"},[_vm._v(" YOUR DOMAIN "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Select the focus domain you want to monitor.")])])],1),_c('v-text-field',{staticClass:"mb-2",attrs:{"disabled":_vm.disabled,"rounded":"","filled":"","hint":"e.g. https://www.xxxlutz.de","persistent-hint":""},model:{value:(_vm.inputDomain),callback:function ($$v) {_vm.inputDomain=$$v},expression:"inputDomain"}})],1),_c('div',{staticClass:"pb-4 qgreycard"},[_c('div',{staticClass:"quarolbl pb-2"},[_vm._v(" LOCATION "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The \"Location\" field specifies the geographical location of the search results that will be analyzed. ")])])],1),_c('v-select',{attrs:{"items":_vm.textMap,"disabled":_vm.disabled,"rounded":"","item-text":"location_text","item-value":"location_code","prepend-inner-icon":"mdi-map-marker","return-object":"","filled":"","attach":""},model:{value:(_vm.project.langloc),callback:function ($$v) {_vm.$set(_vm.project, "langloc", $$v)},expression:"project.langloc"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"qgreycard mt-0"},[_c('div',{staticClass:"quarolbl pb-1"},[_vm._v(" DEVICE "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The \"Device\" field specifies the type of device the rankings will be fetched for.")])])],1),_c('v-chip-group',{staticClass:"py-0",attrs:{"color":"primary","column":"","show-arrows":"","mandatory":"","next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left"},model:{value:(_vm.deviceFilter),callback:function ($$v) {_vm.deviceFilter=$$v},expression:"deviceFilter"}},_vm._l((_vm.deviceTypes),function(deviceType,i){return _c('v-chip',{key:i,attrs:{"filter":"","close-icon":"mdi-close-circle-outline","filter-icon":"mdi-check-circle"}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(deviceType))])])}),1)],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"qgreycard mt-0"},[_c('div',{staticClass:"quarolbl pb-1"},[_vm._v(" Update Interval "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The \"Update Interval\" specifies if the rankings of your project will be updated every day, once a week or once a month.")])])],1),_c('v-chip-group',{staticClass:"py-0",attrs:{"color":"primary","column":"","show-arrows":"","return-object":"","mandatory":"","next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left"},model:{value:(_vm.intervalFilter),callback:function ($$v) {_vm.intervalFilter=$$v},expression:"intervalFilter"}},_vm._l((_vm.intervalTexts),function(interval,i){return _c('v-chip',{key:i,attrs:{"filter":"","close-icon":"mdi-close-circle-outline","filter-icon":"mdi-check-circle"}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(interval))])])}),1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }