<template>
    <div>
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <AddQuestionDialog
            :show="addQuestionDialogOptions.show"
            @added="addQuestions"
            @close="addQuestionDialogOptions.show = false"
            @failed="
                notification = $helpers.createNotification('Something went wrong adding your question! Please try again or contact support.', 'mdi-alert', 'orange darken-2')
                addQuestionDialogOptions.question_fetch_loading = false
            "></AddQuestionDialog>

        <div v-if="setup">
            <h2 style="font-weight: 300">Select Questions</h2>
            <p>For a comprehensive content it is important to answer questions and problems of the readers. Choose some questions you want to answer with your content.</p>
        </div>

        <v-row no-gutters>
            <v-col cols="12" class="ml-auto">
                <v-row class="ml-auto" no-gutters>
                    <!-- <v-slide-x-transition>
                            <v-text-field
                                v-if="addManually"
                                class="ml-auto mr-3"
                                dense
                                single-line
                                rounded
                                filled
                                v-model="topic_manual"
                                label="enter manually"
                                hide-details
                                @keyup.enter="add"></v-text-field>
                        </v-slide-x-transition> -->
                    <v-btn rounded class="ml-auto mb-2" color="primary" @click="addQuestionDialogOptions.show = true" :loading="addQuestionDialogOptions.question_fetch_loading">enter manually</v-btn>
                    <span class="mt-2 mx-3">or</span>
                    <v-btn rounded color="primary" @click="addFromClipboard">add from clipboard</v-btn>
                </v-row>
            </v-col>
        </v-row>
        <div class="questions-table-area">
            <v-row class="pa-4">
                <span>
                    <v-chip class="white--text" color="darkgreen" small> {{ selected.length }} selected</v-chip>
                </span>

                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                    clearable
                    clear-icon="mdi-close"
                    dense
                    rounded
                    filled
                    v-model="search"
                    max-width="300px"
                    prepend-inner-icon="mdi-magnify"
                    label="search keywords..."
                    single-line
                    hide-details></v-text-field>
            </v-row>
            <v-data-table
                disabled
                id="cs_comp_table"
                show-select
                :search="search"
                v-model="selected"
                fixed-header
                height="550px"
                :loading="!questions.length"
                :headers="headers"
                :items="questions"
                class="elevation-0 comp-table"
                @toggle-select-all="selectPage"
                item-key="id">
                <!-- header slot -->
                <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                    <span :key="index">
                        <span v-bind:style="{ width: header.width }">
                            <!-- Adding tooltips to header -->
                            <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="quarotblheader">
                                        {{ header.text }}
                                        <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                    </span>
                                </template>
                                <span>
                                    <v-row no-gutters>
                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                    </v-row>
                                    {{ header.tooltip }}
                                </span>
                            </v-tooltip>
                        </span>
                    </span>
                </template>

                <!-- Table Body -->
                <template v-slot:body="{ items }">
                    <tbody v-if="items.length">
                        <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                            <td class="selecttd">
                                <v-icon v-if="!item.selected" @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-blank-outline</v-icon>
                                <v-icon v-else @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-marked</v-icon>
                            </td>
                            <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="table_td">
                                <!-- ------- -->
                                <!-- question -->
                                <!-- ------- -->
                                <div v-if="header.value === 'question'" class="td_question">
                                    {{ item[header.value] }}
                                </div>
                                <!-- -------------- -->
                                <!---- question_word ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'question_word'" v-bind="(question = $helpers.getQuestionWord(item))">
                                    <v-chip class="white--text" :color="question.color">
                                        {{ question.text }}
                                    </v-chip>
                                </div>
                                <!-- ------- -->
                                <!-- source -->
                                <!-- ------- -->
                                <div v-if="header.value === 'source'">
                                    <v-chip class="white--text" :color="item.user_input ? 'teal darken-1' : returnSource(item.tags).color" small>{{
                                        item.user_input ? "User Input" : returnSource(item.tags).text
                                    }}</v-chip>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import AddQuestionDialog from "../../content-suite/AddQuestionDialog.vue"

    export default {
        props: {
            questions: Array,
            setup: Boolean
        },
        components: {
            AddQuestionDialog
        },
        data() {
            return {
                notification: { show: false },
                addQuestionDialogOptions: {
                    show: false,
                    question_fetch_loading: false
                },
                search: "",
                colors: {
                    h1: "cyan darken-3",
                    h2: "purple darken-3",
                    h3: "lime darken-3",
                    h4: "light-green darken-3",
                    h5: "blue darken-3",
                    h6: "orange darken-3",
                    people_also_asked: "grey darken-2",
                    CompetitorsContent: "green darken-3rs"
                },

                headers: [
                    {
                        text: "Question",
                        value: "question",
                        sortable: false
                    },
                    {
                        text: "Question Word",
                        value: "question_word",
                        sortable: true
                    },
                    {
                        text: "Source",
                        value: "source",
                        sortable: true
                    }
                ]
            }
        },
        methods: {
            addFromClipboard() {
                if (!this.$store.state.clipboard_keywords.length) {
                    this.notification = this.$helpers.createNotification("Your clipboard is empty! Please push keywords to your clipboard first.", "mdi-alert", "orange darken-2")
                    return
                }

                console.log(this.$store.state.clipboard_keywords)
                let formatted_clipboard_keywords = this.$store.state.clipboard_keywords.map((x, i) => {
                    let question_object = {}

                    if (this.questions.findIndex((question) => question.question == x.keyword + "?") == -1) {
                        question_object = {
                            question: x.keyword + "?",
                            selected: true,
                            user_input: true
                        }

                        this.questions.unshift(question_object)
                    }
                    return question_object
                })
                console.log(formatted_clipboard_keywords)
                this.$emit("updateQuestions", this.questions)

                this.notification = this.$helpers.createNotification("Keywords added from clipboard!", "mdi-check-circle-outline", "primary")
            },
            addQuestions(question) {
                this.notification = this.$helpers.createNotification("Your question has been added!", "mdi-check-circle-outline", "primary")

                if (this.questions.findIndex((q) => q.question == question) == -1) {
                    let question_object = {
                        question: question,
                        selected: true,
                        user_input: true
                    }
                    this.questions.unshift(question_object)
                }
                this.$emit("updateQuestions", this.questions)
            },
            returnSource(tags) {
                if (tags && tags[0] == "people_also_ask") {
                    return { text: "Google SERP", color: "grey darken-2" }
                } else {
                    if (!tags) {
                        return { text: "Competitors Content", color: this.colors["CompetitorsContent"] }
                    }
                    let obj = { text: "", color: "" }
                    tags.map((tag) => {
                        if (tag.substring(0, 1) == "h") {
                            obj.text = "Competitors " + tag
                            obj.color = this.colors[tag]
                        } else {
                            obj.text = "Competitors Content"
                            obj.color = this.colors["CompetitorsContent"]
                        }
                    })
                    return obj
                }
            },
            selectItem(index, item) {
                let visibleIndex = this.questions.indexOf(item)

                // if (this.selected.length == 10 && this.questions[visibleIndex].selected == false) {
                //     this.notification = this.$helpers.createNotification("You cannot selet more than 10 questions!", "mdi-alert", "orange darken-2")
                //     return
                // }
                if (!this.questions[visibleIndex].selected) {
                    this.questions[visibleIndex].selected = false
                }
                this.questions[visibleIndex].selected = !this.questions[visibleIndex].selected
                // this.$emit("updatequestions", [item])
                this.$emit("updateQuestions", [item])
            },
            selectPage(event) {
                console.log(event.items)
                console.log(this.questions)
                let select = false
                let selectedPageItems = event.items.filter((x) => x.selected)
                if (event.value && selectedPageItems.length == 0) {
                    select = true
                }
                event.items.map((item) => {
                    let kw = this.questions.find((question) => question.question === item.question)
                    if (kw) {
                        kw.selected = select
                    }
                })

                this.$emit("updateQuestions", event.items)

                // console.log(event)
                // if (event.value == true) {
                //     this.questions.map((question, index) => {
                //         console.log(index)
                //         if (index < 10) {
                //             question.selected = true
                //         }
                //     })
                // } else {
                //     this.questions.map((question) => {
                //         question.selected = false
                //     })
                // }
            }
        },
        computed: {
            selected: {
                get() {
                    let selectedquestions = this.questions.filter((question) => question.selected)
                    return selectedquestions
                },
                set() {}
            }
        }
    }
</script>

<style></style>
