<template>
    <div>
        <v-slide-x-transition hide-on-leave>
            <v-card v-show="show2" class="rounded_card" id="selectedTextMenu">
                <v-list rounded dense flat>
                    <v-list-item class="pl-2" @click="extendText">
                        <v-icon class="mr-2">mdi-text-long</v-icon>
                        <v-list-item-title>Extend Text</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="pl-2" @click="showTranslateDialog">
                        <v-icon class="mr-2">mdi-translate</v-icon>
                        <v-list-item-title>Translate</v-list-item-title>
                    </v-list-item>
                    <v-list-item disabled class="pl-2" @click="extendText">
                        <v-icon class="mr-2">mdi-text-long</v-icon>
                        <v-list-item-title>Paraphrase</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="pl-2" @click="summarizeText">
                        <v-icon class="mr-2">mdi-text-long</v-icon>
                        <v-list-item-title>Summarize</v-list-item-title>
                    </v-list-item>
                    <v-list-item disabled class="pl-2" @click="endSentence">
                        <v-icon class="mr-2">mdi-file-document-edit </v-icon>
                        <v-list-item-title>End Sentence</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-slide-x-transition>
    </div>
</template>

<script>
    export default {
        name: "SelectedTextMenu",

        props: {
            show: Boolean,
            keywords: Array
        },
        data: () => ({}),
        computed: {
            query_keywords() {
                return this.keywords.map((keyword) => keyword.keyword)
            },
            show2: {
                get() {
                    return this.show
                },
                set(val) {
                    console.log("set")
                    this.$emit("set", val)
                }
            }
        },
        methods: {
            extendText(e) {
                e.stopPropagation()

                this.$emit("extend")
            },
            showTranslateDialog(e) {
                e.stopPropagation()

                this.$emit("showTranslateDialog")
            },
            summarizeText(e) {
                e.stopPropagation()

                this.$emit("summarize")
            },
            endSentence(e) {
                e.stopPropagation()

                this.$emit("endSentence")
            }
        }
    }
</script>
<style scoped>
    #selectedTextMenu .v-list-item:hover {
        background-color: rgb(237, 237, 237);
    }
</style>
