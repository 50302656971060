var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"top":"","color":_vm.info.color},model:{value:(_vm.showInfo),callback:function ($$v) {_vm.showInfo=$$v},expression:"showInfo"}},[_c('v-icon',[_vm._v(_vm._s(_vm.info.icon))]),_c('span',{staticClass:"font-weight-bold pl-2"},[_vm._v(_vm._s(_vm.info.message))])],1),(_vm.mountedOn.url == 'content-projects')?_c('center',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-switch',_vm._g({attrs:{"hide-details":""},on:{"change":function($event){_vm.createShareLink()
                        _vm.share_project_item = 'briefing'
                        _vm.changeSharedExternStatus()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.share_data.briefing ? "Briefing" : "Briefing"))])]},proxy:true}],null,true),model:{value:(_vm.share_data.briefing),callback:function ($$v) {_vm.$set(_vm.share_data, "briefing", $$v)},expression:"share_data.briefing"}},on))]}}],null,false,1334212430)},[_c('span',[_vm._v("Note: If you deactivate this link your customers are not able to see the collection anymore.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-switch',_vm._g({attrs:{"hide-details":""},on:{"change":function($event){_vm.createShareLink()
                        _vm.share_project_item = 'content-editor'
                        _vm.changeSharedExternStatus()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.share_data.editor ? "Content Editor" : "Content Editor"))])]},proxy:true}],null,true),model:{value:(_vm.share_data.editor),callback:function ($$v) {_vm.$set(_vm.share_data, "editor", $$v)},expression:"share_data.editor"}},on))]}}],null,false,4206659345)},[_c('span',[_vm._v("Note: If you deactivate this link your customers are not able to see the collection anymore.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-switch',_vm._g({attrs:{"hide-details":""},on:{"change":function($event){_vm.createShareLink()
                        _vm.share_project_item = 'content-project/competitors'
                        _vm.changeSharedExternStatus()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.share_data.competitors ? "Competitor Analysis" : "Competitor Analysis"))])]},proxy:true}],null,true),model:{value:(_vm.share_data.competitors),callback:function ($$v) {_vm.$set(_vm.share_data, "competitors", $$v)},expression:"share_data.competitors"}},on))]}}],null,false,1416628345)},[_c('span',[_vm._v("Note: If you deactivate this link your customers are not able to see the collection anymore.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-switch',_vm._g({attrs:{"hide-details":""},on:{"change":function($event){_vm.createShareLink()
                        _vm.share_project_item = 'content-project/wdf-idf'
                        _vm.changeSharedExternStatus()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.share_data.wdfidf ? "WDF*IDF Analysis" : "WDF*IDF Analysis"))])]},proxy:true}],null,true),model:{value:(_vm.share_data.wdfidf),callback:function ($$v) {_vm.$set(_vm.share_data, "wdfidf", $$v)},expression:"share_data.wdfidf"}},on))]}}],null,false,2564885241)},[_c('span',[_vm._v("Note: If you deactivate this link your customers are not able to see the collection anymore.")])]),_c('v-row',{staticClass:"mt-10"},[_c('v-text-field',{staticClass:"pr-5 pl-2",attrs:{"disabled":!_vm.share_data.briefing && !_vm.share_data.editor && !_vm.share_data.wdfidf && !_vm.share_data.competitors,"readonly":"","rounded":"","filled":"","dense":""},model:{value:(_vm.share_link),callback:function ($$v) {_vm.share_link=$$v},expression:"share_link"}}),_c('v-btn',{staticClass:"mr-5",staticStyle:{"margin-top":"3px"},attrs:{"color":"primary","disabled":!_vm.share_data.briefing && !_vm.share_data.editor && !_vm.share_data.wdfidf && !_vm.share_data.competitors,"rounded":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.share_link)}}},[_vm._v("Copy")])],1)],1):_c('center',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-switch',_vm._g({attrs:{"hide-details":""},on:{"change":function($event){return _vm.changeSharedExternStatus(_vm.share_link)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.shareSwitch ? "Deactivate Link" : "Activate link"))])]},proxy:true}],null,true),model:{value:(_vm.shareSwitch),callback:function ($$v) {_vm.shareSwitch=$$v},expression:"shareSwitch"}},on)),_c('v-row',[_c('v-text-field',{staticClass:"pr-5 pl-2",attrs:{"disabled":!_vm.shareSwitch,"readonly":"","rounded":"","filled":"","dense":""},model:{value:(_vm.share_link),callback:function ($$v) {_vm.share_link=$$v},expression:"share_link"}}),_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","disabled":!_vm.shareSwitch,"rounded":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.share_link)}}},[_vm._v("Copy")])],1)]}}])},[_c('span',[_vm._v("Note: If you deactivate this link your customers are not able to see the collection anymore.")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }