<template>
    <v-dialog persistent v-model="redoClusteringDialog.show" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small class="ml-2 px-1 share-dialog-invite-tbn" color="darkgreen" text>
                <v-icon> mdi-refresh </v-icon>
                {{ activatorBtnText }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                {{ redoClusteringDialog.title }}
                <v-btn class="mt-2" @click="redoClusteringDialog.show = false" right absolute icon><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>{{ redoClusteringDialog.subtitle }} </v-card-subtitle>
            <v-card-text class="pt-5 pb-0">
                <v-select rounded filled :items="selectItems" v-model="selectedItems" multiple chips return-object item-text="text" deletable-chips color="primary">
                    <template v-slot:prepend-item>
                        <!-- unselect all -->
                        <v-list-item ripple v-if="selectedItems.length > 0" @click="selectedItems = []">
                            <v-list-item-action>
                                <v-icon color="primary"> mdi-close-box</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title> unselect all </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- select all -->
                        <v-list-item ripple v-if="selectedItems.length == 0" v-on:click="selectedItems = selectItems">
                            <v-list-item-action>
                                <v-icon color="primary"> mdi-checkbox-multiple-marked </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title> select all </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
            </v-card-text>
            <v-card-actions>
                <v-btn class="ml-auto" rounded outlined @click="redoClusteringDialog.show = false" color="error">cancel</v-btn>
                <v-btn :loading="loading" class="white--text" rounded outlined @click="cluster" color="primary">
                    <v-icon class="px-5" v-if="loaded">mdi-check-circle-outline</v-icon> <span v-else> cluster</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "RedoClusterDialog",
        props: {
            selectItems: Array,
            header: Object
        },
        data() {
            return {
                redoClusteringDialog: {
                    show: false,
                    title: "Redo Clustering for Column: " + this.header.text,
                    subtitle: "Select the Values you want to cluster"
                },
                activatorBtnText: "Auto Clustering",
                selectedItems: [],
                loaded: false,
                loading: false
            }
        },
        watch: {
            // loading(val) {
            //     console.log("LOADING: ", val)
            // }
        },
        methods: {
            cluster() {
                this.loading = true
                this.$emit("cluster", this.header, this.selectedItems)
                setTimeout(() => {
                    this.loading = false
                    this.loaded = true
                    setTimeout(() => {
                        this.redoClusteringDialog.show = false
                        this.selectedItems = []
                        this.loaded = false
                    }, 500)
                }, 1700)
            }
        },
        mounted() {
            console.log("mounted")
        }
    }
</script>

<style></style>
