import { Line } from "vue-chartjs"
import chartjsPluginAnnotation from "chartjs-plugin-annotation"
export default {
    extends: Line,
    name: "LineChart",
    // Hilfvariablen werden in dieser Komponente entgegengenommen
    props: ["dataChart", "options", "countGraph", "countGraph2"],
    mounted() {
        // generate color gradient
        this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450)
        this.gradient.addColorStop(0, this.$vuetify.theme.themes.light.gradient1)
        this.gradient.addColorStop(0.5, this.$vuetify.theme.themes.light.gradient2)
        this.gradient.addColorStop(1, this.$vuetify.theme.themes.light.gradient3)

        for (let index = 0; index < this.dataChart.datasets.length; index++) {
            if (this.dataChart.datasets[index].label === "Sum of all keywords" || this.dataChart.datasets[index].label === "Google Searchvolume (All Keywords)") {
                this.dataChart.datasets[index].backgroundColor = this.gradient
                this.dataChart.datasets[index].borderColor = this.$vuetify.theme.themes.light.gradient1
                this.dataChart.datasets[index].borderWidth = 3.5
                this.dataChart.datasets[index].pointBackgroundColor = "rgba(255, 255, 255, 1)"
                this.dataChart.datasets[index].pointStyle = "circle"
                this.dataChart.datasets[index].pointRadius = 4
                this.dataChart.datasets[index].pointHoverRadius = 8
                this.dataChart.datasets[index].borderWidth = 1
            }
        }

        this.renderTheChart()
    },
    // computed: {
    //   chartData() {
    //     return this.dataChart;
    //   }
    // },
    methods: {
        renderTheChart() {
            // console.log(this.options)
            // this.options.legend.labels.usePointStyle = true

            this.renderChart(this.dataChart, this.options)
        },
        createGradient(rgbcode) {
            this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450)
            this.gradient.addColorStop(0, "rgba(" + rgbcode + ",0.8)")
            this.gradient.addColorStop(0.3, "rgba(" + rgbcode + ",0.3)")
            this.gradient.addColorStop(0.5, "rgba(" + rgbcode + ",0.0)")
            return this.gradient
        }
    },
    // Watcher der bei Veränderung von countGraph auslöst
    watch: {
        countGraph: {
            handler: function () {
                // if(this.countGraph!=0){
                for (var i = 0; i < this.dataChart.datasets.length - 1; i++) {
                    var min = Math.ceil(0)
                    var max = Math.floor(255)
                    var math1 = Math.floor(Math.random() * (max - min)) + min
                    var math2 = Math.floor(Math.random() * (max - min)) + min
                    var math3 = Math.floor(Math.random() * (max - min)) + min

                    this.dataChart.datasets[i].borderColor = "rgba(" + math1 + "," + math2 + "," + math3 + ",0.8)"
                }
                // }
                // Neuladen der Chart
                this.$data._chart.update()
            },
            deep: true
        },
        countGraph2: {
            handler: function () {
                this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450)
                this.gradient.addColorStop(0, this.$vuetify.theme.themes.light.gradient1)
                this.gradient.addColorStop(0.5, this.$vuetify.theme.themes.light.gradient2)
                this.gradient.addColorStop(1, this.$vuetify.theme.themes.light.gradient3)

                for (let index = 0; index < this.dataChart.datasets.length; index++) {
                    if (this.dataChart.datasets[index].label === "Sum of all keywords" || this.dataChart.datasets[index].label === "Google Searchvolume (All Keywords)") {
                        this.dataChart.datasets[index].backgroundColor = this.gradient
                        this.dataChart.datasets[index].borderColor = this.$vuetify.theme.themes.light.gradient1
                        this.dataChart.datasets[index].borderWidth = 3.5
                        this.dataChart.datasets[index].pointBackgroundColor = "rgba(255, 255, 255, 1)"
                        this.dataChart.datasets[index].pointStyle = "circle"
                        this.dataChart.datasets[index].pointRadius = 4
                        this.dataChart.datasets[index].pointHoverRadius = 8
                        this.dataChart.datasets[index].borderWidth = 1
                    }
                    if (this.dataChart.datasets[index].gradient) {
                        this.dataChart.datasets[index].backgroundColor = this.createGradient("0,160,137")
                    }
                }
                // Neuladen der Chart
                this.renderTheChart()
                this.$data._chart.update()
            },
            deep: true
        }
    }
}

String.prototype.convertToRGB = function () {
    if (this.length == 7) {
        this.substring(1)
    }

    var aRgbHex = this.match(/.{1,2}/g)
    var aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)]
    return aRgb
}
