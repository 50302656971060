<template>
    <div class="serp_items_wrapper">
        <div v-if="serp_items && serp_items.length > 0">
            <div class="serp_item" v-for="(serp_item, index) in showAll ? serp_items : serp_items.slice(0, 3)" :key="index">
                <v-tooltip top v-if="serp_item">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <!-- <v-icon v-if="serp_item.id > 12" color="#9b9b9b" size="20px">mdi-square-rounded-badge-outline</v-icon> -->
                            <v-icon class="serpicon" style="fill: rgba(0, 0, 0, 0.38)" color="primary" size="20px">${{ serp_item.value }}</v-icon>
                        </span>
                    </template>
                    <span>
                        <!-- tooltip content -->
                        {{ serp_item.tooltip }}
                    </span>
                </v-tooltip>
            </div>
            <v-chip small class="px-0" @click="$emit('toggle')"
                ><v-icon small>{{ showAll ? "mdi-chevron-left" : "mdi-chevron-right" }}</v-icon>
            </v-chip>
        </div>
        <div v-else>-</div>
    </div>
</template>

<script>
    export default {
        name: "SERPTypesCell",
        props: {
            serp_items: Array,
            showAll: Boolean
        },
        data() {
            return {}
        }
    }
</script>

<style scoped>
    .theme--dark.serpicon {
        fill: #ffffff !important;
    }

    .serp_items_wrapper > div {
        display: inline-flex;
    }
    .serp_item {
        padding-right: 5px;
    }
</style>
