<template>
    <div>
        <v-snackbar top v-model="notification.show" :color="notification.color" max-width="100px">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <v-card class="quaroshadow rounded_card mb-10 editorCard" style="">
            <v-slide-y-reverse-transition>
                <div class="backsheet" v-if="loadbacksheet"></div>
            </v-slide-y-reverse-transition>
            <v-card-text style="padding-top: 1px">
                <!-- <v-row no-gutters class="mt-4"> -->
                <v-row no-gutters v-if="editorVersion == 1" class="mt-4">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                :disabled="$store.state.content_suite_group.id !== 1 && $store.state.content_suite_group.id !== 2 && !shared_extern"
                                id="aifuncbtn"
                                v-bind="attrs"
                                v-on="on"
                                class="mb-2 white--text"
                                color="cyan darken-2"
                                small>
                                <v-icon x-small class="mr-1">mdi-head-remove-outline</v-icon>
                                AI Tools
                            </v-btn>
                        </template>
                        <v-list rounded dense flat>
                            <v-list-item
                                class="pl-2"
                                @click="
                                    set_ai_dialog_data('Generate Content')
                                    ai_text_dialog.show = true
                                ">
                                <v-icon class="mr-2">mdi-cog</v-icon>
                                <v-list-item-title>Content Generator</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="pl-2" @click="showTranslateDialog = true">
                                <v-icon class="mr-2">mdi-translate</v-icon>
                                <v-list-item-title>Translate</v-list-item-title>
                            </v-list-item>
                            <v-list-item disabled class="pl-2" @click="">
                                <v-icon class="mr-2">mdi-text-long</v-icon>
                                <v-list-item-title>Extend Text</v-list-item-title>
                            </v-list-item>
                            <v-list-item disabled class="pl-2" @click="">
                                <v-icon class="mr-2">mdi-refresh</v-icon>
                                <v-list-item-title>Paraphrase</v-list-item-title>
                            </v-list-item>
                            <v-list-item disabled class="pl-2" @click="">
                                <v-icon class="mr-2">mdi-file-document-edit </v-icon>
                                <v-list-item-title>End Sentence</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn outlined class="ml-2 mb-2 white--text" color="primary" small @click="copyHtml">
                        <v-icon x-small class="mr-1">mdi-content-copy</v-icon>
                        copy html
                    </v-btn>
                    <v-btn outlined class="ml-2 mb-2 white--text" color="primary" small @click="scroll"> scroll </v-btn>
                    <v-btn
                        v-if="$store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2 || shared_extern"
                        outlined
                        class="ml-2 mb-2 white--text"
                        color="primary"
                        small
                        @click="$refs.editor.quill.history.undo()">
                        <v-icon>mdi-undo</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="$store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2 || shared_extern"
                        outlined
                        class="ml-2 mb-2 white--text"
                        color="primary"
                        small
                        @click="$refs.editor.quill.history.redo()">
                        <v-icon>mdi-redo</v-icon>
                    </v-btn>
                </v-row>

                <tiptap
                    v-if="editorVersion == '2'"
                    value="Test"
                    :content="content2"
                    :pasteContentToEditor="pasteContent"
                    :comments="comments"
                    :keywords="keywords"
                    :keywordFrequency="keywordFrequency"
                    :project="project"
                    @setContent="setContent"
                    @processContent="processContent"
                    @updateComment="updateComment"
                    @deleteComment="deleteComment"></tiptap>
                <!--  -->
                <div class="contenttext" @mouseup="handleMouseUp" v-if="editorVersion == 1">
                    <vue-editor
                        id="vueeditor"
                        @text-change="textChanged"
                        v-model="content2"
                        ref="editor"
                        :disabled="editor_disabled || ($store.state.content_suite_group.id !== 1 && $store.state.content_suite_group.id !== 2 && !shared_extern)"></vue-editor>
                </div>

                <!-- 
                    <v-btn color="cyan darken-2" right absolute bottom rounded v-if="showButton" class="white--text" @click="outputPreviousParagraph"
                        ><v-icon small class="mr-1">mdi-auto-fix</v-icon>write</v-btn
                    > -->
            </v-card-text>
            <!-- <v-card-actions>
                <v-btn :loading="loading" color="primary" class="white--text ml-2">save</v-btn>
            </v-card-actions> -->
            <SelectedTextMenu
                @set="setMenu()"
                @showTranslateDialog="initTranslateDialog"
                @extend="extendText"
                @endSentence="endSentence"
                @summarize="initSummarizeDialog"
                class="selectedtextmenu popup rounded_card"
                :show="selectedTextMenu"></SelectedTextMenu>
            <v-dialog v-model="ai_response_dialog.show" persistent max-width="500px" transition="slide-x-transition">
                <v-card>
                    <v-card-title>
                        <span v-if="!ai_response_dialog.loading">{{ ai_response_dialog.title }}</span>
                        <v-btn class="close-btn" absolute top right icon color="primary" @click="ai_response_dialog.show = false"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text class="pt-2 pb-0">
                        <div v-if="ai_response_dialog.loading">
                            <h2 class="mb-7 text-center" style="font-weight: 100">{{ ai_response_dialog.loading_title }}</h2>
                            <div class="flex my-5">
                                <span class="circularprogress mx-auto">
                                    <img id="loader" src="../../../assets/quaro-q.svg" />
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <div class="markedTextArea">{{ ai_response_dialog.markedText }}...</div>
                            <div class="ai_response_text_area">{{ ai_response_dialog.response }}</div>
                            <div>
                                <v-btn class="mb-5 mt-2" small rounded outlined :disabled="true"><v-icon class="mr-2">mdi-plus-circle-outline</v-icon>load more</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn v-if="ai_response_dialog.loading" outlined class="mx-auto" color="error" rounded>cancel</v-btn> -->
                        <!-- <v-btn v-if="!ai_response_dialog.loading" class="mx-auto" color="primary" rounded @click="">Copy</v-btn> -->
                        <v-btn v-if="!ai_response_dialog.loading" class="mx-auto" color="primary" rounded @click="insertText">insert</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="ai_text_dialog.show"
                max-width="600px"
                transition="slide-x-transition"
                @click:outside="
                    ai_text_dialog.show = false
                    ai_text_dialog.loading = false
                ">
                <v-card>
                    <v-card-title>
                        <span class="uppercase">{{ ai_text_dialog.title }}</span>
                        <v-btn
                            class="close-btn"
                            absolute
                            top
                            right
                            icon
                            color="primary"
                            @click="
                                ai_text_dialog.show = false
                                ai_text_dialog.loading = false
                            "
                            ><v-icon>mdi-close</v-icon></v-btn
                        >
                    </v-card-title>
                    <v-card-subtitle>
                        <span>{{ ai_text_dialog.subtitle }}</span>
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text class="pt-5 pb-0" v-if="!ai_text_dialog.showResponse">
                        <v-snackbar top v-model="dialognotification.show" :color="dialognotification.color" max-width="100px">
                            <v-icon>{{ dialognotification.icon }}</v-icon
                            ><span class="font-weight-bold pl-2"> {{ dialognotification.message }} </span>
                        </v-snackbar>
                        <div>
                            <div class="qgreycard">
                                <div class="quarolbl pb-1">
                                    Topic
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>The main topic for the generated content...</span>
                                    </v-tooltip>
                                </div>
                                <v-text-field class="mb-2" v-model="ai_text_dialog.topic" hide-details rounded filled></v-text-field>
                            </div>
                            <div class="qgreycard">
                                <div class="quarolbl pb-1">
                                    Keywords
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Set keywords that should be included...(max 10)</span>
                                    </v-tooltip>
                                </div>
                                <v-combobox
                                    class="mb-2"
                                    v-model="ai_text_dialog.meta_keywords"
                                    :items="ai_text_dialog.meta_keyword_items"
                                    rounded
                                    filled
                                    color="primary"
                                    small-chips
                                    counter="10"
                                    deletable-chips
                                    hint="*optional"
                                    persistent-hint
                                    multiple
                                    v-on:input="maxItems"
                                    item-text="keyword"></v-combobox>
                            </div>

                            <!-- <v-autocomplete></v-autocomplete> -->
                            <div class="qgreycard">
                                <div class="quarolbl pb-1">
                                    Additional Commands:
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>A short description what the generated content should be about. Or any other command for the AI.</span>
                                    </v-tooltip>
                                </div>
                                <v-textarea hint="*optional" persistent-hint class="mb-2" rows="1" v-model="ai_text_dialog.description" rounded filled></v-textarea>
                            </div>

                            <div class="qgreycard mb-5">
                                <div class="quarolbl pb-1">
                                    Output
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Your desired content type</span>
                                    </v-tooltip>
                                </div>
                                <v-radio-group return-object class="mt-0" v-model="ai_text_dialog.outputType" row mandatory returnObject>
                                    <v-radio v-for="t in ai_output_types" :key="t.value" :label="t.label" :value="t.value"></v-radio>
                                </v-radio-group>
                            </div>
                            <v-slider class="mt-10" thumb-label="always" v-model="ai_text_dialog.word_count" label="Content Length" :min="10" :max="750"></v-slider>
                        </div>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-chip class="pl-2 mt-5 ml-1" height="38px" color="#F6F6F6">
                            <!-- <v-icon :size="10" class="mr-1 py-auto">mdi-web</v-icon> -->
                            <img class="mr-1" style="width: 12; border-radius: 5px" :src="'https://s2.googleusercontent.com/s2/favicons?domain=https://chat.openai.com/&sz=12'" alt="" />
                            <span class="pl-1" style="font-size: 14px !important"> Response:</span>
                        </v-chip>
                        <div class="qgreycard">
                            <div class="airesponse" v-html="ai_text_dialog.response"></div>
                        </div>
                    </v-card-text>
                    {{ ai_text_dialog.error }}
                    <v-card-actions>
                        <!-- <v-btn v-if="ai_response_dialog.loading" outlined class="mx-auto" color="error" rounded>cancel</v-btn> -->
                        <!-- <v-btn v-if="!ai_response_dialog.loading" class="mx-auto" color="primary" rounded @click="">Copy</v-btn> -->
                        <v-btn :disabled="ai_text_dialog.loading" v-if="!ai_text_dialog.showResponse" class="mx-auto" color="primary" rounded @click="generateText">Generate</v-btn>
                        <v-btn rounded v-else @click="ai_text_dialog.showResponse = false">Back</v-btn>
                        <!-- <v-btn v-if="ai_text_dialog.showResponse && ai_text_dialog.outputType.value == 'meta_data'" class="" color="primary" rounded @click="insertMetaData">insert</v-btn> -->
                        <v-btn v-if="ai_text_dialog.showResponse" class="" color="primary" rounded @click="copy">Copy</v-btn>
                    </v-card-actions>
                    <v-progress-linear v-if="ai_text_dialog.loading" indeterminate color="primary"></v-progress-linear>
                </v-card>
            </v-dialog>
        </v-card>
        <TranslateText :show="showTranslateDialog" @close="showTranslateDialog = false" :textToTranslate="textToTranslate"></TranslateText>
        <SummarizeText :show="showSummarizeDialog" @close="showSummarizeDialog = false" :textToSummarize="textToSummarize"></SummarizeText>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor"
    import SelectedTextMenu from "./SelectedTextMenu"
    import TranslateText from "./aifunctions/TranslateText.vue"
    import SummarizeText from "./aifunctions/SummarizeText.vue"
    import Tiptap from "./TiptapEditor.vue"

    export default {
        components: {
            VueEditor,
            SelectedTextMenu,
            TranslateText,
            SummarizeText,
            Tiptap
        },
        props: {
            keywords: Array,
            keywordFrequency: Array,
            project: Object,
            content: String,
            comments: Array,
            new_content: Number,
            editor_disabled: Boolean,
            // scrollBottom: Number,
            editorVersion: String,
            pasteContent: String,
            shared_extern: Boolean
        },
        data() {
            return {
                editor_settings: {
                    modules: {
                        clipboard: {
                            matchers: [[Node.ELEMENT_NODE, this.customQuillClipboardMatcher]]
                        }
                    },
                    // Deaktivieren Sie das Einfügen von reinem Text und verwenden Sie den benutzerdefinierten Handler.
                    pastePlain: false
                },
                clipboard_paste: false,
                textToTranslate: "",
                textToSummarize: "",
                showTranslateDialog: false,
                showSummarizeDialog: false,
                ai_output_types: [
                    { label: "Text", value: "text", command: "Schreibe einen SEO und WDF*IDF optimierten Text zu dem Thema:" },
                    { label: "Headline", value: "headline", command: "Schreibe eine SEO optimierte Überschrift (H1) zu dem Thema:" },
                    { label: "List", value: "list", command: "Erstelle eine Liste zu dem Thema:" },
                    { label: "Recipe", value: "recipe", command: "Schreibe ein Rezept zu dem Thema:" },
                    { label: "Meta Data", value: "meta_data", command: "Schreibe SEO optimierten Title (maximal 80 Zeichen) und Description (maximal 168 Zeichen) zu dem Thema:" }
                ],
                ai_response_dialog: {
                    show: false,
                    loading_title: "Generating text...",
                    loading: false,
                    markedText: "",
                    response: ""
                },
                ai_text_dialog: {
                    show: false,
                    loading_title: "Generating text...",
                    loading: false,
                    markedText: "",
                    response: "",
                    raw_response: "",
                    showResponse: false,
                    subtopics: [],
                    topic: "",
                    description: "",
                    word_count: 200,
                    outputType: "text"
                },
                carddata: {
                    title: "Create Overlay for Application",
                    subtitle: "Overlay will be shown for all users"
                },
                isActive: false,
                loading: false,
                loadbacksheet: false,
                notification: { show: false },
                dialognotification: { show: false },

                selectedTextMenu: false,
                selectedText: "",
                showButton: false,
                content_loaded: false
            }
        },
        computed: {
            content2: {
                set(val) {
                    //set Content
                    this.$emit("setContent", val)
                },
                get() {
                    return this.content + ""
                }
            }
        },
        watch: {
            content2() {
                if (!this.content_loaded) {
                    setTimeout(() => {
                        if (this.$refs.editor) {
                            this.$refs.editor.quill.history.clear()
                            this.content_loaded = true
                        }
                    }, 1000)
                }
            }
            // scrollBottom() {
            //     this.scroll()
            // }
        },
        methods: {
            deleteComment(id) {
                this.$emit("deleteComment", id)
            },
            updateComment(commentThread) {
                this.$emit("updateComment", commentThread)
            },
            setContent(content) {
                this.$emit("setContent", content)
            },
            scroll() {
                console.log("scrolling")
                var scrollableDiv = document.getElementsByClassName("ql-editor")
                console.log(scrollableDiv)
                scrollableDiv = scrollableDiv[0]
                scrollableDiv.scrollTop = scrollableDiv.scrollHeight

                // scrollableDiv.scrollTop = scrollableDiv.scrollHeight
            },
            customQuillClipboardMatcher(node, delta) {
                //     delta.ops = delta.ops.map((op) => {
                //         return {
                //             insert: op.insert
                //         }
                //     })
                //     this.clipboard_paste = true

                //     return delta

                this.clipboard_paste = true
                return delta
            },
            copy() {
                navigator.clipboard.writeText(this.ai_text_dialog.raw_response)
                this.notification = this.$helpers.createNotification(" Text copied to clipboard! ", "mdi-content-copy", "green darken-1")
                this.ai_text_dialog.show = false
            },
            initSummarizeDialog() {
                console.log(this.selectedText)
                this.textToSummarize = this.selectedText
                console.log(this.textToSummarize)

                this.showSummarizeDialog = true
            },
            initTranslateDialog() {
                this.textToTranslate = this.selectedText
                this.showTranslateDialog = true
            },
            maxItems(value) {
                console.log(value)
                if (value.length > 10) {
                    console.log(" you can only select 10", value)
                    this.dialognotification = this.$helpers.createNotification("Please do not select more than 10 keywords!", "mdi-alert-circle-outline", "#FCBA03")
                    console.log(this.dialognotification.show)
                    value.pop()
                }
            },
            //1000 -tokens -> 750 words  , 0.75
            set_ai_dialog_data(title) {
                console.log(this.project)
                this.ai_text_dialog.title = "Content Generator"
                this.ai_text_dialog.subtitle = "Use artificial intelligence to generate content quickly"
                this.ai_text_dialog.topic = this.project.main_keyword
                this.ai_text_dialog.meta_keywords = []
                this.ai_text_dialog.meta_keyword_items = [...this.keywords]
                // this.ai_text_dialog.meta_keywords = this.project.topics.filter((topic) => topic.selected && topic.keyword != this.project.main_keyword)
                // this.ai_text_dialog.meta_keyword_items = [...this.ai_text_dialog.meta_keywords, ...this.keywords]
            },
            copyHtml() {
                navigator.clipboard.writeText(this.content2)
                this.notification = this.$helpers.createNotification(" HTML copied to clipboard! ", "mdi-content-copy", "green darken-1")
            },

            processContent(newContent) {
                this.$emit("processContent", newContent)
            },
            textChanged(delta, oldDelta) {
                // if (oldDelta.ops[0].insert == "\n") {
                //     return
                // }
                this.$emit("processContent", delta, this.clipboard_paste ? true : false)
                this.clipboard_paste = false

                //test
                // const selection = window.getSelection()
                // const range = selection.getRangeAt(0)
                // const previousParagraph = range.startContainer.previousElementSibling
                // this.showButton = previousParagraph !== null
            },

            setMenu(val) {
                console.log(val)
                this.selectedTextMenu = true
            },
            async generateText() {
                try {
                    if (this.ai_text_dialog.meta_keywords.length > 10) {
                        this.ai_text_dialog.error = "Please make sure to select not more than 10 keywords!"
                        return
                    }

                    // Schreibe eine SEO und WDF*IDF optimierten Text zu dem Thema "Hundeleckerlies selber machen". Berücksichtige dabei folgende Keywords: backen, zutaten, kekse, hunde, haferflocken. Schreibe mindestens 200 Wörter

                    this.ai_text_dialog.loading = true
                    let command = this.ai_output_types.find((e) => e.value == this.ai_text_dialog.outputType)
                    let post_data = {
                        options: {
                            temperature: 0.8
                        },
                        input: command.command + this.ai_text_dialog.topic
                    }
                    if (this.ai_text_dialog.outputType.value != "meta_data") {
                        post_data.options.max_tokens = Math.ceil(this.ai_text_dialog.word_count * 1.33333)
                    }
                    if (this.ai_text_dialog.meta_keywords.length) {
                        post_data.input += ". Berücksichtige dabei folgende Keywords: " + this.ai_text_dialog.meta_keywords.map((e) => e.keyword)
                    }
                    if (this.ai_text_dialog.description.length) {
                        post_data.input += ". Berücksichte zusätzlich folgende informationen: " + this.ai_text_dialog.description
                    }
                    let url = process.env.VUE_APP_APIURL + "/v2/sources/openai/chatgpt"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data.choices[0].message.content)
                    this.ai_text_dialog.raw_response = response_data.data.choices[0].message.content
                    this.ai_text_dialog.response = this.formatResponse(response_data.data.choices[0].message.content)

                    this.ai_text_dialog.loading = false
                    this.ai_text_dialog.showResponse = true
                } catch (error) {
                    this.ai_text_dialog.loading = false
                    this.dialognotification = this.$helpers.createNotification("There was an error generating content. Please try again or contact the support!", "mdi-alert-circle-outline", "#FCBA03")
                    console.log(error)
                }
            },
            formatResponse(response) {
                let formattedText = response.replace(/\n/g, "<br>")
                // Tabulatoren durch Leerzeichen oder CSS-Styling ersetzen
                formattedText = formattedText.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") // Vier Leerzeichen pro Tabulator
                // Spezielle Zeichen in HTML-Entitätscode umwandeln
                formattedText = formattedText.replace(/&/g, "&amp;")
                formattedText = formattedText.replace(/"/g, "&quot;")
                formattedText = formattedText.replace(/'/g, "&apos;")
                return formattedText
            },
            async extendText() {
                try {
                    this.selectedTextMenu = false
                    this.ai_response_dialog.show = true
                    this.ai_response_dialog.title = "Extend Text"
                    this.ai_response_dialog.loading = true
                    let post_data = {
                        options: {
                            temperature: 0.1,
                            max_tokens: 500
                        },
                        input: "Verlängere diesen Text: " + this.selectedText
                    }
                    let url = process.env.VUE_APP_APIURL + "/v2/sources/openai/chatgpt"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data.choices[0].message.content)
                    this.ai_response_dialog.response = response_data.data.choices[0].message.content
                    this.ai_response_dialog.loading = false
                } catch (error) {
                    this.dialognotification = this.$helpers.createNotification("There was an error generating content. Please try again or contact the support!", "mdi-alert-circle-outline", "#FCBA03")
                    this.ai_response_dialog.loading = false
                    console.log(error)
                }
            },
            async endSentence() {
                try {
                    this.selectedTextMenu = false
                    this.ai_response_dialog.show = true
                    this.ai_response_dialog.loading = true

                    let post_data = {
                        options: {
                            temperature: 0.1,
                            max_tokens: 100
                        },
                        input: "Beende diesen satz: " + this.selectedText
                    }
                    let url = process.env.VUE_APP_APIURL + "/v2/sources/openai/chatgpt"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data.choices[0].message.content)
                    this.ai_response_dialog.response = response_data.data.choices[0].message.content
                    this.ai_response_dialog.loading = false
                    // var pre = document.getElementById("typearea");
                    // var doc = pre.ownerDocument.defaultView;
                    var sel = document.getSelection()
                    console.log(sel)
                    var range = sel.getRangeAt(sel.length)

                    // const scrollWidthBefore = pre.scrollWidth;

                    var tabNode = document.createTextNode(response_data.data.choices[0].message.content)
                    range.insertNode(tabNode)

                    range.setStartAfter(tabNode)
                    range.setEndAfter(tabNode)
                } catch (error) {
                    this.dialognotification = this.$helpers.createNotification("There was an error generating content. Please try again or contact the support!", "mdi-alert-circle-outline", "#FCBA03")
                    this.ai_response_dialog.loading = false
                    console.log(error)
                }
            },
            insertText() {
                let index = this.content2.indexOf(this.ai_response_dialog.markedText)
                this.content2 = this.content2.substring(0, index) + this.ai_response_dialog.markedText + this.ai_response_dialog.response + this.content2.substring(index, this.content2.length - 1)
                this.ai_response_dialog.show = false
                this.selectedTextMenu = false
            },
            handleMouseUp(e) {
                // Der Code für handleMouseUp
                var pageX, pageY
                this.selectedTextMenu = false

                pageX = e.clientX
                pageY = e.clientY
                setTimeout(() => {
                    let selection = document.getSelection()
                    this.selectedText = selection.toString().trim()
                    console.log(selection)
                    console.log(this.selectedText, "selectedText")
                    // ... Weitere Verarbeitung des ausgewählten Textes, falls erforderlich ...

                    var menu = document.querySelector(".selectedtextmenu")
                    let rect = document.querySelector(".contenttext").getBoundingClientRect()
                    let inXRange = pageX > rect.left && pageX < rect.right
                    let inYRange = pageY > rect.top && pageY < rect.bottom
                    if (this.selectedText.length) {
                        const menuWidth = menu.clientWidth
                        const contentTextWidth = rect.width
                        let menuLeft = Math.min(pageX + 10, contentTextWidth - menuWidth)
                        menu.style.left = menuLeft + "px"
                        // Begrenze die Top-Position des Menüs basierend auf der Höhe des contenttext Elements
                        console.log(menu)
                        // const menuHeight = menu.offsetHeight
                        const menuHeight = 232
                        const contentTextHeight = rect.height
                        console.log("menuHeight", menuHeight)
                        console.log("contentTextHeight - menuHeight", contentTextHeight - menuHeight)
                        console.log("window.innerHeight - menuHeight", window.innerHeight - menuHeight)
                        console.log("pageY", pageY)
                        let menuTop = Math.min(pageY, window.innerHeight - menuHeight)
                        menu.style.top = menuTop + "px"

                        this.selectedTextMenu = true
                    } else {
                        this.selectedTextMenu = false
                    }
                }, 200)
            },

            handleMouseDown(e) {
                this.selectedTextMenu = false
            }
        },
        mounted() {
            setTimeout(() => {
                this.loadbacksheet = true
            }, 600)

            // Mon Jan 15 2024 14:55:28 GMT+0100 (Mitteleuropäische Normalzeit)
        },
        beforeRouteLeave(to, from, next) {
            // ...
            document.removeEventListener("mouseup", this.handleMouseUp)
            document.removeEventListener("mousedown", this.handleMouseDown)
        },
        destroyed() {
            document.removeEventListener("mouseup", this.handleMouseUp)
            document.removeEventListener("mousedown", this.handleMouseDown)
        }
    }
</script>
<style lang="scss">
    .editorCard {
        // overflow: visible !important;
        // overflow-y: auto !important;
        // overflow-x: hidden !important;
        //
    }
    // .ql-editor {
    //     padding: 4rem 2rem 4rem 5rem !important;
    // }
    // .ql-editor {
    //     margin-left: auto !important;
    //     margin-right: auto !important;
    //     max-width: 42rem !important;
    // }
    // @media (min-width: 1024px) {
    //     .ql-editor {
    //         padding-left: 2rem;
    //         padding-right: 2rem;
    //     }
    // }

    // .ql-editor > img {
    //     margin-left: auto;
    //     margin-right: auto;
    //     max-width: 42rem;
    // }
    // .ql-editor p:first-child {
    //     margin-top: 0 !important;
    // }

    // .ql-editor p:last-child {
    //     margin-bottom: 0 !important;
    // }

    .ql-editor > p {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    // .ql-editor > p:first-child {
    //     margin-top: 0 !important;
    // }

    // .ql-editor > p:last-child {
    //     margin-bottom: 0 !important;
    // }

    // .ql-editor h1 {
    //     font-size: 1.875rem !important;
    //     line-height: 2.25rem !important;
    // }

    // .ql-editor h2 {
    //     font-size: 1.5rem !important;
    //     line-height: 2rem !important;
    // }

    // .ql-editor h3 {
    //     font-size: 1.25rem !important;
    //     line-height: 1.75rem !important;
    // }

    // .ql-editor h4 {
    //     font-size: 1.125rem !important;
    //     line-height: 1.75rem !important;
    // }

    // .ql-editor h5 {
    //     font-size: 1rem !important;
    //     line-height: 1.5rem !important;
    // }

    // .ql-editor h6 {
    //     font-size: 0.875rem !important;
    //     line-height: 1.25rem !important;
    // }

    // .ql-editor h1,
    // .ql-editor h2,
    // .ql-editor h3,
    // .ql-editor h4,
    // .ql-editor h5,
    // .ql-editor h6 {
    //     font-weight: 700 !important;
    // }

    // .ql-editor h1:first-child,
    // .ql-editor h2:first-child,
    // .ql-editor h3:first-child,
    // .ql-editor h4:first-child,
    // .ql-editor h5:first-child,
    // .ql-editor h6:first-child {
    //     margin-top: 0 !important;
    // }

    // .ql-editor h1:last-child,
    // .ql-editor h2:last-child,
    // .ql-editor h3:last-child,
    // .ql-editor h4:last-child,
    // .ql-editor h5:last-child,
    // .ql-editor h6:last-child {
    //     margin-bottom: 0 !important;
    // }

    // .ql-editor h1,
    // .ql-editor h2,
    // .ql-editor h3 {
    //     margin-top: 3rem !important;
    // }

    // .ql-editor h4,
    // .ql-editor h5,
    // .ql-editor h6 {
    //     margin-top: 2rem !important;
    // }
    // .ql-container.ql-snow {
    //     border: 1px solid #ccc !important;
    //     border-radius: 10px;
    // }
    // .ql-toolbar.ql-snow {
    //     border: none !important;
    // }
    .ql-editor {
        max-height: 75vh;
        // min-width: 75vh;
        scroll-behavior: smooth !important;
    }
    .ql-editor p {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        line-height: 1.625;
    }
    // .ql-editor > p {
    //     margin-top: 1.5rem;
    //     margin-bottom: 1.5rem;
    //     line-height: 1.625;
    // }
    .popup {
        box-shadow: 0px 4px 12px #dbdbdb, 4px 0px 12px #e8e8e8 !important;
        /* display: none; */
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        /* width: 100%; */
        /* height: 100%; */
        background-color: antiquewhite;
        overflow: auto;
    }
    .markedTextArea {
        font-size: 16px;
        margin-bottom: 50px;
    }
    .ai_response_text_area {
        font-size: 16px;
    }
    #aifuncbtn {
        /* background: linear-gradient(90deg, #e68989 0%, #e68989 75%); */
    }
    .floating-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
    }
    // .ql-editor {

    // }
</style>
