<template>
    <div>
        <v-dialog v-model="select_questions_dialog">
            <v-card>
                <v-card-title class="titlelbl uppercase">
                    Select Questions
                    <v-btn icon right absolute @click="select_questions_dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle> All questions are generated from competitors content and google search results! </v-card-subtitle>

                <v-card-text>
                    <SelectQuestions :questions="project.questions" @updateQuestions="updateQuestions"></SelectQuestions>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card class="flat">
            <!-- <div class="backsheet"></div> -->

            <v-card-title class="briefing_card_label pb-6">
                <!-- Questions
                <v-tooltip max-width="250" color="" top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                    </template>
                    <span
                        >The "Questions" field specifies some questions that should be answered with the content of the project. Questions can help to guide the writer and ensure that the content
                        covers all the necessary information that the target audience may be looking for.</span
                    >
                </v-tooltip> -->
                <!-- <v-btn v-if="!shared_extern" icon @click="select_questions_dialog = true">add</v-btn> -->
                <v-btn style="z-index: 9" v-if="!shared_extern && $store.state.content_suite_group.id == 1" color="primary" right absolute top small rounded @click="select_questions_dialog = true"
                    ><v-icon class="mr-1" small>mdi-pencil</v-icon> edit questions</v-btn
                >
                <!-- <v-btn v-if="!shared_extern" right absolute top small icon @click="select_questions_dialog = true"><v-icon small>mdi-pencil</v-icon></v-btn> -->
            </v-card-title>
            <v-data-table id="bqtable" fixed-header height="550px" :headers="headers" :items="questions" :custom-sort="customSort" class="elevation-0 comp-table">
                <!-- header slot -->
                <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                    <span :key="index">
                        <span v-bind:style="{ width: header.width }">
                            <!-- Adding tooltips to header -->
                            <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{ header.text }}
                                        <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                    </span>
                                </template>
                                <span>
                                    <v-row no-gutters>
                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                    </v-row>
                                    {{ header.tooltip }}
                                </span>
                            </v-tooltip>
                        </span>
                    </span>
                </template>

                <!-- Table Body -->
                <template v-slot:body="{ items }">
                    <tbody v-if="items.length">
                        <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                            <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="table_td">
                                <!-- ------- -->
                                <!-- question -->
                                <!-- ------- -->
                                <div v-if="header.value === 'question'" class="briefing_td_question">
                                    {{ item[header.value] }}
                                </div>
                                <!-- -------------- -->
                                <!---- question_word ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'question_word'" v-bind="(question = getQuestionWord(item))">
                                    <v-chip class="white--text" :color="question.color">
                                        {{ question.text }}
                                    </v-chip>
                                </div>
                                <!-- ------- -->
                                <!-- source -->
                                <!-- ------- -->
                                <div v-if="header.value === 'source'">
                                    <v-chip class="white--text" :color="item.user_input ? 'teal darken-1' : returnSource(item.tags).color">{{
                                        item.user_input ? "User Input" : returnSource(item.tags).text
                                    }}</v-chip>
                                </div>
                                <!-- -------------- -->
                                <!---- actions ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'actions'">
                                    <v-btn v-if="!shared_extern && $store.state.content_suite_group.id == 1" icon @click="$emit('removeQuestion', [item])"
                                        ><v-icon class="removekwbtn">mdi-close</v-icon></v-btn
                                    >
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import SelectQuestions from "../../content-suite/setup/SelectQuestions.vue"

    export default {
        props: {
            questions: Array,
            project: Object,
            shared_extern: Boolean
        },
        components: {
            SelectQuestions
        },
        data() {
            return {
                select_questions_dialog: false,
                colors: {
                    h1: "cyan darken-3",
                    h2: "purple darken-3",
                    h3: "lime darken-3",
                    h4: "light-green darken-3",
                    h5: "blue darken-3",
                    h6: "orange darken-3",
                    people_also_asked: "grey darken-2",
                    CompetitorsContent: "green darken-3rs"
                },
                questionParams: [
                    { text: "wann", color: "green darken-2" },
                    { text: "warum", color: "light-green darken-2" },
                    { text: "was", color: "lime darken-2" },
                    { text: "welche", color: "yellow darken-2" },
                    { text: "welcher", color: "amber darken-2" },
                    { text: "welches", color: "orange darken-2" },
                    { text: "wer", color: "purple darken-2" },
                    { text: "wie", color: "indigo darken-2" },
                    { text: "wo", color: "cyan darken-2" },
                    { text: "wohin", color: "brown darken-2" }
                ],
                headers: [
                    {
                        text: "Question",
                        value: "question",
                        sortable: true
                    },
                    {
                        text: "Question Word",
                        value: "question_word",
                        sortable: true
                    },
                    {
                        text: "Source",
                        value: "source",
                        sortable: true
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: true
                    }
                ],
                tblOptions: {
                    "footer-props": {
                        "items-per-page-options": [5, 10, 15]
                    }
                },
                perpage: 5
            }
        },
        methods: {
            async updateQuestions(questions) {
                questions.map((question) => {
                    let index = this.project.questions.findIndex((x) => question.question == x.question)
                    if (index > -1) {
                        this.project.questions[index].selected = question.selected
                    } else {
                        this.project.questions.push(question)
                    }
                })
                const url = process.env.VUE_APP_APIURL + "/cs/update"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: this.project
                }
                await this.$helpers.axiosPost(url, post_data)
                console.log("questions updated!!!!")
                this.$emit("fetchQuestions")
            },
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers, this.questionParams)
                console.log(sortedItems)
                return sortedItems
            },
            getQuestionWord(question) {
                let param2 = { text: "-", color: "" }

                this.questionParams.map((param) => {
                    let words = question.question.toLowerCase().split(" ")
                    if (words.includes(param.text)) {
                        param2 = param
                    }
                })
                return param2
            },
            returnSource(tags) {
                if (tags && tags[0] == "people_also_ask") {
                    return { text: "Google SERP", color: "grey darken-2" }
                } else {
                    let obj = { text: "", color: "" }
                    if (!tags) {
                        obj.text = "Competitors Content"
                        obj.color = this.colors["CompetitorsContent"]
                        return obj
                    }
                    tags.map((tag) => {
                        if (tag.substring(0, 1) == "h") {
                            obj.text = "Competitors " + tag
                            obj.color = this.colors[tag]
                        } else {
                            obj.text = "Competitors Content"
                            obj.color = this.colors["CompetitorsContent"]
                        }
                    })
                    return obj
                }
            }
        }
    }
</script>

<style scoped>
    th {
        text-transform: uppercase !important;
        font-weight: 100;
    }
</style>
