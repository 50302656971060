<template>
    <div>
        <div class="px-5">
            <v-row>
                <v-col cols="12">
                    <h2 style="font-weight: 300" class="pb-2">Add keywords to your project</h2>
                    <p style="max-width: 800px">
                        You can now select the keywords you want to monitor in this project. You can can either upload your plain keywords or keywords with assigned tags or categories.
                    </p>
                </v-col>
                <!-- <v-col style="display: flex" class="mx-auto" cols="3"> <img src="../../../assets/content_project.svg" width="90%" class="mx-auto" alt="" /></v-col> -->
            </v-row>
        </div>
        <div class="flex"><div class="pl-5 quarolbl">1. CHOOSE UPLOAD TYPE</div></div>
        <v-row class="choose">
            <v-col>
                <div :class="!project.categorized_project ? ' quaroshadow newcontent active ml-auto' : 'ml-auto newcontent'" @click="project.categorized_project = false">
                    <div class="choosetitle pa-auto" style="max-width: 59%">Only Keywords</div>
                    <div class="chooseimage">
                        <img v-if="!project.categorized_project" src="../../../assets/newwhite.svg" width="90%" class="mx-auto" alt="" />
                        <img v-else src="../../../assets/content_project.svg" width="90%" class="mx-auto" alt="" />
                    </div>
                </div>
            </v-col>
            <v-col>
                <div
                    :class="project.categorized_project ? 'quaroshadow optimizecontent active mr-auto' : ' optimizecontent mr-auto'"
                    @click="
                        project.categorized_project = true
                        uploadVia = 'file'
                    ">
                    <div class="choosetitle pa-auto"><span>Categorized Keywords</span></div>
                    <div class="chooseimage">
                        <img src="../../../assets/opimize_primary.svg" width="90%" class="mx-auto" alt="" />
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <v-row>
            <div class="px-8 mt-5">
                <v-radio-group inline v-model="uploadVia" mandatory>
                    <template v-slot:label>
                        <div class="quarolbl">2. How do you want to add your keywords?</div>
                    </template>

                    <v-radio value="file">
                        <template v-slot:label>
                            <div>Upload file <strong class="text-success">Excel/CSV</strong></div>
                        </template>
                    </v-radio>
                    <v-radio value="paste" :disabled="project.categorized_project">
                        <template v-slot:label>
                            <div><strong class="text-primary">Copy/Paste </strong> Keywords</div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </div>
        </v-row>

        <v-slide-x-transition v-if="uploadVia == 'file'" hide-on-leave>
            <div>
                <div class="flex mb-2">
                    <v-btn :href="exampleFile" class="ml-auto" small color="primary"><v-icon class="mr-2" small>mdi-cloud-download</v-icon> example file</v-btn>
                </div>
                <div>
                    <FileUpload @fileLoaded="fileLoaded" @createNotification="createNotification" :url="uploadUrl" :project="project" @success="$router.push('/monitoring')"></FileUpload>
                </div>
            </div>
        </v-slide-x-transition>
        <v-slide-x-transition v-else hide-on-leave>
            <div>
                <v-textarea class="mt-6" label="Paste keywords here.." v-model="kw_textfield" @input="textChanged" rounded filled color="primary"> </v-textarea>
                <div class="d-flex">
                    <v-btn class="ml-auto" :disabled="cleaned_keywords.length == 0" color="primary" @click="$emit('createProjectWithKeywords')"> Create Project </v-btn>
                </div>
            </div>
        </v-slide-x-transition>
    </div>
</template>

<script>
    import FileUpload from "./FileUpload"

    export default {
        props: {
            project: Object
        },
        components: {
            FileUpload
        },
        data() {
            return {
                exampleFile: process.env.VUE_APP_MONITORING_API_URL + "/exports/example-file.csv",
                uploadUrl: process.env.VUE_APP_MONITORING_API_URL + "/project/add/keywords/upload",
                uploadVia: "file",
                kw_textfield: ""
                // uploadViaFile: false
            }
        },
        methods: {
            fileLoaded(file_loaded) {
                this.$emit("fileLoaded", file_loaded)
            },
            createNotification(message, icon, color) {
                this.$emit("createNotification", message, icon, color)
            },
            textChanged() {
                console.log(this.cleaned_keywords)
                this.$emit("keywordsAdded", this.cleaned_keywords)
            },
            splitInput(textfield) {
                // Remove new lines and komma
                let inputlines = textfield.split(String.fromCharCode(10))
                //console.log(inputlines)
                let input = []
                for (let inputlinesCounter = 0; inputlinesCounter < inputlines.length; inputlinesCounter++) {
                    let noKomma = inputlines[inputlinesCounter].split(",")
                    for (let noKommaCounter = 0; noKommaCounter < noKomma.length; noKommaCounter++) {
                        input.push(noKomma[noKommaCounter])
                    }
                }
                input = input.filter((e) => {
                    return e != false && e !== null && e !== " " && e !== ""
                })
                input = input.map((v) => v.toLowerCase())
                return input
            },
            validateInput(input) {
                console.log("validate")
                let inputErrors = this.$helpers.validateUserInput(input)
                console.log(inputErrors)
                if (inputErrors.length) {
                    console.log(inputErrors)
                    let lines = inputErrors.reduce((lineString, entry) => lineString + (parseInt(entry.index) + 1) + ",", "")
                    console.log(lines)
                    this.$emit("createNotification", "Please validate your input. Watch Keywords: " + lines, "mdi-alert-circle-outline", "#FCBA03")

                    return false
                }
                return input
            }
        },
        updated() {
            console.log(this.project)
        },
        computed: {
            cleaned_keywords() {
                try {
                    let input = this.splitInput(this.kw_textfield)
                    if (!this.validateInput(input)) return
                    input = this.$helpers.replaceSpecialCharacters(input)
                    return input
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .choose > div > div {
        max-width: 300px;
        display: flex;
        // margin: auto;
        padding: 0px 30px;
        background-color: #f6f6f6;
        border-radius: 10px;
        &:hover {
            background-color: #dddcdc;
            cursor: pointer;
        }
        &.active {
            background-color: var(--v-primary-base) !important;
            color: white;
        }
    }
    .chooseimage {
        max-width: 40%;
        padding: 10px;
    }
    .choosetitle {
        display: flex;
        margin: auto;
    }
</style>
