<template>
    <div id="contentcontainter" class="standard-mode">
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <v-row>
            <v-col col="12">
                <v-card class="rounded_card mx-auto" id="questions_search_card">
                    <div class="backsheet"></div>
                    <h2 class="pt-5">{{ cardtitle }}</h2>
                    <v-card-text class="pt-0">
                        <v-row class="pl-2 pr-2" id="searchRow">
                            <v-col md="9" xl="9" sm="5" xs="12" class="pb-0">
                                <v-text-field
                                    rounded
                                    label="e.g. Backpack"
                                    rows="1 "
                                    filled
                                    hide-details
                                    v-model="googleSearch"
                                    v-on:keyup.enter="startGoogleQuestionFetch"
                                    prepend-inner-icon="mdi-magnify"></v-text-field>
                            </v-col>
                            <v-col md="2" xl="2" sm="5" xs="12">
                                <v-select v-model="selectedLocation" :items="stateItems" item-text="loc" item-value="id" label="Location" return-object top rounded filled attach hide-details>
                                </v-select>
                            </v-col>
                            <v-col v-if="!btndisabled" lg="1" md="1" sm="2" xs="12" class="">
                                <v-btn rounded id="sendBtn" elevation="0" :disabled="btndisabled" class="white-text" color="primary" v-on:click="startGoogleQuestionFetch"
                                    ><v-icon>mdi-send</v-icon></v-btn
                                >
                            </v-col>
                            <v-col v-else lg="1" md="1" sm="2" xs="12" class="">
                                <v-btn rounded id="sendBtn" elevation="0" class="white-text" color="primary" v-on:click="initStopSuggests"><v-icon>mdi-close</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-progress-linear v-if="loading" :value="progress" color="primary"> </v-progress-linear>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-slide-y-transition>
                    <v-card class="rounded_card" v-if="serp_loaded">
                        <div class="backsheet"></div>
                        <PeopleAlsoAsked :serp="serp"></PeopleAlsoAsked>
                    </v-card>
                </v-slide-y-transition>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-slide-y-transition>
                    <KeywordTable
                        v-if="loaded"
                        ref="kw_table"
                        mountedOn="Questions"
                        :keywords="keywords"
                        :columnsDisabled="columnsDisabled"
                        :headers="headers"
                        :catsLoading="false"
                        :cardData="carddata"
                        :catsOfList="catsOfList"
                        :serpsOfList="serpsOfList"
                        :resize="resize_kwtable"
                        @filterColumns="filterColumns"
                        @removeCat="removeCat"
                        @switchCat="switchCat"
                        @addToChart="addToChart"
                        @deleteKeywords="prepareDeleteKw"
                        @filterForCat="filterForCat"
                        @addCategories="addCategories"
                        @createNotification="createNotification"
                        @addCustomEntries="addCustomEntries"
                        @filterForCustomDropdown="filterForCustomDropdown"
                        @modifiedDropDownOptions="modifiedDropDownOptions"
                        @changedHeaders="changedHeaders"></KeywordTable>
                </v-slide-y-transition>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import locs from "../components/helper/locs"
    const GoogleApi = require("../components/SuggestPlattforms/GoogleApi")
    import dataprocessing from "../components/helper/dataprocessing.js"
    import datasources from "../components/helper/datasources.js"
    import KeywordTable from "../components/keyword-table/KeywordTable"
    import PeopleAlsoAsked from "../components/PeopleAlsoAsked"
    import { standardHeaders } from "../components/helper/global.js"
    import { questionsColumn } from "../components/helper/global.js"
    import { questionsParams } from "../components/helper/global.js"

    import $ from "jquery"
    import _ from "lodash"
    export default {
        components: {
            KeywordTable,
            PeopleAlsoAsked
        },
        data() {
            return {
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),

                serp_loaded: false,
                serp: {},
                cardtitle: "get questions about your product or service",
                selectedLocation: localStorage.selectedLocation ? JSON.parse(localStorage.selectedLocation) : locs.stateItems[locs.stateItems.findIndex((x) => x.loc == "Germany")],
                stateItems: locs.stateItems,
                googleSearch: "",
                btndisabled: false,
                stopSuggests: false,
                frontendstop: false,
                notification: {},
                realprogress: 0,
                progress: 0,
                loading: false,
                answerCounter: 0,
                sentToBackend: false,
                keywords: [],
                catData: [],
                headers: {
                    options: {
                        sort: true,
                        active: true
                    },
                    standardHeaders: [],
                    modifiedHeaders: []
                },
                columnsDisabled: {},
                carddata: {
                    title: "Questions",
                    subTitle: "",
                    searchPhrases: null
                },
                resize_kwtable: 0,
                catsOfList: [],
                serpsOfList: [],
                loaded: false,
                unfilteredKeywords: []
            }
        },
        methods: {
            startGoogleQuestionFetch() {
                if (this.googleSearch.length > 0) {
                    this.btndisabled = true
                    this.stopSuggests = false
                    if (this.googleSearch.length < 81) {
                        try {
                            this.$matomo.trackEvent("Questions", "", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        var params = GoogleApi.buildParamsGoogleQuestionsV2(this.googleSearch, this.selectedQuestion, this.selectedLocation.cc, this.selectedLocation.cc)

                        this.loading = true
                        this.fetchSERP()
                        this.googleRequest(params.params.search, params.params.cp, params.params.ds, 0, params.params.client, [], "questions")
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            async fetchSERP() {
                let options = {
                    keyword: this.googleSearch,
                    language_code: this.selectedLocation.cc,
                    location_code: this.selectedLocation.value,
                    depth: 10
                }
                let serp_response = await datasources.fetchDFS(options, localStorage.company_id, localStorage.id, "google_serps_live")
                console.log(serp_response.result)
                let people_also_ask = serp_response.result.items.filter((item) => item.type == "people_also_ask")
                console.log(people_also_ask)
            },
            googleRequest: _.debounce(function (search, cp, ds, i, client, suggestArray, platform) {
                if (!this.frontendstop && !this.stopSuggests) {
                    // //console.log("da")
                    var addtoprogress = 98 / search.length
                    this.realprogress += addtoprogress
                    if (i % 2 == 0) {
                        this.progress = Math.floor(this.realprogress)
                    }
                    this.$jsonp(
                        "https://suggestqueries.google.com/complete/search?",
                        {
                            q: search[i],
                            client: "chrome",
                            hl: this.selectedLocation.cc,
                            gl: this.selectedLocation.cc,
                            // cp: cp[i],
                            ds: ds
                        },
                        5000
                    )
                        .then((json) => {
                            if (!this.stopSuggests) {
                                // Success.
                                for (var j = 0; j < json[1].length; j++) {
                                    suggestArray.push(json[1][j])
                                }
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            if (err.status == 400 && !this.sentToBackend && !this.stopSuggests) {
                                this.frontendstop = true
                                var params = {}

                                params = GoogleApi.buildParamsGoogleQuestionsV2(this.googleSearch, this.selectedQuestion, this.selectedLocation.cc, this.selectedLocation.cc)

                                let suggestData = {
                                    params: params.urls,
                                    keyword: params.keyword,
                                    language: this.selectedLocation.cc,
                                    location: this.selectedLocation.value,
                                    company: localStorage.company_id,
                                    user: localStorage.id
                                }
                                this.getSuggestsBackend(suggestData)
                                this.sentToBackend = true
                            }
                        })
                    if (i < search.length - 1 && !this.frontendstop && !this.stopSuggests) {
                        this.googleRequest(search, cp, ds, i + 1, client, suggestArray, platform)
                    } else {
                        this.fetchKwData(suggestArray)
                    }
                }
            }, 200),
            async fetchKwData(keywords) {
                let userinfo = {
                    id: "additional-data",
                    company_id: 0
                }
                keywords = this.$helpers.replaceSpecialCharacters(keywords)
                // Make Fetched Keyword Unique
                keywords = keywords.filter(function (elem, index, self) {
                    return index === self.indexOf(elem)
                })
                this.allsuggests = keywords

                keywords = this.$helpers.validateGoogleInput(keywords)

                let keyworddata = keywords.map((keyword) => {
                    let obj = {}
                    obj.keyword = keyword
                    return obj
                })
                let data = await dataprocessing.fetchDFSHistSV(keyworddata, this.selectedLocation, userinfo)
                this.keywords = await dataprocessing.formatGoogleResponse(data, this.catData)
                keyworddata.map((entry) => {
                    let indexOfKeyword = this.keywords.findIndex((keywordrow) => keywordrow.keyword == entry.keyword)
                    if (indexOfKeyword == -1) {
                        entry.search_volumes = []
                        this.keywords.push(entry)
                    }
                })
                this.progress = 100
                this.loading = false
                this.carddata.subtitle = this.keywords.length + " keywords found"
                this.addQuestionEntries()
                this.createHeader(this.keywords)
                this.getListCats()
                this.getListSerps()
                console.log(this.keywords)
                this.loaded = true
            },
            addQuestionEntries() {
                console.log(questionsParams)

                // {
                //     "text": "-",
                //     "color": {
                //         "hex": ""
                //     },
                //     "id": 0
                // }

                //    custom: {
                //     "custom_Type": {
                //         "text": "-",
                //         "color": {
                //             "hex": ""
                //         },
                //         "id": 0
                //     },
                //     "custom_testo": {
                //         "text": "Option 1",
                //         "color": {
                //             "hex": "#d34eed"
                //         },
                //         "id": 1
                //     }
                // }
            },
            fetchAllGoogleCats() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        // console.log(cats)
                        this.catData = cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            endLoading() {
                this.loading = false
                this.progress = 0
                this.realprogress = 0
                this.btndisabled = false
                this.sentToBackend = false
                this.frontendstop = false
            },
            initStopSuggests() {
                if (this.frontendstop) {
                    this.$socket.emit("stopSuggestsBackend", {
                        frontendSocketID: this.$socket.id,
                        backendSocketID: this.apiSocketID
                    })
                }
                this.stopSuggests = true
                this.loading = false
                // this.loaded = false
                this.progress = 0
                this.realprogress = 0
                this.graphloaded = false

                this.btndisabled = false
                this.sentToBackend = false
                this.frontendstop = false
            },
            changedHeaders() {
                console.log("header in parent set")
                this.headers = this.$store.state.header_items
                console.log(this.headers)
            },
            filterColumns() {
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
            },
            removeCat(row, cat) {
                // remove cat
                let rowindex = this.keywords.indexOf(row)
                let catindex = this.keywords[rowindex].cats.indexOf(cat)
                if (this.keywords[rowindex].cats.length < 2) {
                    this.keywords[rowindex].cats = null
                    this.rerenderKeywordTable()
                } else {
                    this.keywords[rowindex].cats.splice(catindex, 1)
                    this.rerenderKeywordTable()
                }
            },
            rerenderKeywordTable() {
                this.keywords.push({})
                this.keywords.pop()
            },
            /**
             *  * sets/changes primary category for keyword(s)
             * @param {Array} rows keyword(s) to modify
             * @param {Object} cat category to set
             */
            switchCat(rows, cat) {
                // switch visible categories
                this.$helpers.switchCat(rows, cat, this.keywords)
                this.getListCats()
                this.rerenderKeywordTable()
            },
            getListCats() {
                let catsOfList = []
                this.keywords.forEach((keyword) => {
                    let cats = keyword.cats
                    if (cats) {
                        if (cats[0]) {
                            if (cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1]) {
                                catsOfList.push(cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1])
                            }
                        }
                    }
                })

                catsOfList.sort((a, b) => a.category_name.localeCompare(b.category_name))
                catsOfList.unshift({ category_name: "-" })

                this.catsOfList = catsOfList
            },
            addToChart(selected) {
                let graphdata = this.dataChart.datasets
                let selectedNoDoubles = this.$helpers.checkGraphDoubles(selected, graphdata)
                if (selected.length > 0) {
                    try {
                        this.$matomo.trackEvent("Ideas", "addToChart", "Keywords: " + selectedNoDoubles.length, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    //console.log(selectedNoDoubles)
                    if (selectedNoDoubles.length > 0) {
                        if (this.$helpers.checkSelectedSizeForGraph(selectedNoDoubles, 11, graphdata)) {
                            let graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, this.keywords, selectedNoDoubles)
                            this.dataChart.datasets = graphObjects.graphdata
                            this.dataChart.labels = graphObjects.graphlabel
                            if (this.dataChart.datasets[0].label == "Sum of all keywords") {
                                this.dataChart.datasets[0].hidden = true
                            }
                            // Hilfsvariable wird hochgezählt und Aktion in linechart.js ausgelöst
                            this.countGraph2++
                        } else {
                            this.notification = this.$helpers.createNotification("Only 10 keywords can be displayed in graph!", "mdi-alert-circle-outline", "#FCBA03")
                        }
                    } else {
                        this.notification = this.$helpers.createNotification("Selected keywords are displayed in the graph already!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            prepareDeleteKw(selected) {
                this.selected = selected
                this.deleteQuestion = true
            },
            deleteKeywords(keywords) {
                if (this.selected.length > 0) {
                    try {
                        for (var i = 0; i < this.selected.length; i++) {
                            const index = this.keywords.indexOf(this.selected[i])
                            this.keywords.splice(index, 1)
                        }
                        if (this.unfilteredKeywords.length > 0) {
                            for (var i = 0; i < this.selected.length; i++) {
                                let index = this.unfilteredKeywords.indexOf(this.selected[i])
                                this.unfilteredKeywords.splice(index, 1)
                            }
                        }
                        this.$matomo.trackEvent("Ideas", "deleteKeywords", "KeywordsCount: " + this.selected.length, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    let kwCount = this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length

                    this.$refs.kw_table.keywordsDeleted(kwCount)

                    let selectedNoDoubles = this.$helpers.checkGraphDoubles(this.selected, this.dataChart.datasets)
                    for (var i = 0; i < this.selected.length; i++) {
                        let index = selectedNoDoubles.findIndex((set) => {
                            if (set.keyword == this.selected[i].keyword) return set
                        })
                        if (index > -1) selectedNoDoubles.splice(index, 1)
                    }
                    let kwForGraph = this.keywords
                    if (this.unfilteredKeywords.length > this.keywords.length) {
                        kwForGraph = this.unfilteredKeywords
                    }
                    if (kwForGraph.length > 0) {
                        var graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, kwForGraph, selectedNoDoubles)
                        this.dataChart.datasets = graphObjects.graphdata
                        this.dataChart.labels = graphObjects.graphlabel
                        if (this.dataChart.datasets.length > 1) {
                            this.dataChart.datasets[0].hidden = true
                        } else {
                            this.dataChart.datasets[0].hidden = false
                        }
                        this.countGraph2++
                        this.getListCats()
                        this.getListSerps()
                    }

                    this.selected = []
                    this.keywords.map((x) => {
                        x.selected = false
                    })
                    this.deleteQuestion = false
                    // this.selected = [];
                    this.notification = this.$helpers.createNotification("The selected keywords have been deleted!", "mdi-check-circle-outline", "#16C96D")
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    console.log(this.selected)
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            getListSerps() {
                let serpsOfList = []
                this.keywords.forEach((keyword) => {
                    let serps = keyword.serp_items
                    if (serps) {
                        serps.map((x) => {
                            serpsOfList.push(x)
                        })
                    }
                })
                serpsOfList = new Set(serpsOfList)
                serpsOfList = Array.from(serpsOfList)

                serpsOfList.sort((a, b) => a.value - b.value)
                serpsOfList.unshift({ value: "-", tooltip: "-:" })
                this.serpsOfList = serpsOfList
            },
            filterForCat(cat_for_filter) {
                if (cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.forEach((keyword) => {
                    if (keyword.cats) {
                        let catsofKeyword = keyword.cats[keyword.active_cat_index]
                        cat_for_filter.forEach((catforfilter) => {
                            if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                this.keywords.push(keyword)
                            }
                        })
                    } else if (!keyword.cats) {
                        let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                        if (findemptycat) this.keywords.push(keyword)
                    }
                })
                this.carddata.subtitle = this.keywords.length + " keywords"
            },
            addCategories(row, selectedCats) {
                //adds categories to a keyword
                let rowindex = this.keywords.indexOf(row)
                for (let index = 0; index < selectedCats.length; index++) {
                    let tree = this.getTree(selectedCats[index])
                    if (this.keywords[rowindex].cats) {
                        this.keywords[rowindex].cats.unshift(tree)
                    } else {
                        this.keywords[rowindex].cats = [tree]
                    }
                }
                this.getListCats()
                this.rerenderKeywordTable()
            },
            getTree(category) {
                let tree = []
                tree[0] = category
                var findParent = (category) => {
                    let parent = this.catData.filter((x) => x.category_code == category.category_code_parent)
                    if (parent[0]) {
                        tree.unshift(parent[0])
                        if (parent[0].category_code_parent) {
                            findParent(parent[0])
                        }
                    }
                }
                if (category.category_code_parent) {
                    findParent(category)
                }
                return tree
            },
            createNotification(notification) {
                this.notification = this.$helpers.createNotification(notification.message, notification.icon, notification.color)
            },
            addCustomEntries(rows, selectedEntry, headerValue, isBackend, isArray) {
                if (!rows) return
                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    if (rowindex > -1) {
                        if (this.keywords[rowindex].customs === undefined) {
                            this.keywords[rowindex].customs = {}
                        }
                        this.keywords[rowindex].customs[headerValue] = isArray ? [...selectedEntry] : selectedEntry
                        console.log(this.keywords[rowindex])
                    }
                })
                this.keywords.push({})
                this.keywords.pop()
            },
            filterForCustomDropdown(initialObject, isArray, cat_for_filter) {
                var resultObject = Object.fromEntries(Object.entries(initialObject).filter(([_, v]) => v.length != 0))

                if (Object.keys(resultObject).length === 0 && cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.map((keyword) => {
                    if (Object.keys(resultObject).length > 0) {
                        Object.keys(resultObject).map((entry) => {
                            if (keyword.customs !== undefined && Object.keys(keyword.customs).includes(entry)) {
                                if (
                                    resultObject[entry]
                                        .map((obj) => {
                                            if (isArray) {
                                                return keyword.customs[entry].some((keyEntry) => keyEntry.text === obj.text)
                                            } else {
                                                return keyword.customs[entry].text === obj.text
                                            }
                                        })
                                        .includes(true)
                                ) {
                                    if (!this.keywords.some((k) => k === keyword)) {
                                        this.keywords.push(keyword)
                                    }
                                }
                            }
                        })
                    }
                    if (cat_for_filter.length > 0) {
                        if (keyword.cats) {
                            let catsofKeyword = keyword.cats[keyword.active_cat_index]
                            if (catsofKeyword) {
                                cat_for_filter.forEach((catforfilter) => {
                                    if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                        this.keywords.push(keyword)
                                    }
                                })
                            }
                        } else if (!keyword.cats) {
                            let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                            if (findemptycat) this.keywords.push(keyword)
                        }
                    }
                })
                console.log("filtered")
                // this.cardData.subtitle = this.keywords.length + " keywords"
            },
            modifiedDropDownOptions(option, headerValue) {
                this.keywords.map((keywordRow) => {
                    if (keywordRow.customs[headerValue].id == option.id) {
                        keywordRow.customs[headerValue] = option
                    }
                })
            },
            createHeader(keywords) {
                let monthArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                const researchHeaderIndex = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")
                let headers = []
                keywords.map((keyword) => {
                    keyword.search_volumes.map((vol) => {
                        let header = {
                            group: 1,
                            custom: false,
                            active: true,
                            canBeModified: false,
                            modifiedText: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            text: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            sortable: true,
                            width: "160"
                        }
                        if (vol.month < 10) {
                            header.value = vol.year.toString() + "0" + vol.month.toString()
                        } else {
                            header.value = vol.year.toString() + vol.month.toString()
                        }
                        header.dataChartLabel = monthArray[vol.month] + " " + vol.year
                        // console.log(headers.findIndex(p => p.text === header.text) === -1 )
                        if (headers.findIndex((p) => p.text === header.text) === -1) headers.push(header)
                    })
                })
                headers.sort(function (a, b) {
                    return a.value - b.value
                })
                this.headers.standardHeaders[researchHeaderIndex].columns = headers
                if (this.headers.modifiedHeaders.length == 0) {
                    this.headers.modifiedHeaders = JSON.parse(JSON.stringify(this.headers.standardHeaders))
                }
                console.log("Erstelle Header", this.headers)
            }
        },
        beforeRouteLeave(to, from, next) {
            if (this.unfilteredKeywords.length) this.keywords = [...this.unfilteredKeywords]
            next()
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.keywords.length) {
                    vm.unfilteredKeywords = [...vm.keywords]
                    vm.keywords = vm.$helpers.filterKeywordTable(vm.unfilteredKeywords)
                }
            })
        },
        mounted() {
            this.fetchAllGoogleCats()
            this.headers.standardHeaders = JSON.parse(JSON.stringify(standardHeaders))
            this.headers.standardHeaders.splice(4, 0, questionsColumn)
            console.log("this.headers, ", this.headers)
        }
    }
</script>

<style scoped>
    h2 {
        font-family: Nunito;
        text-transform: uppercase;
        font-weight: 500 !important;
        color: var(--v-primary-base);
        text-align: center;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    #questions_search_card {
        max-width: 1000px;
    }
    .searchwrapper {
        display: inline-flex;
    }
    #sendBtn {
        height: 56px !important;
    }
</style>
