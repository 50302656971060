<template>
    <v-slide-x-transition>
        <div v-if="show">
            <div class="pb-4 qgreycard">
                <div class="quarolbl pb-2">
                    Dropdown Options
                    <v-tooltip max-width="250" color="" top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                        </template>
                        <span
                            ><b>Paste the options you want to add! </b> <br />
                            Note: One option per row!</span
                        >
                    </v-tooltip>
                </div>

                <v-textarea
                    :placeholder="`Option 1 \
                    \nOption 2 `"
                    persistent-hint
                    :hint="(options ? options.length : '0') + ' options'"
                    class="mt-2"
                    label="Paste options here.."
                    v-model="textarea"
                    rounded
                    filled
                    color="primary">
                </v-textarea>
                <v-btn text color="error" small @click="$emit('close')"> <v-icon size="16px">mdi-chevron-left</v-icon> cancel</v-btn>
                <v-btn text color="primary" small @click="processOptions()"> <v-icon size="16px">mdi-plus</v-icon>create Options</v-btn>
            </div>
        </div>
    </v-slide-x-transition>
</template>

<script>
    export default {
        props: {
            show: Boolean,
            column: Object
        },
        data() {
            return {
                textarea: ""
            }
        },
        methods: {
            processOptions() {
                console.log(this.options)
                console.log(this.textarea)
                try {
                    this.options.map((option) => {
                        let obj = {
                            text: option,
                            color: { hex: "#" + [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join("") },
                            id: this.column.customOptions.entries.length + 1
                        }
                        this.column.customOptions.entries.splice(1, 0, obj)
                    })
                } catch (error) {
                    this.errorMessage = ""
                }
                this.loading = false
                this.$emit("close")
            },
            splitInput(textfield) {
                // Remove new lines and komma
                let inputlines = textfield.split(String.fromCharCode(10))
                //console.log(inputlines)
                let input = []
                for (let inputlinesCounter = 0; inputlinesCounter < inputlines.length; inputlinesCounter++) {
                    let noKomma = inputlines[inputlinesCounter].split(",")
                    for (let noKommaCounter = 0; noKommaCounter < noKomma.length; noKommaCounter++) {
                        input.push(noKomma[noKommaCounter])
                    }
                }
                input = input.filter((e) => {
                    return e != false && e !== null && e !== " " && e !== ""
                })
                return input
            }
        },
        computed: {
            options() {
                try {
                    let input = this.splitInput(this.textarea)
                    // this.validateInput(input)
                    // input = this.$helpers.replaceSpecialCharacters(input)
                    return input
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }
</script>

<style></style>
