<template>
    <div>
        <v-snackbar v-model="showchangeMessage" color="#16C96D" top>
            <v-icon dark size="25">mdi-check</v-icon><span class="font-weight-bold pl-2">{{ changeMessage }}</span>
        </v-snackbar>
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="showInfo">
            <v-icon>{{ info.icon }}</v-icon> <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <!-- DELETE USER DIALOG -->
        <v-dialog v-model="deleteUserQuestion" max-width="350px">
            <v-card>
                <v-card-title
                    style="
                         {
                            text-align: center;
                        }
                    ">
                    Delete {{ selectedUser.username }}?</v-card-title
                >
                <v-card-text class="text-center">
                    <v-btn class="mr-3" text absolute left @click="deleteUserQuestion = false"> Nevermind </v-btn>
                    <v-btn color="primary" text @click="deleteUser()"> Yes </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Change Group Dialog -->
        <!-- <v-dialog v-model="changeGroupDialog" max-width="400" height="300px">
            <v-card>
                <v-card-title>
                    {{ selectedUser ? selectedUser.username : "" }}
                </v-card-title>
                <v-card-subtitle> Change Group </v-card-subtitle>

                <v-card-text>
                    <v-select color="primary" v-model="newgroup" :items="groups" filled prepend-icon="mdi-account-group" label="select new group" item-text="text" item-value="value" return-object>
                        <template v-slot:item="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-list-item ripple v-on="on">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.text }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <span>{{ item.description }}</span>
                            </v-tooltip>
                        </template>
                    </v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn rounded class="mx-auto" color="primary" dark @click="changeGroup"> Change Group </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
        <AddUserDialog @close="addUserDialog = false" @createInfo="createInfo" @refreshData="fetchUser()" :company_domains="domains" :addUserDialog="addUserDialog"></AddUserDialog>

        <v-row>
            <v-col class="d-flex">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn rounded small @click="addUserDialog = true" color="primary" class="fabicon mx-5 mt-auto" v-bind="attrs" v-on="on"> <v-icon dark>mdi-plus</v-icon> add user </v-btn>
                    </template>
                    <span>invite user</span>
                </v-tooltip>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex">
                <div class="qgreycard mx-5 my-0 ml-auto">
                    <span class="lbl"
                        >Default Research Suite user group
                        <v-tooltip max-width="250" color="" top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>The selected Research Suite default user group will be assigned to any user created manually by the Admin or via the Register page!</span>
                        </v-tooltip>
                    </span>
                    <v-select
                        dense
                        color="primary"
                        hide-details
                        rounded
                        filled
                        class="my-auto"
                        :items="groups"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="selected_standard_group"
                        @change="changeStandardGroup">
                        <template v-slot:item="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-list-item ripple v-on="on">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <span>{{ item.description }}</span>
                            </v-tooltip>
                        </template>
                    </v-select>
                </div>
            </v-col>
        </v-row>
        <v-text-field class="mx-5" max-width="300px" dense small prepend-inner-icon="mdi-magnify" rounded filled v-model="search" label="Search user..."></v-text-field>
        <v-data-table :headers="header" :items="user" :search.sync="search" class="elevation-0" max-height="500" item-key="searchPhrase">
            <template class="px-0" v-slot:no-data>
                <v-progress-linear class="px-0" :size="50" color="primary" :indeterminate="true"></v-progress-linear>
            </template>
            <template class="px-0" v-slot:item.username="{ item }">
                <div class="py-3">
                    <div>{{ item.username }}</div>
                    <v-chip small>{{ item.user }}</v-chip>
                </div>
            </template>
            <template class="px-0" v-slot:item.group_id="{ item }">
                <!-- <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on">{{ item.group_name }}</v-chip>
                    </template>
                    <span>{{ item.group_description }}</span>
                </v-tooltip> -->
                <v-menu top offset-y right content-class="submenu" :close-on-content-click="true">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small text rounded color="grey darken-2" outlined>
                            <span v-if="item.admin">admin</span>
                            <span v-else-if="!item.research_suite">no access</span>
                            <span v-else>
                                {{ groups[groups.findIndex((x) => item.group_id == x.id)].name }}
                            </span>
                            <v-icon size="16px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list rounded dense style="max-height: 300px; overflow-y: auto; font-weight: bold !important">
                        <v-list-item class="pl-2" @click="grantAdmin(item)">
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> Admin </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-for="group in groups" :key="group.id" class="pl-2" @click="changeGroupV2('rs', item, group.id)">
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> {{ group.name }} </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="pl-2" @click="changeAccess('rs', item)">
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> No Access </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!-- //TODO: einbauen wenn research_suite_group vorhanden ist -->
                <!-- <v-menu top offset-y right content-class="submenu" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small text rounded color="grey darken-2" outlined>
                            <span v-if="!item.research_suite">no access</span>
                            <span v-else-if="item.research_suite && item.research_suite && item.research_suite.id">
                                {{ item.research_suite.name }}
                            </span>
                            <span v-else>Read & Write</span>
                            <v-icon size="16px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list rounded dense style="max-height: 300px; overflow-y: auto; font-weight: bold !important">
                        <v-list-item class="pl-2" @click="changeGroupV2('rs', 1)">
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> Read & Write </v-list-item-title>
                        </v-list-item>

                        <v-list-item class="pl-2" @click="changeGroupV2('rs', 2)">
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> Read Only </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu> -->
            </template>
            <template class="px-0" v-slot:item.content_suite_group_id="{ item }">
                <!-- <v-tooltip v-if="item.content_suite" top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on">Read & Write</v-chip>
                    </template>
                    <span>Allowed to access the Quaro Content Suite</span>
                </v-tooltip> -->

                <v-menu top offset-y right content-class="submenu" :close-on-content-click="true">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small text rounded color="grey darken-2" outlined>
                            <span v-if="item.admin">admin</span>
                            <span v-else-if="!item.content_suite">no access</span>
                            <span v-else>
                                {{ cs_groups[cs_groups.findIndex((x) => item.content_suite_group_id == x.id)].name }}
                            </span>
                            <!-- <span v-else>Read & Write</span> -->
                            <v-icon size="16px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list rounded dense style="max-height: 300px; overflow-y: auto; font-weight: bold !important">
                        <v-list-item class="pl-2" @click="grantAdmin(item)">
                            <!-- 'mdi-check-circle-outline -->
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title>Admin </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-for="group in cs_groups" :key="group.id" class="pl-2" @click="changeGroupV2('cs', item, group.id)">
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> {{ group.name }} </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="pl-2" @click="changeAccess('cs', item)">
                            <!-- 'mdi-check-circle-outline -->
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> No Access </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template class="px-0" v-slot:item.monitoring_suite_group_id="{ item }">
                <!-- <v-tooltip v-if="item.monitoring_suite" top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on">Read & Write</v-chip>
                    </template>
                    <span>Accces to Monitoring Suite</span>
                </v-tooltip> -->

                <v-menu top offset-y right content-class="submenu" :close-on-content-click="true">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small text rounded color="grey darken-2" outlined>
                            <span v-if="item.admin">admin</span>
                            <span v-else-if="!item.monitoring_suite">no access</span>
                            <span v-else>
                                {{ ms_groups[ms_groups.findIndex((x) => item.monitoring_suite_group_id == x.id)].name }}
                            </span>

                            <v-icon size="16px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list rounded dense style="max-height: 300px; overflow-y: auto; font-weight: bold !important">
                        <v-list-item class="pl-2" @click="grantAdmin(item)">
                            <!-- 'mdi-check-circle-outline -->
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title>Admin </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-for="group in ms_groups" :key="group.id" class="pl-2" @click="changeGroupV2('ms', item, group.id)">
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> {{ group.name }} </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="pl-2" @click="changeAccess('ms', item)">
                            <!-- 'mdi-check-circle-outline -->
                            <v-icon color="primary" class="mr-2">mdi-circle-outline</v-icon>
                            <v-list-item-title> No Access </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!-- <template class="px-0" v-slot:item.group_name="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on">{{ item.group_name }}</v-chip>
                    </template>
                    <span>{{ item.group_description }}</span>
                </v-tooltip>
                <v-tooltip v-if="item.content_suite" top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on">Content Suite</v-chip>
                    </template>
                    <span>Allowed to access the Quaro Content Suite</span>
                </v-tooltip>
            </template> -->
            <template class="px-0" v-slot:item.verification_status_translated="{ item }">
                <center>
                    <v-icon color="primary" v-if="item.verification_status == 1">mdi-check-circle-outline</v-icon>
                    <v-icon color="errorred" v-else>mdi-close-circle-outline</v-icon>
                </center>
            </template>
            <template class="px-0" v-slot:item.action="{ item }">
                <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark rounded outlined small v-bind="attrs" v-on="on"> <v-icon small>mdi-account-edit</v-icon><v-icon>mdi-chevron-down</v-icon> </v-btn>
                    </template>
                    <v-list rounded dense>
                        <!-- <v-list-item @click="openGroupDialog(item)"> <v-icon class="mr-2">mdi-refresh</v-icon> <v-list-item-title>Change Group</v-list-item-title> </v-list-item> -->
                        <v-list-item @click="openDeleteDialog(item)"> <v-icon class="mr-2">mdi-delete-circle</v-icon> <v-list-item-title>Delete User</v-list-item-title> </v-list-item>
                        <v-list-item v-if="item.verification_status == 0" @click="resendVerificationMail(item)">
                            <v-icon class="mr-2">mdi-send-circle-outline</v-icon><v-list-item-title>Resend verification mail</v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item v-if="content_suite_allowed && item.content_suite" @click="changeCs(item)">
                            <v-icon class="mr-2">mdi-newspaper-minus</v-icon> <v-list-item-title>Disable Content Suite</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="content_suite_allowed && !item.content_suite" @click="changeCs(item)">
                            <v-icon class="mr-2">mdi-newspaper-plus</v-icon> <v-list-item-title>Enable Content Suite</v-list-item-title>
                        </v-list-item> -->
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import AddUserDialog from "../dialogs/AddUserDialog"

    export default {
        name: "UserTable",
        components: { AddUserDialog },
        props: {
            domains: Array
        },
        data() {
            return {
                info: {
                    message: null,
                    icon: null,
                    color: null
                },
                showInfo: false,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                changeGroupDialog: false,
                deleteDialog: false,
                content_suite_allowed: false,
                deleteUserQuestion: false,
                showchangeMessage: false,
                changeMessage: "User Group was successfully changed!",
                selectedUser: {
                    username: ""
                },
                addUserDisabled: true,
                addUserDialog: false,
                search: "",
                newgroup: null,
                selected_standard_group: {},
                groups: [],
                cs_groups: [],
                ms_grous: [],
                user: [],
                header: [
                    {
                        text: "NAME",
                        align: "start",
                        sortable: true,
                        value: "username"
                    },
                    // {
                    //     text: "COMPANY ADMIN",
                    //     align: "start",
                    //     sortable: false,
                    //     value: "admin"
                    // },
                    {
                        text: "RESEARCH SUITE",
                        align: "start",
                        sortable: false,
                        value: "group_id"
                    },
                    {
                        text: "CONTENT SUITE",
                        align: "start",
                        sortable: false,
                        value: "content_suite_group_id"
                    },
                    {
                        text: "MONITORING SUITE",
                        align: "start",
                        sortable: false,
                        value: "monitoring_suite_group_id"
                    },
                    // {
                    //     text: "GROUP",
                    //     align: "start",
                    //     sortable: false,
                    //     value: "group_name"
                    // },
                    {
                        text: "MAIL VERIFICATED",
                        align: "center",
                        sortable: false,
                        value: "verification_status_translated"
                    },
                    {
                        text: "",
                        align: "end",
                        sortable: false,
                        value: "action"
                    }
                ]
            }
        },
        computed: {
            lutz() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) == 32
            },
            company_status_cs() {
                return this.$store.state.company_status_content_suite
            },
            company_status_ms() {
                return this.$store.state.company_status_monitoring_suite
            }
        },
        methods: {
            async getStandardGroup() {
                try {
                    const url = process.env.VUE_APP_APIURL + "/company/standardgroup/get"
                    let post_data = {
                        company_id: localStorage.company_id
                    }
                    let answer = await this.$helpers.axiosPost(url, post_data)
                    console.log(answer)
                    console.log(this.groups)
                    this.selected_standard_group = this.groups[this.groups.findIndex((x) => x.id == answer.data.standard_group)]
                    console.log("HEEEERE", this.selected_standard_group)
                } catch (error) {
                    console.log(error)
                }
            },
            async changeStandardGroup() {
                console.log(this.selected_standard_group)
                try {
                    const url = process.env.VUE_APP_APIURL + "/company/standardgroup/switch"
                    let post_data = {
                        company_id: localStorage.company_id,
                        group: this.selected_standard_group.id
                    }
                    await this.$helpers.axiosPost(url, post_data)
                } catch (error) {
                    console.log(error)
                }
            },
            async changeAccess(suite, item) {
                let logout_user = true
                if (item.id == this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)) {
                    logout_user = false
                }
                let url = process.env.VUE_APP_APIURL + "/user/suite/switch"
                let post_data = {
                    id: item.id,
                    status_new: suite == "rs" ? (item.research_suite == 0 ? 1 : 0) : suite == "cs" ? (item.content_suite == 0 ? 1 : 0) : item.monitoring_suite == 0 ? 1 : 0,
                    logout_user: logout_user,
                    suite: suite
                }
                let response = await this.$helpers.axiosPost(url, post_data)
                console.log("STATUS NEW: ", post_data.status_new)
                console.log(this.user[this.user.findIndex((x) => x.id == item.id)])
                console.log(this.user[this.user.findIndex((x) => x.id == item.id)][suite == "rs" ? "research_suite" : suite == "cs" ? "content_suite" : "monitoring_suite"])
                let userindex = this.user.findIndex((x) => x.id == item.id)
                if (userindex > -1) {
                    this.user[userindex][suite == "rs" ? "research_suite" : suite == "cs" ? "content_suite" : "monitoring_suite"] = post_data.status_new
                    this.user[userindex].admin = 0
                }

                if (item.id == this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)) {
                    this.$store.dispatch("FETCH_STARTUP_DATA", item.user)
                }
                console.log(response)
                this.showchangeMessage = true
            },
            async grantAdmin(item) {
                let logout_user = true
                if (item.id == this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)) {
                    logout_user = false
                }
                let url = process.env.VUE_APP_APIURL + "/user/admin/switch"
                let post_data = {
                    id: item.id,
                    logout_user: logout_user,
                    new_status: 1
                }
                let response = await this.$helpers.axiosPost(url, post_data)
                console.log(this.user.findIndex((x) => x.id == item.id))
                let userindex = this.user.findIndex((x) => x.id == item.id)
                if (userindex > -1) {
                    this.user[userindex].admin = 1
                    this.user[userindex].research_suite = 1
                    this.user[userindex].content_suite = 1
                    this.user[userindex].monitoring_suite = 1
                }

                if (item.id == this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)) {
                    this.$store.dispatch("FETCH_STARTUP_DATA", item.user)
                }
                console.log(response)
                this.showchangeMessage = true
            },
            createInfo(message, color, icon) {
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },
            resendVerificationMail(item) {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/user/verificate/mail", { email: item.user }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        if (response.status == 200) {
                            this.createInfo("Email has been resent. The user should receice an email with an activation-link!", "green", "mdi-check-circle-outline")
                        } else {
                            this.createInfo("There went something wrong. Please contact the support!", "#fcba03", "mdi-alert-circle-outline")
                        }
                    })
                    .catch((error) => {
                        this.createInfo("There went something wrong. Please contact the support!", "#fcba03", "mdi-alert-circle-outline")
                    })
            },
            openDeleteDialog(item) {
                this.selectUser(item)
                this.deleteUserQuestion = true
            },
            openGroupDialog(item) {
                this.selectUser(item)
                this.changeGroupDialog = true
            },
            changeGroupV2(suite, item, id) {
                let logoutUser = true
                if (item.id == this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)) {
                    logoutUser = false
                }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/user/group/switch", { id: item.id, group_id: id, logoutUser: logoutUser, suite: suite }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        if (item.id == this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)) {
                            this.$store.dispatch("FETCH_STARTUP_DATA", item.user)
                        }
                        // this.changeGroupDialog = false
                        // this.fetchUser()
                        let userindex = this.user.findIndex((x) => x.id == item.id)
                        if (userindex > -1) {
                            this.user[userindex][suite == "rs" ? "group_id" : suite == "cs" ? "content_suite_group_id" : "monitoring_suite_group_id"] = id
                            this.user[userindex][suite == "rs" ? "research_suite" : suite == "cs" ? "content_suite" : "monitoring_suite"] = 1
                            this.user[userindex].admin = 0
                            // if author, change rs and ms to false
                            if (suite == "cs" && id == 2) {
                                this.user[userindex].research_suite = 0
                                this.user[userindex].monitoring_suite = 0
                            }
                        }

                        console.log(this.user)
                        this.showchangeMessage = true
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            selectUser(userrow) {
                this.selectedUser = userrow
            },
            deleteUser() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/user/deleteone", { id: this.selectedUser.id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        this.deleteUserQuestion = false
                        this.fetchUser()
                        this.createInfo("The selected user has been deleted!", "green", "mdi-check-circle-outline")
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            fetchUser() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/user/companyuser", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        console.log(response)
                        // let admins = response.body.filter((x) => x.group_id == 1)
                        // let cs_allowed_admins = admins.filter((x) => x.content_suite)
                        // if (cs_allowed_admins.length) this.content_suite_allowed = true
                        this.fetchGroups(response.body)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },

            fetchGroups(users) {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/rights/groups/company", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let mappedUser = users.map((x) => {
                            if (x.verification_status == 1) {
                                x.verification_status_translated = "yes"
                            } else {
                                x.verification_status_translated = "no"
                            }
                            return x
                        })

                        //hide admin accounts in customer databases
                        if (parseInt(this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)) > 2) {
                            this.user = mappedUser.filter((x) => x.id > 4)
                        } else {
                            this.user = mappedUser
                        }

                        // let mappedGroups = response.body.standard_groups.map((x) => {
                        //     // console.log(x)
                        //     let obj = {}
                        //     obj.text = x.name
                        //     obj.value = x.id
                        //     obj.description = x.description
                        //     return obj
                        // })

                        //remove when guest role should appear
                        // mappedGroups.length = 5

                        this.groups = response.body.standard_groups
                        console.log(this.groups)
                        // filter author from cs groups
                        this.cs_groups = response.body.cs_groups
                        // .filter((x) => x.id !== 2)
                        this.ms_groups = response.body.ms_groups
                        this.getStandardGroup()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            hideColumns() {
                if (this.company_status_cs !== "active" && this.company_status_cs !== "testing") {
                    this.header.splice(
                        this.header.findIndex((x) => x.value == "content_suite_group_id"),
                        1
                    )
                }
                if (this.company_status_ms !== "active" && this.company_status_ms !== "testing") {
                    this.header.splice(
                        this.header.findIndex((x) => x.value == "monitoring_suite_group_id"),
                        1
                    )
                }
            }
            // fetchCsStatus() {
            //     this.$http
            //         .post(process.env.VUE_APP_APIURL + "/cs/status", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
            //         .then((response) => {
            //             console.log(response)
            //             console.log(response.body.cs_allowed)
            //             this.content_suite_allowed = response.body.cs_allowed
            //         })
            //         .catch((err) => {
            //             console.log(err)
            //         })
            // }
        },
        mounted() {
            this.hideColumns()
            if (this.lutz) {
                let headerIndex = this.header.findIndex((e) => e.value == "monitoring_suite_group_id")
                if (headerIndex > -1) this.header[headerIndex].text = "QUICK SEO"
            }
            // this.fetchCsStatus()
            this.fetchUser()
        }
    }
</script>
