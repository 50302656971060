module.exports = {
    getStandardParams() {
        var alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ä", "ö", "ü"]
        var numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        return { alphabet: alphabet, numbers: numbers }
    },

    // Build params for sending to google api
    // Combinations:
    // keyword, keyword + alphabet, keyword + numbers
    buildParamsEbay(keyword, shop) {
        var standardParams = this.getStandardParams()
        var search = []
        var urls = []

        search.push(keyword)
        urls.push({ url: "https://autosug.ebay.com/autosug?&kwd=" + keyword + "&sId=" + shop, platform: "ebay" })
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(keyword + standardParams.alphabet[i])
            urls.push({ url: "https://autosug.ebay.com/autosug?&kwd=" + keyword + standardParams.alphabet[i] + "&sId=" + shop, platform: "ebay" })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(keyword + standardParams.numbers[i])
            urls.push({ url: "https://autosug.ebay.com/autosug?&kwd=" + keyword + standardParams.numbers[i] + "&sId=" + shop, platform: "ebay" })
        }

        // //console.log(search)

        let params = {
            search: search
        }
        return { params: params, urls: urls, keyword: keyword }
    }
}
