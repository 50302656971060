var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5"},[_c('v-snackbar',{attrs:{"top":"","color":_vm.notification.color},model:{value:(_vm.notification.show),callback:function ($$v) {_vm.$set(_vm.notification, "show", $$v)},expression:"notification.show"}},[_c('v-icon',[_vm._v(_vm._s(_vm.notification.icon))]),_c('span',{staticClass:"font-weight-bold pl-2"},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")])],1),_c('v-sheet',{staticClass:"pa-4 pt-7"},[_c('v-text-field',{attrs:{"label":"Search Categories","flat":"","rounded":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[(_vm.categories_loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","rounded":"","color":"darkgreen"}}):_vm._e(),(_vm.categories_type == 2 && !_vm.categories_loading)?_c('v-tooltip',{attrs:{"content-class":"tooltip_round","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"color":"darkgreen","fab":"","small":""},on:{"click":_vm.addTopLevelCategory}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus-circle")])],1)]}}],null,false,4098799399)},[_c('span',[_vm._v(_vm._s(_vm.addButtonTooltip))])]):_vm._e(),(!_vm.categories_loading)?_c('v-btn-toggle',{staticClass:"mb-5 ml-3",attrs:{"color":"darkgreen","rounded":"","borderless":""},model:{value:(_vm.categories_type),callback:function ($$v) {_vm.categories_type=$$v},expression:"categories_type"}},[_c('v-btn',{attrs:{"small":"","value":"1"},on:{"click":function($event){return _vm.resetCats(_vm.googleCats)}}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Google Categories")])]),_c('v-btn',{attrs:{"small":"","value":"2"},on:{"click":function($event){return _vm.resetCats(_vm.customCats)}}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Custom Categories")])])],1):_vm._e(),_c('v-treeview',{attrs:{"expand-icon":"mdi-chevron-down","rounded":"","hoverable":"","items":_vm.tree,"search":_vm.search,"filter":_vm.filter,"open":_vm.open},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-chip',{attrs:{"color":"darkgreen","dark":"","small":""}},[_vm._v(" Level "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getIcon(open, item))+" ")])],1)]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"id":"catrow"}},[(item.edit)?_c('v-text-field',{ref:"edit",staticClass:"cat_text_field",attrs:{"autofocus":"","dense":"","hide-details":"","flat":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveChanges(item)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}):_c('span',{staticClass:"pt-1"},[_vm._v(_vm._s(item.name))]),(item.edit)?_c('span',[_c('v-tooltip',{attrs:{"content-class":"tooltip_round","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.saveChanges(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.saveChangesTooltip))])]),(_vm.categories_type == 2)?_c('v-tooltip',{attrs:{"content-class":"tooltip_round","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteCategory(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.deleteCategoryTooltip))])]):_vm._e()],1):_vm._e(),(!item.edit)?_c('span',[_c('v-tooltip',{attrs:{"content-class":"tooltip_round","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setEdit(item, true)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"cat_actions",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.editCategoryTooltip))])]),(_vm.categories_type == 2)?_c('v-tooltip',{attrs:{"content-class":"tooltip_round","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.addChildCategory(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"cat_actions",attrs:{"small":""}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.addChildButtonTooltip))])]):_vm._e()],1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }