var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"top":"","color":_vm.notification.color},model:{value:(_vm.notification.show),callback:function ($$v) {_vm.$set(_vm.notification, "show", $$v)},expression:"notification.show"}},[_c('v-icon',[_vm._v(_vm._s(_vm.notification.icon))]),_c('span',{staticClass:"font-weight-bold pl-2"},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")])],1),_c('AddQuestionDialog',{attrs:{"show":_vm.addQuestionDialogOptions.show},on:{"added":_vm.addQuestions,"close":function($event){_vm.addQuestionDialogOptions.show = false},"failed":function($event){_vm.notification = _vm.$helpers.createNotification('Something went wrong adding your question! Please try again or contact support.', 'mdi-alert', 'orange darken-2')
            _vm.addQuestionDialogOptions.question_fetch_loading = false}}}),(_vm.setup)?_c('div',[_c('h2',{staticStyle:{"font-weight":"300"}},[_vm._v("Select Questions")]),_c('p',[_vm._v("For a comprehensive content it is important to answer questions and problems of the readers. Choose some questions you want to answer with your content.")])]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ml-auto",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"ml-auto mb-2",attrs:{"rounded":"","color":"primary","loading":_vm.addQuestionDialogOptions.question_fetch_loading},on:{"click":function($event){_vm.addQuestionDialogOptions.show = true}}},[_vm._v("enter manually")]),_c('span',{staticClass:"mt-2 mx-3"},[_vm._v("or")]),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.addFromClipboard}},[_vm._v("add from clipboard")])],1)],1)],1),_c('div',{staticClass:"questions-table-area"},[_c('v-row',{staticClass:"pa-4"},[_c('span',[_c('v-chip',{staticClass:"white--text",attrs:{"color":"darkgreen","small":""}},[_vm._v(" "+_vm._s(_vm.selected.length)+" selected")])],1),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"clearable":"","clear-icon":"mdi-close","dense":"","rounded":"","filled":"","max-width":"300px","prepend-inner-icon":"mdi-magnify","label":"search keywords...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0 comp-table",attrs:{"disabled":"","id":"cs_comp_table","show-select":"","search":_vm.search,"fixed-header":"","height":"550px","loading":!_vm.questions.length,"headers":_vm.headers,"items":_vm.questions,"item-key":"id"},on:{"toggle-select-all":_vm.selectPage},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header_entry,index){return {key:("header." + (header_entry.value)),fn:function(ref){
            var header = ref.header;
return [_c('span',{key:index},[_c('span',{style:({ width: header.width })},[_c('v-tooltip',{attrs:{"max-width":"250","color":"primary","top":"","disabled":header.tooltip ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"quarotblheader"},on),[_vm._v(" "+_vm._s(header.text)+" "),(header.tooltip)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-help")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-help-circle-outline")])],1)]),_vm._v(" "+_vm._s(header.tooltip)+" ")],1)])],1)])]}}}),{key:"body",fn:function(ref){
            var items = ref.items;
return [(items.length)?_c('tbody',_vm._l((items),function(item,itemIndex){return _c('tr',{key:itemIndex,class:item.selected ? 'highlighted' : ''},[_c('td',{staticClass:"selecttd"},[(!item.selected)?_c('v-icon',{staticStyle:{"margin":"0px","padding":"0px"},on:{"click":function($event){return _vm.selectItem(itemIndex, item)}}},[_vm._v("mdi-checkbox-blank-outline")]):_c('v-icon',{staticStyle:{"margin":"0px","padding":"0px"},on:{"click":function($event){return _vm.selectItem(itemIndex, item)}}},[_vm._v("mdi-checkbox-marked")])],1),_vm._l((_vm.headers),function(header,headerIndex){return _c('td',{key:headerIndex,staticClass:"table_td"},[(header.value === 'question')?_c('div',{staticClass:"td_question"},[_vm._v(" "+_vm._s(item[header.value])+" ")]):_vm._e(),(header.value === 'question_word')?_c('div',_vm._b({},'div',(_vm.question = _vm.$helpers.getQuestionWord(item)),false),[_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.question.color}},[_vm._v(" "+_vm._s(_vm.question.text)+" ")])],1):_vm._e(),(header.value === 'source')?_c('div',[_c('v-chip',{staticClass:"white--text",attrs:{"color":item.user_input ? 'teal darken-1' : _vm.returnSource(item.tags).color,"small":""}},[_vm._v(_vm._s(item.user_input ? "User Input" : _vm.returnSource(item.tags).text))])],1):_vm._e()])})],2)}),0):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }