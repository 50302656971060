module.exports = {
    getStandardParams() {
        var alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ä", "ö", "ü"]
        var numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        return { alphabet: alphabet, numbers: numbers }
    },

    // Build params for sending to google api
    // Combinations:
    // numbers+keyword , alphabet+keyword , keywordAlphabet , keywordNumbers
    // Needed Params:
    // cp: length + 1
    // client: response type
    // ds: ?
    buildParamsGoogle(keyword, language, location) {
        var standardParams = this.getStandardParams()
        var search = []
        var cp = []
        var urls = []

        search.push(keyword)
        cp.push(keyword.length + 1)
        urls.push({
            url: "https://suggestqueries.google.com/complete/search?=&q=" + keyword + "&client=firefox&hl=" + language + "&gl=" + location + "&cp=" + (keyword.length + 1) + "&ds=",
            platform: "google"
        })

        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(keyword + standardParams.alphabet[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.alphabet[i] +
                    "&client=firefox&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=",
                platform: "google"
            })
        }
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(standardParams.alphabet[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url: "https://suggestqueries.google.com/complete/search?=&q=" + standardParams.alphabet[i] + "+" + keyword + "&client=firefox&hl=" + language + "&gl=" + location + "&cp=" + 1 + "&ds=",
                platform: "google"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(keyword + standardParams.numbers[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.numbers[i] +
                    "&client=firefox&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=",
                platform: "google"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(standardParams.numbers[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url: "https://suggestqueries.google.com/complete/search?=&q=" + standardParams.numbers[i] + "+" + keyword + "&client=firefox&hl=" + language + "&gl=" + location + "&cp=" + 1 + "&ds=",
                platform: "google"
            })
        }

        let params = {
            search: search,
            cp: cp,
            ds: "",
            client: "firefox"
        }
        return { params: params, urls: urls, keyword: keyword }
    },
    /**
     * rework questions
     * @param {*} keyword
     * @param {*} selectedQuestion
     * @param {*} language
     * @param {*} location
     * @returns
     */
    buildParamsGoogleQuestionsV2(keyword, selectedQuestion, language, location) {
        // var standardParams = this.getStandardParams()
        var search = []
        var urls = []
        console.log(location)

        let questionParams = selectedQuestion.questionParams
        // let questionParams = ["das", "dass", "der", "die", "wann", "warum", "was", "welche", "welcher", "welches", "wer", "wie", "wo", "wohin"]
        let prepositionParams = selectedQuestion.prepositionParams
        // let prepositionParams = ["ähnlich", "an", "auf", "bei", "bis", "für", "mit", "nach", "nahe", "ohne", "wie", "zu"]
        let comparisonParams = selectedQuestion.comparisonParams
        // let comparissonParams = ["gegen", "gleich", "oder", "und", "vs"]

        let allParams = [...questionParams, ...prepositionParams, ...comparisonParams]
        console.log(allParams)
        for (var j = 0; j < allParams.length; j++) {
            search.push(allParams[j] + " " + keyword)
            urls.push({
                url: "https://suggestqueries.google.com/complete/search?=&q=" + allParams[j] + " " + keyword + "&client=firefox&hl=" + language + "&gl=" + location + "&ds=",
                platform: "google"
            })

            search.push(keyword + " " + allParams[j] + " ")
            urls.push({
                url: "https://suggestqueries.google.com/complete/search?=&q=" + keyword + " " + allParams[j] + " " + "&client=firefox&hl=" + language + "&gl=" + location + "&ds=",
                platform: "google"
            })
        }
        console.log("anzahl requests: ", search.length)
        // var that =this;
        // var cp = search.map(function (word) {
        //     return word.length - 1 - keyword.length
        // })
        // //console.log(search)
        // //console.log(cp)

        let params = {
            search: search,
            // cp: cp,
            ds: "",
            client: "firefox"
        }
        return { params: params, urls: urls, keyword: keyword }
    },

    // Build params for sending to google api for w-questions
    // Combinations:
    // ?
    // Needed Params:
    // cp: ?
    // client: response type
    // ds: ?
    buildParamsGoogleQuestions(keyword, selectedQuestion, language, location) {
        var standardParams = this.getStandardParams()
        var search = []
        var urls = []

        for (var j = 0; j < selectedQuestion.length; j++) {
            search.push(selectedQuestion[j].question + "+" + keyword)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    selectedQuestion[j].question +
                    "+" +
                    keyword +
                    "&client=firefox&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    ((selectedQuestion[j].question + "+" + keyword).length - 1 - keyword.length) +
                    "&ds=",
                platform: "google"
            })
            for (var i = 0; i < standardParams.alphabet.length; i++) {
                search.push(selectedQuestion[j].question + "+" + standardParams.alphabet[i] + "+" + keyword)
                urls.push({
                    url:
                        "https://suggestqueries.google.com/complete/search?=&q=" +
                        selectedQuestion[j].question +
                        "+" +
                        standardParams.alphabet[i] +
                        "+" +
                        keyword +
                        "&client=firefox&hl=" +
                        language +
                        "&gl=" +
                        location +
                        "&cp=" +
                        ((selectedQuestion[j].question + "+" + standardParams.alphabet[i] + "+" + keyword).length - 1 - keyword.length) +
                        "&ds=",
                    platform: "google"
                })
            }
            for (var i = 0; i < standardParams.numbers.length; i++) {
                search.push(selectedQuestion[j].question + "+" + standardParams.numbers[i] + "+" + keyword)
                urls.push({
                    url:
                        "https://suggestqueries.google.com/complete/search?=&q=" +
                        selectedQuestion[j].question +
                        "+" +
                        standardParams.numbers[i] +
                        "+" +
                        keyword +
                        "&client=firefox&hl=" +
                        language +
                        "&gl=" +
                        location +
                        "&cp=" +
                        ((selectedQuestion[j].question + "+" + standardParams.numbers[i] + "+" + keyword).length - 1 - keyword.length) +
                        "&ds=",
                    platform: "google"
                })
            }
        }

        // var that =this;
        var cp = search.map(function (word) {
            return word.length - 1 - keyword.length
        })
        // //console.log(search)
        // //console.log(cp)

        let params = {
            search: search,
            cp: cp,
            ds: "",
            client: "firefox"
        }
        return { params: params, urls: urls, keyword: keyword }
    },

    // Build params for sending to google api for images
    // Combinations:
    // ?
    // Needed Params:
    // cp: ?
    // client: response type
    // ds: ?
    buildParamsGoogleImages(keyword, language, location) {
        var standardParams = this.getStandardParams()
        var search = []
        var cp = []
        var urls = []

        search.push(keyword)
        cp.push(keyword.length + 1)
        urls.push({
            url: "https://suggestqueries.google.com/complete/search?=&q=" + keyword + "&client=img&hl=" + language + "&gl=" + location + "&cp=" + (keyword.length + 1) + "&ds=",
            platform: "images"
        })
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(keyword + standardParams.alphabet[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.alphabet[i] +
                    "&client=img&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=",
                platform: "images"
            })
        }
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(standardParams.alphabet[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url: "https://suggestqueries.google.com/complete/search?=&q=" + standardParams.alphabet[i] + "+" + keyword + "&client=img&hl=" + language + "&gl=" + location + "&cp=" + 1 + "&ds=",
                platform: "images"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(keyword + standardParams.numbers[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.numbers[i] +
                    "&client=img&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=",
                platform: "images"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(standardParams.numbers[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url: "https://suggestqueries.google.com/complete/search?=&q=" + standardParams.numbers[i] + "+" + keyword + "&client=img&hl=" + language + "&gl=" + location + "&cp=" + 1 + "&ds=",
                platform: "images"
            })
        }

        let params = {
            search: search,
            cp: cp,
            ds: "",
            client: "img"
        }
        return { params: params, urls: urls, keyword: keyword }
    },

    // Build params for sending to google api for images
    // Combinations:
    // ?
    // Needed Params:
    // cp: ?
    // client: response type
    // ds: ?
    buildParamsGoogleShopping(keyword, language, location) {
        var standardParams = this.getStandardParams()
        var search = []
        var cp = []
        var urls = []

        search.push(keyword)
        cp.push(keyword.length + 1)
        urls.push({
            url: "https://suggestqueries.google.com/complete/search?=&q=" + keyword + "&client=products-cc&hl=" + language + "&gl=" + location + "&cp=" + (keyword.length + 1) + "&ds=sh",
            platform: "shopping"
        })

        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(keyword + standardParams.alphabet[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.alphabet[i] +
                    "&client=products-cc&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=sh",
                platform: "shopping"
            })
        }
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(standardParams.alphabet[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    standardParams.alphabet[i] +
                    "+" +
                    keyword +
                    "&client=products-cc&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    1 +
                    "&ds=sh",
                platform: "shopping"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(keyword + standardParams.numbers[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.numbers[i] +
                    "&client=products-cc&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=sh",
                platform: "shopping"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(standardParams.numbers[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    standardParams.numbers[i] +
                    "+" +
                    keyword +
                    "&client=products-cc&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    1 +
                    "&ds=sh",
                platform: "shopping"
            })
        }

        let params = {
            search: search,
            cp: cp,
            ds: "sh",
            client: "products-cc"
        }
        return { params: params, urls: urls, keyword: keyword }
    },
    // Build params for sending to google api for youtube
    // Combinations:
    // ?
    // Needed Params:
    // cp: ?
    // client: response type
    // ds: ?
    buildParamsYoutube(keyword, language, location) {
        var standardParams = this.getStandardParams()
        var search = []
        var cp = []
        var urls = []

        search.push(keyword)
        cp.push(keyword.length + 1)
        urls.push({
            url: "https://suggestqueries.google.com/complete/search?=&q=" + keyword + "&client=youtube&hl=" + language + "&gl=" + location + "&cp=" + (keyword.length + 1) + "&ds=yt",
            platform: "youtube"
        })
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(keyword + standardParams.alphabet[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.alphabet[i] +
                    "&client=youtube&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=yt",
                platform: "youtube"
            })
        }
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(standardParams.alphabet[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    standardParams.alphabet[i] +
                    "+" +
                    keyword +
                    "&client=youtube&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    1 +
                    "&ds=yt",
                platform: "youtube"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(keyword + standardParams.numbers[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.numbers[i] +
                    "&client=youtube&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=yt",
                platform: "youtube"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(standardParams.numbers[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" + standardParams.numbers[i] + "+" + keyword + "&client=youtube&hl=" + language + "&gl=" + location + "&cp=" + 1 + "&ds=yt",
                platform: "youtube"
            })
        }

        let params = {
            search: search,
            cp: cp,
            ds: "yt",
            client: "youtube"
        }
        return { params: params, urls: urls, keyword: keyword }
    },
    // Build params for sending to google api for Google News
    // Combinations:
    // ?
    // Needed Params:
    // cp: ?
    // client: response type
    // ds: ?
    buildParamsGoogleNews(keyword, language, location) {
        var standardParams = this.getStandardParams()
        var search = []
        var cp = []
        var urls = []

        search.push(keyword)
        cp.push(keyword.length + 1)
        urls.push({
            url: "https://suggestqueries.google.com/complete/search?=&q=" + keyword + "&client=news-cc&hl=" + language + "&gl=" + location + "&cp=" + (keyword.length + 1) + "&ds=n",
            platform: "news"
        })
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(keyword + standardParams.alphabet[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.alphabet[i] +
                    "&client=news-cc&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=n",
                platform: "news"
            })
        }
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(standardParams.alphabet[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" + standardParams.alphabet[i] + "+" + keyword + "&client=news-cc&hl=" + language + "&gl=" + location + "&cp=" + 1 + "&ds=n",
                platform: "news"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(keyword + standardParams.numbers[i])
            cp.push(keyword.length + 2)
            urls.push({
                url:
                    "https://suggestqueries.google.com/complete/search?=&q=" +
                    keyword +
                    standardParams.numbers[i] +
                    "&client=news-cc&hl=" +
                    language +
                    "&gl=" +
                    location +
                    "&cp=" +
                    (keyword.length + 2) +
                    "&ds=n",
                platform: "news"
            })
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(standardParams.numbers[i] + "+" + keyword)
            cp.push(1)
            urls.push({
                url: "https://suggestqueries.google.com/complete/search?=&q=" + standardParams.numbers[i] + "+" + keyword + "&client=news-cc&hl=" + language + "&gl=" + location + "&cp=" + 1 + "&ds=n",
                platform: "news"
            })
        }

        let params = {
            search: search,
            cp: cp,
            ds: "n",
            client: "news-cc"
        }
        return { params: params, urls: urls, keyword: keyword }
    }
}
