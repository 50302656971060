<template>
    <div>
        <div class="pa-2">
            <!--UPLOAD-->
            <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                <div class="dropbox">
                    <input v-if="showInput" type="file" :name="uploadFieldName" :disabled="isSaving" @input="handleFileChange" accept=".xls,.xlsx,.csv" class="input-file" />
                    <p v-if="isInitial && !selectedFile">
                        Drag your file(s) here to begin<br />
                        or click to browse
                    </p>
                    <p v-if="isSaving"></p>
                    <div v-if="selectedFile" class="d-flex filewrapper">
                        <div class="selectedfile ma-auto">
                            <!-- :value="uploadProgress" -->
                            <!-- :indeterminate="uploadProgress == 100 ? true : false" -->

                            <v-progress-circular class="mr-2" v-if="isSaving" indeterminate color="primary" height="10"></v-progress-circular>
                            <v-icon color="primary" v-else class="mr-2">mdi-file</v-icon>
                            {{ selectedFile.name.length > 40 ? selectedFile.name.substring(0, 40) + "..." : selectedFile.name }}
                            <v-btn icon v-if="!isSaving && !isSuccess" @click="reset" class="ml-5"><v-icon>mdi-close</v-icon></v-btn>
                        </div>
                    </div>
                </div>
            </form>
            <!-- <div v-if="isSuccess">Upload finshed <v-icon>mdi-check-circle-outline</v-icon></div> -->
        </div>
        <div class="d-flex mt-4">
            <v-btn color="primary" :disabled="isSaving || !selectedFile" class="mt-2 ml-auto" @click="createProject">create project</v-btn>
        </div>
    </div>
</template>

<script>
    import axios from "axios"

    const STATUS_INITIAL = 0,
        STATUS_SAVING = 1,
        STATUS_SUCCESS = 2,
        STATUS_FAILED = 3
    export default {
        props: {
            project: Object,
            url: String,
            data: Object,
            categorized: Boolean
        },
        data() {
            return {
                file: null,
                uploadError: null,
                currentStatus: null,
                uploadFieldName: "photos",
                selectedFile: null,
                showInput: true,
                uploadProgress: 0
            }
        },
        computed: {
            isInitial() {
                return this.currentStatus === STATUS_INITIAL
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED
            }
        },
        methods: {
            reset() {
                // reset form to initial state
                this.showInput = false
                console.log("hi")
                this.currentStatus = STATUS_INITIAL
                this.selectedFile = null
                this.uploadError = null
                this.$emit("fileLoaded", false)

                setTimeout(() => {
                    this.showInput = true
                }, 300)
            },

            handleFileChange(event) {
                console.log("change")
                this.$emit("fileLoaded", true)
                this.selectedFile = event.target.files[0]
                console.log(this.selectedFile)
            },
            // async createProject() {
            //     try {

            //         console.log(this.project)
            //         this.project.created = true
            //         this.project.created_date = new Date()
            //         // await axios post create project
            //         const url = process.env.VUE_APP_MONITORING_API_URL + "/project/create"
            //         let post_data = {
            //             company_id: localStorage.company_id,
            //             projectobject: this.project
            //         }
            //         let response_data = await this.$helpers.axiosPost(url, post_data)
            //         if (response_data.data.created) {
            //             this.project = response_data.data.resp
            //             this.e1++
            //         } else {
            //             this.notification = this.$helpers.createNotification("There is already a project with this name! Pleas select another one.", "mdi-alert", "orange darken-2")
            //         }
            //     } catch (error) {
            //         console.log(error)
            //         this.notification = this.$helpers.createNotification("There was an error creating your project. Please try again or contact the support!", "mdi-alert", "orange darken-2")
            //     }
            // },
            createProject() {
                let type = this.selectedFile.name.split(".")[this.selectedFile.name.split(".").length - 1]
                console.log(type)
                if (type !== "xls" && type !== "xlsx" && type !== "csv") {
                    this.$emit("createNotification", "File type not supported!", "mdi-alert-circle-outline", "#FCBA03")
                    this.selectedFile = null
                    return
                }

                const config = {
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    },
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
                this.currentStatus === STATUS_INITIAL
                const formData = new FormData()
                console.log(this.selectedFile)
                formData.append("file", this.selectedFile)
                formData.append("project", JSON.stringify(this.project))
                formData.append("company_id", localStorage.company_id)
                if (this.data) {
                    formData.append("data", JSON.stringify(this.data))
                }
                this.currentStatus = STATUS_SAVING
                axios
                    .post(this.url, formData, config)
                    .then((response) => {
                        console.log(response)
                        // Erfolgreich hochgeladen
                        this.$emit("success")
                        this.currentStatus = STATUS_SUCCESS
                        console.log(response.data)
                    })
                    .catch((error) => {
                        // Fehler beim Hochladen
                        console.error(error)
                        this.uploadError = err.response
                        this.currentStatus = STATUS_FAILED
                    })
            }
        },
        mounted() {
            this.reset()
        }
    }
</script>

<style lang="scss">
    .dropbox {
        outline: 2px dashed grey;
        outline-offset: 5px;
        background: #f6f6f6;
        color: dimgray;
        padding: 10px 10px;
        min-height: 100px;
        max-height: 200px;
        position: relative;
        cursor: pointer;
        border-radius: 10px;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100px;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: #c0c0c0; /* when mouse over to the drop zone, change color */
        opacity: 0.5;
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 25px 0;
    }
    .selectedfile {
        border-radius: 10px;
        border: 1px solid grey;
        padding: 5px 0px 5px 15px;
        // background-color: rgba(41, 233, 41, 0.387);
    }
    .filewrapper {
        height: 100px;
    }
</style>
