var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-table"},[_c('v-snackbar',{attrs:{"top":"","color":_vm.notification.color},model:{value:(_vm.notification.show),callback:function ($$v) {_vm.$set(_vm.notification, "show", $$v)},expression:"notification.show"}},[_c('v-icon',[_vm._v(_vm._s(_vm.notification.icon))]),_c('span',{staticClass:"font-weight-bold pl-2"},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")])],1),_c('EditProject',{attrs:{"editDialog":_vm.editProjectDialog,"all_collections":_vm.researches,"project_data":_vm.selectedProject},on:{"close":function($event){_vm.editProjectDialog = false},"renewData":_vm.renewData}}),_c('DeleteDialog',{attrs:{"deleteDialog":_vm.deleteDialog,"selectedDeleteItem":_vm.selectedDeleteItem ? _vm.selectedDeleteItem.project : null,"deleteLabel":"Project","loading":_vm.deleteLoading},on:{"delete":_vm.deleteProject,"close":function($event){_vm.deleteDialog = false}}}),_c('AddProjectsDialog',{attrs:{"all_collections":_vm.researches,"projectsDialog":_vm.projectsDialog},on:{"created":function($event){return _vm.$emit('renewData')},"close":function($event){_vm.projectsDialog = false}}}),(_vm.projects.length < 1)?_c('ProjectsBanner',{staticClass:"mt-5 mb-10",on:{"showAddDialog":function($event){{
                _vm.projectsDialog = true
            }}}}):_c('v-card',{staticClass:"rounded_card quaroshadow px-5 py-3 my-8 mb-10"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-sheet',{staticClass:"pa-12 quaroshadow rounded_card",attrs:{"color":"primary","max-height":"200","dark":"","top":"","id":"tablesheet","elevation":"6"}},[_c('div',{staticClass:"headingsheet2"},[_c('v-icon',{attrs:{"light":this.$vuetify.theme.dark ? true : false,"id":"sheeticon","large":""}},[_vm._v("mdi-folder-table")])],1)]),_c('v-card-title',{attrs:{"id":"heading3"}},[_vm._v(" All Projects ")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"rounded":"","filled":"","dense":"","append-icon":"mdi-magnify","label":"Search for project","single-line":"","hide-details":"","id":"kwsearch"},model:{value:(_vm.projectsSearch),callback:function ($$v) {_vm.projectsSearch=$$v},expression:"projectsSearch"}})],1),(_vm.$helpers.isActionAllowed(30, _vm.user_rights))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"fabicon",attrs:{"absolute":"","right":"","fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){_vm.projectsDialog = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,3786014975)},[_c('span',[_vm._v("create a project")])]):_vm._e(),_c('v-data-table',{staticClass:"row-pointer mt-5",attrs:{"id":"projtable","headers":_vm.headers,"loading":_vm.loading,"items":_vm.projects,"single-expand":_vm.singleExpand,"search":_vm.projectsSearch,"expanded":_vm.expanded,"sort-by":_vm.projects_sort_by,"sort-desc":_vm.projects_sort_desc,"items-per-page":_vm.projects_items_per_page,"item-key":"_id","show-expand":"","expand-icon":"mdi-chevron-down","multi-sort":""},on:{"update:search":function($event){_vm.projectsSearch=$event},"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.projects_sort_by=$event},"update:sort-by":function($event){_vm.projects_sort_by=$event},"update:sortDesc":function($event){_vm.projects_sort_desc=$event},"update:sort-desc":function($event){_vm.projects_sort_desc=$event},"update:itemsPerPage":function($event){_vm.projects_items_per_page=$event},"update:items-per-page":function($event){_vm.projects_items_per_page=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header_entry,index){return {key:("header." + (header_entry.value)),fn:function(ref){
            var header = ref.header;
return [_c('span',{key:index},[_c('span',{style:({ width: header.width })},[_c('v-tooltip',{attrs:{"max-width":"250","color":"primary","top":"","disabled":header.tooltip ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"quarotblheader"},on),[_vm._v(" "+_vm._s(header.text)+" "),(header.tooltip)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-help")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-help-circle-outline")])],1)]),_vm._v(" "+_vm._s(header.tooltip)+" ")],1)])],1)])]}}}),{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('SimpleResearchTable',{attrs:{"researches":_vm.getResearches(item)},on:{"renewData":_vm.renewData,"removeCollection":_vm.removeCollectionFromTable,"openShareMenu":_vm.openShareMenu,"initChangeNameDialog":_vm.initChangeNameDialog}})],1)]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"py-2",staticStyle:{"max-width":"800px"}},[_vm._v(_vm._s(item.description))])]}},{key:"item.project",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"project-title mb-1"},[_vm._v(_vm._s(item.project))]),_c('v-list-item-subtitle',{staticClass:"updated-subtitle",staticStyle:{"display":"flex"}},[_c('v-icon',{staticClass:"pr-1",attrs:{"x-small":""}},[_vm._v("mdi-clock-outline")]),_c('span',[_vm._v(" Updated "+_vm._s(item.updated_text)+" ")])],1)],1)],1)]}},{key:"item.owner",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-avatar',_vm._g({staticClass:"pr-0 avat",attrs:{"color":"primary","size":"30"}},on),[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.getInitials(item.owner_name)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.owner_name))])])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.status != 'creating' && item.status != 'updating')?_c('v-menu',{attrs:{"rounded":"lg","color":"primary","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.$helpers.isActionAllowed(32, _vm.user_rights) || _vm.$helpers.isActionAllowed(31, _vm.user_rights))?_c('v-btn',_vm._g({staticClass:"ml-auto",attrs:{"id":"cogbtn","icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1):_vm._e()]}}],null,true)},[_c('v-list',{attrs:{"rounded":"","dense":""}},[(item.status != 'creating' && item.status != 'updating' && _vm.$helpers.isActionAllowed(32, _vm.user_rights))?_c('v-list-item',{on:{"click":function($event){return _vm.showEditProjectDialog(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),(item.status != 'creating' && item.status != 'updating' && _vm.$helpers.isActionAllowed(31, _vm.user_rights))?_c('v-list-item',{on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e()],1)],1):_vm._e()]}},{key:"item.lists",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"small":"","icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-multiple-outline")]),_vm._v(_vm._s(item.lists.length)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.lists.length)+" assigned collections")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"small":"","icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-share-variant")]),_vm._v(_vm._s(item.shared_count)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.shared_count)+" shared collections")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-text-outline")]),_vm._v(_vm._s(item.comments_count))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comments_count)+" comments")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }