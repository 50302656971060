<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-card>
            <v-card-title style="font-size: 18px">
                {{ authors ? "Select responsible authors" : "Select Responsible" }}
                <v-btn right absolute icon @click="closeDialog" color="primary">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-tabs class="px-auto" v-model="selectedTab" color="primary">
                    <v-tab :disabled="index == 1" v-for="(tab, index) in tabs" :key="index">{{ tab }}</v-tab>
                </v-tabs>
                <!-- User Tab Content -->
                <v-tabs-items v-model="selectedTab">
                    <v-tab-item class="mt-5">
                        <v-autocomplete
                            close-on-content-click
                            min-height="80"
                            item-text="username"
                            item-value="id"
                            return-object
                            multiple
                            hide-details
                            rounded
                            filled
                            v-model="selectedUser"
                            :items="userOptions"
                            label="Search User">
                            <template v-slot:selection="{ item, index }">
                                <span class="">
                                    <v-chip class="pl-2 pr-1">
                                        <v-avatar size="24" style="font-size: 10px" :style="{ backgroundColor: $helpers.stringToBackgroundColor(item.username) }" class="white--text mr-2">{{
                                            $helpers.getInitials(item.username)
                                        }}</v-avatar>
                                        {{ item.username }}
                                        <v-btn class="ml-1" style="margin-top: 2px" x-small icon @click="selectedUser.splice(index, 1)"><v-icon x-small>mdi-close</v-icon></v-btn>
                                    </v-chip>
                                </span>
                            </template>
                            <template v-slot:item="{ item }">
                                <!-- <v-row>
                                    <div>{{ item.username }}</div>
                                </v-row>

                                <v-row>({{ item.user }})</v-row> -->

                                <v-list-item two-line>
                                    <v-list-item-icon class="mr-5 pt-4">
                                        <v-avatar size="32" style="font-size: 12px" :style="{ backgroundColor: $helpers.stringToBackgroundColor(item.username) }" class="white--text">{{
                                            $helpers.getInitials(item.username)
                                        }}</v-avatar>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.username }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-chip outlined color="priamry" small class="pl-2">
                                                <v-icon small class="mr-1" color="grey">mdi-email</v-icon>
                                                {{ item.user }}</v-chip
                                            >
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </template>
                        </v-autocomplete>
                    </v-tab-item>
                    <!-- Group Tab Content -->
                    <v-tab-item class="mt-5">
                        <v-autocomplete multiple hide-details rounded filled v-model="selectedGroup" :items="groupOptions" label="Search Group" dense></v-autocomplete>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>

            <v-card-actions>
                <v-btn class="mx-auto" @click="assignResponsible" rounded color="primary"> ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            authors: Boolean,
            value: Boolean,
            user: Array
        },
        data() {
            return {
                dialog: false,
                tabs: ["User", "Group"],
                selectedTab: "User",
                selectedUser: null,
                selectedGroup: null,
                userOptions: ["User1", "User2", "User3"], // Beispieloptionen, durch deine Daten ersetzen
                groupOptions: ["Group1", "Group2", "Group3"] // Beispieloptionen, durch deine Daten ersetzen
            }
        },
        watch: {
            user(val) {
                console.log(val)
                console.log(this.userOptions)
                if (this.authors) {
                    this.selectedUser = []
                    // this.userOptions = this.userOptions.filter((x) => x.content_suite_group_id == 2)
                    val.map((x) => {
                        let userindex = this.userOptions.findIndex((y) => y.id == x.id)
                        if (userindex > -1) this.selectedUser.push(this.userOptions[userindex])
                    })
                    console.log(this.selectedUser)
                } else {
                    this.selectedUser = val
                }
            },
            value(newVal) {
                // Überwache die übergebene 'value'-Prop und aktualisiere den internen Zustand entsprechend
                this.dialog = newVal
            },
            dialog(newVal) {
                // Überwache den internen Dialogzustand und aktualisiere die übergeordnete 'value'-Prop entsprechend
                this.$emit("input", newVal)
            }
        },
        methods: {
            async fetchUser() {
                const post_data = { company_id: localStorage.company_id }
                const url = process.env.VUE_APP_APIURL + "/user/companyuser"
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log("respi", response_data)
                this.userOptions = response_data.data.filter((user) => user.content_suite == 1)
                console.log("length", this.userOptions.length)
                // this.selectedUser = this.task.responsible.user
                // this.selectedGroup = this.task.responsible.groups
            },

            fetchGroups() {
                // this.$http
                //     .post(process.env.VUE_APP_APIURL + "/rights/groups/company", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                //     .then((response) => {
                //         console.log(response.body)
                //         // let mappedUser = users.map((x) => {
                //         //     let result = response.body.groups.find((o) => o.id === x.group_id)
                //         //     x.group_name = result.name
                //         //     x.group_description = result.description
                //         //     if (x.verification_status == 1) {
                //         //         x.verification_status_translated = "yes"
                //         //     } else {
                //         //         x.verification_status_translated = "no"
                //         //     }
                //         //     return x
                //         // })
                //         // //hide admin accounts in customer databases
                //         // if (parseInt(this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)) > 2) {
                //         //     this.user = mappedUser.filter((x) => x.id > 4)
                //         // } else {
                //         //     this.user = mappedUser
                //         // }
                //         // let mappedGroups = response.body.groups.map((x) => {
                //         //     // console.log(x)
                //         //     let obj = {}
                //         //     obj.text = x.name
                //         //     obj.value = x.id
                //         //     obj.description = x.description
                //         //     return obj
                //         // })
                //         // //remove when guest role should appear
                //         // mappedGroups.length = 5
                //         // this.groups = mappedGroups
                //         // this.getStandardGroup()
                //     })
                //     .catch((err) => {
                //         console.log(err)
                //     })
            },
            closeDialog() {
                this.dialog = false
                // Zurücksetzen der ausgewählten Werte
                this.selectedUser = null
                this.selectedGroup = null
            },
            assignResponsible() {
                // Hier kannst du die ausgewählten Werte verwenden, um den verantwortlichen Benutzer oder die Gruppe zuzuweisen
                console.log("Selected User:", this.selectedUser)
                console.log("Selected Group:", this.selectedGroup)
                this.$emit("assign", { user: this.selectedUser, groups: this.selectedGroup })
                this.closeDialog()
            }
        },
        async mounted() {
            // Daten während des Mountens laden
            await this.fetchUser()
            this.fetchGroups()
        }
    }
</script>
