<template>
    <v-card flat tile min-height="380" class="folders-tree-card" style="height: 100% !important; flex-grow: 0 !important">
        <!-- <v-card class="mb-10 mt-4 rounded_card quaroshadow" style="height: 100% !important"> -->
        <!-- <div class="backsheet"></div> -->
        <!-- search for categories -->
        <!-- <v-card-title id="heading3" class="pb-0 pt-2 uppercase folderheading">
            <v-row no-gutters>

                <v-col class="flex"> <span class="my-auto">Folders</span> </v-col>
            </v-row>
        </v-card-title> -->
        <!-- <v-divider></v-divider> -->
        <v-card-text class="pa-2 pb-0">
            <v-row class="my-2">
                <v-col class="pt-1 pb-3 px-4">
                    <v-text-field
                        v-model="foldersearch"
                        class="ml-auto"
                        rounded
                        dense
                        filled
                        prepend-inner-icon="mdi-magnify"
                        label="search folder..."
                        single-line
                        hide-details
                        id="kwsearch"></v-text-field>
                </v-col>
            </v-row>
            <!-- loader -->
            <v-progress-linear v-if="folders_loading" indeterminate rounded color="darkgreen"></v-progress-linear>

            <!-- <div class="py-2">
                <span class="quarotblheader">All/</span>
            </div> -->
            <!-- add top level cat - only for custom -->

            <div class="flex mb-1 mt-2">
                <div>
                    <v-btn :disabled="$store.state.content_suite_group.id !== 1" small color="primary" style="padding-left: 12px" text @click="dialog.show = true">
                        <v-icon small color="primary" class="pr-2">mdi-folder-plus-outline</v-icon> New Folder
                    </v-btn>
                </div>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on" class="ml-auto white--text" color="primary" small>
                            <!-- <v-icon x-small class="mr-1">mdi-sort</v-icon> -->
                            sort by
                        </v-btn>
                    </template>
                    <v-list rounded dense flat>
                        <v-list-item @click="folder_sort_by = ['_id']" class="pl-2">
                            <v-list-item-title>Created Date</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="folder_sort_by = ['projects.length']" class="pl-2">
                            <v-list-item-title>Projects Count</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="pl-2" @click="folder_sort_by = ['name']">
                            <v-list-item-title>Title</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn @click="folder_sort_desc = folder_sort_desc[0] == true ? [false] : [true]" color="primary" small icon>
                    <v-icon v-if="folder_sort_desc[0] == false" small>mdi-sort-ascending</v-icon>
                    <v-icon v-else small>mdi-sort-descending</v-icon>
                </v-btn>
            </div>
        </v-card-text>
        <v-divider></v-divider>

        <!-- tree category view -->
        <!-- {{ tree }} -->
        <v-dialog v-model="dialog.show" max-width="500px" transition="slide-x-transition" hide-details @click:outside="dialog.show = false">
            <v-card>
                <div class="backsheet"></div>
                <v-btn class="close-btn" absolute top right icon color="primary" @click="dialog.show = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-card-text class="pt-5 pb-0">
                    <div class="lbl pb-1">FOLDER NAME</div>
                    <v-text-field class="my-2" label="Folder Name" rounded filled v-model="folderTitle"></v-text-field>
                    <div class="lbl pb-1">SELECT PROJECTS</div>
                    <v-autocomplete label="Search..." filled rounded v-model="selectedFolderProjects" :items="projects" item-text="title" item-value="_id" chips deletable-chips multiple>
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mx-auto" color="primary" @click="editFolder ? editFolderItem() : createFolder()" rounded>{{ editFolder ? "Edit" : "Create" }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="manageFolderProjects" max-width="500px" transition="slide-x-transition" hide-details @click:outside="manageFolderProjects = false">
            <v-card>
                <div class="backsheet"></div>
                <v-btn class="close-btn" absolute top right icon color="primary" @click="manageFolderProjects = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-card-text class="pt-5 pb-0">
                    <div class="lbl pb-1">MANAGE PROJECTS</div>
                    <v-autocomplete label="Search..." filled rounded v-model="selectedFolderProjects" :items="projects" item-text="title" item-value="_id" chips deletable-chips multiple>
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mx-auto" color="primary" @click="updateFolderProjects(null)" rounded>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addProjectToFolderDialog" max-width="500px" transition="slide-x-transition" hide-details @click:outside="addProjectToFolderDialog = false">
            <v-card>
                <div class="backsheet"></div>
                <v-btn class="close-btn" absolute top right icon color="primary" @click="addProjectToFolderDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-card-text class="pt-7 pb-0">
                    <div class="lbl pb-1">SELECT FOLDER</div>
                    <v-autocomplete label="Search..." filled rounded v-model="selectedFolderAdd" close-on-click :items="folders" item-text="name" return-object chips deletable-chips> </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mx-auto" color="primary" @click="addProjectToFolder" rounded>Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DeleteDialog
            :deleteDialog="deleteDialog.show"
            :selectedDeleteItem="selectedDeleteItem ? selectedDeleteItem.name : ''"
            deleteLabel="Folder"
            :loading="deleteDialog.loading"
            @delete="deleteFolderItem(selectedDeleteItem._id)"
            @close="deleteDialog.show = false">
        </DeleteDialog>
        <div class="pb-2 px-0">
            <v-data-table
                v-if="folders.length"
                hide-default-footer
                hide-default-header
                :headers="headers"
                :items="folders"
                :loading="folders_loading"
                :expanded.sync="expanded"
                item-key="id"
                no-data-text="No folders yet.."
                :item-class="itemBackground"
                @click:row="onRowClick"
                :single-expand="true"
                :search.sync="foldersearch"
                expand-icon="mdi-chevron-down"
                class="row-pointer"
                :sort-by.sync="folder_sort_by"
                :sort-desc.sync="folder_sort_desc"
                :items-per-page.sync="folder_items_per_page">
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="px-0">
                        <v-btn small color="darkgreen" text class="mb-1" @click="initSubFolderDialog(item)">
                            <v-icon small color="primary" class="pr-2">mdi-plus-circle-outline</v-icon> Sub Folder
                        </v-btn>
                        <v-treeview v-model="tree" :open="initiallyOpen" :items="items" activatable item-key="name" open-on-click>
                            <template v-slot:prepend="{ item, open }">
                                <v-icon color="primary" v-if="!item.file">
                                    {{ open ? "mdi-folder-open" : "mdi-folder" }}
                                </v-icon>
                                <v-icon v-else>
                                    {{ files[item.file] }}
                                </v-icon>
                            </template>
                        </v-treeview>
                    </td>
                </template>

                <template v-slot:item.name="{ item }">
                    <v-list-item two-line class="px-0">
                        <v-list-item-avatar class="folder-avatar" :style="'background-color: #edeff1'">
                            <v-icon class="folder-avatar-icon">{{ item.active ? "mdi-folder-open" : "mdi-folder" }}</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content style="color: #666666" class="folder-list-content">
                            <v-list-item-title class="folder-title" style="font-size: 15px">{{ item.name }}</v-list-item-title>
                            <v-list-item-subtitle class="updated-subtitle">
                                <!-- <v-icon x-small :color="item.active ? 'white' : ''" class="pr-1">mdi-clock-outline</v-icon>
                                <span class="folder-sub"> Updated </span> -->
                                <!-- <div style="position: absolute; left: 48px; bottom: 8px"></div> -->
                                <div style="display: flex">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="$store.state.content_suite_group.id == 1 ? manageProjects(item) : null"
                                                :color="'#edeff1'"
                                                class="px-2 mr-1 qchip folder-btn"
                                                :text="item.active ? true : false"
                                                v-on="on"
                                                x-small
                                                elevation="0"
                                                fab
                                                height="20px"
                                                min-width="40px"
                                                rounded>
                                                <v-icon class="mr-1" x-small>mdi-file-multiple-outline</v-icon>{{ item.projects ? item.projects.length : 0 }}</v-btn
                                            >
                                        </template>
                                        <span>{{ item.projects ? item.projects.length : 0 }} assigned collections</span>
                                    </v-tooltip>
                                    <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                disabled
                                                @click="shareFolder(item)"
                                                :color="item.active ? 'white' : 'primary'"
                                                class="px-1 mr-1"
                                                :text="item.active ? true : false"
                                                v-on="on"
                                                x-small
                                                elevation="0"
                                                outlined
                                                rounded>
                                                <v-icon :color="item.active ? 'white' : ''" class="mr-1" x-small>mdi-share-variant</v-icon>
                                            </v-btn>
                                        </template>
                                        <span> Share Folder</span>
                                    </v-tooltip> -->

                                    <v-menu :disabled="$store.state.content_suite_group.id !== 1" offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                :color="item.active ? 'white' : '#edeff1'"
                                                class="mr-1 folder-btn"
                                                :text="item.active ? true : false"
                                                x-small
                                                elevation="0"
                                                rounded
                                                fab
                                                width="20px"
                                                height="20px"
                                                ><v-icon :color="item.active ? 'white' : ''" x-small>mdi-dots-vertical</v-icon></v-btn
                                            >
                                        </template>
                                        <v-list rounded dense>
                                            <v-list-item
                                                @click="
                                                    editFolder = true
                                                    folderTitle = item.name
                                                    selectedFolderProjects = item.projects
                                                    editItem = item
                                                    dialog.show = true
                                                ">
                                                <v-icon small class="mr-2">mdi-pencil</v-icon><v-list-item-title>Edit</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="
                                                    selectedDeleteItem = item
                                                    deleteDialog.show = true
                                                ">
                                                <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-data-table>
        </div>
    </v-card>
</template>

<script>
    // import VueInputAutowidth from "vue-input-autowidth"
    import DeleteDialog from "../../components/dialogs/DeleteDialog.vue"

    export default {
        name: "FolderBar",
        props: {
            projects: Array,
            projectToFolderAdd: Object,
            addProjectToFolderVar: Number,
            projectToFolderDelete: Object,
            deleteProjectToFolderVar: Number,
            clearSelection: Number,
            updateSelection: Number
        },
        components: {
            // VueInputAutowidth
            DeleteDialog
        },
        data: () => ({
            deleteDialog: { show: false, loading: false },
            selectedDeleteItem: null,
            createSub: false,
            manageFolder: {},
            manageFolderProjects: false,
            editFolder: false,
            editItem: {},
            selectedFolderProjects: [],
            folder_sort_desc: [true],
            folder_sort_by: ["name"],
            folder_items_per_page: -1,
            foldersearch: "",
            headers: [
                {
                    text: "Project",
                    align: "start",
                    sortable: true,
                    value: "name"
                },
                {
                    text: "Created",
                    align: " d-none",
                    sortable: true,
                    sort: (a, b) => {
                        try {
                            let local = "de-de"
                            let date1 = new Date(parseInt(a.toString().slice(0, 8), 16) * 1000)
                            let date2 = new Date(parseInt(b.toString().slice(0, 8), 16) * 1000)

                            const f = new Intl.DateTimeFormat(local, {
                                dateStyle: "long"
                            })
                            date1 = f.format(date1)
                            date2 = f.format(date2)

                            return date1 < date2 ? -1 : 1
                        } catch (error) {
                            return 1
                        }
                    },
                    value: "_id"
                },
                {
                    text: "Project",
                    align: " d-none",
                    sortable: true,
                    value: "projects.length"
                },
                { text: "", value: "data-table-expand" }
            ],
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            clickedCat: null,
            // tooltips
            switchBtnTooltip: "Move to Category",
            saveChangesTooltip: "Save Changes",
            deleteCategoryTooltip: "Delete Category",
            editCategoryTooltip: "Edit Category",
            addChildButtonTooltip: "Create Child Category",
            addButtonTooltip: "Create Top Level Category",
            folders_loading: false,
            folders: [{ name: "test", id: 1, active: false }],
            tree: [],
            open: [],
            search: null,
            caseSensitive: false,
            folderTitle: "",
            dialog: { show: false, parent: null },
            expanded: [],
            initiallyOpen: ["public"],
            files: {
                html: "mdi-language-html5",
                js: "mdi-nodejs",
                json: "mdi-code-json",
                md: "mdi-language-markdown",
                pdf: "mdi-file-pdf",
                png: "mdi-file-image",
                txt: "mdi-file-document-outline",
                xls: "mdi-file-excel"
            },
            tree: [],
            items: [
                {
                    name: ".git"
                },
                {
                    name: "node_modules"
                }
            ],
            addProjectToFolderDialog: false,
            selectedFolderAdd: null
        }),
        computed: {
            filter() {
                return this.caseSensitive ? (item, search, textKey) => item[textKey].indexOf(search) > -1 : undefined
            },
            user_settings() {
                return this.$store.state.user_settings
            },
            created_date() {
                return this.getCreatedDate(id)
            }
        },
        watch: {
            updateSelection() {
                let aciveFolder = this.folders.find((f) => f.active)
                console.log(this.folders)
                if (!aciveFolder) return
                this.$emit("folderSelect", aciveFolder)
            },
            clearSelection() {
                console.log("clear folder selection")
                this.folders.map((f) => (f.active = false))
                this.folders.push({})
                this.folders.pop()
            },
            folder_sort_desc() {
                console.log(this.folder_sort_desc)
                if (!this.user_settings.cs_projects_folders_sort) this.user_settings.cs_projects_folders_sort = {}
                this.user_settings.cs_projects_folders_sort.sort = this.folder_sort_desc
                this.user_settings.cs_projects_folders_sort.column = this.folder_sort_by

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            folder_sort_by() {
                console.log(this.folder_sort_desc)
                // this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            addProjectToFolderVar() {
                this.selectedFolderAdd = null // clear selection
                this.addProjectToFolderDialog = true
            },
            deleteProjectToFolderVar() {
                this.deleteProjectFromFolder()
            }
        },
        methods: {
            deleteProjectFromFolder() {
                if (this.projectToFolderDelete) {
                    let folders = this.folders.filter((x) => {
                        let innerindex = x.projects.indexOf(this.projectToFolderDelete._id)
                        // console.log(innerindex)
                        if (innerindex > -1) {
                            // console.log("found")
                            return true
                        } else {
                            return false
                        }
                    })
                    for (let index = 0; index < folders.length; index++) {
                        folders[index].projects.splice(folders[index].projects.indexOf(this.projectToFolderDelete._id), 1)
                    }
                }
            },
            addProjectToFolder() {
                if (this.projectToFolderAdd) {
                    console.log(this.projectToFolderAdd)
                    console.log(this.selectedFolderAdd)
                    this.selectedFolderAdd.projects.push(this.projectToFolderAdd._id)
                    this.selectedFolderAdd.projects = [...new Set(this.selectedFolderAdd.projects)]
                    this.updateFolderProjects(this.selectedFolderAdd)
                    console.log(this.selectedFolderAdd)
                    this.addProjectToFolderDialog = false
                    this.$emit("addedToFolderNotify")
                }
            },
            initSubFolderDialog(parent) {
                this.createSub = true
                this.dialog.parent = parent
                this.dialog.show = true
            },
            manageProjects(item) {
                event.stopPropagation()
                this.manageFolder = item
                this.manageFolderProjects = true
                this.selectedFolderProjects = item.projects
            },
            async updateFolderProjects(folder) {
                console.log(this.manageFolder)
                this.manageFolder.projects = JSON.parse(JSON.stringify(this.selectedFolderProjects))
                this.manageFolderProjects = false

                //TODO: Update in Db
                const url = process.env.VUE_APP_APIURL + "/cs/update/folder"
                let post_data = {
                    company_id: localStorage.company_id,
                    folder: folder ? folder : this.manageFolder
                }
                console.log(post_data)
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data)
                return
            },
            shareFolder(item) {
                event.stopPropagation()
            },
            // folderSettings(item) {
            //     event.stopPropagation()
            // },
            itemBackground(item) {
                console.log(item)
                if (!item) return ""
                console.log("Background", item)
                if (item.active) {
                    console.log("class returned")
                    return "itemactive2"
                } else {
                    return ""
                }
            },
            onRowClick(item) {
                if (!item.active) {
                    this.folders.map((f) => (f.active = false))
                    item.active = true
                    console.log(item)
                    console.log(this.folders)
                    this.folders.push({})
                    this.folders.pop()
                    this.$emit("folderSelect", item)
                } else {
                    this.folders.map((f) => (f.active = false))
                    this.folders.push({})
                    this.folders.pop()
                    this.$emit("folderSelect", null)
                }
            },

            async deleteFolder(item) {
                console.log(item)
                // check if its a new cat creation cancel -- missing
                // remove cat from keywords and show message --- missing
                // remove cat from db
                const url = process.env.VUE_APP_APIURL + "/cs/delete/folder"
                let post_data = {
                    company_id: localStorage.company_id,
                    folder: item
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
            },
            createFolder() {
                if (this.createSub) {
                    this.createSubFolder()
                } else {
                    this.createNewTopCategory()
                }
            },
            editFolderItem() {
                this.editItem.name = this.folderTitle
                this.editItem.projects = this.selectedFolderProjects
                let folder = this.editItem
                let index = this.folders.findIndex((x) => x._id == folder._id)
                if (index > -1) this.folders[index] = folder
                this.dialog.show = false
                this.editFolder = false
                this.updateFolderProjects(folder)
            },
            deleteFolderItem(_id) {
                let index = this.folders.findIndex((x) => x._id == _id)
                this.deleteFolder(this.folders[index])

                if (index > -1) this.folders.splice(index, 1)
                this.deleteDialog.show = false
            },
            createSubFolder() {
                let rndNumber = Math.floor(Math.random() * Date.now()).toString(16)

                let newFolder = { name: this.folderTitle, projects: this.selectedFolderProjects, children: [], id: rndNumber }
                this.dialog.parent.children.push(newFolder)
                // Update Folder in DB
                console.log(this.dialog.parent)
                this.folderTitle = []
                this.selectedFolderProjects = []
                this.dialog.show = false
            },
            async createNewTopCategory() {
                try {
                    //             {
                    //   id: 1,
                    //   name: 'Applications :',
                    //   children: [
                    //     { id: 2, name: 'Calendar : app' },
                    //     { id: 3, name: 'Chrome : app' },
                    //     { id: 4, name: 'Webstorm : app' },
                    //   ],
                    // },

                    let rndNumber = Math.floor(Math.random() * Date.now()).toString(16)
                    let newFolder = { name: this.folderTitle, projects: this.selectedFolderProjects, children: [], id: rndNumber }

                    this.folders.unshift(newFolder)
                    console.log(newFolder)
                    this.dialog.show = false
                    this.folderTitle = ""

                    const url = process.env.VUE_APP_APIURL + "/cs/add/folder"
                    let post_data = {
                        company_id: localStorage.company_id,
                        user_id: localStorage.id,
                        folder: newFolder
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                    // let folder = response_data.data
                    // folder.id = folder.folder_code
                    // folder.level = item.level
                    // folder.name = folder.folder_name
                    // this.folders.push(folder)
                    // this.fetchFolder()
                    // this.processFolders(this.folders)
                    // this.resetFolder(this.folders)
                } catch (error) {
                    console.log(error)
                }
            },

            async fetchFolder() {
                try {
                    this.folders_loading = true

                    const url = process.env.VUE_APP_APIURL + "/cs/get/folders"
                    let post_data = {
                        company_id: localStorage.company_id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    // folders.unshift({ folder_name: "-" })
                    console.log("response_data FOLDER", response_data)
                    this.folders = response_data.data.folders.map((f) => {
                        f.active = false
                        return f
                    })
                    this.folders_loading = false
                } catch (error) {
                    console.log(error)
                }
                // this.$http
                //     .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                //     .then((response) => {
                //         let cats = response.body.categories.filter((x) => x != null && x.folder_name)
                //         cats.unshift({ folder_name: "-" })
                //         this.processFolders(cats)
                //     })
                //     .catch((err) => {
                //         console.log(err)
                //     })
            }
        },
        mounted() {
            this.folder_sort_desc = this.user_settings.cs_projects_folders_sort == undefined ? [true] : this.user_settings.cs_projects_folders_sort.sort
            this.folder_sort_by = this.user_settings.cs_projects_folders_sort == undefined ? ["name"] : this.user_settings.cs_projects_folders_sort.column
            this.fetchFolder()
        }
    }
</script>
<style lang="scss">
    .itemactive2 {
        background: var(--v-primary-base) !important;
        font-size: 8px !important;
        color: white !important;
        border: 2px solid black;
        .folder-title {
            color: white !important;
        }
        .folder-sub {
            color: white !important;
        }
        .mdi-chevron-down::before {
            color: white !important;
        }
    }
</style>
<style scoped>
    .v-treeview-node__root:hover .cat_actions {
        color: rgb(104, 104, 104) !important;
    }
    .v-treeview-node__root .cat_actions {
        color: white !important;
    }
    .cat_text_field {
        width: 200px;
    }
    #catrow {
        display: inline-flex;
    }
    .v-tooltip .v-tooltip--top {
        background-color: orange !important;
    }
    .tooltip_round {
        border-radius: 15px !important;
    }
    .folderheading {
        font-size: 14px;
    }
    .v-treeview-node {
        background-color: grey !important;
    }
    #detailfields .v-expansion-panel-header {
        background-color: #f6f6f6;
        margin: 7px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        border-radius: 10px;
        max-width: 95%;
    }
    #detailfields .headericon {
        width: 62px;
        max-width: 62px;
        display: flex;
    }
    .folderactive {
        /* background-color: rgb(255, 0, 0) !important; */
        border-top: 1px grey !important;
    }
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }
    .itemactive2 {
        background: red !important;
        font-size: 8px !important;
        color: red;
        border: 2px solid black;
    }
    table > tbody > tr .itemactive {
        color: red;
    }
    .text-start {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    table > tbody > tr > td:nth-child(1) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .folders-tree-card {
        height: 100%;
    }
    .fixed-column {
        flex: 0 0 300px !important; /* Feste Größe von 300px, verändert sich nicht */
    }
    .theme--dark .folder-list-content {
        color: white !important;
    }
    .theme--dark .folder-avatar {
        background-color: rgb(0, 0, 0) !important;
    }
    .theme--dark .folder-avatar-icon {
        color: rgb(112, 112, 112) !important;
    }
    .theme--dark .folder-btn {
        background-color: rgb(112, 112, 112) !important;
    }
</style>
