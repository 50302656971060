import { Pie } from "vue-chartjs"
export default {
    extends: Pie,
    props: ["chartData", "options", "render"],
    watch: {
        render() {
            console.log("render")
            this.renderChart(this.chartData, this.options)
        }
    },
    mounted() {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, this.options)
    }
}
