<template>
    <div>
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon>
            <span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <!-- <v-btn class="pl-2" small @click="init"> <v-icon small class="mr-1">mdi-plus-circle-outline</v-icon> create new project</v-btn> -->
        <v-dialog v-model="projectsDialog" max-width="500px" @click:outside="$emit('close')">
            <v-card>
                <div class="dialog-title">
                    <span class="mb-5 d-inline-block mx-auto dialog-title-text"> {{ cardData.title }}</span>
                    <v-btn right absolute @click="$emit('close')" class="dialog-close-btn" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
                </div>
                <!-- <v-card-subtitle>
                    {{ cardData.subtitle }}
                </v-card-subtitle> -->
                <v-card-text>
                    <v-text-field clearable rounded filled v-model="projectData.name" :label="cardData.txt_field_label"> </v-text-field>
                    <v-autocomplete
                        :label="cardData.autocomplete_label"
                        filled
                        rounded
                        v-model="projectData.lists"
                        :items="filterDisabled()"
                        item-text="listname"
                        item-value="_id"
                        chips
                        deletable-chips
                        multiple>
                        <template v-slot:item="data">
                            <v-list-item ripple v-if="projectData.lists.indexOf(data.item._id) == -1" @click="data.item.disabled ? null : projectData.lists.push(data.item._id)">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-action v-on="data.item.disabled ? on : null">
                                            <v-icon :disabled="data.item.disabled"> mdi-checkbox-blank-outline </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content v-on="data.item.disabled ? on : null">
                                            <v-list-item-title :style="data.item.disabled ? 'color: #d3d3d3' : 'primary'">
                                                {{ data.item.listname }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <span>Collection already assigned to project "{{ data.item.project }}".</span>
                                </v-tooltip>
                            </v-list-item>
                            <v-list-item ripple v-else @click="projectData.lists = projectData.lists.filter((x) => x !== data.item._id)">
                                <v-list-item-action>
                                    <v-icon> mdi-checkbox-marked </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.listname }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <v-textarea rounded clearable filled v-model="projectData.description" :label="cardData.area_field_label"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn rounded :loading="loading" block class="mx-auto" @click="create" dark color="primary">create Project</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "AddProjectsDialog",
        props: {
            projectsDialog: Boolean,
            all_collections: Array
        },
        data() {
            return {
                loading: false,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                notification: { show: false },
                projectData: {
                    name: null,
                    description: null,
                    lists: []
                },
                cardData: {
                    title: "New Project",
                    subtitle: "Create a new project to keep your Collections organized",
                    txt_field_label: "Project name",
                    autocomplete_label: "Select Collections for your Project",
                    area_field_label: "Project description"
                },
                dialog: false
            }
        },
        computed: {},
        methods: {
            // init() {
            //     this.dialog = true
            //     var type = "research"
            //     this.$http
            //         .post(
            //             process.env.VUE_APP_APIURL + "/research/list",
            //             { type: type, company_id: localStorage.company_id },
            //             { headers: { Authorization: this.auth } }
            //         )
            //         .then(function (response) {
            //             for (let index = 0; index < response.body.length; index++) {
            //                 let research = {}
            //                 research.listname = response.body[index].listname
            //                 research.id = response.body[index]._id
            //                 // research.count = response.body[index].count
            //                 // research.updated = response.body[index].mdata.updated
            //                 // research.country = response.body[index].mdata.langloc.value
            //                 // research.trendData = response.body[index].trendData
            //                 // research.status = response.body[index].mdata.status
            //                 // research.owner = response.body[index].owner
            //                 this.all_collections.push(research)
            //             }
            //         })
            // },
            filterDisabled() {
                let collections = JSON.parse(JSON.stringify(this.all_collections))

                for (let index = 0; index < collections.length; index++) {
                    if (collections[index].project) collections[index].disabled = true
                }
                console.log(collections)
                return collections.sort((a, b) => a.listname.localeCompare(b.listname))
            },
            create() {
                this.loading = true
                console.log(this.projectData.lists)
                console.log(this.projectData.lists.length)

                if (!this.projectData.name || this.projectData.lists.length < 1) {
                    this.notification = this.$helpers.createNotification("Please provide a project name and select at least one Collection!", "mdi-alert", "errorred")
                    this.loading = false
                    return
                }
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    project: this.projectData.name,
                    description: this.projectData.description,
                    lists: this.projectData.lists
                }
                console.log(post_data)
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/projects/add", post_data, {
                        headers: { Authorization: this.auth }
                    })
                    .then(function (response) {
                        if (response.body.created) {
                            this.notification = this.$helpers.createNotification("Your Project has been created successfully!", "mdi-check-circle-outline", "success")
                            this.$emit("created")
                            this.$emit("close")
                            this.projectData.name = null
                            this.projectData.description = null
                            this.projectData.lists = []
                        } else {
                            this.notification = this.$helpers.createNotification("This project name is already in use, please try something else!", "mdi-alert", "errorred")
                        }
                        this.loading = false
                    })
                    .catch((err) => {
                        console.log(err)
                        this.loading = false
                        this.notification = this.$helpers.createNotification("Something went wrong! Please try again or contact the support.", "mdi-alert", "errorred")
                    })
            }
        }
    }
</script>

<style>
    .addprojectstitle {
        font-size: 1.7em !important;
    }
</style>
