<template>
    <node-view-wrapper class="vue-component">
        <span class="label">
            <v-icon size="16px" class="mr-1 pb-1" color="white">mdi-head-remove-outline</v-icon>
            AI Writer
        </span>
        <v-btn right style="right: 0px" absolute icon color="primary" @click="deleteAiWriter()"><v-icon>mdi-close</v-icon></v-btn>

        <div class="pa-3" style="width: 100%">
            <v-row class="preview-row mt-10" v-if="messages.length">
                <span class="ml-4" style="font-size: 14px; color: rgba(0, 0, 0, 0.6); font-family: Arial, Helvetica, sans-serif">Preview</span>
                <!-- <v-btn class="preview-btn ml-auto" color="grey" x-small text><v-icon class="mr-1" size="16px">mdi-pencil</v-icon>edit</v-btn> -->
                <v-btn @click="pasteContent(messages[0].content)" class="preview-btn ml-auto mr-2" color="primary" small text><v-icon class="mr-1" size="16px">mdi-check</v-icon>paste</v-btn>
                <v-col class="pl-5" cols="12">
                    <div class="px-3" contenteditable="true" style="white-space: pre-line; border-left: 4px solid; border-left-color: #e3e3e3" v-html="messages[0].content"></div>
                    <!-- <div id="ai-preview"></div> -->
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-textarea
                        label="Prompt"
                        v-model="prompt"
                        hide-details
                        class="mt-10 mb-3"
                        rows="3"
                        width="100%"
                        rounded
                        filled
                        placeholder="Tell me what you want me to write about..."></v-textarea>
                </v-col>

                <v-col class="d-flex">
                    <v-menu top offset-y right content-class="submenu" :close-on-content-click="true">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="mr-1" color="grey darken-2" small text rounded>
                                <v-icon size="16px" class="mr-1">mdi-microphone-settings</v-icon>
                                {{ selectedTone ? selectedTone : "change tone" }}
                                <v-icon size="16px">mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list rounded dense style="max-height: 300px; overflow-y: auto; font-weight: bold !important">
                            <v-list-item class="pl-1" @click="selectedTone = null">
                                <v-icon class="ml-0 mr-1" color="grey darken-1" size="16px">mdi-restore</v-icon>
                                <v-list-item-title>Reset</v-list-item-title>
                            </v-list-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item class="pl-2" v-for="tone in tones" :key="tone" @click="selectedTone = tone">
                                <v-list-item-title>{{ tone }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu top offset-y right content-class="submenu" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small text rounded color="grey darken-2">
                                <v-icon size="16px" class="mr-2">mdi-key</v-icon>
                                {{ selectedPromptKeywordsLength ? selectedPromptKeywordsLength + " keywords" : "add keywords" }}
                                <v-icon size="16px">mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list rounded dense style="max-height: 300px; overflow-y: auto; font-weight: bold !important">
                            <v-list-item class="pl-2" @click="resetKeywords()">
                                <v-icon class="mr-2" color="grey darken-1">mdi-restore</v-icon>
                                <v-list-item-title>Reset</v-list-item-title>
                            </v-list-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item class="pl-2" v-for="(keyword, index) in keywordArray" :key="index" @click="selectPromptKeyword(keyword)">
                                <v-icon v-if="keyword.promptSelected" color="primary" class="mr-2"> mdi-check-circle-outline </v-icon>
                                <v-icon v-else color="primary" class="mr-2">mdi-circle-outline</v-icon>
                                <v-list-item-title>
                                    {{ keyword.keyword }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <!-- <v-select hide-details outlined dense :items="['hi', 'hi2']"></v-select>
                    <v-select height="28px" hide-details filled dense rounded :items="['hi', 'hi2']"></v-select> -->
                    <v-btn class="ml-auto white--text" small rounded color="grey" @click="generate">
                        <v-icon v-if="!loading" size="16px" class="mr-1">mdi-creation-outline</v-icon>
                        <v-progress-circular indeterminate class="mr-1" color="white" v-if="loading" size="16" width="2"></v-progress-circular>
                        generate text</v-btn
                    >
                </v-col>
            </v-row>
        </div>
    </node-view-wrapper>
</template>

<script>
    import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2"
    import aiFunctions from "../aiFunctions"

    export default {
        components: {
            NodeViewWrapper
        },
        props: {
            // the editor instance

            editor: {
                type: Object
            },

            // the current node
            node: {
                type: Object
            },

            // an array of decorations
            decorations: {
                type: Array
            },

            // `true` when there is a `NodeSelection` at the current node view
            selected: {
                type: Boolean
            },

            // access to the node extension, for example to get options
            extension: {
                type: Object
            },

            // get the document position of the current node
            getPos: {
                type: Function
            },

            // update attributes of the current node
            updateAttributes: {
                type: Function
            },

            // delete the current node
            deleteNode: {
                type: Function
            }
        },
        data() {
            return {
                keywordArray: [],
                selectedTone: null,
                tones: [
                    "Academic",
                    "Business",
                    "Casual",
                    "Childfriendly",
                    "Conversational",
                    "Emotional",
                    "Humorous",
                    "Informative",
                    "Inspirational",
                    "Memeify",
                    "Narrative",
                    "Objective",
                    "Persuasive",
                    "Poetic"
                ],
                prompt: "",
                messages: [],
                loading: false
            }
        },
        computed: {
            selectedPromptKeywordsLength() {
                return this.keywordArray.filter((k) => k.promptSelected).length
            }
        },
        mounted() {
            if (this.node.attrs.keywords) {
                let keywords = this.node.attrs.keywords

                if (Array.isArray(keywords)) {
                    // Wenn es bereits ein Array ist, alles wie bisher
                    this.keywordArray = [...keywords].map((k) => {
                        k.promptSelected = false
                        return k
                    })
                } else if (typeof keywords === "string") {
                    // Wenn es ein String ist, aufteilen, Leerzeichen entfernen und in ein neues Array einfügen
                    this.keywordArray = keywords.split(",").map((keyword) => keyword.trim())
                } else {
                    console.log("Die Eigenschaft 'keywords' ist weder ein Array noch ein String.")
                    this.keywordArray = [] // Oder einen anderen Wert oder Fehler behandeln
                }
            }
        },

        methods: {
            resetKeywords() {
                this.keywordArray = this.keywordArray.map((k) => {
                    k.promptSelected = false
                    return k
                })
            },
            selectPromptKeyword(keyword) {
                keyword.promptSelected = !keyword.promptSelected
                console.log(keyword)
                this.keywordArray.push({})
                this.keywordArray.pop()
            },
            async generate() {
                if (this.prompt.length && !this.loading) {
                    try {
                        console.time("aifetch")
                        let postPrompt = "This is the prompt: '" + this.prompt + "'; "
                        // let postPrompt = "This is the prompt. Your response has to be in the language of the prompt: '" + this.prompt + "'; "
                        this.loading = true
                        let selectedKeywords = this.keywordArray.filter((k) => k.promptSelected).map((k) => k.keyword)
                        console.log(selectedKeywords)
                        if (this.selectedTone) {
                            let str = "Please write in a " + this.selectedTone.toLowerCase() + " tone of voice. "
                            postPrompt += str
                        }
                        if (selectedKeywords.length) {
                            let str = "Please use the following keywords in a meaningful way: " + selectedKeywords.join(", ") + "."
                            postPrompt += str
                        }
                        console.log(this.prompt)
                        const response = await aiFunctions.trainedAssistant(postPrompt)
                        console.timeEnd("aifetch")
                        console.log("Completed------------------------------------")
                        console.log(response.data)
                        this.loading = false

                        this.ai_response = response.data.choices[0].message.content
                        this.messages.unshift(response.data.choices[0].message)
                        // setTimeout(() => {
                        //     document.getElementById("ai-preview").innerHTML = this.ai_response
                        // }, 500)
                        // this.pasteContent(response.data.choices[0].message.content)
                    } catch (error) {
                        this.loading = false

                        console.log(error)
                    }
                }
            },
            deleteAiWriter() {
                // this.editor.commands.focus()
                const { state, commands } = this.editor
                this.editor.chain().focus().deleteRange({ from: state.selection.from, to: state.selection.to }).run()
            },
            pasteContent(content) {
                let paragraphs = content.split("\n\n")
                // Erstelle den HTML-Code für die Tiptap-Komponente
                let conentToPaste = paragraphs
                    .filter((p) => p.length > 0)
                    .map((paragraph) => `<p>${paragraph}</p>`)
                    .join("")
                this.editor.commands.deleteNode("vueComponent")
                this.editor.commands.insertContentAt({ from: this.getPos(), to: this.getPos() + 1 }, conentToPaste, {
                    updateSelection: true
                })
            }
        }
    }
</script>

<style lang="scss">
    .vue-component {
        background: #ffffff;
        border: 2px dotted var(--v-primary-base);
        border-radius: 0.5rem;
        margin: 1rem 0;
        position: relative;
    }

    .label {
        margin-left: 1rem;
        background-color: var(--v-primary-base);
        font-size: 0.6rem;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 0;
        padding: 0.25rem 0.75rem;
        border-radius: 0 0 0.5rem 0.5rem;
    }

    .content {
        margin-top: 1.5rem;
        padding: 1rem;
    }
</style>
