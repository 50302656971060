<template>
    <v-dialog v-model="dialog" max-width="500px" transition="slide-x-transition" @click:outside="$emit('close')">
        <v-card>
            <div class="backsheet"></div>
            <v-btn class="close-btn" absolute top right icon color="primary" @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
            <v-card-text class="pt-5 pb-0">
                <div class="lbl pb-1">ADD WEBSITE URL</div>
                <v-text-field class="my-2" rounded filled v-model="url" hint="e.g. https://www.quaro.io/keyword-recherche" persistent-hint></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn class="mx-auto" color="primary" @click="saveURL" rounded>ok</v-btn>
            </v-card-actions>
        </v-card>

        <v-snackbar v-if="notification" top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            show: Boolean,
            id: String,
            setMainURL: Boolean,
            addLighthouseData: Boolean,
            url: String
        },
        data() {
            return {
                notification: null
            }
        },
        computed: {
            dialog() {
                return this.show
            }
        },
        methods: {
            trackEvent(component, action, param1, param2) {
                try {
                    this.$matomo.trackEvent(component, action, param1, param2)
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
            },
            async saveURL() {
                // check if correct url
                try {
                    if (!this.url.includes("https://")) this.url = "https://" + this.url
                    console.log(this.url)
                    let checkurl = new URL(this.url)
                    console.log(checkurl)
                } catch (error) {
                    this.notification = this.$helpers.createNotification("Please enter a complete and accurate website URL!", "mdi-alert", "orange darken-2")
                    return
                }
                try {
                    const url = process.env.VUE_APP_APIURL + "/cs/add/url"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.id,
                        url: this.url,
                        mainurl: this.setMainURL,
                        addLighthouseData: this.addLighthouseData,
                        user_id: localStorage.id,
                        socket_id: this.$socket.id
                    }
                    this.$emit("started")
                    this.$helpers.axiosPost(url, post_data)
                    this.trackEvent("CS - AddURLDialog", this.url, "", "")

                    // this.$emit("added")
                } catch (error) {
                    this.notification = this.$helpers.createNotification("Something went wrong adding your URL! Please try again or contact support.", "mdi-alert", "orange darken-2")
                }
            }
        }
    }
</script>

<style scoped>
    .close-btn {
        top: 5px;
        right: 5px;
    }
</style>
