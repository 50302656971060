<template>
    <v-card class="mb-5">
        <!-- notification bar  -->
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <v-sheet class="pa-4 pt-7">
            <!-- search for categories -->
            <v-text-field v-model="search" label="Search Categories" flat rounded solo-inverted hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field>
        </v-sheet>
        <v-card-text>
            <!-- loader -->
            <v-progress-linear v-if="categories_loading" indeterminate rounded color="darkgreen"></v-progress-linear>

            <!-- add top level cat - only for custom -->
            <v-tooltip content-class="tooltip_round" top v-if="categories_type == 2 && !categories_loading">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="darkgreen" class="mb-1" @click="addTopLevelCategory" fab small v-bind="attrs" v-on="on">
                        <v-icon color="white">mdi-plus-circle</v-icon>
                    </v-btn>
                </template>
                <span>{{ addButtonTooltip }}</span>
            </v-tooltip>

            <!-- button group -->
            <v-btn-toggle color="darkgreen" v-if="!categories_loading" rounded v-model="categories_type" borderless class="mb-5 ml-3">
                <v-btn @click="resetCats(googleCats)" small value="1">
                    <span class="hidden-sm-and-down">Google Categories</span>
                </v-btn>

                <v-btn @click="resetCats(customCats)" small value="2">
                    <span class="hidden-sm-and-down">Custom Categories</span>
                </v-btn>
            </v-btn-toggle>

            <!-- tree category view -->
            <!-- {{ tree }} -->
            <v-treeview expand-icon="mdi-chevron-down" rounded hoverable :items="tree" :search="search" :filter="filter" :open.sync="open">
                <template v-slot:prepend="{ item, open }">
                    <!-- prepend Icons -->
                    <v-chip color="darkgreen" dark small>
                        Level
                        <v-icon small class="ml-1">
                            {{ getIcon(open, item) }}
                        </v-icon>
                    </v-chip>
                </template>
                <template v-slot:label="{ item }">
                    <div id="catrow">
                        <!-- text field to edit -->
                        <v-text-field @keyup.enter="saveChanges(item)" autofocus ref="edit" v-if="item.edit" class="cat_text_field" dense hide-details flat v-model="item.name"></v-text-field>
                        <span v-else class="pt-1">{{ item.name }}</span>
                        <span v-if="item.edit">
                            <!-- save changes button-->
                            <v-tooltip content-class="tooltip_round" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small class="ml-2" icon @click="saveChanges(item)">
                                        <v-icon class="">mdi-check</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ saveChangesTooltip }}</span>
                            </v-tooltip>
                            <!-- delete button -->
                            <v-tooltip content-class="tooltip_round" top v-if="categories_type == 2">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" @click="deleteCategory(item)" small icon>
                                        <v-icon small class="">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ deleteCategoryTooltip }}</span>
                            </v-tooltip>
                        </span>
                        <!-- edit button -->
                        <span v-if="!item.edit">
                            <v-tooltip content-class="tooltip_round" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small class="ml-2" icon @click="setEdit(item, true)">
                                        <v-icon small class="cat_actions">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ editCategoryTooltip }}</span>
                            </v-tooltip>

                            <!-- add button -->
                            <v-tooltip content-class="tooltip_round" top v-if="categories_type == 2">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small icon @click="addChildCategory(item)">
                                        <v-icon small class="cat_actions">mdi-plus-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ addChildButtonTooltip }}</span>
                            </v-tooltip>

                            <!-- switch button -->
                            <!-- <v-tooltip content-class="tooltip_round" top v-if="categories_type == 2">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" small icon>
                                    <v-icon small class="cat_actions">mdi-swap-vertical-bold</v-icon>
                                </v-btn>        
                            </template>
                            <span>{{switchBtnTooltip}}</span>
                        </v-tooltip>                                   -->
                        </span>
                    </div>
                </template>
            </v-treeview>
        </v-card-text>
    </v-card>
</template>

<script>
    // import VueInputAutowidth from "vue-input-autowidth"
    export default {
        name: "CategorySettings",
        props: [],
        components: {
            // VueInputAutowidth
        },
        data: () => ({
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            clickedCat: null,
            // tooltips
            switchBtnTooltip: "Move to Category",
            saveChangesTooltip: "Save Changes",
            deleteCategoryTooltip: "Delete Category",
            editCategoryTooltip: "Edit Category",
            addChildButtonTooltip: "Create Child Category",
            addButtonTooltip: "Create Top Level Category",
            notification: { show: false },
            googleCats: [],
            categories_type: "1", // 1 = google, 2 = custom
            categories_loading: false,
            tree: [],
            open: [],
            search: null,
            caseSensitive: false
        }),
        computed: {
            filter() {
                return this.caseSensitive ? (item, search, textKey) => item[textKey].indexOf(search) > -1 : undefined
            }
        },
        methods: {
            getIcon(open, item) {
                let outlineString = "-outline"
                let icons = [
                    "",
                    "mdi-numeric-1-circle",
                    "mdi-numeric-2-circle",
                    "mdi-numeric-3-circle",
                    "mdi-numeric-4-circle",
                    "mdi-numeric-5-circle",
                    "mdi-numeric-6-circle",
                    "mdi-numeric-7-circle",
                    "mdi-numeric-8-circle",
                    "mdi-numeric-9-circle"
                ]
                let icon = icons[item.level]
                return open ? icon : icon + outlineString
            },
            addChildCategory(item) {
                // open cat
                this.open.push(item.id)
                let newCat = { name: "", children: [], edit: true, new: true, level: item.level + 1, category_code_parent: item.category_code }
                // find cat to push child
                var findInTree = (parents) => {
                    parents.forEach((category) => {
                        if (category.category_code == item.category_code) {
                            category.children.push(newCat)
                            return
                        } else {
                            findInTree(category.children)
                        }
                    })
                }
                findInTree(this.tree)
            },
            addTopLevelCategory() {
                console.log(this.tree)
                let newCat = { name: "", children: [], edit: true, new: true, level: 1 }
                this.tree.unshift(newCat)
            },

            removeCatFromDataset(item) {
                if (this.categories_type == 2) {
                    const index = this.$helpers.getIndexbyAttribute(this.customCats, item, "_id")
                    if (index > -1) {
                        this.customCats.splice(index, 1)
                        this.resetCats(this.customCats)
                        return
                    }
                    console.log("couldnt find cat")
                }
            },
            deleteCategory(item) {
                console.log(item)
                // check if its a new cat creation cancel -- missing
                // remove cat from keywords and show message --- missing
                // remove cat from db
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories/delete/one", { company_id: localStorage.company_id, category: item }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        // remove cat from dataset
                        console.log(response)
                        this.removeCatFromDataset(item)
                        this.notification = this.$helpers.createNotification(response.body.message, "mdi-check-circle", "success")
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.body.message) {
                            this.notification = this.$helpers.createNotification(err.body.message, "mdi-alert", "warning")
                        }
                    })
            },
            createNewCategory(item) {
                let newCat = { category_name: item.name, category_code_parent: item.category_code_parent ? item.category_code_parent : null, custom: true }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories/add", { company_id: localStorage.company_id, category: newCat }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        console.log(response.body.category)
                        let cat = response.body.category
                        cat.id = cat.category_code
                        cat.level = item.level
                        cat.name = cat.category_name
                        this.customCats.push(cat)
                        this.resetCats(this.customCats)
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.body.message) {
                            this.notification = this.$helpers.createNotification(err.body.message, "mdi-alert", "warning")
                        }
                    })
            },
            renameCategory(item) {
                console.log(item)
                let newCat = { category_name: item.name, category_code_parent: item.category_code_parent ? item.category_code_parent : null, category_code: item.category_code }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories/rename", { company_id: localStorage.company_id, category: newCat }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        // console.log(response.body.category)
                        // let cat = response.body.category
                        // cat.id = cat.category_code
                        // cat.level = item.level
                        // cat.name = cat.category_name
                        // this.customCats.push(cat)
                        // this.resetCats(this.customCats)
                        this.fetchCategories()
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.body.message) {
                            this.notification = this.$helpers.createNotification(err.body.message, "mdi-alert", "warning")
                        }
                    })
            },
            saveChanges(item) {
                // Input String Valdidierung
                // name given , too short?
                console.log(item)
                this.setEdit(item, false)
                // if (this.clickedCat == item) return //no changes
                console.log(item)
                // console.log(this.tree.indexOf(item))
                if (!item.name || item.name.length < 2) {
                    this.notification = this.$helpers.createNotification("Please enter a proper category name!", "mdi-alert-circle-outline", "warning")
                    return
                }
                // create new Category
                if (item.new) {
                    // if new cat add to db
                    // if new cat add to tree
                    this.createNewCategory(item)
                    return
                }
                // if existing cat update in db
                if (item.edit) {
                    this.renameCategory(item)
                }
                // close text field
            },

            async resetCats(cats) {
                this.tree = await this.list_to_tree(cats)
            },
            async setEdit(item, edit) {
                this.clickedCat = item
                if (this.categories_type == 1) {
                    this.googleCats.forEach((cat) => {
                        if (cat.id == item.id) {
                            cat.edit = edit
                            this.resetCats(this.googleCats)
                            return
                        }
                    })
                } else {
                    this.customCats.forEach((cat) => {
                        if (cat.id == item.id) {
                            cat.edit = edit
                            this.resetCats(this.customCats)
                            return
                        }
                    })
                }
            },
            list_to_tree(dataset) {
                return new Promise((resolve, reject) => {
                    const hashTable = Object.create(null)
                    dataset.forEach((aData) => (hashTable[aData.category_code] = { ...aData, children: [] }))
                    const dataTree = []
                    dataset.forEach((aData) => {
                        if (aData.category_code_parent) hashTable[aData.category_code_parent].children.push(hashTable[aData.category_code])
                        else dataTree.push(hashTable[aData.category_code])
                    })
                    resolve(dataTree)
                })
            },
            setLevels(cats) {
                cats.forEach((cat) => {
                    // add level to cats
                    cat.level = 1
                    var code = cat.category_code_parent
                    if (code != null) {
                        cat.level++
                        do {
                            let parent = cats.filter((x) => x.category_code == code)
                            code = parent[0].category_code_parent
                            if (code) cat.level++
                        } while (code)
                    }
                })
                return cats
            },
            async processCats(cats) {
                cats.map((x) => {
                    x.id = x.category_code
                    x.name = x.category_name
                })
                cats = this.setLevels(cats)
                this.googleCats = cats.filter((x) => x.category_code < 99999)
                this.customCats = cats.filter((x) => x.category_code > 99999 && x.category_code != 1000000)
                this.tree = await this.list_to_tree(this.googleCats)
                this.categories_loading = false
            },
            fetchCategories() {
                this.categories_loading = true
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        cats.unshift({ category_name: "-" })
                        this.processCats(cats)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        mounted() {
            this.fetchCategories()
        }
    }
</script>
<style scoped>
    .v-treeview-node__root:hover .cat_actions {
        color: rgb(104, 104, 104) !important;
    }
    .v-treeview-node__root .cat_actions {
        color: white !important;
    }
    .cat_text_field {
        width: 200px;
    }
    #catrow {
        display: inline-flex;
    }
    .v-tooltip .v-tooltip--top {
        background-color: orange !important;
    }
    .tooltip_round {
        border-radius: 15px !important;
    }
</style>
