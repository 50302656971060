<template>
    <div>
        <v-row>
            <!-- :sm="languages && languages.length > 0 ? '6' : '12'" -->
            <v-col cols="12" sm="6" class="pt-0">
                <!--  {{ search }} -->
                <v-autocomplete
                    id="location_select"
                    v-model="selectedLocation"
                    :items="locations"
                    item-text="location_name"
                    item-value="id"
                    prepend-inner-icon="mdi-map-marker"
                    rounded
                    filled
                    hide-no-data
                    hide-details
                    placeholder="Start typing to Search"
                    color="primary"
                    return-object
                    clearable
                    clear-icon="mdi-close"
                    @change="onLocationSelected"
                    :search-input.sync="search">
                    <template v-slot:selection="{ item }">
                        <!-- <v-chip small > -->
                        <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; color: rgba(0, 0, 0, 0.87)">
                            {{ item.location_name }} <span v-if="item.parent_name" style="text-transform: uppercase"> , {{ item.parent_iso_code }} </span></span
                        >
                        <!-- </v-chip> -->
                    </template>
                    <template v-slot:item="{ item }">
                        <!-- <v-chip small > -->
                        {{ item.location_name }}<span v-if="item.parent_name">, {{ item.location_code_parent }} </span>
                        <span v-if="item.parent_name" style="text-transform: uppercase"> , {{ item.parent_iso_code }}</span>
                        <!-- </v-chip> -->
                    </template>
                </v-autocomplete>
                <!-- {{ selectedLocation && selectedLocation.parent_name ? "Country:" + selectedLocation.parent_iso_code : "" }} -->
            </v-col>
            <v-col cols="12" sm="6" class="pt-0">
                <v-select
                    v-model="selectedLanguage"
                    :items="languages"
                    item-text="language_name"
                    item-value="id"
                    prepend-inner-icon="mdi-translate"
                    rounded
                    no-data-text="-"
                    :placeholder="languages.length == 0 ? '-' : ''"
                    hide-details
                    clear-icon="mdi-close"
                    filled
                    color="primary"
                    @change="onLanguageSelected"
                    return-object
                    clearable>
                    <!-- <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="null">
                    <v-list-item-action>
                        <v-icon :color="selectedFruits.length > 0 ? 'indigo darken-4' : ''">
                            mdi-check-box
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider> </template
        > -->
                </v-select>
            </v-col>
        </v-row>

        <!-- -->
    </div>
</template>

<script>
    import locs from "./helper/locs"
    export default {
        data() {
            return {
                selectedLocation: null,
                selectedLanguage: null,
                locations: [],
                languages: [],
                search: ""
            }
        },
        props: {
            suggest_tab: String
        },
        methods: {
            async fetchLocations(query = false) {
                try {
                    const post_data = {}
                    if (query) {
                        post_data.query = query
                    }
                    const url = process.env.VUE_APP_APIURL + "/service/google-locations"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data.locations)
                    console.log(this.suggest_tab)
                    console.log(locs)
                    if (this.suggest_tab !== "tab-2") {
                        this.locations = response_data.data.locations
                    } else {
                        this.locations = response_data.data.locations.filter((x) => locs.stateItems.findIndex((y) => y.value == x.location_code) > -1)
                    }
                } catch (error) {
                    console.error("Error fetching locations:", error)
                }
            },
            async fetchLanguagesInit(location) {
                try {
                    const post_data = { location: location }

                    const url = process.env.VUE_APP_APIURL + "/service/google-languages"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data.languages)
                    if (response_data.data.languages.length > 0) {
                        this.languages = response_data.data.languages
                        this.selectedLanguage = this.languages[0]
                        this.selectedLocation.language = this.selectedLanguage
                    } else {
                        this.languages = []
                        this.selectedLocation.language = null
                    }
                    this.buildLangLocObject()
                } catch (error) {
                    console.error("Error fetching locations:", error)
                }
            },
            // async fetchLanguages(location) {
            //     try {
            //         const post_data = { location: location }

            //         const url = process.env.VUE_APP_APIURL + "/service/google-languages"
            //         let response_data = await this.$helpers.axiosPost(url, post_data)
            //         console.log(response_data.data.languages)
            //         if (response_data.data.languages.length > 0) {
            //             this.languages = response_data.data.languages
            //             let langindex = this.languages.findIndex((x) => x.language_code == this.selected_location.ads_lang_code)
            //             console.log("langindex", langindex)
            //             // if (langindex > -1) this.selectedLanguage = this.languages[langindex]
            //         } else {
            //             this.languages = []
            //             // this.selectedLocation.language = null
            //         }
            //         // this.buildLangLocObject()
            //     } catch (error) {
            //         console.error("Error fetching locations:", error)
            //     }
            // },
            onLocationSelected() {
                if (this.selectedLocation) {
                    console.log("Selected Location:", this.selectedLocation)
                    // Additional actions on location selection
                    if (this.selectedLocation.location_name !== "International") {
                        this.fetchLanguagesInit(this.selectedLocation)
                    } else {
                        this.languages = []
                        this.buildLangLocObject()
                    }
                } else {
                    this.fetchLocations()
                }
            },
            onLanguageSelected() {
                if (this.selectedLanguage) {
                    console.log("lang", this.selectedLanguage)
                    // Additional actions on location selection
                    this.selectedLocation.language = this.selectedLanguage
                    console.log("Selected Location:", this.selectedLocation)
                    this.buildLangLocObject()
                }
            },
            // onLocationInput(query) {
            //     // Fetch locations based on user input
            //     if (query) {
            //         this.fetchLocations(query)
            //     }
            // },
            buildLangLocObject() {
                if (this.selectedLocation) {
                    let questions = !this.selectedLocation.language
                        ? {}
                        : !locs.stateItems[locs.stateItems.findIndex((x) => x.value == this.selectedLocation.location_code && x.ads_lang_code == this.selectedLocation.language.language_code)]
                        ? {}
                        : locs.stateItems[locs.stateItems.findIndex((x) => x.value == this.selectedLocation.location_code && x.ads_lang_code == this.selectedLocation.language.language_code)]

                    let langloc = {
                        loc: this.selectedLocation.location_name,
                        value: this.selectedLocation.location_code,
                        ads_lang_code: this.selectedLocation.language == null ? null : this.selectedLocation.language.language_code,
                        cc:
                            this.selectedLocation.location_type == "Country"
                                ? this.selectedLocation.country_iso_code
                                    ? this.selectedLocation.country_iso_code.toLowerCase()
                                    : null
                                : this.selectedLocation.parent_iso_code,
                        serp_lang_code:
                            this.selectedLocation.location_type == "Country" && this.selectedLocation.language
                                ? this.selectedLocation.language.language_code
                                : this.selectedLocation.parent_iso_code
                                ? this.selectedLocation.parent_iso_code
                                : null,
                        questions: questions.questions,
                        questionParams: questions.questionParams,
                        prepositionParams: questions.prepositionParams,
                        comparisonParams: questions.comparisonParams
                    }
                    console.log(JSON.stringify(langloc))
                    this.$store.dispatch("SET_SELECTED_LOCATION", {
                        selectedLocation: this.selectedLocation,
                        locations: this.locations,
                        selectedLanguage: this.selectedLanguage,
                        languages: this.languages
                    })
                    this.$emit("setLangLoc", langloc)
                    localStorage.setItem("location_selection", JSON.stringify({ selectedLocation: this.selectedLocation, selectedLanguage: this.selectedLanguage }))
                }
            },
            setLangloc() {
                // console.log(this.locations.length)
                // console.log(this.selected_location.value)
                // let loc_index = this.locations.findIndex((x) => x.location_code == this.selected_location.value)
                // console.log("index", loc_index)
                // if (loc_index > -1) {
                //     this.selectedLocation = this.locations[loc_index]
                //     if (this.selectedLocation.location_name !== "International") {
                //         this.fetchLanguages(this.selectedLocation)
                //     } else {
                //         this.buildLangLocObject()
                //     }
                // }
            }
        },
        watch: {
            async suggest_tab(val) {
                if (val == "tab-2") {
                    console.log("filtered")
                    if (locs.stateItems.findIndex((x) => x.value == this.selectedLocation.location_code) == -1) {
                        console.log("setting germmany")
                        await this.fetchLocations("Germany")
                        this.selectedLocation = this.locations[0]
                        this.fetchLanguagesInit(this.selectedLocation)
                    } else {
                        this.locations = this.locations.filter((x) => locs.stateItems.findIndex((y) => y.value == x.location_code) > -1)
                    }
                    console.log("selectedLocation")
                } else {
                    this.fetchLocations(this.search)
                }
            },
            search(val) {
                if (!val) return
                console.log("watch")
                this.fetchLocations(this.search)
            },
            "$store.state.selected_location"() {
                console.log("CHANGEDDD")
                console.log(this.$store.state.selected_location)
                this.locations = this.$store.state.selected_location.locations
                this.selectedLocation = this.$store.state.selected_location.selectedLocation
                this.languages = this.$store.state.selected_location.languages
                this.selectedLanguage = this.$store.state.selected_location.selectedLanguage
                // this.buildLangLocObject()
            }
        },
        async mounted() {
            // Fetch locations initially
            if (!localStorage.location_selection) {
                console.log("init fetch")
                await this.fetchLocations("Germany")
                this.selectedLocation = this.locations[0]
                this.fetchLanguagesInit(this.selectedLocation)
            } else {
                console.log(localStorage.location_selection)
                let loc = JSON.parse(localStorage.location_selection)
                // if (loc.location_name !== "International") {
                if (this.suggest_tab == "tab-2") {
                    console.log("filtered")
                    if (locs.stateItems.findIndex((x) => x.value == loc.selectedLocation.location_code) == -1) {
                        console.log("setting germmany")
                        await this.fetchLocations("Germany")
                        this.selectedLocation = this.locations[0]
                        this.fetchLanguagesInit(this.selectedLocation)
                    } else {
                        await this.fetchLocations(loc.selectedLocation.location_name)
                        this.selectedLocation = loc.selectedLocation
                        this.fetchLanguagesInit(this.selectedLocation)
                        this.selectedLanguage = loc.selectedLanguage
                        this.locations = this.locations.filter((x) => locs.stateItems.findIndex((y) => y.value == x.location_code) > -1)
                    }
                    console.log("selectedLocation")
                } else {
                    await this.fetchLocations(loc.selectedLocation.location_name)
                    this.selectedLocation = loc.selectedLocation
                    this.fetchLanguagesInit(this.selectedLocation)
                    this.selectedLanguage = loc.selectedLanguage
                }
                // } else {
                //     this.selectedLanguage = {}
                //     this.languages = []
                // }
                this.buildLangLocObject()
            }

            // this.setLangloc()
        }
    }
</script>
