<template>
    <div>
        <v-row>
            <v-col cols="6">
                <v-card color="primary" class="mx-auto">
                    <!-- <v-btn right absolute @click="dialog = false" class="mb-1" icon><v-icon color="primary">mdi-close</v-icon></v-btn> -->
                    <v-card-text class="">
                        <v-row>
                            <v-col cols="4" style="display: flex">
                                <img width="120" class="mx-auto" src="../assets/undraw_At_work_re_qotl.svg" />
                            </v-col>
                            <v-col cols="8">
                                <h2 class="my-2 white-text">
                                    {{ bannertext.title }}
                                </h2>

                                <p class="mb-3 white--text">{{ bannertext.firstp }}</p>
                                <v-btn small @click="$emit('createBtnClick')"><v-icon small class="mr-1">mdi-plus-circle-outline</v-icon>create new collection</v-btn>
                                <!-- <AddProjectsDialog></AddProjectsDialog> -->
                            </v-col>
                        </v-row>

                        <!-- {{welcome_message.secondp}} -->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "CreateCollectionBanner",
        props: {},
        components: {},
        data() {
            return {
                bannertext: {
                    title: "Ups, nothing here yet!",
                    firstp: "Let`s create your first Collection!",
                    secondp:
                        "Der Name Quaro kommt vom lateinischen Verb Quaerere und steht unter anderem für Suchen, Fragen und Erforschen. Wir sind der Überzeugung, dass der neue Name und das neue Logo uns besser beschreiben und einfach besser passen."
                }
                // user: localStorage.username ?  localStorage.username : 'undefined',
                // plan: localStorage.plan ?  localStorage.plan : 'undefined',
            }
        },
        computed: {
            //   decryptedName(){
            //     return this.CryptoJS.AES.decrypt(this.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            //   },
            //   decryptedPlan(){
            //     return this.CryptoJS.AES.decrypt(this.plan, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            //   },
        },
        methods: {}
    }
</script>

<style scoped></style>
