<template>
    <div>
        <!-- <v-btn @click="openFilterMenu" icon color="primary">Filter erstellen</v-btn> -->

        <v-menu v-model="menuOpen" :close-on-content-click="false" offset-x rounded>
            <template v-slot:activator="{ on }">
                <span v-on="on">
                    <v-slide-x-transition hide-on-leave>
                        <v-btn :small="dense" v-if="filters.length == 0" rounded class="mr-1" color="primary"><v-icon class="mr-1">mdi-filter-variant</v-icon> Filter</v-btn>
                    </v-slide-x-transition>
                    <v-slide-x-transition hide-on-leave>
                        <v-chip class="pointer" small close v-if="filters.length > 0" @click:close="filters = []">
                            <v-icon close-icon="mdi-close" small color="primary" class="mr-1">mdi-filter-variant</v-icon>
                            {{ filters.length }} {{ filters.length === 1 ? " active filter" : " active filters" }}</v-chip
                        >
                    </v-slide-x-transition>
                </span>
            </template>

            <v-card rounded>
                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-select hide-details rounded filled v-model="selectedField" :items="fields" label="Field to filter" item-value="key" item-text="name"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select hide-details rounded filled v-model="selectedOperator" :items="operators" label="Operator"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <div v-bind="(optionsData = options)">
                                <v-autocomplete v-if="optionsData.values" hide-details rounded filled v-model="filterValue" :items="optionsData.values" label="Value">
                                    <!-- <template v-slot:item="data"> {{ getDecryptedName(data.item) }} </template> -->
                                </v-autocomplete>
                                <v-text-field v-else hide-details rounded filled v-model="filterValue" label="Value" @keyup.enter="addFilter"></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-btn rounded @click="addFilter" color="primary">Add Filter</v-btn>
                </v-card-text>
                <v-divider v-if="filters.length"></v-divider>
                <v-card-text v-if="filters.length">
                    <span v-for="(filter, index) in filters" :key="index">
                        <v-chip color="" class="mr-2" close @click:close="removeFilter(index)">
                            <v-icon color="primary" small class="mr-1">mdi-filter-variant</v-icon> {{ filter.field.name }}
                            <span class="mx-1"
                                ><b>{{ filter.operator.name }}</b></span
                            >
                            {{ filter.value }}
                        </v-chip>
                        <!-- <span class="d-flex mr-2 mt-1" v-if="filters.length > 0 && index < filters.length - 1"><span class="my-auto">AND</span></span> -->
                    </span>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    export default {
        props: {
            fields: Array,
            dense: Boolean
        },
        data() {
            return {
                selectedField: "",
                selectedOperator: "",
                filterValue: "",
                filters: [],
                menuOpen: false,
                filterLinkOperator: "AND", // Standardverknüpfungsoperator
                filterLinkOperators: ["AND", "OR"] // Optionen für die Verknüpfung
            }
        },
        watch: {
            filters() {
                this.$emit("filtersUpdated", this.filters)
            }
        },
        computed: {
            options() {
                if (!this.selectedField) return { values: null }
                let field = this.fields.find((f) => f.key === this.selectedField)
                try {
                    return { values: field.options.values }
                } catch (error) {
                    return { values: null }
                }
            },
            operators() {
                if (!this.selectedField) return
                let numbers = ["equals", "greater than", "less than"]
                let text = ["equals", "contains", "does not contain"]
                let options = ["equals"]
                let arrayOptions = ["includes"]
                console.log(this.selectedField)
                let field = this.fields.find((f) => f.key === this.selectedField)
                if (field.type == "number") {
                    return numbers
                }
                if (field.type == "text") {
                    return text
                }
                if (field.type == "array") {
                    return arrayOptions
                }
                return options
            }
        },
        methods: {
            getDecryptedName(item) {
                if (item && item.length > 0) {
                    let name = this.CryptoJS.AES.decrypt(item, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                    return name
                } else {
                    return null
                }
            },
            openFilterMenu() {
                this.menuOpen = true
            },
            addFilter() {
                if (this.selectedField && this.selectedOperator && this.filterValue !== "") {
                    const fieldObj = this.fields.find((field) => field.key === this.selectedField)
                    this.filters.push({
                        field: fieldObj,
                        operator: { name: this.selectedOperator, value: this.getOperatorValue(this.selectedOperator) },
                        value: this.filterValue
                    })
                    this.selectedField = ""
                    this.selectedOperator = ""
                    this.filterValue = ""
                }
            },
            removeFilter(index) {
                this.filters.splice(index, 1)
            },
            getOperatorValue(operator) {
                switch (operator) {
                    case "equals":
                        return "equals"
                    case "includes":
                        return "includes"
                    case "greater than":
                        return "greaterThan"
                    case "less than":
                        return "lessThan"
                    case "contains":
                        return "contains"
                    case "does not contain":
                        return "doesNotContain"
                    default:
                        break
                }
            }
        }
    }
</script>
<style scoped>
    .pointer:hover {
        cursor: pointer !important;
    }
    .pointer {
        margin-top: 6px;
    }
</style>
