<template>
    <div>
        <v-dialog v-model="urlPreview.show" scrollable>
            <v-card >
                <v-card-title>
                    URL Preview: {{ urlPreview.url.base  + urlPreview.url.params }}
                    <v-btn right absolute @click="urlPreview.show = false" class="mb-1" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <iframe id="ifrm" :src="urlPreview.url.base + 'igu=1&' + urlPreview.url.params" width="2000" height="1000"></iframe>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="" @click="urlPreview.show = false" dark color="primary">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "OpenSerpDialog",
        props: {
            task_id: String
        },
        data() {
            return {
                urlPreview: {
                    show: false,
                    url: {}
                },
            }
        },
        computed: {
         
        },
        watch: {
            task_id(){
                console.log("new task id")
                this.fetchSerpCheckUrl()
            }
        },
        methods: {
            async fetchSerpCheckUrl() {
                try {
                    const requestData = {
                        task_id: this.task_id
                    }
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/frontend/checkurl"
                    const response = await this.$helpers.axiosPost(url, requestData)
                    // let response = {
                    //     data:{
                    //         check_url:"https://www.google.de/search?q=schlauch%20lkw%20reifen&num=100&hl=de&gl=DE&gws_rd=cr&ie=UTF-8&oe=UTF-8&uule=w+CAIQIFISCWu-scIecppHEVvlkY5rXeh1"
                    //     }
                    // }
                    let index = response.data.check_url.indexOf("?"); // Finde den Index des ersten Fragezeichens
                    let firstPart = response.data.check_url.substring(0, index); // Extrahiere den Teil des Strings vor dem ersten Fragezeichen
                    let secondPart = response.data.check_url.substring(index + 1); // Extrahiere den Teil des Strings nach dem ersten Fragezeichen
                    this.urlPreview.url = { base: firstPart + "?", params: secondPart }
                    console.log("url check",response.data.check_url == (firstPart+"?"+ secondPart))
                    this.urlPreview.show = true
                    console.log(response.data)
                } catch (error) {
                    console.error(error)
                    // Handle error here
                    this.loading = false
                }
            },
        }
    }
</script>

<style>
    