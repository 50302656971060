<template>
    <v-card class="quaroshadow rounded_card" style="height: 100%">
        <div class="backsheet"></div>
        <!-- <AddTopicsDialog
            :active="{ process: 'briefing', area: 'term_placement' }"
            :tags="placementColors"
            :show="addTopicsDialogOptions.show"
            @started="
                addTopicsDialogOptions.loading = true
                addTopicsDialogOptions.show = false
            "
            @added="addTopics"
            @close="addTopicsDialogOptions.show = false"
            @failed="
                notification = $helpers.createNotification('Something went wrong fetching your keywords! Please try again or contact support.', 'mdi-alert', 'orange darken-2')
                addTopicsDialogOptions.loading = false
            "></AddTopicsDialog> -->
        <v-card-title class="briefing_card_label">
            Term Placements
            <!-- <v-btn rounded class="ml-auto mb-2" color="primary" @click="addTopicsDialogOptions.show = true" :loading="addTopicsDialogOptions.loading">enter manually</v-btn> -->
            <v-tooltip max-width="250" color="" top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                </template>
                <span>Shows where your competitors are placing the keyword on their website.</span>
            </v-tooltip>
        </v-card-title>
        <v-data-table id="btptable" fixed-header :headers="headers" :items="termplacements" class="elevation-0 comp-table" item-key="id">
            <!-- header slot -->
            <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                <span :key="index">
                    <span v-bind:style="{ width: header.width }">
                        <!-- Adding tooltips to header -->
                        <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    {{ header.text }}
                                    <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                </span>
                            </template>
                            <span>
                                <v-row no-gutters>
                                    <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                </v-row>
                                {{ header.tooltip }}
                            </span>
                        </v-tooltip>
                    </span>
                </span>
            </template>

            <!-- Table Body -->
            <template v-slot:body="{ items }">
                <tbody v-if="items.length">
                    <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                        <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="table_td">
                            <!-- ------- -->
                            <!-- keyword -->
                            <!-- ------- -->
                            <div v-if="header.value === 'keyword'" class="briefing_td_keyword">
                                {{ item[header.value] }}
                            </div>

                            <!-- -------------- -->
                            <!---- tag ----->
                            <!-- -------------- -->
                            <div v-if="header.value === 'tag'">
                                <v-chip class="white--text" :color="placementColors[item[header.value]]"> {{ item[header.value] }}</v-chip>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    // import AddTopicsDialog from "../../content-suite/AddTopicsDialog.vue"

    export default {
        props: {
            termplacements: Array
        },
        // components: {
        //     AddTopicsDialog
        // },
        data() {
            return {
                // addTopicsDialogOptions: {
                //     show: false,
                //     loading: false
                // },
                placementColors: {
                    h1: "cyan darken-3",
                    h2: "purple darken-3",
                    h3: "lime darken-3",
                    h4: "light-green darken-3",
                    h5: "blue darken-3",
                    h6: "orange darken-3",
                    description: "grey darken-2",
                    title: "grey darken-2"
                },
                headers: [
                    {
                        text: "Keyword",
                        align: "left",
                        sortable: true,
                        value: "keyword"
                    },
                    {
                        text: "Tag",
                        value: "tag",
                        align: "left",
                        sortable: true
                    }
                ],
                tblOptions: {
                    "footer-props": {
                        "items-per-page-options": [5, 10, 15]
                    }
                },
                perpage: 10
            }
        },
        methods: {
            // addTopics(topics) {
            //     this.$emit("addTopics", topics)
            // }
        }
    }
</script>

<style>
    .briefing_td_keyword {
        /* font-weight: bold !important; */
    }
</style>
