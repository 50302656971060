<template>
    <div id="contentcontainter" class="standard-mode">
        <v-container id="register" fill-height tag="section" class="pt-15">
            <v-slide-y-transition appear>
                <v-card class="pa-3 pa-md-5 mx-auto regsitercard rounded_card quaroshadow" light id="registercard" style="margin-top: 50px">
                    <!-- SSO MESSAGE -->
                    <v-snackbar top color="green" timeout="10000" v-model="showSSOInfo">
                        <v-icon>mdi-check-circle-outline</v-icon>
                        <span class="font-weight-bold pl-2"
                            >SSO detected. Please use SSO button if you want to use Single Sign On.<br />
                            You will be redirected and logged in through your SSO provider.</span
                        >
                    </v-snackbar>
                    <!-- registered alert -->
                    <v-snackbar top v-model="showRegisteredMessage" color="green" class="pl-10">
                        <v-icon>mdi-check-circle-outline</v-icon>
                        <span class="font-weight-bold">Welcome to Quaro, please check your email account for our activation-link!</span>
                    </v-snackbar>
                    <!-- exists alert -->
                    <v-snackbar top v-model="exists" color="red" class="pl-10">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                        <span class="font-weight-bold">{{ existMessage }}</span>
                    </v-snackbar>
                    <!-- not allowed alert -->
                    <v-snackbar top v-model="notAllowed" timeout="10000" color="red" class="pl-10">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                        <span class="font-weight-bold">{{ notAllowedMessage }}</span>
                    </v-snackbar>

                    <!-- AGB -->
                    <v-dialog v-model="agb" max-width="800">
                        <v-card>
                            <v-card-title class="headline"> Allgemeine Geschäftsbedingungen </v-card-title>

                            <v-card-text> Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running. </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn color="green darken-1" text @click="agb = false"> Agree </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <center>
                        <div id="sidebarLogo" class="quaroshadow">
                            <img width="150" id="whitelogo" src="../assets/quaro_final_white.svg" />
                            <template>
                                <div class="text-center">
                                    <v-btn v-for="(social, i) in socials" :key="i" :href="social.href" class="ma-1" icon rel="noopener" target="_blank">
                                        <v-icon v-text="social.icon" />
                                    </v-btn>
                                </div>
                            </template>
                        </div>
                    </center>
                    <v-layout>
                        <v-container>
                            <div class="text-center">
                                <div class="my-2" />
                                <!-- <span class="existMessage">
                    {{existMessage}}
                  </span> -->
                                <form @submit.prevent="register" autocomplete="off">
                                    <v-text-field rounded filled class="mr-3" v-model="username" color="primary" label="Your Name" prepend-icon="mdi-face" required />
                                    <v-text-field rounded filled class="mr-3" v-model="email" color="primary" label="Email..." type="email" prepend-icon="mdi-email" @blur="blurFunctions" required />
                                    <v-text-field
                                        rounded
                                        filled
                                        class="mr-3"
                                        v-model="password"
                                        color="primary"
                                        label="Password..."
                                        prepend-icon="mdi-lock-outline"
                                        required
                                        :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_pw ? 'text' : 'password'"
                                        @click:append="show_pw = !show_pw" />
                                    <v-text-field
                                        rounded
                                        filled
                                        v-model="passwordCheck"
                                        class="mr-3"
                                        color="primary"
                                        label="Confirm Password..."
                                        prepend-icon="mdi-refresh"
                                        required
                                        :append-icon="show_pw_check ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_pw_check ? 'text' : 'password'"
                                        @click:append="show_pw_check = !show_pw_check" />

                                    <v-select
                                        v-model="selectedlang"
                                        :items="stateItems"
                                        item-text="loc"
                                        item-value="id"
                                        label="Language"
                                        prepend-icon="mdi-map-marker"
                                        return-object
                                        class="mb-5 mr-3"
                                        rounded
                                        filled
                                        attach
                                        required
                                        color="primary">
                                    </v-select>
                                    <!-- <v-checkbox
                    v-model="checkTerms"
                    color="secondary"
                    required
                  >
                    <template v-slot:label>
                      <span class="text-no-wrap">I agree to the &nbsp;&nbsp;</span>
                      <a
                        class="secondary--text ml-6 ml-sm-0"
                        @click="agb = true"
                        style="padding-left: 5px"
                      >
                        terms and conditions
                      </a>.
                    </template>
                  </v-checkbox> -->
                                    <v-row>
                                        <v-col class="mx-auto"><v-btn :disabled="btndisabled" color="primary" large rounded type="submit"> Register </v-btn></v-col>
                                    </v-row>
                                    <v-row v-if="showSsoButton">
                                        <v-col class="mx-auto"> <v-btn color="primary" large outlined rounded @click="redirectToSSO"> SSO </v-btn></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="mx-auto"> <v-btn color="primary" text @click="$router.push('/login')">Login</v-btn></v-col>
                                    </v-row>
                                    <!-- <p class="registerLink" @click="$router.push('/login')">Login</p> -->
                                </form>
                            </div>
                        </v-container>
                    </v-layout>
                </v-card>
            </v-slide-y-transition>
            <Datenschutz :show="showDatenschutz" @close="showDatenschutz = false"></Datenschutz>
            <v-footer padless fixed bottom>
                <v-col @click="openAGB()" class="footer-col">
                    <span>AGB</span>
                </v-col>
                <v-col @click="openImprint()" class="footer-col">
                    <span>Impressum</span>
                </v-col>
                <v-col @click="showDatenschutz = true" class="footer-col">
                    <span>Datenschutz</span>
                </v-col>
            </v-footer>
        </v-container>
    </div>
</template>
<script>
    import Datenschutz from "../components/footer/Datenschutz.vue"
    export default {
        name: "Register",
        components: {
            Datenschutz
        },
        data: () => ({
            selectedlang: {
                id: 1,
                loc: "German",
                cc: "de"
            },
            stateItems: [
                {
                    id: 1,
                    loc: "German",
                    cc: "de"
                },
                {
                    id: 2,
                    loc: "English",
                    cc: "en"
                }
            ],
            showDatenschutz: false,
            showSSOInfo: false,
            showSsoButton: false,
            agb: false,
            showRegisteredMessage: false,
            username: "",
            email: "",
            password: "",
            passwordCheck: "",
            show_pw: false,
            show_pw_check: false,
            checkTerms: false,
            exists: false,
            existMessage: "",
            notAllowed: false,
            notAllowedMessage: "",
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            ssoChecked: false,
            nameChecked: false,
            btndisabled: false,
            sections: [
                {
                    icon: "mdi-chart-timeline-variant",
                    iconColor: "primary",
                    title: "Marketing",
                    text: `We've created the marketing campaign of the website. It was a very interesting collaboration.`
                },
                {
                    icon: "mdi-code-tags",
                    iconColor: "secondary",
                    title: "Fully Coded in HTML5",
                    text: `We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub.`
                },
                {
                    icon: "mdi-account-multiple",
                    iconColor: "cyan",
                    title: "Built Audience",
                    text: "There is also a Fully Customizable CMS Admin Dashboard for this product."
                }
            ],
            socials: [
                {
                    href: "https://www.facebook.com/quaro.io",
                    icon: "mdi-facebook"
                },
                {
                    href: "https://www.instagram.com/quaro.io/",
                    icon: "mdi-instagram"
                },
                {
                    href: "https://de.linkedin.com/company/quaro-das-keyword-tool",
                    icon: "mdi-linkedin"
                }
            ]
        }),
        methods: {
            openAGB() {
                window.open(`https://quaro.io/agb/`, "_blank")
            },
            openImprint() {
                window.open(`https://quaro.io/impressum/`, "_blank")
            },
            register: function () {
                if (this.password == this.passwordCheck) {
                    if (this.ssoChecked && this.nameChecked) {
                        this.btndisabled = true
                        try {
                            this.$matomo.trackEvent("Register", "register", "", "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }
                        let registerObject = {
                            email: this.email,
                            pass: this.password,
                            username: this.username,
                            lang: this.selectedlang.cc
                        }
                        this.$http
                            .post(process.env.VUE_APP_APIURL + "/user/register", registerObject, { headers: { Authorization: this.auth } })
                            .then((response) => {
                                // //console.log(answer)
                                if (response.body.registered == true) {
                                    this.showRegisteredMessage = true
                                    //console.log(response)
                                    setTimeout(() => {
                                        this.$router.push({ path: "/login" })
                                    }, 5000)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                if (error.body.error.notallowed) {
                                    // TODO: Website redirect
                                    this.notAllowed = true
                                    this.notAllowedMessage =
                                        "Is your email adresss correct? This email adress is not allowed to register yet. Please register a company account first. You will be redirected..."
                                    setTimeout(() => {
                                        window.open(`https://quaro.io/keyword-tool/`, "_blank")
                                    }, 8000)

                                    this.btndisabled = false
                                } else if (error.body.singleusererror) {
                                    this.notAllowed = true
                                    this.notAllowedMessage = "Company licence is single user account only. Please contact us for access: contact@quaro.io"
                                    this.btndisabled = false
                                }
                            })
                    }
                } else {
                    this.existMessage = "The passwords do not match"
                    this.exists = true
                    this.password = ""
                    this.passwordCheck = ""
                }
            },
            blurFunctions() {
                this.checkIfExists()
                this.checkForSso()
            },
            checkIfExists: function () {
                this.nameChecked = false
                let nameObject = {
                    user: this.email
                }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/user/namecheck", nameObject, {
                        headers: { Authorization: this.auth }
                    })
                    .then(function (response) {
                        if (response.body.register == false) {
                            this.existMessage = "That email already exists, please try something else"
                            this.exists = true
                            this.email = ""
                            this.nameChecked = false
                        } else {
                            this.nameChecked = true
                        }
                    })
            },
            checkForSso() {
                this.ssoChecked = false
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/user/checkforsso", { mail: this.email }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        console.log(response)
                        if (response.body.sso_status == 1) {
                            this.showSSOInfo = true
                            this.showSsoButton = true
                        }
                        this.ssoChecked = true
                    })
                    .catch((error) => {
                        console.log(error)
                        this.ssoChecked = true
                    })
            },
            redirectToSSO() {
                window.location = process.env.VUE_APP_SAMLURL
            }
        },
        mounted() {
            let language = window.navigator.language
            let languageFistTwo = language.substr(0, 2) // To only keep the first 2 characters.
            console.log(languageFistTwo)
            if (languageFistTwo == "de") {
                this.selectedlang = this.stateItems[0]
            } else {
                this.selectedlang = this.stateItems[1]
            }
        }
    }
</script>
<style lang="scss" scoped>
    .footer-col:hover {
        background-color: rgb(236, 236, 236);
        cursor: pointer;
    }
    .footer-col {
        display: flex;
        font-size: 0.8em;
        span {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .regsitercard {
        padding-bottom: 0px !important;
    }
    #loginbtn {
        font-size: 0.8em;
    }
    .existMessage {
        color: red;
    }
    .v-card--material__heading {
        margin-top: -50px;
    }

    #registercard {
        width: 475px;
        border-radius: 30px;
    }
    #whitelogo {
        padding-top: 20%;
    }
    #sidebarLogo {
        width: 300px;
        height: 170px;
        margin-top: -70px;
        border-radius: 10px;
        margin-bottom: 30px;
        background: linear-gradient(56deg, #019e86 0%, #009e85 59%, #0c8574 100%);
    }
    .registerLink {
        color: rgb(185, 185, 185);
        font-size: 0.9em;
        margin-bottom: -20px;
        padding-top: 25px;
        margin: 0%;
    }
    .registerLink:hover {
        cursor: pointer;
        color: #1cedb2;
    }
</style>
<style lang="sass">
    .v-card--material__heading
      top: -30px
    #register
      .v-list-item__subtitle
        -webkic-line-clamp: initial
        -webkit-box-orient: initial
</style>
