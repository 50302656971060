module.exports = {
    getStandardParams() {
        var alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ä", "ö", "ü"]
        var numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        return { alphabet: alphabet, numbers: numbers }
    },

    // Build params for sending to google api
    // Combinations:
    // numbers+keyword , alphabet+keyword , keywordAlphabet , keywordNumbers
    // Needed Params:
    // cp: length + 1
    // client: response type
    // ds: ?

    buildParamsInstagram(keyword) {
        var standardParams = this.getStandardParams()
        var search = []
        var urls = []

        search.push(keyword)
        urls.push({
            url: "https://www.instagram.com/web/search/topsearch/?context=blended&query=" + keyword,
            platform: "instagram"
        })
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(keyword + standardParams.alphabet[i])
            urls.push({
                url: "https://www.instagram.com/web/search/topsearch/?context=blended&query=" + keyword + standardParams.alphabet[i],
                platform: "instagram"
            })
        }
        //   for (var i = 0; i<standardParams.alphabet.length;i++){
        //     search.push(standardParams.alphabet[i]+keyword);
        //   }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(keyword + standardParams.numbers[i])
            urls.push({
                url: "https://www.instagram.com/web/search/topsearch/?context=blended&query=" + keyword + standardParams.alphabet[i],
                platform: "instagram"
            })
        }
        //   for (var i = 0; i<standardParams.numbers.length;i++){
        //     search.push(standardParams.numbers[i]+keyword);
        //   }

        let params = {
            search: search
        }
        return { params: params, urls: urls, keyword: keyword }
    }
}
