<template>
    <v-card flat tile>
        <!-- <v-card-title>
            <v-icon class="mr-2">mdi-account-multiple</v-icon>
            <span style="color: rgb(86, 86, 86)"> Competitors </span>
        </v-card-title> -->
        <v-data-table
            :headers="headers"
            :items="competitors"
            :loading="loading"
            :sort-desc.sync="sortDesc"
            :sort-by.sync="sortBy"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerProps"
            fixed-header
            height="70vh"
            id="monitoringCompTable"
            class="elevation-0">
            <!-- header slot -->
            <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                <span :key="index">
                    <span v-bind:style="{ width: header.width }">
                        <v-tooltip max-width="250" color="" top :disabled="header.tooltip ? false : true">
                            <template v-slot:activator="{ on }">
                                <span v-on="on" style="white-space: nowrap; text-transform: uppercase; font-weight: 500">
                                    {{ header.text }}
                                    <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                </span>
                            </template>
                            <span>
                                <v-row no-gutters>
                                    <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                </v-row>
                                {{ header.tooltip }}
                            </span>
                        </v-tooltip>
                    </span>
                </span>
            </template>
            <template v-slot:item.domain="{ item }">
                <div class="d-flex py-3">
                    <div class="fav_wrapper my-auto mr-4 ml-3">
                        <img class="fav" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item.domain + '&sz=42'" alt="" />
                    </div>
                    <div class="my-auto dtd">
                        {{ item.domain }}
                    </div>
                </div>
            </template>
            <template v-slot:item.visibility.0.value="{ item }">
                <div class="d-flex justify-end" v-bind="(change = calculatePercentageChange(item.visibility[0], item.visibility[1], 'value'))">
                    <span class="my-auto"> {{ $helpers.formatNumber(item.visibility[0].value) }}</span>
                    <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined v-if="item.visibility.length">
                        <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">{{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}</v-icon>
                        <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                        <span class="my-auto">{{ change.value }}%</span>
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.visibility.0.top10="{ item }">
                <div class="d-flex justify-end" v-bind="(change = calculatePercentageChange(item.visibility[0], item.visibility[1], 'top10'))">
                    <span class="my-auto"> {{ item.visibility[0].top10 }} </span>
                    <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined v-if="item.visibility.length">
                        <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">{{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}</v-icon>
                        <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                        <span class="my-auto">{{ change.value }}%</span>
                    </v-chip>
                </div>
            </template>

            <template v-slot:item.visibility.0.top50="{ item }">
                <div class="d-flex justify-end" v-bind="(change = calculatePercentageChange(item.visibility[0], item.visibility[1], 'top50'))">
                    <span class="my-auto"> {{ item.visibility[0].top50 }} </span>
                    <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined v-if="item.visibility.length">
                        <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">{{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}</v-icon>
                        <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                        <span class="my-auto">{{ change.value }}%</span>
                    </v-chip>
                </div>
            </template>

            <template v-slot:item.visibility.0.top100="{ item }">
                <div class="d-flex justify-end" v-bind="(change = calculatePercentageChange(item.visibility[0], item.visibility[1], 'top100'))">
                    <span class="my-auto"> {{ item.visibility[0].top100 }}</span>
                    <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined v-if="item.visibility.length">
                        <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">{{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}</v-icon>
                        <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                        <span class="my-auto">{{ change.value }}%</span>
                    </v-chip>
                </div>
            </template>
            <template v-slot:item._id="{ item }">
                {{ generateCreatedDate(item._id) }}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        props: {
            search: {
                type: String
            },
            selectedDates: {
                type: Array
            },
            category_id: {
                type: String
            },
            exportData: {
                type: Number
            }
        },
        data() {
            return {
                count: 0,
                loading: false,
                sortDesc: true,
                sortBy: "visibility.0.value",
                page: 1,
                itemsPerPage: 25,
                pagination: {},
                competitors: []
            }
        },
        computed: {
            footerProps() {
                return {
                    itemsPerPageOptions: [10, 25, 50, 100],
                    showFirstLastPage: true
                }
            },
            headers() {
                return [
                    { text: "Domain", value: "domain", tooltip: "" },
                    {
                        text: "Visibility",
                        value: "visibility.0.value",
                        tooltip: "The visibility value is based on the rankings the competitor got in the selected timeperiod and category",
                        align: "end"
                    },
                    { text: "Top 10 Rankings", value: "visibility.0.top10", tooltip: "The amount of rankings where the position in googles search results is 10 or lower.", align: "end" },
                    { text: "Top 50 Rankings", value: "visibility.0.top50", tooltip: "The amount of rankings where the position in googles search results is 50 or lower.", align: "end" },
                    { text: "Top 100 Rankings", value: "visibility.0.top100", tooltip: "The amount of rankings where the position in googles search results is 100 or lower.", align: "end" },
                    { text: "Created", value: "_id", tooltip: "The date the competitor was identified", align: "end" }
                    // You can add more headers here if needed
                ]
            }
        },
        methods: {
            async fetchData() {
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/frontend/competitors"
                const sort = this.getSortObject()

                let post_data = {
                    company_id: localStorage.company_id,
                    project_id: this.$route.params.id,
                    category_id: this.category_id ? this.category_id : null,
                    sortDesc: this.sortDesc ? 1 : -1,
                    selectedDates: this.selectedDates,
                    search: this.search,
                    start: (this.page - 1) * this.itemsPerPage,
                    limit: this.itemsPerPage,
                    sort: sort
                }
                if (this.sortDomain) {
                    post_data.sortDomain = this.sortDomain
                }
                this.loading = true
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.competitors = response_data.data.competitors
                this.count = response_data.data.totalCount ? response_data.data.totalCount : 0
                this.loading = false
                console.log(response_data.data)
            },
            getSortObject() {
                let sort = {}
                var sortBy,
                    sortDesc = ""
                if (this.sortBy && this.sortBy.length > 0) {
                    sortBy = Array.isArray(this.sortBy) ? this.sortBy[0] : this.sortBy
                    sortDesc = Array.isArray(this.sortDesc) ? this.sortDesc[0] : this.sortDesc
                }
                sort[sortBy] = sortDesc ? -1 : 1
                return sort
            },
            generateCreatedDate(objectId) {
                // Convert MongoDB ObjectID to a timestamp and format the date
                // Convert MongoDB ObjectID to a timestamp and format the date
                const timestamp = new Date(parseInt(objectId.substring(0, 8), 16) * 1000)
                const formattedDate = timestamp.toISOString().slice(0, 10)
                return formattedDate
            },
            calculatePercentageChange(currentValue, previousValue, key) {
                if (!previousValue || previousValue[key] === 0) {
                    return { value: "0.00" }
                }
                const changePercentage = ((currentValue[key] - previousValue[key]) / previousValue[key]) * 100

                return { value: changePercentage.toFixed(2) }
            }
        },
        watch: {
            async exportData() {
                console.log("download triggered")
                console.log(this.selectedDates)

                let fields = this.headers.map((header) => header.text)
                let rows = this.competitors.map((competitor) => {
                    let row = {}
                    row["Domain"] = competitor.domain
                    let visibEnty = competitor.visibility.find((e) => e.date == this.selectedDates[1])
                    row["Visibility"] = visibEnty ? visibEnty.value : 0
                    row["Top 10 Rankings"] = visibEnty ? visibEnty.top10 : 0
                    row["Top 50 Rankings"] = visibEnty ? visibEnty.top50 : 0
                    row["Top 100 Rankings"] = visibEnty ? visibEnty.top100 : 0
                    row["Created"] = this.generateCreatedDate(competitor._id)
                    return row
                })
                console.log(rows, fields)

                let post_data = {
                    rows: rows,
                    fields: fields
                }
                if (this.selectedDates[1]) {
                    post_data.fileTitle = "Competitors_" + this.selectedDates[1].substring(0, 10)
                } else {
                    post_data.fileTitle = "Competitors_" + this.selectedDates[0].substring(0, 10)
                }
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/export/csv"
                let response_data = await this.$helpers.axiosPost(url, post_data)
                window.location = process.env.VUE_APP_MONITORING_API_URL + "/" + response_data.data
            },
            category_id() {
                this.fetchData()
            },
            selectedDates() {
                this.fetchData()
            },
            // competitors: {
            //     handler() {
            //         // Fetch data when the competitors prop changes
            //         this.fetchData()
            //     },
            //     immediate: true // Fetch data initially when the component is created
            // },
            search() {
                // Fetch data when the search query changes
                this.page = 1 // Reset the page to 1 when searching
                this.fetchData()
            },
            sortBy() {
                // Fetch data when the sort column changes
                this.fetchData()
            },
            sortDesc(val) {
                let h = Array.isArray(val) ? val[0] : val
                if (h) {
                    this.fetchData()
                }
                // Fetch data when the sort direction changes
            },
            page() {
                // Fetch data when the page changes
                this.fetchData()
            },
            itemsPerPage() {
                // Fetch data when the number of items per page changes
                this.fetchData()
            }
        },
        mounted() {
            this.fetchData()
        }
    }
</script>
<style>
    #monitoringCompTable th {
        white-space: nowrap !important;
    }
</style>
<style scoped>
    .dtd {
        font-size: 0.98rem;
    }
</style>
