var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"quaroshadow rounded_card",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"backsheet"}),_c('v-card-title',{staticClass:"briefing_card_label"},[_vm._v(" Term Placements "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-1 mr-auto",attrs:{"x-small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Shows where your competitors are placing the keyword on their website.")])])],1),_c('v-data-table',{staticClass:"elevation-0 comp-table",attrs:{"id":"btptable","fixed-header":"","headers":_vm.headers,"items":_vm.termplacements,"item-key":"id"},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header_entry,index){return {key:("header." + (header_entry.value)),fn:function(ref){
var header = ref.header;
return [_c('span',{key:index},[_c('span',{style:({ width: header.width })},[_c('v-tooltip',{attrs:{"max-width":"250","color":"primary","top":"","disabled":header.tooltip ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(header.text)+" "),(header.tooltip)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-help")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-help-circle-outline")])],1)]),_vm._v(" "+_vm._s(header.tooltip)+" ")],1)])],1)])]}}}),{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('tbody',_vm._l((items),function(item,itemIndex){return _c('tr',{key:itemIndex,class:item.selected ? 'highlighted' : ''},_vm._l((_vm.headers),function(header,headerIndex){return _c('td',{key:headerIndex,staticClass:"table_td"},[(header.value === 'keyword')?_c('div',{staticClass:"briefing_td_keyword"},[_vm._v(" "+_vm._s(item[header.value])+" ")]):_vm._e(),(header.value === 'tag')?_c('div',[_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.placementColors[item[header.value]]}},[_vm._v(" "+_vm._s(item[header.value]))])],1):_vm._e()])}),0)}),0):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }