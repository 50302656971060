<template>
    <div id="contentcontainter" class="standard-mode">
        <div v-if="loading && !researches.length">
            <div class="loaderwrapper">
                <center class="circularprogress">
                    <img id="loader" src="../assets/quaro-q.svg" />
                </center>
            </div>
        </div>
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="showInfo">
            <v-icon>{{ info.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <!-- TOUR -->
        <v-tour name="collectionsTour" :steps="tour_steps"></v-tour>

        <v-dialog light v-model="showResearchDialog" width="500px" height="600px">
            <v-card class="chooseMainCard">
                <v-row class="pt-5">
                    <div class="chooseCard mx-auto my-auto" @click="$router.push('/ideas')">
                        <!-- <div class="avat"><v-icon></v-icon></div> -->
                        <v-row>
                            <v-col>
                                <img src="../assets/ideas-illustration.svg" width="150px" class="illustration" alt="" />
                            </v-col>
                            <v-col>
                                <h3 class="mt-5">Ideas</h3>
                                <p class="mr-5 mt-2">Use Ideas to get inspiration and to identify search trends.</p>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
                <v-row class="py-5">
                    <div class="chooseCard mx-auto my-auto" @click="$router.push('/suggests')">
                        <!-- <div class="avat"><v-icon></v-icon></div> -->
                        <v-row>
                            <v-col>
                                <img src="../assets/suggests-illustrations.svg" width="150px" class="illustration" alt="" />
                            </v-col>
                            <v-col>
                                <h3 class="mt-5">Suggests</h3>
                                <p class="mr-5 mt-2">Use Suggests to identify what people are searching for on YouTube, Instagram or Amazon</p>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-card>
        </v-dialog>

        <!-- change name dialog -->
        <v-dialog v-model="changeNameDialog" max-width="450px" id="downloaddialog">
            <v-card>
                <center>
                    <strong id="title" class="mb-5 d-inline-block">Rename Collection</strong>
                    <br />
                </center>

                <v-card-text class="pb-0">
                    <v-text-field v-model="newname" label="Research name" clearable filled rounded></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-btn rounded class="mx-auto" dark color="primary" v-on:click="changeResearchName">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- SHARE ITEMS -->
        <ShareDialog
            :shareMenu="shareMenu"
            :shareItem="shareItem"
            @disableShareMenu="disableShareMenu"
            @renewData="fetchData"
            @createInfo="createInfo"
            @lowerSharedCount="lowerSharedCount"
            @upperSharedCount="upperSharedCount"
            @changeSharedItem="changeSharedItem"
            :mountedOn="{ url: 'research', name: 'Collection' }">
        </ShareDialog>

        <!-- ADD COLLECTION TO PROJECT DIALOG -->
        <AddToProjectDialog
            :availableProjects="availableProjects"
            :addToProjectDialog="addToProjectDialog"
            :selectedAddToProjectItem="selectedAddToProjectItem"
            :researches_old="researches_old"
            @renewData="fetchData"
            @closeAddToProjectDialog="closeAddToProjectDialog">
        </AddToProjectDialog>

        <v-container id="allresearches" fluid tag="section" :class="user_settings.advancedMode ? 'content-margin' : ''">
            <!-- <welcome-card class="mb-10"></welcome-card> -->

            <v-row>
                <!--  <v-col cols="12" sm="12" md="6" xl="6" v-if="researches.length > 0">
                    <v-card class="px-5 py-4 rounded_card">
                        <v-row>
                            <v-sheet color="primary" max-height="50" class="pa-12 quaro_gradient rounded_card" dark top id="searchsheet" elevation="6">
                                <div class="headingsheet2">
                                    <v-icon id="sheeticon" :light="this.$vuetify.theme.dark ? true : false" large>mdi-magnify</v-icon>
                                </div>
                            </v-sheet>
                            <v-row class="searchrow">
                                <v-text-field
                                    label="Search for keywords in all collections"
                                    append-outer-icon="mdi-magnify"
                                    :error-messages="keywordErrors"
                                    v-model="keyword"
                                    class="longcol"
                                    @input="$v.keyword.$touch()"
                                    v-on:keyup.enter="searchKeywords"
                                    @click:append-outer="searchKeywords"
                                    clearable />
                            
                            </v-row>
                        </v-row>
                    </v-card>
                </v-col> -->

                <v-col sm="12" md="6" xl="4" class="mb-2">
                    <v-slide-x-transition>
                        <v-card class="px-5 py-3 rounded_card quaroshadow" id="kwcountCard" v-if="researches.length">
                            <v-row no-gutters>
                                <v-sheet color="primary" max-height="50" class="pa-12 rounded_card" dark top id="searchsheet" elevation="6">
                                    <div class="headingsheet2">
                                        <v-icon id="sheeticon" :light="this.$vuetify.theme.dark ? true : false" large>mdi-database</v-icon>
                                    </div>
                                </v-sheet>
                                <div class="ml-auto text-right">
                                    <div class="body-3 grey--text font-weight-light">Saved keywords</div>
                                    <div>{{ collectionCount }}</div>
                                </div>
                            </v-row>
                            <v-col cols="12" class="px-0 pb-1">
                                <v-divider />
                            </v-col>
                            <v-icon size="16" class="ml-2 mr-1"> mdi-clock </v-icon>
                            <span class="caption grey--text font-weight-light"> Updated on {{ updatedDate }}</span>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>
            </v-row>
            <!-- CollectionBanner -->
            <!-- PROJECTS BANNER -->
            <v-slide-x-transition>
                <ProjectsTable
                    v-if="researches.length"
                    :loading="loading"
                    :researches="researches"
                    :projects="projects"
                    @renewData="fetchData"
                    @removeCollection="removeCollectionFromTable"
                    @openShareMenu="openShareMenu"
                    @initChangeNameDialog="initChangeNameDialog">
                </ProjectsTable>
            </v-slide-x-transition>
            <v-slide-x-transition>
                <v-card v-if="!loading" class="px-5 py-3 rounded_card quaroshadow">
                    <v-row no-gutters>
                        <v-sheet color="primary" max-height="200" class="pa-12 rounded_card" dark top id="tablesheet" elevation="6">
                            <div class="headingsheet2">
                                <v-icon :light="this.$vuetify.theme.dark ? true : false" id="sheeticon" large>mdi-file-table</v-icon>
                            </div>
                        </v-sheet>
                        <v-card-title id="heading3"> All saved Collections </v-card-title>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="researchSearch"
                            rounded
                            dense
                            style="max-width: 400px"
                            filled
                            append-icon="mdi-magnify"
                            label="Search for collection"
                            single-line
                            hide-details
                            id="kwsearch"></v-text-field>
                    </v-row>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn absolute right fab dark small @click="showResearchDialog = true" color="primary" class="fabicon" v-bind="attrs" v-on="on">
                                <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>create a research</span>
                    </v-tooltip>
                    <v-card-text class="mt-5 researchTable px-0">
                        <CollectionBanner v-if="researches.length == 0 && !loading" @createBtnClick="startTour"></CollectionBanner>

                        <v-data-table
                            v-else
                            id="researchtable"
                            class="row-pointer"
                            :headers="researchHeaders"
                            :items="researches"
                            :loading="loading"
                            :search.sync="researchSearch"
                            :sort-by.sync="table_sort_by"
                            :sort-desc.sync="table_sort_desc"
                            :items-per-page.sync="table_items_per_page"
                            :custom-sort="customSort">
                            <!-- header slot -->
                            <template v-for="(header_entry, index) in researchHeaders" v-slot:[`header.${header_entry.value}`]="{ header }">
                                <span :key="index">
                                    <span v-bind:style="{ width: header.width }">
                                        <!-- Adding tooltips to header -->
                                        <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on" class="quarotblheader">
                                                    {{ header.text }}
                                                    <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                                </span>
                                            </template>
                                            <span>
                                                <v-row no-gutters>
                                                    <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                                </v-row>
                                                {{ header.tooltip }}
                                            </span>
                                        </v-tooltip>
                                    </span>
                                </span>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr v-for="(item, index) in items" :key="item.listname" id="research_row">
                                        <td @click="onColumnClick(item)" class="py-3">
                                            <div style="display: flex; flex-direction: row">
                                                <div style="white-space: nowrap" class="mr-5">
                                                    <div>
                                                        <span class="listnameColumn">{{ item.listname }}</span>
                                                    </div>
                                                    <div class="listnamesub">
                                                        <v-row no-gutters>
                                                            <v-col key="ma-auto" style="display: flex">
                                                                <v-icon x-small class="mr-1 my-auto">mdi-clock-outline</v-icon>
                                                                <span class="my-auto" style="padding-top: 1px"> Updated {{ $helpers.returnResearchDate(item.updated) }}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>

                                                <div class="ml-auto" style="display: flex">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <div class="savedicon_wrapper" v-on="on" @click="openshare(item)">
                                                                <v-icon x-small :color="item.shared_extern ? 'primary' : 'white'" class="savedicon">mdi-share-variant</v-icon>
                                                            </div>
                                                        </template>
                                                        <span> {{ item.shared_extern ? "shared via guest link" : "not shared" }} </span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </td>
                                        <td @click="onColumnClick(item)">
                                            <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                <v-chip outlined color="primary" :style="'background-color: rgb(237 239 241 /' + ($vuetify.theme.light == false ? '35%)' : '10%)') + '!important'">
                                                    <v-icon class="mr-1" color="grey" size="14px">mdi-key</v-icon>
                                                    <span class="researchKwCount"> {{ $helpers.formatNumber(item.count) }}</span>
                                                </v-chip>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </div>
                                        </td>
                                        <td @click="onColumnClick(item)">
                                            <div class="spark" v-if="item.status !== 'creating' && item.status != 'updating'">
                                                <!-- check if new format -  introduced in march 2024 -->
                                                <v-row no-gutters>
                                                    <v-col class="d-flex">
                                                        <v-sparkline
                                                            class="my-auto"
                                                            style="max-width: 110px; max-height: 40px"
                                                            id="sparkline"
                                                            color="primary"
                                                            :value="item.trendData.data"
                                                            padding="2"
                                                            smooth="20"
                                                            gradient-direction="top"
                                                            auto-draw
                                                            :fill="false"></v-sparkline>
                                                        <v-sparkline
                                                            class="my-auto"
                                                            style="max-width: 110px; max-height: 40px; position: absolute"
                                                            id="sparkline"
                                                            color="primary"
                                                            :value="item.trendData.data"
                                                            :gradient="gradient"
                                                            padding="2"
                                                            smooth="20"
                                                            gradient-direction="top"
                                                            auto-draw
                                                            :fill="true"></v-sparkline>
                                                        <v-row outlined small class="qchip my-auto pl-3" v-if="item.trendData.change">
                                                            <span :style="'color: ' + item.trendData.icon_color" class="my-auto ml-2">{{ Math.abs(item.trendData.change) + "%" }} </span>

                                                            <v-icon size="16px" :color="item.trendData.icon_color">{{ item.trendData.icon }}</v-icon>
                                                        </v-row>
                                                        <v-row outlined small class="qchip my-auto pl-3" v-else>
                                                            <span :style="'color: grey'" class="my-auto ml-2">{{ Math.abs(item.trendData.change) + "%" }} </span>
                                                        </v-row>
                                                    </v-col>
                                                    <!-- <v-col style="max-width: 65px" class="d-flex">
                                                        <span outlined :color="item.trendData.icon_color" small class="qchip my-auto pl-1" v-if="item.trendData.change">
                                                            <v-icon>{{ item.trendData.icon }}</v-icon>
                                                            <span class="my-auto">{{ item.trendData.change }} </span>
                                                        </span>
                                                    </v-col> -->
                                                </v-row>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </div>
                                        </td>

                                        <td @click="onColumnClick(item)">
                                            <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                <v-row>
                                                    <v-col class="ma-auto" style="display: flex">
                                                        <v-icon class="my-auto mr-1" small>mdi-map-marker</v-icon>
                                                        <!-- <img :src="`https://flagsapi.com/${item.cc}/shiny/24.png`" alt="Flag" /> -->
                                                        {{ item.country }}
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </div>
                                        </td>
                                        <!-- <td @click="onColumnClick(item)">
                                            <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                <v-row>
                                                    <v-col class="ma-auto" style="display: flex">
                                                        <v-icon class="my-auto mr-1" small>mdi-language</v-icon>
                                                        {{ item.language }}</v-col
                                                    >
                                                </v-row>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </div>
                                        </td> -->

                                        <td @click="onColumnClick(item)">
                                            <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                <!-- <span v-if="item.created">{{ $helpers.returnResearchDate(item.created) }}</span> -->

                                                <v-row v-if="item.created">
                                                    <v-col class="ma-auto" style="display: flex">
                                                        <v-icon class="my-auto mr-1" small>mdi-clock-time-eight</v-icon>
                                                        {{ $helpers.returnResearchDate(item.created) }}</v-col
                                                    >
                                                </v-row>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </div>
                                        </td>
                                        <!-- <td @click="onColumnClick(item)">
                                        <div v-if="item.status != 'creating' && item.status != 'updating'">
                                            <v-chip>{{ returnDate(item.updated) }} </v-chip>
                                        </div>
                                        <div v-else>
                                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                        </div>
                                    </td> -->
                                        <td @click="onColumnClick(item)">
                                            <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar v-on="on" v-if="item.owner_name" color="primary" size="30" class="pr-0 avat">
                                                            <span class="white--text">{{ $helpers.getInitials(item.owner_name) }}</span>
                                                        </v-avatar>
                                                    </template>
                                                    <span>{{ item.owner_name }}</span>
                                                </v-tooltip>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                <v-chip
                                                    v-if="item.project"
                                                    color="primary"
                                                    outlined
                                                    :style="'background-color: rgb(237 239 241 /' + ($vuetify.theme.light == false ? '35%)' : '10%)') + '!important'"
                                                    @click="$helpers.isActionAllowed(30, user_rights) ? initAddToProjectDialog(item, index) : null">
                                                    <v-icon small style="padding-bottom: 2px" class="mr-1">mdi-folder-table</v-icon>
                                                    {{ item.project }}
                                                </v-chip>
                                                <v-chip
                                                    v-else
                                                    color="grey"
                                                    outlined
                                                    :style="'background-color: rgb(237 239 241 /' + ($vuetify.theme.light == false ? '35%)' : '10%)') + '!important'"
                                                    @click="$helpers.isActionAllowed(30, user_rights) ? initAddToProjectDialog(item, index) : null">
                                                    <v-icon class="mr-1" small>mdi-plus-circle-outline</v-icon> add to project
                                                </v-chip>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </div>
                                        </td>
                                        <!-- ------- -->
                                        <!-- Actions -->
                                        <!-- ------- -->

                                        <td>
                                            <v-menu rounded="lg" color="primary" transition="slide-y-transition" bottom v-if="item.status != 'creating' && item.status != 'updating'">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        class="ml-auto"
                                                        v-if="
                                                            $helpers.isActionAllowed(2, user_rights) ||
                                                            $helpers.isActionAllowed(3, user_rights) ||
                                                            $helpers.isActionAllowed(4, user_rights) ||
                                                            (decryptedId == item.owner && $helpers.isActionAllowed(28, user_rights)) ||
                                                            $helpers.isActionAllowed(34, user_rights)
                                                        "
                                                        id="cogbtn"
                                                        icon
                                                        v-on="on">
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list rounded dense>
                                                    <v-list-item v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(4, user_rights)" @click="openShareMenu(item)">
                                                        <v-icon small class="mr-2">mdi-share-variant</v-icon><v-list-item-title>Share</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(2, user_rights)"
                                                        @click="initChangeNameDialog(item)">
                                                        <v-icon small class="mr-2">mdi-square-edit-outline</v-icon><v-list-item-title>Rename</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(34, user_rights)"
                                                        @click="initAddToProjectDialog(item, index)">
                                                        <v-icon small class="mr-2">mdi-folder-plus-outline</v-icon><v-list-item-title>Add to Project</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-if="
                                                            item.status != 'creating' &&
                                                            item.status != 'updating' &&
                                                            ($helpers.isActionAllowed(3, user_rights) || (decryptedId == item.owner && $helpers.isActionAllowed(28, user_rights)))
                                                        "
                                                        @click="prepareDeleteItem(item)">
                                                        <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>Delete</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-slide-x-transition>
            <!-- delete dialog -->
            <v-dialog v-model="deleteQuestion" max-width="400">
                <v-card>
                    <v-card-title style="text-align: center">
                        <span class="mr-2"> Delete Research:</span>
                        <span class="no-line-break"
                            >"<strong>{{ selectedDeleteItem.listname }}</strong
                            >" ?</span
                        >
                    </v-card-title>
                    <v-card-actions class="px-auto">
                        <v-btn class="mr-3" text @click="deleteQuestion = false"> Nevermind </v-btn>
                        <v-btn class="ml-auto" color="error" @click="deleteItem(selectedDeleteItem)"> Delete </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- delete alert -->
            <v-snackbar v-model="deleteSuccess" color="#16C96D" type="success" top>
                <v-icon size="25">mdi-check</v-icon><span class="font-weight-bold pl-2">The selected research has been deleted!</span>
            </v-snackbar>
            <!-- Graphlimit Info -->
            <v-snackbar top color="#fcba03" v-model="noSearchResult">
                <v-icon>mdi-alert-circle-outline</v-icon>
                <span class="font-weight-bold pl-2">No keyword found. Please use Keywordplanner or Suggest to get data for this keyword.</span>
            </v-snackbar>
        </v-container>
    </div>
</template>

<script>
    import { validationMixin } from "vuelidate"
    import { required, maxLength, alphaNum } from "vuelidate/lib/validators"
    // import WelcomeCard from '../components/WelcomeCard.vue';
    import ShareDialog from "../components/dialogs/shareDialog/Sharedialog"
    import ProjectsTable from "../components/projects/ProjectsTable"
    import AddToProjectDialog from "../components/projects/AddToProjectDialog"
    import CollectionBanner from "../components/CreateCollectionBanner"
    import { parse } from "filepond"
    export default {
        name: "AllResearches",
        mixins: [validationMixin],
        validations: {
            keyword: { required, maxLength: maxLength(30) }
        },
        components: {
            // WelcomeCard,
            ShareDialog,
            ProjectsTable,
            CollectionBanner,
            AddToProjectDialog
        },
        data: () => ({
            tour_steps: [
                {
                    target: "#research_row", // We're using document.querySelector() under the hood
                    header: {
                        title: "Congratulations"
                    },
                    content: `You have created your first collection. You can manage and edit all collections here.  `,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve("foo")
                            }, 1000)
                            // router push collections page
                        })
                }
            ],
            loading: true,
            table_sort_desc: [true],
            table_sort_by: ["count"],
            table_items_per_page: 10,
            newname: null,
            changeNameDialog: false,
            user: [],
            selectedUser: [],
            showResearchDialog: false,
            items: [{ title: "Click Me" }, { title: "Click Me" }, { title: "Click Me" }, { title: "Click Me 2" }],
            updatedDate: "",
            // selectedLanguage:'',
            // languages: [],
            researchHeaders: [
                {
                    text: "Research",
                    value: "listname"
                },
                {
                    text: "Keywords",
                    value: "count"
                },
                {
                    text: "Trend (Google SV)",
                    value: "trendData",
                    width: "200px",
                    algin: "center",
                    sortable: true
                },

                {
                    text: "Location",
                    value: "country"
                },
                // {
                //     text: "Language",
                //     value: "language"
                // },

                {
                    text: "Created on",
                    value: "created",
                    sortable: true
                },
                // {
                //     text: "Updated on",
                //     value: "updated",
                //     sortable: false
                // },
                {
                    text: "Created by",
                    value: "owner_name"
                },
                {
                    text: "Project",
                    value: "project"
                },
                {
                    sortable: false,
                    text: " ",
                    value: "action",
                    align: "end",
                    width: 50
                }
            ],
            brandindizes: [],
            researches: [],
            researchSearch: undefined,
            draftSearch: undefined,
            collectionCount: undefined,
            collectionSize: undefined,
            deleteQuestion: false,
            selectedDeleteItem: {},
            deleteSuccess: false,
            noSearchResult: false,
            keyword: "",
            info: {
                message: null,
                icon: null,
                color: null
            },
            showInfo: false,
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),

            shareMenu: false,
            shareItem: {},
            availableProjects: [],
            addToProjectDialog: false,
            selectedAddToProjectItem: {},
            projects: [],
            researches_old: []
        }),
        computed: {
            gradient() {
                return [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3]
            },
            keywordErrors() {
                const errors = []
                if (!this.$v.keyword.$dirty) return errors
                !this.$v.keyword.maxLength && errors.push("Keyword must be at most 30 characters long.")
                !this.$v.keyword.required && errors.push("This field is required.")

                return errors
            },
            user_rights() {
                return this.$store.state.user_rights
            },
            decryptedId() {
                if (localStorage.id) {
                    return this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            user_settings() {
                return this.$store.state.user_settings
            }
            // companyUserSecelctionIcon(){
            //   if (this.companyUser.length) return 'mdi-close-box'
            //   if (this.someDistricts) return 'mdi-minus-box'
            //   return 'mdi-checkbox-blank-outline'
            // }
        },
        watch: {
            table_sort_desc() {
                console.log(this.table_sort_desc)
                this.user_settings.collections_collection_sort.sort = this.table_sort_desc
                this.user_settings.collections_collection_sort.column = this.table_sort_by

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            table_sort_by() {
                console.log(this.table_sort_by)
                // this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            table_items_per_page() {
                console.log(this.table_items_per_page)
                this.user_settings.collections_collection_rowsperpage = this.table_items_per_page

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            }
        },
        methods: {
            customSort(items, index, isDesc) {
                if (!index[0]) return items
                items.sort((a, b) => {
                    if (index[0] == "project" || index[0] == "owner_name") {
                        if (!isDesc[0]) {
                            if (a[index[0]] && b[index[0]]) return a[index[0]] < b[index[0]] ? -1 : 1
                            if (!a[index[0]]) return -1
                            if (!b[index[0]]) return 1
                        } else {
                            if (a[index[0]] && b[index[0]]) return b[index[0]] < a[index[0]] ? -1 : 1
                            if (!a[index[0]]) return 1
                            if (!b[index[0]]) return -1
                        }
                    } else if (index[0] == "created") {
                        let amonth = a[index[0]].split("/")[0]
                        if (parseInt(amonth) < 10) amonth = "0" + amonth.toString()
                        let bmonth = b[index[0]].split("/")[0]
                        if (parseInt(bmonth) < 10) bmonth = "0" + bmonth.toString()
                        let ayear = a[index[0]].split("/")[1]
                        let byear = b[index[0]].split("/")[1]
                        let avalue = parseInt(ayear + amonth)
                        let bvalue = parseInt(byear + bmonth)
                        if (!isDesc[0]) {
                            return avalue < bvalue ? -1 : 1
                        } else {
                            return bvalue < avalue ? -1 : 1
                        }
                    } else if (index[0] == "trendData") {
                        if (!a[index[0]].change) a[index[0]].change = 0
                        if (!b[index[0]].change) b[index[0]].change = 0
                        if (!isDesc[0]) {
                            return Number(a[index[0]].change) - Number(b[index[0]].change)
                        } else {
                            return Number(b[index[0]].change) - Number(a[index[0]].change)
                        }
                    } else {
                        //  standard columns
                        if (!isNaN(a[index[0]])) {
                            // String
                            if (!isDesc[0]) {
                                return a[index[0]] - b[index[0]]
                            } else {
                                return b[index[0]] - a[index[0]]
                            }
                        } else {
                            // Number
                            if (!isDesc[0]) {
                                return a[index[0]] < b[index[0]] ? -1 : 1
                            } else {
                                return b[index[0]] < a[index[0]] ? -1 : 1
                            }
                        }
                    }
                })
                return items
            },
            openshare(item) {
                event.stopPropagation()
                this.openShareMenu(item)
            },
            changeSharedItem(item) {
                let index = this.researches.findIndex((x) => x._id == item.id)
                console.log(index)
                if (index > -1) this.researches[index].shared_extern = item.status
            },
            startTour() {
                this.$router.push("/ideas?tour=ideas")
            },
            removeCollectionFromTable(list_id, project_name) {
                console.log(this.projects)

                let indexOfProject = this.projects.findIndex((x) => x.project === project_name)
                let indexOfCollection = this.projects[indexOfProject].lists.indexOf(list_id)
                this.projects[indexOfProject].lists.splice(indexOfCollection, 1)

                //remove project from saved collections
                let result = this.researches.findIndex((y) => y._id == list_id)
                console.log(result)
                this.researches[result].project = null
                this.researches[result].project_owner = null

                if (this.researches[result].shared_extern) this.projects[indexOfProject].shared_count--
            },
            lowerSharedCount(list_id) {
                console.log("lower")
                console.log(list_id)
                let indexOfProject = this.projects.findIndex((x) => x.lists.indexOf(list_id) > -1)
                if (indexOfProject > -1) this.projects[indexOfProject].shared_count--
            },
            upperSharedCount(list_id) {
                console.log("upper")
                console.log(list_id)
                let indexOfProject = this.projects.findIndex((x) => x.lists.indexOf(list_id) > -1)
                if (indexOfProject > -1) this.projects[indexOfProject].shared_count++
            },
            initAddToProjectDialog(item, index) {
                item.index = index
                this.selectedAddToProjectItem = JSON.parse(JSON.stringify(item))
                this.addToProjectDialog = true
            },
            closeAddToProjectDialog() {
                this.addToProjectDialog = false
            },
            // addListToProject(list_id, project, index){
            //     if(this.researches_old[index].project){
            //         if(this.researches_old[index].project !== project){
            //             this.$http
            //             .post(process.env.VUE_APP_APIURL + "/projects/delete/list", { list_id: list_id, project: this.researches_old[index].project, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
            //             .then(function (response) {
            //                 // console.log(response)
            //                 if(project == undefined)this.fetchData()
            //             })
            //         }
            //     }
            //     if(project !== undefined){
            //         this.$http
            //         .post(process.env.VUE_APP_APIURL + "/projects/list/add", { list_id: list_id, project: project, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
            //         .then(function (response) {
            //             // console.log(response)
            //             this.fetchData()
            //         })
            //     }
            // },
            disableShareMenu() {
                this.shareMenu = false
            },
            initChangeNameDialog(item) {
                this.researchToUpdate = item.listname
                this.newname = item.listname
                this.changeNameDialog = true
            },
            async changeResearchName() {
                //if newname == oldname show error
                if (/\s$/.test(this.newname)) {
                    this.newname = this.newname.replace(/^[ ]+|[ ]+$/g, "")
                }
                if (this.newname == this.researchToUpdate) {
                    this.createInfo("The new name is similar to the old name. Please provide a new name.", "#fcba03", "mdi-alert-circle-outline")
                } else {
                    var checkIfExists = await this.checkIfExists()
                    if (checkIfExists) {
                        // check if name has no / & %
                        var validString = true
                        if (this.newname.indexOf("%") > -1) validString = false
                        if (this.newname.indexOf("/") > -1) validString = false
                        if (this.newname.indexOf("&") > -1) validString = false
                        if (validString && this.newname.length > 3) {
                            // //console.log("valid")
                            let data = {
                                listname: this.researchToUpdate,
                                newname: this.newname,
                                company_id: localStorage.company_id
                            }
                            console.log(data)
                            this.$http
                                .post(process.env.VUE_APP_APIURL + "/research/rename", data, {
                                    headers: { Authorization: this.auth }
                                })
                                .then(function (response) {
                                    console.log(response.body)
                                    this.fetchData()
                                    this.createInfo(response.body.message, this.$vuetify.theme.themes.light.primary, "mdi-success")
                                    this.changeNameDialog = false
                                })
                        } else {
                            this.createInfo("Please use a proper name. Minimum 4 characters and none of [%, /, &].", "#fcba03", "mdi-alert-circle-outline")
                        }
                    }
                }
            },
            checkIfExists: function () {
                return new Promise((resolve, reject) => {
                    if (this.newname != "") {
                        this.$http
                            .post(process.env.VUE_APP_APIURL + "/research/namecheck", { name: this.newname, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                            .then(function (response) {
                                if (response.body.error) {
                                    this.createInfo("That name already exists, please try something else!", "#fcba03", "mdi-alert-circle-outline")
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                    }
                })
            },
            openShareMenu(item) {
                this.shareItem = item
                console.log(item)
                this.shareMenu = true
                console.log("Sharemenu")
            },

            fetchCountryFlag(country) {
                this.$http.get("https://restcountries.eu/rest/v2/name/" + country).then(function (response) {
                    //console.log('https://www.countryflags.io/' + response.body[0].alpha2Code  + '/shiny/64.png')
                    let imgsrc = "https://www.countryflags.io/" + response.body[0].alpha2Code + "/shiny/64.png"
                    return imgsrc
                })
            },

            setDate() {
                var today = new Date()
                var dd = String(today.getDate()).padStart(2, "0")
                var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
                var yyyy = today.getFullYear()

                this.updatedDate = dd + "." + mm + "." + yyyy
            },
            prepareDeleteItem(props) {
                try {
                    let deleteitem = JSON.stringify(props)
                    this.$matomo.trackEvent("AllResearches", "prepareDeleteItem", "Item: " + deleteitem, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }

                this.deleteQuestion = true
                this.selectedDeleteItem = props
            },
            deleteItem(props) {
                try {
                    let deleteitem = JSON.stringify(props)
                    this.$matomo.trackEvent("AllResearches", "deleteItem", "Item: " + deleteitem, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/research/delete", { listname: props.listname, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        if (props.project) {
                            this.$http
                                .post(
                                    process.env.VUE_APP_APIURL + "/projects/delete/list",
                                    {
                                        list_id: props._id,
                                        project: props.project,
                                        company_id: localStorage.company_id
                                    },
                                    { headers: { Authorization: this.auth } }
                                )
                                .then(function (response) {
                                    // console.log(response)

                                    this.removeCollectionFromTable(props._id, props.project)
                                    this.researches = this.$helpers.removeElement(props.listname, this.researches, "listname")
                                })
                        } else {
                            this.researches = this.$helpers.removeElement(props.listname, this.researches, "listname")
                        }

                        this.fetchStats()
                        this.deleteQuestion = false
                        this.deleteSuccess = true
                    })
            },
            searchKeywords() {
                try {
                    this.$matomo.trackEvent("AllResearches", "searchKeywords", "Word: " + this.keyword, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                this.$v.$touch()
                if (this.$v.$invalid) {
                    try {
                        this.$matomo.trackEvent("AllResearches", "searchKeywords - invalid", "Word: " + this.keyword, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                } else {
                    try {
                        this.$matomo.trackEvent("AllResearches", "searchKeywords - valid", "Word: " + this.keyword, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    this.$http
                        .post(
                            process.env.VUE_APP_APIURL + "/research/findkeywords",
                            {
                                kw: this.keyword,
                                limit: 0,
                                svminimum: 0,
                                nodata: true,
                                company_id: localStorage.company_id
                            },
                            { headers: { Authorization: this.auth } }
                        )
                        .then(function (response) {
                            //console.log(response);
                            if (response.body.count > 0) {
                                this.$router.push({
                                    path: "/research/0" + "?findKeyword=" + this.keyword
                                })
                            } else {
                                this.noSearchResult = true
                            }
                        })
                }
            },

            onColumnClick(item) {
                event.stopPropagation()
                if (item.status == "creating") {
                    this.createInfo("This collection is currently being created", "#fcba03", "mdi-alert-circle-outline")
                } else if (item.status == "updating") {
                    this.createInfo("This collection is currently being updated", "#fcba03", "mdi-alert-circle-outline")
                } else {
                    this.$router.push({ path: "/research/" + item.listname })
                }
            },
            createInfo(message, color, icon) {
                console.log("createinfo")
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },

            // onBrandRowClick(params) {
            //   this.$router.push({ path: '/brandindex/' + params.row.research + '&700' + '&200'}) //Filter entfernen da indizes eh klein?
            // },
            fetchStats() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/research/stats", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        let stats = {}
                        this.collectionCount = response.body.stats.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        this.collectionSize = (response.body.stats.storageSize / 1014).toFixed(2) + " MB"
                    })
                    .catch((err) => {
                        console.log(err)
                        this.collectionCount = 0
                    })
            },
            getTrendData(data) {
                //TODO: the if can be removed after one update of sv - everey colection will have data[0].volume
                // <!-- check if new format -  introduced in march 2024 -->
                if (data && data[0] && data[0].volume) {
                    let trendData = JSON.parse(JSON.stringify(data))
                        .slice(0, 12)
                        .reverse()
                        .map((e) => e.volume)
                    let latest = trendData[trendData.length - 1]
                    let previous = trendData[trendData.length - 2]
                    let change = (latest - previous) / previous
                    let color = change < 0 ? "#c10000" : "#008000"
                    console.log()
                    color += this.$vuetify.theme.dark == false ? "91" : "" // adding transparency for dark mode
                    return {
                        data: trendData,
                        icon: change < 0 ? "mdi-chevron-down-circle-outline" : "mdi-chevron-up-circle-outline",
                        icon_color: color,
                        change: Math.ceil(change * 100, 2)
                    }
                }
                return { data: data }
            },
            getCreatedDate(dateInput) {
                let date = new Date(dateInput)
                return date.getMonth() + 1 + "/" + date.getFullYear()
            },
            fetchData() {
                this.loading = true
                let urls = [
                    this.$http.post(process.env.VUE_APP_APIURL + "/research/list", { type: "research", company_id: localStorage.company_id }, { headers: { Authorization: this.auth } }),
                    this.$http.post(process.env.VUE_APP_APIURL + "/projects/get", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                ]
                Promise.all(urls)
                    .then((data) => {
                        console.log(data)
                        this.researches = []
                        let fetchedResearches = []
                        for (let index = 0; index < data[0].body.length; index++) {
                            let research = {}
                            research.listname = data[0].body[index].listname
                            research._id = data[0].body[index]._id
                            research.count = data[0].body[index].count
                            research.updated = data[0].body[index].mdata.updated
                            research.country = data[0].body[index].mdata.langloc_new.loc
                            research.language = data[0].body[index].mdata.langloc_new.ads_lang_code
                            research.cc = data[0].body[index].mdata.langloc_new.cc ? data[0].body[index].mdata.langloc_new.cc.toUpperCase() : null
                            research.trendData = this.getTrendData(data[0].body[index].trendData)
                            research.status = data[0].body[index].mdata.status
                            research.owner = data[0].body[index].owner
                            research.owner_name = data[0].body[index].owner_name
                            research.created = this.getCreatedDate(data[0].body[index].mdata.created)
                            research.comments = data[0].body[index].comments ? data[0].body[index].comments : []
                            research.shared_extern = data[0].body[index].mdata.shared_extern
                            fetchedResearches.push(research)
                        }

                        this.availableProjects = []
                        for (let index = 0; index < data[1].body.projects.length; index++) {
                            this.availableProjects.push(data[1].body.projects[index].project)
                            data[1].body.projects[index].lists.map((x) => {
                                // console.log(x)
                                // console.log(fetchedResearches)
                                let result = fetchedResearches.findIndex((y) => y._id == x)
                                // console.log(result)
                                if (result != -1) {
                                    fetchedResearches[result].project = data[1].body.projects[index].project
                                    fetchedResearches[result].project_owner = data[1].body.projects[index].owner
                                }
                            })
                        }

                        this.researches = fetchedResearches
                        console.log(this.researches)
                        this.projects = data[1].body.projects.sort()
                        for (let index = 0; index < this.projects.length; index++) {
                            let counts = this.calcCountsOfProjectLists(this.projects[index].lists)
                            this.projects[index].comments_count = counts.comments_count
                            this.projects[index].shared_count = counts.shared_count
                            this.projects[index].updated_text = this.$helpers.getDateDiffText(this.projects[index].mdata.updated)
                        }
                        console.log(this.projects)
                        this.researches_old = JSON.parse(JSON.stringify(fetchedResearches))
                        this.loading = false
                    })
                    .catch((err) => {
                        console.log(err)
                        this.loading = false
                        console.log("hi")
                    })
            },
            calcCountsOfProjectLists(research_ids) {
                let project_researches = this.researches.filter((research) => research_ids.indexOf(research._id) > -1)
                let comments_count = 0
                let shared_count = 0
                for (let index = 0; index < project_researches.length; index++) {
                    comments_count += project_researches[index].comments.length
                    shared_count += project_researches[index].shared_extern ? 1 : 0
                }
                return { comments_count: comments_count, shared_count: shared_count }
            }
        },
        beforeRouteLeave(to, from, next) {
            next()
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.setDate()
                vm.fetchStats()
                vm.fetchData()

                // if (vm.keywords.length) {
                //     vm.unfilteredKeywords = [...vm.keywords]
                //     vm.keywords = vm.$helpers.filterKeywordTable(vm.unfilteredKeywords)
                // }
                // vm.checkIfSendTo()

                vm.$router.onReady(() => {
                    if (vm.$route.query.tour == "ideas") {
                        console.log("ideas-tour detected")
                        vm.$tours["collectionsTour"].start()
                    }
                })
                //test end check
                if (vm.$store.state.company_status === "test_ended") {
                    vm.$router.push("/endoftest")
                }
            })
        },
        created: function () {},
        mounted: function () {
            this.$socket.on("reloadResearches", (data) => {
                console.log("Reloading Researches")
                this.deleteQuestion = false
                this.changeNameDialog = false
                this.fetchData()
            })
            this.table_sort_desc = this.user_settings.collections_collection_sort.sort
            this.table_sort_by = this.user_settings.collections_collection_sort.column
            this.table_items_per_page = this.user_settings.collections_collection_rowsperpage
        }
    }
</script>
<style lang="css" scoped>
    .illustration {
        margin-top: 7% !important;
        margin-left: 15%;
    }
    .chooseMainCard {
        background-color: rgb(243, 243, 243);
        overflow-x: hidden;
    }
    .avat {
        width: 100px;
        height: 100px;
        background-color: var(--v-primary-base);
        border-radius: 50%;
        margin-left: 5%;
        background: linear-gradient(56deg, var(--v-left_nav_gradient1-base) 0%, var(--v-left_nav_gradient3-base) 100%);
        font-size: 0.9em;
    }
    .chooseCard {
        width: 450px;
        height: 200px;
        background-color: white;
        border-radius: 5px;
        cursor: pointer;
    }
    .chooseCard:hover {
        border: 2px solid var(--v-primary-base);
        box-shadow: 6px 4px 4px #cecece;
    }
    #sparkline {
        max-width: 140px;
    }
    #kwsearch {
        margin-right: 20px !important;
    }
    #heading3 {
        font-weight: 300 !important;
        color: rgb(105, 105, 105);
    }
    .headingsheet {
        margin-top: -15px;
    }
    .headingsheet2 {
        margin-top: -15px;
    }
    #searchsheet {
        margin-top: -30px;
        margin-left: 5px;
        width: 30px;
        height: 30px;
    }
    #tablesheet {
        margin-left: 5px;
        margin-top: -30px;
        width: 60px;
        height: 60px;
    }
    .rounded-card {
        margin-top: 30px;
        /* padding-left: 0px !important;
    padding-right: 0px !important; */
    }
    #sheeticon {
        margin-left: -25px;
        margin-top: -25px;
        margin: auto !important;
        padding-bottom: 50% !important;
        width: 50% !important;
    }
    .shortcol {
        max-width: 20% !important;
    }
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }

    .v-card__title {
        padding-top: 0px;
    }
    #kwcountCard {
        padding-bottom: 10px !important;
    }
    .v-application .pa-12 {
        padding: 50px !important;
    }
    .searchrow {
        margin: 15px;
    }
    #allresearches {
        padding-top: 30px;
        margin-top: -10px;
    }

    .content-margin {
        /* margin-left: 3.5%;
        margin-right: 3.5%;
        padding-top: 50px; */
    }

    /* tr:hover #cogbtn{
      color:var(--v-darkgreen-base) !important;
    } */
    .theme--light .listnamesub {
        font-size: 0.8em;
        color: rgba(0, 0, 0, 0.6);
    }
    .theme--dark .listnamesub {
        font-size: 0.8em;
        color: rgba(255, 255, 255, 0.7);
    }
    .theme--dark .researchKwCount {
        color: white !important;
    }
    .researchKwCount {
        color: grey;
    }

    #researchtable {
        color: #4d5156;
    }
    .theme--dark#researchtable {
        color: white;
    }
    #researchtable th {
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: 500;
    }
    #researchtable table > tbody > tr > td:last-child {
        background: var(--v-keywordcol-base) !important;
        border-left: 2px solid var(--v-primary-base);
    }
    #researchtable table > tbody > tr > td:last-child,
    #researchtable table > thead > tr > th:last-child {
        position: sticky !important;
        position: -webkit-sticky !important;
        right: 0 !important;
    }
    .circularprogress {
        padding-top: 20vh;
    }
    .savedicon {
        margin: auto;
        padding-right: 2px;
        padding-top: 1px;
    }
    .savedicon_wrapper {
        display: flex;
        margin: auto;
        width: 25px;
        height: 25px;
        border-radius: 12.5px;
        background-color: var(--v-q_grey-base);
    }
    .savedicon_wrapper:hover {
        background-color: var(--v-q_grey_darken_1-base);
    }
</style>
