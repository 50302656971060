var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"ml-5",attrs:{"xl":"10","md":"12","sm":"12"}},[_c('div',{},[_c('div',{},[_c('h2',{staticClass:"pb-2",staticStyle:{"font-weight":"300"}},[_vm._v("Briefing Settings")])]),_c('div',{staticClass:"pa-5 pt-0"},[_c('v-checkbox',{attrs:{"label":"use default settings"},model:{value:(_vm.useDefault),callback:function ($$v) {_vm.useDefault=$$v},expression:"useDefault"}}),_c('v-row',{staticClass:"qgreycard"},[_c('v-col',[_c('div',{staticClass:"lbl pb-2 pb-2"},[_vm._v(" TARGETED LENGTH OF CONTENT "),_c('v-tooltip',{attrs:{"max-width":"450","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("This field allows you to specify the desired length of the content for your project. The default value is based on the average length of content produced by your competitors. It is important to consider the attention span of your target audience, the complexity of the topic, and the desired level of detail when choosing the length of your content. ")])])],1),_c('v-text-field',{attrs:{"disabled":_vm.useDefault,"rounded":"","filled":"","type":"num","hint":'avg. competitors content length: ' + Math.ceil(_vm.project.avgWordCount),"persistent-hint":""},on:{"focus":function($event){return _vm.showTipp('title')}},model:{value:(_vm.project.settings.word_count),callback:function ($$v) {_vm.$set(_vm.project.settings, "word_count", $$v)},expression:"project.settings.word_count"}})],1)],1),_c('v-row',{staticClass:"qgreycard"},[_c('v-col',[_c('div',{staticClass:"lbl pb-2 pb-2"},[_vm._v(" TARGETED TERM USAGE "),_c('v-tooltip',{attrs:{"max-width":"450","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Allows you to specify the desired frequency and distribution of keywords and phrases in your content. For example: 75% of your selected content phrases must be used at least as often as your competitors do on average. ")])])],1),_c('v-select',{attrs:{"disabled":_vm.useDefault,"hint":"Default: 75%","persistent-hint":"","items":_vm.termusages,"rounded":"","filled":"","item-text":"text","item-value":"value"},model:{value:(_vm.project.settings.term_usage),callback:function ($$v) {_vm.$set(_vm.project.settings, "term_usage", $$v)},expression:"project.settings.term_usage"}})],1)],1),_c('v-row',{staticClass:"qgreycard"},[_c('v-col',[_c('div',{staticClass:"lbl pb-2 pb-2"},[_vm._v(" TARGETED READABILITY "),_c('v-tooltip',{attrs:{"max-width":"450","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Allows you to specify the desired level of readability for your content based on the Flesh Reading Ease Index. The index measures how easy or difficult it is to read a particular piece of content. It is important to create content that is easy to read and understand, as this can increase user engagement and sharing.")])])],1),_c('v-select',{attrs:{"disabled":_vm.useDefault,"hint":"Default: normal","persistent-hint":"","items":_vm.readabilities,"rounded":"","filled":"","item-text":"text","return-object":""},model:{value:(_vm.project.settings.readability),callback:function ($$v) {_vm.$set(_vm.project.settings, "readability", $$v)},expression:"project.settings.readability"}})],1)],1),_c('v-row',{staticClass:"qgreycard"},[_c('v-col',[_c('div',{staticClass:"lbl pb-2 pb-2"},[_vm._v(" TARGETED IMAGE USAGE "),_c('v-tooltip',{attrs:{"max-width":"450","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Allows you to specify the desired frequency of images in your content. The default value is based on the average image usage of your competitors. Including relevant and high-quality images can enhance the visual appeal of your content and increase user engagement. ")])])],1),_c('v-text-field',{attrs:{"disabled":_vm.useDefault,"hint":'avg. competitors Image Count: ' + _vm.project.avgImgCount,"persistent-hint":"","rounded":"","type":"number","filled":""},model:{value:(_vm.project.settings.img_count),callback:function ($$v) {_vm.$set(_vm.project.settings, "img_count", $$v)},expression:"project.settings.img_count"}})],1)],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }