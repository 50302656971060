<template>
    <div>
        <v-progress-linear class="mb-2" height="6px" style="border-radius: 4px" v-show="loading" indeterminate color="primary"></v-progress-linear>
        <line-chart :dataChart="chartData" :countGraph2="renderChart" :options="graphOptions"></line-chart>
    </div>
</template>

<script>
    import LineChart from "../charts/LineChart"
    export default {
        components: {
            LineChart
        },
        props: {
            selectedCompetitors: Array,
            category_id: String,
            show: Boolean
        },
        data() {
            return {
                loading: false,
                title: "Visibility",
                subtitle: "test",
                renderChart: 0,
                chartData: {
                    labels: [],
                    datasets: []
                },
                graphOptions: {
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItems, data) {
                                return data.datasets[tooltipItems.datasetIndex].label + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toLocaleString()
                            }
                        }
                    },
                    legend: {
                        display: true,
                        labels: {
                            fontSize: 14
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                pointStyle: "rectRounded"
                            }
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: "Google Searches"
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: function (value, index, values) {
                                        return value.toLocaleString()
                                    }
                                }
                                // ticks: {
                                //     beginAtZero: true
                                // }
                            }
                        ],
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: "Months"
                                }
                                // ticks: {
                                //     beginAtZero: true
                                // }
                            }
                        ]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        },
        methods: {
            async fetchSearches() {
                try {
                    console.log(this.selectedCompetitors)
                    const competitorArray = this.selectedCompetitors.map((c) => c.domain)
                    // Fetch visibility historical for selected Comps
                    this.loading = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/category-searches"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id,
                        category_id: this.category_id ? this.category_id : null
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.loading = false
                    // this.formatChartData(response_data.data.data)
                    this.formatChartData(
                        response_data.data.data.sort(function (a, b) {
                            return b.monthly_searches[b.monthly_searches.length - 1].search_volume - a.monthly_searches[a.monthly_searches.length - 1].search_volume
                        })
                    )

                    console.log(response_data)
                } catch (error) {
                    //TODO: create notification
                    this.loading = false
                    console.log(error)
                }
            },
            formatChartData(searchData) {
                // Chart-Daten-Objekt
                this.chartData = {
                    labels: [],
                    datasets: []
                }
                let monthArray = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                // Labels für den Chart erstellen
                this.chartData.labels = searchData[0].monthly_searches.map((entry) => monthArray[entry.month] + " " + entry.year)
                // this.chartData.labels = dates.map((date) => this.getDayMonthAndYear(date)).reverse()

                // Daten für jeden Konkurrenten extrahieren und dem Chart-Daten-Objekt hinzufügen
                searchData.forEach((category, index) => {
                    console.log(category)
                    const dataset = {
                        label: category._id || "No category",
                        data: category.monthly_searches.map((entry) => entry.search_volume),
                        backgroundColor: "transparent",
                        borderColor: this.getColoFromIndex(index) || this.getColorFromString(category._id),
                        backgroundColor: (this.getColoFromIndex(index) || this.getColorFromString(category._id)) + "05",
                        pointBackgroundColor: "rgba(255, 255, 255, 1)",
                        pointStyle: "circle",
                        pointRadius: 6,
                        pointHoverRadius: 10,
                        borderWidth: 2
                        // gradient: true
                    }
                    this.chartData.datasets.push(dataset)
                })
                this.renderChart++
                console.log(this.chartData)
            },

            // Hilfsfunktion zum Generieren eines Labels
            // getDayMonthAndYear(dateString) {
            //     const date = new Date(dateString)
            //     const day = date.getDate()
            //     const month = date.toLocaleString("default", { month: "long" })
            //     const year = date.getFullYear()
            //     return `${day} ${month} ${year}`
            // },
            // Hilfsfunktion zum Generieren einer Farbe aus einer Zeichenkette
            getColoFromIndex(index) {
                let colors = ["#C2185B", "#7B1FA2", "#1976D2", "#0097A7", "#388E3C", "#AFB42B", "#F57C00", "#5D4037", "#455A64"]
                return colors[index]
            },
            getColorFromString(str) {
                let hash = 0
                for (let i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash) + str.length
                }
                const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16)
                return "#" + "0".repeat(6 - color.length) + color
            }
        },
        watch: {
            selectedCompetitors(val) {
                console.log("chart: ", val)
                this.fetchSearches()
            },
            show() {
                this.renderChart++
            }
        },
        mounted() {
            this.fetchSearches()
            this.renderChart++
        }
    }
</script>

<style lang="scss">
    #comp-chart-card {
        z-index: 3 !important;
    }
    #comp-chart-card > div.v-card__text {
        flex-grow: 1;
        min-height: 0;
        height: 100%;
        > div {
            position: relative;
            height: 280px;
        }
    }
</style>
