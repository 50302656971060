// USED IN BRANDINDEX

import { Line, mixins } from "vue-chartjs"
const { reactiveProp } = mixins

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ["chartData", "reloadChart"],
    data() {
        return {
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: "Chart.js Stacked Line/Bar Chart"
                    }
                },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true
                    }
                },
                legend: {
                    display: true,
                    labels: {
                        fontSize: 14
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    mounted() {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, this.options)
    },
    watch: {
        reloadChart: {
            handler: function () {
                // Neuladen der Chart
                this.$data._chart.update()
                this.renderChart(this.chartData, this.options)
            },
            deep: true
        }
    }
}
