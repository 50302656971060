<template>
    <div class="difficulty_wrapper">
        <v-progress-linear
            v-if="difficulty && difficulty.difficulty != null"
            class="white--text"
            style="border-radius: 12px; font-size: 12px"
            rounded
            outlined
            :value="difficulty.difficulty"
            :color="getColor(difficulty.difficulty)"
            height="24">
            {{ difficulty.difficulty }}
        </v-progress-linear>
        <!-- <div id="difficulty" v-if="difficulty && difficulty.difficulty >= 0">
            <div class="bg-icon-wrapper">
                <v-icon :small="small ? true : false" class="bg-icon">{{ icon }}</v-icon>
            </div>
            <svg
                id="gauge"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
                xml:space="preserve">
                <path :style="0 > lineCounter ? 'display: none' : 'fill: #009f89; display: show'" d="M12.9,75.5c0.3,0.5,0.7,0.9,1,1.4l7.6-5.7c-0.3-0.4-0.5-0.7-0.8-1.1L12.9,75.5z" />
                <path :style="1 > lineCounter ? 'display: none' : 'fill: #009f89; display: show'" d="M9.3,69.3c0.3,0.5,0.5,1,0.8,1.5l8.4-4.4c-0.2-0.4-0.4-0.8-0.6-1.2L9.3,69.3z" />
                <path :style="2 > lineCounter ? 'display: none' : 'fill: #009f89; display: show'" d="M6.7,62.4C6.9,62.9,7,63.5,7.2,64l9.1-3c-0.1-0.4-0.3-0.8-0.4-1.2L6.7,62.4z" />
                <path :style="3 > lineCounter ? 'display: none' : 'fill: #009f89; display: show'" d="M5.3,55.2c0,0.6,0.1,1.1,0.2,1.7l9.4-1.5c-0.1-0.4-0.1-0.9-0.2-1.3L5.3,55.2z" />
                <path :style="4 > lineCounter ? 'display: none' : 'fill: #009f70; display: show'" d="M5,47.9c0,0.6,0,1.1,0,1.7l9.5,0.1c0-0.5,0-0.9,0-1.4L5,47.9z" />
                <path :style="5 > lineCounter ? 'display: none' : 'fill: #009f70; display: show'" d="M5.9,40.7c-0.1,0.6-0.2,1.1-0.3,1.7L15,44c0.1-0.4,0.2-0.9,0.2-1.3L5.9,40.7z" />
                <path :style="6 > lineCounter ? 'display: none' : 'fill: #00e6a6; display: show'" d="M8,33.7c-0.2,0.5-0.4,1.1-0.6,1.6l9,3.1c0.1-0.4,0.3-0.9,0.5-1.3L8,33.7z" />
                <path :style="7 > lineCounter ? 'display: none' : 'fill: #00e6a6; display: show'" d="M11.2,27.1c-0.3,0.5-0.5,1-0.8,1.5l8.3,4.6c0.2-0.4,0.4-0.8,0.7-1.2L11.2,27.1z" />
                <path :style="8 > lineCounter ? 'display: none' : 'fill: #00e6a6; display: show'" d="M15.5,21.2c-0.4,0.4-0.8,0.9-1.1,1.3l7.5,5.8c0.3-0.4,0.6-0.7,0.9-1.1L15.5,21.2z" />
                <path :style="9 > lineCounter ? 'display: none' : 'fill: #00e6a6; display: show'" d="M20.6,16c-0.5,0.3-0.9,0.7-1.3,1.1l6.5,7c0.3-0.3,0.6-0.6,1-0.9L20.6,16z" />
                <path :style="10 > lineCounter ? 'display: none' : 'fill: #00e6a6; display: show'" d="M26.4,11.7c-0.5,0.3-0.9,0.6-1.4,0.9l5.3,7.9c0.3-0.2,0.7-0.5,1-0.7L26.4,11.7z" />
                <path :style="11 > lineCounter ? 'display: none' : 'fill: #abc4a6; display: show'" d="M33,8.3c-0.6,0.2-1.1,0.5-1.6,0.7l3.9,8.7c0.4-0.2,0.9-0.4,1.3-0.6L33,8.3z" />
                <path :style="12 > lineCounter ? 'display: none' : 'fill: #abc4a6; display: show'" d="M39.9,6.1c-0.5,0.1-1.1,0.3-1.6,0.4l2.5,9.2c0.4-0.1,0.8-0.2,1.2-0.3L39.9,6.1z" />
                <path :style="13 > lineCounter ? 'display: none' : 'fill: #cdb3a0; display: show'" d="M47.2,5.2c-0.6,0-1.1,0-1.7,0.1l0.9,9.4c0.4,0,0.9-0.1,1.3-0.1L47.2,5.2z" />
                <path :style="14 > lineCounter ? 'display: none' : 'fill: #cdb3a0; display: show'" d="M54.5,5.2c-0.6,0-1.1-0.1-1.7-0.1l-0.6,9.5c0.4,0,0.9,0.1,1.3,0.1L54.5,5.2z" />
                <path :style="15 > lineCounter ? 'display: none' : 'fill: #f17e7a; display: show'" d="M61.6,6.5c-0.5-0.1-1.1-0.3-1.6-0.4l-2.2,9.3c0.4,0.1,0.9,0.2,1.3,0.3L61.6,6.5z" />
                <path :style="16 > lineCounter ? 'display: none' : 'fill: #f17e7a; display: show'" d="M68.6,9C68,8.7,67.5,8.5,67,8.3l-3.6,8.8c0.4,0.2,0.8,0.3,1.2,0.5L68.6,9z" />
                <path :style="17 > lineCounter ? 'display: none' : 'fill: #f17e7a; display: show'" d="M74.9,12.5c-0.4-0.3-0.9-0.6-1.4-0.9l-5,8.1c0.4,0.2,0.7,0.5,1.1,0.7L74.9,12.5z" />
                <path :style="18 > lineCounter ? 'display: none' : 'fill: #ff9897; display: show'" d="M80.6,17c-0.5-0.4-0.9-0.7-1.3-1.1l-6.2,7.2c0.3,0.3,0.7,0.6,1,0.9L80.6,17z" />
                <path :style="19 > lineCounter ? 'display: none' : 'fill: #ff9897; display: show'" d="M85.7,22.4c-0.4-0.4-0.7-0.9-1.1-1.3l-7.4,6.1c0.3,0.3,0.5,0.7,0.8,1L85.7,22.4z" />
                <path :style="20 > lineCounter ? 'display: none' : 'fill: #ff9897; display: show'" d="M89.6,28.6c-0.2-0.5-0.5-1-0.8-1.5L80.6,32c0.2,0.4,0.4,0.7,0.6,1.1L89.6,28.6z" />
                <path :style="21 > lineCounter ? 'display: none' : 'fill: #f86867; display: show'" d="M92.5,35.3c-0.2-0.5-0.4-1.1-0.6-1.6l-8.8,3.4c0.2,0.4,0.3,0.8,0.5,1.2L92.5,35.3z" />
                <path :style="22 > lineCounter ? 'display: none' : 'fill: #f86867; display: show'" d="M94.4,42.2c-0.1-0.5-0.2-1.1-0.3-1.6l-9.4,2c0.1,0.4,0.2,0.9,0.2,1.3L94.4,42.2z" />
                <path :style="23 > lineCounter ? 'display: none' : 'fill: #f86867; display: show'" d="M95,47.8l-9.5,0.5c0,0.4,0,0.9,0,1.3l9.5-0.1C95,48.9,95,48.4,95,47.8z" />
                <path :style="24 > lineCounter ? 'display: none' : 'fill: #f86867; display: show'" d="M94.5,56.8c0.1-0.5,0.1-1.1,0.2-1.7l-9.4-1c-0.1,0.4-0.1,0.9-0.2,1.3L94.5,56.8z" />
                <path :style="25 > lineCounter ? 'display: none' : 'fill: #f54140; display: show'" d="M92.8,63.9c0.2-0.5,0.3-1,0.5-1.7l-9.1-2.6c-0.1,0.4-0.3,0.9-0.4,1.3L92.8,63.9z" />
                <path :style="26 > lineCounter ? 'display: none' : 'fill: #f54140; display: show'" d="M90,70.7c0.2-0.5,0.5-1,0.8-1.5l-8.7-4.1c-0.2,0.4-0.4,0.8-0.6,1.2L90,70.7z" />
                <path :style="27 > lineCounter ? 'display: none' : 'fill: #f54140; display: show'" d="M89.9,80.1c0.5-0.7,1-1.3,1.4-1.9l-12-8.2c-0.3,0.4-0.6,0.9-0.9,1.3L89.9,80.1z" />
            </svg>
            <div id="gauge-label">{{ difficulty.difficulty }}</div>
        </div> -->
        <div v-else>-</div>
    </div>
</template>

<script>
    export default {
        name: "KeywordDifficultyCell",
        props: {
            difficulty: Object,
            icon: String,
            small: Boolean
        },
        data() {
            return {}
        },
        computed: {
            lineCounter() {
                let count = 3.571428571428571
                return this.difficulty.difficulty / count
            }
        },
        methods: {
            getColor(position) {
                let positionColors = [
                    "green darken-2",
                    "light-green darken-2",
                    "light-green darken-2",
                    "lime darken-2",
                    "yellow darken-3",
                    "yellow darken-4",
                    "orange",
                    "orange darken-2",
                    "deep-orange darken-2",
                    "deep-orange darken-3"
                ]
                // console.log(position)
                let colorIndex = Math.ceil(position / 10) - 1
                let color = positionColors[colorIndex] ? positionColors[colorIndex] : positionColors[positionColors.length - 1]
                return color
            }
        }
    }
</script>
<style scoped></style>
<style>
    #competition_cell > div > div > div.v-progress-linear__background,
    #kw_difficulty_cell > div > div > div.v-progress-linear__background {
        opacity: 0.5 !important;
    }
    #competition_cell > div > div > div.v-progress-linear__determinate,
    #kw_difficulty_cell > div > div > div.v-progress-linear__determinate {
        opacity: 0.85 !important;
    }
    .difficulty_wrapper {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Nunito", sans-serif;
    }

    #difficulty {
        position: relative;
        width: 50px;
        height: 50px;
        padding: 0px;
        border-radius: 6px;
        box-sizing: border-box;
    }
    #difficulty #gauge {
        width: 100%;
        height: 100%;
        padding: 0px;
        /* background: white; */
        border-radius: 50%;
        box-sizing: border-box;
    }
    #difficulty #gauge path:first-child {
        opacity: 1 !important;
        display: block !important;
    }
    #difficulty #gauge-label {
        position: absolute;
        top: 50%;
        left: 50%;
        /* color: white; */
        font-size: 15px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /* color: #e31212; */
    }
    #difficulty #gauge-label:after {
        /* content: "Mbit/s"; */
        position: absolute;
        font-size: 35%;
        bottom: 5px;
        left: 50%;
        -webkit-transform: translate(-50%, 100%);
        transform: translate(-50%, 100%);
    }
    #difficulty:before,
    #difficulty:after {
        display: block;
        position: absolute;
        bottom: 25%;
        font-family: inherit;
        font-size: 14px;
        color: #9198af;
    }
    .bg-icon {
        width: 14px;
        height: 14px;
        position: absolute !important;
        top: 33px;
        left: calc(25px - 7px);
        opacity: 0.3;
    }
    .bg-icon-wrapper {
        position: relative;
    }
    /* #speedtest:before {
        content: "0";
        left: 28%;
    }
    #speedtest:after {
        content: "100";
        right: 28%;
    } */
</style>
