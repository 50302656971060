<template>
    <div>
        <v-snackbar style="z-index: 9999" top v-model="notification.show" :color="notification.color" max-width="100px">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <v-dialog v-model="show" max-width="600px" transition="slide-x-transition" persistent>
            <v-card>
                <v-card-title>
                    <span class="uppercase">{{ dialog.title }}</span>
                    <v-btn class="close-btn" absolute top right icon color="primary" @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>
                    <span>{{ dialog.subtitle }}</span>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text class="pt-5 pb-0" v-if="!dialog.showResponse">
                    <div class="qgreycard">
                        <div class="quarolbl pb-1">
                            Additional Commands:
                            <v-tooltip max-width="250" color="" top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>A short description what the generated content should be about. Or any other command for the AI.</span>
                            </v-tooltip>
                        </div>
                        <v-textarea hint="*optional" persistent-hint class="mb-2" rows="1" v-model="description" rounded filled></v-textarea>
                    </div>

                    <div class="qgreycard mb-5">
                        <div class="quarolbl pb-1">
                            Output
                            <v-tooltip max-width="250" color="" top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>Your desired content type</span>
                            </v-tooltip>
                        </div>
                        <v-radio-group return-object class="mt-0" v-model="outputType" row mandatory returnObject>
                            <v-radio v-for="t in ai_output_types" :key="t.value" :label="t.label" :value="t.value"></v-radio>
                        </v-radio-group>
                    </div>
                    <v-slider class="mt-10" thumb-label="always" v-model="word_count" label="Content Length" :min="10" :max="750"></v-slider>
                </v-card-text>
                <v-card-text v-else>
                    <v-chip class="pl-2 mt-5 ml-1" height="38px" color="#F6F6F6">
                        <!-- <v-icon :size="10" class="mr-1 py-auto">mdi-web</v-icon> -->
                        <img class="mr-1" style="width: 12; border-radius: 5px" :src="'https://s2.googleusercontent.com/s2/favicons?domain=https://chat.openai.com/&sz=12'" alt="" />
                        <span class="pl-1" style="font-size: 14px !important"> Response:</span>
                    </v-chip>
                    <div class="qgreycard">
                        <div class="airesponse" v-html="dialog.response"></div>
                    </div>
                </v-card-text>
                {{ dialog.error }}
                <!-- <v-divider></v-divider> -->
                <v-card-actions>
                    <!-- <v-btn v-if="ai_response_dialog.loading" outlined class="mx-auto" color="error" rounded>cancel</v-btn> -->

                    <v-btn :disabled="dialog.loading" v-if="!dialog.showResponse" class="mx-auto" color="primary" rounded @click="start">summarize</v-btn>
                    <v-btn v-else @click="dialog.showResponse = false" rounded>Back</v-btn>
                    <v-btn v-if="dialog.showResponse" class="" color="primary" rounded @click="copy">Copy</v-btn>
                </v-card-actions>
                <v-progress-linear v-if="dialog.loading" indeterminate color="primary"></v-progress-linear>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: { show: Boolean, textToSummarize: String },
        data() {
            return {
                notification: { show: false },
                text: "",
                dialog: {
                    title: "Summarize Text",
                    subtitle: "Use artificial intelligence to summarize text",
                    loading: false,
                    error: null,
                    response: null,
                    showResponse: false
                },
                description: "",
                word_count: 200,
                outputType: {},
                ai_output_types: [
                    { label: "Text", value: "text", command: "Fasse die wichtigsten Argumente aus folgendem Text kurz zusammen: " }
                    // { label: "List", value: "list", command: "Fasse die wichtigsten Argumente als Stichpunkte aus folgendem Text kurz zusammen: " }
                ]
            }
        },
        watch: {
            textToSummarize() {
                if (this.textToSummarize.length) {
                    this.text = this.textToSummarize
                }
            }
        },
        methods: {
            copy() {
                navigator.clipboard.writeText(this.dialog.response)
                this.notification = this.$helpers.createNotification(" Text copied to clipboard! ", "mdi-content-copy", "green darken-1")
                this.$emit("close")
            },
            async start() {
              try {
                console.log(this.text)
                console.log("start")
                if (!this.text.length) {
                    console.log("error")
                    return
                }
                this.dialog.loading = true
                let post_data = {
                    options: {
                        temperature: 0.8,
                        max_tokens: Math.ceil(this.word_count * 1.33333)
                    },
                    input: this.outputType.command + this.text
                }
                if (this.description) post_data.input = " Berücksichtige folgendes: " + this.description + "\n\n" + this.post_data + input
                let url = process.env.VUE_APP_APIURL + "/v2/sources/openai/chatgpt"
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data.choices[0].message.content)

                this.dialog.response = response_data.data.choices[0].message.content
                this.dialog.loading = false
                this.dialog.showResponse = true
              } catch (error) {
                this.dialog.loading = false
              }
            }
        },
        mounted() {
            console.log("mounted translate dialog")
        }
    }
</script>

<style></style>
