<template>
    <v-dialog v-model="show" max-width="700px" @click:outside="$emit('close')">
        <v-card>
            <v-card-title>
                <span class=""> Change Category</span>
                <v-btn right absolute @click="$emit('close')" class="dialog-close-btn" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>
                <v-chip class="mt-1" small outlined> {{ keywords.length }} selected</v-chip>
            </v-card-subtitle>
            <v-card-text>
                <v-tabs centered v-model="tab" color="primary" class="white--text">
                    <v-tab>Add to existing </v-tab>
                    <v-tab>Create new</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card-text>
                            <v-autocomplete
                                placeholder="Select category..."
                                append-icon="mdi-chevron-down"
                                class="mt-2"
                                v-model="selectedCategory"
                                :items="categories"
                                rounded
                                item-text="category_id"
                                filled
                                hint="Type to search..."
                                return-object>
                                <template v-slot:selection="{ item, index }">
                                    <span class="">
                                        <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                                            <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                            <v-chip small> {{ cat }}</v-chip>
                                        </span>
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <span class="">
                                        <span v-for="(cat, index) in data.item.category_id.split(';')" :key="cat">
                                            <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                            <v-chip small> {{ cat }}</v-chip>
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn rounded :loading="changeCat" class="mx-auto" color="primary" v-on:click="assignToExisting()">
                                <span v-if="!success">Assign to category</span>
                                <v-icon class="px-10 mx-9" v-else>mdi-check-circle-outline</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card-text>
                            <v-autocomplete
                                placeholder="Select parent category..."
                                append-icon="mdi-chevron-down"
                                class="mt-2"
                                v-model="newCategory.parent"
                                :items="categories"
                                rounded
                                filled
                                hint="Type to search..."
                                item-text="category_id"
                                return-object>
                                <template v-slot:selection="{ item, index }">
                                    <span class="">
                                        <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                                            <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                            <v-chip small> {{ cat }}</v-chip>
                                        </span>
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <span class="">
                                        <span v-for="(cat, index) in data.item.category_id.split(';')" :key="cat">
                                            <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                            <v-chip small> {{ cat }}</v-chip>
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                            <v-text-field placeholder="Category name..." class="mt-2" v-model="newCategory.name" rounded filled></v-text-field>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn rounded :loading="changeCat" class="mx-auto" color="primary" v-on:click="createAndAssign()">Create and Assign</v-btn>
                        </v-card-actions>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            keywords: Array,
            show: Boolean
        },
        data() {
            return {
                tab: 0,
                selectedCategory: null,
                categories: [],
                newCategory: {
                    parent: null,
                    name: ""
                },
                fetchingCats: false,
                changeCat: false,
                success: false
            }
        },
        methods: {
            async fetchCategories() {
                try {
                    this.fetchingCats = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/categories"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.categories = response_data.data.categories.sort((a, b) => a.category_id.localeCompare(b.category_id))
                    console.log(this.categories)

                    this.fetchingCats = false
                } catch (error) {
                    console.log(error)
                }
            },
            async assignToExisting() {
                console.log(this.keywords)
                console.log(this.selectedCategory)
                this.changeCat = true
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/change/category"
                let post_data = {
                    company_id: localStorage.company_id,
                    project_id: this.$route.params.id,
                    keywords: this.keywords,
                    categoryToSet: this.selectedCategory
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
                setTimeout(() => {
                    this.changeCat = false
                    this.success = true
                    setTimeout(() => {
                        this.$emit("catUpdated")
                    }, 300)
                }, 300)
            },
            async createAndAssign() {
                console.log(this.newCategory)
                if (this.newCategory.name.length) {
                    let newCat = {
                        category_id: this.newCategory.parent ? this.newCategory.parent.category_id + ";" + this.newCategory.name : this.newCategory.name,
                        categories: this.newCategory.parent ? this.newCategory.parent.categories : {},
                        keyword_count: this.keywords.length,
                        project_id: this.$route.params.id
                    }
                    newCat.level = newCat.category_id.split(";").length - 1
                    newCat.categories["level" + newCat.level] = this.newCategory.name
                    console.log(newCat)
                    this.changeCat = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/change/newcategory"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id,
                        keywords: this.keywords,
                        newCat: newCat
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                    setTimeout(() => {
                        this.changeCat = false
                        this.success = true
                        setTimeout(() => {
                            this.$emit("catUpdated")
                        }, 300)
                    }, 300)
                }
            }
        },
        watch: {
            show(val) {
                if (val) {
                    this.success = false
                    this.fetchCategories()
                }
            }
        }
    }
</script>

<style></style>
