module.exports = {
    stateItems: [
        // value = location_code
        // cc = DataForSEO Labs lang_code

        {
            id: 0,
            loc: "Australia",
            value: "2036",
            ads_lang_code: "en",
            cc: "en",
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questionParams: ["are", "can", "how", "what", "when", "where", "which", "who", "why", "will"],
            prepositionParams: ["for", "is", "near", "to", "with", "without"],
            comparisonParams: ["and", "like", "or", "versus", "vs"]
        },
        {
            id: 1,
            loc: "Austria",
            value: "2040",
            ads_lang_code: "de",
            cc: "de",
            questions: [{ question: "wer" }, { question: "wie" }, { question: "was" }, { question: "wo" }, { question: "warum" }],
            questionParams: ["das", "dass", "der", "die", "wann", "warum", "was", "welche", "welcher", "welches", "wer", "wie", "wo", "wohin"],
            prepositionParams: ["ähnlich", "an", "auf", "bei", "bis", "für", "mit", "nach", "nahe", "ohne", "zu"],
            comparisonParams: ["gegen", "gleich", "oder", "und", "vs"]
        },
        {
            id: 42,
            loc: "Bahrain",
            value: "2048",
            ads_lang_code: "ar",
            cc: "ar",
            questions: [{ question: "من" }, { question: "كيف" }, { question: "ما" }, { question: "أين" }, { question: "لماذا" }],
            questionParams: ["من", "كيف", "ما", "أين", "لماذا"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 2,
            loc: "Belgium - Dutch",
            value: "2056",
            ads_lang_code: "nl",
            cc: "nl",
            questions: [{ question: "wie" }, { question: "hoe" }, { question: "wat" }, { question: "waar" }, { question: "waarom" }],
            questionParams: ["hoe", "is", "waar", "waarom", "wanneer", "wat", "welke", "wie"],
            prepositionParams: ["als", "bij", "met", "naar", "voor", "zonder"],
            comparisonParams: ["en", "gelijk", "of", "versus", "vs"]
        },
        {
            id: 3,
            loc: "Belgium - French",
            value: "2056",
            ads_lang_code: "fr",
            cc: "fr",
            questions: [{ question: "qui" }, { question: "comment" }, { question: "que" }, { question: "où" }, { question: "pourquoi" }],
            questionParams: ["pourquoi", "quand", "ou", "quel", "lequel", "comment", "laquelle", "quoi", "que", "quelle", "qui", "quest ce"],
            prepositionParams: ["avec", "au", "pour", "a", "sans", "pres de", "pres"],
            comparisonParams: ["contre", "et", "comme", "ou", "vs"]
        },
        {
            id: 44,
            loc: "Canada",
            value: "2124",
            ads_lang_code: "en",
            cc: "en",
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questionParams: ["are", "can", "how", "what", "when", "where", "which", "who", "why", "will"],
            prepositionParams: ["for", "is", "near", "to", "with", "without"],
            comparisonParams: ["and", "like", "or", "versus", "vs"]
        },
        {
            id: 4,
            loc: "Croatia",
            value: "2191",
            ads_lang_code: "hr",
            cc: "hr",
            questions: [{ question: "tko" }, { question: "kako" }, { question: "što" }, { question: "gdje" }, { question: "zašto" }],
            questionParams: ["tko", "kako", "što", "gdje", "zašto"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 5,
            loc: "Czechia",
            value: "2203",
            ads_lang_code: "cs",
            cc: "cs",
            questions: [{ question: "kdo" }, { question: "jak" }, { question: "co" }, { question: "kde" }, { question: "proč" }],
            questionParams: ["bude", "co", "jak", "jaký", "kde", "kdo", "kdy", "můžu", "proc"],
            prepositionParams: ["bez", "blízko", "do", "je", "jsou", "možný", "pro"],
            comparisonParams: ["jako", "nebo", "versus", "vs"]
        },
        {
            id: 6,
            loc: "Denmark",
            value: "2208",
            ads_lang_code: "da",
            cc: "da",
            questions: [{ question: "hvem" }, { question: "hvordan" }, { question: "hvad" }, { question: "hvor" }, { question: "hvorfor" }],
            questionParams: ["er", "hvad", "hvem", "hvilken", "hvilket", "hvor", "hvordan", "hvorfor", "når"],
            prepositionParams: ["for", "med", "nær", "som", "til", "uden"],
            comparisonParams: ["eller", "imod", "kontra", "ligesom", "og", "vs"]
        },
        {
            id: 7,
            loc: "Finland",
            value: "2246",
            ads_lang_code: "fi",
            cc: "fi",
            questions: [{ question: "kuka" }, { question: "miten" }, { question: "mikä" }, { question: "missä" }, { question: "miksi" }],
            questionParams: ["kuinka", "kuka", "kumpi", "miksi", "milloin", "missä", "mitä", "onko", "voiko"],
            prepositionParams: ["ilman", "johonkin", "kanssa", "lähellä", "on", "ovatko", "varten", "voi"],
            comparisonParams: ["ja", "kuten", "tai", "vastaan", "vs"]
        },
        {
            id: 8,
            loc: "France",
            value: "2250",
            ads_lang_code: "fr",
            cc: "fr",
            questions: [{ question: "qui" }, { question: "comment" }, { question: "que" }, { question: "où" }, { question: "pourquoi" }],
            questionParams: ["pourquoi", "quand", "ou", "quel", "lequel", "comment", "laquelle", "quoi", "que", "quelle", "qui", "quest ce"],
            prepositionParams: ["avec", "au", "pour", "a", "sans", "pres de", "pres"],
            comparisonParams: ["contre", "et", "comme", "ou", "vs"]
        },
        {
            id: 9,
            loc: "Germany",
            value: "2276",
            ads_lang_code: "de",
            cc: "de",
            questions: [{ question: "wer" }, { question: "wie" }, { question: "was" }, { question: "wo" }, { question: "warum" }],
            questionParams: ["das", "dass", "der", "die", "wann", "warum", "was", "welche", "welcher", "welches", "wer", "wie", "wo", "wohin"],
            prepositionParams: ["ähnlich", "an", "auf", "bei", "bis", "für", "mit", "nach", "nahe", "ohne", "zu"],
            comparisonParams: ["gegen", "gleich", "oder", "und", "vs"]
        },
        {
            id: 10,
            loc: "Greece",
            value: "2300",
            ads_lang_code: "el",
            cc: "el",
            questions: [{ question: "Ποιός" }, { question: "Πώς" }, { question: "Τί" }, { question: "Πού" }, { question: "Γιατί" }],
            questionParams: ["wπότε", "για που", "γιατί", "η", "ο", "ποιο", "ποιος", "που", "πως", "τι", "το"],
            prepositionParams: ["για", "δίπλα", "κοντά", "με", "μετά", "πάνω από", "παρόμοιος", "πριν από", "πως", "σε", "στο", "χωρίς"],
            comparisonParams: ["εναντίον", "ή", "και", "κατά"]
        },
        {
            id: 11,
            loc: "Hungary",
            value: "2348",
            ads_lang_code: "hu",
            cc: "hu",
            questions: [{ question: "ki" }, { question: "hogy" }, { question: "mi" }, { question: "hol" }, { question: "miért" }],
            questionParams: ["ki", "hogy", "mi", "hol", "miért"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 12,
            loc: "Japan",
            value: "2392",
            ads_lang_code: "ja",
            cc: "ja",
            questions: [{ question: "誰" }, { question: "どうやって" }, { question: "何" }, { question: "どこ" }, { question: "どうして" }],
            questionParams: ["誰", "どうやって", "何", "どこ", "どうして"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 43,
            loc: "India",
            value: "2356",
            ads_lang_code: "hi",
            cc: "hi",
            questions: [{ question: "कौन" }, { question: "कैसे" }, { question: "क्या" }, { question: "कहा" }, { question: "क्यों" }],
            questionParams: ["कौन", "कैसे", "क्या", "कहा", "क्यों"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 32,
            loc: "International",
            value: null,
            cc: "",
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questionParams: ["are", "can", "how", "what", "when", "where", "which", "who", "why", "will"],
            prepositionParams: ["for", "is", "near", "to", "with", "without"],
            comparisonParams: ["and", "like", "or", "versus", "vs"]
        },
        {
            id: 33,
            loc: "Ireland",
            value: "2372",
            ads_lang_code: "en",
            cc: "en",
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questionParams: ["are", "can", "how", "what", "when", "where", "which", "who", "why", "will"],
            prepositionParams: ["for", "is", "near", "to", "with", "without"],
            comparisonParams: ["and", "like", "or", "versus", "vs"]
        },
        {
            id: 13,
            loc: "Italy",
            value: "2380",
            ads_lang_code: "it",
            cc: "it",
            questions: [{ question: "chi" }, { question: "come" }, { question: "cosa" }, { question: "dove" }, { question: "perché" }],
            questionParams: ["chi", "come", "cosa", "dove", "perché", "quale", "quando", "siamo", "sono"],
            prepositionParams: ["a", "per", "senza", "vicino"],
            comparisonParams: ["come", "con", "contro", "o", "vs"]
        },
        {
            id: 14,
            loc: "Lithuania",
            value: "2440",
            ads_lang_code: "lt",
            cc: "lt",
            questions: [{ question: "kas" }, { question: "kaip" }, { question: "kas" }, { question: "kur" }, { question: "kodėl" }],
            questionParams: ["kas", "kaip", "kur", "kodėl"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 15,
            loc: "Malaysia",
            value: "2458",
            ads_lang_code: "ms",
            cc: "ms",
            questions: [{ question: "yang" }, { question: "bagaimana" }, { question: "apa" }, { question: "mana" }, { question: "mengapa" }],
            questionParams: ["yang", "bagaimana", "apa", "mana", "mengapa"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 36,
            loc: "Mexico",
            value: "2484",
            ads_lang_code: "es",
            cc: "es",
            questions: [{ question: "quién" }, { question: "cómo" }, { question: "qué" }, { question: "dónde" }, { question: "por qué" }],
            questionParams: ["cómo", "cual", "cuando", "dónde", "por qué", "qué", "quien", "son"],
            prepositionParams: ["a", "cerca", "cómo", "con", "para", "sin"],
            comparisonParams: ["igual", "o", "versus", "vs", "y"]
        },
        {
            id: 16,
            loc: "Netherlands",
            value: "2528",
            ads_lang_code: "nl",
            cc: "nl",
            questions: [{ question: "wie" }, { question: "hoe" }, { question: "wat" }, { question: "waar" }, { question: "waarom" }],
            questionParams: ["hoe", "is", "waar", "waarom", "wanneer", "wat", "welke", "wie"],
            prepositionParams: ["als", "bij", "met", "naar", "voor", "zonder"],
            comparisonParams: ["en", "gelijk", "of", "versus", "vs"]
        },
        {
            id: 17,
            loc: "Norway",
            value: "2578",
            ads_lang_code: "no",
            cc: "nb",
            questions: [{ question: "hvem" }, { question: "hvordan" }, { question: "hva" }, { question: "hvor" }, { question: "hvorfor" }],
            questionParams: ["hvem", "hvordan", "hva", "hvor", "hvorfor"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 18,
            loc: "Poland",
            value: "2616",
            ads_lang_code: "pl",
            cc: "pl",
            questions: [{ question: "kto" }, { question: "jak" }, { question: "co" }, { question: "gdzie" }, { question: "dlaczego" }],
            questionParams: ["co", "czemu", "dlaczego", "gdzie", "jak", "kiedy", "kto", "które", "który", "którzy", "w jaki sposób"],
            prepositionParams: ["bez", "blisko", "czy", "do", "jak", "niedaleko", "obok", "podobne", "w okolicy", "w pobliżu", "z"],
            comparisonParams: ["dla", "i", "lub", "podobny", "przeciw", "vs"]
        },
        {
            id: 35,
            loc: "Portugal",
            value: "2620",
            ads_lang_code: "pt",
            cc: "pt",
            questions: [{ question: "que" }, { question: "quem" }, { question: "qual" }, { question: "porque" }, { question: "como" }],
            questionParams: ["que", "quem", "qual", "quanto", "porque", "quando", "onde", "como"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 19,
            loc: "Romania",
            value: "2642",
            ads_lang_code: "ro",
            cc: "ro",
            questions: [{ question: "cine" }, { question: "cum" }, { question: "ce" }, { question: "unde" }, { question: "de ce" }],
            questionParams: ["cine", "cum", "ce", "unde", "de ce"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 34,
            loc: "Saudi Arabia",
            value: "2682",
            ads_lang_code: "ar",
            cc: "ar",
            questions: [{ question: "من" }, { question: "كيف" }, { question: "ما" }, { question: "أين" }, { question: "لماذا" }],
            questionParams: ["من", "كيف", "ما", "أين", "لماذا"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 34,
            loc: "Saudi Arabia",
            value: "2682",
            ads_lang_code: "ar",
            cc: "ar",
            questions: [{ question: "من" }, { question: "كيف" }, { question: "ما" }, { question: "أين" }, { question: "لماذا" }]
        },
        {
            id: 38,
            loc: "Serbia",
            value: "2688",
            ads_lang_code: "sr",
            cc: "sr",
            questions: [{ question: "ко" }, { question: "где" }, { question: "шта" }, { question: "зашто" }, { question: "како" }]
        },
        {
            id: 39,
            loc: "Singapore",
            value: "2702",
            ads_lang_code: "zh_CN",
            cc: "zh-CN",
            questions: [{ question: "谁" }, { question: "什么" }, { question: "哪里" }, { question: "哪个" }, { question: "为什么" }]
        },
        {
            id: 20,
            loc: "Slovakia",
            value: "2703",
            ads_lang_code: "sk",
            cc: "sk",
            questions: [{ question: "kto" }, { question: "ako" }, { question: "čo" }, { question: "kde" }, { question: "prečo" }],
            questionParams: ["kto", "ako", "čo", "kde", "prečo"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 21,
            loc: "Slovenia",
            value: "2705",
            ads_lang_code: "sl",
            cc: "sl",
            questions: [{ question: "kdo" }, { question: "kako" }, { question: "kaj" }, { question: "kje" }, { question: "zakaj" }],
            questionParams: ["kdo", "kako", "kaj", "kje", "zakaj"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 22,
            loc: "South Korea",
            value: "2410",
            ads_lang_code: "ko",
            cc: "ko",
            questions: [{ question: "누구" }, { question: "어떻게" }, { question: "뭐" }, { question: "어디에" }, { question: "왜" }],
            questionParams: ["누구", "어떻게", "뭐", "어디에", "왜"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 23,
            loc: "Spain",
            value: "2724",
            ads_lang_code: "es",
            cc: "es",
            questions: [{ question: "quién" }, { question: "cómo" }, { question: "qué" }, { question: "dónde" }, { question: "por qué" }],
            questionParams: ["cómo", "cual", "cuando", "dónde", "por qué", "qué", "quien", "son"],
            prepositionParams: ["a", "cerca", "cómo", "con", "para", "sin"],
            comparisonParams: ["igual", "o", "versus", "vs", "y"]
        },
        {
            id: 24,
            loc: "Sweden",
            value: "2752",
            ads_lang_code: "sv",
            cc: "sv",
            questions: [{ question: "vem" }, { question: "hur" }, { question: "vad" }, { question: "var" }, { question: "varför" }],
            questionParams: ["är", "hur", "när", "vad", "varför", "varti", "vem", "vilka", "vilken", "vilket"],
            prepositionParams: ["för", "med", "nära", "som", "till", "utan"],
            comparisonParams: ["eller", "kontra", "mot", "och", "som", "vs"]
        },
        {
            id: 25,
            loc: "Switzerland",
            value: "2756",
            ads_lang_code: "de",
            cc: "de",
            questions: [{ question: "wer" }, { question: "wie" }, { question: "was" }, { question: "wo" }, { question: "warum" }],
            questionParams: ["das", "dass", "der", "die", "wann", "warum", "was", "welche", "welcher", "welches", "wer", "wie", "wo", "wohin"],
            prepositionParams: ["ähnlich", "an", "auf", "bei", "bis", "für", "mit", "nach", "nahe", "ohne", "zu"],
            comparisonParams: ["gegen", "gleich", "oder", "und", "vs"]
        },
        {
            id: 37,
            loc: "Taiwan",
            value: "2158",
            ads_lang_code: "zh_TW",
            cc: "zh-TW",
            questions: [{ question: "谁" }, { question: "什么" }, { question: "为什么" }, { question: "哪里" }, { question: "哪个" }],
            questionParams: ["谁", "什么", "为什么", "哪里", "哪个", "什么时候", "怎么"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 26,
            loc: "Thailand",
            value: "2764",
            ads_lang_code: "th",
            cc: "th",
            questions: [{ question: "ใคร" }, { question: "อย่างไร" }, { question: "อะไร" }, { question: "ที่ไหน" }, { question: "ทำไม" }],
            questionParams: ["ใคร", "อย่างไร", "อะไร", "ที่ไหน", "ทำไม"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 27,
            loc: "Turkey",
            value: "2792",
            ads_lang_code: "tr",
            cc: "tr",
            questions: [{ question: "kim" }, { question: "nasıl" }, { question: "ne" }, { question: "nerede" }, { question: "niye" }],
            questionParams: ["kim", "nasıl", "ne", "nerede", "niye"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 28,
            loc: "Ukraine",
            value: "2804",
            ads_lang_code: "uk",
            cc: "uk",
            questions: [{ question: "хто" }, { question: "як" }, { question: "що" }, { question: "де" }, { question: "чому" }],
            questionParams: ["хто", "як", "що", "де", "чому"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 40,
            loc: "UAE - Arabic",
            value: "2784",
            ads_lang_code: "ar",
            cc: "ar",
            questions: [{ question: "من" }, { question: "كيف" }, { question: "ما" }, { question: "أين" }, { question: "لماذا" }],
            questionParams: ["من", "كيف", "ما", "أين", "لماذا"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 41,
            loc: "UAE - English",
            value: "2784",
            ads_lang_code: "en",
            cc: "en",
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questionParams: ["are", "can", "how", "what", "when", "where", "which", "who", "why", "will"],
            prepositionParams: ["for", "is", "near", "to", "with", "without"],
            comparisonParams: ["and", "like", "or", "versus", "vs"]
        },
        {
            id: 29,
            loc: "United Kingdom",
            value: "2826",
            ads_lang_code: "en",
            cc: "en",
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questionParams: ["are", "can", "how", "what", "when", "where", "which", "who", "why", "will"],
            prepositionParams: ["for", "is", "near", "to", "with", "without"],
            comparisonParams: ["and", "like", "or", "versus", "vs"]
        },
        {
            id: 30,
            loc: "United States",
            value: "2840",
            ads_lang_code: "en",
            cc: "en",
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questionParams: ["are", "can", "how", "what", "when", "where", "which", "who", "why", "will"],
            prepositionParams: ["for", "is", "near", "to", "with", "without"],
            comparisonParams: ["and", "like", "or", "versus", "vs"]
        },
        {
            id: 31,
            loc: "Vietnam",
            value: "2704",
            ads_lang_code: "vi",
            cc: "vi",
            questions: [{ question: "ai" }, { question: "thế nào" }, { question: "Gì" }, { question: "Ở đâu" }, { question: "tại sao" }],
            questionParams: ["ai", "thế nào", "Gì", "Ở đâu", "tại sao"],
            prepositionParams: [],
            comparisonParams: []
        },
        {
            id: 32,
            loc: "International",
            value: null,
            cc: "",
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questions: [{ question: "who" }, { question: "how" }, { question: "what" }, { question: "where" }, { question: "why" }],
            questionParams: ["are", "can", "how", "what", "when", "where", "which", "who", "why", "will"],
            prepositionParams: ["for", "is", "near", "to", "with", "without"],
            comparisonParams: ["and", "like", "or", "versus", "vs"]
        }
    ]
}
