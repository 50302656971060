var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"ml-1",staticStyle:{"text-transform":"uppercase","font-weight":"100","color":"rgb(59, 59, 59)","font-size":"18px"}},[_vm._v(" Add Keywords")]),_c('v-btn',{staticClass:"dialog-close-btn",attrs:{"right":"","absolute":"","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',[_c('v-chip',{staticClass:"mt-1",attrs:{"color":"primary","small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.project_title))])],1),_c('v-card-text',[_c('div',{staticClass:"pb-4 qgreycard"},[_c('div',{staticClass:"quarolbl pb-2"},[_vm._v(" Category "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Select the category to which the keywords should be added.")])])],1),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"placeholder":"Select category...","append-icon":"mdi-chevron-down","items":_vm.categories,"rounded":"","item-text":"category_id","filled":"","hint":"Type to search...","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{},_vm._l((item.category_id.split(';')),function(cat,index){return _c('span',{key:cat},[(index)?_c('v-icon',{staticClass:"px-0",attrs:{"small":""}},[_vm._v("mdi-chevron-right")]):_vm._e(),_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(cat))])],1)}),0)]}},{key:"item",fn:function(data){return [_c('span',{},_vm._l((data.item.category_id.split(';')),function(cat,index){return _c('span',{key:cat},[(index)?_c('v-icon',{staticClass:"px-0",attrs:{"small":""}},[_vm._v("mdi-chevron-right")]):_vm._e(),_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(cat))])],1)}),0)]}}]),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-btn',{staticStyle:{"right":"25px","top":"14px"},attrs:{"outlined":"","rounded":"","small":"","color":"primary","text":"","right":"","absolute":""},on:{"click":_vm.addFromClipboard}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-package-down")]),_vm._v(" from clipboard")],1)],1),_c('div',{staticClass:"pb-4 qgreycard"},[_c('div',{staticClass:"quarolbl pb-2"},[_vm._v(" Keywords "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_c('b',[_vm._v("Paste the keywords you want to upload.")]),_vm._v(" "),_c('br'),_vm._v(" You can paste them directly from a table or via the integrated quaro clipbloard!")])])],1),_c('v-textarea',{staticClass:"mt-2",attrs:{"persistent-hint":"","hint":_vm.cleaned_keywords.length + ' keywords',"label":"Paste keywords here..","rounded":"","filled":"","color":"primary"},model:{value:(_vm.kw_textfield),callback:function ($$v) {_vm.kw_textfield=$$v},expression:"kw_textfield"}})],1),(_vm.inputErrors.length)?_c('div',[_c('v-list',[_vm._l((_vm.inputErrors),function(errorEntry,index){return _c('div',{key:index},[(index < 3)?_c('v-list-item',{attrs:{"dense":"","three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"pb-1",attrs:{"small":"","color":"error"}},[_vm._v("mdi-alert-circle")]),_vm._v(" Row: "+_vm._s(errorEntry.index + 1))],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(errorEntry.type)+"!")]),_c('v-list-item-subtitle',[_vm._v(" keyword: "+_vm._s(errorEntry.keyword))])],1)],1):_vm._e()],1)}),(_vm.inputErrors.length > 3)?_c('div',{staticStyle:{"color":"red"}},[_vm._v("and "+_vm._s(_vm.inputErrors.length - 3)+" more... Rows: "+_vm._s(_vm.inputErrors.map(function (e) { return e.index + 1; })))]):_vm._e()],2)],1):_vm._e()]),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-auto",attrs:{"disabled":_vm.uploadingDisabled,"rounded":"","loading":_vm.uploading,"color":"primary"},on:{"click":_vm.addKeywords}},[(!_vm.success)?_c('span',[_vm._v("Add Keywords")]):_c('v-icon',{staticClass:"px-10 mx-9"},[_vm._v("mdi-check-circle-outline")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }