<template>
    <v-dialog v-model="show" max-width="700px" @click:outside="$emit('close')">
        <v-card v-if="selectedDeleteItem">
            <v-card-title>
                <span class="">
                    Delete Category: <b>{{ categoryName }}</b></span
                >
                <v-btn right absolute @click="$emit('close')" class="dialog-close-btn" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>There are {{ $helpers.formatNumber(selectedDeleteItem.keyword_count) }} keywords in this category!</v-card-subtitle>
            <v-card-text>
                <div>
                    <!-- <div>There are {{ $helpers.formatNumber(selectedDeleteItem.keyword_count) }} keywords in this category!</div> -->
                    <!-- <div>What should we do with the assigned keywords?</div> -->
                </div>
                <v-radio-group v-model="option" row>
                    <v-radio label="Delete all keywords" value="delete"></v-radio>
                    <v-radio label="Assign to another category" value="move"></v-radio>
                </v-radio-group>
                <v-slide-x-transition>
                    <div v-if="option == 'move'">
                        <v-autocomplete
                            placeholder="Select category..."
                            append-icon="mdi-chevron-down"
                            class="mt-2"
                            v-model="selectedCategory"
                            :items="categories"
                            rounded
                            item-text="category_id"
                            filled
                            hint="Type to search..."
                            return-object>
                            <template v-slot:selection="{ item, index }">
                                <span class="">
                                    <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                                        <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                        <v-chip small> {{ cat }}</v-chip>
                                    </span>
                                </span>
                            </template>
                            <template v-slot:item="data">
                                <span class="">
                                    <span v-for="(cat, index) in data.item.category_id.split(';')" :key="cat">
                                        <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                        <v-chip small> {{ cat }}</v-chip>
                                    </span>
                                </span>
                            </template>
                        </v-autocomplete>
                    </div>
                </v-slide-x-transition>
            </v-card-text>

            <v-card-actions>
                <v-btn rounded :loading="loading" class="mx-auto" color="primary" v-on:click="deleteCategory()">delete Category</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            show: Boolean,
            selectedDeleteItem: Object
        },
        data() {
            return {
                loading: false,
                option: "delete",
                selectedCategory: null,
                categories: []
            }
        },
        computed: {
            categoryName() {
                let split = this.selectedDeleteItem.category_id.split(";")
                return split[split.length - 1]
            }
        },
        methods: {
            async fetchCategories() {
                try {
                    this.fetchingCats = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/categories"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.categories = response_data.data.categories.sort((a, b) => a.category_id.localeCompare(b.category_id))
                    console.log(this.categories)

                    this.fetchingCats = false
                } catch (error) {
                    console.log(error)
                }
            },

            async deleteCategory() {
                console.log(this.option)
                console.log(this.selectedCategory)
                if (this.selectedDeleteItem) {
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/delete/category"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id,
                        option: this.option,
                        categoryToDelete: this.selectedDeleteItem,
                        newCat: this.selectedCategory
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                    setTimeout(() => {
                        this.success = true
                        setTimeout(() => {
                            this.$emit("catDeleted")
                        }, 300)
                    }, 300)
                }
            }
        },
        watch: {
            show(val) {
                if (val) {
                    this.success = false
                    this.fetchCategories()
                }
            }
        }
    }
</script>

<style></style>
