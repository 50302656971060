<template>
    <div id="contentcontainter" class="standard-mode" style="display: flex; height: 95%; padding-top: 0px">
        <v-container id="login" fill-height tag="section" class="pa-0">
            <v-row justify="center" class="ma-auto">
                <v-slide-y-transition appear>
                    <v-card light max-width="100%" width="450" class="px-5 py-3 rounded_card quaroshadow" style="margin: auto">
                        <center>
                            <div id="sidebarLogo" class="quaroshadow">
                                <img width="150" id="whitelogo" src="../assets/quaro_final_white.svg" />
                                <template>
                                    <div class="text-center">
                                        <v-btn v-for="(social, i) in socials" :key="i" :href="social.href" class="ma-1" icon rel="noopener" target="_blank">
                                            <v-icon v-text="social.icon" />
                                        </v-btn>
                                    </div>
                                </template>
                            </div>
                        </center>

                        <v-card-text class="text-center">
                            <form @submit.prevent="login">
                                <v-text-field rounded filled class="mr-3" v-model="email" color="primary" label="Email..." prepend-icon="mdi-email" @blur="checkForSso(false)" />

                                <v-text-field
                                    rounded
                                    class="mr-3"
                                    filled
                                    v-model="pass"
                                    color="primary"
                                    label="Password..."
                                    prepend-icon="mdi-lock-outline"
                                    :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_pw ? 'text' : 'password'"
                                    @click:append="show_pw = !show_pw" />
                                <v-btn color="primary" large rounded type="submit" :loading="loading"> Login </v-btn>

                                <v-btn v-if="showSsoButton" class="ml-5" color="primary" large rounded outlined @click="redirectToSSO"> SSO </v-btn>
                            </form>
                            <v-row no-gutters class="mt-5">
                                <v-col>
                                    <v-btn color="primary" class="mr-auto" @click="gotoRegister" text>Register</v-btn>
                                    <v-spacer></v-spacer>
                                    <!-- <p class="registerLink" @click="gotoRegister">No account? Register here!</p> -->

                                    <v-dialog v-model="dialog" max-width="400px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <!-- <p class="forgotpwlink" v-bind="attrs" v-on="on">Forgot password?</p> -->
                                            <v-btn v-bind="attrs" v-on="on" color="primary" text>Forgot password?</v-btn>
                                        </template>
                                        <v-card id="passwordDialog">
                                            <v-card-title class="justify-center">
                                                <span class="headline">Reset Password </span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12" sm="12" md="12">
                                                            <v-text-field label="Email*" v-model="email" required></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions class="justify-center">
                                                <v-btn color="primary" outlined @click="checkEmail(email)"> Send Email </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-dialog v-model="resendEmailDialog" v-if="showResendMessage" max-width="400px">
                                <template v-slot:activator="{ on, attrs }">
                                    <p class="forgotpwlink" v-bind="attrs" v-on="on">Resend verification mail!</p>
                                </template>
                                <v-card id="passwordDialog">
                                    <v-card-title class="justify-center">
                                        <span class="headline">Resend verification mail </span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field label="Email*" v-model="email" required></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="justify-center">
                                        <v-btn color="primary" outlined @click="resendVerificationMail(email)"> Send Email </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- Info -->
                            <v-snackbar top :color="info.color" v-model="showInfo">
                                <v-icon>{{ info.icon }}</v-icon>
                                <span class="font-weight-bold pl-2">{{ info.message }}</span>
                            </v-snackbar>
                            <!-- SSO MESSAGE -->
                            <v-snackbar top color="green" timeout="10000" v-model="showSSOInfo">
                                <v-icon>mdi-check-circle-outline</v-icon>
                                <span class="font-weight-bold pl-2"
                                    >SSO detected. Please use SSO button if you want to use Single Sign On.<br />
                                    You will be redirected and logged in through your SSO provider.</span
                                >
                            </v-snackbar>
                            <v-snackbar top v-model="authError" color="red" class="pl-10">
                                <v-icon>mdi-alert-circle-outline</v-icon>
                                <span class="font-weight-bold">The combination of the chosen username and password is invalid!</span>
                            </v-snackbar>
                            <!-- No user found with this auth code -->
                            <v-snackbar top v-model="showNoUserFound" color="red" class="pl-10">
                                <v-icon>mdi-alert-circle-outline</v-icon>
                                <span class="font-weight-bold">We could not find the user you are looking for. <br />Please contact the support at contact@quaro.io</span>
                            </v-snackbar>
                            <!-- No user found with this auth code -->
                            <v-snackbar top v-model="verificationError" color="red" class="pl-10">
                                <v-icon>mdi-alert-circle-outline</v-icon>
                                <span class="font-weight-bold">Your email adress has not been verfied yet! <br />Please check your email account for our activation-link.</span>
                            </v-snackbar>
                            <!-- auth true -->
                            <v-snackbar top v-model="showSuccessMessage" color="green" class="pl-10">
                                <v-icon>mdi-check-circle-outline</v-icon>
                                <span class="font-weight-bold">Your email adress has been verified!</span>
                            </v-snackbar>
                            <!-- password will be resetted -->
                            <v-snackbar top v-model="showResetPasswordMessage" color="green" class="pl-10">
                                <v-icon>mdi-check-circle-outline</v-icon>
                                <span class="font-weight-bold">We have sent you a link to reset your password!<br />Please check your email account.</span>
                            </v-snackbar>
                        </v-card-text>
                    </v-card>
                </v-slide-y-transition>
            </v-row>
        </v-container>
        <Datenschutz :show="showDatenschutz" @close="showDatenschutz = false"></Datenschutz>
        <v-footer padless fixed bottom>
            <v-col @click="openAGB()" class="footer-col">
                <span>AGB</span>
            </v-col>
            <v-col @click="openImprint()" class="footer-col">
                <span>Impressum</span>
            </v-col>
            <v-col @click="showDatenschutz = true" class="footer-col">
                <span>Datenschutz</span>
            </v-col>
        </v-footer>
    </div>
</template>

<script>
    import Datenschutz from "../components/footer/Datenschutz.vue"

    import auth from "../components/helper/auth"
    export default {
        name: "Login",

        components: {
            Datenschutz
        },

        data: () => ({
            loading: false,
            showAGB: false,
            showDatenschutz: false,
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            email: "",
            pass: "",
            show_pw: false,
            dialog: false,
            resendEmailDialog: false,
            authError: false,
            verificationError: false,
            showSuccessMessage: false,
            showSsoButton: false,
            showSSOInfo: false,
            showNoUserFound: false,
            showResetPasswordMessage: false,
            showResendMessage: false,
            ssoChecked: false,
            info: {
                message: null,
                icon: null,
                color: null
            },
            showInfo: false,
            socials: [
                {
                    href: "https://www.facebook.com/quaro.io",
                    icon: "mdi-facebook"
                },
                {
                    href: "https://www.instagram.com/quaro.io/",
                    icon: "mdi-instagram"
                },
                {
                    href: "https://de.linkedin.com/company/quaro-das-keyword-tool",
                    icon: "mdi-linkedin"
                }
            ]
        }),
        methods: {
            openAGB() {
                window.open(`https://quaro.io/agb/`, "_blank")
            },
            openImprint() {
                window.open(`https://quaro.io/impressum/`, "_blank")
            },
            createInfo(message, color, icon) {
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },
            resendVerificationMail(email) {
                if (email.length > 0) {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/user/verificate/mail", { email: email }, { headers: { Authorization: this.auth } })
                        .then((response) => {
                            console.log(response)
                            if (response.status == 200) {
                                this.resendEmailDialog = false
                                this.createInfo("Email has been resent. Please check your email account for our activation-link!", "green", "mdi-check-circle-outline")
                            } else {
                                this.showNoUserFound = true
                            }
                        })
                        .catch((error) => {
                            this.showNoUserFound = true
                        })
                }
            },
            checkEmail(email) {
                if (email.length > 0) {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/user/resetpassword", { email: email }, { headers: { Authorization: this.auth } })
                        .then((response) => {
                            console.log(response)
                            if (response.body.user_found) {
                                this.showResetPasswordMessage = true
                                this.dialog = false
                                try {
                                    this.$matomo.trackEvent("Login", "resetPassword", email, "")
                                } catch (error) {
                                    console.log(error)
                                    console.log("matomo blocked")
                                }
                            } else {
                                this.showNoUserFound = true
                                try {
                                    this.$matomo.trackEvent("Login", "resetPassword - no user found ", email, "")
                                } catch (error) {
                                    console.log(error)
                                    console.log("matomo blocked")
                                }
                            }
                        })
                        .catch((error) => {
                            // if(error.body.error.notallowed){
                            //   // TODO: Website redirect
                            //   this.notAllowed = true;
                            //   this.notAllowedMessage = "This email adress is not allowed to register. Please contact us: contact@searchinsights.de";
                            // }else{
                            //console.log(error)
                            // }
                        })
                }
            },
            gotoRegister() {
                try {
                    this.$matomo.trackEvent("Login", "gotoRegister", "", "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                this.$router.push({ path: "/register" })
            },
            redirectToSSO() {
                console.log(process.env.VUE_APP_SAMLURL)
                window.location = process.env.VUE_APP_SAMLURL + "/" + this.email.split("@")[1]
            },
            checkForSso(loginAfterChecking) {
                if (this.email.length > 0) {
                    this.ssoChecked = false
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/user/checkforsso", { mail: this.email }, { headers: { Authorization: this.auth } })
                        .then((response) => {
                            console.log(response)
                            if (response.body.sso_status == 1) {
                                this.showSSOInfo = true
                                // setTimeout(() => {
                                //   window.location = process.env.VUE_APP_SAMLURL;
                                // }, 5000);
                                this.showSsoButton = true
                                this.ssoChecked = true
                            } else {
                                this.ssoChecked = true
                                this.showSsoButton = false
                                if (loginAfterChecking) {
                                    this.login()
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            this.ssoChecked = true
                            this.showSsoButton = false
                            if (loginAfterChecking) {
                                this.login()
                            }
                        })
                }
            },
            login() {
                this.loading = true
                console.log(this.ssoChecked)
                if (this.ssoChecked) {
                    try {
                        this.$matomo.trackEvent("Login", "login", "", "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    auth.login(this.email, this.pass, (loggedIn) => {
                        console.log(loggedIn)
                        if (loggedIn.userfound) {
                            if (loggedIn.active) {
                                if (loggedIn.verification) {
                                    if (loggedIn.authentication) {
                                        this.$store.dispatch("FETCH_STARTUP_DATA", this.email)
                                        this.$router.replace(this.$route.query.redirect || "/")
                                        // this.loading = false
                                    } else {
                                        this.authError = true
                                        this.loading = false
                                    }
                                } else {
                                    this.verificationError = true
                                    this.showResendMessage = true
                                    this.loading = false
                                }
                            } else {
                                this.createInfo("Your account expired. Please contact contact@quaro.io for further assistance.", "#fcba03", "mdi-alert-circle-outline")
                            }
                        } else {
                            this.createInfo("We could not find a account for this email, please register first.", "#fcba03", "mdi-alert-circle-outline")
                            this.loading = false
                            // setTimeout(() => {
                            //   this.$router.push({ path: '/register'})
                            // }, 5000);
                        }
                    })
                } else {
                    this.loading = false
                    this.checkForSso(true)
                }
            },
            verificateUser() {
                this.$http
                    .get(process.env.VUE_APP_APIURL + "/user/auth/" + this.$route.params.auth, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        //console.log(response.body.success)asdasd
                        if (response.body.success) {
                            this.showSuccessMessage = true
                            try {
                                this.$matomo.trackEvent("Login", "verificateUser - verficated ", this.$route.params.auth, "")
                            } catch (error) {
                                console.log(error)
                                console.log("matomo blocked")
                            }
                        } else {
                            this.showNoUserFound = true
                            try {
                                this.$matomo.trackEvent("Login", "verificateUser - not verficated ", this.$route.params.auth, "")
                            } catch (error) {
                                console.log(error)
                                console.log("matomo blocked")
                            }
                        }
                    })
                    .catch((error) => {
                        // if(error.body.error.notallowed){
                        //   // TODO: Website redirect
                        //   this.notAllowed = true;
                        //   this.notAllowedMessage = "This email adress is not allowed to register. Please contact us: contact@searchinsights.de";
                        // }else{
                        //console.log(error)
                        // }
                    })
            }
        },
        mounted() {
            // //console.log(this.$route.params.auth)
            if (this.$route.params.auth) {
                this.verificateUser()
            }
        }
    }
</script>
<style lang="scss" scoped>
    #passwordDialog {
        box-shadow: none !important;
    }
    #whitelogo {
        padding-top: 20%;
    }
    #sidebarLogo {
        width: 300px;
        height: 170px;
        margin-top: -70px;
        border-radius: 12px;
        margin-bottom: 12px;
        background: linear-gradient(56deg, #019e86 0%, #009e85 59%, #0c8574 100%);
        // background: linear-gradient(56deg, rgba(28, 237, 178, 1) 0%, rgba(0, 159, 138, 1) 0%, rgb(28, 237, 178) 100%);
    }
    .registerLink {
        color: rgb(185, 185, 185);
        font-size: 0.9em;
        margin-bottom: -20px;
        padding-top: 25px;
        margin: 0%;
    }
    .registerLink:hover {
        cursor: pointer;
        color: #1cedb2;
    }
    .footer-col:hover {
        background-color: rgb(236, 236, 236);
        cursor: pointer;
    }
    .footer-col {
        display: flex;
        font-size: 0.8em;
        span {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .forgotpwlink {
        color: rgb(185, 185, 185);
        font-size: 0.9em;
        margin-bottom: -20px;
        margin: 0%;
    }
    .forgotpwlink:hover {
        cursor: pointer;
        color: #1cedb2;
    }
    .welcome {
        margin-top: -50px;
    }

    .text-center {
        padding-bottom: 0px;
    }
    #sendmailbtn {
        margin-right: 50%;
    }
</style>
