<template>
    <div>
        <!-- change name dialog -->
        <v-dialog v-model="addToProjectDialog" @click:outside="$emit('closeAddToProjectDialog')" max-width="450px">
            <v-card>
                <center>
                    <strong id="title" class="mb-5 d-inline-block">Add collection to project</strong>
                    <br />
                </center>

                <v-card-text class="pb-0">
                    <v-autocomplete label="project" filled rounded v-model="selectedAddToProjectItem.project" :items="availableProjectsSorted" clearable> </v-autocomplete>
                </v-card-text>

                <v-card-actions>
                    <v-btn rounded class="mx-auto" dark color="primary" v-on:click="addListToProject(selectedAddToProjectItem._id, selectedAddToProjectItem.project, selectedAddToProjectItem.index)"
                        >Save</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "AddToProjectDialog",
        props: {
            availableProjects: Array,
            addToProjectDialog: Boolean,
            selectedAddToProjectItem: Object,
            researches_old: Array
        },
        data() {
            return {
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
            }
        },
        computed: {
            availableProjectsSorted() {
                return this.availableProjects.sort()
            }
        },

        methods: {
            addListToProject(list_id, project, index) {
                if (this.researches_old[index].project) {
                    if (this.researches_old[index].project !== project) {
                        this.$http
                            .post(
                                process.env.VUE_APP_APIURL + "/projects/delete/list",
                                {
                                    list_id: list_id,
                                    project: this.researches_old[index].project,
                                    company_id: localStorage.company_id
                                },
                                { headers: { Authorization: this.auth } }
                            )
                            .then(function (response) {
                                // console.log(response)
                                if (project == undefined) this.$emit("renewData")
                                this.$emit("closeAddToProjectDialog")
                            })
                    }
                } else {
                    this.$emit("closeAddToProjectDialog")
                }
                if (project !== undefined) {
                    this.$http
                        .post(
                            process.env.VUE_APP_APIURL + "/projects/list/add",
                            {
                                list_id: list_id,
                                project: project,
                                company_id: localStorage.company_id
                            },
                            { headers: { Authorization: this.auth } }
                        )
                        .then(function (response) {
                            // console.log(response)
                            this.$emit("renewData")
                            this.$emit("closeAddToProjectDialog")
                        })
                }
            }
        },
        created() {}
    }
</script>

<style></style>
