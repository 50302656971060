<template>
    <v-fade-transition>
        <div class="cont" v-if="show">
            <div class="select_menu">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="redirect('/ideas')" icon v-bind="attrs" v-on="on" class="my-auto mx-1"><v-icon color="white">mdi-lightbulb-group-outline</v-icon></v-btn>
                    </template>
                    <span>Get Ideas</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="redirect('/suggests')" icon v-bind="attrs" v-on="on" class="my-auto mx-1"><v-icon color="white">mdi-head-lightbulb</v-icon></v-btn>
                    </template>
                    <span>Get Suggest</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="redirect('/searchvolume')" icon v-bind="attrs" v-on="on" class="my-auto mx-1"><v-icon color="white">mdi-google</v-icon></v-btn>
                    </template>
                    <span>Get Search Volume</span>
                </v-tooltip>
                <!-- <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="redirect('/searchvolumehistoric')" icon v-bind="attrs" v-on="on" class="my-auto mx-1"><v-icon color="white">mdi-history</v-icon></v-btn>
                    </template>
                    <span>Get Historical Search Volume</span>
                </v-tooltip> -->
            </div>
        </div>
    </v-fade-transition>
</template>

<script>
    export default {
        name: "SelectMenu",

        props: {
            show: Boolean,
            keywords: Array
        },
        data: () => ({}),
        computed: {
            query_keywords() {
                return this.keywords.map((keyword) => keyword.keyword)
            }
        },
        methods: {
            redirect(path) {
                console.log(this.$route.path)
                if (path == "/ideas") {
                    if (this.query_keywords.length > 20) {
                        this.$emit("createNotification", "Please select max 20 keywords for this function!", "mdi-alert-circle-outline", "#FCBA03")
                        return
                    }
                } else if (path == "/suggests") {
                    if (this.query_keywords.length > 1) {
                        this.$emit("createNotification", "Please select only one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                        return
                    }
                } else if (path == "/searchvolume") {
                    if (this.query_keywords.length > 700) {
                        this.$emit("createNotification", "Please select max 700 keywords for this function!", "mdi-alert-circle-outline", "#FCBA03")
                        return
                    }
                } else if (path == "/searchvolumehistoric") {
                    if (this.query_keywords.length > 700) {
                        this.$emit("createNotification", "Please select max 700 keywords for this function!", "mdi-alert-circle-outline", "#FCBA03")
                        return
                    }
                }
                this.$store.dispatch("SET_SEND_TO_KEYWORDS", this.query_keywords)
                if (path == this.$route.path) {
                    this.$emit("startFetchWithSendToKeywords")
                } else {
                    this.$router.push({ path: path })
                }
            },
            download() {}
        }
    }
</script>
<style scoped>
    .cont {
        right: calc(50% - 103px);
        bottom: 50px;
        display: flex;
        position: fixed;
        z-index: 4;
        margin-left: auto;
        margin-right: auto;
    }
    .select_menu {
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        border-radius: 10px;
        max-width: 300px;
        height: 45px;
        background-color: var(--v-primary-base);
        /* box-shadow: inset 0 0 10px #007045; */
        /* -moz-box-shadow:    3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;  */
        box-shadow: 3px 3px 10px rgb(148, 148, 148);
    }
</style>
