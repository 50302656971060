<template>
    <div id="contentcontainter">
        <div :class="user_settings.advancedMode ? 'advanced-mode' : 'standard-mode'">
            <v-snackbar top v-model="notification.show" :color="notification.color">
                <v-icon>{{ notification.icon }}</v-icon
                ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
            </v-snackbar>
            <SearchSettings :catsOfList="catsOfList" :seasonsOfList="seasonsOfList" :headers="headers.modifiedHeaders"></SearchSettings>

            <!-- Delete Dialog -->
            <v-dialog v-model="deleteQuestion" max-width="300">
                <v-card>
                    <v-card-title
                        style="
                             {
                                text-align: center;
                            }
                        ">
                        Are you sure?</v-card-title
                    >
                    <v-card-text class="text-center">
                        <v-btn class="mr-3" text @click="deleteQuestion = false"> Nevermind </v-btn>
                        <v-btn color="primary" text @click="deleteKeywords(selected)"> Yes </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-slide-x-transition hide-on-leave>
                <!-- advanced mode  -->
                <div v-if="user_settings.advancedMode">
                    <div v-if="!loaded">
                        <!-- startview -->
                        <v-row>
                            <v-col lg="9" md="10" sm="12" class="mx-auto px-0">
                                <v-card class="mb-2 marginhelper-suggest rounded_card quaroshadow">
                                    <v-tabs
                                        v-model="activeTab"
                                        background-color="primary"
                                        style="border-radius: 12px 12px 0 0 !important; padding-bottom: 15px !important"
                                        class="suggestabs rounded_card"
                                        centered
                                        dark
                                        rounded
                                        icons-and-text>
                                        <!-- TAB SLIDER HEADS -->
                                        <v-tabs-slider color="white"></v-tabs-slider>
                                        <v-tab v-for="(plattform, i) in plattforms" :key="i" :disabled="plattform.name == 'Instagram'" v-bind:href="plattform.id">
                                            <p>{{ plattform.name }}</p>
                                            <v-divider></v-divider>
                                            <v-icon>{{ plattform.icon }}</v-icon>
                                        </v-tab>

                                        <!-- #1 GOOGLE -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="1" :value="'tab-1'">
                                            <v-card class="rounded_card bottomrounded" flat>
                                                <v-card-text class="bottomrounded" style="padding-bottom: 5px">
                                                    <v-row class="pl-5 pr-5 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <!-- <v-select
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                top
                                                                filled
                                                                rounded
                                                                attach
                                                                required>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1">
                                                            <v-btn id="sendBtn" color="primary" elevation="0" rounded :disabled="btndisabled" v-on:click="startGoogleFetch" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1">
                                                            <v-btn id="sendBtn" color="primary" elevation="0" rounded v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>

                                        <!-- #2 W-FRAGEN -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="2" :value="'tab-2'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <!-- <v-row class="pl-2 pr-2 pt-5">
                                                        <v-col md="6" xl="6" sm="12" xs="12">
                                                            <v-select
                                                                v-model="selectedQuestion"
                                                                :items="questions"
                                                                label="Question Prefixes"
                                                                multiple
                                                                chips
                                                                rounded
                                                                filled
                                                                deletable-chips
                                                                item-text="question"
                                                                item-value="value"
                                                                return-object
                                                                attach></v-select>
                                                        </v-col>
                                                    </v-row> -->
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                rounded
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleQuestionFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <!-- <v-select
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                top
                                                                rounded
                                                                filled
                                                                attach>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" :disabled="btndisabled" class="white-text" color="primary" v-on:click="startGoogleQuestionFetch"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" class="white-text" color="primary" v-on:click="initStopSuggests"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #3 GOOGLE IMAGES -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="3" :value="'tab-3'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleImagesFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <!-- <v-select
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                top
                                                                filled
                                                                rounded
                                                                attach>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleImagesFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #4 AMAZON -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="4" :value="'tab-4'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="3" md="6" sm="6" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startAmazonFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="3" md="6" sm="6">
                                                            <v-select
                                                                v-model="selectedMarketplace"
                                                                :items="amzMarketItems"
                                                                item-text="market"
                                                                item-value="market"
                                                                label="Marketplace"
                                                                return-object
                                                                attach
                                                                filled
                                                                rounded></v-select>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" md="12">
                                                            <!-- <v-select
                                                                hide-details
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                top
                                                                filled
                                                                rounded
                                                                attach>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startAmazonFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #5 EBAY -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="5" :value="'tab-5'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="3" md="6" sm="6" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startEbayFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="3" md="6" sm="6">
                                                            <v-select
                                                                v-model="selectedShop"
                                                                :items="ebayShops"
                                                                item-text="name"
                                                                item-value="value"
                                                                label="Marketplace"
                                                                return-object
                                                                attach
                                                                rounded
                                                                filled></v-select>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" md="12">
                                                            <!-- <v-select
                                                                hide-details
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                top
                                                                filled
                                                                rounded
                                                                attach>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <!-- <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startEbayFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <v-select
                                                                v-model="selectedShop"
                                                                :items="ebayShops"
                                                                item-text="name"
                                                                item-value="value"
                                                                label="Shop"
                                                                prepend-icon="mdi-web"
                                                                return-object
                                                                attach
                                                                rounded
                                                                filled></v-select>
                                                        </v-col> -->
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startEbayFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #6 GOOGLE SHOPPING -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="6" :value="'tab-6'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleShoppingFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <!-- <v-select
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                top
                                                                rounded
                                                                filled
                                                                attach>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleShoppingFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #7 YOUTUBE -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="7" :value="'tab-7'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startYoutubeFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <!-- <v-select
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                top
                                                                rounded
                                                                filled
                                                                attach>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startYoutubeFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #8 TIKTOK -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="8" :value="'tab-8'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startTiktokFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startTiktokFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- <v-tab-item class="rounded_card bottomrounded" :key="8" :value="'tab-8'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startInstagramFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startInstagramFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item> -->
                                        <!-- #9 GOOGLE NEWS -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="9" :value="'tab-9'">
                                            <v-card class="rounded_card" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleNewsFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <!-- <v-select
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                rounded
                                                                top
                                                                filled
                                                                attach>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleNewsFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #10 Bing -->
                                        <v-tab-item class="rounded_card bottomrounded" :key="10" :value="'tab-10'">
                                            <v-card flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                rounded
                                                                filled
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startBingFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xl="5" lg="7" md="7">
                                                            <!-- <v-select
                                                                v-model="selectedLocation"
                                                                :items="stateItems"
                                                                item-text="loc"
                                                                rounded
                                                                item-value="id"
                                                                label="Location"
                                                                return-object
                                                                top
                                                                filled
                                                                attach>
                                                            </v-select> -->
                                                            <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startBingFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <!-- <v-container fluid grid-list-xl id="kwpform">
                                    <v-layout align-center wrap >
                                    <v-flex xs12 md4 xl4>
                                    <v-text-field 
                                        class="kwtxtfield" 
                                        label="Insert your keyword here!"
                                        v-model="googleSearch"
                                        v-on:keyup.enter="startBingFetch"
                                        prepend-icon="mdi-magnify"
                                    ></v-text-field>
                                    </v-flex> 
                                    <v-flex xs12 sm4 class="bingform">
                                    <v-switch v-model="keepdata" :label="`keep keywords in list`" color="#42defa"></v-switch>
                                    </v-flex> 
                                    </v-layout>
                                </v-container> -->
                                                </v-card-text>
                                                <!-- <v-card-actions>
                                            <v-flex id="searchbutton">
                                    <v-btn color="#42defa" dark  v-on:click="startBingFetch" > <v-icon color="white">mdi-magnify</v-icon>Search</v-btn>  
                                </v-flex>
                                </v-card-actions> -->
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs>
                                    <!-- Spinner animation -->

                                    <div class="loaderwrap" v-if="loading">
                                        <v-progress-linear height="5" class="linearloader" rounded :value="progress" color="primary"> </v-progress-linear>
                                    </div>
                                </v-card> </v-col
                        ></v-row>
                    </div>
                    <v-row v-if="loaded && $vuetify.breakpoint.mdAndDown">
                        <v-col cols="12" lg="6">
                            <v-slide-x-transition>
                                <v-card class="mt-0" id="searchbar_adv" v-show="delay">
                                    <!-- after first search  -->
                                    <v-tabs v-model="activeTab" class="suggestabs nobottombr" fixed-tabs background-color="primary" dark icons dense>
                                        <!-- TAB SLIDER HEADS -->
                                        <v-tabs-slider color="white"></v-tabs-slider>
                                        <v-tab class="smalltab" v-for="(plattform, i) in plattforms" :key="i" :disabled="plattform.name == 'Instagram'" v-bind:href="plattform.id">
                                            <!-- <p>{{ plattform.name }}</p>
                                <v-divider></v-divider> -->
                                            <v-icon class="mx-0 px-0">{{ plattform.icon }}</v-icon>
                                        </v-tab>

                                        <!-- #1 GOOGLE -->
                                        <v-tab-item class="bottomrounded" :key="1" :value="'tab-1'">
                                            <v-card class="mt-0" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn id="sendBtn" color="primary" elevation="0" rounded :disabled="btndisabled" v-on:click="startGoogleFetch" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn id="sendBtn" color="primary" elevation="0" rounded v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0 pl-0">
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>

                                        <!-- #2 W-FRAGEN -->
                                        <v-tab-item class="bottomrounded" :key="2" :value="'tab-2'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                rounded
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                hide-details
                                                                filled
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleQuestionFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" :disabled="btndisabled" class="white-text" color="primary" v-on:click="startGoogleQuestionFetch"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" class="white-text" color="primary" v-on:click="initStopSuggests"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0 pl-0">
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #3 GOOGLE IMAGES -->
                                        <v-tab-item class="bottomrounded" :key="3" :value="'tab-3'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1"
                                                                hide-details
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleImagesFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleImagesFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #4 AMAZON -->
                                        <v-tab-item class="bottomrounded" :key="4" :value="'tab-4'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                hide-details
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startAmazonFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startAmazonFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <!-- <v-col md="4" xl="7" sm="12" xs="12" class="pb-0">
                                                <v-text-field
                                                    auto-grow
                                                    label="e.g. Backpack"
                                                    rows="1 "
                                                    filled
                                                    rounded
                                                    v-model="googleSearch"
                                                    v-on:keyup.enter="startAmazonFetch"
                                                    prepend-inner-icon="mdi-magnify"></v-text-field>
                                            </v-col>
                                            <v-col md="3" xl="2" sm="12" xs="12">
                                                <v-select
                                                    v-model="selectedLanguage"
                                                    :items="amzLanguages"
                                                    item-text="name"
                                                    item-value="amz"
                                                    label="Language"
                                                    return-object
                                                    attach
                                                    filled
                                                    rounded></v-select>
                                            </v-col>
                                            <v-col md="3" xl="2" sm="12" xs="12">
                                                <v-select
                                                    v-model="selectedMarketplace"
                                                    :items="amzMarketItems"
                                                    item-text="market"
                                                    item-value="value"
                                                    label="Marketplace"
                                                    return-object
                                                    attach
                                                    filled
                                                    rounded></v-select>
                                            </v-col>
                                            <v-col v-if="!btndisabled" lg="1" md="1" sm="12" xs="12" class="">
                                                <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startAmazonFetch" :disabled="btndisabled" class="white-text"
                                                    ><v-icon>mdi-send</v-icon></v-btn
                                                >
                                            </v-col>
                                            <v-col v-else lg="1" md="1" sm="12" xs="12" class="">
                                                <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                            </v-col> -->
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="4" class="pt-0">
                                                                <v-select
                                                                    v-model="selectedMarketplace"
                                                                    :items="amzMarketItems"
                                                                    item-text="market"
                                                                    item-value="market"
                                                                    label="Marketplace"
                                                                    hide-details
                                                                    return-object
                                                                    attach
                                                                    filled
                                                                    rounded></v-select>
                                                            </v-col>
                                                            <v-col cols="8" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #5 EBAY -->
                                        <v-tab-item class="bottomrounded" :key="5" :value="'tab-5'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <!-- <v-col xl="8" lg="6" md="5" class="pl-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startEbayFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col xl="3" lg="4" md="5" class="px-0">
                                                            <v-select
                                                                v-model="selectedShop"
                                                                :items="ebayShops"
                                                                item-text="name"
                                                                item-value="value"
                                                                label="Shop"
                                                                hide-details
                                                                return-object
                                                                attach
                                                                rounded
                                                                filled></v-select>
                                                        </v-col> -->
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                hide-details
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startEbayFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" xl="1" lg="2" md="2">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startEbayFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" lg="2" md="2">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="4" class="pt-0">
                                                                <v-select
                                                                    v-model="selectedShop"
                                                                    :items="ebayShops"
                                                                    item-text="name"
                                                                    item-value="value"
                                                                    label="Shop"
                                                                    hide-details
                                                                    return-object
                                                                    attach
                                                                    rounded
                                                                    filled></v-select>
                                                            </v-col>
                                                            <v-col cols="8" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #6 GOOGLE SHOPPING -->
                                        <v-tab-item class="bottomrounded" :key="6" :value="'tab-6'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleShoppingFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleShoppingFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #7 YOUTUBE -->
                                        <v-tab-item class="bottomrounded" :key="7" :value="'tab-7'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startYoutubeFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startYoutubeFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #8 TIKTOK -->
                                        <v-tab-item class="bottomrounded" :key="8" :value="'tab-8'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                hide-details
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startTiktokFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startTiktokFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                            <!-- <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col xl="11" sm="10" class="px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                hide-details
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startInstagramFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startInstagramFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card> -->
                                        </v-tab-item>
                                        <!-- #9 GOOGLE NEWS -->
                                        <v-tab-item class="bottomrounded" :key="9" :value="'tab-9'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleNewsFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleNewsFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #10 Bing -->
                                        <v-tab-item class="bottomrounded" :key="10" :value="'tab-10'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                rounded
                                                                hide-details
                                                                filled
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startBingFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startBingFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs>
                                    <!-- Spinner animation -->

                                    <div class="loaderwrap" v-if="loading">
                                        <v-progress-linear heigth="5" class="linearloader" rounded :value="progress" color="primary"> </v-progress-linear>
                                    </div>
                                </v-card>
                            </v-slide-x-transition>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" lg="6" class="pb-0 order-xs-last order-sm-last order-md-last">
                            <v-slide-x-transition>
                                <v-card class="mt-0" id="searchbar_adv" v-if="loaded && $vuetify.breakpoint.lgAndUp" v-show="delay">
                                    <!-- after first search  -->
                                    <v-tabs v-model="activeTab" class="suggestabs nobottombr" fixed-tabs background-color="primary" dark icons dense>
                                        <!-- TAB SLIDER HEADS -->
                                        <v-tabs-slider color="white"></v-tabs-slider>
                                        <v-tab class="smalltab" v-for="(plattform, i) in plattforms" :key="i" :disabled="plattform.name == 'Instagram'" v-bind:href="plattform.id">
                                            <!-- <p>{{ plattform.name }}</p>
                                <v-divider></v-divider> -->
                                            <v-icon class="mx-0 px-0">{{ plattform.icon }}</v-icon>
                                        </v-tab>

                                        <!-- #1 GOOGLE -->
                                        <v-tab-item class="bottomrounded" :key="1" :value="'tab-1'">
                                            <v-card class="mt-0" flat>
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn id="sendBtn" color="primary" elevation="0" rounded :disabled="btndisabled" v-on:click="startGoogleFetch" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn id="sendBtn" color="primary" elevation="0" rounded v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0 pl-0">
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>

                                        <!-- #2 W-FRAGEN -->
                                        <v-tab-item class="bottomrounded" :key="2" :value="'tab-2'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                rounded
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                hide-details
                                                                filled
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleQuestionFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" :disabled="btndisabled" class="white-text" color="primary" v-on:click="startGoogleQuestionFetch"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" class="white-text" color="primary" v-on:click="initStopSuggests"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0 pl-0">
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #3 GOOGLE IMAGES -->
                                        <v-tab-item class="bottomrounded" :key="3" :value="'tab-3'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1"
                                                                hide-details
                                                                filled
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleImagesFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleImagesFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #4 AMAZON -->
                                        <v-tab-item class="bottomrounded" :key="4" :value="'tab-4'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                hide-details
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startAmazonFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startAmazonFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <!-- <v-col md="4" xl="7" sm="12" xs="12" class="pb-0">
                                                <v-text-field
                                                    auto-grow
                                                    label="e.g. Backpack"
                                                    rows="1 "
                                                    filled
                                                    rounded
                                                    v-model="googleSearch"
                                                    v-on:keyup.enter="startAmazonFetch"
                                                    prepend-inner-icon="mdi-magnify"></v-text-field>
                                            </v-col>
                                            <v-col md="3" xl="2" sm="12" xs="12">
                                                <v-select
                                                    v-model="selectedLanguage"
                                                    :items="amzLanguages"
                                                    item-text="name"
                                                    item-value="amz"
                                                    label="Language"
                                                    return-object
                                                    attach
                                                    filled
                                                    rounded></v-select>
                                            </v-col>
                                            <v-col md="3" xl="2" sm="12" xs="12">
                                                <v-select
                                                    v-model="selectedMarketplace"
                                                    :items="amzMarketItems"
                                                    item-text="market"
                                                    item-value="value"
                                                    label="Marketplace"
                                                    return-object
                                                    attach
                                                    filled
                                                    rounded></v-select>
                                            </v-col>
                                            <v-col v-if="!btndisabled" lg="1" md="1" sm="12" xs="12" class="">
                                                <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startAmazonFetch" :disabled="btndisabled" class="white-text"
                                                    ><v-icon>mdi-send</v-icon></v-btn
                                                >
                                            </v-col>
                                            <v-col v-else lg="1" md="1" sm="12" xs="12" class="">
                                                <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                            </v-col> -->
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="4" class="pt-0">
                                                                <v-select
                                                                    v-model="selectedMarketplace"
                                                                    :items="amzMarketItems"
                                                                    item-text="market"
                                                                    item-value="market"
                                                                    label="Marketplace"
                                                                    hide-details
                                                                    return-object
                                                                    attach
                                                                    filled
                                                                    rounded></v-select>
                                                            </v-col>
                                                            <v-col cols="8" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #5 EBAY -->
                                        <v-tab-item class="bottomrounded" :key="5" :value="'tab-5'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <!-- <v-col xl="8" lg="6" md="5" class="pl-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startEbayFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col xl="3" lg="4" md="5" class="px-0">
                                                            <v-select
                                                                v-model="selectedShop"
                                                                :items="ebayShops"
                                                                item-text="name"
                                                                item-value="value"
                                                                label="Shop"
                                                                hide-details
                                                                return-object
                                                                attach
                                                                rounded
                                                                filled></v-select>
                                                        </v-col> -->
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                hide-details
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startEbayFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>
                                                        <v-col v-if="!btndisabled" xl="1" lg="2" md="2">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startEbayFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" lg="2" md="2">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="4" class="pt-0">
                                                                <v-select
                                                                    v-model="selectedShop"
                                                                    :items="ebayShops"
                                                                    item-text="name"
                                                                    item-value="value"
                                                                    label="Shop"
                                                                    hide-details
                                                                    return-object
                                                                    attach
                                                                    rounded
                                                                    filled></v-select>
                                                            </v-col>
                                                            <v-col cols="8" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #6 GOOGLE SHOPPING -->
                                        <v-tab-item class="bottomrounded" :key="6" :value="'tab-6'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleShoppingFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleShoppingFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #7 YOUTUBE -->
                                        <v-tab-item class="bottomrounded" :key="7" :value="'tab-7'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startYoutubeFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startYoutubeFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #8 TIKTOK -->
                                        <v-tab-item class="bottomrounded" :key="8" :value="'tab-8'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startTiktokFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startTiktokFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                            <!-- <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                        <v-col xl="11" sm="10" class="px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                rounded
                                                                hide-details
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startInstagramFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startInstagramFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card> -->
                                        </v-tab-item>
                                        <!-- #9 GOOGLE NEWS -->
                                        <v-tab-item class="bottomrounded" :key="9" :value="'tab-9'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                filled
                                                                hide-details
                                                                rounded
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startGoogleNewsFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleNewsFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- #10 Bing -->
                                        <v-tab-item class="bottomrounded" :key="10" :value="'tab-10'">
                                            <v-card flat class="mt-0">
                                                <v-card-text class="cardcontent">
                                                    <v-row class="pl-2 pr-2" id="searchRow">
                                                        <v-col xl="11" sm="10" class="pb-0 px-0">
                                                            <v-text-field
                                                                auto-grow
                                                                label="e.g. Backpack"
                                                                rows="1 "
                                                                rounded
                                                                hide-details
                                                                filled
                                                                v-model="googleSearch"
                                                                v-on:keyup.enter="startBingFetch"
                                                                prepend-inner-icon="mdi-magnify"></v-text-field>
                                                        </v-col>

                                                        <v-col v-if="!btndisabled" xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startBingFetch" :disabled="btndisabled" class="white-text"
                                                                ><v-icon>mdi-send</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                        <v-col v-else xl="1" sm="2" class="pb-0">
                                                            <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"
                                                                ><v-icon>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                    <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                                        <v-icon color="primary" small>mdi-cog</v-icon>
                                                        <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                                    </span>
                                                    <v-slide-y-transition>
                                                        <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                            <v-col cols="12" class="pt-0">
                                                                <!-- <v-select
                                                                    hide-details
                                                                    v-model="selectedLocation"
                                                                    :items="stateItems"
                                                                    item-text="loc"
                                                                    item-value="id"
                                                                    label="Location"
                                                                    return-object
                                                                    top
                                                                    filled
                                                                    rounded
                                                                    attach>
                                                                </v-select> -->
                                                                <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                            </v-col>
                                                        </v-row>
                                                    </v-slide-y-transition>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs>
                                    <!-- Spinner animation -->

                                    <div class="loaderwrap" v-if="loading">
                                        <v-progress-linear heigth="5" class="linearloader" rounded :value="progress" color="primary"> </v-progress-linear>
                                    </div>
                                </v-card>
                            </v-slide-x-transition>
                            <!-- Keyword Table -->
                            <v-row
                                ><v-col class="pb-0">
                                    <v-slide-x-transition>
                                        <KeywordTable
                                            ref="kw_table"
                                            v-if="showFirstHalf || loaded"
                                            v-show="delay"
                                            :keywords="keywords"
                                            :columnsDisabled="columnsDisabled"
                                            :headers="headers"
                                            :catsLoading="catsLoading"
                                            :cardData="carddata"
                                            :catsOfList="catsOfList"
                                            :media_counts="mediaCountsInData"
                                            :resize="resize_kwtable"
                                            mountedOn="Suggests"
                                            :new_data="new_data"
                                            :addToSerpDisabled="addToSerpDisabled"
                                            :siteKeywordsLocation="siteKeywordsLocation"
                                            @filterColumns="filterColumns"
                                            :serpsOfList="serpsOfList"
                                            @removeCat="removeCat"
                                            @fetchSERP="fetchSERP"
                                            @switchCat="switchCat"
                                            @addToChart="addToChart"
                                            @deleteKeywords="prepareDeleteKw"
                                            @filterForCat="filterForCat"
                                            @addCategories="addCategories"
                                            @createNotification="createNotification"
                                            @addCustomEntries="addCustomEntries"
                                            @filterForCustomDropdown="filterForCustomDropdown"
                                            @modifiedDropDownOptions="modifiedDropDownOptions"
                                            @changedHeaders="changedHeaders"
                                            @startFetchWithSendToKeywords="startFetchWithSendToKeywords"
                                            @changeRankingURL="addRankings"></KeywordTable>
                                    </v-slide-x-transition> </v-col
                            ></v-row>
                        </v-col>

                        <v-col cols="12" lg="6" class="order-xs-first order-sm-first order-md-first order-lg-last">
                            <v-row>
                                <v-col cols="12" class="py-0" id="linechart_col">
                                    <!-- line chart  -->
                                    <v-slide-x-transition>
                                        <KeywordLineChart
                                            id="line_chart"
                                            :dataChart="dataChart"
                                            :countGraph="countGraph"
                                            :countGraph2="countGraph2"
                                            :graphloaded="graphloaded"
                                            v-if="loaded"
                                            v-show="delay"
                                            @clearChart="clearChart"
                                            @rerender="rerenderChart">
                                        </KeywordLineChart>
                                    </v-slide-x-transition>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pb-0">
                                    <!-- SERP Preview -->
                                    <v-slide-x-transition>
                                        <serp-preview
                                            :searched="loaded"
                                            v-if="loaded"
                                            v-show="delay"
                                            :keyword="serpKeyword"
                                            :selectedLocation="siteKeywordsLocation"
                                            :reload="serpReload"
                                            :search_volume="preview_sv_data"
                                            @serpLoading="addToSerpDisabled = false"></serp-preview>
                                    </v-slide-x-transition>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-slide-x-transition>
            <v-slide-x-transition hide-on-leave>
                <!-- Standard Mode -->
                <div v-if="!user_settings.advancedMode">
                    <v-row>
                        <v-col lg="9" md="10" sm="12" class="mx-auto">
                            <v-card class="mb-2 rounded_card pb-5 quaroshadow">
                                <v-tabs
                                    v-model="activeTab"
                                    background-color="primary"
                                    style="border-radius: 12px 12px 0 0 !important"
                                    class="suggestabs rounded_card"
                                    centered
                                    dark
                                    rounded
                                    icons-and-text>
                                    <!-- TAB SLIDER HEADS -->
                                    <v-tabs-slider color="white"></v-tabs-slider>
                                    <v-tab v-for="(plattform, i) in plattforms" :disabled="plattform.name == 'Instagram'" :key="i" v-bind:href="plattform.id">
                                        <p>{{ plattform.name }}</p>
                                        <v-divider></v-divider>
                                        <v-icon>{{ plattform.icon }}</v-icon>
                                    </v-tab>

                                    <!-- #1 GOOGLE -->
                                    <v-tab-item class="bottomrounded rounded_card" :key="1" :value="'tab-1'">
                                        <v-card flat class="rounded_card">
                                            <v-card-text class="rounded_card pb-0">
                                                <v-row class="pl-5 pr-5 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startGoogleFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <!-- <v-select
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            filled
                                                            rounded
                                                            attach
                                                            required>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" lg="1" md="1">
                                                        <v-btn id="sendBtn" color="primary" elevation="0" rounded :disabled="btndisabled" v-on:click="startGoogleFetch" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else lg="1" md="1">
                                                        <v-btn id="sendBtn" color="primary" elevation="0" rounded v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>

                                    <!-- #2 W-FRAGEN -->
                                    <v-tab-item class="bottomrounded" :key="2" :value="'tab-2'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <!-- <v-row class="pl-2 pr-2 pt-5">
                                                <v-col md="6" xl="6" sm="12" xs="12">
                                                    <v-select
                                                        v-model="selectedQuestion"
                                                        :items="questions"
                                                        label="Question Prefixes"
                                                        multiple
                                                        chips
                                                        rounded
                                                        filled
                                                        deletable-chips
                                                        item-text="question"
                                                        item-value="value"
                                                        return-object
                                                        attach></v-select>
                                                </v-col>
                                            </v-row> -->
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            rounded
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startGoogleQuestionFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <!-- <v-select
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            rounded
                                                            filled
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" :disabled="btndisabled" class="white-text" color="primary" v-on:click="startGoogleQuestionFetch"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" class="white-text" color="primary" v-on:click="initStopSuggests"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- #3 GOOGLE IMAGES -->
                                    <v-tab-item class="bottomrounded" :key="3" :value="'tab-3'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startGoogleImagesFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <!-- <v-select
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            filled
                                                            rounded
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleImagesFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- #4 AMAZON -->
                                    <v-tab-item class="bottomrounded" :key="4" :value="'tab-4'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="3" md="6" sm="6" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startAmazonFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" md="6" sm="6">
                                                        <v-select
                                                            v-model="selectedMarketplace"
                                                            :items="amzMarketItems"
                                                            item-text="market"
                                                            item-value="market"
                                                            label="Marketplace"
                                                            return-object
                                                            attach
                                                            filled
                                                            rounded></v-select>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" md="12">
                                                        <!-- <v-select
                                                            hide-details
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            filled
                                                            rounded
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startAmazonFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- #5 EBAY -->
                                    <v-tab-item class="bottomrounded" :key="5" :value="'tab-5'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="3" md="6" sm="6" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startEbayFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" md="6" sm="6">
                                                        <v-select
                                                            v-model="selectedShop"
                                                            :items="ebayShops"
                                                            item-text="name"
                                                            item-value="value"
                                                            label="Marketplace"
                                                            return-object
                                                            attach
                                                            rounded
                                                            filled></v-select>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" md="12">
                                                        <!-- <v-select
                                                            hide-details
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            filled
                                                            rounded
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <!-- <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startEbayFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <v-select
                                                            v-model="selectedShop"
                                                            :items="ebayShops"
                                                            item-text="name"
                                                            item-value="value"
                                                            label="Shop"
                                                            prepend-icon="mdi-web"
                                                            return-object
                                                            attach
                                                            rounded
                                                            filled></v-select>
                                                    </v-col> -->
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startEbayFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- #6 GOOGLE SHOPPING -->
                                    <v-tab-item class="bottomrounded" :key="6" :value="'tab-6'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startGoogleShoppingFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <!-- <v-select
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            rounded
                                                            filled
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleShoppingFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- #7 YOUTUBE -->
                                    <v-tab-item class="bottomrounded" :key="7" :value="'tab-7'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startYoutubeFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <!-- <v-select
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            rounded
                                                            filled
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startYoutubeFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- #8 tiktok -->
                                    <v-tab-item class="bottomrounded" :key="8" :value="'tab-8'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startTiktokFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <!-- <v-select
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            rounded
                                                            filled
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startTiktokFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                        <!-- <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startInstagramFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startInstagramFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text> 
                                        </v-card>-->
                                    </v-tab-item>
                                    <!-- #9 GOOGLE NEWS -->
                                    <v-tab-item class="bottomrounded" :key="9" :value="'tab-9'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            filled
                                                            rounded
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startGoogleNewsFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <!-- <v-select
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            rounded
                                                            top
                                                            filled
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startGoogleNewsFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- #10 Bing -->
                                    <v-tab-item class="bottomrounded" :key="10" :value="'tab-10'">
                                        <v-card flat>
                                            <v-card-text class="cardcontent">
                                                <v-row class="pl-2 pr-2 pt-5" id="searchRow">
                                                    <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                                        <v-text-field
                                                            auto-grow
                                                            label="e.g. Backpack"
                                                            rows="1 "
                                                            rounded
                                                            filled
                                                            v-model="googleSearch"
                                                            v-on:keyup.enter="startBingFetch"
                                                            prepend-inner-icon="mdi-magnify"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="5" lg="7" md="7">
                                                        <!-- <v-select
                                                            v-model="selectedLocation"
                                                            :items="stateItems"
                                                            item-text="loc"
                                                            rounded
                                                            item-value="id"
                                                            label="Location"
                                                            return-object
                                                            top
                                                            filled
                                                            attach>
                                                        </v-select> -->
                                                        <LocationSelection :suggest_tab="activeTab" @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                    </v-col>
                                                    <v-col v-if="!btndisabled" cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="startBingFetch" :disabled="btndisabled" class="white-text"
                                                            ><v-icon>mdi-send</v-icon></v-btn
                                                        >
                                                    </v-col>
                                                    <v-col v-else cols="12" xl="1" lg="1" md="1" class="">
                                                        <v-btn rounded id="sendBtn" elevation="0" color="primary" v-on:click="initStopSuggests" class="white-text"><v-icon>mdi-close</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                                <!-- <v-container fluid grid-list-xl id="kwpform">
                                    <v-layout align-center wrap >
                                    <v-flex xs12 md4 xl4>
                                    <v-text-field 
                                        class="kwtxtfield" 
                                        label="Insert your keyword here!"
                                        v-model="googleSearch"
                                        v-on:keyup.enter="startBingFetch"
                                        prepend-icon="mdi-magnify"
                                    ></v-text-field>
                                    </v-flex> 
                                    <v-flex xs12 sm4 class="bingform">
                                    <v-switch v-model="keepdata" :label="`keep keywords in list`" color="#42defa"></v-switch>
                                    </v-flex> 
                                    </v-layout>
                                </v-container> -->
                                            </v-card-text>
                                            <!-- <v-card-actions>
                                            <v-flex id="searchbutton">
                                    <v-btn color="#42defa" dark  v-on:click="startBingFetch" > <v-icon color="white">mdi-magnify</v-icon>Search</v-btn>  
                                </v-flex>
                                </v-card-actions> -->
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs>
                                <!-- Spinner animation -->

                                <div class="loaderwrap" v-if="loading">
                                    <v-progress-linear height="5" class="linearloader" rounded :value="progress" color="primary"> </v-progress-linear>
                                </div>
                                <!-- {{ progress }}% -->
                                <!-- <br> -->
                                <!-- Wir sind zwar verdammt schnell aber wir überprüfen auch wirklich alles für dich. <br>Bitte gedulde dich kurz, wir beeilen uns! -->
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- Line Chart -->
                    <v-row>
                        <v-col class="mb-5">
                            <v-slide-x-transition>
                                <KeywordLineChart
                                    class="mb-5"
                                    :dataChart="dataChart"
                                    :countGraph="countGraph"
                                    :countGraph2="countGraph2"
                                    :graphloaded="graphloaded"
                                    :loading="false"
                                    v-if="loaded"
                                    v-show="delay"
                                    :showAfterFirstHalf="showFirstHalf"
                                    @clearChart="clearChart"
                                    @rerender="rerenderChart">
                                </KeywordLineChart>
                            </v-slide-x-transition>
                        </v-col>
                    </v-row>

                    <!-- Data table  -->
                    <v-slide-x-transition>
                        <KeywordTable
                            ref="kw_table"
                            class="mb-5"
                            v-if="showFirstHalf || loaded"
                            v-show="delay"
                            :keywords="keywords"
                            :columnsDisabled="columnsDisabled"
                            :headers="headers"
                            :catsLoading="catsLoading"
                            :cardData="carddata"
                            :catsOfList="catsOfList"
                            :media_counts="mediaCountsInData"
                            :serpsOfList="serpsOfList"
                            mountedOn="Suggests"
                            :addToSerpDisabled="addToSerpDisabled"
                            :siteKeywordsLocation="siteKeywordsLocation"
                            :new_data="new_data"
                            @filterColumns="filterColumns"
                            @removeCat="removeCat"
                            @switchCat="switchCat"
                            @addToChart="addToChart"
                            @deleteKeywords="prepareDeleteKw"
                            @filterForCat="filterForCat"
                            @addCategories="addCategories"
                            @createNotification="createNotification"
                            @addCustomEntries="addCustomEntries"
                            @filterForCustomDropdown="filterForCustomDropdown"
                            @modifiedDropDownOptions="modifiedDropDownOptions"
                            @changedHeaders="changedHeaders"
                            @startFetchWithSendToKeywords="startFetchWithSendToKeywords"
                            @changeRankingURL="addRankings"></KeywordTable>
                    </v-slide-x-transition>
                </div>
            </v-slide-x-transition>
        </div>
        <!-- <div v-if="!delay">
            <div style="display: flex; margin-top: 15vh">
                <span class="circularprogress mx-auto">
                    <img id="loader" src="../assets/quaro-q.svg" />
                </span>
            </div>
        </div> -->
    </div>
</template>

<script>
    const GoogleApi = require("../components/SuggestPlattforms/GoogleApi")
    const AmazonApi = require("../components/SuggestPlattforms/AmazonApi")
    const EbayApi = require("../components/SuggestPlattforms/EbayApi")
    const BingApi = require("../components/SuggestPlattforms/BingApi")
    const InstagramApi = require("../components/SuggestPlattforms/InstagramApi")
    import { standardHeaders } from "../components/helper/global.js"
    import dataprocessing from "../components/helper/dataprocessing.js"
    import datasources from "../components/helper/datasources.js"
    import SerpPreview from "../components/SerpPreview"

    import KeywordLineChart from "../components/charts/KeywordLineChart"
    import KeywordTable from "../components/keyword-table/KeywordTable"
    // import locs from "../components/helper/locs"
    import SearchSettings from "../components/SearchSettings"
    import LocationSelection from "../components/LocationSelection"

    import axios from "axios"

    import _ from "lodash"
    import $ from "jquery"

    export default {
        name: "Suggest",
        components: {
            KeywordLineChart,
            KeywordTable,
            SerpPreview,
            SearchSettings,
            LocationSelection
        },
        data() {
            return {
                activeTab: "",
                preview_sv_data: {},
                resize_kwtable: 0,
                show_search_settings: false,
                serpKeyword: null,
                serpReload: 0,
                unfilteredKeywords: [],
                notification: { show: false },
                mediaCountsInData: false,
                columnsDisabled: {},
                cat_for_filter: null,
                catsOfList: [],
                serpsOfList: [],
                seasonsOfList: [],
                display: {},
                dialog: false,
                catData: [],
                selectedCats: [],
                showAllCats: false,
                catsLoading: true,
                apiSocketID: "",
                stopSuggests: false,
                sentToBackend: false,
                frontendstop: false,
                tab: null,
                btndisabled: false,
                showLocError: false,
                socket: {},
                //table variables
                tableBtnDisabled: true,
                // downloaded: false,
                deleteQuestion: false,
                selected: [],
                keywords: [],
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],
                pagination: { sortBy: "avgsv", descending: true },
                search: "",
                headers: {
                    options: {
                        sort: true,
                        active: true
                    },
                    standardHeaders: [],
                    modifiedHeaders: []
                },
                selectedPlattform: { name: "Google", icon: "mdi-google", id: "#tab-1" },
                //plattforms for tab heads
                plattforms: [
                    { name: "Google", icon: "mdi-google", id: "#tab-1" },
                    { name: "Questions", icon: "mdi-help", id: "#tab-2" },
                    { name: "Images", icon: "far fa-image", id: "#tab-3" },
                    { name: "Amazon", icon: "fab fa-amazon", id: "#tab-4" },
                    { name: "Ebay", icon: "fab fa-ebay", id: "#tab-5" },
                    { name: "Shopping", icon: "mdi-tag-outline", id: "#tab-6" },
                    { name: "Youtube", icon: "mdi-youtube", id: "#tab-7" },
                    // { name: "TikTok", icon: "fab fa-tiktok", id: "#tab-8" },
                    { name: "News", icon: "mdi-newspaper-variant", id: "#tab-9" },
                    { name: "Bing", icon: "mdi-microsoft-bing", id: "#tab-10" }
                ],
                showgraph: true,
                showFirstHalf: false,
                keywordsWithSV: [],
                allsuggests: [],
                // selectedLanguage: { name: "Germany", amz: "de_DE" },
                selectedLocation: {},
                siteKeywordsLocation: {},
                selectedMarketplace: { market: "Germany", value: "A1PA6795UKMFR9", url: "https://completion.amazon.de/api/2017/suggestions?", lang: "de_DE" },

                selectedCategory: { cat: "All Departments", value: "aps" },
                selectedShop: { name: "Germany", value: "77" },
                ebayShops: [
                    { name: "Australia", value: "15" },
                    { name: "Austria", value: "16" },
                    { name: "Belgium - Dutch", value: "123" },
                    { name: "Belgium - French", value: "23" },
                    // { name: "Canada", value: "2" },
                    // { name: "Canada - French", value: "210"},
                    { name: "France", value: "71" },
                    { name: "Germany", value: "77" },
                    // { name: "Hong Kong", value: "201" },
                    { name: "Ireland", value: "205" },
                    { name: "Italy", value: "101" },
                    { name: "Malaysia", value: "207" },
                    { name: "Motors", value: "100" },
                    { name: "Netherlands", value: "146" },
                    // { name: "Philippines", value: "211" },
                    { name: "Poland", value: "212" },
                    { name: "Singapore", value: "216" },
                    { name: "Spain", value: "186" },
                    { name: "Switzerland", value: "193" },
                    { name: "United Kingdom", value: "3" },
                    { name: "United States", value: "0" }
                ],

                // amzLanguages: [
                //     { name: "Germany", amz: "de_DE" },
                //     { name: "Great Britain", amz: "en_GB" },
                //     { name: "Italy", amz: "it_IT" },
                //     { name: "Poland", amz: "pl_PL" },
                //     { name: "Czechia", amz: "cs_CZ" },
                //     { name: "Brazil", amz: "pt_BR" },
                //     { name: "China", amz: "zh_CN" },
                //     { name: "Australia", amz: "en_AU" },
                //     { name: "France", amz: "fr_FR" },
                //     { name: "India", amz: "en_IN" },
                //     { name: "Japan", amz: "ja_JP" },
                //     { name: "Canada", amz: "en_CA" },
                //     { name: "Mexico", amz: "es_MX" },
                //     { name: "Netherlands", amz: "nl_NL" },
                //     { name: "Spain", amz: "es_ES" },
                //     { name: "United States", amz: "en_US" }
                // ],
                // Old Amz market values
                // amzMarketItems: [
                // { market: "Germany", value: "4" }
                // {market: "Italy", value: "35691"},
                // {market: "United Kingdom", value: "3"},
                // // {market: "Australia", value: "111172"}, AUSTRALIA SUGGEST API LINK?
                // {market: "Brazil", value: "526970"},
                // {market: "China", value: "3240"},
                // {market: "France", value: "5"},
                // // {market: "India", value: "44571"}, SUGGEST API LINK?
                // {market: "Japan", value: "6"},
                // {market: "Canada", value: "7"},
                // {market: "Mexico", value: "771770"},
                // {market: "Netherlands", value: "328451"},
                // {market: "Espana", value: "44551"},
                // {market: "Unites States", value: "1"},
                // ],
                amzMarketItems: [
                    { market: "Australia", value: "A39IBJ37TRP1C6", url: "https://completion.amazon.com.au/api/2017/suggestions?", lang: "en_AU" },
                    { market: "Belgium - Dutch", value: "AMEN7PMS3EDWL", url: "https://completion.amazon.com.be/api/2017/suggestions?", lang: "nl_BE" },
                    { market: "Belgium - French", value: "AMEN7PMS3EDWL", url: "https://completion.amazon.com.be/api/2017/suggestions?", lang: "fr_BE" },
                    // { market: "Brazil", value: "A2Q3Y263D00KWC", url: "https://completion.amazon.com.br/api/2017/suggestions?", lang: "pt_BR" },
                    // { market: "Canada", value: "A2EUQ1WTGCTBG2", url: "https://completion.amazon.ca/api/2017/suggestions?", lang: "en_CA" },
                    // { market: "China", value: "3240", url: "https://completion.amazon.cn/api/2017/suggestions?", lang: "zh_CN" },
                    // { market: "Egypt", value: "ARBP9OOSHTCHU", url: "https://completion.amazon.eg/api/2017/suggestions?", lang: "ar_EG" },
                    { market: "France", value: "A13V1IB3VIYZZH", url: "https://completion.amazon.fr/api/2017/suggestions?", lang: "fr_FR" },
                    { market: "Germany", value: "A1PA6795UKMFR9", url: "https://completion.amazon.de/api/2017/suggestions?", lang: "de_DE" },
                    // { market: "India", value: "A21TJRUUN4KGV", url: "https://completion.amazon.in/api/2017/suggestions?", lang: "hi_IN" },
                    { market: "Italy", value: "APJ6JRA9NG5V4", url: "https://completion.amazon.it/api/2017/suggestions?", lang: "it_IT" },
                    { market: "Japan", value: "A1VC38T7YXB528", url: "https://completion.amazon.co.jp/api/2017/suggestions?", lang: "ja_JP" },
                    { market: "Mexico", value: "A1AM78C64UM0Y8", url: "https://completion.amazon.com.mx/api/2017/suggestions?", lang: "de_DE" },
                    { market: "Netherlands", value: "A1805IZSGTT6HS", url: "https://completion.amazon.nl/api/2017/suggestions?", lang: "nl_NL" },
                    { market: "Poland", value: "A1C3SOZRARQ6R3", url: "https://completion.amazon.pl/api/2017/suggestions?", lang: "pl_PL" },
                    { market: "Saudi Arabia", value: "A17E79C6D8DWNP", url: "https://completion.amazon.sa/api/2017/suggestions?", lang: "ar_SA" },
                    { market: "Singapore", value: "A19VAU5U5O7RUS", url: "https://completion.amazon.sg/api/2017/suggestions?", lang: "zh_SG" },
                    { market: "Spain", value: "A1RKKUPIHCS9HS", url: "https://completion.amazon.es/api/2017/suggestions?", lang: "es_ES" },
                    { market: "Sweden", value: "A2NODRKZP88ZB9", url: "https://completion.amazon.se/api/2017/suggestions?", lang: "sv_SE" },
                    { market: "Turkey", value: "A33AVAJ2PDY3EV", url: "https://completion.amazon.com.tr/api/2017/suggestions?", lang: "tr_TR" },
                    { market: "UAE - Arabic", value: "A2VIGQ35RCS4UG", url: "https://completion.amazon.ae/api/2017/suggestions?", lang: "ar_AE" },
                    { market: "UAE - English", value: "A2VIGQ35RCS4UG", url: "https://completion.amazon.ae/api/2017/suggestions?", lang: "en_AE" },
                    { market: "United Kingdom", value: "A1F83G8C2ARO7P", url: "https://completion.amazon.co.uk/api/2017/suggestions?", lang: "en_GB" },
                    { market: "United States", value: "ATVPDKIKX0DER", url: "https://completion.amazon.com/api/2017/suggestions?", lang: "en_US" }
                ],
                amzCategories: [
                    { cat: "All Departments", value: "aps" }

                    // andere Ketgorien noch einfügen!
                ],
                // stateItems: locs.stateItems,
                langItems: [
                    { lang: "German", value: "1001", cc: "de", amz: "de_DE" },
                    { lang: "French", value: "1002", cc: "fr", amz: "fr_FR" },
                    { lang: "Spanish", value: "1003", cc: "es", amz: "es_ES" },
                    { lang: "Dutch", value: "1010", cc: "nl", amz: "nl_NL" },
                    { lang: "English", value: "1000", cc: "en", amz: "en_GB" },
                    { lang: "Italian", value: "1004", cc: "it", amz: "it_IT" },
                    { lang: "Polish", value: "1030", cc: "pl", amz: "pl_PL" }
                    // {lang: "Russian", value: "1031", cc: "ru" },
                    // {lang: "Croatian", value: "1039", cc: "hr" },
                    // {lang: "Czech", value: "1021", cc: "cz" , amz: 'cs_CZ'},
                    // {lang: "Slovak", value: "1033", cc: "sk" },
                    // {lang: "Slovenian", value: "1034",cc: "si" }
                ],
                googleSearch: "",

                carddata: {
                    title: "",
                    subTitle: "Analyze the customers search behaviour on various platforms",
                    searchPhrases: []
                },
                dataChart: {
                    labels: [],
                    datasets: []
                },
                countGraph: 0,
                countGraph2: 0,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                loaded: false,
                loading: false,
                graphloaded: false,
                keywords_data: [],
                inputlines: [],
                monthArray: ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

                keepdata: false,
                questions: [],
                selectedQuestion: [],
                progress: 0,
                realprogress: 0,
                media_counts: [],
                answerCounter: 0,
                get plan() {
                    if (localStorage.getItem("plan")) {
                        if (localStorage.getItem("plan") != "[object Object],[object Object]") {
                            // //console.log(localStorage.getItem('user'))
                            return localStorage.getItem("plan")
                        } else {
                            return
                        }
                    } else {
                        return
                    }
                },
                addToSerpDisabled: false,
                delay: true,
                new_data: 0
            }
        },
        watch: {
            selectedLocation: function (val) {
                localStorage.setItem("selectedLocation", JSON.stringify(val))
                // let stateIndex = this.stateItems.findIndex((x) => x.id == val.id)
                // this.questions = this.stateItems[stateIndex].questions
                // this.selectedQuestion = this.stateItems[stateIndex].questions
            },
            "$store.state.user_settings.advancedMode"(val) {
                this.keywords.map((x) => {
                    x.selected = false
                })
            }
        },
        computed: {
            decryptedPlan() {
                if (this.plan.length > 0) {
                    return this.CryptoJS.AES.decrypt(this.plan, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            user_settings: {
                get: function () {
                    return this.$store.state.user_settings
                },
                set: function (newValue) {
                    this.$store.dispatch("CHANGE_USER_SETTINGS", newValue)
                }
            }
            // computedQuestions() {
            //     console.log(this.questions)
            //     let questions = this.questions.map((x) => {
            //         return x.questions
            //     })
            //     console.log(questions)
            //     return questions
            // }
            //
        },

        methods: {
            changedHeaders() {
                console.log("header in parent set")
                this.headers = this.$store.state.header_items
            },
            modifiedDropDownOptions(option, headerValue) {
                this.keywords.map((keywordRow) => {
                    if (keywordRow.customs[headerValue].id == option.id) {
                        keywordRow.customs[headerValue] = option
                    }
                })
            },
            addCustomEntries(rows, selectedEntry, headerValue, isBackend, isArray) {
                console.log("add entries")
                if (!rows) return
                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    if (rowindex > -1) {
                        if (this.keywords[rowindex].customs === undefined) {
                            this.keywords[rowindex].customs = {}
                        }
                        this.keywords[rowindex].customs[headerValue] = isArray ? [...selectedEntry] : selectedEntry
                    }
                })
                // this.keywords.push({})
                // this.keywords.pop()
            },
            filterColumns() {
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
                console.log(" kw length: ", this.keywords.length)
                console.log("unfiltered kw length: ", this.unfilteredKeywords.length)
            },
            filterForCustomDropdown(initialObject, isArray, cat_for_filter) {
                var resultObject = Object.fromEntries(Object.entries(initialObject).filter(([_, v]) => v.length != 0))

                if (Object.keys(resultObject).length === 0 && cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.map((keyword) => {
                    if (Object.keys(resultObject).length > 0) {
                        Object.keys(resultObject).map((entry) => {
                            if (keyword.customs !== undefined && Object.keys(keyword.customs).includes(entry)) {
                                if (
                                    resultObject[entry]
                                        .map((obj) => {
                                            if (isArray) {
                                                return keyword.customs[entry].some((keyEntry) => keyEntry.text === obj.text)
                                            } else {
                                                return keyword.customs[entry].text === obj.text
                                            }
                                        })
                                        .includes(true)
                                ) {
                                    if (!this.keywords.some((k) => k === keyword)) {
                                        this.keywords.push(keyword)
                                    }
                                }
                            }
                        })
                    }
                    if (cat_for_filter.length > 0) {
                        if (keyword.cats) {
                            let catsofKeyword = keyword.cats[keyword.active_cat_index]
                            if (catsofKeyword) {
                                cat_for_filter.forEach((catforfilter) => {
                                    if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                        this.keywords.push(keyword)
                                    }
                                })
                            }
                        } else if (!keyword.cats) {
                            let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                            if (findemptycat) this.keywords.push(keyword)
                        }
                    }
                })
                console.log("filtered")
                // this.cardData.subtitle = this.keywords.length + " keywords"
            },
            createNotification(notification) {
                this.notification = this.$helpers.createNotification(notification.message, notification.icon, notification.color)
            },
            initStartSearch() {
                this.stopSuggests = false
                this.btndisabled = true
                this.siteKeywordsLocation = this.selectedLocation
                this.serpKeyword = this.googleSearch
                // this.serpReload++
            },

            // #1 GOOGLE
            startGoogleFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "google"

                        try {
                            this.$matomo.trackEvent("Suggests", "Google - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }
                        this.keepData()
                        var params = GoogleApi.buildParamsGoogle(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                        // this.graphloaded = false
                        this.loading = true
                        // // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }

                        // this.$socket.emit("getSuggests", suggestData)
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                        this.googleRequest(params.params.search, params.params.cp, params.params.ds, 0, params.params.client, [], "google")
                        // console.log(params.params)
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // #2 GOOGLE W-FRAGEN
            startGoogleQuestionFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.tab = "questions"

                        this.initStartSearch()

                        try {
                            this.$matomo.trackEvent("Suggests", "W-Questions - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }
                        this.keepData()
                        let questions = {}
                        // console.log(locs)
                        console.log(this.siteKeywordsLocation.cc)

                        questions.questionParams = this.selectedLocation.questionParams
                        questions.prepositionParams = this.selectedLocation.prepositionParams
                        questions.comparisonParams = this.selectedLocation.comparisonParams

                        var params = GoogleApi.buildParamsGoogleQuestionsV2(this.googleSearch, questions, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData)

                        this.googleRequest(params.params.search, params.params.cp, params.params.ds, 0, params.params.client, [], "questions")
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // #3 GOOGLE IMAGES
            startGoogleImagesFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "img"

                        try {
                            this.$matomo.trackEvent("Suggests", "Google Images - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        this.keepData()
                        var params = GoogleApi.buildParamsGoogleImages(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData)

                        this.googleRequestImg(params.params.search, params.params.cp, params.params.ds, 0, params.params.client, [], "images")
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // #4 AMAZON
            startAmazonFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "amz"

                        try {
                            this.$matomo.trackEvent("Suggests", "Amazon - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        this.keepData()
                        var params = AmazonApi.buildParamsAmazonV2(this.googleSearch, this.selectedCategory.value, this.selectedMarketplace)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData)
                        this.amzRequest(params.urls, 0, [])
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // #5 EBAY
            startEbayFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "ebay"

                        try {
                            this.$matomo.trackEvent("Suggests", "Ebay - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        this.keepData()
                        var params = EbayApi.buildParamsEbay(this.googleSearch, this.selectedShop.value)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData)
                        this.ebayRequest(params.params.search, 0, [])
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // #6 GOOGLE SHOPPING
            startGoogleShoppingFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "shopping"

                        try {
                            this.$matomo.trackEvent("Suggests", "Shopping - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        this.keepData()
                        var params = GoogleApi.buildParamsGoogleShopping(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData)
                        this.googleRequestImg(params.params.search, params.params.cp, params.params.ds, 0, params.params.client, [], "shopping")
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // #7 YOUTUBE
            startYoutubeFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "youtube"

                        try {
                            this.$matomo.trackEvent("Suggests", "Youtube - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        this.keepData()
                        var params = GoogleApi.buildParamsYoutube(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData)
                        this.YoutubeRequest(params.params.search, params.params.cp, params.params.ds, 0, params.params.client, [])
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // #8 TIKTOK
            startTiktokFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "insta"

                        try {
                            this.$matomo.trackEvent("Suggests", "Instagram - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        this.keepData()
                        this.media_counts = []
                        var params = InstagramApi.buildParamsTiktok(this.googleSearch)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false

                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData)
                        this.instagramRequest(params.params.search, 0, [], this.media_counts)
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // startInstagramFetch() {
            //     if (this.googleSearch.length > 0) {
            //         // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
            //         if (this.googleSearch.length < 81) {
            //             this.initStartSearch()
            //             this.tab = "insta"

            //             try {
            //                 this.$matomo.trackEvent("Suggests", "Instagram - Search", "keyword: " + this.googleSearch, "")
            //             } catch (error) {
            //                 console.log(error)
            //                 console.log("matomo blocked")
            //             }

            //             this.keepData()
            //             this.media_counts = []
            //             var params = InstagramApi.buildParamsInstagram(this.googleSearch)
            //             // this.graphloaded = false
            //             this.loading = true
            //             // this.loaded = false

            //             // let suggestData = {
            //             //   params: params.urls,
            //             //   keyword: params.keyword,
            //             //   language: this.selectedLanguage.value,
            //             //   location: this.selectedLocation.value,
            //             //   company: localStorage.company_id,
            //             //   user: localStorage.id
            //             // }
            //             // this.$socket.emit("getSuggests", suggestData)
            //             this.instagramRequest(params.params.search, 0, [], this.media_counts)
            //             // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
            //         } else {
            //             this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
            //             this.googleSearch = ""
            //         }
            //     }
            // },
            // #9 GOOGLE NEWS
            startGoogleNewsFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "news"

                        try {
                            this.$matomo.trackEvent("Suggests", "News - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        this.keepData()
                        var params = GoogleApi.buildParamsGoogleNews(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData)

                        this.googleRequestImg(params.params.search, params.params.cp, params.params.ds, 0, params.params.client, [], "news")
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },
            // #10 Bing
            startBingFetch() {
                if (this.googleSearch.length > 0) {
                    // var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.googleSearch);
                    if (this.googleSearch.length < 81) {
                        this.initStartSearch()
                        this.tab = "bing"

                        try {
                            this.$matomo.trackEvent("Suggests", "Bing - Search", "keyword: " + this.googleSearch, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }

                        this.keepData()
                        var params = BingApi.buildParamsBing(this.googleSearch)
                        // this.graphloaded = false
                        this.loading = true
                        // this.loaded = false
                        // let suggestData = {
                        //   params: params.urls,
                        //   keyword: params.keyword,
                        //   language: this.selectedLanguage.value,
                        //   location: this.selectedLocation.value,
                        //   company: localStorage.company_id,
                        //   user: localStorage.id
                        // }
                        // this.$socket.emit("getSuggests", suggestData);

                        this.bingRequest(params.params.search, 0, [])
                        // this.proxyRequest(params.urls, params.keyword, this.selectedLanguage.value, this.selectedLocation.value, localStorage.company_id, localStorage.id);
                    } else {
                        this.notification = this.$helpers.createNotification("Please enter one keyword only!", "mdi-alert-circle-outline", "#FCBA03")
                        this.googleSearch = ""
                    }
                }
            },

            // Request to Google API for GOOGLE / GOOGLE W-FRAGEN
            googleRequest: _.debounce(function (search, cp, ds, i, client, suggestArray, platform) {
                if (!this.frontendstop && !this.stopSuggests) {
                    // //console.log("da")
                    var addtoprogress = 98 / search.length
                    this.realprogress += addtoprogress
                    if (i % 2 == 0) {
                        this.progress = Math.floor(this.realprogress)
                    }
                    this.$jsonp(
                        "https://suggestqueries.google.com/complete/search?",
                        {
                            q: search[i],
                            client: "chrome",
                            hl: this.siteKeywordsLocation.cc,
                            gl: this.siteKeywordsLocation.cc,
                            // cp: cp[i],
                            ds: ds
                        },
                        5000
                    )
                        .then((json) => {
                            if (!this.stopSuggests) {
                                // Success.
                                for (var j = 0; j < json[1].length; j++) {
                                    suggestArray.push(json[1][j])
                                }
                            }
                        })
                        .catch((err) => {
                            console.log("error")
                            console.log(err)
                            if (err.status == 400 && !this.sentToBackend && !this.stopSuggests) {
                                this.frontendstop = true
                                var params = {}
                                if (platform == "google") {
                                    params = GoogleApi.buildParamsGoogle(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                                } else if (platform == "questions") {
                                    params = GoogleApi.buildParamsGoogleQuestionsV2(this.googleSearch, this.selectedQuestion, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                                }

                                let suggestData = {
                                    params: params.urls,
                                    keyword: params.keyword,
                                    language: this.siteKeywordsLocation.cc,
                                    location: this.siteKeywordsLocation.value,
                                    company: localStorage.company_id,
                                    user: localStorage.id
                                }
                                // this.$socket.emit("getSuggests", suggestData)
                                this.getSuggestsBackend(suggestData)
                                this.sentToBackend = true
                            }

                            // //console.log("Google Suggest Request failed!")
                            // Failed.
                        })
                    if (i < search.length - 1 && !this.frontendstop && !this.stopSuggests) {
                        this.googleRequest(search, cp, ds, i + 1, client, suggestArray, platform)
                    } else {
                        this.fetchKwData(suggestArray)
                    }
                }
            }, 200),

            // Request to Google API for GOOGLE IMAGES
            googleRequestImg: _.debounce(function (search, cp, ds, i, client, suggestArray, platform) {
                if (!this.frontendstop && !this.stopSuggests) {
                    var addtoprogress = 98 / search.length
                    this.realprogress += addtoprogress
                    if (i % 2 == 0) {
                        this.progress = Math.floor(this.realprogress)
                    }
                    this.$jsonp(
                        "https://suggestqueries.google.com/complete/search?",
                        {
                            q: search[i],
                            client: client,
                            hl: this.siteKeywordsLocation.cc,
                            gl: this.siteKeywordsLocation.cc,
                            cp: cp[i],
                            ds: ds
                        },
                        5000
                    )
                        .then((json) => {
                            if (!this.stopSuggests) {
                                var shortString = []
                                var endString = []
                                // Success.
                                if (platform == "shopping") {
                                    // for (var j=0; j<json[0].length;j++) {

                                    //   var suggestString = json[0][j][0];

                                    //   if (search[i]==suggestString){
                                    //     suggestArray.push(suggestString);
                                    //     // //console.log("1: "+suggestString)

                                    //   }else{
                                    //     shortString = suggestString.split(">");
                                    //     endString = shortString[1].split("<");
                                    //     var cleanedSuggestString = json[0]+endString[0]

                                    //     if(cleanedSuggestString.charAt(1)== "+"){
                                    //       suggestArray.push(endString[0]);
                                    //     }else{
                                    //       suggestArray.push(json[0]+endString[0]);

                                    //     }

                                    //   }

                                    // }
                                    for (var j = 0; j < json[0].length; j++) {
                                        var suggestString = json[0][j][0]
                                        var cleanedSuggestString = suggestString.replace(/(<([^>]+)>)/gi, "")
                                        suggestArray.push(cleanedSuggestString)
                                        // console.log(cleanedSuggestString)
                                    }
                                } else {
                                    for (var j = 0; j < json[1].length; j++) {
                                        var suggestString = json[1][j][0]

                                        if (search[i] == suggestString) {
                                            suggestArray.push(suggestString)
                                            // //console.log("1: "+suggestString)
                                        } else {
                                            shortString = suggestString.split(">")
                                            endString = shortString[1].split("<")
                                            var cleanedSuggestString = json[0] + endString[0]

                                            if (cleanedSuggestString.charAt(1) == "+") {
                                                suggestArray.push(endString[0])
                                            } else {
                                                suggestArray.push(json[0] + endString[0])
                                            }
                                        }
                                    }
                                }
                            }
                        })
                        .catch((err) => {
                            // //console.log("Google Suggest Request failed!" + err)
                            // Failed.
                            if (err.status == 400 && !this.sentToBackend && !this.stopSuggests) {
                                this.frontendstop = true
                                var params = {}
                                if (platform == "images") {
                                    params = GoogleApi.buildParamsGoogleImages(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                                } else if (platform == "shopping") {
                                    params = GoogleApi.buildParamsGoogleShopping(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                                } else if (platform == "news") {
                                    params = GoogleApi.buildParamsGoogleNews(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                                }
                                let suggestData = {
                                    params: params.urls,
                                    keyword: params.keyword,
                                    language: this.siteKeywordsLocation.cc,
                                    location: this.siteKeywordsLocation.value,
                                    company: localStorage.company_id,
                                    user: localStorage.id
                                }
                                // this.$socket.emit("getSuggests", suggestData)
                                this.getSuggestsBackend(suggestData)
                                this.sentToBackend = true
                            }
                        })
                    if (i < search.length - 1 && !this.frontendstop && !this.stopSuggests) {
                        this.googleRequestImg(search, cp, ds, i + 1, client, suggestArray, platform)
                    } else {
                        this.fetchKwData(suggestArray)
                    }
                }
            }, 200),

            // Request to Amazon API
            amzRequest: _.debounce(function (search, i, suggestArray) {
                if (!this.stopSuggests) {
                    this.frontendstop = true
                    var params = AmazonApi.buildParamsAmazonV2(this.googleSearch, this.selectedCategory.value, this.selectedMarketplace)
                    let suggestData = {
                        params: params.urls,
                        keyword: params.keyword,
                        language: this.siteKeywordsLocation.cc,
                        location: this.siteKeywordsLocation.value,
                        company: localStorage.company_id,
                        user: localStorage.id
                    }
                    this.getSuggestsBackend(suggestData)
                    this.sentToBackend = true
                    // var usApi = "https://completion.amazon.com/search/complete?"
                    // var euApi = "https://completion.amazon.co.uk/search/complete?"
                    // var jpApi = "https://completion.amazon.co.jp/search/complete?"
                    // var cnApi = "https://completion.amazon.cn/search/complete?"
                    // var api

                    // if (
                    //     this.selectedMarketplace.market == "Brazil" ||
                    //     this.selectedMarketplace.market == "Canada" ||
                    //     this.selectedMarketplace.market == "Mexico" ||
                    //     this.selectedMarketplace.market == "United States"
                    // ) {
                    //     api = usApi
                    // } else if (this.selectedMarketplace.market == "Japan") {
                    //     api = jpApi
                    // } else if (this.selectedMarketplace.market == "China") {
                    //     api = cnApi
                    // } else {
                    //     api = euApi
                    // }
                    // var addtoprogress = 98 / search.length
                    // this.realprogress += addtoprogress
                    // if (i % 2 == 0) {
                    //     this.progress = Math.floor(this.realprogress)
                    // }

                    // this.$jsonp(
                    //     search[i].url,
                    //     // {
                    //     //     ["search-alias"]: this.selectedCategory.value,
                    //     //     l: this.selectedLanguage.amz,
                    //     //     mkt: this.selectedMarketplace.value,
                    //     //     q: search[i],
                    //     //     qs: qs[i],
                    //     //     client: "amazon-search-ui"
                    //     // },
                    //     5000
                    // )
                    //     .then((json) => {
                    //         console.log(json)
                    //         if (!this.stopSuggests) {
                    //             for (var j = 0; j < json[1].length; j++) {
                    //                 suggestArray.push(json[1][j])
                    //             }
                    //         }
                    //     })
                    //     .catch((err) => {
                    //         console.log(JSON.stringify(err))
                    //         // Failed.
                    //     })

                    // if (i < search.length - 1 && !this.stopSuggests) {
                    //     this.amzRequest(search, i + 1, suggestArray)
                    // } else {
                    //     this.fetchKwData(suggestArray)
                    // }
                }
            }, 150),

            // Request to Ebay API
            ebayRequest: _.debounce(function (search, i, suggestArray) {
                if (!this.stopSuggests) {
                    this.frontendstop = true
                    var params = EbayApi.buildParamsEbay(this.googleSearch, this.selectedShop.value)
                    let suggestData = {
                        params: params.urls,
                        keyword: params.keyword,
                        language: this.siteKeywordsLocation.cc,
                        location: this.siteKeywordsLocation.value,
                        company: localStorage.company_id,
                        user: localStorage.id
                    }
                    // this.$socket.emit("getSuggests", suggestData)
                    this.getSuggestsBackend(suggestData)
                    this.sentToBackend = true
                    // var addtoprogress = 98 / search.length
                    // this.realprogress += addtoprogress
                    // if (i % 2 == 0) {
                    //     this.progress = Math.floor(this.realprogress)
                    // }
                    // this.$jsonp("https://autosug.ebaystatic.com/autosug?", { kwd: search[i], sId: this.selectedShop.value }, 5000)
                    //     .then((json) => {
                    //         if (!this.stopSuggests) {
                    //             // Success.
                    //             if (json.res) {
                    //                 for (var j = 0; j < json.res.sug.length; j++) {
                    //                     suggestArray.push(json.res.sug[j])
                    //                 }
                    //             }
                    //         }
                    //     })
                    //     .catch((err) => {
                    //         console.log(err)
                    //         // Failed.
                    //     })
                    // if (i < search.length - 1 && !this.stopSuggests) {
                    //     this.ebayRequest(search, i + 1, suggestArray)
                    // } else {
                    //     this.fetchKwData(suggestArray)
                    // }
                }
            }, 150),

            // Request to Google API for Youtube Suggests
            YoutubeRequest: _.debounce(function (search, cp, ds, i, client, suggestArray) {
                if (!this.frontendstop && !this.stopSuggests) {
                    var addtoprogress = 98 / search.length
                    this.realprogress += addtoprogress
                    if (i % 2 == 0) {
                        this.progress = Math.floor(this.realprogress)
                    }
                    this.$jsonp(
                        "https://suggestqueries.google.com/complete/search?",
                        {
                            q: search[i],
                            client: client,
                            hl: this.siteKeywordsLocation.cc,
                            gl: this.siteKeywordsLocation.cc,
                            cp: cp[i],
                            ds: ds
                        },
                        5000
                    )
                        .then((json) => {
                            if (!this.stopSuggests) {
                                // Success.
                                for (var j = 0; j < json[1].length; j++) {
                                    suggestArray.push(json[1][j][0])
                                }
                            }
                        })
                        .catch((err) => {
                            // //console.log("Google Suggest Request failed!")
                            // Failed.
                            if (err.status == 400 && !this.sentToBackend && !this.stopSuggests) {
                                this.frontendstop = true
                                var params = GoogleApi.buildParamsYoutube(this.googleSearch, this.siteKeywordsLocation.cc, this.siteKeywordsLocation.cc)
                                let suggestData = {
                                    params: params.urls,
                                    keyword: params.keyword,
                                    language: this.siteKeywordsLocation.cc,
                                    location: this.siteKeywordsLocation.value,
                                    company: localStorage.company_id,
                                    user: localStorage.id
                                }

                                // this.$socket.emit("getSuggests", suggestData)
                                this.getSuggestsBackend(suggestData)
                                this.sentToBackend = true
                            }
                        })
                    if (i < search.length - 1 && !this.frontendstop && !this.stopSuggests) {
                        this.YoutubeRequest(search, cp, ds, i + 1, client, suggestArray)
                    } else {
                        this.fetchKwData(suggestArray)
                    }
                }
            }, 200),

            // Request to Instagram Suggests
            instagramRequest: _.debounce(function (search, i, suggestArray, media_counts) {
                console.log(this.frontendstop)
                if (!this.frontendstop && !this.stopSuggests) {
                    var addtoprogress = 98 / search.length
                    this.realprogress += addtoprogress
                    if (i % 2 == 0) {
                        this.progress = Math.floor(this.realprogress)
                    }

                    this.$http.get("https://www.instagram.com/web/search/topsearch/?context=blended&query=" + search[i]).then(
                        function (response) {
                            if (response.body.hashtags && !this.stopSuggests) {
                                for (var j = 0; j < response.body.hashtags.length; j++) {
                                    suggestArray.push(response.body.hashtags[j].hashtag.name)
                                    this.media_counts.push({
                                        media_count: response.body.hashtags[j].hashtag.media_count,
                                        keyword: response.body.hashtags[j].hashtag.name
                                    })
                                }
                            } else {
                                if (!this.sentToBackend && !this.stopSuggests) {
                                    this.frontendstop = true
                                    var params = InstagramApi.buildParamsInstagram(this.googleSearch)
                                    let suggestData = {
                                        params: params.urls,
                                        keyword: params.keyword,
                                        language: this.siteKeywordsLocation.cc,
                                        location: this.siteKeywordsLocation.value,
                                        company: localStorage.company_id,
                                        user: localStorage.id
                                    }
                                    // this.$socket.emit("getSuggests", suggestData)
                                    this.getSuggestsBackend(suggestData)
                                    this.sentToBackend = true
                                }
                            }
                        },
                        (error) => {
                            if (!this.sentToBackend && !this.stopSuggests) {
                                this.frontendstop = true
                                var params = InstagramApi.buildParamsInstagram(this.googleSearch)
                                let suggestData = {
                                    params: params.urls,
                                    keyword: params.keyword,
                                    language: this.siteKeywordsLocation.cc,
                                    location: this.siteKeywordsLocation.value,
                                    company: localStorage.company_id,
                                    user: localStorage.id
                                }
                                // this.$socket.emit("getSuggests", suggestData)
                                this.getSuggestsBackend(suggestData)
                                this.sentToBackend = true
                            }
                        }
                    )

                    if (i < search.length - 1 && !this.frontendstop && !this.stopSuggests) {
                        this.instagramRequest(search, i + 1, suggestArray, media_counts)
                    } else {
                        this.fetchKwData(suggestArray)
                    }
                }
            }, 200),

            // Request to Bing API
            bingRequest: _.debounce(function (search, i, suggestArray) {
                if (!this.stopSuggests) {
                    var addtoprogress = 98 / search.length
                    this.realprogress += addtoprogress
                    if (i % 2 == 0) {
                        this.progress = Math.floor(this.realprogress)
                    }
                    var url = "https://api.bing.com/osjson.aspx?JsonType=callback&JsonCallback=?"
                    $.getJSON(
                        url,
                        {
                            query: search[i]
                        },
                        function (data) {
                            if (!this.stopSuggests) {
                                //  //console.log(that.realprogress)
                                for (var j = 0; j < data[1].length; j++) {
                                    suggestArray.push(data[1][j])
                                }
                            }
                        }
                    )

                    if (i < search.length - 1 && !this.stopSuggests) {
                        this.bingRequest(search, i + 1, suggestArray)
                    } else {
                        // //console.log(suggestArray)
                        this.fetchKwData(suggestArray)
                    }
                }
            }, 100),

            getSuggestsBackend(data) {
                // this.progress = 99;
                axios({
                    method: "post",
                    url: process.env.VUE_APP_APIURL + "/research/proxyRequest",
                    data: {
                        params: data.params,
                        keyword: data.keyword,
                        language: data.language,
                        location: data.location,
                        company: data.company,
                        user: data.user,
                        socket_id: this.$socket.id
                    },
                    headers: {
                        Authorization: this.auth
                    }
                })
                    .then((response) => {
                        if (response.data.error == "no_credits_left") {
                            this.notification = this.$helpers.createNotification("You dont have any credits left. Please contact the support via chat or email!", "mdi-alert-circle-outline", "#FCBA03")
                            this.endloading()
                        }
                        this.apiSocketID = response.data.apiSocketID

                        // this.endloading();
                    })
                    .catch((error) => {
                        // handle error
                        // //console.log("Errorround: "+ errorrounds)
                        // //console.log(error);
                    })
                    .then(function () {
                        // always executed
                    })
            },

            // sendpackets(data, last) {
            //     let p = new Promise((resolve, reject) => {
            //         const moment = require("moment")
            //         const dateTime = moment().format("YYYY-MM-DD")
            //         this.$http
            //             .post(
            //                 process.env.VUE_APP_APIURL + process.env.VUE_APP_DATASOURCE,
            //                 {
            //                     keywords: data,
            //                     language: this.selectedLocation.cc,
            //                     location: this.selectedLocation.value
            //                 },
            //                 { headers: { Authorization: this.auth } }
            //             )
            //             .then(
            //                 (response) => {
            //                     for (let index = 0; index < response.body.totalNumEntries; index++) {
            //                         let entry = response.body.entries[index].data[1].value
            //                         let annual = 0
            //                         for (let sumindex = 0; sumindex < 12; sumindex++) {
            //                             annual += parseInt(entry.value[sumindex].count) || 0
            //                         }
            //                         let row = {
            //                             keyword: response.body.entries[index].data[0].value.value,
            //                             annualsv: annual,
            //                             avgsv: parseInt(annual / 12),
            //                             search_volumes: [],
            //                             [this.monthArray[entry.value[11].month]]: parseInt(entry.value[11].count) || 0,
            //                             [this.monthArray[entry.value[10].month]]: parseInt(entry.value[10].count) || 0,
            //                             [this.monthArray[entry.value[9].month]]: parseInt(entry.value[9].count) || 0,
            //                             [this.monthArray[entry.value[8].month]]: parseInt(entry.value[8].count) || 0,
            //                             [this.monthArray[entry.value[7].month]]: parseInt(entry.value[7].count) || 0,
            //                             [this.monthArray[entry.value[6].month]]: parseInt(entry.value[6].count) || 0,
            //                             [this.monthArray[entry.value[5].month]]: parseInt(entry.value[5].count) || 0,
            //                             [this.monthArray[entry.value[4].month]]: parseInt(entry.value[4].count) || 0,
            //                             [this.monthArray[entry.value[3].month]]: parseInt(entry.value[3].count) || 0,
            //                             [this.monthArray[entry.value[2].month]]: parseInt(entry.value[2].count) || 0,
            //                             [this.monthArray[entry.value[1].month]]: parseInt(entry.value[1].count) || 0,
            //                             [this.monthArray[entry.value[0].month]]: parseInt(entry.value[0].count) || 0,
            //                             svdate: dateTime
            //                         }
            //                         for (let monthCounter = 0; monthCounter < entry.value.length; monthCounter++) {
            //                             let volume = {
            //                                 count: parseInt(entry.value[monthCounter].count),
            //                                 year: entry.value[monthCounter].year,
            //                                 month: entry.value[monthCounter].month
            //                             }
            //                             row.search_volumes.push(volume)
            //                         }
            //                         this.keywords.push(row)
            //                         this.keywordsWithSV.push(response.body.entries[index].data[0].value.value)
            //                         if (index == response.body.totalNumEntries - 1 && last) {
            //                             // //console.log("letzter durchgang")
            //                             setTimeout(() => resolve("promise"), 1000)
            //                         }
            //                     }
            //                     this.generateVisuals(response)

            //                     this.carddata.subTitle = this.keywords.length + " keyword suggests found"
            //                     this.progress = 99
            //                     // if (last) {
            //                     //   //console.log("addzero ausgelöst");
            //                     //   setTimeout(() => this.endloading(), 1000);
            //                     // }
            //                 },
            //                 (response) => {
            //                     // //console.log(response);
            //                     // //console.log("komisch ausgelöst");
            //                     setTimeout(() => this.sendpackets(data, last), 3000)
            //                 }
            //             )
            //     })
            //     p.then((message) => {
            //         // //console.log(message)
            //         this.endloading()
            //     })
            // },
            // fetchKwData: _.debounce(function (data) {
            //     this.fetchKeywordData(data)
            // }, 2000),
            async fetchKwData(keywords) {
                try {
                    // Add serch phrase if not in keywords
                    let exists = keywords.find((keyword) => keyword == this.googleSearch)
                    if (!exists) keywords.push(this.googleSearch)

                    let userinfo = {
                        id: localStorage.id,
                        company_id: localStorage.company_id
                    }
                    keywords = this.$helpers.replaceSpecialCharacters(keywords)
                    // Make Fetched Keyword Unique
                    keywords = keywords.filter(function (elem, index, self) {
                        return index === self.indexOf(elem)
                    })
                    // this.allsuggests = keywords

                    keywords = this.$helpers.validateGoogleInput(keywords)
                    if (keywords.length > 1000) keywords.length = 1000
                    console.log("KW", keywords)

                    let keyworddata = keywords.map((keyword) => {
                        let obj = {}
                        obj.keyword = keyword
                        return obj
                    })
                    this.serpReload++

                    // let date = new Date()
                    // let year = date.getFullYear()
                    // year -= 4
                    // date.setFullYear(year)
                    // date = date.toISOString().split("T")[0]

                    let storedate = this.$store.state.actual_date
                    let month = storedate.split("/")[0]
                    let year = storedate.split("/")[storedate.split("/").length - 1]
                    if (month.length == 1) {
                        month = "0" + month
                    }
                    let date = year - 4 + "-" + month + "-01"
                    console.log(date)

                    let options = {
                        keywords: keywords,
                        // language_code: this.selectedLocation.cc,
                        location_code: this.selectedLocation.value,
                        date_from: date
                    }
                    let data = await datasources.fetchDFS(options, localStorage.company_id, localStorage.id, "adwords_searchvolume")
                    console.log(data)
                    this.keywords = await this.$helpers.formatDFSAdwordsKeywords(data.result, this.catData)
                    // let data = await dataprocessing.fetchDFSHistSV(keyworddata, this.selectedLocation, userinfo)
                    // console.log(data)
                    // this.keywords = await dataprocessing.formatGoogleResponse(data, this.catData)

                    console.log(this.keywords)
                    this.progress = 100
                    this.loading = false
                    this.carddata.title = this.keywords.length + " keywords found"

                    await keyworddata.map(async (entry) => {
                        let indexOfKeyword = this.keywords.findIndex((keywordrow) => keywordrow.keyword == entry.keyword)
                        if (indexOfKeyword == -1) {
                            entry = await this.$helpers.buildSvObject(entry, 48)
                            this.keywords.push(entry)
                        }
                    })
                    this.fetchDFSCategories()

                    // this.addEvSerpPreview()
                    // this.generateVisuals()
                    // this.getListCats()
                    // this.getListSerps()
                    // this.getListSeasons()
                    // console.log(this.keywords)
                    // this.endloading()

                    this.catsLoading = false
                } catch (error) {
                    console.log(error)
                    if (error == "no_credits_left") {
                        this.notification = this.$helpers.createNotification("You dont have any credits left. Please contact the support via chat or email!", "mdi-alert-circle-outline", "#FCBA03")
                    } else {
                        this.notification = this.$helpers.createNotification("Something went wrong. Please try again or contact the support via chat or email!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                    this.endloading()
                }
            },
            /**
             * fetch and add cats to keywords
             * gets called by child component KeywordTable.vue
             */
            async fetchDFSCategories(endloading = true) {
                try {
                    this.catsLoading = true
                    let userinfo = {
                        id: "additional-data",
                        company_id: 0
                    }
                    if (this.siteKeywordsLocation.ads_lang_code !== null)
                        await dataprocessing.fetchCategories(this.keywords, this.siteKeywordsLocation, userinfo, this.catData, this.catsLoading, this.keywords_ideas)
                    if (this.$store.state.active_filter.length) this.filterColumns()
                    this.addEvSerpPreview()
                    this.generateVisuals()
                    this.getListCats()
                    this.getListSerps()
                    this.getListSeasons()
                    // console.log(this.keywords)
                    if (endloading) {
                        this.endloading()
                        this.catsLoading = false
                    }
                } catch (error) {
                    console.log(error)
                    this.catsLoading = false
                }
            },
            addEvSerpPreview() {
                console.log(this.serpKeyword)
                console.log(this.keywords)
                let svValue = this.keywords.find((keywordrow) => keywordrow.keyword == this.serpKeyword)
                console.log(svValue)
                this.preview_sv_data = { keyword: this.serpKeyword, value: svValue ? svValue.avgsv : 0 }
            },
            // DEPRECATED
            // fetchKeywordData(data) {
            //     // if (!this.keepdata) {
            //     //   this.keywords = [];
            //     //   this.keywordsWithSV = [];
            //     //   this.selected = [];
            //     // }
            //     this.selected = []
            //     data = this.$helpers.replaceSpecialCharacters(data)
            //     console.log(data)
            //     // Make Fetched Keyword Unique
            //     var unique = data.filter(function (elem, index, self) {
            //         return index === self.indexOf(elem)
            //     })
            //     console.log(unique)
            //     // Make keywordsWithSV (= tablerows, but only the keywords) unique
            //     // var uniqueKeywordsWithSV = this.keywordsWithSV.filter(function(elem, index, self) {
            //     //   return index === self.indexOf(elem);
            //     // })

            //     // this.allsuggests = unique;

            //     // Filters every keyword from data which is already in tablerows
            //     var that = this
            //     var filteredArray = unique.filter(function (x) {
            //         return that.keywordsWithSV.indexOf(x) < 0
            //     })
            //     filteredArray = this.$helpers.validateGoogleInput(filteredArray)

            //     this.answerCounter = 0
            //     if (filteredArray.length > 0) {
            //         this.serpKeyword = this.googleSearch
            //         this.serpReload++
            //         let keywordArray = this.splitArray(filteredArray, 700)

            //         for (let splits = 0; splits < keywordArray.length; splits++) {
            //             if (splits === keywordArray.length - 1) {
            //                 this.getSearchVolume(keywordArray[splits], keywordArray.length, 5)
            //                 // //console.log("letzte")
            //             } else {
            //                 this.getSearchVolume(keywordArray[splits], keywordArray.length, 5)
            //                 // //console.log("nichtletzte")
            //             }
            //         }
            //     } else {
            //         // No new keywords found -> end loading and show error message
            //         this.loading = false
            //         // this.loaded = false
            //         // // this.graphloaded = false;
            //         this.notification = this.$helpers.createNotification("We could not find any keywords for your search!", "mdi-alert-circle-outline", "#FCBA03")
            //         this.progress = 0
            //         this.realprogress = 0

            //         this.btndisabled = false
            //         this.sentToBackend = false
            //         this.frontendstop = false
            //     }
            // },
            keepData() {
                if (!this.keepdata) {
                    this.carddata.subTitle = ""
                    // this.keywords = []
                    this.keywordsWithSV = []
                    this.selected = []
                    this.unfilteredKeywords = []
                }
            },
            initStopSuggests() {
                if (this.frontendstop) {
                    this.$socket.emit("stopSuggestsBackend", {
                        frontendSocketID: this.$socket.id,
                        backendSocketID: this.apiSocketID
                    })
                }
                this.stopSuggests = true
                this.loading = false
                // this.loaded = false
                this.progress = 0
                this.realprogress = 0
                this.graphloaded = false

                this.btndisabled = false
                this.sentToBackend = false
                this.frontendstop = false
            },
            catfrequencies(keywords) {
                let catFrequencies = []
                for (let index = 0; index < keywords.length; index++) {
                    for (let treeCounter = 0; treeCounter < keywords[index].cats.length; treeCounter++) {
                        let indexOfCat = this.getIndexOfCat(keywords[index].cats[treeCounter], catFrequencies)
                        if (indexOfCat > -1) {
                        } else {
                            catData[index].count = 1
                            catFrequencies.push
                        }
                    }
                }
            },
            startFetchWithSendToKeywords() {
                if (this.$store.state.send_to_keywords.length) {
                    this.keywords_data = this.$store.state.send_to_keywords.reduce((a, b) => a + "\n" + b)
                    // this.getKeywordData(this.keywords_data)
                    this.$store.dispatch("SET_SEND_TO_KEYWORDS", [])
                }
            },
            fixselectError() {
                if (this.cat_for_filter.length > 0) {
                    this.$refs.selectComponent.$el.querySelector(".v-select__selections input").style.width = "40px"
                } else {
                    this.$refs.selectComponent.$el.querySelector(".v-select__selections input").style.width = ""
                }
            },
            filterForCat(cat_for_filter) {
                if (cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.carddata.subTitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.forEach((keyword) => {
                    if (keyword.cats) {
                        let catsofKeyword = keyword.cats[keyword.active_cat_index]
                        cat_for_filter.forEach((catforfilter) => {
                            if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                this.keywords.push(keyword)
                            }
                        })
                    } else if (!keyword.cats) {
                        let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                        if (findemptycat) this.keywords.push(keyword)
                    }
                })
                this.carddata.subTitle = this.keywords.length + " keywords"
            },
            fetchAllCats() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        // console.log(cats)
                        this.catData = cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            fetchCategories(keywords) {
                console.log(keywords)
                this.catsLoading = true
                this.fetchAllCats()
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories/keywords", { company_id: localStorage.company_id, keywords: keywords }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        console.log(response)
                        let catData = response.body
                        let onlykeywords = keywords.map((x) => x.keyword)
                        for (let index = 0; index < catData.length; index++) {
                            let pos = onlykeywords.indexOf(catData[index].keyword)
                            this.keywords[pos].cats = this.sortCats(catData[index].categories)
                            if (this.keywords[pos].cats) {
                                this.keywords[pos].cats.forEach((cat) => {
                                    cat.active = false
                                })
                                this.keywords[pos].cats[0].active = true
                            }
                        }
                        this.catsLoading = false
                        this.getListCats()
                        this.getListSerps()
                        this.getListSeasons()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            getTree(category) {
                let tree = []
                tree[0] = category
                var findParent = (category) => {
                    let parent = this.catData.filter((x) => x.category_code == category.category_code_parent)
                    if (parent[0]) {
                        tree.unshift(parent[0])
                        if (parent[0].category_code_parent) {
                            findParent(parent[0])
                        }
                    }
                }
                if (category.category_code_parent) {
                    findParent(category)
                }
                return tree
            },
            sortCats(allCats) {
                if (!allCats) return null
                let sortedCats = []
                let catRoots = []
                let nulls = allCats.find((x) => null)
                allCats = allCats.filter((x) => x)
                let childCats = allCats.filter((x) => x.category_code_parent)
                for (let index = 0; index < allCats.length; index++) {
                    if (!allCats[index].category_code_parent) {
                        allCats[index].catlevel = 0
                        let rootArr = []
                        rootArr.push(allCats[index])
                        catRoots.push(rootArr)
                    }
                }
                var findChildren = (parent, catlevel) => {
                    // let parentCopy = parent
                    let childsOfRoot = childCats.filter((x) => x.category_code_parent == parent[catlevel].category_code)
                    catlevel++
                    if (childsOfRoot[0]) {
                        childsOfRoot[0].catlevel = catlevel
                        parent.push(childsOfRoot[0])
                        findChildren(parent, catlevel)
                        // if (childsOfRoot.length > 1) {
                        //     for (let index = 1; index < childsOfRoot.length; index++) {
                        //         let rootDouble = parentCopy
                        //         rootDouble.push(childsOfRoot[index])
                        //         catRoots.push(rootDouble)
                        //         findChildren(rootDouble, catlevel)
                        //     }
                        // }
                    } // else{
                    //     parent[catlevel].lastlvl = true
                    // }
                }
                for (let index = 0; index < catRoots.length; index++) {
                    findChildren(catRoots[index], 0)
                }
                catRoots.sort((a, b) => b.length - a.length)
                return catRoots
            },
            getIndexOfCat(cat, catArray) {
                for (let index = 0; index < catArray.length; index++) {
                    if (catArray[index].category_code == cat.category_code) {
                        return index
                    } else {
                        return -1
                    }
                }
                return -1
            },
            removeCat(row, cat) {
                // remove cat
                let rowindex = this.keywords.indexOf(row)
                let catindex = this.keywords[rowindex].cats.indexOf(cat)
                if (this.keywords[rowindex].cats.length < 2) {
                    this.keywords[rowindex].cats = null
                    this.rerenderKeywordTable()
                } else {
                    this.keywords[rowindex].cats.splice(catindex, 1)
                    this.rerenderKeywordTable()
                }
            },
            getListCats() {
                let catsOfList = []
                this.keywords.forEach((keyword) => {
                    let cats = keyword.cats
                    if (cats) {
                        if (cats[0]) {
                            if (cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1]) {
                                catsOfList.push(cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1])
                            }
                        }
                    }
                })

                catsOfList.sort((a, b) => a.category_name.localeCompare(b.category_name))
                catsOfList.unshift({ category_name: "-" })

                this.catsOfList = catsOfList
            },
            getListSerps() {
                let serpsOfList = []
                this.keywords.forEach((keyword) => {
                    let serps = keyword.serp_items
                    if (serps) {
                        serps.map((x) => {
                            serpsOfList.push(x)
                        })
                    }
                })
                serpsOfList = new Set(serpsOfList)
                serpsOfList = Array.from(serpsOfList)

                serpsOfList.sort((a, b) => a.value - b.value)
                serpsOfList.unshift({ value: "-", tooltip: "-:" })
                this.serpsOfList = serpsOfList
            },
            getListSeasons() {
                let seasonsOfList = []
                this.keywords.forEach((keyword) => {
                    if (keyword.season) {
                        seasonsOfList.push(keyword.season.season)
                    }
                })
                seasonsOfList = new Set(seasonsOfList)
                seasonsOfList = Array.from(seasonsOfList)
                console.log(seasonsOfList)

                seasonsOfList.sort()
                seasonsOfList.unshift("-")
                this.seasonsOfList = seasonsOfList
            },
            addCategories(rows, selectedCats) {
                //adds categories to a keyword
                if (!selectedCats[0]) return

                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    for (let index = 0; index < selectedCats.length; index++) {
                        let tree = this.getTree(selectedCats[index])

                        if (this.keywords[rowindex].cats) {
                            this.keywords[rowindex].cats.unshift(tree)
                        } else {
                            this.keywords[rowindex].cats = [tree]
                        }
                        console.log(row)
                        console.log(tree)
                    }
                })
                for (let index = 0; index < selectedCats.length; index++) {
                    let tree = this.getTree(selectedCats[index])
                    this.switchCat(rows, tree)
                }
                this.getListCats()
                this.rerenderKeywordTable()
            },
            rerenderKeywordTable() {
                this.$refs.kw_table.rerenderKeywordTable()
            },
            /**
             *  * sets/changes primary category for keyword(s)
             * @param {Array} rows keyword(s) to modify
             * @param {Object} cat category to set
             */
            switchCat(rows, cat) {
                // switch visible categories
                this.$helpers.switchCat(rows, cat, this.keywords)
                this.getListCats()
                this.rerenderKeywordTable()
            },
            addMediaCounts(media_counts) {
                console.log(media_counts)

                console.log("length: " + media_counts.length)
                // //console.log("kwlength: "+ this.keywords.length)
                //console.log(this.keywords)

                if (media_counts.length > 0) {
                    //console.log("mediacounts vorhanden")
                    for (let keywordCounter = 0; keywordCounter < this.keywords.length; keywordCounter++) {
                        for (let mediaCounter = 0; mediaCounter < media_counts.length; mediaCounter++) {
                            if (this.keywords[keywordCounter].keyword == media_counts[mediaCounter].keyword) {
                                this.keywords[keywordCounter].media_count = media_counts[mediaCounter].media_count
                                // //console.log(media_counts[mediaCounter].keyword + " - "+ media_counts[mediaCounter].media_count)
                            }
                        }
                    }
                    // this.headers.standardHeaders[1].align = "left"
                    //  this.headers.modifiedHeaders[1].align = "left"
                }
                // else {
                //     // //console.log("mediacounts leer")
                //     this.mediaCountsInData = false
                //     // this.headers.standardHeaders[1].align = " d-none"
                //     //                      this.headers.modifiedHeaders[1].align = " d-none"
                // }
                console.log(media_counts.length)
                let media_counts_in_keywords = false
                this.keywords.map((x) => {
                    if (x.media_count) media_counts_in_keywords = true
                })
                this.headers.standardHeaders.map((header) => {
                    if (header.value === "media_count") header.active = media_counts_in_keywords ? true : false
                    return header
                })
                if (this.headers.modifiedHeaders.length) {
                    this.headers.modifiedHeaders.map((header) => {
                        if (header.value === "media_count") header.active = media_counts_in_keywords ? true : false
                        return header
                    })
                }
                this.mediaCountsInData = media_counts_in_keywords
            },
            isJsonString(str) {
                try {
                    JSON.parse(str)
                    // //console.log(JSON.parse(str))
                } catch (e) {
                    return false
                }
                return true
            },
            // getSearchVolume(data, keywordArrayLength, errorrounds) {
            //     console.log("all suggests", data)
            //     if (errorrounds > 0 && !this.stopSuggests) {
            //         // this.progress = 99;
            //         axios({
            //             method: "post",
            //             url: process.env.VUE_APP_APIURL + "/v2/sources/dataforseo/historicalsv",
            //             data: {
            //                 keywords: data,
            //                 language: this.selectedLocation.cc,
            //                 location: this.selectedLocation.value,
            //                 company: localStorage.company_id,
            //                 user: localStorage.id
            //             },
            //             headers: {
            //                 Authorization: this.auth
            //             }
            //         })
            //             .then(async (response) => {
            //                 if (!this.stopSuggests) {
            //                     if (this.answerCounter == 0) {
            //                         this.keywords = []
            //                         this.keywordsWithSV = []
            //                         if (this.$refs.kw_table) this.$refs.kw_table.unselectAll()
            //                         console.log(this.media_counts.length)
            //                         this.headers.standardHeaders.map((header) => {
            //                             if (header.value === "media_count") header.active = this.media_counts.length ? true : false
            //                             return header
            //                         })
            //                         if (this.headers.modifiedHeaders.length) {
            //                             this.headers.modifiedHeaders.map((header) => {
            //                                 if (header.value === "media_count") header.active = this.media_counts.length ? true : false
            //                                 return header
            //                             })
            //                         }
            //                     }
            //                     console.log(response)
            //                     if (response.data.error == "no_credits_left") {
            //                         this.notification = this.$helpers.createNotification(
            //                             "You dont have any credits left. Please contact the support via chat or email!",
            //                             "mdi-alert-circle-outline",
            //                             "#FCBA03"
            //                         )
            //                         this.endloading()
            //                         return
            //                     }
            //                     // handle success
            //                     // //console.log(response);
            //                     // this.progress = 100;
            //                     this.answerCounter++
            //                     let additionaldata = await dataprocessing.extractAdditionalData(response.data, [])
            //                     console.log(additionaldata)
            //                     console.log("google response", response.data.entries)
            //                     for (let index = 0; index < response.data.totalNumEntries; index++) {
            //                         let entry = response.data.entries[index].data[1].value
            //                         let annual = 0
            //                         for (let sumindex = 0; sumindex < 12; sumindex++) {
            //                             annual += parseInt(entry.value[sumindex].count) || 0
            //                         }
            //                         let row = {
            //                             keyword: response.data.entries[index].data[0].value.value,
            //                             annualsv: annual,
            //                             avgsv: parseInt(annual / 12),
            //                             search_volumes: [],
            //                             dialog: false,
            //                             categories: response.data.entries[index].data[1].value.categories,
            //                             adwords_cpc_high: response.data.entries[index].data[2].value.value.high,
            //                             adwords_cpc: response.data.entries[index].data[2].value.value.high,
            //                             adwords_cpc_low: response.data.entries[index].data[2].value.value.low,
            //                             adwords_competition:
            //                                 response.data.entries[index].data[3].value && response.data.entries[index].data[3].value.value !== null
            //                                     ? response.data.entries[index].data[3].value.value.toFixed(0)
            //                                     : null,

            //                             active_cat_index: 0,
            //                             keyword_difficulty: additionaldata[additionaldata.findIndex((x) => x.keyword == response.data.entries[index].data[0].value.value)].keyword_difficulty,
            //                             serp_items: additionaldata[additionaldata.findIndex((x) => x.keyword == response.data.entries[index].data[0].value.value)].serp_items
            //                         }
            //                         // starts estimated visits calculation in serp preview
            //                         console.log(this.serpKeyword)
            //                         if (row.keyword == this.serpKeyword) {
            //                             this.preview_sv_data = { keyword: this.serpKeyword, value: row.avgsv }
            //                         }

            //                         for (let monthCounter = 0; monthCounter < entry.value.length; monthCounter++) {
            //                             let volume = {
            //                                 count: parseInt(entry.value[monthCounter].count) || 0,
            //                                 year: entry.value[monthCounter].year,
            //                                 month: entry.value[monthCounter].month
            //                             }
            //                             row.search_volumes.push(volume)

            //                             let svname = ""
            //                             if (entry.value[monthCounter].month < 10) {
            //                                 svname = entry.value[monthCounter].year.toString() + "0" + entry.value[monthCounter].month.toString()
            //                             } else {
            //                                 svname = entry.value[monthCounter].year.toString() + entry.value[monthCounter].month.toString()
            //                             }
            //                             row[svname] = parseInt(entry.value[monthCounter].count) || 0
            //                         }
            //                         this.keywords.push(row)
            //                         this.keywordsWithSV.push(response.data.entries[index].data[0].value.value)
            //                     }
            //                     if (keywordArrayLength == this.answerCounter) {
            //                         this.progress = 100
            //                         if (response.data.totalNumEntries > 0) {
            //                             this.generateVisuals(response)
            //                         }

            //                         // //console.log(this.keywords.length)
            //                         this.fetchCategories(this.keywords)
            //                         this.carddata.subTitle = this.keywords.length + " keyword suggests found"

            //                         this.endloading()
            //                     }
            //                     this.resize++
            //                 }
            //                 // this.endloading();
            //             })
            //             .catch((error) => {
            //                 // handle error
            //                 console.log(error)
            //                 setTimeout(() => {
            //                     errorrounds--
            //                     this.getSearchVolume(data, keywordArrayLength, errorrounds)
            //                 }, 2000)
            //                 // //console.log(error);
            //             })
            //             .then(function () {
            //                 // always executed
            //             })
            //     }
            // },

            processData(response) {
                return new Promise(async (resolve, reject) => {
                    console.log(response)
                    if (response.keywords.length > 0) {
                        if (!this.showFirstHalf) {
                            this.keywords = []
                            if (this.$refs.kw_table) this.$refs.kw_table.unselectAll()
                            this.keywordsWithSV = []
                        }
                        // let additionaldata = await dataprocessing.extractAdditionalData(response.data, [])
                        for (let index = 0; index < response.data.totalNumEntries; index++) {
                            if (response.data.entries[index].data[0].value.value !== this.googleSearch) {
                                let entry = response.data.entries[index].data[1].value
                                let annual = 0
                                for (let sumindex = 0; sumindex < 12; sumindex++) {
                                    annual += parseInt(entry.value[sumindex].count) || 0
                                }
                                let row = {
                                    keyword: response.data.entries[index].data[0].value.value,
                                    annualsv: annual,
                                    avgsv: parseInt(annual / 12),
                                    search_volumes: [],
                                    dialog: false,
                                    categories: response.data.entries[index].data[1].value.categories,
                                    adwords_cpc_high: response.data.entries[index].data[2].value.value.high,
                                    adwords_cpc: response.data.entries[index].data[2].value.value.high,
                                    adwords_cpc_low: response.data.entries[index].data[2].value.value.low,
                                    adwords_competition:
                                        response.data.entries[index].data[3].value && response.data.entries[index].data[3].value.value !== null
                                            ? response.data.entries[index].data[3].value.value.toFixed(0)
                                            : null,

                                    active_cat_index: 0
                                    // keyword_difficulty: additionaldata[additionaldata.findIndex((x) => x.keyword == response.data.entries[index].data[0].value.value)].keyword_difficulty,
                                    // serp_items: additionaldata[additionaldata.findIndex((x) => x.keyword == response.data.entries[index].data[0].value.value)].serp_items
                                }
                                for (let monthCounter = 0; monthCounter < entry.value.length; monthCounter++) {
                                    let volume = {
                                        count: parseInt(entry.value[monthCounter].count) || 0,
                                        year: entry.value[monthCounter].year,
                                        month: entry.value[monthCounter].month
                                    }
                                    row.search_volumes.push(volume)

                                    let svname = ""
                                    if (entry.value[monthCounter].month < 10) {
                                        svname = entry.value[monthCounter].year.toString() + "0" + entry.value[monthCounter].month.toString()
                                    } else {
                                        svname = entry.value[monthCounter].year.toString() + entry.value[monthCounter].month.toString()
                                    }
                                    row[svname] = parseInt(entry.value[monthCounter].count) || 0
                                }
                                this.keywords.push(row)
                                this.keywordsWithSV.push(response.data.entries[index].data[0].value.value)
                            }
                        }
                        this.keywords = this.$helpers.removeDuplicatesByAttribute(this.keywords, "keyword")
                        this.keywordsWithSV = this.$helpers.removeDuplicatesByAttribute(this.keywordsWithSV, "")
                        if (response.data.totalNumEntries > 0) {
                            this.showFirstHalf = true
                            this.carddata.title = "Keyword Suggests "
                            this.carddata.searchPhrases[0] = this.googleSearch
                            this.carddata.subtitle = this.keywords.length + " keywords found"
                            this.fetchCategories(this.keywords)
                            // this.generateVisuals(response)
                            resolve(true)
                        }
                    } else {
                        this.notification = this.$helpers.createNotification("We could not find any keywords for your search!", "mdi-alert-circle-outline", "#FCBA03")
                        resolve(true)
                    }
                })
            },
            endloading() {
                // let show_media_counts = this.media_counts.length ? true : false
                // this.headers.standardHeaders.map((header) => {
                //     if (header.value === "media_count") header.active = show_media_counts
                //     return header
                // })
                // if (this.headers.modifiedHeaders.length) {
                //     this.headers.modifiedHeaders.map((header) => {
                //         if (header.value === "media_count") header.active = show_media_counts
                //         return header
                //     })
                // }
                console.log("endloadin")
                this.carddata.title = "Keyword Suggests "
                this.carddata.searchPhrases[0] = this.googleSearch
                this.carddata.subtitle = this.keywords.length + " keywords found"
                this.progress = 0
                this.realprogress = 0
                this.loading = false
                this.showFirstHalf = false
                this.btndisabled = false
                this.sentToBackend = false
                this.frontendstop = false
                this.media_counts = []
                // this.unfilteredKeywords = []
                this.catsLoading = false

                // this.keywords = []
                // if (this.$refs.kw_table) this.$refs.kw_table.unselectAll()
                console.log(this.headers)
            },
            addRankings(url, device) {
                this.$helpers.addRankings(this.keywords, this.headers, this.selectedLocation.value, url, device)
            },
            generateVisuals() {
                let keywords = this.$helpers.removeDuplicatesByAttribute(this.keywords, "keyword")
                this.createHeader(keywords)
                keywords.sort(function (a, b) {
                    return b.avgsv - a.avgsv
                })
                this.keywords = keywords
                if (this.$store.state.monitoring_suite) this.addRankings("www.xxxlutz.de", 0)

                this.addMediaCounts(this.media_counts)

                let graphObjects = this.$helpers.buildGraphObjects([], keywords, this.dataChart.datasets, this.dataChart.labels)
                // console.log(graphObjects)
                this.dataChart.datasets = graphObjects.graphdata
                this.dataChart.labels = graphObjects.graphlabel
                this.countGraph2++
                this.new_data++
                this.graphloaded = true
                this.loaded = true

                // this.createHeader(this.keywords)
                // this.calOverallGraph(this.keywords);

                if (this.$store.state.active_filter.length) this.filterColumns()
                console.log(this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length)
                setTimeout(() => {
                    this.carddata.subtitle = (this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length) + " keywords found"
                    if (this.$refs.kw_table !== undefined) this.$refs.kw_table.setKwCount(this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length)

                    this.addQuestionHeaders()
                }, 200)
            },
            addQuestionHeaders() {
                let standardIndex = this.headers.standardHeaders.findIndex((x) => x.value == "custom_Question")
                let modifiedIndex = this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Question")
                console.log(standardIndex)
                console.log(modifiedIndex)

                if (this.tab == "questions") {
                    let questions = {}
                    // console.log(locs)
                    console.log(this.siteKeywordsLocation.cc)

                    questions.questionParams = this.selectedLocation.questionParams
                    questions.prepositionParams = this.selectedLocation.prepositionParams
                    questions.comparisonParams = this.selectedLocation.comparisonParams
                    let questionsheader = this.$helpers.buildQuestionHeaders(questions)
                    console.log(questionsheader.questions)

                    // let adwordscompetitionindexstandard = this.headers.standardHeaders.findIndex((x) => x.value == "season")
                    // let adwordscompetitionindexmodified = this.headers.modifiedHeaders.findIndex((x) => x.value == "season")

                    // //delete old headers if there
                    // if (standardIndex !== -1) {
                    //     console.log("Found, splicing!")
                    //     this.headers.standardHeaders.splice(
                    //         this.headers.standardHeaders.findIndex((x) => x.value == "custom_Question"),
                    //         1
                    //     )
                    //     this.headers.standardHeaders.splice(
                    //         this.headers.standardHeaders.findIndex((x) => x.value == "custom_Preposition"),
                    //         1
                    //     )
                    //     this.headers.standardHeaders.splice(
                    //         this.headers.standardHeaders.findIndex((x) => x.value == "custom_Comparison"),
                    //         1
                    //     )
                    // }
                    // if (modifiedIndex !== -1) {
                    //     this.headers.modifiedHeaders.splice(
                    //         this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Question"),
                    //         1
                    //     )
                    //     this.headers.modifiedHeaders.splice(
                    //         this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Preposition"),
                    //         1
                    //     )
                    //     this.headers.modifiedHeaders.splice(
                    //         this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Comparison"),
                    //         1
                    //     )
                    // }

                    // after kw trend, before cats
                    if (standardIndex == -1) {
                        console.log("not found, adding")
                        this.headers.standardHeaders.splice(4, 0, questionsheader.comparisons)
                        this.headers.standardHeaders.splice(4, 0, questionsheader.prepositions)
                        this.headers.standardHeaders.splice(4, 0, questionsheader.questions)
                    } else {
                        console.log(
                            "FOUD",
                            this.headers.standardHeaders.findIndex((x) => x.value == "custom_Question")
                        )
                        this.headers.standardHeaders[this.headers.standardHeaders.findIndex((x) => x.value == "custom_Question")] = questionsheader.questions
                        this.headers.standardHeaders[this.headers.standardHeaders.findIndex((x) => x.value == "custom_Preposition")] = questionsheader.prepositions
                        this.headers.standardHeaders[this.headers.standardHeaders.findIndex((x) => x.value == "custom_Comparison")] = questionsheader.comparisons
                        this.headers.standardHeaders.push({})
                        this.headers.standardHeaders.pop()
                    }
                    if (modifiedIndex == -1) {
                        this.headers.modifiedHeaders.splice(4, 0, questionsheader.comparisons)
                        this.headers.modifiedHeaders.splice(4, 0, questionsheader.prepositions)
                        this.headers.modifiedHeaders.splice(4, 0, questionsheader.questions)
                    } else {
                        this.headers.modifiedHeaders[this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Question")] = questionsheader.questions
                        this.headers.modifiedHeaders[this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Preposition")] = questionsheader.prepositions
                        this.headers.modifiedHeaders[this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Comparison")] = questionsheader.comparisons
                        this.headers.modifiedHeaders.push({})
                        this.headers.modifiedHeaders.pop()
                    }
                    // this.$refs.kw_table.doAutoClusterQuestions()

                    // this.questionsAutoCluster++
                    this.clusterLoading = true

                    questionsheader.comparisons.customOptions.entries.map((option) => {
                        if (option.id != 0) {
                            let rowsToModify = this.keywords.filter(
                                (row) =>
                                    row.keyword.toLowerCase().includes(option.text.toLowerCase()) &&
                                    (row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == undefined ||
                                        row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == " ") &&
                                    (row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == undefined ||
                                        row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == " ")
                            )
                            if (rowsToModify.length > 0) {
                                this.addCustomEntries(rowsToModify, option, questionsheader.comparisons.value, true, false)
                            }
                        }
                    })
                    questionsheader.prepositions.customOptions.entries.map((option) => {
                        if (option.id != 0) {
                            let rowsToModify = this.keywords.filter(
                                (row) =>
                                    row.keyword.toLowerCase().includes(option.text.toLowerCase()) &&
                                    (row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == undefined ||
                                        row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == " ") &&
                                    (row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == undefined ||
                                        row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == " ")
                            )
                            if (rowsToModify.length > 0) {
                                this.addCustomEntries(rowsToModify, option, questionsheader.prepositions.value, true, false)
                            }
                        }
                    })
                    questionsheader.questions.customOptions.entries.map((option) => {
                        if (option.id != 0) {
                            let rowsToModify = this.keywords.filter(
                                (row) =>
                                    row.keyword.toLowerCase().includes(option.text.toLowerCase()) &&
                                    (row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == undefined ||
                                        row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == " ") &&
                                    (row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == undefined ||
                                        row.keyword[row.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == " ")
                            )
                            if (rowsToModify.length > 0) {
                                this.addCustomEntries(rowsToModify, option, questionsheader.questions.value, true, false)
                            }
                        }
                    })
                    this.keywords.sort((a, b) => {
                        let aincludes = false
                        questionsheader.questions.customOptions.entries.map((option) => {
                            if (option.id != 0) {
                                if (
                                    a.keyword.toLowerCase().includes(option.text.toLowerCase()) &&
                                    (a.keyword[a.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == undefined ||
                                        a.keyword[a.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == " ") &&
                                    (a.keyword[a.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == undefined ||
                                        a.keyword[a.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == " ")
                                )
                                    aincludes = true
                            }
                        })
                        let bincludes = false
                        questionsheader.questions.customOptions.entries.map((option) => {
                            if (option.id != 0) {
                                if (
                                    b.keyword.toLowerCase().includes(option.text.toLowerCase()) &&
                                    (b.keyword[b.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == undefined ||
                                        b.keyword[b.keyword.toLowerCase().indexOf(option.text.toLowerCase()) - 1] == " ") &&
                                    (b.keyword[b.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == undefined ||
                                        b.keyword[b.keyword.toLowerCase().indexOf(option.text.toLowerCase()) + option.text.length] == " ")
                                )
                                    bincludes = true
                            }
                        })
                        if (aincludes && bincludes) {
                            return b.avgsv - a.avgsv
                        } else if (aincludes) {
                            return -1
                        } else if (bincludes) {
                            return 1
                        } else {
                            return b.avgsv - a.avgsv
                        }
                    })
                    // let filter = {
                    //     field: { type: "array", text: "Question", id: 12, custom: true },
                    //     action: { text: "includes", value: "includes", type: "array" },
                    //     value: [
                    //         { text: "wer", color: { hex: "#8c9F064" }, id: 1 },
                    //         { text: "wie", color: { hex: "#ff71fe" }, id: 2 },
                    //         { text: "wo", color: { hex: "#fdd778" }, id: 3 },
                    //         { text: "was", color: { hex: "#2236f0" }, id: 4 }
                    //     ]
                    // }
                    // let active_filter = this.$store.state.active_filter
                    // active_filter.push(filter)

                    // this.$store.dispatch("CHANGE_ACTIVE_FILTER", active_filter)

                    this.clusterLoading = false
                } else {
                    console.log("ENTFERNE HEADERs")
                    if (standardIndex !== -1) {
                        console.log("Found, splicing!")
                        this.headers.standardHeaders.splice(
                            this.headers.standardHeaders.findIndex((x) => x.value == "custom_Question"),
                            1
                        )
                        this.headers.standardHeaders.splice(
                            this.headers.standardHeaders.findIndex((x) => x.value == "custom_Preposition"),
                            1
                        )
                        this.headers.standardHeaders.splice(
                            this.headers.standardHeaders.findIndex((x) => x.value == "custom_Comparison"),
                            1
                        )
                    }
                    if (modifiedIndex !== -1) {
                        this.headers.modifiedHeaders.splice(
                            this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Question"),
                            1
                        )
                        this.headers.modifiedHeaders.splice(
                            this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Preposition"),
                            1
                        )
                        this.headers.modifiedHeaders.splice(
                            this.headers.modifiedHeaders.findIndex((x) => x.value == "custom_Comparison"),
                            1
                        )
                    }
                }
            },
            getIndexOfHeader(header, headers) {
                //  console.log(header)
                for (let index = 0; index < headers.length; index++) {
                    if (header.text == headers[index].text) {
                        return true
                    }
                }
                return false
            },
            createHeader(keywords) {
                let monthArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                const researchHeaderIndex = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")
                let modifiedHeaderIndex = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")

                // reset SV Headers
                this.headers.standardHeaders[researchHeaderIndex].columns = []
                if (this.headers.modifiedHeaders.length > 0) {
                    this.headers.modifiedHeaders[modifiedHeaderIndex].columns = []
                }
                let headers = []
                keywords.map((keyword) => {
                    keyword.search_volumes.map((vol) => {
                        let header = {
                            group: 1,
                            custom: false,
                            active: true,
                            canBeModified: false,
                            modifiedText: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            text: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            sortable: true,
                            width: "160"
                        }
                        if (vol.month < 10) {
                            header.value = vol.year.toString() + "0" + vol.month.toString()
                        } else {
                            header.value = vol.year.toString() + vol.month.toString()
                        }
                        header.dataChartLabel = monthArray[vol.month] + " " + vol.year
                        // console.log(headers.findIndex(p => p.text === header.text) === -1 )
                        if (headers.findIndex((p) => p.text === header.text) === -1) headers.push(header)
                    })
                })
                headers.sort(function (a, b) {
                    return a.value - b.value
                })
                this.headers.standardHeaders[researchHeaderIndex].columns = headers
                if (this.headers.modifiedHeaders.length == 0) {
                    this.headers.modifiedHeaders = JSON.parse(JSON.stringify(this.headers.standardHeaders))
                } else {
                    this.headers.modifiedHeaders[modifiedHeaderIndex].columns = headers
                }
                //push headers in this.headers and set linechart labels
                let labels = []
                this.headers.standardHeaders[researchHeaderIndex].columns.map((header) => {
                    if (!labels.includes(header.dataChartLabel)) labels.push(header.dataChartLabel)
                })
                console.log("Erstelle Header", this.headers)

                // this.dataChart.labels = labels
            },
            prepareDeleteKw(selected) {
                this.selected = selected
                this.deleteQuestion = true
            },
            deleteKeywords(keywords) {
                if (this.selected.length > 0) {
                    try {
                        for (var i = 0; i < this.selected.length; i++) {
                            const index = this.keywords.indexOf(this.selected[i])
                            this.keywords.splice(index, 1)
                        }
                        if (this.unfilteredKeywords.length > 0) {
                            for (var i = 0; i < this.selected.length; i++) {
                                let index = this.unfilteredKeywords.indexOf(this.selected[i])
                                this.unfilteredKeywords.splice(index, 1)
                            }
                        }
                        this.$matomo.trackEvent("Ideas", "deleteKeywords", "KeywordsCount: " + this.selected.length, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    let kwCount = this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length

                    this.$refs.kw_table.keywordsDeleted(kwCount)

                    let selectedNoDoubles = this.$helpers.checkGraphDoubles(this.selected, this.dataChart.datasets)
                    for (var i = 0; i < this.selected.length; i++) {
                        let index = selectedNoDoubles.findIndex((set) => {
                            if (set.keyword == this.selected[i].keyword) return set
                        })
                        if (index > -1) selectedNoDoubles.splice(index, 1)
                    }
                    let kwForGraph = this.keywords
                    if (this.unfilteredKeywords.length > this.keywords.length) {
                        kwForGraph = this.unfilteredKeywords
                    }

                    if (kwForGraph.length > 0) {
                        var graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, kwForGraph, selectedNoDoubles)

                        this.dataChart.datasets = graphObjects.graphdata
                        this.dataChart.labels = graphObjects.graphlabel
                        if (this.dataChart.datasets.length > 1) {
                            this.dataChart.datasets[0].hidden = true
                        } else {
                            this.dataChart.datasets[0].hidden = false
                        }
                        this.countGraph2++
                        this.getListCats()
                        this.getListSerps()
                        this.getListSeasons()
                    }

                    this.selected = []
                    this.keywords.map((x) => {
                        x.selected = false
                    })
                    this.deleteQuestion = false
                    this.notification = this.$helpers.createNotification("The selected keywords have been deleted!", "mdi-check-circle-outline", "#16C96D")
                    // this.selected = [];
                    this.carddata.subtitle = this.keywords.length + " keywords"
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one Keyword!", "mdi-alert-circle-outline", "orange")
                }
            },
            getSparklineData(ranking) {
                let svArray = []
                let svArrayCounter = 0
                for (let searchvolumesCounter = ranking.search_volumes.length - 1; searchvolumesCounter > -1; searchvolumesCounter--) {
                    svArray[svArrayCounter] = ranking.search_volumes[searchvolumesCounter].count
                    svArrayCounter++
                }
                return svArray
            },
            disabled() {
                if (this.selected.length > 0) {
                    this.tableBtnDisabled = false
                    return this.$vuetify.theme.themes.light.primary
                } else {
                    this.tableBtnDisabled = true
                    return "grey"
                }
            },

            addToChart(selected) {
                let graphdata = this.dataChart.datasets
                let selectedNoDoubles = this.$helpers.checkGraphDoubles(selected, graphdata)
                if (selected.length > 0) {
                    try {
                        this.$matomo.trackEvent("Ideas", "addToChart", "Keywords: " + selectedNoDoubles.length, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    //console.log(selectedNoDoubles)
                    if (selectedNoDoubles.length > 0) {
                        if (this.$helpers.checkSelectedSizeForGraph(selectedNoDoubles, 11, graphdata)) {
                            let graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, this.keywords, selectedNoDoubles)
                            this.dataChart.datasets = graphObjects.graphdata
                            this.dataChart.labels = graphObjects.graphlabel
                            if (this.dataChart.datasets.length > 1) {
                                this.dataChart.datasets[0].hidden = true
                            } else {
                                this.dataChart.datasets[0].hidden = false
                            }
                            this.countGraph2++
                        } else {
                            this.notification = this.$helpers.createNotification("Only 10 keywords can be displayed in graph!", "mdi-alert-circle-outline", "#FCBA03")
                        }
                    } else {
                        this.notification = this.$helpers.createNotification("Selected keywords are displayed in the graph already!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            rerenderChart() {
                this.countGraph2++
            },
            clearChart() {
                try {
                    this.$matomo.trackEvent("Suggests", "clearChart", "", "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                let graphObjects = this.$helpers.buildGraphObjects([], this.keywords, this.dataChart.datasets, this.dataChart.labels)
                // console.log(graphObjects)
                this.dataChart.datasets = graphObjects.graphdata
                this.dataChart.labels = graphObjects.graphlabel
                this.countGraph2++
            },
            togglegraph() {
                try {
                    this.$matomo.trackEvent("Suggests", "togglegraph", "", "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                this.showgraph = !this.showgraph
            },
            splitArray(myArray, chunk_size) {
                let myChunk = []
                let tempArray = []
                //remove empty elements
                if (myArray[myArray.length - 1] === "") {
                    myArray.splice(-1, 1)
                }
                //split Array in equal parts
                for (let index = 0; index < myArray.length; index += chunk_size) {
                    myChunk = myArray.slice(index, index + chunk_size)
                    tempArray.push(myChunk)
                }

                return tempArray
            },
            // selectAll() {
            //     if (this.selected.length == this.keywords.length) {
            //         this.selected = []
            //     } else {
            //         this.selected = this.keywords
            //     }
            //     try {
            //         this.$matomo.trackEvent("Suggests", "selectAll", "length: " + this.selected.length, "")
            //     } catch (error) {
            //         console.log(error)
            //         console.log("matomo blocked")
            //     }
            // },

            getCategories() {
                this.$http
                    .get(process.env.VUE_APP_APIURL + "/research/amazon/categories", {
                        headers: { Authorization: this.auth }
                    })
                    .then(function (response) {
                        this.amzCategories = response.body
                    })
            },
            fetchSERP(selected) {
                console.log("fetchSERP:", selected.length)
                if (!selected || selected.length == 0) {
                    this.notification = this.$helpers.createNotification("You have to select one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                    return
                }
                this.addToSerpDisabled = true
                this.serpKeyword = selected[0].keyword
                this.preview_sv_data = { keyword: selected[0].keyword, value: selected[0].avgsv }
                this.serpReload++
            },
            fetchAllGoogleCats() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        // console.log(cats)
                        this.catData = cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            checkIfSendTo() {
                if (this.$store.state.send_to_keywords.length) {
                    this.googleSearch = this.$store.state.send_to_keywords.reduce((a, b) => a + String.fromCharCode(44) + b)
                    this.$store.dispatch("SET_SEND_TO_KEYWORDS", [])
                }
            }
        },
        created: function () {
            this.getCategories()
            // this.proxyRequest();
            // this.socket = io(process.env.VUE_APP_SOCKETURL, {transports: ['websocket'], rejectUnauthorized: false, path: '/'})
            // this.socket = io(process.env.VUE_APP_SOCKETURL, {transports: ['websocket']})
        },
        beforeRouteLeave(to, from, next) {
            // if (this.unfilteredKeywords.length) {
            //     console.log("DRINNNNNNNNNNE")
            //     this.keywords = [...this.unfilteredKeywords]
            // }
            this.delay = false
            next()
        },
        activated() {
            this.delay = true
            this.$router.onReady(() => {
                this.fetchAllGoogleCats()
                // this.stateItems = JSON.parse(JSON.stringify(locs.stateItems))
                //deleting worldwide sv, no ww sv in dataforseolabs
                // this.stateItems.splice(this.stateItems.indexOf({ id: 26, loc: "International", value: null, cc: "" }), 1)
                // let stateIndex = this.stateItems.findIndex((x) => x.id == this.selectedLocation.id)
                // this.questions = this.stateItems[stateIndex].questions
                // this.selectedQuestion = this.stateItems[stateIndex].questions

                this.headers.standardHeaders = JSON.parse(JSON.stringify(standardHeaders))
                console.log(this.headers.standardHeaders)
                // if (this.keywords.length) {
                //     this.unfilteredKeywords = [...this.keywords]
                //     this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
                // }
                this.checkIfSendTo()

                //test end check
                if (this.$store.state.company_status === "test_ended") {
                    this.$router.push("/endoftest")
                }
            })
        },
        // beforeRouteEnter(to, from, next) {
        //     next((vm) => {
        //         vm.fetchAllGoogleCats()
        //         vm.stateItems = JSON.parse(JSON.stringify(locs.stateItems))
        //         //deleting worldwide sv, no ww sv in dataforseolabs
        //         // this.stateItems.splice(this.stateItems.indexOf({ id: 26, loc: "International", value: null, cc: "" }), 1)
        //         let stateIndex = vm.stateItems.findIndex((x) => x.id == vm.selectedLocation.id)
        //         vm.questions = vm.stateItems[stateIndex].questions
        //         vm.selectedQuestion = vm.stateItems[stateIndex].questions

        //         vm.headers.standardHeaders = JSON.parse(JSON.stringify(standardHeaders))

        //         if (vm.keywords.length) {
        //             vm.unfilteredKeywords = [...vm.keywords]
        //             vm.keywords = vm.$helpers.filterKeywordTable(vm.unfilteredKeywords)
        //         }
        //         vm.checkIfSendTo()

        //         //test end check
        //         if (vm.$store.state.company_status === "test_ended") {
        //             vm.$router.push("/endoftest")
        //         }
        //     })
        // },
        mounted() {
            // this.$socket.on("sendWelcomeToClient", (data) => {
            //     // //console.log(data)
            // })
            // this.$socket.on("changingdata", (data) => {
            //     // //console.log(data)
            // })
            this.$socket.on("retrieveSuggestsClientSide", async (data) => {
                var lastpackage = data
                if (lastpackage.media_counts) {
                    console.log("MEDIA COUNTS")
                    this.media_counts.push(...lastpackage.media_counts)
                }
                // if (this.tab=="questions") {
                //   for (let index = 0; index < lastpackage.data.entries.length; index++) {
                //     if (lastpackage.data.entries[index].data[0].value.value !== this.googleSearch) {
                //         console.log('found')
                //         lastpackage.data.entries.splice(index, 1);
                //     }
                //   }
                // }

                // //console.log(this.media_counts)
                if (lastpackage.lastpackage) {
                    await this.processData(lastpackage)

                    // last package
                    this.progress = 100
                    this.fetchDFSCategories()
                    // this.endloading()
                } else {
                    await this.processData(lastpackage)
                    this.fetchDFSCategories(false)
                }
            })
            this.$socket.on("suggestProgressClienSide", (data) => {
                // //console.log(data)
                var progress = data
                // //console.log(progress)

                this.progress = ((100 / progress.overallLength) * progress.count).toFixed(0)
            })
        }
    }
</script>
<style scoped lang="scss">
    .tabslider {
        margin-top: -50px !important;
    }
    .cardcontent {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .bingform {
        padding-left: 10px;
    }
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }
    .bottomrounded {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
    .nobottombr {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .toprounded {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
    #brandlogo {
        padding-top: 10%;
    }
    .v-card {
        margin-top: 20px;
    }
    .suggestabs {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-radius: 20px;
        padding-bottom: 1px;
    }

    .kwtxtfield {
        padding-left: 25px;
        padding-bottom: 20px;
    }
    #paddingleft {
        padding-top: 0px;
        padding-left: 35px;
        padding-bottom: 10px;
    }
    #switch {
        margin-top: 0px !important;
        padding-top: 0% !important;
    }
    .chartBtn {
        float: right !important;
    }
    .chartCardHeading {
        padding-top: 23px;
        padding-left: 23px;
    }
    #cardtitle {
        padding-bottom: 15px;
        padding-top: 15px;
        background-color: #42defa;
        color: white;
    }
    .Innenabstand {
        padding: 20px;
    }
    #kwpformflex {
        padding-left: 43px;
        padding-top: 0%;
        padding-right: 0%;
    }
    #kwpform {
        padding-left: 0.5%;
    }
    #searchbutton {
        text-align: center;
    }
    .seitenabstand {
        padding-left: 30px;
        padding-right: 30px;
    }
    .spark {
        padding: 0%;
    }
    .v-card .v-divider {
        border-color: white;
    }
    .fabicon {
        margin-left: 10px;
        margin-top: 10px;
    }
    #tablesubtitle {
        padding-bottom: 0px;
    }
    #buttonFlex {
        padding-left: 2px;
    }
    #sendBtn {
        height: 56px !important;
    }
    #searchRow {
        padding-top: 0px !important;
    }
    h2 {
        font-family: Raleway;
        color: #42defa;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    #searchbar {
        margin-left: 10%;
        margin-right: 10%;
    }
    .catmenu {
        margin-top: 2px;
        margin-left: 15px;
    }
    .catmenutitle {
        padding-bottom: -10px;
        line-height: 25px;
        font-size: 0.95em;
        font-weight: 600;
        text-transform: uppercase;
    }
    .catmenusubtitle {
        margin-top: -4px;
        margin-bottom: 5px;
        font-size: 0.8em !important;
        color: rgb(110, 103, 103);
    }
    .catchip {
        background-color: #e0e0e0 !important;
    }
    .pointerhover:hover {
        cursor: pointer;
    }
    .catchip:hover {
        background-color: #e0e0e0;
        cursor: pointer;
    }
    .catdialogtitle {
        margin-top: -5px;
        line-height: 25px;
        font-size: 0.8em;
        font-weight: 600;
        text-transform: uppercase;
    }
    .catdialogsubtitle {
        margin-top: -8px;
        margin-bottom: 5px;
        font-size: 0.61em !important;
        color: rgb(110, 103, 103);
    }
    .catlistitem:hover {
        background-color: rgb(245, 245, 245);
    }
    .smalltab {
        min-width: 57px;
    }
    .v-tabs-slider-wrapper {
        min-width: 57px !important;
    }
    .search_settings_btn:hover {
        cursor: pointer;
    }

    div.v-window.v-item-group.theme--light.v-tabs-items {
        border-radius: 0px !important;
    }
</style>
