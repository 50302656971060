<template>
    <div id="contentcontainter" class="standard-mode">
        <v-container id="register" fill-height tag="section" class="pt-15">
            <!-- Info -->
            <v-snackbar top :color="info.color" v-model="showInfo">
                <v-icon>{{ info.icon }}</v-icon> <span class="font-weight-bold pl-2">{{ info.message }}</span>
            </v-snackbar>
            <!-- couldnt find user for code -->
            <v-snackbar top color="#fcba03" v-model="showNoUserMessage">
                <v-row>
                    <v-col cols="1"><v-icon class="pl-3">mdi-alert-circle-outline</v-icon> </v-col>
                    <v-col> <span class="font-weight-bold">We could not find the user you are looking for. Please contact contact@quaro.io for further assistance.</span> </v-col>
                </v-row>
            </v-snackbar>
            <!-- password not the same alert -->
            <v-snackbar top v-model="exists" color="red" class="pl-10">
                <v-icon>mdi-alert-circle-outline</v-icon> <span class="font-weight-bold">{{ existMessage }}</span>
            </v-snackbar>
            <!-- password resetted -->
            <v-snackbar top v-model="passwordChanged" color="green" class="pl-10">
                <v-icon>mdi-check-circle-outline</v-icon> <span class="font-weight-bold">Your password has been set. Welcome to Quaro!</span>
            </v-snackbar>
            <v-slide-y-transition appear>
                <v-card class="pa-3 pa-md-5 mx-auto registercard rounded_card quaroshadow" light id="registercard" style="margin-top: 50px">
                    <!-- AGB -->
                    <v-dialog v-model="agb" max-width="800">
                        <v-card>
                            <v-card-title class="headline"> Allgemeine Geschäftsbedingungen </v-card-title>

                            <v-card-text> Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running. </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn color="green darken-1" text @click="agb = false"> Agree </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <center>
                        <div id="sidebarLogo" class="quaroshadow rounded_card">
                            <img width="150" id="whitelogo" src="../assets/quaro_final_white.svg" />
                            <template>
                                <!-- <div class="text-center">
                                <v-btn v-for="(social, i) in socials" :key="i" :href="social.href" class="ma-1" icon rel="noopener" target="_blank">
                                    <v-icon v-text="social.icon" />
                                </v-btn>
                            </div> -->
                            </template>
                        </div>
                    </center>
                    <v-layout>
                        <v-container>
                            <div class="text-center">
                                <!-- <span class="existMessage">
                    {{existMessage}}
                  </span> -->
                                <form @submit.prevent="setPassword" autocomplete="off">
                                    <span style="font-size: 18px"> Please enter your new password. </span>
                                    <v-text-field
                                        class="mt-5"
                                        v-model="password"
                                        color="primary"
                                        rounded
                                        filled
                                        label="Password..."
                                        prepend-icon="mdi-lock-outline"
                                        required
                                        :disabled="inputdisabled"
                                        :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_pw ? 'text' : 'password'"
                                        @click:append="show_pw = !show_pw" />
                                    <v-text-field
                                        v-model="passwordCheck"
                                        class="mb-8"
                                        color="primary"
                                        rounded
                                        filled
                                        label="Confirm Password..."
                                        prepend-icon="mdi-refresh"
                                        required
                                        :disabled="inputdisabled"
                                        :append-icon="show_pw_check ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_pw_check ? 'text' : 'password'"
                                        @click:append="show_pw_check = !show_pw_check" />
                                    <!-- <v-checkbox
                    v-model="checkTerms"
                    color="secondary"
                    required
                  >
                    <template v-slot:label>
                      <span class="text-no-wrap">I agree to the &nbsp;&nbsp;</span>
                      <a
                        class="secondary--text ml-6 ml-sm-0"
                        @click="agb = true"
                        style="padding-left: 5px"
                      >
                        terms and conditions
                      </a>.
                    </template>
                  </v-checkbox> -->
                                    <v-btn :loading="loading" rounded color="primary" type="submit" large class="mb-5"> Set Password </v-btn>
                                    <!-- <p class="registerLink" @click="$router.push('/login')">Login</p> -->
                                </form>
                            </div>
                        </v-container>
                    </v-layout>
                </v-card>
            </v-slide-y-transition>
        </v-container>
        <Datenschutz :show="showDatenschutz" @close="showDatenschutz = false"></Datenschutz>
        <v-footer padless fixed bottom>
            <v-col @click="openAGB()" class="footer-col">
                <span>AGB</span>
            </v-col>
            <v-col @click="openImprint()" class="footer-col">
                <span>Impressum</span>
            </v-col>
            <v-col @click="showDatenschutz = true" class="footer-col">
                <span>Datenschutz</span>
            </v-col>
        </v-footer>
    </div>
</template>
<script>
    import Datenschutz from "../components/footer/Datenschutz.vue"
    import auth from "../components/helper/auth"
    export default {
        name: "SetPasswordLogin",
        components: { Datenschutz },
        data: () => ({
            showAGB: false,
            showDatenschutz: false,
            info: {
                message: null,
                icon: null,
                color: null
            },
            showInfo: false,
            inputdisabled: true,
            loading: false,
            passwordChanged: false,
            agb: false,
            showRegisteredMessage: false,
            showNoUserMessage: false,
            username: "",
            email: "",
            password: "",
            passwordCheck: "",
            show_pw: false,
            show_pw_check: false,
            checkTerms: false,
            exists: false,
            existMessage: "",
            notAllowed: false,
            notAllowedMessage: "",
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            sections: [
                {
                    icon: "mdi-chart-timeline-variant",
                    iconColor: "primary",
                    title: "Marketing",
                    text: `We've created the marketing campaign of the website. It was a very interesting collaboration.`
                },
                {
                    icon: "mdi-code-tags",
                    iconColor: "secondary",
                    title: "Fully Coded in HTML5",
                    text: `We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub.`
                },
                {
                    icon: "mdi-account-multiple",
                    iconColor: "cyan",
                    title: "Built Audience",
                    text: "There is also a Fully Customizable CMS Admin Dashboard for this product."
                }
            ],
            socials: [
                {
                    href: "https://www.facebook.com/quaro.io",
                    icon: "mdi-facebook"
                },
                {
                    href: "https://www.instagram.com/quaro.io/",
                    icon: "mdi-instagram"
                },
                {
                    href: "https://de.linkedin.com/company/quaro-das-keyword-tool",
                    icon: "mdi-linkedin"
                }
            ]
        }),
        methods: {
            openAGB() {
                window.open(`https://quaro.io/agb/`, "_blank")
            },
            openImprint() {
                window.open(`https://quaro.io/impressum/`, "_blank")
            },
            setPassword: function () {
                if (this.password == this.passwordCheck && !this.inputdisabled) {
                    this.loading = true
                    try {
                        this.$matomo.trackEvent("Set Password", "Set password", this.$route.params.code, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    let codeObject = {
                        code: this.$route.params.code,
                        pass: this.password
                    }
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/user/setpassword/login", codeObject, { headers: { Authorization: this.auth } })
                        .then((response) => {
                            console.log(response)
                            if (response.body.user_found) {
                                auth.login(response.body.email, this.password, (loggedIn) => {
                                    console.log(loggedIn)
                                    if (loggedIn.active) {
                                        this.passwordChanged = true
                                        this.$store.dispatch("FETCH_STARTUP_DATA", response.body.email)
                                        setTimeout(() => {
                                            this.$router.push({ path: "/" })
                                        }, 3000)
                                    } else {
                                        this.loading = false
                                        this.createInfo("Your account expired. Please contact contact@quaro.io for further assistance.", "#fcba03", "mdi-alert-circle-outline")
                                    }
                                })
                            } else {
                                this.loading = false
                                this.inputdisabled = true
                                this.showNoUserMessage = true
                            }
                        })
                        .catch((error) => {
                            this.loading = false

                            console.log(error)
                        })
                } else {
                    this.loading = false
                    this.existMessage = "The passwords do not match"
                    this.exists = true
                    this.password = ""
                    this.passwordCheck = ""
                }
            },
            createInfo(message, color, icon) {
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            }
        },
        mounted() {
            if (this.$route.params.code) {
                this.inputdisabled = false
            } else {
                this.inputdisabled = true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .registercard {
        padding-bottom: 0px !important;
    }
    #loginbtn {
        font-size: 0.8em;
    }
    .existMessage {
        color: red;
    }
    .v-card--material__heading {
        margin-top: -50px;
    }
    #sidebarLogo {
        max-width: 300px;
        margin-top: -50px;
        border-radius: 30px;
    }
    #registercard {
        width: 450px;
        border-radius: 30px;
    }
    #whitelogo {
        padding-top: 70px;
    }
    #sidebarLogo {
        width: 300px;
        height: 170px;
        margin-top: -50px;
        border-radius: 30px;
        margin-bottom: 30px;
        background: linear-gradient(56deg, #019e86 0%, #009e85 59%, #0c8574 100%);
    }
    .registerLink {
        color: rgb(185, 185, 185);
        font-size: 0.9em;
        margin-bottom: -20px;
        padding-top: 25px;
        margin: 0%;
    }
    .registerLink:hover {
        cursor: pointer;
        color: #1cedb2;
    }
    .footer-col:hover {
        background-color: rgb(236, 236, 236);
        cursor: pointer;
    }
    .footer-col {
        display: flex;
        font-size: 0.8em;
        span {
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>
<style lang="sass">
    .v-card--material__heading
      top: -30px
    #register
      .v-list-item__subtitle
        -webkic-line-clamp: initial
        -webkit-box-orient: initial
</style>
