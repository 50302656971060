<template>
    <div>
        <v-dialog v-model="addUserDialog" max-width="450px" @click:outside="$emit('close')">
            <!-- delete dialog -->
            <!-- Info -->
            <v-snackbar top :color="info.color" v-model="showInfo">
                <v-icon>{{ info.icon }}</v-icon> <span class="font-weight-bold pl-2">{{ info.message }}</span>
            </v-snackbar>
            <v-card>
                <v-card-title>
                    <span class="mr-2"> Add User </span>
                </v-card-title>
                <v-card-text>
                    <v-text-field rounded filled class="mr-3" v-model="username" color="primary" label="Username" prepend-icon="mdi-face" required />
                    <v-text-field rounded filled class="mr-3" v-model="email" color="primary" label="Email..." type="email" prepend-icon="mdi-email" required />

                    <v-select
                        v-model="selectedlang"
                        :items="stateItems"
                        item-text="loc"
                        item-value="id"
                        label="Language"
                        prepend-icon="mdi-map-marker"
                        return-object
                        class="mb-5 mr-3"
                        rounded
                        filled
                        attach
                        required
                        color="primary">
                    </v-select>
                </v-card-text>
                <v-card-actions class="px-auto">
                    <v-btn class="mr-3" color="error" text rounded @click="$emit('close')"> Cancel </v-btn>
                    <v-btn :loading="loading" rounded class="ml-auto" color="primary" @click="validateInput"> Add User </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "AddUserDialog",
        props: {
            addUserDialog: Boolean,
            company_domains: Array
        },
        data() {
            return {
                info: {
                    message: null,
                    icon: null,
                    color: null
                },
                showInfo: false,
                username: "",
                email: "",
                // domainChecked: false,
                nameChecked: false,
                selectedlang: {
                    id: 1,
                    loc: "German",
                    cc: "de"
                },
                stateItems: [
                    {
                        id: 1,
                        loc: "German",
                        cc: "de"
                    },
                    {
                        id: 2,
                        loc: "English",
                        cc: "en"
                    }
                ],
                loading: false,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
            }
        },
        methods: {
            createInfo(message, color, icon) {
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },
            validateInput() {
                this.email = this.email.trim()
                this.loading = true
                if (this.username && this.email) {
                    this.validateMail()
                } else {
                    this.loading = false
                    this.createInfo("Username and email are required.", "#fcba03", "mdi-alert-circle-outline")
                }
            },
            validateMail() {
                // this.domainChecked = false
                let splits = this.email.split("@")
                if (splits[1]) {
                    let pointsplits = splits[1].split(".")
                    if (pointsplits[0] && pointsplits[1]) {
                        // this.domainChecked = true
                        this.checkIfExists()
                    } else {
                        this.loading = false
                        this.createInfo("Please enter a valid email adress!", "#fcba03", "mdi-alert-circle-outline")
                    }
                } else {
                    this.loading = false
                    // this.domainChecked = false
                    this.createInfo("Please enter a valid email adress!", "#fcba03", "mdi-alert-circle-outline")
                }
            },
            checkIfExists: function () {
                this.nameChecked = false
                let nameObject = {
                    user: this.email
                }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/user/namecheck", nameObject, {
                        headers: { Authorization: this.auth }
                    })
                    .then(function (response) {
                        if (response.body.register == false) {
                            this.email = ""
                            this.nameChecked = false
                            this.loading = false

                            this.createInfo("An user with this email already exists!", "#fcba03", "mdi-alert-circle-outline")
                        } else {
                            this.nameChecked = true
                            this.addUser()
                        }
                    })
            },
            addUser() {
                let registerObject = {
                    email: this.email,
                    username: this.username,
                    lang: this.selectedlang.cc,
                    company_id: localStorage.company_id
                }
                this.$http.post(process.env.VUE_APP_APIURL + "/user/register/settings", registerObject, { headers: { Authorization: this.auth } }).then((response) => {
                    console.log(response)
                    this.email = ""
                    this.username = ""
                    this.nameChecked = false
                    // this.domainChecked = false
                    this.loading = false

                    this.$emit("refreshData")
                    this.createInfo("User has been invited. The user should receice an email with an activation-link!", "green", "mdi-check-circle-outline")
                })
            }
        }
    }
</script>

<style scoped></style>
