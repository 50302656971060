<template>
    <div>
        <v-dialog v-model="dialog" max-width="450px" @click:outside="$emit('close')">
            <!-- delete dialog -->

            <v-card>
                <v-card-title>
                    <span class="mr-2"> Delete {{ deleteLabel }}</span>
                    <span class="no-line-break" v-if="selectedDeleteItem.length">
                        <strong class="px-0 mx-0">{{ selectedDeleteItem }}</strong>
                    </span>
                </v-card-title>
                <v-card-actions class="px-auto">
                    <v-btn class="mr-3" text rounded @click="$emit('close')"> Cancel </v-btn>
                    <v-btn :loading="loading" rounded class="ml-auto" color="error" @click="$emit('delete')"> Delete </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "DeleteDialog",
        props: {
            deleteDialog: Boolean,
            selectedDeleteItem: String,
            deleteLabel: String,
            loading: Boolean
        },
        computed: {
            dialog: {
                set(val) {
                    this.$emit("close")
                },
                get() {
                    return this.deleteDialog
                }
            }
        }
    }
</script>

<style scoped></style>
