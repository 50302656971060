var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[(_vm.show)?_c('div',{staticClass:"cont"},[_c('div',{staticClass:"select_menu"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-auto mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.redirect('/ideas')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-lightbulb-group-outline")])],1)]}}],null,false,2244123416)},[_c('span',[_vm._v("Get Ideas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-auto mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.redirect('/suggests')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-head-lightbulb")])],1)]}}],null,false,1893828655)},[_c('span',[_vm._v("Get Suggest")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-auto mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.redirect('/searchvolume')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-google")])],1)]}}],null,false,803486549)},[_c('span',[_vm._v("Get Search Volume")])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }