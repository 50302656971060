<template>
    <div id="chart-settings-wrapper">
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="info.show">
            <v-icon>{{ info.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>

        <v-card id="chart-settings" class="py-2 px-4" color="primary" dark flat link min-width="100" style="position: fixed; top: 285px; right: -35px; border-radius: 25px; z-index: 4; opacity: 0.7">
            <v-icon large> mdi-chart-timeline-variant</v-icon>
        </v-card>
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            activator="#chart-settings"
            bottom
            content-class="v-settings"
            left
            nudge-left="8"
            offset-x
            origin="top right"
            transition="scale-transition">
            <v-card class="text-center mb-0" width="300" id="clipboard">
                <v-card-text class="pa-0">
                    <v-expansion-panels accordion>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <div style="display: flex">
                                    <v-avatar color="rgb(236 236 236)" class="mr-3">
                                        <v-icon color="primary" class="mt-auto mb-auto">mdi-chart-timeline-variant</v-icon>
                                    </v-avatar>
                                    <span class="mt-auto mb-auto panel-title"> Line Chart</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <!-- <v-row class="pb-3">
                                    <v-col cols="12" class="py-0">
                                        <v-select
                                            rounded
                                            filled
                                            hide-details=""
                                            small-chips
                                            multiple
                                            @input="toggleLineChart(true)"
                                            chips
                                            deletable-chips
                                            return-object
                                            :items="chartHeaders"
                                            v-model="selectedLineHeaders"
                                            label="Visible datasets:"></v-select>
                                    </v-col>
                                </v-row> -->
                                <v-row style="align-items: center">
                                    <v-col cols="6" class="py-0">
                                        <v-switch
                                            v-if="!$store.state.user_settings.advancedMode"
                                            v-model="localLineChartVisible"
                                            @change="$emit('toggleLineChart')"
                                            class="ma-0 pa-0"
                                            :label="lineChartVisible ? 'active' : 'hidden'"
                                            hide-details>
                                        </v-switch>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <v-btn class="ml-auto" icon @click="$emit('settingsLineChart')"> <v-icon>mdi-cog</v-icon></v-btn>
                                        <v-btn class="ml-auto" icon @click="$emit('downloadLineChart')"> <v-icon>mdi-cloud-download</v-icon></v-btn>
                                        <v-btn class="ml-auto" icon @click="$emit('resetLineChart')"> <v-icon>mdi-refresh</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="!$store.state.user_settings.advancedMode">
                            <v-expansion-panel-header expand-icon="mdi-chevron-down">
                                <div style="display: flex">
                                    <v-avatar color="rgb(236 236 236)" class="mr-3">
                                        <v-icon color="primary" class="mt-auto mb-auto">mdi-chart-pie</v-icon>
                                    </v-avatar>
                                    <span class="mt-auto mb-auto panel-title"> Pie Chart</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="pb-3">
                                    <v-col cols="12" class="py-0">
                                        <v-select
                                            rounded
                                            filled
                                            hide-details=""
                                            small-chips
                                            multiple
                                            @input="togglePieChart(true)"
                                            chips
                                            deletable-chips
                                            return-object
                                            :items="chartHeaders"
                                            v-model="selectedPieHeaders"
                                            label="Visible datasets:"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row style="align-items: center">
                                    <v-col cols="6" class="py-0">
                                        <v-switch v-model="localPieChartVisible" @change="togglePieChart(false)" class="ma-0 pa-0" :label="pieChartVisible ? 'active' : 'hidden'" hide-details>
                                        </v-switch>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <!-- <v-btn class="ml-auto" icon @click="$emit('settingsPieChart')"> <v-icon>mdi-cog</v-icon></v-btn> -->
                                        <!-- <v-btn class="ml-auto" icon @click="$emit('downloadPieChart')"> <v-icon>mdi-cloud-download</v-icon></v-btn> -->
                                        <v-btn class="ml-auto" icon @click="$emit('resetPieChart')"> <v-icon>mdi-refresh</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: "ChartSettings",
        props: {
            lineChartVisible: Boolean,
            pieChartVisible: Boolean
        },
        data: () => ({
            // headers: [],
            localPieChartVisible: null,
            localLineChartVisible: null,
            menu: false,
            info: {
                message: null,
                icon: null,
                color: null,
                show: false
            }
        }),
        computed: {
            headers: {
                // getter
                get: function () {
                    return this.$store.state.header_items
                },
                // setter
                set: function (newValue) {
                    // does not get called -changes to deep? -> done in setter of selectedPieHeaders
                    this.$store.dispatch("CHANGE_HEADER_ITEMS", { items: newValue })
                    this.$emit("pieHeadersChanges", this.selectedPieHeaders)
                }
            },
            // selectable headers for pie chart
            chartHeaders() {
                if (this.headers && this.headers.modifiedHeaders) {
                    let chartHeaders = this.headers.modifiedHeaders.filter((header) => (header.custom && header.customOptions.type == "Dropdown") || header.value == "cats")
                    return chartHeaders
                }
                return []
            },
            // selected and visible pie chart headers
            selectedLineHeaders: {
                get: function () {
                    if (this.headers && this.headers.modifiedHeaders) {
                        let selectedLineHeaders = this.headers.modifiedHeaders.filter((header) => {
                            if (header.chart_options && header.chart_options.line_chart) {
                                return header.chart_options.line_chart.status.active === true
                            }
                            return false
                        })
                        return selectedLineHeaders
                    } else {
                        return 5
                    }
                },
                set: function (newHeaders) {
                    console.log(newHeaders)
                    this.headers.modifiedHeaders.map((header) => {
                        if (header.chart_options && header.chart_options.line_chart) {
                            header.chart_options.line_chart.status.active = false
                        } else {
                            if (header.chart_options) {
                                header.chart_options.line_chart = { status: { active: false } }
                            } else {
                                header.chart_options = { line_chart: { status: { active: false } } }
                            }
                        }
                    })
                    newHeaders.map((newHeader) => {
                        let index = this.headers.modifiedHeaders.findIndex((header) => header.value == newHeader.value)
                        if (index > -1) {
                            if (this.headers.modifiedHeaders[index].chart_options && this.headers.modifiedHeaders[index].chart_options.line_chart) {
                                this.headers.modifiedHeaders[index].chart_options.line_chart.status.active = true
                            } else {
                                if (this.headers.modifiedHeaders[index].chart_options) {
                                    this.headers.modifiedHeaders[index].chart_options.line_chart = { status: { active: true } }
                                } else {
                                    this.headers.modifiedHeaders[index].chart_options = { line_chart: { status: { active: true } } }
                                }
                            }
                        }
                    })
                    console.log(this.headers)
                    this.$store.dispatch("CHANGE_HEADER_ITEMS", { items: this.headers })
                    this.$emit("lineHeadersChanges", this.selectedLineHeaders)
                }
            },
            selectedPieHeaders: {
                get: function () {
                    if (this.headers && this.headers.modifiedHeaders) {
                        let selectedPieHeaders = this.headers.modifiedHeaders.filter((header) => {
                            if (header.chart_options && header.chart_options.pie_chart) {
                                return header.chart_options.pie_chart.status.active === true
                            }
                            return false
                        })
                        return selectedPieHeaders
                    } else {
                        return 5
                    }
                },
                set: function (newHeaders) {
                    console.log(newHeaders)
                    this.headers.modifiedHeaders.map((header) => {
                        if (header.chart_options && header.chart_options.pie_chart) {
                            header.chart_options.pie_chart.status.active = false
                        } else {
                            if (header.chart_options) {
                                header.chart_options.pie_chart = { status: { active: false } }
                            } else {
                                header.chart_options = { pie_chart: { status: { active: false } } }
                            }
                        }
                    })
                    newHeaders.map((newHeader) => {
                        let index = this.headers.modifiedHeaders.findIndex((header) => header.value == newHeader.value)
                        if (index > -1) {
                            if (this.headers.modifiedHeaders[index].chart_options && this.headers.modifiedHeaders[index].chart_options.pie_chart) {
                                this.headers.modifiedHeaders[index].chart_options.pie_chart.status.active = true
                            } else {
                                if (this.headers.modifiedHeaders[index].chart_options) {
                                    this.headers.modifiedHeaders[index].chart_options.pie_chart = { status: { active: true } }
                                } else {
                                    this.headers.modifiedHeaders[index].chart_options = { pie_chart: { status: { active: true } } }
                                }
                            }
                        }
                    })
                    console.log(this.headers)
                    this.$store.dispatch("CHANGE_HEADER_ITEMS", { items: this.headers })
                    this.$emit("pieHeadersChanges", this.selectedPieHeaders)
                }
            }
        },
        watch: {
            pieChartVisible(val) {
                this.localPieChartVisible = val
            },
            lineChartVisible(val) {
                this.localLineChartVisible = val
            }
        },
        methods: {
            toggleLineChart(forcedShow = false) {
                if (forcedShow && this.localPieChartVisible == false) {
                    this.$emit("togglePieChart")
                } else if (!forcedShow) {
                    console.log("elseif")
                    let lineHeaders = this.headers.modifiedHeaders.filter((header) => (header.custom && header.customOptions.type == "Dropdown") || header.value == "cats")
                    // ---------------- filters columns which are set to active
                    lineHeaders = lineHeaders.filter((header) => {
                        if (header.chart_options && header.chart_options.line_chart) {
                            return header.chart_options.line_chart.status.active === true
                        }
                        return false
                    })
                    if (lineHeaders.length === 0) {
                        let notification = { message: "There are no Columns activated for the Chart. Please add a dataset!", icon: "mdi-alert", color: "warning" }
                        this.$emit("createNotification", notification)
                        this.localLineChartVisible = false
                    } else {
                        this.$emit("toggleLineChart")
                    }
                }
            },
            togglePieChart(forcedShow = false) {
                console.log(forcedShow)
                if (forcedShow && this.localPieChartVisible == false) {
                    this.$emit("togglePieChart")
                } else if (!forcedShow) {
                    console.log("elseif")
                    let pieHeaders = this.headers.modifiedHeaders.filter((header) => (header.custom && header.customOptions.type == "Dropdown") || header.value == "cats")
                    // ---------------- filters columns which are set to active
                    pieHeaders = pieHeaders.filter((header) => {
                        if (header.chart_options && header.chart_options.pie_chart) {
                            return header.chart_options.pie_chart.status.active === true
                        }
                        return false
                    })
                    if (pieHeaders.length === 0) {
                        let notification = { message: "There are no Columns activated for the Chart. Please add a dataset!", icon: "mdi-alert", color: "warning" }
                        this.$emit("createNotification", notification)
                        this.localPieChartVisible = false
                    } else {
                        this.$emit("togglePieChart")
                    }
                }
            },
            createInfo(message, color, icon) {
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            }
        },
        mounted() {
            this.localPieChartVisible = this.pieChartVisible
            this.localLineChartVisible = this.lineChartVisible
            // this.headers = this.$store.state.header_items
        },
        updated() {}
    }
</script>
<style scoped>
    #maintitle {
        padding-top: 3px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        font-size: 1em;
    }
    #title {
        padding-top: 16px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        color: #212529;
        font-size: 1em;
    }
    #divider {
        border: 1px solid rgb(231, 231, 231);
        border-radius: 1px;
    }
    .panel-title {
        font-size: 1.15em;
    }
</style>
<style lang="sass" scoped>
    .v-settings
      .v-item-group > *
        cursor: pointer

      &__item
        border-width: 3px
        border-style: solid
        border-color: transparent !important

        &--active
          border-color: #00cae3 !important
    .infotext
      text-align: left

    .v-data-footer__select
      display: none
</style>
