<template>
    <v-row>
        <!-- Error Message -->
        <v-snackbar top color="#fcba03" v-model="showErrorMessage">
            <v-icon>mdi-alert-circle-outline</v-icon>
            <span class="font-weight-bold pl-2">Please insert correct website domains in the format "www.domain.de"!</span>
        </v-snackbar>
        <!-- Limit Message -->
        <v-snackbar top color="#fcba03" v-model="showLimitMessage">
            <v-icon>mdi-alert-circle-outline</v-icon>
            <span class="font-weight-bold pl-2">Please insert no more than 6 competitors!</span>
        </v-snackbar>

        <!-- Name Error Message -->
        <v-snackbar top color="#fcba03" v-model="showNameErrorMessage">
            <v-icon>mdi-alert-circle-outline</v-icon>
            <span class="font-weight-bold pl-2">Please insert a proper name for your project!</span>
        </v-snackbar>

        <!-- Empty Brand Message -->
        <v-snackbar top color="#fcba03" v-model="showEmptyBrandMessage">
            <v-icon>mdi-alert-circle-outline</v-icon>
            <span class="font-weight-bold pl-2">Please fill all brand phrases!</span>
        </v-snackbar>

        <!-- Server Error Message
        <v-snackbar top color="error" v-model="showErrorMessage">    
            <v-icon>mdi-alert-circle-outline</v-icon> <span class="font-weight-bold pl-2">An error accured! The Support is informed. Please try again later.</span>    
        </v-snackbar> -->

        <!-- Domain Error Message -->
        <v-snackbar top color="error" v-model="showDomainError">
            <v-icon>mdi-alert-circle-outline</v-icon>
            <span class="font-weight-bold pl-2">We couldn't find results for {{ errorTarget }}. Please double check! </span>
        </v-snackbar>

        <!-- Success Message -->
        <v-snackbar top color="success" v-model="showSuccessMessage">
            <v-icon>mdi-check-circle-outline</v-icon>
            <span class="font-weight-bold pl-2">Your Dashboard will be generated!</span>
        </v-snackbar>

        <!-- help dialog -->
        <v-dialog v-model="showDialog" scrollable max-width="350px">
            <v-card>
                <v-card-title>How does it work?</v-card-title>

                <v-card-text class="selectfiletype" id="selectfiletype">
                    <!-- Missing text -->
                </v-card-text>

                <v-card-actions id="downloadactions">
                    <v-spacer></v-spacer>
                    <v-btn dark color="primary" v-on:click="showDialog = false">close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- success dialog -->
        <v-dialog close v-model="showSuccessDialog" scrollable max-width="500px">
            <v-card>
                <v-card-title id="successHeader">
                    <img src="@/assets/owncheck.gif" width="100px" class="mx-auto mt-3 mb-3" id="checkgif" />
                </v-card-title>

                <v-card-text class="selectfiletype text-center pb-0 pt-4" id="succestext">
                    <!-- Missing text -->
                    <b>Your Dashboard will be created! </b>
                    <p class="mt-1">You will get notified when the process is finished.<v-icon small>mdi-bell-outline</v-icon></p>
                </v-card-text>

                <v-card-actions id="downloadactions" class="mb-2">
                    <v-btn class="mx-auto" dark color="pimary" v-on:click="showSuccessDialog = false">got it</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- main card -->
        <v-col cols="12" sm="12" md="8" xl="5" id="main">
            <v-card class="px-5 py-3 rounded-card">
                <v-row>
                    <v-sheet color="primary" class="pa-12" dark top id="tablesheet" rounded elevation="6">
                        <div class="headingsheet2">
                            <v-icon id="sheeticon" large>mdi-file-table </v-icon>
                        </div>
                    </v-sheet>

                    <v-card-title id="heading3"> Create a new Brand Dashboard </v-card-title>
                </v-row>
                <v-card-text>
                    <span class="existMessage">
                        {{ existMessage }}
                    </span>
                    <v-row>
                        <v-col>
                            <v-text-field label="Dashboard Name" @focus="existMessage = ''" v-model="documentName" required prepend-icon="mdi-pencil-box"></v-text-field>
                        </v-col>
                        <v-col xs12 id="kwpformflex">
                            <v-select
                                v-model="searchvolumeFrom"
                                :items="searchvolumeFromItems"
                                item-text="value"
                                label="country for searchvolume"
                                prepend-icon="mdi-map-marker"
                                return-object
                                overflow
                                attach>
                            </v-select>
                        </v-col>
                    </v-row>
                    <center>
                        <p class="mt-5 mb-2" id="selectext">Insert the brands as you would google them and pick a color:</p>
                    </center>
                    <div id="brandselection" class="mx-auto">
                        <v-row v-for="brand in brands" :key="brand.icon">
                            <v-col cols="10">
                                <v-text-field :label="brand.label" v-model="brand.brand" :prepend-icon="brand.icon" filled rounded></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-chip dark large v-on="on" :color="brand.color">
                                            <v-icon> mdi-palette </v-icon>
                                        </v-chip>
                                    </template>
                                    <v-color-picker v-model="brand.color" show-swatches class="mx-auto"></v-color-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </div>
                    <center>
                        <v-btn x-small v-if="brands.length > 1" fab color="primary" @click="deleteBrandField" class="mx-auto">
                            <v-icon> mdi-minus </v-icon>
                        </v-btn>
                    </center>
                    <br />

                    <center>
                        <v-btn x-small v-if="brands.length < 6" fab color="primary" @click="addBrandFields" class="mx-auto">
                            <v-icon> mdi-plus </v-icon>
                        </v-btn>
                    </center>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="prepareCreate()" class="mx-auto" large dark color="primary">create</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import { create } from "domain"
    export default {
        data() {
            return {
                brands: [
                    {
                        brand: "mercedes",
                        color: "blue",
                        icon: "mdi-numeric-1-circle",
                        label: "brand phrase no. 1"
                    },
                    {
                        brand: "audi",
                        color: "green",
                        icon: "mdi-numeric-2-circle",
                        label: "brand phrase no. 2"
                    }
                ],
                selectlenght: 2,
                showLimitMessage: false,
                showSuccessDialog: false,
                colorPickerValue: "white",
                color: "pink",
                errorTarget: "asdasd",
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                apiUrl: process.env.VUE_APP_APIURL,
                existMessage: "",
                showServerErrorMessage: false,
                showNameErrorMessage: false,
                showDomainError: false,
                showEmptyBrandMessage: false,
                documentName: "",
                showSuccessMessage: false,
                showErrorMessage: false,
                showDialog: false,
                items2: [],
                select2: [
                    { value: "mercedes", color: "green" },
                    { value: "audi", color: "blue" }
                ],
                select: ["mercedes", "audi"],
                selectObject: [
                    { domain: "mercedes", color: "blue" },
                    { domain: "audi", color: "green" }
                ],
                items: [],
                searchvolumeFrom: { lang: "1001", loc: "2276", value: "Germany" },
                searchvolumeFromItems: [
                    { lang: "1001", loc: "2276", value: "Germany" }
                    // {lang: "1001", loc: "2040", value: "Austria" },
                    // {lang: "1001", loc: "2756", value: "Switzerland" },
                    // {lang: "1000", loc: "2826", value: "United Kingdom" },
                    // {lang: "1004", loc: "2380", value: "Italy" },
                    // {lang: "1030", loc: "2616", value: "Poland" },
                    // {lang: "1031", loc: "2643", value: "Russia" },
                    // {lang: "1039", loc: "2191", value: "Croatia"},
                    // {lang: "1021", loc: "2203", value: "Czechia" },
                    // {lang: "1033", loc: "2703", value: "Slovakia"},
                    // {lang: "1034", loc: "2705", value: "Slovenia" },
                    // {lang: "1024", loc: "2348", value: "Hungary" },
                    // {lang: "1002", loc: "2250", value: "France" },
                ]
            }
        },
        methods: {
            deleteBrandField() {
                this.brands.pop()
            },
            addBrandFields() {
                if (this.brands.length >= 6) {
                    this.showLimitMessage = true
                    return
                }
                var min = Math.ceil(0)
                var max = Math.floor(255)
                var math1 = Math.floor(Math.random() * (max - min)) + min
                var math2 = Math.floor(Math.random() * (max - min)) + min
                var math3 = Math.floor(Math.random() * (max - min)) + min

                var color = "rgba(" + math1 + "," + math2 + "," + math3 + ",1)"

                let number = this.brands.length + 1
                let obj = { brand: "", color: color }
                obj.icon = "mdi-numeric-" + number + "-circle"
                obj.label = "brand phrase no. " + number

                this.brands.push(obj)
            },
            prepareCreate() {
                for (let brandsIndex = 0; brandsIndex < this.brands.length; brandsIndex++) {
                    if (this.brands[brandsIndex].brand.length == 0) {
                        this.showEmptyBrandMessage = true
                        return
                    }
                }

                try {
                    this.$matomo.trackEvent("AddBrandIndex", "prepareCreate", "Documentname: " + this.documentName, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }

                this.$http
                    .post(this.apiUrl + "/research/namecheck", { name: this.documentName, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        if (response.body.error) {
                            this.existMessage = "That name already exists, please try something else"
                            this.documentName = ""
                        } else {
                            var validString = /^[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u0020]+$/i.test(this.documentName)
                            if (validString) {
                                this.create()
                            } else {
                                this.existMessage = "Please only use alphabetic and numeric characters!"
                                this.documentName = ""
                            }
                        }
                    })
            },
            cleandata(value) {
                if (this.select.length > this.selectlenght) {
                    this.addSelectObject()
                    this.selectlenght++
                } else {
                    if (this.select.length == 0) {
                        this.selectlenght = 0
                    } else {
                        this.selectlenght--
                    }
                }

                if (this.select.length == 0) {
                    this.selectObject = []
                } else if (this.select.length > 4) {
                    this.select.length = 4
                    this.showLimitMessage = true
                } else {
                }
                if (this.selectObject.length > 4) {
                    this.selectObject.length = 4
                }
                for (let index = 0; index < this.selectObject.length; index++) {
                    let exists = this.select.indexOf(this.selectObject[index].domain)
                    console.log(exists)
                    if (exists === -1) {
                        this.selectObject = this.selectObject.filter((item) => {
                            return item.domain !== this.selectObject[index].domain
                        })
                        //    this.selectObject.splice(index, 1);
                        console.log(this.selectObject)
                    }
                }

                console.log(this.selectObject)
                console.log(this.select)
            },
            setColor(item) {
                console.log(item)
                for (let index = 0; index < this.selectObject.length; index++) {
                    // //console.log(this.selectObject[index].domain == item)
                    // //console.log(item)
                    // //console.log(this.selectObject)
                    if (this.selectObject[index].domain == item) {
                        this.selectObject[index].color = this.colorPickerValue
                        // //console.log(this.colorPickerValue)
                        // //console.log(this.selectObject[index].color)
                    }
                }
            },
            getColor(item) {
                for (let index = 0; index < this.select.length; index++) {
                    if (this.select[index] == item) {
                        return this.selectObject[index] ? this.selectObject[index].color : "white"
                    }
                }
                return "blue"
            },
            addSelectObject: function () {
                if (this.select.length > 4) {
                    this.select.length = 4
                    this.showLimitMessage = true
                }
                if (this.selectObject.length > 4) {
                    this.selectObject.length = 4
                }
                this.selectObject.push({
                    domain: this.select[this.select.length - 1],
                    color: "#" + Math.floor(Math.random() * 16777215).toString(16)
                })
            },
            checkIfExists: function () {
                return new Promise((resolve, reject) => {
                    if (this.documentName != "") {
                        this.$http
                            .post(this.apiUrl + "/research/namecheck", { name: this.documentName, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                            .then(function (response) {
                                if (response.body.error) {
                                    this.existMessage = "That name already exists, please try something else"
                                    this.documentName = ""
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                    }
                })
            },
            create() {
                try {
                    let brands = JSON.stringify(this.brands)
                    // console.log(brands)
                    this.$matomo.trackEvent("AddBrandIndex", "create", brands, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                // let validated = this.validateDomains();
                // this.checkDomains()
                if (this.brands.length > 0) {
                    // if (validated) {
                    if (this.documentName.length > 4) {
                        let postdata = {
                            listname: this.documentName,
                            company: localStorage.company_id,
                            user: localStorage.id,
                            brands: this.getLowerCase(this.brands),
                            langloc: this.searchvolumeFrom,
                            socket_id: this.$socket.id
                        }
                        // //console.log(postdata)
                        this.$http
                            .post(this.apiUrl + "/brands/generateindex/", postdata, {
                                headers: { Authorization: this.auth }
                            })
                            .then(function (response) {
                                // //console.log(response.body)
                                // this.showSuccessDialog = true;
                                this.brands = [
                                    {
                                        brand: "",
                                        color: "",
                                        icon: "mdi-numeric-1-circle",
                                        label: "brand phrase no. 1"
                                    },
                                    {
                                        brand: "",
                                        color: "",
                                        icon: "mdi-numeric-2-circle",
                                        label: "brand phrase no. 2"
                                    }
                                ]
                                this.selectObject = []
                                this.documentName = ""
                                setTimeout(() => {
                                    this.$router.push("/brands")
                                }, 2000)
                            })
                            .catch((err) => {
                                // //console.log(err)
                                this.errorTarget = err.body.error.target
                                this.showDomainError = true
                            })
                    } else {
                        this.showNameErrorMessage = true
                    }
                    // } else {

                    //     this.showErrorMessage = true;
                    //     this.select.pop()
                    //     this.selectObject.pop()
                    // }
                } else {
                    this.showErrorMessage = true
                }
            },
            getLowerCase(brands) {
                for (let index = 0; index < brands.length; index++) {
                    brands[index].brand = brands[index].brand.toLowerCase()
                }
                return brands
            },

            getLowerCase2(competitors, callback) {
                for (let index = 0; index < competitors.length; index++) {
                    competitors[index] = competitors[index].toLowerCase()
                }
                // //console.log(competitors)
                return competitors
            }
        }
    }
</script>

<style lang="scss" scoped>
    #brandselection {
        width: 80%;
    }
    #selectext {
        margin-bottom: 0px;
        font-size: 1.1em;
    }
    .existMessage {
        color: red;
    }
    #main {
        margin-left: auto;
        margin-right: auto;
    }
    .iconarrow {
        padding-top: 6px;
    }
    #iconcricle {
        padding-bottom: 30px !important;
        margin: auto;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 2px solid white;
    }

    .explaintext {
        padding-top: 30px !important;
        padding-bottom: auto;
    }

    .explainer {
        font-size: 15px;
        height: 250px;
        width: 100%;
        background-color: rgba(66, 222, 250, 0.7);
        background-image: linear-gradient(55deg, rgba(66, 152, 250, 0.7) 0%, rgba(66, 222, 250, 0.7) 50%);
        color: #fff;
        text-align: center;
        padding: 15px;
        border-radius: 6px;
    }
    .headingsheet2 {
        margin-top: -15px;
    }
    #sheeticon {
        margin-left: -25px;
        margin-top: -25px;
        margin: auto !important;
        padding-bottom: 50% !important;
        width: 50% !important;
    }
    .rounded-card {
        margin-top: 30px;
    }
    #tablesheet {
        margin-left: 10px;
        margin-top: -30px;
        width: 60px;
        height: 60px;
    }
    #heading3 {
        font-weight: 300 !important;
        color: rgb(105, 105, 105);
    }
    .v-select .v-chip {
        display: block;
    }
    #succestext {
        margin-top: -25px;
        font-family: raleway;
        font-size: 1.1em;
    }
    #checkgif {
        margin-top: -10px;
    }
</style>
