<template>
    <span>
        <!-- ---------------------- -->
        <!-- Chart Download Dialog: -->
        <!-- ---------------------- -->

        <v-dialog v-model="chartDownloadDialog.display" scrollable max-width="350px" id="downloaddialog">
            <v-card>
                <strong id="title" class="d-inline-block">{{ chartDownloadDialog.title }}</strong>
                <v-card-text class="pt-5">
                    <center>
                        <v-btn color="primary" large rounded @click="downloadChart(0)">Download as PDF</v-btn>
                    </center>
                    <br />
                    <center>
                        <v-btn color="primary" large rounded @click="downloadChart(1)">Download as PNG</v-btn>
                    </center>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- ---------------------- -->
        <!-- Chart Card -->
        <!-- ---------------------- -->
        <v-card v-if="show" class="rounded_card" id="pie_chart_card">
            <div class="backsheet"></div>
            <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" fab color="primary" top small right absolute id="pie_chart_menu">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list rounded dense>
                    <!-- <v-list-item class="pl-2" @click="chartDownloadDialog.display = true">
                        <v-icon class="mr-2">mdi-cloud-download</v-icon>
                        <v-list-item-title>Download Chart</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item class="pl-2" @click="resetChart">
                        <v-icon class="mr-2">mdi-refresh</v-icon>
                        <v-list-item-title>Reset Chart</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="JSON.stringify(user_settings) !== '{}'" class="pl-2" @click="$emit('hide')">
                        <v-icon class="mr-2">mdi-eye-off</v-icon>
                        <v-list-item-title>Hide Chart</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item class="pl-2" @click="chartSettingsDialog.display = true"> <v-icon class="mr-2">mdi-cog</v-icon><v-list-item-title>Settings</v-list-item-title> </v-list-item> -->
                </v-list>
            </v-menu>
            <v-card-title class="pb-4">
                {{ graphCardData.title }}
            </v-card-title>
            <v-card-subtitle class="pb-2">
                <!-- <span v-if="showTop10Warning" class="top10_warning"> only Top 10 Categories are displayed</span> -->
                <!-- {{ graphCardData.subTitle }} -->
                <v-row>
                    <div class="mr-auto ml-2">
                        <span class="pl-1" v-for="columnButton in columns" :key="columnButton.id">
                            <v-btn dark :color="!columnButton.isActive ? 'grey' : 'primary'" x-small @click="setActiveDataset(columnButton)">{{ columnButton.text }}</v-btn>
                        </span>
                    </div>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <pie-chart :render="renderChart" :chartData="chartData" :options="options"></pie-chart>
            </v-card-text>
        </v-card>
    </span>

    <!-- ---------------------- -->
    <!-- Chart Settings Dialog: -->
    <!-- ---------------------- -->

    <!-- Chart settings   -->
    <!-- <v-dialog v-model="chartSettingsDialog.display" scrollable max-width="350px" id="downloaddialog">
            <v-card>
                <strong id="title" class="d-inline-block">{{ chartSettingsDialog.title }}</strong>
                <v-card-text class="pt-5">
                    <span class="ma-5" v-for="(slider, i) in settingsSlider" v-bind:key="i">
                        <v-slider v-model="slider.value" class="align-center" :max="slider.max" @change="setChartOptions" :min="slider.min" thumb-label hide-details>
                            <template v-slot:append>
                                <div>{{ slider.value }}</div>
                            </template>
                            <template v-slot:prepend>
                                <div class="labeldiv">{{ slider.label }}</div>
                            </template>
                        </v-slider>
                    </span>
                    <v-divider></v-divider>
                    <v-switch class="" @change="setChartOptions" label="Always start at zero" v-model="beginAtZero"></v-switch>
                </v-card-text>
            </v-card>
        </v-dialog> -->
</template>

<script>
    import PieChart from "./ColumnsPieChart"
    import canvasToImage from "canvas-to-image"
    import { jsPDF } from "jspdf"
    import { colorCodes } from "../helper/global.js"

    export default {
        components: {
            PieChart
        },
        name: "KeywordPieChart",
        props: {
            render: Number,
            show: Boolean,
            keywords: Array,
            headers: Object,
            resetPieChart: Number
        },
        data: () => ({
            columns: [],
            renderChart: 0,
            chartData: {
                // labels: ["Italy", "India", "Japan", "USA"],
                // datasets: [
                //     {
                //         borderWidth: 1,
                //         borderColor: ["rgba(255,99,132,1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)"],
                //         backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)"],
                //         data: [1000, 500, 1500, 1000],
                //         hidden: false
                //     },
                //     {
                //         borderWidth: 1,
                //         borderColor: ["rgba(255,99,132,1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)"],
                //         backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)"],
                //         data: [1000, 500, 1500, 1000],
                //         hidden: true
                //     }
                // ]
            },
            options: {
                tooltips: {
                    callbacks: {
                        label: function (tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].labels[tooltipItems.index] + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toLocaleString()
                        }
                    }
                },

                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false,
                showTop10Warning: false
            },

            chartDownloadDialog: {
                display: false,
                title: "Chart Download"
            },
            graphCardData: {
                title: "Search Volume by Category",
                subTitle: "Analyze Google Search of categories"
            }
        }),
        watch: {
            render() {
                this.initPieChart(this.keywords, this.headers)
            },
            resetPieChart() {
                this.resetChart()
            }
        },
        computed: {
            user_settings() {
                return this.$store.state.user_settings
            }
        },
        methods: {
            /**
             * triggers rerender of the chart component
             * by increasing watched prop
             */
            renderPieChart() {
                this.renderChart++
            },
            /**
             * @param {Array} keywords all keywords rows
             * @param {Array} headers header object (standard and modified)
             */
            initPieChart(keywords, headers) {
                // ---------------- filters all possible pie columns //TODO needed ???
                let pieHeaders = headers.modifiedHeaders.filter((header) => (header.custom && header.customOptions.type == "Dropdown") || header.value == "cats")
                // ---------------- filters columns which are set to active
                pieHeaders = pieHeaders.filter((header) => {
                    if (header.chart_options && header.chart_options.pie_chart) {
                        return header.chart_options.pie_chart.status.active === true
                    }
                    return false
                })
                // ---------------- return if no colums for pie Charts
                if (pieHeaders.length === 0) {
                    console.log("no columsn for pie chart")
                    this.$emit("hide")
                    return
                }
                // let dropDownHeaders = customHeaders.filter((header) => header.customOptions.type == "Dropdown")
                this.columns = pieHeaders.map((header, index) => {
                    let column = {}
                    column.color = ""
                    column.id = index
                    column.text = header.modifiedText
                    column.value = header.value
                    column.isActive = index === 0 ? true : false
                    return column
                })
                this.createAllDatasets(keywords, pieHeaders)
                this.setActiveDataset(this.columns[0])
            },
            /** creates and sets all datasets
             * @param {Array} keywords  all keywords rows
             * @param {Array} pieHeaders custom headers with type dropdown
             */
            createAllDatasets(keywords, pieHeaders) {
                let datasets = pieHeaders.map((header, index) => {
                    let dataset = {}
                    if (header.value == "cats") {
                        dataset = this.getCategoryDataSet(keywords, header, index)
                    } else {
                        dataset = {
                            id: index,
                            borderWidth: 3,
                            borderColor: this.borderColors(header),
                            backgroundColor: this.getBackgroundColors(header),
                            data: this.getDataByOptionEntries(keywords, header),
                            hidden: true,
                            labels: this.getDatasetLabels(header)
                        }
                    }
                    return dataset
                })

                console.log(datasets)
                let sortedDatasets = this.sortDatasets(datasets)
                this.chartData.datasets = datasets
            },
            /**
             * called by template button
             * @param {Object} selectedColumn Column or Header Data of selected
             * calculates the data for pie chart based on selection
             */
            setActiveDataset(selectedColumn) {
                // sets active column button bsaed on selection
                this.columns.map((column) => {
                    column.id == selectedColumn.id ? (column.isActive = true) : (column.isActive = false)
                })
                // sets active dataset and labels bsaed on selection
                this.chartData.datasets.map((dataset, index) => {
                    if (dataset.id == selectedColumn.id) {
                        dataset.hidden = false
                        this.chartData.labels = dataset.labels
                        const element = this.chartData.datasets.splice(index, 1)[0]
                        this.chartData.datasets.splice(0, 0, element)
                    } else {
                        dataset.hidden = true
                    }
                    // dataset.id == selectedColumn.id ? (dataset.hidden = false) : (dataset.hidden = true)
                })
                this.renderPieChart()
            },
            /**
             * helper for creating Datasets
             * @param {Object} header
             * @returns {Array} backgroundColors
             */
            getBackgroundColors(header) {
                let backgroundColors = header.customOptions.entries.map((entry) => {
                    let colorCodes = this.$helpers.hexToRgb(entry.color.hex)
                    let rgba = "rgba(" + colorCodes + ", 0.4)"
                    return colorCodes ? rgba : ""
                })
                return backgroundColors
            },
            /**
             * helper for creating Datasets
             * @param {Object} header
             * @returns {Array} borderColors
             */
            borderColors(header) {
                let borderColors = header.customOptions.entries.map((entry) => {
                    return entry.color.hex
                })
                return borderColors
            },
            /**
             * helper for creating Datasets | sums up searchvolume for Entries
             * @param {Array}   keywords
             * @param {Array}   header
             * @returns {Array} data
             */
            getDataByOptionEntries(keywords, header) {
                let data = header.customOptions.entries.map((optionEntry) => {
                    let keywordsWithOption = keywords.filter((keyword) => keyword.customs[header.value].text == optionEntry.text)
                    let sum = this.$helpers.sumUpByAttribute(keywordsWithOption, "annualsv")
                    return sum
                })
                return data
            },
            /**
             * return labels for dataset based on entries
             * @param {Object} header
             * @returns {Array} labels
             */
            getDatasetLabels(header) {
                let labels = header.customOptions.entries.map((entry) => {
                    return entry.text
                })
                return labels
            },
            /**
             * calculates all date for google categories column
             * @param {*} keywords
             * @param {*} header
             * @param {*} header_index
             */
            getCategoryDataSet(keywords, header, header_index) {
                // extract cat names for labels
                let labels = ["no category"]
                // keywords = keywords.filter((keyword) => keyword.cats && keyword.cats[0])
                for (let index = 0; index < keywords.length; index++) {
                    let catname = ""
                    if (keywords[index].cats && keywords[index].cats[0]) {
                        catname = keywords[index].cats[keywords[index].active_cat_index][keywords[index].cats[keywords[index].active_cat_index].length - 1].category_name
                        if (!labels.includes(catname)) {
                            labels.push(catname)
                        }
                    }
                }
                if (labels.length > 10) {
                    this.showTop10Warning = true
                }
                let top10labels = labels.slice(0, 9)
                let othersLabels = labels.slice(9, labels.length - 1)
                console.log(top10labels)
                console.log(othersLabels)
                // calculate data for each label
                let data = top10labels.map((label) => {
                    let keywordsWithOption = []
                    if (label != "no category") {
                        keywordsWithOption = keywords.filter(
                            (keyword) => keyword.cats && keyword.cats[keyword.active_cat_index][keyword.cats[keyword.active_cat_index].length - 1].category_name == label
                        )
                    } else {
                        keywordsWithOption = keywords.filter((keyword) => !keyword.cats)
                    }
                    let sum = this.$helpers.sumUpByAttribute(keywordsWithOption, "annualsv")
                    return sum
                })
                // group data of categories if more than 10 to "OTHERS"
                // if (othersLabels.length > 0) {
                //     let keywordsWithOption = keywords.filter(
                //         (keyword) => keyword.cats && othersLabels.includes(keyword.cats[keyword.active_cat_index][keyword.cats[keyword.active_cat_index].length - 1].category_name)
                //     )
                //     let sum = this.$helpers.sumUpByAttribute(keywordsWithOption, "annualsv")
                //     top10labels.push(othersLabels.length + " more Categories")
                //     data.push(sum)
                // }

                //sort data & labels

                // get colorcodes
                let borderColors = labels.map((label, index) => {
                    return colorCodes[index] ? "rgb(" + colorCodes[index] + ")" : this.$helpers.getRandomRGBColor()
                })

                // add opacity
                let backgroundColors = borderColors.map((color) => {
                    let index = color.indexOf(")")
                    let rgba = color.slice(0, index) + ", 0.4" + color.slice(index)
                    index = color.indexOf("(")
                    rgba = rgba.slice(0, index) + "a" + rgba.slice(index)
                    return rgba
                })

                let dataset = {
                    id: header_index,
                    borderWidth: 3,
                    borderColor: borderColors,
                    backgroundColor: backgroundColors,
                    data: data,
                    hidden: true,
                    labels: top10labels
                }
                console.log(dataset)
                return dataset
            },
            /**
             * sort datasets based on search volume
             * sorts data, labels and colos
             * @param {*} datasets
             * @returns sorted datasets
             */
            sortDatasets(datasets) {
                console.log(datasets)

                let sortedDatasets = datasets.map((dataset) => {
                    let helperObject = []
                    for (let index = 0; index < dataset.labels.length; index++) {
                        let obj = {}
                        obj.value = dataset.data[index]
                        obj.borderColor = dataset.borderColor[index]
                        obj.backgroundColor = dataset.backgroundColor[index]
                        obj.label = dataset.labels[index]
                        helperObject.push(obj)
                    }
                    let sorted = helperObject.sort((a, b) => b.value - a.value)
                    return sorted
                })
                console.log(sortedDatasets)
                let sortedandFormatted = datasets.map((dataset, index) => {
                    dataset.data = sortedDatasets[index].map((entry) => entry.value)
                    dataset.borderColor = sortedDatasets[index].map((entry) => entry.borderColor)
                    dataset.backgroundColor = sortedDatasets[index].map((entry) => entry.backgroundColor)
                    dataset.labels = sortedDatasets[index].map((entry) => entry.label)
                    return dataset
                })
                return sortedandFormatted
            },
            /**
             * refreshes the chart
             */
            resetChart() {
                this.initPieChart(this.keywords, this.headers)
                console.log("reset chart")
            },
            /**
             * creates PDF or PNG
             * downloads created file
             * @param {} format selected file format
             * 0 -> PDF
             * 1 -> PNG
             */
            downloadChart(format) {
                const canvasEl = document.getElementById("pie_chart_card")
                if (format === 0) {
                    const doc = new jsPDF("landscape")
                    const ratio = canvasEl.width / canvasEl.height
                    const width = doc.internal.pageSize.getWidth()
                    const height = width / ratio
                    doc.addImage(canvasEl, "PNG", 0, height / 2, width, height)
                    doc.save("categories.pdf")
                } else if (format === 1) {
                    canvasToImage(canvasEl, {
                        name: "Google Search Volume",
                        type: "png",
                        quality: 1
                    })
                }
            }
        },
        mounted() {
            if (this.keywords.length > 0 && this.headers.modifiedHeaders.length > 0) {
                this.initPieChart(this.keywords, this.headers)
            }
        }
    }
</script>

<style scoped></style>
