<template>
    <div>
        <span class="mt-10" style="font-size: 12px; display: block">
            <div class="pb-1">
                <v-chip x-small color="" class="mr-2" style="text-transform: capitalize">
                    <v-icon x-small class="mr-1">{{ device == "mobile" ? "mdi-cellphone" : "mdi-monitor" }}</v-icon>
                    {{ device }}
                </v-chip>
                <v-chip x-small color="" class="mr-2" style="text-transform: uppercase">
                    <v-icon x-small class="mr-1">mdi-map-marker</v-icon>
                    {{ language_code }}
                </v-chip>
                <v-btn :disabled="shared_company_id ? true : false" rounded x-small outlined color="primary" @click="workflowDialog = true" class="qchip pr-1">
                    <v-icon class="mr-1" small>mdi-list-status</v-icon>
                    {{ project.workflow ? project.workflow.active_stage.title : "Briefing" }}
                    <v-icon class="ml-1" small>mdi-chevron-down</v-icon>
                </v-btn>
            </div>
        </span>
        <v-row no-gutters style="font-size: 24px" class="mb-1"
            >{{ title }}
            <div class="flex" style="height: 100%; width: 100px">
                <div class="ml-2">
                    <v-btn
                        v-if="!shared_extern"
                        :disabled="$store.state.content_suite_group.id !== 1"
                        @click="
                            shareMenu = true
                            shareItem = {
                                _id: id,
                                listname: title
                            }
                        "
                        style="padding-right: 2px"
                        color="primary"
                        elevation="0"
                        height="24px"
                        width="24px"
                        class="mb-1"
                        fab
                        ><v-icon x-small>mdi-share-variant</v-icon></v-btn
                    >
                </div>
            </div>
        </v-row>

        <navi class="mt-2" :shared_extern="shared_extern" :share_data="share_data" :shared_company_id="shared_company_id" :id="id" :active="active"></navi>
        <Sharedialog :shareMenu="shareMenu" :shareItem="shareItem" @disableShareMenu="shareMenu = false" :mountedOn="active"> </Sharedialog>

        <WorkflowDialog @close="workflowDialog = false" :show="workflowDialog" :project="project" :shared_company_id="shared_company_id"></WorkflowDialog>
    </div>
</template>

<script>
    import Sharedialog from "../dialogs/shareDialog/Sharedialog.vue"
    import navi from "./Navi.vue"
    import WorkflowDialog from "./Workflow.vue"
    export default {
        name: "ComponentHeader",
        components: {
            navi,
            Sharedialog,
            WorkflowDialog
        },
        props: {
            title: String,
            device: String,
            id: String,
            project: Object,
            language_code: String,
            active: Object,
            shared_extern: Boolean,
            share_data: Object,
            shared_company_id: String
        },
        data() {
            return {
                shareMenu: false,
                shareItem: {},
                workflowDialog: false
            }
        },
        methods: {}
    }
</script>

<style></style>
