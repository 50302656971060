<template>
    <v-dialog v-if="show" v-model="show" max-width="500px" transition="slide-x-transition" @click:outside="$emit('close')">
        <v-card>
            <div class="backsheet"></div>
            <v-btn class="close-btn" absolute top right icon color="primary" @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
            <v-card-title class="pr-5" style="white-space: normal">
                <span class="pr-10"> {{ project.title }} </span>
            </v-card-title>
            <v-card-subtitle>
                <span style="font-size: 12px; display: block">
                    <div class="pb-1 mt-1">
                        <v-chip x-small color="" class="mr-2" style="text-transform: capitalize">
                            <v-icon x-small class="mr-1">{{ project.device == "mobile" ? "mdi-cellphone" : "mdi-monitor" }}</v-icon>
                            {{ project.device }}
                        </v-chip>
                        <v-chip x-small color="" class="mr-2" style="text-transform: uppercase">
                            <v-icon x-small class="mr-1">mdi-map-marker</v-icon>
                            {{ project.langloc.language_code }}
                        </v-chip>
                    </div>
                </span>
            </v-card-subtitle>
            <v-card-text class="pb-0">
                <v-tabs centered v-model="tab" color="primary" class="white--text">
                    <v-tab>General</v-tab>
                    <v-tab>Briefing</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <div>
                            <div class="pb-4 qgreycard">
                                <div class="lbl pb-2 pb-2">
                                    PROJECT TITLE
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>The "Project Title" field specifies the title of your content project. </span>
                                    </v-tooltip>
                                </div>
                                <v-text-field type="text" @input="titleChanged = true" rounded filled v-model="title" hint="e.g. Optimization: KW-Research" persistent-hint></v-text-field>
                            </div>
                            <div class="qgreycard">
                                <div class="lbl pb-1">WEBSITE URL</div>

                                <v-row
                                    ><v-col class="d-flex">
                                        <v-text-field
                                            class="my-2"
                                            @input="urlChanged = true"
                                            rounded
                                            filled
                                            v-model="url"
                                            hint="e.g. https://www.quaro.io/keyword-recherche"
                                            persistent-hint></v-text-field>
                                        <!-- <v-btn rounded class="mt-5 ml-3" color="primary">
                                            <v-icon class="mr-1" small> {{ project.url ? "mdi-pencil" : "mdi-plus" }} </v-icon>
                                            {{ project.url ? "edit" : "add" }}</v-btn
                                        > -->
                                    </v-col></v-row
                                >
                            </div>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row class="qgreycard" no-gutters>
                            <v-col>
                                <div class="lbl pb-2 pb-2">
                                    TARGETED LENGTH OF CONTENT
                                    <v-tooltip max-width="450" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span
                                            >This field allows you to specify the desired length of the content for your project. The default value is based on the average length of content produced
                                            by your competitors. It is important to consider the attention span of your target audience, the complexity of the topic, and the desired level of detail
                                            when choosing the length of your content.
                                        </span>
                                    </v-tooltip>
                                </div>
                                <v-text-field
                                    @input="settingsChanged = true"
                                    rounded
                                    filled
                                    type="num"
                                    v-model="settings.word_count"
                                    :hint="'avg. competitors content length: ' + Math.ceil(project.avgWordCount)"
                                    persistent-hint></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="qgreycard" no-gutters>
                            <v-col>
                                <div class="lbl pb-2 pb-2">
                                    TARGETED TERM USAGE
                                    <v-tooltip max-width="450" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span
                                            >Allows you to specify the desired frequency and distribution of keywords and phrases in your content. For example: 75% of your selected content phrases
                                            must be used at least as often as your competitors do on average.
                                        </span>
                                    </v-tooltip>
                                </div>
                                <v-select
                                    hint="Default: 75%"
                                    persistent-hint
                                    :items="termusages"
                                    v-model="settings.term_usage"
                                    rounded
                                    @input="settingsChanged = true"
                                    filled
                                    item-text="text"
                                    item-value="value"></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="qgreycard" no-gutters>
                            <v-col>
                                <div class="lbl pb-2 pb-2">
                                    TARGETED READABILITY
                                    <v-tooltip max-width="450" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span
                                            >Allows you to specify the desired level of readability for your content based on the Flesh Reading Ease Index. The index measures how easy or difficult it
                                            is to read a particular piece of content. It is important to create content that is easy to read and understand, as this can increase user engagement and
                                            sharing.</span
                                        >
                                    </v-tooltip>
                                </div>
                                <v-select
                                    hint="Default: normal"
                                    persistent-hint
                                    :items="readabilities"
                                    @input="settingsChanged = true"
                                    v-model="settings.readability"
                                    rounded
                                    filled
                                    item-text="text"
                                    return-object></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="qgreycard" no-gutters>
                            <v-col>
                                <div class="lbl pb-2 pb-2">
                                    TARGETED IMAGE USAGE
                                    <v-tooltip max-width="450" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span
                                            >Allows you to specify the desired frequency of images in your content. The default value is based on the average image usage of your competitors. Including
                                            relevant and high-quality images can enhance the visual appeal of your content and increase user engagement.
                                        </span>
                                    </v-tooltip>
                                </div>
                                <v-text-field
                                    :hint="'avg. competitors Image Count: ' + project.avgImgCount"
                                    persistent-hint
                                    @input="settingsChanged = true"
                                    v-model="settings.img_count"
                                    rounded
                                    type="number"
                                    filled></v-text-field>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn class="mx-auto" color="primary" @click="saveProject" rounded>
                    <v-progress-circular width="2" v-if="loading" size="24" indeterminate></v-progress-circular>
                    <span v-else> save</span>
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-if="notification" top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            project: Object,
            show: Boolean
        },
        data() {
            return {
                settings: {},
                url: "",
                title: "",
                notification: { show: false },
                titleChanged: false,
                settingsChanged: false,
                urlChanged: false,
                tab: 0,
                readabilities: [
                    { id: 1, min: 0, max: 20, text: "very difficult", color: "red darken-4" },
                    { id: 2, min: 20, max: 40, text: "difficult", color: "red darken-2" },
                    { id: 3, min: 40, max: 50, text: "demanding", color: "orange darken-2" },
                    { id: 4, min: 50, max: 60, text: "normal", color: "amber darken-2" },
                    { id: 5, min: 60, max: 70, text: "simple", color: "yellow darken-2" },
                    { id: 6, min: 70, max: 80, text: "easy", color: "green darken-1" },
                    { id: 7, min: 80, max: 90, text: "very easy", color: "light-green darken-2" }
                ],
                termusages: [
                    { text: "10%", value: 10 },
                    { text: "15%", value: 10 },
                    { text: "20%", value: 10 },
                    { text: "25%", value: 25 },
                    { text: "30%", value: 30 },
                    { text: "35%", value: 35 },
                    { text: "40%", value: 40 },
                    { text: "45%", value: 45 },
                    { text: "50%", value: 50 },
                    { text: "55%", value: 55 },
                    { text: "60%", value: 60 },
                    { text: "65%", value: 65 },
                    { text: "70%", value: 70 },
                    { text: "75%", value: 75 },
                    { text: "80%", value: 80 },
                    { text: "85%", value: 85 },
                    { text: "90%", value: 90 },
                    { text: "95%", value: 95 },
                    { text: "100%", value: 100 }
                ],
                loading: false
            }
        },
        computed: {},
        watch: {
            show(val) {
                if (val) {
                    this.title = this.project.title + ""
                    this.url = this.project.url + ""
                    this.settings = JSON.parse(JSON.stringify(this.project.settings))
                }
                this.urlChanged = false
                this.titleChanged = false
                this.settingsChanged = false
            }
        },
        methods: {
            saveProject() {
                if (this.urlChanged) {
                    // update reference URL
                    this.updateURL()
                }
                if (this.titleChanged || this.settingsChanged) {
                    this.updateProject()
                }
                if (!this.urlChanged && !this.titleChanged && !this.settingsChanged) {
                    this.$emit("close")
                }
            },
            updateProject() {
                try {
                    const url = process.env.VUE_APP_APIURL + "/cs/edit/project"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.project._id,
                        title: this.titleChanged ? this.title : null,
                        settings: this.settingsChanged ? this.settings : null
                    }
                    this.$helpers.axiosPost(url, post_data)
                    this.loading = true
                    setTimeout(() => {
                        this.$emit("refresh")

                        this.loading = false
                    }, 500)
                } catch (error) {
                    this.notification = this.$helpers.createNotification("Something went wrong adding your URL! Please try again or contact support.", "mdi-alert", "orange darken-2")
                }
            },
            updateURL() {
                try {
                    if (!this.url.includes("https://")) this.url = "https://" + this.url
                    console.log(this.url)
                    let checkurl = new URL(this.url)
                    console.log(checkurl)
                } catch (error) {
                    this.notification = this.$helpers.createNotification("Please enter a complete and accurate website URL!", "mdi-alert", "orange darken-2")
                    return
                }
                try {
                    const url = process.env.VUE_APP_APIURL + "/cs/add/url"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.project._id,
                        url: this.url,
                        mainurl: true,
                        addLighthouseData: true,
                        user_id: localStorage.id,
                        socket_id: this.$socket.id
                    }
                    this.$helpers.axiosPost(url, post_data)
                    this.$emit("close")
                    this.trackEvent("CS - AddURLDialog", this.url, "", "")
                } catch (error) {
                    this.notification = this.$helpers.createNotification("Something went wrong adding your URL! Please try again or contact support.", "mdi-alert", "orange darken-2")
                }
            }
        }
    }
</script>

<style></style>
