<template>
    <div>
        <v-dialog v-model="editDialog" max-width="500px" @click:outside="$emit('close')">
            <v-snackbar top v-model="notification.show" :color="notification.color">
                <v-icon>{{ notification.icon }}</v-icon>
                <span class="font-weight-bold pl-2"> {{ notification.message }} </span>
            </v-snackbar>
            <v-card>
                <div class="dialog-title">
                    <span class="mb-5 d-inline-block mx-auto dialog-title-text"> {{ cardData.title }}</span>
                    <v-btn right absolute @click="$emit('close')" class="dialog-close-btn" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
                </div>

                <!-- <v-card-title class="addprojectstitle mb-5">
                    {{ cardData.title }}
                    <v-btn right absolute @click="$emit('close')" class="mb-1" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
                </v-card-title> -->
                <!-- <v-card-subtitle>
                    {{ cardData.subtitle }}
                </v-card-subtitle> -->
                <v-card-text>
                    <v-text-field clearable rounded filled v-model="project_data.project" :label="cardData.txt_field_label"> </v-text-field>
                    <v-autocomplete
                        rounded
                        :label="cardData.autocomplete_label"
                        filled
                        v-model="project_data.lists"
                        :items="filterDisabled()"
                        item-text="listname"
                        item-value="_id"
                        chips
                        deletable-chips
                        multiple>
                        <template v-slot:item="data">
                            <v-list-item ripple v-if="project_data.lists.indexOf(data.item._id) == -1" @click="data.item.disabled ? null : project_data.lists.push(data.item._id)">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-action v-on="data.item.disabled ? on : null">
                                            <v-icon :color="data.item.disabled ? '#D3D3D3' : null" :disabled="data.item.disabled"> mdi-checkbox-blank-outline </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content v-on="data.item.disabled ? on : null">
                                            <v-list-item-title :style="data.item.disabled ? 'color: #d3d3d3' : 'primary'">
                                                {{ data.item.listname }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <span>Collection already assigned to project "{{ data.item.project }}".</span>
                                </v-tooltip>
                            </v-list-item>
                            <v-list-item ripple v-else @click="project_data.lists = project_data.lists.filter((x) => x !== data.item._id)">
                                <v-list-item-action>
                                    <v-icon> mdi-checkbox-marked </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.listname }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <v-textarea clearable rounded filled v-model="project_data.description" :label="cardData.area_field_label"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="loading" block rounded class="mx-auto" @click="updateProject" dark color="primary">save Project</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "EditProject",
        props: {
            editDialog: Boolean,
            all_collections: Array,
            project_data: {
                type: Object
            }
        },
        data() {
            return {
                // new_project: {},
                loading: false,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                notification: { show: false },
                cardData: {
                    title: "Edit Project",
                    subtitle: "Update the project informations",
                    txt_field_label: "Project name",
                    autocomplete_label: "Edit assigned collections of your Project",
                    area_field_label: "Project description"
                }
            }
        },
        computed: {
            // new_project(){
            //     let project = this.project_data
            //     console.log(project)
            //     if(project){
            //         project.lists=this.all_collections.filter((collection) => this.project_data.lists.indexOf(collection._id) > -1)
            //         console.log( project.lists)
            //     }else{
            //         project.lists=null
            //     }
            //     return project
            // }
        },

        methods: {
            filterDisabled() {
                let collections = JSON.parse(JSON.stringify(this.all_collections))

                for (let index = 0; index < collections.length; index++) {
                    if (collections[index].project && collections[index].project !== this.project_data.project) collections[index].disabled = true
                }
                return collections.sort((a, b) => a.listname.localeCompare(b.listname))
            },
            updateProject() {
                this.loading = true
                console.log(this.project_data)

                if (!this.project_data.project || this.project_data.lists.length < 1) {
                    this.notification = this.$helpers.createNotification("Please provide a project name and select at least one Collection!", "mdi-alert", "errorred")
                    this.loading = false
                    return
                }
                if (this.project_data.description) {
                    if (this.project_data.description.length > 1000) {
                        this.notification = this.$helpers.createNotification("Please use not more than 1000 characters for the project description!", "mdi-alert", "errorred")
                        this.loading = false
                        return
                    }
                }
                let post_data = {
                    company_id: localStorage.company_id,
                    // user_id: localStorage.id,
                    project: this.project_data
                    // description: this.new_project.description,
                    // lists: this.new_project.lists.map((x) => x.id)
                }
                // console.log(post_data)
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/projects/update", post_data, {
                        headers: { Authorization: this.auth }
                    })
                    .then(function (response) {
                        if (response.body.updated) {
                            this.notification = this.$helpers.createNotification("Your Project has been updated successfully!", "mdi-check-circle-outline", "success")
                            this.$emit("close")
                            this.$emit("renewData")
                        } else {
                            this.notification = this.$helpers.createNotification("Something went wrong! Please try again or contact the support.", "mdi-alert", "errorred")
                        }
                        this.loading = false
                    })
                    .catch((err) => {
                        console.log(err)
                        this.loading = false
                        this.notification = this.$helpers.createNotification("Something went wrong! Please try again or contact the support.", "mdi-alert", "errorred")
                    })
            }
        },
        mounted() {
            console.log(this.project_data)
            // this.new_project = {
            //     project: this.project_data ? this.project_data.project : null,
            //     description: this.project_data ? this.project_data.description : null,
            //     lists: this.project_data
            //         ? this.all_collections.filter((collection) => this.project_data.lists.indexOf(collection.id) > -1)
            //         : null
            // }
        }
    }
</script>

<style>
    .addprojectstitle {
        font-size: 1.7em !important;
    }
</style>
