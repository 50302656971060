<template>
    <div id="contentcontainer" class="standard-mode">
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <div>
            <div class="monitoringstepper mt-10 mb-7 pl-1">
                <div style="font-size: 24px; text-transform: uppercase">
                    <span>New Internal Searches Project</span>
                </div>
                <div style="font-size: 12px">Set basic settings like title or your domain</div>
            </div>
        </div>

        <v-stepper rounded v-model="e1" alt-labels non-linear class="rounded_card quaroshadow monitoringstepper mb-10">
            <v-stepper-header elevation-0>
                <v-stepper-step :complete="e1 > 1" step="1">Domain</v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2"> Add Keywords </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <!---   CreateProject    --->
                <!-- -------------------- -->
                <v-stepper-content step="1">
                    <div class="px-5">
                        <div class="pb-4 qgreycard">
                            <div class="quarolbl pb-2 pb-2">
                                Domain
                                <v-tooltip max-width="250" color="" top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                    </template>
                                    <span>The "Domain" field specifies from which domain the search data comes from. </span>
                                </v-tooltip>
                            </div>
                            <v-text-field type="text" rounded filled v-model="project.domain" hint="e.g. xxxlutz.de" persistent-hint></v-text-field>
                        </div>
                    </div>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="next()"> Continue </v-btn>
                    </v-row>
                </v-stepper-content>

                <!-- ---- AddKeywords ----- -->
                <!-- ------ Step 2 ------ -->
                <!-- -------------------- -->
                <v-stepper-content step="2">
                    <div class="px-8">
                        <div class="pb-4 qgreycard">
                            <div class="quarolbl pb-2 pb-2">Select the date for which you want to import data</div>

                            <v-menu v-model="datepickerMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        placeholder="Select the date for which you want to import data"
                                        hide-details
                                        class="mt-2"
                                        rounded
                                        v-model="project.importDate"
                                        filled
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="project.importDate" color="primary" show-current="false" type="month"></v-date-picker>
                                <!-- <v-date-picker color="primary" type="month" show-current="false" v-model="importDate"></v-date-picker
                            > -->
                            </v-menu>
                            <!-- <v-date-picker v-model="month" @change="monthMenu = false" color="primary" scrollable type="month"></v-date-picker> -->
                            <!-- <v-text-field type="text" rounded filled v-model="project.domain" hint="e.g. xxxlutz.de" persistent-hint></v-text-field> -->
                        </div>
                    </div>
                    <div class="pa-2 px-10">
                        <!--UPLOAD-->
                        <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                            <div class="dropbox">
                                <input v-if="showInput" type="file" :name="uploadFieldName" :disabled="isSaving" @input="handleFileChange" accept=".csv" class="input-file" />
                                <p v-if="isInitial && !selectedFile">
                                    Drag your file here to begin<br />
                                    or click to browse
                                </p>
                                <p v-if="isSaving"></p>
                                <div v-if="selectedFile" class="d-flex filewrapper">
                                    <div class="selectedfile ma-auto">
                                        <!-- :value="uploadProgress" -->
                                        <!-- :indeterminate="uploadProgress == 100 ? true : false" -->

                                        <v-progress-circular class="mr-2" v-if="isSaving" indeterminate color="primary" height="10"></v-progress-circular>
                                        <v-icon color="primary" v-else class="mr-2">mdi-file</v-icon>
                                        {{ selectedFile.name.length > 40 ? selectedFile.name.substring(0, 40) + "..." : selectedFile.name }}
                                        <v-btn icon @click="reset" class="ml-5"><v-icon>mdi-close</v-icon></v-btn>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div v-if="isSuccess">Upload finshed <v-icon>mdi-check-circle-outline</v-icon></div>
                    </div>
                    <v-row no-gutters class="mt-3" v-if="isSuccess">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="$router.push('/quickseo/internal-searches')"> open projects </v-btn>
                    </v-row>
                    <v-row no-gutters class="mt-3" v-else>
                        <v-spacer></v-spacer>
                        <v-btn text class="mr-2" @click="e1--"> Back </v-btn>
                        <v-btn color="primary" :loading="isSaving" @click="uploadFile"> Upload </v-btn>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
    import axios from "axios"

    const STATUS_INITIAL = 0,
        STATUS_SAVING = 1,
        STATUS_SUCCESS = 2,
        STATUS_FAILED = 3
    export default {
        components: {},
        data() {
            return {
                e1: 1,
                notification: { show: false },
                project: {
                    domain: "",
                    importDate: ""
                },
                datepickerMenu: false,
                keywords: [],
                saveproject_loading: false,
                file: null,
                uploadError: null,
                currentStatus: 0,
                uploadFieldName: "photos",
                selectedFile: null,
                showInput: true,
                uploadProgress: 0
            }
        },
        computed: {
            isInitial() {
                return this.currentStatus === STATUS_INITIAL
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED
            }
        },
        methods: {
            reset() {
                // reset form to initial state
                this.showInput = false
                console.log("hi")
                this.currentStatus = STATUS_INITIAL
                this.selectedFile = null
                this.uploadError = null
                setTimeout(() => {
                    this.showInput = true
                }, 300)
            },

            handleFileChange(event) {
                console.log("change")
                this.selectedFile = event.target.files[0]
                console.log(this.selectedFile)
            },
            keywordsAdded(keywords) {
                console.log("added")
                this.keywords = keywords
            },
            createNotification(message, icon, color) {
                this.notification = this.$helpers.createNotification(message, icon, color)
            },

            uploadFile() {
                let type = this.selectedFile.name.split(".")[this.selectedFile.name.split(".").length - 1]
                console.log(type)
                if (type !== "csv") {
                    this.$emit("createNotification", "Only .csv-files are allowed for upload!", "mdi-alert-circle-outline", "#FCBA03")
                    this.selectedFile = null
                    return
                }

                const config = {
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    },
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
                this.currentStatus === STATUS_INITIAL
                const formData = new FormData()
                console.log(this.selectedFile)
                formData.append("file", this.selectedFile)
                formData.append("project", JSON.stringify(this.project))
                formData.append("company_id", localStorage.company_id)
                this.currentStatus = STATUS_SAVING
                axios
                    .post(process.env.VUE_APP_APIURL + "/internal-searches/create", formData, config)
                    .then((response) => {
                        console.log(response)
                        // Erfolgreich hochgeladen
                        this.currentStatus = STATUS_SUCCESS
                        console.log(response.data)
                        setTimeout(() => {
                            this.$router.push("/quickseo/internal-searches")
                        }, 1000)
                    })
                    .catch((error) => {
                        // Fehler beim Hochladen
                        console.error(error)
                        this.uploadError = err.response
                        this.currentStatus = STATUS_FAILED
                    })
            },

            next() {
                if (!this.project.domain || this.project.domain.indexOf(".") === -1) {
                    this.notification = this.$helpers.createNotification("Please provide a project domain!", "mdi-alert", "orange darken-2")
                    return
                }
                this.e1++
            }
        },
        mounted() {
            let year = new Date().getFullYear()
            let month = new Date().getMonth()
            console.log(month.toString().length)

            month = month.toString().length == 1 ? "0" + month.toString() : month
            this.project.importDate = year + "-" + month
        }
    }
</script>

<style scoped>
    .monitoringstepper {
        max-width: 800px;
        margin: auto;
    }
    .v-stepper__header {
        box-shadow: 0px 3px 12px var(--v-shadow1-base), 3px 0px 12px var(--v-shadow2-base) !important;
    }
    .dropbox {
        outline: 2px dashed grey;
        outline-offset: 5px;
        background: #f6f6f6;
        color: dimgray;
        padding: 10px 10px;
        min-height: 100px;
        position: relative;
        cursor: pointer;
        border-radius: 10px;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100px;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: #c0c0c0; /* when mouse over to the drop zone, change color */
        opacity: 0.5;
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 25px 0;
    }
    .selectedfile {
        border-radius: 10px;
        border: 1px solid grey;
        padding: 5px 0px 5px 15px;
        /* background-color: rgba(41, 233, 41, 0.387); */
    }
    .filewrapper {
        height: 100px;
    }
</style>
