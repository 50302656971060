<template>
    <v-card class="rounded_card">
        <div class="backsheet"></div>
        <!-- background-color="primary" dark -->
        <v-tabs id="tab_module" v-model="active_tab">
            <v-tab v-for="(tabmodule, i) in tabmodules" :key="i">
                {{ tabmodule.name }}
            </v-tab>

            <v-tab-item>
                <serp-preview
                    @toggle_linechart="hide_linechart = !hide_linechart"
                    :searched="searched"
                    :keyword="keyword"
                    :selectedLocation="selectedLocation"
                    :reload="reload"
                    :search_volume="search_volume"
                    :resize="resize"></serp-preview>
            </v-tab-item>
            <v-tab-item>
                <keyword-trends :keywords="keywords" :mountedOn="mountedOn" :resize="resize" @fetchSERP="fetchSERP" @addToChart="addToChart" @addToClipboard="addToClipboard"></keyword-trends>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
    import SerpPreview from "./SerpPreview"
    import KeywordTrends from "./keyword-trends/KeywordTrends"
    export default {
        components: {
            SerpPreview,
            KeywordTrends
        },
        props: {
            keywords: Array, //KeywordTrends
            keyword: String, //SerpPreview
            reload: Number, //SerpPreview
            selectedLocation: Object, //SerpPreview
            search_volume: Object, //SerpPreview
            searched: Boolean, //SerpPreview
            mountedOn: String
        },
        data() {
            return {
                active_tab: 0,
                tabmodules: [
                    {
                        name: "SERP Preview",
                        component: "SerpPreview"
                    }
                    // {
                    //     name: "Keyword Trends",
                    //     component: "KeywordTrends"
                    // }
                ],
                resize: 0
            }
        },
        watch: {
            active_tab() {
                this.resize++
            }
        },
        methods: {
            fetchSERP(selected) {
                this.active_tab = 0
                this.$emit("fetchSERP", selected)
            },
            addToChart(selected) {
                this.$emit("addToChart", selected)
            },
            addToClipboard(selected) {
                console.log("tab", selected)
                this.$emit("addToClipboard", selected)
            }
        }
    }
</script>

<style>
    #tab_module {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
</style>
