<template>
    <div id="contentcontainter">
        <!-- <v-btn color="primary" small><v-switch small color="white" v-model="user_settings.advancedMode"></v-switch> advanced mode</v-btn> -->

        <v-slide-x-transition hide-on-leave>
            <div class="advanced-mode" v-if="user_settings.advancedMode" key="1">
                <span v-if="!loaded" class="">
                    <center class="pb-3 init-search-box">
                        <v-btn-toggle id="request_type_toggle" mandatory color="primary" rounded v-model="requestType" class="">
                            <v-btn small> search by domain </v-btn>
                            <v-btn small> search by keyword </v-btn>
                            <!-- <v-btn small> search by category </v-btn> -->
                        </v-btn-toggle>
                    </center>
                    <v-row class="mt-2">
                        <v-col md="12" class="pt-0 searchbarcol" style="">
                            <v-card id="searchbar" class="mx-auto rounded_card marginhelper quaroshadow">
                                <div class="backsheet"></div>
                                <h2 class="pt-5">{{ cardtitle }}</h2>
                                <v-row class="pl-5 pr-5" id="searchRow">
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="5" class="pb-0">
                                        <v-text-field
                                            id="search_field"
                                            v-if="requestType < 2"
                                            auto-grow
                                            :label="searchlbl"
                                            rows="1 "
                                            rounded
                                            filled
                                            v-model="keywords_ideas"
                                            prepend-inner-icon="mdi-magnify"
                                            v-on:keyup.enter="fetchKeywords"></v-text-field>
                                        <v-autocomplete
                                            v-else
                                            rounded
                                            v-model="catForSearch"
                                            cache-items
                                            :items="catData"
                                            filled
                                            attach
                                            item-text="category_name"
                                            item-value="data"
                                            return-object
                                            prepend-inner-icon="mdi-magnify"
                                            label="select category"
                                            v-on:keyup.enter="fetchKeywords"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                                        <LocationSelection @setLangLoc="selectedLocation = $event"></LocationSelection>
                                        <!-- <v-select
                                            id="location_select"
                                            rounded
                                            :disabled="btndisabled"
                                            v-model="selectedLocation"
                                            :items="stateItems"
                                            item-text="loc"
                                            item-value="id"
                                            label="Location"
                                            prepend-inner-icon="mdi-map-marker"
                                            return-object
                                            top
                                            filled
                                            attach
                                            required>
                                        </v-select> -->
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="1">
                                        <v-btn elevation="0" rounded :disabled="btndisabled" id="sendBtn" color="primary" v-on:click="fetchKeywords" class="white--text"
                                            ><v-icon>mdi-send</v-icon></v-btn
                                        >
                                    </v-col>
                                </v-row>

                                <!-- loader -->
                                <div class="loaderwrap" v-if="loading">
                                    <v-progress-linear height="5" class="linearloader" rounded :indeterminate="true" color="primary"> </v-progress-linear>
                                </div>
                            </v-card>
                        </v-col> </v-row
                ></span>

                <v-row>
                    <!-- search field & Keyword Table -->

                    <v-col id="col1" cols="12" lg="6" :class="{ 'small-height': isSmallHeight }" class="pb-0 pt-0 order-xs-last order-sm-last order-md-last">
                        <v-row>
                            <v-col>
                                <v-slide-x-transition>
                                    <v-card class="rounded_card mt-0 quaroshadow pa-1" id="searchbar_adv" v-if="loaded" v-show="delay">
                                        <!-- <h2>{{ cardtitle }}</h2> -->
                                        <v-btn-toggle class="ml-2 pt-2" id="request_type_toggle" mandatory color="primary" v-model="requestType">
                                            <v-btn small> search by domain </v-btn>
                                            <v-btn small> search by keyword </v-btn>
                                            <!-- <v-btn small> search by category </v-btn> -->
                                        </v-btn-toggle>
                                        <v-row id="searchRow" class="px-2 pt-2">
                                            <v-col cols="12" xl="11" sm="10" class="pb-0">
                                                <v-text-field
                                                    dense
                                                    id="search_field"
                                                    v-if="requestType < 2"
                                                    auto-grow
                                                    :label="searchlbl"
                                                    rows="1 "
                                                    hide-details
                                                    rounded
                                                    filled
                                                    v-model="keywords_ideas"
                                                    prepend-inner-icon="mdi-magnify"
                                                    v-on:keyup.enter="fetchKeywords"></v-text-field>
                                                <v-autocomplete
                                                    v-else
                                                    rounded
                                                    v-model="catForSearch"
                                                    cache-items
                                                    :items="catData"
                                                    filled
                                                    hide-details
                                                    attach
                                                    item-text="category_name"
                                                    item-value="data"
                                                    return-object
                                                    prepend-inner-icon="mdi-magnify"
                                                    label="select category"
                                                    v-on:keyup.enter="fetchKeywords"></v-autocomplete>
                                            </v-col>
                                            <!-- <v-col cols="12" xl="5" lg="7" md="7">
                                            <LocationSelection
                                                @setLangLoc="
                                                    selectedLocation = $event
                                                "></LocationSelection>
                                        </v-col> -->
                                            <v-col cols="12" xl="1" lg="1" md="1" sm="2" style="width: 70px">
                                                <v-btn elevation="0" rounded :disabled="btndisabled" id="sendBtn" color="primary" v-on:click="fetchKeywords" class="white--text"
                                                    ><v-icon>mdi-send</v-icon></v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                        <span @click="show_search_settings = !show_search_settings" class="search_settings_btn ml-2">
                                            <v-icon color="primary" small>mdi-cog</v-icon>
                                            <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                        </span>
                                        <v-slide-y-transition>
                                            <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                                <v-col cols="12">
                                                    <LocationSelection @setLangLoc="selectedLocation = $event"></LocationSelection>
                                                </v-col>
                                            </v-row>
                                        </v-slide-y-transition>
                                        <!-- <span @click="show_search_settings = !show_search_settings" class="search_settings_btn">
                                    <v-icon color="primary" small>mdi-cog</v-icon>
                                    <v-icon color="primary" small>{{ show_search_settings ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                                </span>
                                <v-slide-y-transition>
                                    <v-row class="pl-2 pr-2" v-if="show_search_settings">
                                        <v-col cols="12" class="pt-0 pl-0">
                                            <v-select
                                                v-model="selectedQuestion"
                                                :items="questions"
                                                label="Question Prefixes"
                                                multiple
                                                chips
                                                rounded
                                                hide-details
                                                filled
                                                deletable-chips
                                                item-text="question"
                                                item-value="value"
                                                return-object
                                                attach></v-select>
                                        </v-col>
                                    </v-row>
                                </v-slide-y-transition> -->
                                        <!-- loader -->
                                        <div class="loaderwrap" v-if="loading">
                                            <v-progress-linear height="5" class="linearloader" rounded :indeterminate="true" color="primary"> </v-progress-linear>
                                        </div>
                                    </v-card>
                                </v-slide-x-transition>
                            </v-col>
                            <v-slide-x-transition>
                                <v-col class="pb-0" v-if="loaded" v-show="delay">
                                    <KeywordTable
                                        ref="kw_table"
                                        :columnsDisabled="columnsDisabled"
                                        :keywords="keywords"
                                        :headers="headers"
                                        :catsOfList="catsOfList"
                                        :catsLoading="catsLoading"
                                        :cardData="carddata"
                                        :media_counts="false"
                                        :tourPushToClipboard="tourPushToClipboard"
                                        :tourSelectKeywords="tourSelectKeywords"
                                        :serpsOfList="serpsOfList"
                                        :trendClipboardKw="trendClipboardKw"
                                        :addToSerpDisabled="addToSerpDisabled"
                                        :siteKeywordsLocation="siteKeywordsLocation"
                                        :new_data="new_data"
                                        mountedOn="Ideas"
                                        @fetchSERP="fetchSERP"
                                        @filterColumns="filterColumns"
                                        @removeCat="removeCat"
                                        @switchCat="switchCat"
                                        @addToChart="addToChart"
                                        @deleteKeywords="prepareDeleteKw"
                                        @filterForCat="filterForCat"
                                        @addCategories="addCategories"
                                        @createNotification="createNotification"
                                        @addCustomEntries="addCustomEntries"
                                        @filterForCustomDropdown="filterForCustomDropdown"
                                        @modifiedDropDownOptions="modifiedDropDownOptions"
                                        @changedHeaders="changedHeaders"
                                        @startFetchWithSendToKeywords="startFetchWithSendToKeywords"
                                        @changeRankingURL="addRankings"></KeywordTable>
                                </v-col>
                            </v-slide-x-transition>
                        </v-row>
                    </v-col>
                    <!--  hide_linechart &SERP Preview -->
                    <v-col id="col2" cols="12" lg="6" :class="{ 'small-height': isSmallHeight }" class="order-xs-first order-sm-first order-md-first order-lg-last">
                        <v-row>
                            <v-col cols="12" class="py-0" id="linechart_col">
                                <!-- line chart  -->
                                <v-slide-x-transition>
                                    <KeywordLineChart
                                        id="line_chart"
                                        :dataChart="dataChart"
                                        :countGraph="countGraph"
                                        :countGraph2="countGraph2"
                                        :graphloaded="graphloaded"
                                        :hidden="hide_linechart"
                                        :loading="false"
                                        v-if="loaded"
                                        v-show="delay"
                                        @clearChart="clearChart"
                                        @rerender="rerenderChart">
                                    </KeywordLineChart>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <!-- SERP Preview -->
                                <v-slide-x-transition>
                                    <!-- <TabModule
                                    v-if="loaded"
                                    :keywords="keywords"
                                    :searched="loaded"
                                    mountedOn="Ideas"
                                    :keyword="serpKeyword"
                                    :selectedLocation="selectedLocation"
                                    :reload="serpReload"
                                    :search_volume="preview_sv_data"
                                    @fetchSERP="fetchSERP"
                                    @addToChart="addToChart"
                                    @addToClipboard="trendAddToClipboard"></TabModule> -->

                                    <serp-preview
                                        :searched="loaded"
                                        v-show="delay"
                                        :keyword="serpKeyword"
                                        :selectedLocation="siteKeywordsLocation"
                                        :reload="serpReload"
                                        :search_volume="preview_sv_data"
                                        @serpLoading="addToSerpDisabled = false"></serp-preview>

                                    <!-- <keyword-trends :keywords="keywords"></keyword-trends> -->
                                    <!-- <serp-preview
                                    @toggle_linechart="hide_linechart = !hide_linechart"
                                    :searched="loaded"
                                    :keyword="serpKeyword"
                                    :selectedLocation="selectedLocation"
                                    :reload="serpReload"
                                    :search_volume="preview_sv_data"></serp-preview> -->
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div> </v-slide-x-transition
        ><v-slide-x-transition hide-on-leave>
            <!-- standardmode -->
            <div v-if="!user_settings.advancedMode" class="standard-mode" key="2">
                <!-- new search box -->
                <v-slide-x-transition>
                    <center class="pb-3">
                        <v-btn-toggle id="request_type_toggle" mandatory color="primary" rounded v-model="requestType">
                            <v-btn small> search by domain </v-btn>
                            <v-btn small> search by keyword </v-btn>
                            <!-- <v-btn small> search by category </v-btn> -->
                        </v-btn-toggle>
                    </center>
                </v-slide-x-transition>
                <v-row class="mt-2">
                    <v-col md="12" class="pt-0">
                        <v-slide-x-transition>
                            <v-card id="searchbar" class="rounded_card quaroshadow">
                                <div class="backsheet"></div>
                                <h2>{{ cardtitle }}</h2>
                                <v-row class="pl-5 pr-5 pt-5" id="searchRow">
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="5" class="pb-0">
                                        <v-text-field
                                            id="search_field"
                                            v-if="requestType < 2"
                                            auto-grow
                                            :label="searchlbl"
                                            rows="1 "
                                            rounded
                                            filled
                                            v-model="keywords_ideas"
                                            prepend-inner-icon="mdi-magnify"
                                            v-on:keyup.enter="fetchKeywords"></v-text-field>
                                        <v-autocomplete
                                            v-else
                                            rounded
                                            v-model="catForSearch"
                                            cache-items
                                            :items="catData"
                                            filled
                                            attach
                                            item-text="category_name"
                                            item-value="data"
                                            return-object
                                            prepend-inner-icon="mdi-magnify"
                                            label="select category"
                                            v-on:keyup.enter="fetchKeywords"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                                        <LocationSelection @setLangLoc="selectedLocation = $event"></LocationSelection>
                                        <!-- <v-select
                                            id="location_select"
                                            rounded
                                            :disabled="btndisabled"
                                            v-model="selectedLocation"
                                            :items="stateItems"
                                            item-text="loc"
                                            item-value="id"
                                            label="Location"
                                            prepend-inner-icon="mdi-map-marker"
                                            return-object
                                            top
                                            filled
                                            attach
                                            required>
                                        </v-select> -->
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="1">
                                        <v-btn elevation="0" rounded :disabled="btndisabled" id="sendBtn" color="primary" v-on:click="fetchKeywords" class="white--text"
                                            ><v-icon>mdi-send</v-icon></v-btn
                                        >
                                    </v-col>
                                </v-row>
                                <!-- loader -->

                                <div class="loaderwrap" v-if="loading">
                                    <v-progress-linear height="5" class="linearloader" rounded :indeterminate="true" color="primary"> </v-progress-linear>
                                </div>
                            </v-card>
                        </v-slide-x-transition>
                    </v-col>
                </v-row>

                <v-row class="mb-3">
                    <v-col>
                        <v-slide-x-transition>
                            <KeywordLineChart
                                class="mb-5"
                                id="line_chart"
                                v-if="loaded"
                                v-show="delay"
                                :dataChart="dataChart"
                                :countGraph="countGraph"
                                :countGraph2="countGraph2"
                                :graphloaded="graphloaded"
                                @clearChart="clearChart"
                                @rerender="rerenderChart">
                            </KeywordLineChart>
                        </v-slide-x-transition>
                    </v-col>
                </v-row>

                <v-slide-x-transition>
                    <KeywordTable
                        ref="kw_table"
                        v-if="loaded"
                        :columnsDisabled="columnsDisabled"
                        :keywords="keywords"
                        :headers="headers"
                        :catsOfList="catsOfList"
                        :catsLoading="catsLoading"
                        :cardData="carddata"
                        :media_counts="false"
                        :tourPushToClipboard="tourPushToClipboard"
                        :tourSelectKeywords="tourSelectKeywords"
                        :serpsOfList="serpsOfList"
                        mountedOn="Ideas"
                        :addToSerpDisabled="addToSerpDisabled"
                        :siteKeywordsLocation="siteKeywordsLocation"
                        :new_data="new_data"
                        @filterColumns="filterColumns"
                        @removeCat="removeCat"
                        @switchCat="switchCat"
                        @addToChart="addToChart"
                        @deleteKeywords="prepareDeleteKw"
                        @filterForCat="filterForCat"
                        @addCategories="addCategories"
                        @createNotification="createNotification"
                        @addCustomEntries="addCustomEntries"
                        @filterForCustomDropdown="filterForCustomDropdown"
                        @modifiedDropDownOptions="modifiedDropDownOptions"
                        @changedHeaders="changedHeaders"
                        @startFetchWithSendToKeywords="startFetchWithSendToKeywords"
                        @changeRankingURL="addRankings"></KeywordTable>
                </v-slide-x-transition>
                <!-- <v-row>
            <v-col cols="5">
                <v-slide-y-transition>
                    <serp-preview :keyword="serpKeyword" :selectedLocation="selectedLocation" :reload="serpReload" :search_volume="25000"></serp-preview>
                </v-slide-y-transition>
            </v-col>
            </v-row> -->
            </div>
        </v-slide-x-transition>

        <!-- <div v-if="loaded && !delay">
            <div style="display: flex; margin-top: 15vh">
                <span class="circularprogress mx-auto">
                    <img id="loader" src="../assets/quaro-q.svg" />
                </span>
            </div>
        </div> -->

        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>

        <v-tour name="ideasTour" :steps="tour_steps" :callbacks="tourCallbacks"></v-tour>
        <SearchSettings :catsOfList="catsOfList" :seasonsOfList="seasonsOfList" :headers="headers.modifiedHeaders"></SearchSettings>

        <!-- different locations in clipboard Info
            <v-snackbar top color="#fcba03" v-model="showLocError">    
                <v-row> <v-col cols='1'><v-icon class="pl-3">mdi-alert-circle-outline</v-icon> </v-col> <v-col> <span class="font-weight-bold">The keywords in your    clipboard have search volume of another country. Please delete these keywords first. </span> </v-col>   </v-row> 
            </v-snackbar> -->

        <!-- Delete Dialog -->
        <v-dialog v-model="deleteQuestion" max-width="300">
            <v-card>
                <v-card-title style="text-align: center"> Are you sure?</v-card-title>
                <v-card-text class="text-center">
                    <v-btn class="mr-3" text @click="deleteQuestion = false"> Nevermind </v-btn>
                    <v-btn color="primary" text @click="deleteKeywords(selected)"> Yes </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-btn v-if="!user_settings.advancedMode" small class="mx-auto mb-5 white--text" id="tourbuttom" @click="startTour">
            <v-icon right dark class="ml-0 mr-2">mdi-flag-checkered</v-icon>
            start Tour
        </v-btn>
    </div>
</template>

<script>
    import KeywordLineChart from "../components/charts/KeywordLineChart"
    import KeywordTable from "../components/keyword-table/KeywordTable"
    import SerpPreview from "../components/SerpPreview"
    import KeywordTrends from "../components/keyword-trends/KeywordTrends"
    import TabModule from "../components/TabModule"
    // import locs from "../components/helper/locs"
    import { standardHeaders } from "../components/helper/global.js"
    import dataprocessing from "../components/helper/dataprocessing.js"
    import SearchSettings from "../components/SearchSettings"
    import LocationSelection from "../components/LocationSelection"

    export default {
        name: "Ideas",
        data() {
            return {
                isSmallHeight: false,
                show_search_settings: false,
                tourCallbacks: {
                    onSkip: this.endTour,
                    onFinish: this.endTour
                },
                tour_steps: [
                    {
                        target: "#searchbar", // We're using document.querySelector() under the hood
                        header: {
                            title: "Get Started"
                        },
                        offset: -525,
                        content: `This is the <strong>Ideas-Module</strong>. Use it to find new keyword ideas and inspiration!`
                    },
                    // {
                    //     target: "#request_type_toggle", // We're using document.querySelector() under the hood
                    //     header: {
                    //         title: "Starting point of your search"
                    //     },
                    //     content: `Insert a keyword like <strong>Sneaker</strong> to get a starting point!`
                    // },
                    {
                        target: "#search_field", // We're using document.querySelector() under the hood
                        header: {
                            title: "Set a Topic"
                        },
                        offset: -525,
                        content: `Insert a keyword like <strong>samsung s23</strong> to get a starting point!`
                    },
                    {
                        target: "#location_select", // We're using document.querySelector() under the hood
                        header: {
                            title: "Select a Country"
                        },
                        offset: -525,
                        content: `Select from which <strong>country</strong> the keywords and search volume come from!`,
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.keywords_ideas = "samsung s23"
                                this.requestType = 1
                                resolve("foo")
                            })
                    },
                    {
                        target: "#sendBtn",
                        header: {
                            title: "Start your Search"
                        },
                        offset: -525,
                        content: `<strong>Click</strong> the button to start your search!`
                    },
                    {
                        target: "#line_chart_card",
                        header: {
                            title: "Google Searchvolume"
                        },
                        offset: -225,
                        content: `This is the sum of the search volume of <strong>all found keywords</strong>.`,
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                if (this.keywords.length > 0) {
                                    resolve()
                                } else {
                                    this.keywords_ideas = "samsung s23"
                                    this.requestType = 1
                                    this.fetchKeywords()
                                    setInterval(() => {
                                        if (!this.loading) {
                                            resolve("foo")
                                        }
                                    }, 500)
                                }
                            })
                    },
                    {
                        target: "#chart_menu",
                        header: {
                            title: "Chart Settings"
                        },
                        content: `you can change the appearance of the graph and export it as a <strong>PNG</strong> or <strong>PDF</strong>!`,
                        offset: -240
                        // params: {
                        //     enableScrolling: false
                        // }
                    },
                    {
                        target: "#tabletitle",
                        header: {
                            title: "Keyword Table"
                        },
                        content: `All found keywords are listed in this table!`,
                        params: {
                            placement: "top" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        },
                        offset: -180
                    },
                    {
                        target: "#avgsv_cell",
                        header: {
                            title: "Google Searchvolume"
                        },
                        content: `Represents the average search queries via google per month.`,
                        offset: -420,
                        params: {
                            enableScrolling: false
                        }
                    },
                    {
                        target: "#sparkline_cell",
                        header: {
                            title: "Trend & Season"
                        },
                        content: `Represents the searches from january to december for each keyword.`,
                        offset: -420,
                        params: {
                            enableScrolling: false
                        }
                    },
                    {
                        target: "#cat_cell",
                        header: {
                            title: "Google Categories"
                        },
                        content: `Represents the Google product and service categories for each keyword. You can create and change categories as you wish!`,
                        offset: -420,
                        params: {
                            enableScrolling: false
                        }
                    },
                    {
                        target: "#cpc_cell",
                        header: {
                            title: "Google Ads CPC"
                        },
                        content: `Represents the average <strong>cost per click (EUR)</strong> historically paid for the keyword.`,
                        offset: -420,
                        params: {
                            enableScrolling: false
                        }
                    },

                    {
                        target: "#competition_cell",
                        header: {
                            title: "Google Ads SEA Competition"
                        },
                        content: `Represents the relative amount of competition associated with the given keyword in paid SERP only.`,
                        offset: -420,
                        params: {
                            enableScrolling: false
                        }
                    },
                    {
                        target: ".v-data-table__checkbox",
                        header: {
                            title: "Select Keywords"
                        },
                        content: `With this button you can <strong>select</strong> the keywords of the first page.`,
                        offset: -420,
                        params: {
                            enableScrolling: false
                        }
                    },
                    {
                        target: "#select_all_btn",
                        header: {
                            title: "Select Keywords"
                        },
                        content: `With this button you can <strong>select</strong> all found keywords.`,
                        offset: -420,
                        params: {
                            enableScrolling: false
                        }
                    },
                    {
                        target: "#add_clipboard_btn",
                        header: {
                            title: "Add Keywords to your clipboard"
                        },
                        content: `<strong>Click</strong> the button to add the selected keywords to the Clipboard.`,
                        offset: -420,
                        params: {
                            enableScrolling: false
                        },
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.tourSelectKeywords++
                                resolve("foo")
                            })
                    },
                    {
                        target: "#clipboard",
                        header: {
                            title: "Clipboard"
                        },
                        content: `Use the Clipboard to collect the best keywords from Suggests or <strong>any other Module!</strong>`,
                        params: {
                            placement: "auto" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        },
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.tourSelectKeywords++

                                this.tourPushToClipboard = !this.tourPushToClipboard

                                setTimeout(() => {
                                    // this.$store.dispatch("SHOW_SIDEBARMENU")
                                    resolve("foo")
                                }, 600)
                            })
                    },
                    {
                        target: ".actionBtns",
                        header: {
                            title: "Clipboard"
                        },
                        content: `With the buttons you can save your keywords as a new collection, export them as Excel or CSV file or add them to an existing collection.</strong>`,
                        params: {
                            placement: "left" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        },
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.$store.dispatch("SHOW_SIDEBARMENU")
                                setTimeout(() => {
                                    resolve("foo")
                                }, 1000)
                            })
                    },
                    {
                        target: ".actionBtns",
                        header: {
                            title: "Save Collection"
                        },
                        content: `<strong>Click</strong> the save button to create your first collection!`,
                        params: {
                            placement: "left" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        },
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.$store.dispatch("SHOW_SIDEBARMENU")
                                setTimeout(() => {
                                    resolve("foo")
                                }, 500)
                            })
                    },
                    {
                        target: "#final_save_collecion",
                        header: {
                            title: "Collection Name"
                        },
                        content: `You can insert a name for your Collection!`,
                        params: {
                            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        },
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.$store.dispatch("SET_OPEN_SAVE_COLLECTION_DIALOG")
                                setTimeout(() => {
                                    resolve("foo")
                                }, 500)
                                //    save Collection
                            })
                    },
                    {
                        target: "#final_save_collecion",
                        header: {
                            title: "Save your Collection"
                        },
                        content: `Once saved, your collection will be up to date always!`,
                        params: {
                            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        },
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.$store.dispatch("SET_OPEN_SAVE_COLLECTION_DIALOG")

                                setTimeout(() => {
                                    this.$store.dispatch("SET_SET_COLLECTION_NAME")
                                    resolve("foo")
                                }, 500)
                                //    save Collection
                            })
                    },
                    {
                        target: "#keppKeywordsBtn",
                        header: {
                            title: "Keep Keywords"
                        },
                        content: `You can keep the collected keywords in the Clipboard for later usage!`,
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.$store.dispatch("SET_SAVE_COLLECTION")
                                setTimeout(() => {
                                    resolve("foo")
                                }, 2000)
                                //    save Collection
                            })
                    },
                    {
                        target: "#top_nav",
                        header: {
                            title: "Congratulations"
                        },
                        content: `You created your first collection!`,
                        before: (type) =>
                            new Promise((resolve, reject) => {
                                this.$store.dispatch("SET_SKIP_KEEP_KEYWORDS")

                                setTimeout(() => {
                                    this.$router.push("/?tour=ideas")
                                    resolve("foo")
                                }, 500)

                                // router push collections page
                            })
                    }
                ],
                get plan() {
                    if (localStorage.getItem("plan")) {
                        if (localStorage.getItem("plan") != "[object Object],[object Object]") {
                            // //console.log(localStorage.getItem('user'))
                            return localStorage.getItem("plan")
                        } else {
                            return
                        }
                    } else {
                        return
                    }
                },
                tourSelectKeywords: 0,
                tourPushToClipboard: false,
                notification: { show: false },
                columnsDisabled: {},
                catsOfList: [],
                serpsOfList: [],
                seasonsOfList: [],
                catForSearch: null,
                requestType: 1,
                catData: [],
                catsLoading: true,
                btndisabled: false,
                deleteQuestion: false,
                selected: [],
                dataChart: {
                    selected: [],
                    labels: [],
                    datasets: []
                },
                countGraph: 0,
                countGraph2: 0,
                graphloaded: false,
                keywords: [],
                search: "",
                headers: {
                    options: {
                        sort: true,
                        active: true
                    },
                    standardHeaders: [],
                    modifiedHeaders: []
                },
                keywords_ideas: [],
                selectedLanguage: { lang: "German", value: "1001" },
                selectedLocation: {},
                siteKeywordsLocation: {},
                langItems: [
                    { lang: "German", value: "1001" },
                    { lang: "English", value: "1000" },
                    { lang: "Italian", value: "1004" },
                    { lang: "Polish", value: "1030" },
                    { lang: "French", value: "1002" },
                    { lang: "Spanish", value: "1003" },
                    { lang: "Dutch", value: "1010" }
                    // {lang: "Croatian", value: "1039" },
                    // {lang: "Czech", value: "1021" },
                    // {lang: "Slovak", value: "1033" },
                    // {lang: "Slovenian", value: "1034" }
                ],
                // stateItems: locs.stateItems,
                loading: false,
                loaded: false,
                carddata: {
                    title: "",
                    subTitle: "",
                    searchPhrases: null
                },
                unfilteredKeywords: [],
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                monthArray: ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                serpKeyword: null,
                serpReload: 0,
                hide_linechart: false,
                preview_sv_data: {},
                trendClipboardKw: [],
                addToSerpDisabled: false,
                delay: true,
                new_data: 0
            }
        },
        watch: {
            selectedLocation: function (val) {
                localStorage.setItem("selectedLocation", JSON.stringify(val))
            },
            "$store.state.user_settings.advancedMode"(val) {
                this.keywords.map((x) => {
                    x.selected = false
                })
            },
            "$store.state.user_settings.monitoring_domain"(val) {
                this.$helpers.addRankings(this.keywords, this.headers, this.selectedLocation.value, val, this.$store.state.user_settings.monitoring_device)
            },
            "$store.state.user_settings.monitoring_device"(val) {
                this.$helpers.addRankings(this.keywords, this.headers, this.selectedLocation.value, this.$store.state.user_settings.monitoring_device, val)
            }
        },
        components: {
            KeywordLineChart,
            KeywordTable,
            SerpPreview,
            SearchSettings,
            KeywordTrends,
            TabModule,
            LocationSelection
        },
        computed: {
            user_settings: {
                get: function () {
                    return this.$store.state.user_settings
                },
                set: function (newValue) {
                    this.$store.dispatch("CHANGE_USER_SETTINGS", newValue)
                }
            },

            searchlbl() {
                if (this.requestType == 0) {
                    return "e.g. google.de"
                }
                if (this.requestType == 1) {
                    return "e.g. backpack, sneaker.."
                }
                if (this.requestType == 2) {
                    return "select a category"
                }
            },
            cardtitle() {
                if (this.requestType == 0) {
                    return "Find new Keyword Ideas - by domain"
                }
                if (this.requestType == 1) {
                    return "Find new Keyword Ideas"
                }
                if (this.requestType == 2) {
                    return "Find new Keyword Ideas - by category"
                }
            },
            requestURL() {
                if (this.requestType == 0) {
                    return "/v2/sources/ideasbydomain"
                }
                if (this.requestType == 1) {
                    return "/v2/sources/ideasbykeyword"
                }
                if (this.requestType == 2) {
                    return "/sources/dataforseo/category"
                }
            },
            decryptedPlan() {
                if (this.plan.length > 0) {
                    return this.CryptoJS.AES.decrypt(this.plan, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            }
        },
        methods: {
            checkHeight() {
                this.isSmallHeight = window.innerHeight <= 800
            },

            startFetchWithSendToKeywords() {
                if (this.$store.state.send_to_keywords.length) {
                    this.keywords_ideas = this.$store.state.send_to_keywords.reduce((a, b) => a + String.fromCharCode(44) + b)
                    // this.fetchKeywords()
                    this.$store.dispatch("SET_SEND_TO_KEYWORDS", [])
                }
            },
            trendAddToClipboard(keywords) {
                this.trendClipboardKw = keywords
            },
            fetchSERP(selected) {
                if (!selected || selected.length == 0) {
                    this.notification = this.$helpers.createNotification("You have to select one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                    return
                }
                this.addToSerpDisabled = true
                this.serpKeyword = selected[0].keyword
                this.preview_sv_data = { keyword: selected[0].keyword, value: selected[0].avgsv }
                this.serpReload++
            },
            changedHeaders() {
                this.headers = this.$store.state.header_items
            },
            modifiedDropDownOptions(option, headerValue) {
                this.keywords.map((keywordRow) => {
                    if (keywordRow.customs[headerValue].id == option.id) {
                        keywordRow.customs[headerValue] = option
                    }
                })
            },
            endTour() {
                this.$store.dispatch("SET_TOUR_RUNNING", false)
                this.$tours["ideasTour"].stop()
            },
            startTour() {
                this.$tours["ideasTour"].start()
                this.$store.dispatch("SET_TOUR_RUNNING", true)
            },
            addCustomEntries(rows, selectedEntry, headerValue, isBackend, isArray) {
                if (!rows) return
                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    if (rowindex > -1) {
                        if (this.keywords[rowindex].customs === undefined) {
                            this.keywords[rowindex].customs = {}
                        }
                        this.keywords[rowindex].customs[headerValue] = isArray ? [...selectedEntry] : selectedEntry
                    }
                })
                // // this.keywords.push({})
                // // this.keywords.pop()
                // let rowindex = this.keywords.indexOf(row);
                // if (this.keywords[rowindex].customs === undefined) {
                //   this.keywords[rowindex].customs = {};
                // }
                // this.keywords[rowindex].customs[headerValue] = isArray ? [...selectedEntry] : selectedEntry;
            },
            createNotification(notification) {
                this.notification = this.$helpers.createNotification(notification.message, notification.icon, notification.color)
            },
            getListCats() {
                let catsOfList = []
                this.keywords.forEach((keyword) => {
                    let cats = keyword.cats
                    if (cats) {
                        if (cats[0]) {
                            if (cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1]) {
                                catsOfList.push(cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1])
                            }
                        }
                    }
                })

                catsOfList.sort((a, b) => a.category_name.localeCompare(b.category_name))
                catsOfList.unshift({ category_name: "-" })

                this.catsOfList = catsOfList
            },
            getListSerps() {
                let serpsOfList = []
                this.keywords.forEach((keyword) => {
                    let serps = keyword.serp_items
                    if (serps) {
                        serps.map((x) => {
                            serpsOfList.push(x)
                        })
                    }
                })
                serpsOfList = new Set(serpsOfList)
                serpsOfList = Array.from(serpsOfList)

                serpsOfList.sort((a, b) => a.value - b.value)
                serpsOfList.unshift({ value: "-", tooltip: "-:" })
                this.serpsOfList = serpsOfList
            },
            getListSeasons() {
                let seasonsOfList = []
                this.keywords.forEach((keyword) => {
                    if (keyword.season) {
                        seasonsOfList.push(keyword.season.season)
                    }
                })
                seasonsOfList = new Set(seasonsOfList)
                seasonsOfList = Array.from(seasonsOfList)

                seasonsOfList.sort()
                seasonsOfList.unshift("-")
                this.seasonsOfList = seasonsOfList
            },
            fetchAllGoogleCats() {
                // this.$http
                //     .get(process.env.VUE_APP_APIURL + "/sources/dataforseo/categories", {
                //         headers: { Authorization: this.auth }
                //     })
                //     .then((response) => {
                //         this.catData = response.body.sort((a, b) => a.category_name.localeCompare(b.category_name))
                //         console.log("CATDATA FETCHED!!!!", this.catData)
                //     })
                //     .catch((err) => {
                //         console.log(err)
                //     })

                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        // console.log(cats)
                        this.catData = cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            filterColumns() {
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
                console.log(" kw length: ", this.keywords.length)
                console.log("unfiltered kw length: ", this.unfilteredKeywords.length)
            },
            filterForCustomDropdown(initialObject, isArray, cat_for_filter) {
                var resultObject = Object.fromEntries(Object.entries(initialObject).filter(([_, v]) => v.length != 0))

                if (Object.keys(resultObject).length === 0 && cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.map((keyword) => {
                    if (Object.keys(resultObject).length > 0) {
                        Object.keys(resultObject).map((entry) => {
                            if (keyword.customs !== undefined && Object.keys(keyword.customs).includes(entry)) {
                                if (
                                    resultObject[entry]
                                        .map((obj) => {
                                            if (isArray) {
                                                return keyword.customs[entry].some((keyEntry) => keyEntry.text === obj.text)
                                            } else {
                                                return keyword.customs[entry].text === obj.text
                                            }
                                        })
                                        .includes(true)
                                ) {
                                    if (!this.keywords.some((k) => k === keyword)) {
                                        this.keywords.push(keyword)
                                    }
                                }
                            }
                        })
                    }
                    if (cat_for_filter.length > 0) {
                        if (keyword.cats) {
                            let catsofKeyword = keyword.cats[keyword.active_cat_index]
                            if (catsofKeyword) {
                                cat_for_filter.forEach((catforfilter) => {
                                    if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                        this.keywords.push(keyword)
                                    }
                                })
                            }
                        } else if (!keyword.cats) {
                            let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                            if (findemptycat) this.keywords.push(keyword)
                        }
                    }
                })
                // this.cardData.subtitle = this.keywords.length + " keywords"
            },
            filterForCat(cat_for_filter) {
                if (cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.forEach((keyword) => {
                    if (keyword.cats) {
                        let catsofKeyword = keyword.cats[keyword.active_cat_index]
                        cat_for_filter.forEach((catforfilter) => {
                            if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                this.keywords.push(keyword)
                            }
                        })
                    } else if (!keyword.cats) {
                        let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                        if (findemptycat) this.keywords.push(keyword)
                    }
                })
                this.carddata.subtitle = this.keywords.length + " keywords"
            },

            rerenderKeywordTable() {
                this.$refs.kw_table.rerenderKeywordTable()
            },
            /**
             * fetch and add cats to keywords
             * gets called by child component KeywordTable.vue
             */
            async fetchCategories() {
                try {
                    this.catsLoading = true
                    let userinfo = {
                        id: "additional-data",
                        company_id: 0
                    }
                    // if (this.siteKeywordsLocation.loc !== "International")
                    if (this.siteKeywordsLocation.ads_lang_code !== null)
                        await dataprocessing.fetchCategories(this.keywords, this.siteKeywordsLocation, userinfo, this.catData, this.catsLoading, this.keywords_ideas)
                    if (this.$store.state.active_filter.length) this.filterColumns()
                    this.getListCats()
                    this.getListSerps()
                    this.getListSeasons()
                    this.catsLoading = false
                } catch (error) {
                    console.log(error)
                    this.catsLoading = false
                }
            },
            getTree(category) {
                let tree = []
                tree[0] = category
                var findParent = (category) => {
                    let parent = this.catData.filter((x) => x.category_code == category.category_code_parent)
                    if (parent[0]) {
                        tree.unshift(parent[0])
                        if (parent[0].category_code_parent) {
                            findParent(parent[0])
                        }
                    }
                }
                if (category.category_code_parent) {
                    findParent(category)
                }
                return tree
            },

            getIndexOfCat(cat, catArray) {
                for (let index = 0; index < catArray.length; index++) {
                    if (catArray[index].category_code == cat.category_code) {
                        return index
                    } else {
                        return -1
                    }
                }
                return -1
            },
            removeCat(row, cat) {
                // remove cat
                let rowindex = this.keywords.indexOf(row)
                let catindex = this.keywords[rowindex].cats.indexOf(cat)
                if (this.keywords[rowindex].cats.length < 2) {
                    this.keywords[rowindex].cats = null
                    this.rerenderKeywordTable()
                } else {
                    this.keywords[rowindex].cats.splice(catindex, 1)
                    this.rerenderKeywordTable()
                }
            },
            addCategories(rows, selectedCats) {
                //adds categories to a keyword
                if (!selectedCats[0]) return

                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    for (let index = 0; index < selectedCats.length; index++) {
                        let tree = this.getTree(selectedCats[index])

                        if (this.keywords[rowindex].cats) {
                            this.keywords[rowindex].cats.unshift(tree)
                        } else {
                            this.keywords[rowindex].cats = [tree]
                        }
                    }
                })
                for (let index = 0; index < selectedCats.length; index++) {
                    let tree = this.getTree(selectedCats[index])
                    this.switchCat(rows, tree)
                }
                this.getListCats()
                this.rerenderKeywordTable()
            },
            /**
             *  * sets/changes primary category for keyword(s)
             * @param {Array} rows keyword(s) to modify
             * @param {Object} cat category to set
             */
            switchCat(rows, cat) {
                // switch visible categories
                this.$helpers.switchCat(rows, cat, this.keywords)
                this.getListCats()
                this.rerenderKeywordTable()
            },

            prepareDeleteKw(selected) {
                this.selected = selected
                this.deleteQuestion = true
            },
            deleteKeywords(keywords) {
                if (this.selected.length > 0) {
                    try {
                        for (var i = 0; i < this.selected.length; i++) {
                            const index = this.keywords.indexOf(this.selected[i])
                            this.keywords.splice(index, 1)
                        }
                        if (this.unfilteredKeywords.length > 0) {
                            for (var i = 0; i < this.selected.length; i++) {
                                let index = this.unfilteredKeywords.indexOf(this.selected[i])
                                this.unfilteredKeywords.splice(index, 1)
                            }
                        }
                        this.$matomo.trackEvent("Ideas", "deleteKeywords", "KeywordsCount: " + this.selected.length, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    let kwCount = this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length

                    this.$refs.kw_table.keywordsDeleted(kwCount)

                    let selectedNoDoubles = this.$helpers.checkGraphDoubles(this.selected, this.dataChart.datasets)
                    for (var i = 0; i < this.selected.length; i++) {
                        let index = selectedNoDoubles.findIndex((set) => {
                            if (set.keyword == this.selected[i].keyword) return set
                        })
                        if (index > -1) selectedNoDoubles.splice(index, 1)
                    }
                    let kwForGraph = this.keywords
                    if (this.unfilteredKeywords.length > this.keywords.length) {
                        kwForGraph = this.unfilteredKeywords
                    }
                    if (kwForGraph.length > 0) {
                        var graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, kwForGraph, selectedNoDoubles)
                        this.dataChart.datasets = graphObjects.graphdata
                        this.dataChart.labels = graphObjects.graphlabel
                        if (this.dataChart.datasets.length > 1) {
                            this.dataChart.datasets[0].hidden = true
                        } else {
                            this.dataChart.datasets[0].hidden = false
                        }
                        this.countGraph2++
                        this.getListCats()
                        this.getListSerps()
                        this.getListSeasons()
                    }

                    this.selected = []
                    this.keywords.map((x) => {
                        x.selected = false
                    })
                    this.deleteQuestion = false
                    // this.selected = [];
                    this.notification = this.$helpers.createNotification("The selected keywords have been deleted!", "mdi-check-circle-outline", "#16C96D")
                    this.carddata.subtitle = this.keywords.length + " keywords"
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },

            rerenderChart() {
                this.countGraph2++
            },
            clearChart() {
                let graphObjects = this.$helpers.buildGraphObjects([], this.keywords, this.dataChart.datasets, this.dataChart.labels)
                // console.log(graphObjects)
                this.dataChart.datasets = graphObjects.graphdata
                this.dataChart.labels = graphObjects.graphlabel
                this.countGraph2++
            },

            addToChart(selected) {
                let graphdata = this.dataChart.datasets
                let selectedNoDoubles = this.$helpers.checkGraphDoubles(selected, graphdata)
                if (selected.length > 0) {
                    try {
                        this.$matomo.trackEvent("Ideas", "addToChart", "Keywords: " + selectedNoDoubles.length, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    //console.log(selectedNoDoubles)
                    if (selectedNoDoubles.length > 0) {
                        if (this.$helpers.checkSelectedSizeForGraph(selectedNoDoubles, 11, graphdata)) {
                            let graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, this.keywords, selectedNoDoubles)
                            this.dataChart.datasets = graphObjects.graphdata
                            this.dataChart.labels = graphObjects.graphlabel
                            if (this.dataChart.datasets[0].label == "Sum of all keywords") {
                                this.dataChart.datasets[0].hidden = true
                            }
                            // Hilfsvariable wird hochgezählt und Aktion in linechart.js ausgelöst
                            this.countGraph2++
                        } else {
                            this.notification = this.$helpers.createNotification("Only 10 keywords can be displayed in graph!", "mdi-alert-circle-outline", "#FCBA03")
                        }
                    } else {
                        this.notification = this.$helpers.createNotification("Selected keywords are displayed in the graph already!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            fetchKeywords() {
                //  console.log(this.catForSearch)
                if (this.requestType < 2 && this.keywords_ideas.length === 0) return
                if (this.requestType == 2 && this.catForSearch == null) return

                this.selected = []
                let keywords = []
                this.btndisabled = true
                this.loading = true
                this.unfilteredKeywords = []
                this.carddata.title = "Keyword ideas for"
                this.carddata
                let input = []
                if (this.requestType < 2) {
                    let splittedInput = this.keywords_ideas.split(String.fromCharCode(10)) // split keywords
                    var cleanedInputLowerCase = splittedInput.map((v) => v.toLowerCase()) // to lower case
                    for (let inputlinesCounter = 0; inputlinesCounter < cleanedInputLowerCase.length; inputlinesCounter++) {
                        let noKomma = cleanedInputLowerCase[inputlinesCounter].split(",")
                        for (let noKommaCounter = 0; noKommaCounter < noKomma.length; noKommaCounter++) {
                            input.push(noKomma[noKommaCounter])
                        }
                    }
                    input = this.cleanArray(input) // clean keywords
                    try {
                        if (input.length < 10) {
                            this.$matomo.trackEvent("Ideas", "fetchKeywordIdeas", "keyword: " + this.keywords_ideas, "") //tracking
                        } else {
                            this.$matomo.trackEvent("Ideas", "fetchKeywordIdeas", "keyword: " + input.length, "") //tracking
                        }
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    // if more than 20 words as input show error
                    if (input.length > 20) {
                        this.btndisabled = false
                        this.loading = false
                        this.notification = this.$helpers.createNotification("Please do not enter more than 20 keywords!", "mdi-alert-circle-outline", "#FCBA03")
                        return
                    }
                    //check keywords for length < 81
                    if (this.requestType !== 0) {
                        let keywordsInvalid = this.$helpers.checkKeywordsLength(input)
                        if (keywordsInvalid) {
                            this.btndisabled = false
                            this.loading = false
                            this.notification = this.$helpers.createNotification("One keyword cannot be longer than 80 characters!", "mdi-alert-circle-outline", "#FCBA03")
                            return
                        }

                        let inputErrors = this.$helpers.validateUserInput(input)
                        if (inputErrors.length && this.requestType > 0) {
                            let lines = inputErrors.reduce((lineString, entry) => lineString + (parseInt(entry.keyword) + 1) + ",", "")
                            this.notification = this.$helpers.createNotification("Please validate your input. Watch Keywords: " + lines, "mdi-alert-circle-outline", "#FCBA03")

                            this.loading = false
                            this.btndisabled = false
                            return
                        }
                        input = this.$helpers.replaceSpecialCharacters(input)
                    }

                    this.carddata.searchPhrases = input
                } else {
                    this.carddata.searchPhrases = [this.catForSearch.category_name]
                }
                let storedate = this.$store.state.actual_date
                let month = storedate.split("/")[0]
                let year = storedate.split("/")[storedate.split("/").length - 1]
                if (month.length == 1) {
                    month = "0" + month
                }
                let date = year - 4 + "-" + month + "-01"
                // let date = new Date()
                // let year = date.getFullYear()
                // year -= 4
                // date.setFullYear(year)
                // date = date.toISOString().split("T")[0]
                this.siteKeywordsLocation = this.selectedLocation

                let reqData = {
                    keywords: input,
                    category: this.catForSearch,
                    language: this.siteKeywordsLocation.ads_lang_code ? this.siteKeywordsLocation.ads_lang_code.replace("-", "_") : null,
                    location: this.siteKeywordsLocation.value,
                    company: localStorage.company_id,
                    user: localStorage.id,
                    date_from: date
                }
                if (!reqData.language) delete reqData.language
                if (this.requestType > 0) {
                    this.serpKeyword = input[0]
                    this.serpReload++
                }

                if (false) {
                    this.fetchQIdeas(input, date)
                } else {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + this.requestURL, reqData, {
                            headers: { Authorization: this.auth }
                        })
                        .then((response) => {
                            console.time("process response")
                            if (response.body.error == "no_credits_left") {
                                this.notification = this.$helpers.createNotification(
                                    "You dont have any credits left. Please contact the support via chat or email!",
                                    "mdi-alert-circle-outline",
                                    "#FCBA03"
                                )
                                this.loading = false
                                this.btndisabled = false
                                return
                            }
                            if (response.body.totalNumEntries == 0) {
                                this.loading = false
                                this.loaded = false
                                this.graphloaded = false
                                this.notification = this.$helpers.createNotification("We could not find any keyword ideas for your search!", "mdi-alert-circle-outline", "#FCBA03")
                                this.btndisabled = false
                                return
                            }
                            this.keywords = []
                            let preview_sv_data = { keyword: this.serpKeyword, value: 0 }
                            for (let index = 0; index < response.body.entries.length; index++) {
                                let entry = response.body.entries[index].data[1].value
                                let annual = 0
                                for (let sumindex = 0; sumindex < 12; sumindex++) {
                                    annual += parseInt(entry.value[sumindex].count) || 0
                                }
                                const row = {
                                    keyword: response.body.entries[index].data[0].value.value,
                                    year: response.body.entries[0].data[1].value.value[0].year,
                                    month: response.body.entries[0].data[1].value.value[0].month,
                                    annualsv: annual,
                                    avgsv: parseInt(annual / 12),
                                    search_volumes: [],
                                    dialog: false,
                                    categories: [],
                                    // categories: response.body.entries[index].data[1].value.categories,
                                    adwords_cpc_high: response.body.entries[index].data[2].value.value.high,
                                    // adwords_cpc: response.body.entries[index].data[2].value.value.high,
                                    adwords_cpc_low: response.body.entries[index].data[2].value.value.low,
                                    adwords_competition:
                                        response.body.entries[index].data[3].value && response.body.entries[index].data[3].value.value !== null
                                            ? response.body.entries[index].data[3].value.value.toFixed(0)
                                            : null,
                                    active_cat_index: 0
                                }
                                // starts estimated visits calculation in serp preview
                                if (row.keyword == this.serpKeyword) {
                                    preview_sv_data.value = row.avgsv
                                    console.log("previewSVDATA SET")
                                }

                                for (let monthCounter = 0; monthCounter < entry.value.length; monthCounter++) {
                                    let volume = {
                                        count: parseInt(entry.value[monthCounter].count) || 0,
                                        year: entry.value[monthCounter].year,
                                        month: entry.value[monthCounter].month
                                    }
                                    row.search_volumes.push(volume)

                                    let svname = ""
                                    if (entry.value[monthCounter].month < 10) {
                                        svname = entry.value[monthCounter].year.toString() + "0" + entry.value[monthCounter].month.toString()
                                    } else {
                                        svname = entry.value[monthCounter].year.toString() + entry.value[monthCounter].month.toString()
                                    }
                                    row[svname] = parseInt(entry.value[monthCounter].count) || 0
                                }
                                keywords.push(row)
                            }
                            this.preview_sv_data = preview_sv_data
                            this.createHeader(keywords)
                            this.btndisabled = false
                            keywords = this.$helpers.removeDuplicatesByAttribute(keywords, "keyword")
                            keywords.sort(function (a, b) {
                                return b.avgsv - a.avgsv
                            })
                            this.deletedKWByLeven = this.$helpers.removeDuplicatesByLeven(keywords)
                            this.keywords = keywords
                            // add rankings
                            if (
                                (this.$store.state.company_status_monitoring_suite == "active" || this.$store.state.company_status_monitoring_suite == "testing") &&
                                this.$store.state.monitoring_suite &&
                                this.$store.state.monitoring_domain &&
                                this.$store.state.monitoring_device !== null
                            )
                                this.addRankings(this.$store.state.monitoring_domain, this.$store.state.monitoring_device)

                            console.timeEnd("process response")
                            this.fetchCategories()

                            // console.log(this.catsLoading)
                            // console.log("after fetchingCategories")
                            let graphObjects = this.$helpers.buildGraphObjects([], keywords, this.dataChart.datasets, this.dataChart.labels)

                            // console.log(graphObjects)
                            this.dataChart.datasets = graphObjects.graphdata
                            this.dataChart.labels = graphObjects.graphlabel
                            this.countGraph2++
                            this.new_data++
                            this.graphloaded = true

                            // this.setAnnotationLine(response.body.entries[0].data[1].value.value[0].year);
                            this.carddata.subtitle = this.keywords.length + " keywords"
                            this.loading = false
                            this.loaded = true
                        })
                        .catch((err) => {
                            this.btndisabled = false
                            console.log(err)
                        })
                }
            },
            addRankings(url, device) {
                // let user_settings = this.$store.state.user_settings
                // user_settings.monitoring_domain = url
                // this.$store.dispatch("CHANGE_USER_SETTINGS", user_settings)
                // this.$store.dispatch("SET_MONITORING_DOMAIN", url)
                this.$helpers.addRankings(this.keywords, this.headers, this.selectedLocation.value, url, device)
            },
            async fetchQIdeas(keywords, date) {
                try {
                    let options = {}
                    options.keywords = keywords
                    options.location_code = this.siteKeywordsLocation.value
                    options.language_code = "de"
                    options.closely_variants = true
                    options.order_by = ["keyword_info.search_volume,desc"]
                    options.include_serp_info = true
                    options.limit = 1000
                    options.date_from = date
                    let req_data = { options: options, company: localStorage.company_id, user: localStorage.id, request_type: "labs_ideas" }
                    const req_url = process.env.VUE_APP_APIURL + "/v2/sources"

                    const response_data = await this.$helpers.axiosPost(req_url, req_data)
                    // check if no results
                    if (!response_data.data.result.items.length) {
                        //TODO: end loading
                        this.notification = this.$helpers.createNotification("We could not find any keyword ideas for your search!", "mdi-alert-circle-outline", "#FCBA03")
                        return
                    }
                    // format response data
                    this.keywords = await this.$helpers.formatDFSKeywords(response_data.data.result.items, this.catData)
                    this.createHeader(this.keywords)
                    let graphObjects = this.$helpers.buildGraphObjects([], this.keywords, this.dataChart.datasets, this.dataChart.labels)

                    // console.log(graphObjects)
                    this.dataChart.datasets = graphObjects.graphdata
                    this.dataChart.labels = graphObjects.graphlabel
                    this.countGraph2++
                    console.log(this.keywords)
                    let insertedWord = this.keywords.filter((kw) => kw.keyword == this.serpKeyword)
                    this.preview_sv_data = { keyword: this.serpKeyword, value: insertedWord.avgsv }
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    this.loading = false
                    this.loaded = true
                } catch (error) {
                    console.log(error)
                    this.notification = this.$helpers.createNotification("Something went wrong! Please try again or contact support.", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            createHeader(keywords) {
                let monthArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                const researchHeaderIndex = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")
                let modifiedHeaderIndex = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")

                // reset SV Headers
                this.headers.standardHeaders[researchHeaderIndex].columns = []
                if (this.headers.modifiedHeaders.length > 0) {
                    this.headers.modifiedHeaders[modifiedHeaderIndex].columns = []
                }

                let headers = []
                keywords.map((keyword) => {
                    keyword.search_volumes.map((vol) => {
                        let header = {
                            group: 1,
                            custom: false,
                            active: true,
                            canBeModified: false,
                            modifiedText: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            text: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            sortable: true,
                            width: "160"
                        }
                        if (vol.month < 10) {
                            header.value = vol.year.toString() + "0" + vol.month.toString()
                        } else {
                            header.value = vol.year.toString() + vol.month.toString()
                        }
                        header.dataChartLabel = monthArray[vol.month] + " " + vol.year
                        // console.log(headers.findIndex(p => p.text === header.text) === -1 )
                        if (headers.findIndex((p) => p.text === header.text) === -1) headers.push(header)
                    })
                })
                headers.sort(function (a, b) {
                    return a.value - b.value
                })
                this.headers.standardHeaders[researchHeaderIndex].columns = headers
                if (this.headers.modifiedHeaders.length == 0) {
                    this.headers.modifiedHeaders = JSON.parse(JSON.stringify(this.headers.standardHeaders))
                } else {
                    this.headers.modifiedHeaders[modifiedHeaderIndex].columns = headers
                }
            },
            getIndexOfHeader(header, headers) {
                //  console.log(header)
                for (let index = 0; index < headers.length; index++) {
                    if (header.text == headers[index].text) {
                        return true
                    }
                }
                return false
            },

            cleanArray(arr) {
                return arr.filter((e) => {
                    return e != false && e !== null && e !== " " && e !== ""
                })
            },
            splitArray(myArray, chunk_size) {
                let myChunk = []
                let tempArray = []
                myArray = this.cleanArray(myArray) // hier weiter
                for (let index = 0; index < myArray.length; index += chunk_size) {
                    myChunk = myArray.slice(index, index + chunk_size)
                    tempArray.push(myChunk)
                }
                return tempArray
            },
            checkIfSendTo() {
                if (this.$store.state.send_to_keywords.length) {
                    this.keywords_ideas = this.$store.state.send_to_keywords.reduce((a, b) => a + String.fromCharCode(44) + b)
                    // this.fetchKeywords()
                    this.$store.dispatch("SET_SEND_TO_KEYWORDS", [])
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            // if (this.unfilteredKeywords.length) {
            //     console.log("drinneeeeeeeeeeeee")
            //     this.keywords = [...this.unfilteredKeywords]
            // }
            // console.log(" kw length: ", this.keywords.length)
            // console.log("unfiltered kw length: ", this.unfilteredKeywords.length)
            window.removeEventListener("resize", this.checkHeight)
            this.delay = false
            next()
        },
        // beforeRouteEnter(to, from, next) {
        //     next((vm) => {
        //         vm.headers.standardHeaders = JSON.parse(JSON.stringify(standardHeaders))

        //         vm.fetchAllGoogleCats()
        //         vm.$router.onReady(() => {
        //             if (vm.$route.query.tour == "ideas") {
        //                 vm.startTour()
        //             }
        //         })

        //         if (vm.keywords.length) {
        //             vm.unfilteredKeywords = [...vm.keywords]
        //             vm.keywords = vm.$helpers.filterKeywordTable(vm.unfilteredKeywords)
        //         }
        //         vm.checkIfSendTo()
        //         //test end check
        //         if (vm.$store.state.company_status === "test_ended") {
        //             vm.$router.push("/endoftest")
        //         }
        //     })
        // },
        activated() {
            this.$router.onReady(() => {
                this.delay = true

                if (this.$route.query.tour == "ideas") {
                    this.startTour()
                }
                this.headers.standardHeaders = JSON.parse(JSON.stringify(standardHeaders))

                this.fetchAllGoogleCats()
                // console.log(" kw length before : ", JSON.stringify(this.keywords.length))
                // console.log("unfiltered kw length before: ", JSON.stringify(this.unfilteredKeywords.length))
                // if (this.keywords.length) {
                //     this.unfilteredKeywords = [...this.keywords]
                //     this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
                // }
                // console.log(" kw length after : ", this.keywords.length)
                // console.log("unfiltered kw length after: ", this.unfilteredKeywords.length)
                this.checkIfSendTo()
                //test end check
                if (this.$store.state.company_status === "test_ended") {
                    this.$router.push("/endoftest")
                }
            })
        },
        created() {
            this.checkHeight()
            window.addEventListener("resize", this.checkHeight)
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.checkHeight)
        },

        mounted() {
            this.$router.onReady(() => {
                if (this.$route.query.tour == "ideas") {
                    this.startTour()
                }
                this.headers.standardHeaders = JSON.parse(JSON.stringify(standardHeaders))

                this.fetchAllGoogleCats()
                if (this.keywords.length) {
                    this.unfilteredKeywords = [...this.keywords]
                    this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
                }
                this.checkIfSendTo()
                //test end check
                if (this.$store.state.company_status === "test_ended") {
                    this.$router.push("/endoftest")
                }
            })
        }
    }
</script>

<style scoped>
    @media (max-height: 800px) {
        .small-height {
            flex: 0 0 100%;
            max-width: 100%;
        }
        #col1 {
            order: 2 !important;
        }
        #col2 {
            order: 1 !important;
        }
    }

    #sendBtn {
        height: 56px !important;
    }
    #searchRow {
        padding-top: 0px !important;
    }
    h2 {
        font-family: Nunito;
        text-transform: uppercase;
        font-weight: 500 !important;
        color: var(--v-primary-base);
        text-align: center;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .chartBtn {
        float: right !important;
    }
    .chartCardHeading {
        padding-top: 23px;
        padding-left: 23px;
    }
    #cardtitle {
        padding-bottom: 15px;
        padding-top: 15px;
        background-color: #42defa;
        color: white;
    }
    .Innenabstand {
        padding: 20px;
    }
    #kwpformflex {
        padding-top: 0%;
    }
    #kwpform {
        padding-left: 0.5%;
    }

    #searchbutton {
        text-align: center;
    }
    .seitenabstand {
        padding-left: 25px;
        padding-right: 25px;
    }
    #tourbuttom {
        z-index: 9999;
        position: fixed;
        bottom: 5px;
        left: calc(100vw - 50% - 60px);
        background: rgb(28, 237, 179);
        background: linear-gradient(90deg, #009f89 0%, #e68989 75%);
    }
    .v-step {
        z-index: 9999;
    }
    .spark {
        padding: 0%;
    }
    .v-card .v-divider {
        border-color: white;
    }
    .fabicon {
        margin-left: 10px;
        margin-top: 10px;
    }
    #buttonFlex {
        padding-left: 2px;
    }
    #searchbar {
        margin-left: auto;
        margin-right: auto;
        max-width: 1300px;
    }
    .linechart-col {
        height: 100%;
    }
    .searchbarcol {
        padding-left: 75px;
        padding-right: 75px;
    }
    .search_settings_btn:hover {
        cursor: pointer;
    }
</style>
