module.exports = {
    getStandardParams() {
        var alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ä", "ö", "ü"]
        var numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        return { alphabet: alphabet, numbers: numbers }
    },

    // Build params for sending to google api
    // Combinations:
    // Example: "auto", alphabet = a,b, numbers = 1,2
    // Params:
    // qs = [
    //     auto,
    //     auto,
    //     auto,
    //     auto,
    //     a,
    //     b,
    //     1,
    //     2
    // ]
    // search = [
    //     a,
    //     b,
    //     1,
    //     2,
    //     auto,
    //     auto,
    //     auto,
    //     auto
    // ]
    buildParamsAmazon(keyword, market, category, language, marketplacevalue) {
        var standardParams = this.getStandardParams()
        var search = []
        var qs = []
        var urls = []

        // https://completion.amazon.co.uk/search/complete?&search-alias=aps&l=de_DE&mkt=4&q=test&qs=c&client=amazon-search-ui&callback=jsonp_21296bbcdedd100

        var usApi = "https://completion.amazon.com/search/complete?"
        var euApi = "https://completion.amazon.co.uk/search/complete?"
        var jpApi = "https://completion.amazon.co.jp/search/complete?"
        var cnApi = "https://completion.amazon.cn/search/complete?"
        var api

        if (market == "Brazil" || market == "Canada" || market == "Mexico" || market == "United States") {
            api = usApi
        } else if (market == "Japan") {
            api = jpApi
        } else if (market == "China") {
            api = cnApi
        } else {
            api = euApi
        }

        for (var i = 0; i < standardParams.alphabet.length; i++) {
            qs.push(standardParams.alphabet[i])
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            qs.push(standardParams.numbers[i])
        }
        var lengthQs = qs.length
        for (var i = 0; i < lengthQs; i++) {
            qs.push(keyword)
        }

        for (var i = 0; i < lengthQs; i++) {
            search.push(keyword)
        }
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(standardParams.alphabet[i])
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(standardParams.numbers[i])
        }
        // //console.log(search)
        // //console.log(qs)

        for (let urlCounter = 0; urlCounter < search.length; urlCounter++) {
            urls.push({
                url: api + "&search-alias=" + category + "&l=" + language + "&mkt=" + marketplacevalue + "&q=" + search[urlCounter] + "&qs=" + qs[urlCounter] + "&client=amazon-search-ui",
                platform: "amazon"
            })
        }

        let params = {
            search: search,
            qs: qs
        }
        return { params: params, urls: urls, keyword: keyword }
    },
    buildParamsAmazonV2(keyword, category, marketplace) {
        var standardParams = this.getStandardParams()
        var search = []
        var urls = []

        search.push(keyword)
        for (var i = 0; i < standardParams.alphabet.length; i++) {
            search.push(keyword + standardParams.alphabet[i])
        }
        for (var i = 0; i < standardParams.numbers.length; i++) {
            search.push(keyword + standardParams.numbers[i])
        }
        console.log(search)
        // //console.log(qs)

        for (let urlCounter = 0; urlCounter < search.length; urlCounter++) {
            urls.push({
                url: marketplace.url + "alias=" + category + "&lop=" + marketplace.lang + "&mid=" + marketplace.value + "&prefix=" + search[urlCounter],
                platform: "amazon"
            })
        }

        let params = {
            search: search
        }
        return { params: params, urls: urls, keyword: keyword }
    }
}
