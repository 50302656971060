var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.competitors,"loading":_vm.loading,"sort-desc":_vm.sortDesc,"sort-by":_vm.sortBy,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"fixed-header":"","height":"70vh","id":"monitoringCompTable"},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header_entry,index){return {key:("header." + (header_entry.value)),fn:function(ref){
var header = ref.header;
return [_c('span',{key:index},[_c('span',{style:({ width: header.width })},[_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":"","disabled":header.tooltip ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"white-space":"nowrap","text-transform":"uppercase","font-weight":"500"}},on),[_vm._v(" "+_vm._s(header.text)+" "),(header.tooltip)?_c('v-icon',{staticClass:"questionIcon",attrs:{"x-small":""}},[_vm._v("mdi-help-circle-outline")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-help-circle-outline")])],1)]),_vm._v(" "+_vm._s(header.tooltip)+" ")],1)])],1)])]}}}),{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex py-3"},[_c('div',{staticClass:"fav_wrapper my-auto mr-4 ml-3"},[_c('img',{staticClass:"fav",attrs:{"src":'https://s2.googleusercontent.com/s2/favicons?domain=' + item.domain + '&sz=42',"alt":""}})]),_c('div',{staticClass:"my-auto dtd"},[_vm._v(" "+_vm._s(item.domain)+" ")])])]}},{key:"item.visibility.0.value",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._b({staticClass:"d-flex justify-end"},'div',(_vm.change = _vm.calculatePercentageChange(item.visibility[0], item.visibility[1], 'value')),false),[_c('span',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(item.visibility[0].value)))]),(item.visibility.length)?_c('v-chip',{staticClass:"ml-2 qchip",attrs:{"color":_vm.change.value == 0 ? '' : _vm.change.value < 0 ? 'red' : 'green',"outlined":""}},[(_vm.change.value !== '0.00')?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.change.value < 0 ? 'red' : 'green'}},[_vm._v(_vm._s(_vm.change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up"))]):_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"grey darken-2"}},[_vm._v("mdi-arrow-right")]),_c('span',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.change.value)+"%")])],1):_vm._e()],1)]}},{key:"item.visibility.0.top10",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._b({staticClass:"d-flex justify-end"},'div',(_vm.change = _vm.calculatePercentageChange(item.visibility[0], item.visibility[1], 'top10')),false),[_c('span',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(item.visibility[0].top10)+" ")]),(item.visibility.length)?_c('v-chip',{staticClass:"ml-2 qchip",attrs:{"color":_vm.change.value == 0 ? '' : _vm.change.value < 0 ? 'red' : 'green',"outlined":""}},[(_vm.change.value !== '0.00')?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.change.value < 0 ? 'red' : 'green'}},[_vm._v(_vm._s(_vm.change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up"))]):_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"grey darken-2"}},[_vm._v("mdi-arrow-right")]),_c('span',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.change.value)+"%")])],1):_vm._e()],1)]}},{key:"item.visibility.0.top50",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._b({staticClass:"d-flex justify-end"},'div',(_vm.change = _vm.calculatePercentageChange(item.visibility[0], item.visibility[1], 'top50')),false),[_c('span',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(item.visibility[0].top50)+" ")]),(item.visibility.length)?_c('v-chip',{staticClass:"ml-2 qchip",attrs:{"color":_vm.change.value == 0 ? '' : _vm.change.value < 0 ? 'red' : 'green',"outlined":""}},[(_vm.change.value !== '0.00')?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.change.value < 0 ? 'red' : 'green'}},[_vm._v(_vm._s(_vm.change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up"))]):_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"grey darken-2"}},[_vm._v("mdi-arrow-right")]),_c('span',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.change.value)+"%")])],1):_vm._e()],1)]}},{key:"item.visibility.0.top100",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._b({staticClass:"d-flex justify-end"},'div',(_vm.change = _vm.calculatePercentageChange(item.visibility[0], item.visibility[1], 'top100')),false),[_c('span',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(item.visibility[0].top100))]),(item.visibility.length)?_c('v-chip',{staticClass:"ml-2 qchip",attrs:{"color":_vm.change.value == 0 ? '' : _vm.change.value < 0 ? 'red' : 'green',"outlined":""}},[(_vm.change.value !== '0.00')?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.change.value < 0 ? 'red' : 'green'}},[_vm._v(_vm._s(_vm.change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up"))]):_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"grey darken-2"}},[_vm._v("mdi-arrow-right")]),_c('span',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.change.value)+"%")])],1):_vm._e()],1)]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.generateCreatedDate(item._id))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }