<template>
    <div id="contentcontainter" class="standard-mode">
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="info.show">
            <v-icon>{{ info.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <v-slide-x-transition v-if="!loading">
            <div class="mt-10 mb-5" v-if="project">
                <component-header
                    :active="{ url: 'content-projects', name: 'Content Project', destination: 'content-project/wdf-idf' }"
                    :title="project.title"
                    :id="project._id"
                    :device="project.device"
                    :project="project"
                    :language_code="project.langloc.language_code"
                    :share_data="share_data"
                    :shared_extern="shared_extern"
                    :shared_company_id="sharedCompanyId"></component-header>
            </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
            <v-row v-if="!loading">
                <v-col>
                    <v-expansion-panels v-model="chart_panel" flat accordion elevation-1 class="px-0">
                        <v-expansion-panel class="quaroshadow rounded_card">
                            <div class="backsheet"></div>
                            <v-expansion-panel-header dense class="py-1">
                                <div class="quarolbl">
                                    <v-icon class="pb-1 mr-1" small color="grey">mdi-chart-timeline-variant</v-icon>
                                    Chart
                                </div>
                                <div class="chartswitch">
                                    <v-btn-toggle mandatory color="primary" rounded v-model="chartswitch" borderless class="mx-auto" dense @click.native.stop>
                                        <v-btn value="wdf" small>
                                            <span class="px-3">WDF</span>
                                        </v-btn>

                                        <v-btn value="wdfidf" small>
                                            <span class="">WDF*IDF</span>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <stacked-chart v-if="chartswitch === 'wdf'" class="pb-2" :chartData="wdf_chartData" :reloadChart="reloadChart"></stacked-chart>
                                <stacked-chart v-else class="pb-2" :chartData="wdfidf_chartData" :reloadChart="reloadChart"></stacked-chart>
                                <!-- <v-range-slider max-width="300px" v-model="range" :max="100" :min="1" hide-details class="align-center"></v-range-slider> -->
                                <div class="flex" no-gutters>
                                    <div class="mx-auto">
                                        <v-btn
                                            small
                                            :disabled="range.min == 1"
                                            color="primary"
                                            @click="
                                                range.min -= 20
                                                range.max -= 20
                                                generateChart()
                                            "
                                            icon
                                            ><v-icon>mdi-chevron-left</v-icon></v-btn
                                        >
                                        <v-chip small class="mx-1" color="primary"> {{ range.min }}-{{ range.max }}</v-chip>
                                        <v-btn
                                            small
                                            :disabled="range.min + 20 > analysisData.length"
                                            color="primary"
                                            @click="
                                                range.min += 20
                                                range.max += 20
                                                generateChart()
                                            "
                                            icon
                                            ><v-icon>mdi-chevron-right</v-icon></v-btn
                                        >
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-slide-x-transition>
        <v-row>
            <v-col md="12" sm="12" x-sm="12">
                <v-slide-x-transition>
                    <v-expansion-panels v-model="metrics_panel" flat accordion elevation-1 class="px-0" v-if="!loading">
                        <v-expansion-panel class="quaroshadow rounded_card">
                            <div class="backsheet"></div>
                            <v-expansion-panel-header dense class="py-1">
                                <div class="quarolbl">
                                    <v-icon class="pb-1 mr-1" small color="grey">mdi-cog</v-icon>
                                    Settings
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row no-gutters>
                                    <v-col class="pa-0 mb-2" md="6" sm="12">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl">Reference</span>
                                            <v-chip-group color="orange darken-2" column show-arrows v-model="reffilter" multiple next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                <v-chip v-for="(refType, i) in refTypes" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                                    {{ refType.label }}
                                                </v-chip>
                                            </v-chip-group>
                                            <v-text-field disabled hide-details rounded filled dense :value="project.url" placeholder="no reference URL"></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col class="pa-0 mb-2" md="6" sm="12">
                                        <div class="selectionCol">
                                            <span class="qsublbl">Competitors</span> <v-chip class="ml-2 white--text" x-small color="red lighten-2">coming soon</v-chip>
                                            <v-chip-group color="primary" column show-arrows v-model="compfilter" mandatory next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                <v-chip disabled v-for="(preSelection, i) in preSelections" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                                    {{ preSelection.label }}
                                                </v-chip>
                                            </v-chip-group>
                                            <v-select disabled hide-details rounded filled dense multiple :items="competitors" v-model="selectedCompetitors" placeholder="select manually"></v-select>
                                        </div>
                                    </v-col>
                                    <!-- <v-col class="pa-0 mb-2" md="7" sm="12">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl">Keywords</span>
                                            <v-chip-group color="primary" column v-model="keywordfilter" mandatory show-arrows multiple next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                <v-chip v-for="topic in selectedTopics" :key="topic.keyword" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                                    {{ topic.keyword }}
                                                </v-chip>
                                            </v-chip-group>
                                        </div>
                                    </v-col> -->
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="pa-0 mb-2" md="12" sm="12">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl">Metrics</span>
                                            <v-chip-group color="cyan darken-2" column show-arrows v-model="headerfilter" mandatory multiple next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                <v-chip v-for="(header, i) in selectableHeaders" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                                    {{ header.text }}
                                                </v-chip>
                                            </v-chip-group>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row> </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-slide-x-transition>
            </v-col>
        </v-row>

        <div v-if="loading">
            <div style="display: flex">
                <span class="circularprogress mx-auto">
                    <img id="loader" src="../../assets/quaro-q.svg" />
                </span>
            </div>
        </div>
        <v-slide-x-transition>
            <v-card class="quaroshadow mt-4 rounded_card" v-if="!loading && analysisData.length">
                <div class="backsheet"></div>
                <v-row>
                    <v-col class="flex">
                        <v-btn-toggle class="ml-3" mandatory color="primary" rounded v-model="table_switch" borderless dense @click.native.stop>
                            <v-btn value="all" small>
                                <span class="px-3">All Keywords</span>
                            </v-btn>

                            <v-btn value="selected" small>
                                <span class="">Briefing Keywords</span>
                            </v-btn>
                        </v-btn-toggle>
                        <v-text-field
                            class="ml-auto mr-2"
                            v-model="search"
                            rounded
                            dense
                            style="max-width: 400px"
                            filled
                            append-icon="mdi-magnify"
                            label="Search for keywords"
                            single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>

                <v-data-table
                    id="wdftable"
                    height="750px"
                    fixed-header
                    :search="search"
                    :loading="tableLoading"
                    :headers="tableHeader"
                    :items="analysisData"
                    class="elevation-0"
                    :items-per-page="50"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 50, 100]
                    }"
                    :custom-sort="customSort">
                    <!-- header slot -->
                    <template v-for="(header_entry, index) in tableHeader" v-slot:[`header.${header_entry.value}`]="{ header }">
                        <span :key="index">
                            <span v-bind:style="{ width: header.width }">
                                <!-- Adding tooltips to header -->
                                <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="quarotblheader">
                                            {{ header.text }}
                                            <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                        </span>
                                    </template>
                                    <span>
                                        <v-row no-gutters>
                                            <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                        </v-row>
                                        {{ header.tooltip }}
                                    </span>
                                </v-tooltip>
                            </span>
                        </span>
                    </template>
                    <!-- Table Body -->
                    <template v-slot:body="{ items }">
                        <tbody v-if="items.length">
                            <tr v-for="(item, itemIndex) in items" :key="itemIndex">
                                <td
                                    v-for="(header, headerIndex) in tableHeader"
                                    :key="headerIndex"
                                    class="py-4"
                                    :style="
                                        $vuetify.theme.dark
                                            ? 'background-color: black;'
                                            : header.value.substring(0, 4) == 'wdf_' || header.value.substring(0, 7) == 'wdfidf_'
                                            ? 'background-color: #f6f6f6;'
                                            : ''
                                    ">
                                    <!-- ------- -->
                                    <!-- keyword -->
                                    <!-- ------- -->
                                    <div v-if="header.value === 'keyword'" class="td_keyword">
                                        {{ item.variants ? item.variants.reduce((a, b) => (a.length < b.length ? a : b)) : item.keyword }}
                                    </div>
                                    <!-- -------------- -->
                                    <!---- relevance ----->
                                    <!-- -------------- -->
                                    <!-- <div v-if="header.value === 'relevance'" v-bind="(relevance = getRelevance(item))">
                                        <v-progress-linear style="border-radius: 12px; font-size: 12px" class="white--text" rounded :value="relevance.value" color="primary" height="24">
                                            {{ relevance.value }}%
                                        </v-progress-linear>
                                    </div> -->
                                    <!-- -------------- -->
                                    <!---- avgsv ----->
                                    <!-- -------------- -->
                                    <!-- <div v-if="header.value === 'avgsv'">
                                        <v-chip small color="primary"> {{ $helpers.formatNumber(item[header.value]) }}</v-chip>
                                    </div> -->
                                    <!-- -------------- -->
                                    <!---- url_coverage ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'url_coverage'">
                                        <v-progress-linear
                                            class="white--text"
                                            style="border-radius: 12px; font-size: 12px"
                                            rounded
                                            :value="getPercent(item[header.value])"
                                            color="cyan darken-1"
                                            height="24">
                                            {{ getPercent(item[header.value]) }}%
                                        </v-progress-linear>
                                    </div>
                                    <!-- -------------- -->
                                    <!---- avgKeywordFrequency ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'avgKeywordFrequency'" class="flex">
                                        <span class="ma-auto">{{ item[header.value] ? item[header.value].toFixed(0) : 0 }}</span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- avg_wdf----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'avgWDF'" class="flex">
                                        <span class="ma-auto">{{ item[header.value] ? item[header.value].toFixed(3) : 0 }}</span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- max_wdf----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'maxwdf'" class="flex">
                                        <span class="ma-auto">{{ item[header.value] ? item[header.value].toFixed(3) : 0 }}</span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- min_wdf----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'minwdf'" class="flex">
                                        <span class="ma-auto"> {{ item[header.value] ? item[header.value].toFixed(3) : 0 }}</span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- wdf_url----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'wdf_url'" class="flex">
                                        <span class="ma-auto" v-if="item[header.value] && item[header.value] != '-'"> {{ item[header.value].toFixed(3) }}</span>
                                        <span class="ma-auto" v-else><v-chip class="white--text" color="deep-orange darken-2"> - </v-chip></span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- wdf_editor----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'wdf_editor'" class="flex">
                                        <span class="ma-auto" v-if="item[header.value] && item[header.value] != '-'"> {{ item[header.value].toFixed(3) }}</span>
                                        <span class="ma-auto" v-else><v-chip class="white--text" color="deep-orange darken-2"> - </v-chip></span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- word_count----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'word_count'" class="flex">
                                        <span class="ma-auto"> {{ item.keyword.split(" ").length }}</span>
                                    </div>
                                    <!-- ------ -->
                                    <!---- idf----->
                                    <!-- ------ -->
                                    <div v-else-if="header.selected && header.value === 'idf'">
                                        {{ item[header.value] ? item[header.value].idf.toFixed(3) : 0 }}
                                    </div>
                                    <!-- ------ -->
                                    <!---- termplacement----->
                                    <!-- ------ -->
                                    <div v-else-if="header.selected && header.value === 'termplacement'">
                                        <v-chip-group>
                                            <v-chip small class="white--text mr-1 my-1" v-for="(placement, index) in item[header.value]" :key="index" :color="colors[placement.tag]">{{
                                                placement.tag
                                            }}</v-chip>
                                        </v-chip-group>
                                    </div>

                                    <div v-else class="flex">
                                        <span class="ma-auto" v-if="item[header.value] && item[header.value] != '-'"> {{ item[header.value].toFixed(3) }}</span>
                                        <span class="ma-auto" v-else><v-chip class="white--text" color="deep-orange darken-2"> - </v-chip></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-card>
        </v-slide-x-transition>
        <AddUrlDialogVue
            :show="addURLDialog.show"
            :id="addURLDialog.id"
            @close="
                addURLDialog.show = false
                fetchProjects()
            "
            :setMainURL="true"
            :addLighthouseData="true"></AddUrlDialogVue>

        <v-snackbar top v-model="notification.show" :color="notification.color" v-if="notification">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
    </div>
</template>

<script>
    import AddUrlDialogVue from "../../components/content-suite/AddUrlDialog.vue"
    import ComponentHeader from "../../components/content-suite/ComponentHeader.vue"
    import StackedChart from "../../components/charts/StackedChart.js"
    import { reject } from "q"
    export default {
        components: {
            AddUrlDialogVue,
            StackedChart,
            ComponentHeader
        },
        data() {
            return {
                chartswitch: "wdfidf",
                table_switch: "all",
                range: { min: 1, max: 20 },
                reloadChart: 0,
                chartData: {},
                wdf_chartData: {
                    labels: ["1", "2", "3", "4"],
                    datasets: [
                        {
                            value: "maxwdf",
                            label: "WDF (max)",
                            data: [],
                            borderColor: "#00acc1",
                            backgroundColor: "#00acc138",
                            stack: "combined",
                            pointStyle: "circle",
                            pointRadius: 8,
                            pointHoverRadius: 12,
                            borderWidth: 2
                        },

                        {
                            value: "avgwdf",
                            label: "WDF (Ø)",
                            data: [],
                            borderColor: "rgb(66, 135, 245)",
                            backgroundColor: "rgba(66, 135, 245, .3)",
                            borderWidth: 2,
                            stack: "combined",
                            borderRadius: 5,
                            borderSkipped: false,
                            type: "bar"
                        }
                    ]
                },
                wdfidf_chartData: {
                    labels: ["1", "2", "3", "4"],
                    datasets: [
                        {
                            value: "maxwdfidf",
                            label: "WDF*IDF (max)",
                            data: [],
                            borderColor: "#00acc1",
                            backgroundColor: "#00acc138",
                            stack: "combined",
                            pointStyle: "circle",
                            pointRadius: 8,
                            pointHoverRadius: 12,
                            borderWidth: 2
                        },

                        {
                            value: "avgwdfidf",
                            label: "WDF*IDF (Ø)",
                            data: [],
                            borderColor: "rgb(66, 135, 245)",
                            backgroundColor: "rgba(66, 135, 245, .3)",
                            borderWidth: 2,
                            stack: "combined",
                            borderRadius: 5,
                            borderSkipped: false,
                            type: "bar"
                        }
                    ]
                },
                compfilter: [2],
                competitors: [],
                selectedCompetitors: [],
                preSelections: [
                    { selected: true, label: "All" },
                    { selected: false, label: "Top 10" },
                    { selected: false, label: "Top 20" },
                    { selected: false, label: "Top 30" },
                    { selected: false, label: "Top 40" }
                ],
                refTypes: [
                    { selected: true, label: "URL" },
                    { selected: false, label: "Content-Editor" }
                ],
                reffilter: [0, 1],
                metrics_panel: 0,
                chart_panel: 0,
                project: null,
                addURLDialog: {
                    id: null,
                    show: false
                },
                analysisData: [],
                notification: null,
                loading: true,
                headers: [
                    {
                        text: "Term",
                        value: "keyword",
                        sortable: true,
                        selectable: false,
                        selected: true,
                        width: 250
                    },
                    {
                        text: "Document Count",
                        value: "url_coverage",
                        sortable: true,
                        width: "175px",
                        tooltip: "This column shows the number of documents where the keyword appears. It can help you understand how many of your competitors are using the same keyword.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "Term Placement",
                        value: "termplacement",
                        sortable: false,
                        tooltip: "This column shows the location of the keyword in the document. It can help you understand where your competitors are placing the keyword on their website.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "WDF (min)",
                        value: "minwdf",
                        sortable: true,
                        tooltip:
                            "The Within Document Frequency (WDF) is a weighted value that represents the keyword density within a document. WDF min shows the minimum value for the keyword across all documents.",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF (max)",
                        value: "maxwdf",
                        sortable: true,
                        tooltip:
                            "The Within Document Frequency (WDF) is a weighted value that represents the keyword density within a document. WDF max shows the maximum value for the keyword across all documents.",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF (Ø)",
                        value: "avgWDF",
                        sortable: true,
                        tooltip:
                            "The Within Document Frequency (WDF) is a weighted value that represents the keyword density within a document. WDF Ø shows average WDF value for the keyword across all documents.",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF (url)",
                        value: "wdf_url",
                        sortable: true,
                        tooltip: "The Within Document Frequency for your reference URL of this project",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF (editor)",
                        value: "wdf_editor",
                        sortable: true,
                        tooltip: "The Within Document Frequency for the Editor-Content of this project",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF*IDF (min)",
                        value: "minwdfidf",
                        sortable: true,
                        tooltip:
                            "The WDF multiplied by Inverse Document Frequency (WDF*IDF) is a value that indicates how important the keyword is to the document. WDFIDF min/max/Ø show the minimum, maximum, and average WDF*IDF values for the keyword across all documents.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "WDF*IDF (max)",
                        value: "maxwdfidf",
                        sortable: true,
                        tooltip:
                            "The WDF multiplied by Inverse Document Frequency (WDF*IDF) is a value that indicates how important the keyword is to the document. WDFIDF min/max/Ø show the minimum, maximum, and average WDF*IDF values for the keyword across all documents.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "WDF*IDF (Ø)",
                        value: "avgWDFIDF",
                        sortable: true,
                        tooltip:
                            "The WDF multiplied by Inverse Document Frequency (WDF*IDF) is a value that indicates how important the keyword is to the document. WDFIDF min/max/Ø show the minimum, maximum, and average WDF*IDF values for the keyword across all documents.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "WDF*IDF (url)",
                        value: "wdfidf_url",
                        sortable: true,
                        tooltip: "The WDF multiplied by Inverse Document Frequency (WDF*IDF) for your reference URL",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "WDF*IDF (editor)",
                        value: "wdfidf_editor",
                        sortable: true,
                        tooltip: "The WDF multiplied by Inverse Document Frequency (WDF*IDF) for the Editor-Content of this project",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "IDF",
                        value: "idf",
                        sortable: true,
                        tooltip:
                            "The Inverse Document Frequency (IDF) is a value that indicates how important the keyword is across all documents. A high IDF value means that the keyword is rare and appears in fewer documents.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "Ø Keyword Frequency",
                        value: "avgKeywordFrequency",
                        sortable: true,
                        tooltip:
                            "This column shows the average number of times the keyword appears in each document. It can help you understand how frequently your competitors are using the keyword.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "Word Count",
                        value: "word_count",
                        sortable: true,
                        tooltip: "Shows you how many words the term or phrase consists of.",
                        selectable: true,
                        selected: true
                    }
                ],
                colors: {
                    h1: "cyan darken-3",
                    h2: "purple darken-3",
                    h3: "lime darken-3",
                    h4: "light-green darken-3",
                    h5: "blue darken-3",
                    h6: "orange darken-3",
                    description: "grey darken-2",
                    title: "grey darken-2"
                },
                search: "",
                statusNotification: null,
                relevanceData: {
                    maxWDF: 0,
                    minWDF: 0,
                    minURLCoverage: 0,
                    maxURLCoverage: 0,
                    minPlacementValue: 0,
                    maxPlacementValue: 0
                },
                info: {
                    message: null,
                    icon: null,
                    color: null
                },

                //share
                sharedCompanyId: null,
                sharedId: null,
                sharedUserId: null,
                shared_extern: false,
                share_data: { briefing: false, editor: false, wdfidf: false, competitors: false },
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
            }
        },
        methods: {
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers)
                console.log(sortedItems)
                return sortedItems
            },
            async fetchProjectAnalysis() {
                return new Promise(async (resolve, reject) => {
                    const keywords_url = process.env.VUE_APP_APIURL + "/cs/get/keywords"
                    const project_url = process.env.VUE_APP_APIURL + "/cs/get/project"
                    const editor_url = process.env.VUE_APP_APIURL + "/cs/get/editor"
                    if (this.$route.params.project_id) {
                        const keywords_post_data = {
                            company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                            // user_id: localStorage.id,
                            project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id
                        }
                        const project_post_data = {
                            company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                            id: this.shared_extern ? this.sharedId : this.$route.params.project_id
                        }
                        const [project_response, keywords_response, editor_response] = await Promise.all([
                            this.$helpers.axiosPost(project_url, project_post_data),
                            this.$helpers.axiosPost(keywords_url, keywords_post_data),
                            this.$helpers.axiosPost(editor_url, keywords_post_data)
                        ])
                        this.project = project_response.data.project
                        if (editor_response.data.editorData) {
                            var editorWdfData = await this.editorWdfIdfAnalysis(editor_response.data.editorData.content)
                        }

                        this.share_data = {
                            briefing: project_response.data.project.briefing_shared_extern,
                            editor: project_response.data.project.editor_shared_extern,
                            wdfidf: project_response.data.project.wdfidf_shared_extern,
                            competitors: project_response.data.project.competitors_shared_extern
                        }
                        //resolve for shared check
                        resolve(project_response.data.project.wdfidf_shared_extern)

                        //reset chart variables
                        this.range = { min: 1, max: 20 }

                        this.analysisData = keywords_response.data.keywords.map((entry) => {
                            let wdfs = entry.wdf.map((w) => w.wdf.wdf)
                            entry.maxwdf = Math.max(...wdfs)
                            entry.minwdf = Math.min(...wdfs)
                            // entry.idf = entry.idf.idf ? entry.idf.idf : 0
                            if (this.project.comparissonURL && this.project.comparissonURL.wdfidf_data) {
                                let comparissonURL_entry = this.project.comparissonURL.wdfidf_data.data.find((wdfentry) => wdfentry.keyword == entry.keyword)
                                entry.wdf_url = comparissonURL_entry ? comparissonURL_entry.wdf.wdf : "-"
                            }

                            let editorwdfentry = editorWdfData ? editorWdfData.find((wdfentry) => wdfentry.keyword == entry.keyword) : null
                            entry.wdf_editor = editorwdfentry ? editorwdfentry.wdf.wdf : "-"

                            entry.maxwdfidf = entry.maxwdf ? entry.maxwdf * entry.idf.idf : "-"
                            entry.minwdfidf = entry.minwdf ? entry.minwdf * entry.idf.idf : "-"
                            entry.avgWDFIDF = entry.avgWDF ? entry.avgWDF * entry.idf.idf : "-"
                            entry.wdfidf_editor = entry.wdf_editor != "-" ? entry.wdf_editor * entry.idf.idf : "-"
                            entry.wdfidf_url = entry.wdf_url != "-" ? entry.wdf_url * entry.idf.idf : "-"
                            return entry
                        })
                        if (this.table_switch == "selected") this.analysisData = this.analysisData.filter((x) => x.selected)
                        let answ = this.analysisData.filter((x) => x.idf.idf == null)
                        console.log(answ)
                        this.generateChart()
                        this.loading = false
                        this.tableLoading = false
                    }
                })
            },

            editorWdfIdfAnalysis(doc) {
                return new Promise(async (resolve, reject) => {
                    try {
                        const url = process.env.VUE_APP_APIURL + "/cs/wdfidf"
                        let post_data = {
                            doc: doc,
                            wdf_version: this.project.wdf_version ? this.project.wdf_version : 1
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        resolve(response_data.data.data)
                        // console.log(response_data.data)
                        // this.analysisData = this.analysisData.map((entry) => {
                        //     let editorwdfentry = response_data.data.data.find((wdfentry) => wdfentry.keyword == entry.keyword)

                        //     entry.wdf_editor = editorwdfentry ? editorwdfentry.wdf.wdf : "-"
                        //     return entry
                        // })
                    } catch (error) {
                        console.log(error)
                    }
                })
            },
            generateChart() {
                //Setting labels
                let labels = this.sortedAnalysisData.map((kw, index) => "(" + (index + 1) + ") " + (kw.variants ? kw.variants.reduce((a, b) => (a.length < b.length ? a : b)) : kw.keyword))
                this.wdf_chartData.labels = labels.slice(this.range.min - 1, this.range.max - 1)
                this.wdfidf_chartData.labels = labels.slice(this.range.min - 1, this.range.max - 1)

                //WDF Data
                let avgwdf_dataset = this.wdf_chartData.datasets.find((dataset) => dataset.value == "avgwdf")
                avgwdf_dataset.data = this.sortedAnalysisData.map((kw) => kw.avgWDF * 10).slice(this.range.min - 1, this.range.max - 1)
                let maxwdf_dataset = this.wdf_chartData.datasets.find((dataset) => dataset.value == "maxwdf")
                maxwdf_dataset.data = this.sortedAnalysisData.map((kw) => kw.maxwdf * 10).slice(this.range.min - 1, this.range.max - 1)

                //WDF*IDF Data
                let avgwdfidf_dataset = this.wdfidf_chartData.datasets.find((dataset) => dataset.value == "avgwdfidf")
                avgwdfidf_dataset.data = this.sortedAnalysisData.map((kw) => kw.avgWDFIDF * 10).slice(this.range.min - 1, this.range.max - 1)
                let maxwdfidf_dataset = this.wdfidf_chartData.datasets.find((dataset) => dataset.value == "maxwdfidf")
                maxwdfidf_dataset.data = this.sortedAnalysisData.map((kw) => kw.maxwdfidf * 10).slice(this.range.min - 1, this.range.max - 1)

                this.getReferenceData()
            },
            getReferenceData() {
                let ref_wdf_dataset = {
                    value: "ref",
                    label: "Reference (URL)",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    borderColor: "#fbc02d",
                    borderWidth: 2,
                    stack: "combined",
                    pointStyle: "circle",
                    pointRadius: 8,
                    pointHoverRadius: 12
                }
                let ref_wdfidf_dataset = JSON.parse(JSON.stringify(ref_wdf_dataset))

                // genereate dataset for comparison url
                if (this.reffilter[0] === 0 || this.reffilter[1] === 0) {
                    if (this.project.comparissonURL && this.project.comparissonURL.wdfidf_data) {
                        //setting comparisson URL wdf data

                        ref_wdf_dataset.data = this.sortedAnalysisData
                            .map((kw) => {
                                let entryData = this.project.comparissonURL.wdfidf_data.data.find((entry) => entry.keyword == kw.keyword)
                                return entryData ? entryData.wdf.wdf * 10 : 0
                            })
                            .slice(this.range.min - 1, this.range.max - 1)

                        //setting comparisson URL wdf*idf data

                        ref_wdfidf_dataset.data = this.sortedAnalysisData
                            .map((kw) => {
                                let entryData = this.project.comparissonURL.wdfidf_data.data.find((entry) => entry.keyword == kw.keyword)
                                return entryData ? entryData.wdf.wdf * kw.idf.idf * 10 : 0
                            })
                            .slice(this.range.min - 1, this.range.max - 1)
                    }
                    let setIndex = this.wdf_chartData.datasets.findIndex((entry) => entry.value == "ref")
                    if (setIndex < 0) {
                        //add dataset
                        this.wdfidf_chartData.datasets.splice(1, 0, ref_wdfidf_dataset)
                        //add dataset
                        this.wdf_chartData.datasets.splice(1, 0, ref_wdf_dataset)
                    } else {
                        //reset data
                        this.wdfidf_chartData.datasets[setIndex].data = ref_wdfidf_dataset.data
                        //reset data
                        this.wdf_chartData.datasets[setIndex].data = ref_wdf_dataset.data
                    }
                } else {
                    // URL is not selected as reference
                    this.removeDataset("ref", 0)
                }
                let ref_editor_wdf_dataset = {
                    value: "ref-editor",
                    label: "Reference (Editor)",
                    data: [],
                    borderColor: "#00a089",
                    borderWidth: 2,
                    stack: "combined",
                    pointStyle: "circle",
                    pointRadius: 8,
                    pointHoverRadius: 12
                }
                let ref_editor_wdfidf_dataset = JSON.parse(JSON.stringify(ref_editor_wdf_dataset))

                if (this.reffilter[0] === 1 || this.reffilter[1] === 1) {
                    // genereate wdf dataset

                    ref_editor_wdf_dataset.data = this.sortedAnalysisData
                        .map((kw) => {
                            return kw.wdf_editor != "-" ? kw.wdf_editor * 10 : 0
                        })
                        .slice(this.range.min - 1, this.range.max - 1)

                    // genereate wdf*idf dataset

                    ref_editor_wdfidf_dataset.data = this.sortedAnalysisData
                        .map((kw) => {
                            return kw.wdf_editor != "-" ? kw.wdf_editor * kw.idf.idf * 10 : 0
                        })
                        .slice(this.range.min - 1, this.range.max - 1)
                    // add dataset
                    let setIndex = this.wdf_chartData.datasets.findIndex((entry) => entry.value == "ref-editor")
                    if (setIndex < 0) {
                        //add dataset
                        this.wdf_chartData.datasets.splice(2, 0, ref_editor_wdf_dataset)
                        this.wdfidf_chartData.datasets.splice(2, 0, ref_editor_wdfidf_dataset)
                    } else {
                        //reset data
                        this.wdfidf_chartData.datasets[setIndex].data = ref_editor_wdfidf_dataset.data
                        this.wdf_chartData.datasets[setIndex].data = ref_editor_wdf_dataset.data
                    }
                } else {
                    // URL is not selected as reference
                    this.removeDataset("ref-editor", 1)
                }
                this.reloadChart++
            },
            removeDataset(value, filterIndex) {
                if (this.reffilter[0] !== filterIndex && this.reffilter[1] !== filterIndex) {
                    let removeWDFIndex = this.wdf_chartData.datasets.findIndex((dataset) => dataset.value == value)
                    let removeWDFIDFIndex = this.wdfidf_chartData.datasets.findIndex((dataset) => dataset.value == value)
                    // remove
                    if (removeWDFIndex > -1) {
                        this.wdf_chartData.datasets.splice(removeWDFIndex, 1)
                    }
                    if (removeWDFIDFIndex > -1) {
                        this.wdfidf_chartData.datasets.splice(removeWDFIDFIndex, 1)
                    }
                }
            },
            getPercent(value) {
                return parseInt(value * 100)
            },
            fetchCompanyStatus() {
                return new Promise(async (resolve, reject) => {
                    this.$http.post(process.env.VUE_APP_APIURL + "/company/status", { company_id: this.sharedCompanyId }, { headers: { Authorization: this.auth } }).then(function (response) {
                        // console.log(response.body)

                        if (response.body[0].status !== "test_ended" || response.body[0].status !== "inactive") {
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    })
                })
            }
        },
        watch: {
            keywordfilter(val) {},
            chart_panel(val) {
                console.log(val)
                setTimeout(() => {
                    this.reloadChart++
                }, 1)
            },
            chartswitch(val) {
                // consol e.log(val)
                this.generateChart()
                // setTimeout(() => {
                //     this.reloadChart++
                // }, 1000)
            },
            table_switch(val) {
                // consol e.log(val)
                this.tableLoading = true
                this.fetchProjectAnalysis()
                // setTimeout(() => {
                //     this.reloadChart++
                // }, 1000)
            },
            reffilter(val) {
                console.log(val)
                this.generateChart()
                // val.map((entry) => {
                //     // entry = 0  | URL reference
                //     // entry = 1  | Editor reference
                // })
            }
        },
        computed: {
            sortedAnalysisData() {
                let switchhelper = this.chartswitch
                return this.analysisData.sort(function (a, b) {
                    if (switchhelper == "wdf") {
                        return b.avgWDF - a.avgWDF
                    } else {
                        return b.avgWDFIDF - a.avgWDFIDF
                    }
                })
            },
            headerfilter: {
                set(val) {
                    this.selectableHeaders.map((header, index) => {
                        if (val.includes(index)) {
                            header.selected = true
                        } else {
                            header.selected = false
                        }
                    })
                },
                get() {
                    let filter = []
                    this.selectableHeaders.map((header, index) => (header.selected ? filter.push(index) : ""))
                    return filter
                }
            },
            keywordfilter: {
                set(val) {
                    console.log(val)
                },
                get() {
                    let filter = []
                    this.selectedTopics.map((topic, index) => (topic.selected ? filter.push(index) : ""))
                    return filter
                }
            },
            selectedTopics() {
                return this.project ? this.project.topics.filter((topic) => topic.selected) : []
            },
            tableHeader() {
                return this.headers.filter((header) => header.selected)
            },
            selectableHeaders() {
                return this.headers.filter((header) => header.selectable)
            }
        },
        beforeRouteEnter(to, from, next) {
            next(async (vm) => {
                console.log("BLUBV")

                if (vm.$route.params.share_id) {
                    let encrypted = ""
                    try {
                        encrypted = await vm.CryptoJS.AES.decrypt(vm.$route.params.share_id.replace(new RegExp("Por21Ld", "g"), "/"), process.env.VUE_APP_ENCRYPTION_PW).toString(vm.CryptoJS.enc.Utf8)
                    } catch (error) {
                        console.log(error)
                        vm.info = vm.$helpers.createNotification("Sorry, we could not find what you are looking for!", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = true
                        return
                    }

                    let encryptedSplits = encrypted.split("|")
                    vm.sharedCompanyId = encryptedSplits[0]
                    vm.sharedId = encryptedSplits[1]
                    if (!vm.sharedId || !vm.sharedCompanyId) {
                        vm.info = vm.$helpers.createNotification("Sorry, we could not find what you are looking for!", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = true
                        return
                    }
                    vm.shared_extern = true

                    let informations = await Promise.all([vm.fetchProjectAnalysis(), vm.fetchCompanyStatus()])
                    let shared = informations[0]
                    console.log("sharino: ", shared)
                    let companyactive = informations[1]
                    if (shared && companyactive) {
                        console.log("inside active")
                        if (localStorage.sharedUserId && localStorage.sharedUserId.includes("extern_")) {
                            vm.sharedUserId = localStorage.sharedUserId
                        } else {
                            vm.sharedUserId = "extern_" + Math.random().toString(36).slice(2)
                            localStorage.sharedUserId = vm.sharedUserId
                        }

                        await vm.fetchProjectAnalysis()
                    } else {
                        console.log("not shared extern")
                        vm.shared_extern = false

                        vm.info = vm.$helpers.createNotification("The WDF*IDF Analysis is not shared.", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = true
                    }
                } else {
                    if (!vm.$store.state.content_suite) {
                        vm.$router.push("/")
                    } else {
                        await vm.fetchProjectAnalysis()

                        // vm.checkFocus()
                    }
                }
            })
        },
        mounted() {
            // this.fetchProjectAnalysis()
            // this.fetchEditor()
        }
    }
</script>

<style scoped lang="scss">
    // #wdftable table > thead > tr > th {
    //     background: var(--v-keywordcol-base) !important;
    // }
    // #wdftable table > tbody > tr > td:nth-child(1) {
    //     z-index: 2 !important;
    // }
    // #wdftable > div.v-data-table__wrapper > table > thead > tr > th:nth-child(1),
    // #wdftable table > thead > tr > th:nth-child(1) {
    //     position: sticky !important;
    //     position: -webkit-sticky !important;
    //     left: 0 !important;
    //     z-index: 3 !important;
    //     background: var(--v-keywordcol-base) !important;
    // }
    // #wdftable table > tbody > tr > td:nth-child(1),
    // #wdftable table > tbody > tr > th:nth-child(1) {
    //     position: sticky !important;
    //     position: -webkit-sticky !important;
    //     left: 0 !important;
    //     background: var(--v-keywordcol-base) !important;
    // }
    #wdftable table > thead > tr > th {
        background: var(--v-keywordcol-base) !important;
    }

    // #wdftable table > tbody > tr > td:nth-child(2) {
    //     z-index: 2 !important;
    // }
    // #wdftable table > thead > tr > th:nth-child(2) {
    //     z-index: 3 !important;
    // }
    #wdftable table > tbody > tr > td:nth-child(1) {
        // z-index: 2 !important;
        // position: sticky !important;
        // position: -webkit-sticky !important;
        // left: 0 !important;
        // z-index: 3 !important;
        background: var(--v-keywordcol-base) !important;
    }
    // #wdftable table > thead > tr > th:nth-child(1) {
    //     position: sticky !important;
    //     position: -webkit-sticky !important;
    //     left: 0 !important;
    //     z-index: 3 !important;
    //     background: var(--v-keywordcol-base) !important;
    // }
    // #wdftable > div.v-data-table__wrapper > table > thead > tr > th:nth-child(1) {
    //     position: sticky !important;
    //     left: 0 !important;
    //     z-index: 4 !important;
    // }
    // #wdftable table > tbody > tr > td:nth-child(1),
    // #wdftable table > tbody > tr > th:nth-child(1) {
    //     position: sticky !important;
    //     position: -webkit-sticky !important;
    //     left: 0 !important;
    //     background: var(--v-keywordcol-base) !important;
    // }
    // #wdftable table > tbody > tr > td:nth-child(2),
    // #wdftable table > thead > tr > th:nth-child(2) {
    //     position: sticky !important;
    //     position: -webkit-sticky !important;
    //     left: 350px !important;
    //     // background: var(--v-keywordcol-base) !important;
    //     border-right: 1.5px solid rgb(218, 218, 218);
    // }
    .circularprogress {
        margin-top: 20vh;
    }
    .tabletext {
        font-size: 18px;
    }
    .table_url {
        color: var(--v-primary-base);
    }
    .up_status {
        margin-top: 2px;
        font-size: 12px;
        color: grey;
    }
    .urllink {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    #wdftable .td_keyword {
        font-size: 16px;
    }

    // .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th:nth-child(0) {
    //     position: sticky !important;
    //     position: -webkit-sticky !important;
    //     left: 0 !important;
    //     z-index: 5 !important;
    //     // background: var(--v-keywordcol-base) !important;
    // }
    // .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th:nth-child(0),
    // #wdftable table > tbody > tr > td:nth-child(1),
    // #wdftable table > thead > tr > th:nth-child(1) {
    //     position: sticky !important;
    //     position: -webkit-sticky !important;
    //     left: 0 !important;
    //     background: var(--v-keywordcol-base) !important;
    // }
    // #wdftable table > tbody > tr > td:nth-child(1) {
    //     // z-index: 2 !important;
    // }
    // .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    //     background: #f6f6f6 !important;
    // }
</style>
