<template>
    <div>
        <v-snackbar top :color="info.color" v-model="showInfo">
            <v-icon>{{ info.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <center v-if="mountedOn.url == 'content-projects'">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-switch
                        hide-details
                        v-on="on"
                        v-model="share_data.briefing"
                        @change="
                            createShareLink()
                            share_project_item = 'briefing'
                            changeSharedExternStatus()
                        ">
                        <template v-slot:label>
                            <span>{{ share_data.briefing ? "Briefing" : "Briefing" }}</span>
                        </template></v-switch
                    >
                </template>
                <span>Note: If you deactivate this link your customers are not able to see the collection anymore.</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-switch
                        hide-details
                        v-on="on"
                        v-model="share_data.editor"
                        @change="
                            createShareLink()
                            share_project_item = 'content-editor'
                            changeSharedExternStatus()
                        ">
                        <template v-slot:label>
                            <span>{{ share_data.editor ? "Content Editor" : "Content Editor" }}</span>
                        </template></v-switch
                    >
                </template>
                <span>Note: If you deactivate this link your customers are not able to see the collection anymore.</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-switch
                        hide-details
                        v-on="on"
                        v-model="share_data.competitors"
                        @change="
                            createShareLink()
                            share_project_item = 'content-project/competitors'
                            changeSharedExternStatus()
                        ">
                        <template v-slot:label>
                            <span>{{ share_data.competitors ? "Competitor Analysis" : "Competitor Analysis" }}</span>
                        </template></v-switch
                    >
                </template>
                <span>Note: If you deactivate this link your customers are not able to see the collection anymore.</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-switch
                        hide-details
                        v-on="on"
                        v-model="share_data.wdfidf"
                        @change="
                            createShareLink()
                            share_project_item = 'content-project/wdf-idf'
                            changeSharedExternStatus()
                        ">
                        <template v-slot:label>
                            <span>{{ share_data.wdfidf ? "WDF*IDF Analysis" : "WDF*IDF Analysis" }}</span>
                        </template></v-switch
                    >
                </template>
                <span>Note: If you deactivate this link your customers are not able to see the collection anymore.</span>
            </v-tooltip>
            <v-row class="mt-10"
                ><v-text-field
                    class="pr-5 pl-2"
                    :disabled="!share_data.briefing && !share_data.editor && !share_data.wdfidf && !share_data.competitors"
                    readonly
                    rounded
                    filled
                    dense
                    v-model="share_link"></v-text-field
                ><v-btn
                    color="primary"
                    :disabled="!share_data.briefing && !share_data.editor && !share_data.wdfidf && !share_data.competitors"
                    @click="copyToClipboard(share_link)"
                    class="mr-5"
                    style="margin-top: 3px"
                    rounded
                    >Copy</v-btn
                ></v-row
            >
        </center>
        <center v-else>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-switch hide-details v-on="on" v-model="shareSwitch" @change="changeSharedExternStatus(share_link)">
                        <template v-slot:label>
                            <span>{{ shareSwitch ? "Deactivate Link" : "Activate link" }}</span>
                        </template></v-switch
                    >
                    <v-row
                        ><v-text-field class="pr-5 pl-2" :disabled="!shareSwitch" readonly rounded filled dense v-model="share_link"></v-text-field
                        ><v-btn color="primary" :disabled="!shareSwitch" @click="copyToClipboard(share_link)" class="mr-5" rounded>Copy</v-btn></v-row
                    >
                </template>
                <span>Note: If you deactivate this link your customers are not able to see the collection anymore.</span>
            </v-tooltip>
        </center>
    </div>
</template>

<script>
    // import GuestInviteAre from "../components/guest/InviteArea"

    export default {
        components: {},
        name: "GuestInviteArea",
        props: { listname: String, list_id: String, mountedOn: undefined },
        data: () => ({
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            shareSwitch: false,
            share_link: "",
            info: {
                message: null,
                icon: null,
                color: null
            },
            showInfo: false,
            share_data: { briefing: false, editor: false, wdfidf: false, competitors: false },
            share_project_item: ""
        }),
        watch: {
            listname(val) {
                this.createShareLink()
                this.getSharedExternStatus()
            }
        },
        computed: {},
        methods: {
            createShareLink() {
                let link = ""
                let encrypted = ""
                let baseUrl = ""
                if (process.env.VUE_APP_ENVIRONMENT == "local") {
                    baseUrl = "http://localhost:8080/"
                } else if (process.env.VUE_APP_ENVIRONMENT == "dev") {
                    baseUrl = "https://dev.searchinsights.de/"
                } else {
                    baseUrl = "https://app.quaro.io/"
                }
                if (this.mountedOn.url == "briefing") {
                    link = baseUrl + "briefing/" + this.list_id + "/"
                    encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.list_id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                } else if (this.mountedOn.url == "content-editor") {
                    link = baseUrl + "content-editor/" + this.list_id + "/"
                    encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.list_id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                } else if (this.mountedOn.url == "content-project/wdf-idf") {
                    link = baseUrl + "content-project/wdf-idf/" + this.list_id + "/"
                    encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.list_id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                } else if (this.mountedOn.url == "content-project/competitors") {
                    link = baseUrl + "content-project/competitors/" + this.list_id + "/"
                    encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.list_id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                } else if (this.mountedOn.url == "content-projects") {
                    if (this.share_data.briefing) {
                        link = baseUrl + "briefing/" + this.list_id + "/"
                        encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.list_id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                    } else if (this.share_data.editor) {
                        link = baseUrl + "content-editor/" + this.list_id + "/"
                        encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.list_id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                    } else if (this.share_data.wdfidf) {
                        link = baseUrl + "content-project/wdf-idf/" + this.list_id + "/"
                        encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.list_id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                    } else if (this.share_data.competitors) {
                        link = baseUrl + "content-project/competitors/" + this.list_id + "/"
                        encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.list_id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                    } else {
                        link = baseUrl + ""
                        encrypted = ""
                    }
                } else {
                    link = baseUrl + "research/shared/"
                    encrypted = this.CryptoJS.AES.encrypt(localStorage.company_id + "|" + this.listname, process.env.VUE_APP_ENCRYPTION_PW).toString()
                }

                let replaced = encrypted.replace(new RegExp("/", "g"), "Por21Ld")
                // console.log(link)

                // console.log(replaced)
                this.share_link = link + replaced
            },
            async copyToClipboard(link) {
                try {
                    console.log(link)
                    await navigator.clipboard.writeText(link)
                    this.info = this.$helpers.createNotification("Text copied to clipboard!", "mdi-check", "#16C96D")
                    this.showInfo = true
                } catch (error) {
                    alert("Cannot copy")
                }
            },
            changeSharedExternStatus() {
                console.log(this.list_id)
                if (this.mountedOn.url == "briefing" || this.share_project_item == "briefing") {
                    this.$http
                        .post(
                            process.env.VUE_APP_APIURL + "/cs/briefing/sharedextern/change",
                            { company_id: localStorage.company_id, list_id: this.list_id },
                            { headers: { Authorization: this.auth } }
                        )
                        .then(function (response) {
                            console.log(response)
                        })
                } else if (this.mountedOn.url == "content-editor" || this.share_project_item == "content-editor") {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/cs/editor/sharedextern/change", { company_id: localStorage.company_id, list_id: this.list_id }, { headers: { Authorization: this.auth } })
                        .then(function (response) {
                            console.log(response)
                        })
                } else if (this.mountedOn.url == "content-project/wdf-idf" || this.share_project_item == "content-project/wdf-idf") {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/cs/wdfidf/sharedextern/change", { company_id: localStorage.company_id, list_id: this.list_id }, { headers: { Authorization: this.auth } })
                        .then(function (response) {
                            console.log(response)
                        })
                } else if (this.mountedOn.url == "content-project/competitors" || this.share_project_item == "content-project/competitors") {
                    this.$http
                        .post(
                            process.env.VUE_APP_APIURL + "/cs/competitors/sharedextern/change",
                            { company_id: localStorage.company_id, list_id: this.list_id },
                            { headers: { Authorization: this.auth } }
                        )
                        .then(function (response) {
                            console.log(response)
                        })
                } else {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/research/sharedextern/change", { company_id: localStorage.company_id, list_id: this.list_id }, { headers: { Authorization: this.auth } })
                        .then(function (response) {
                            console.log(response)
                            if (!this.shareSwitch) {
                                this.$emit("lowerSharedCount", this.list_id)
                                this.$emit("changeSharedItem", { id: this.list_id, status: this.shareSwitch })
                            } else {
                                this.$emit("changeSharedItem", this.list_id)
                                this.$emit("changeSharedItem", { id: this.list_id, status: this.shareSwitch })
                            }
                        })
                }
            },
            async getSharedExternStatus() {
                console.log(this.mountedOn)
                if (this.mountedOn.url == "briefing") {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/cs/briefing/sharedextern/get", { company_id: localStorage.company_id, list_id: this.list_id }, { headers: { Authorization: this.auth } })
                        .then(function (response) {
                            this.shareSwitch = response.body.briefing_shared_extern
                        })
                } else if (this.mountedOn.url == "content-editor") {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/cs/editor/sharedextern/get", { company_id: localStorage.company_id, list_id: this.list_id }, { headers: { Authorization: this.auth } })
                        .then(function (response) {
                            this.shareSwitch = response.body.editor_shared_extern
                        })
                } else if (this.mountedOn.url == "content-project/wdf-idf") {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/cs/wdfidf/sharedextern/get", { company_id: localStorage.company_id, list_id: this.list_id }, { headers: { Authorization: this.auth } })
                        .then(function (response) {
                            this.shareSwitch = response.body.wdfidf_shared_extern
                        })
                } else if (this.mountedOn.url == "content-project/competitors") {
                    this.$http
                        .post(
                            process.env.VUE_APP_APIURL + "/cs/competitors/sharedextern/get",
                            { company_id: localStorage.company_id, list_id: this.list_id },
                            { headers: { Authorization: this.auth } }
                        )
                        .then(function (response) {
                            this.shareSwitch = response.body.competitors_shared_extern
                        })
                } else if (this.mountedOn.url == "content-projects") {
                    let answer = await new Promise(async (resolve, reject) => {
                        const url = process.env.VUE_APP_APIURL + "/cs/get/project"
                        let post_data = {
                            company_id: localStorage.company_id,
                            id: this.list_id
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        this.share_data = {
                            briefing: response_data.data.project.briefing_shared_extern,
                            editor: response_data.data.project.editor_shared_extern,
                            wdfidf: response_data.data.project.wdfidf_shared_extern,
                            competitors: response_data.data.project.competitors_shared_extern
                        }
                        this.createShareLink()
                        resolve(response_data.data.project.briefing_shared_extern)
                    })
                } else {
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/research/sharedextern/get", { company_id: localStorage.company_id, list_id: this.list_id }, { headers: { Authorization: this.auth } })
                        .then(function (response) {
                            this.shareSwitch = response.body.shared_extern
                        })
                }
            }
        },
        mounted() {
            this.getSharedExternStatus()
            this.createShareLink()
        },
        created() {}
    }
</script>

<style scoped></style>
