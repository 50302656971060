<template>
    <v-dialog v-model="editDialog.show" max-width="450px" @click:outside="$emit('close')">
        <!-- delete dialog -->
        <v-card id="projectEditDialog">
            <v-card-title>
                <span class="mr-2" style="text-transform: uppercase; font-weight: 100; font-size: 16px">Project Settings</span>
                <v-btn @click="$emit('close')" right top absolute icon color="primary"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>
                <v-chip class="mt-1" small outlined color="primary"> {{ editDialog.item.title }}</v-chip>
            </v-card-subtitle>
            <v-card-text class="mt-2">
                <div class="qgreycard my-0 ml-auto">
                    <span class="lbl"
                        >Title
                        <v-tooltip max-width="250" color="" top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>Select the title of your monitoring project!</span>
                        </v-tooltip>
                    </span>
                    <v-text-field
                        @keyup.enter="saveProject"
                        @input="editDialog.error = null"
                        dense
                        prepend-icon="mdi-label-outline"
                        color="primary"
                        hide-details
                        rounded
                        filled
                        class="my-auto mt-2"
                        v-model="editDialog.item.title"></v-text-field>
                </div>
                <div class="qgreycard my-0 ml-auto mt-5">
                    <span class="lbl"
                        >Domain
                        <v-tooltip max-width="250" color="" top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>Select the focus domain of your monitoring project!</span>
                        </v-tooltip>
                    </span>
                    <v-text-field
                        prepend-icon="mdi-web"
                        @keyup.enter="saveProject"
                        @input="editDialog.error = null"
                        dense
                        color="primary"
                        hide-details
                        rounded
                        filled
                        class="my-auto mt-2"
                        v-model="inputDomain"></v-text-field>
                </div>
                <div class="d-flex">
                    <v-chip class="my-2 mx-auto" v-if="editDialog.error" color="error"><v-icon class="mr-1">mdi-alert</v-icon>{{ editDialog.error }}</v-chip>
                </div>
            </v-card-text>
            <v-card-actions class="px-auto">
                <v-btn class="mr-3" text rounded @click="$emit('close')"> Cancel </v-btn>
                <v-btn rounded class="ml-auto" color="primary" @click="saveProject"> save </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: { editDialog: Object },
        data() {
            return { inputDomain: "" }
        },
        watch: {
            // inputDomain(val) {
            //     const domainRegex = /^((https?|ftp|smtp):\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(:[0-9]+)?(\/\S*)?$/
            //     console.log(val)
            //     if (domainRegex.test(val)) {
            //         // Die eingegebene Domain ist gültig
            //         // Hier können Sie weitere Aktionen ausführen, wenn die Eingabe gültig ist
            //         console.log("Gültige Domain: " + val)
            //         try {
            //             let testUrl = new URL(val)
            //             console.log(testUrl.host)
            //             console.log(testUrl.hostname)
            //             this.editDialog.item.domain = testUrl.host
            //         } catch (error) {
            //             console.log(error)
            //         }
            //     } else {
            //         // Die eingegebene Domain ist ungültig
            //         // Hier können Sie weitere Aktionen ausführen, wenn die Eingabe ungültig ist
            //         console.log("Ungültige Domain")
            //     }
            // }
        },
        methods: {
            validateDomain(val) {
                const domainRegex = /^((https?|ftp|smtp):\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(:[0-9]+)?(\/\S*)?$/
                console.log(val)
                if (domainRegex.test(val)) {
                    // Die eingegebene Domain ist gültig
                    // Hier können Sie weitere Aktionen ausführen, wenn die Eingabe gültig ist
                    console.log("Gültige Domain: " + val)
                    try {
                        let testUrl = new URL(val)
                        console.log(testUrl.host)
                        console.log(testUrl.hostname)
                        return testUrl.host
                    } catch (error) {
                        return null
                    }
                } else {
                    // Die eingegebene Domain ist ungültig
                    // Hier können Sie weitere Aktionen ausführen, wenn die Eingabe ungültig ist
                    console.log("Ungültige Domain")
                    return null
                }
            },
            async saveProject() {
                if (this.editDialog.item.title.length < 3) {
                    this.editDialog.error = "Please provide a proper project title."
                    return
                }
                let domain = this.validateDomain(this.inputDomain)
                if (!domain) {
                    this.editDialog.error = "Please provide a proper project domain."
                    return
                }
                this.editDialog.item.domain = domain

                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/update/information"
                let post_data = {
                    company_id: localStorage.company_id,
                    project_id: this.editDialog.item._id,
                    fieldsToUpdate: { title: this.editDialog.item.title, domain: this.editDialog.item.domain }
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.$emit("saved")
            }
        },
        mounted() {
            console.log(this.editDialog.item.domain)
            this.inputDomain = new URL("https://" + this.editDialog.item.domain)
        }
    }
</script>
<style>
    #projectEditDialog .lbl {
        text-transform: uppercase;
        margin-bottom: 10px;
    }
</style>
