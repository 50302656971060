<template>
    <div id="contentcontainer" class="standard-mode">
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <div v-if="!project.created">
            <div class="monitoringstepper mt-10 mb-7 pl-1">
                <div style="font-size: 24px; text-transform: uppercase">
                    <span>New Monitoring Project</span>
                </div>
                <div style="font-size: 12px">Set basic settings like title or your domain</div>
            </div>
        </div>
        <v-slide-x-transition v-else>
            <div class="mt-10 mb-7 monitoringstepper pl-1">
                <div style="font-size: 12px; text-transform: uppercase">New Monitoring Project</div>
                <div style="font-size: 24px" class="pb-1">
                    <span>{{ project.title }}</span>
                </div>
                <div style="font-size: 12px">
                    <div class="">
                        <v-chip color="primary" class="mr-2" style="text-transform: capitalize">
                            <v-icon small class="mr-1">{{ project.device == "mobile" ? "mdi-cellphone" : "mdi-monitor" }}</v-icon>
                            {{ project.device }}
                        </v-chip>
                        <v-chip color="primary" class="mr-2" style="text-transform: uppercase">
                            <v-icon small class="mr-1">mdi-map-marker</v-icon>
                            {{ project.langloc.language_code }}
                        </v-chip>
                    </div>
                </div>
            </div>
        </v-slide-x-transition>

        <v-stepper rounded v-model="e1" alt-labels non-linear class="rounded_card quaroshadow monitoringstepper mb-10">
            <v-stepper-header elevation-0>
                <v-stepper-step :complete="e1 > 1" step="1">Project Data</v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2"> Add Keywords </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <!---   CreateProject    --->
                <!-- -------------------- -->
                <v-stepper-content step="1">
                    <CreateProject :project="project"></CreateProject>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="
                                nextStep()
                                saveproject_loading = false
                            ">
                            Continue
                        </v-btn>
                    </v-row>
                </v-stepper-content>

                <!-- ---- AddTopics ----- -->
                <!-- ------ Step 2 ------ -->
                <!-- -------------------- -->
                <v-stepper-content step="2">
                    <AddKeywords
                        :project="project"
                        @createNotification="createNotification"
                        @keywordsAdded="keywordsAdded"
                        @createProjectWithKeywords="createProjectWithKeywords"
                        @fileLoaded="fileLoaded"></AddKeywords>
                    <v-row no-gutters class="mt-3">
                        <v-btn text absolute style="bottom: 30px" class="mr-auto" @click="e1--"> Back </v-btn>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
    import AddKeywords from "../../components/monitoring/setup/AddKeywords"
    import CreateProject from "../../components/monitoring/setup/CreateProject"
    export default {
        components: {
            AddKeywords,
            CreateProject
        },
        data() {
            return {
                create: 0,
                e1: 1,
                notification: { show: false },
                project: {
                    title: "",
                    device: "mobile",
                    langloc: { location_text: "Germany", language_code: "de", location_code: 2276 },
                    domain: "",
                    setup: {
                        finished: false
                    },
                    status: {
                        serp_refetch: true,
                        update: true
                    },
                    created: false,
                    categorized_project: false,
                    interval: 1
                },
                keywords: [],
                saveproject_loading: false,
                file_loaded: false
            }
        },
        methods: {
            fileLoaded(file_loaded) {
                this.file_loaded = file_loaded
            },
            keywordsAdded(keywords) {
                console.log("added")
                this.keywords = keywords
            },
            createNotification(message, icon, color) {
                this.notification = this.$helpers.createNotification(message, icon, color)
            },
            async createProjectWithKeywords() {
                try {
                    if (!this.keywords) return
                    this.saveproject_loading = true
                    console.log(this.project)
                    this.project.created = true
                    this.project.created_date = new Date()
                    // await axios post create project
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/add/keywords"
                    let post_data = {
                        company_id: localStorage.company_id,
                        socket_id: this.$socket.id,
                        // user_id: localStorage.id,
                        keywords: this.keywords,
                        project: this.project
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    if (response_data.data.error == "not_enough_credits") {
                        this.notification = this.$helpers.createNotification(
                            "You do not have enough credits left. Requested keyword updates: " +
                                (this.project.interval == 28 ? 1 : this.project.interval == 7 ? 4 : 30) * this.keywords.length +
                                ". Remaining keyword updates: " +
                                (response_data.data.credits - response_data.data.count),
                            "mdi-alert-circle-outline",
                            "#FCBA03"
                        )
                        this.saveproject_loading = false
                        return
                    }
                    this.$router.push("/monitoring")
                } catch (error) {
                    this.saveproject_loading = false
                    console.log(error)
                    this.notification = this.$helpers.createNotification("There was an error saving your keywords. Please try again or contact the support!", "mdi-alert", "orange darken-2")
                }
            },
            async nextStep() {
                let validation = await this.validateProjectData()
                if (!validation) return
                console.log("VALIDATION", validation)
                console.log(this.project)
                this.project.created = true
                this.project.created_date = new Date()
                this.e1++
            },
            // async createProject() {
            //     try {
            //         if (!this.validateProjectData()) return
            //         console.log(this.project)
            //         this.project.created = true
            //         this.project.created_date = new Date()
            //         // await axios post create project
            //         const url = process.env.VUE_APP_MONITORING_API_URL + "/project/create"
            //         let post_data = {
            //             company_id: localStorage.company_id,
            //             projectobject: this.project
            //         }
            //         let response_data = await this.$helpers.axiosPost(url, post_data)
            //         if (response_data.data.created) {
            //             this.project = response_data.data.resp
            //             this.e1++
            //         } else {
            //             this.notification = this.$helpers.createNotification("There is already a project with this name! Pleas select another one.", "mdi-alert", "orange darken-2")
            //         }
            //     } catch (error) {
            //         console.log(error)
            //         this.notification = this.$helpers.createNotification("There was an error creating your project. Please try again or contact the support!", "mdi-alert", "orange darken-2")
            //     }
            // },
            async validateProjectData() {
                return new Promise(async (resolve, reject) => {
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/check/title"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project: this.project
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)

                    if (response_data.data.error == "title_occupied") {
                        this.notification = this.$helpers.createNotification("There is already a project with this title. Please choose another one!", "mdi-alert-circle-outline", "#FCBA03")
                        resolve(false)
                        return
                    }
                    if (this.project.title.length < 3) {
                        this.notification = this.$helpers.createNotification("Please provide a project title! (At least 3 characters)", "mdi-alert", "orange darken-2")
                        resolve(false)
                        return
                    }
                    if (!this.project.domain || this.project.domain.indexOf(".") === -1) {
                        this.notification = this.$helpers.createNotification("Please provide a project domain!", "mdi-alert", "orange darken-2")
                        resolve(false)
                        return
                    }
                    console.log("True")
                    resolve(true)
                })
            }
        }
    }
</script>

<style scoped>
    .monitoringstepper {
        max-width: 800px;
        margin: auto;
    }
    .v-stepper__header {
        box-shadow: 0px 3px 12px var(--v-shadow1-base), 3px 0px 12px var(--v-shadow2-base) !important;
    }
</style>
