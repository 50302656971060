<template>
    <div>
        <v-snackbar top :color="info.color" v-model="showInfo">
            <v-icon>{{ info.icon }}</v-icon> <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <v-dialog v-model="shareMenu" max-width="450px" @click:outside="$emit('disableShareMenu')">
            <v-card>
                <v-card-title>
                    <span class=""> {{ mountedOn.url == "brand-index" ? "Share Brand Dashboard" : "Share " + mountedOn.name }}</span>
                    <v-btn right absolute @click="$emit('disableShareMenu')" class="dialog-close-btn" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle v-if="mountedOn.url !== 'brand-index'">
                    {{ shareItem.listname }}
                </v-card-subtitle>
                <v-card-text>
                    <v-tabs centered v-model="tab" color="primary" class="white--text">
                        <v-tab v-if="mountedOn.url !== 'brand-index'">Guest Link</v-tab>
                        <v-tab>Invite User</v-tab>
                    </v-tabs>
                    <!-- <v-row>
                        <span class="sharetitle mx-auto"> {{ mountedOn.url == "brand-index" ? "Share Brand Dashboard" : "Share " + mountedOn.name }}</span>
                         <span id="title" class="mx-auto">{{ mountedOn.url == "brand-index" ? "Share Brand Dashboard" : "Share " + mountedOn.name + ": " + shareItem.listname }}</span> -
                    </v-row> -->

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-if="mountedOn.url !== 'brand-index'">
                            <v-card-text>
                                <GuestInviteArea
                                    :listname="shareItem.listname"
                                    :list_id="shareItem._id"
                                    @lowerSharedCount="lowerSharedCount"
                                    @upperSharedCount="upperSharedCount"
                                    @changeSharedItem="changeSharedItem"
                                    :mountedOn="mountedOn"></GuestInviteArea
                            ></v-card-text>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card-text class="pl-5 pb-0 mt-2">
                                <v-autocomplete
                                    class="pb-5"
                                    v-model="selectedCompanyUser"
                                    :items="companyUser"
                                    :search-input.sync="companyUserSearch"
                                    hint='search for e.g "user@yourdomain.com" or "Max Mustermann"'
                                    label="select user"
                                    rounded
                                    filled
                                    prepend-icon="mdi-account-multiple"
                                    multiple
                                    persistent-hint
                                    small-chips
                                    item-text="shareBoxCredentials"
                                    return-object>
                                    <template v-slot:prepend-item>
                                        <!-- unselect all -->
                                        <v-list-item ripple v-if="selectedCompanyUser.length > 0" @click="selectedCompanyUser = []">
                                            <v-list-item-action>
                                                <v-icon color="primary"> mdi-close-box</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title> unselect all </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <!-- select all -->
                                        <v-list-item ripple v-if="selectedCompanyUser.length == 0" v-on:click="shareboxSelectAll">
                                            <v-list-item-action>
                                                <v-icon color="primary"> mdi-checkbox-multiple-marked </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title> select all </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-slot:selection="{ item, index }">
                                        <span class="mt-2">
                                            <span v-if="index < 3"
                                                ><v-chip small>{{ item.user }}</v-chip></span
                                            >
                                        </span>

                                        <span v-if="index === 3" class="grey--text caption"> (+{{ selectedCompanyUser.length - 3 }} more) </span>
                                    </template>
                                </v-autocomplete>

                                <v-textarea filled class="pb-4" rounded hide-details label="Comment" rows="2" prepend-icon="mdi-message" v-model="shareComment"></v-textarea>

                                <v-row v-if="$helpers.isActionAllowed(26, user_rights) && user_plan != 3">
                                    <span class="ml-auto mb-2">
                                        <span v-if="!showInviteUser" class="py-auto">Colleque not found? </span>
                                        <v-btn small class="px-1 share-dialog-invite-tbn" color="darkgreen" text @click="showInviteUser = !showInviteUser"
                                            >{{ showInviteUser ? "" : "send Invitation" }}
                                            <v-icon v-if="showInviteUser">mdi-close</v-icon>
                                        </v-btn>
                                    </span>
                                </v-row>

                                <InviteArea :showInviteUser="showInviteUser" @setSelectedInvitedUser="setSelectedInvitedUser"> </InviteArea>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn rounded class="mx-auto" dark color="primary" :disabled="disableShareButton" v-on:click="share()">Share</v-btn>
                            </v-card-actions>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import InviteArea from "./InviteArea"
    import GuestInviteArea from "./guest/GuestInviteArea"

    export default {
        components: {
            InviteArea,
            GuestInviteArea
        },
        name: "ShareDialog",
        props: {
            shareMenu: Boolean,
            shareItem: Object,
            mountedOn: undefined
        },
        data: () => ({
            showInviteUser: false,
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            dataFetched: false,
            companyUser: [],
            selectedCompanyUser: [],
            companyUserSearch: "",
            disableShareButton: false,
            shareboxError: "",
            shareComment: "",
            tab: 0,
            // invitedUser: [],
            // selectedInvitedUser: [],
            // invitedUserSearch: "",
            info: {
                message: null,
                icon: null,
                color: null
            },
            showInfo: false
        }),
        watch: {
            shareMenu(val) {
                if (!this.dataFetched) {
                    if (val == true) {
                        this.fetchUser()
                        this.dataFetched = true
                    }
                }
            }
        },
        computed: {
            user_rights() {
                return this.$store.state.user_rights
            },
            user_plan() {
                return this.$store.state.user_plan
            }
        },
        methods: {
            // createInfo(message, color, icon){
            //     this.info.message = message
            //     this.info.color = color
            //     this.info.icon = icon
            //     this.showInfo = true
            // },
            changeSharedItem(item) {
                this.$emit("changeSharedItem", item)
            },

            share() {
                if (this.selectedCompanyUser.length > 0) {
                    this.disableShareButton = true
                    var toAdresses = this.selectedCompanyUser.map((x) => {
                        return x.user
                    })
                    var data = {
                        listname: this.shareItem.listname,
                        company_id: localStorage.company_id,
                        shareWithCompany: false,
                        username: localStorage.username,
                        comment: this.shareComment,
                        receiverAdresses: toAdresses,
                        mountedOn: this.mountedOn,
                        id: this.shareItem._id
                    }
                    if (this.shareComment.length == 0) {
                        data.comment = "-"
                    }
                    if (this.selectedCompanyUser.length == this.companyUser.length) {
                        data.shareWithCompany = true
                    }
                    console.log(this.selectedCompanyUser)
                    console.log("share")
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/research/share/email", data, {
                            headers: { Authorization: this.auth }
                        })
                        .then(function (response) {
                            this.disableShareButton = false
                            this.$emit("disableShareMenu")
                            this.shareboxError = ""
                            this.selectedCompanyUser = []
                            this.shareComment = ""
                            // this.$emit("createInfo", "The research was shared with selected user!", this.$vuetify.theme.themes.light.primary, "mdi-success")
                            this.createInfo("The " + this.mountedOn.name + " was shared with selected user!", this.$vuetify.theme.themes.light.primary, "mdi-success")

                            console.log(response)
                        })
                } else {
                    this.disableShareButton = false
                }

                if (this.selectedInvitedUser !== undefined && this.selectedInvitedUser.length > 0) {
                    this.disableShareButton = true

                    var data = {
                        listname: this.shareItem.listname,
                        username: localStorage.username,
                        comment: this.shareComment,
                        receiverAdresses: this.selectedInvitedUser,
                        mountedOn: this.mountedOn,
                        id: this.shareItem._id
                    }
                    if (this.shareComment.length == 0) {
                        data.comment = "-"
                    }
                    console.log("invite share")
                    this.$http
                        .post(process.env.VUE_APP_APIURL + "/user/invite", data, {
                            headers: { Authorization: this.auth }
                        })
                        .then(function (response) {
                            this.disableShareButton = false
                            this.$emit("disableShareMenu")
                            this.shareboxError = ""
                            this.selectedInvitedUser = []
                            this.shareComment = ""
                            // this.$emit("createInfo", "An invitation has been sent to selected user!", this.$vuetify.theme.themes.light.primary, "mdi-success")
                            this.createInfo("An invitation has been sent to selected user!", this.$vuetify.theme.themes.light.primary, "mdi-success")
                            console.log(response)
                        })
                } else {
                    this.disableShareButton = false
                }
            },
            createInfo(message, color, icon) {
                console.log("createinfo")
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },
            setSelectedInvitedUser(value) {
                this.selectedInvitedUser = value
            },
            shareboxSelectAll() {
                this.selectedCompanyUser = this.companyUser
            },
            lowerSharedCount(list_id) {
                this.$emit("lowerSharedCount", list_id)
            },
            upperSharedCount(list_id) {
                this.$emit("upperSharedCount", list_id)
            },
            fetchUser() {
                this.$http.post(process.env.VUE_APP_APIURL + "/user/companyuser", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } }).then(function (response) {
                    console.log(response)
                    this.companyUser = response.body.map((x) => {
                        x.shareBoxCredentials = x.username + " - " + x.user
                        return x
                    })
                })
            }
        },
        mounted() {},
        created() {}
    }
</script>

<style scoped>
    .sharetitle {
        font-size: 18px;
        margin-top: 20px;
    }
</style>
