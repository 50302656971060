<template>
    <div>
        <v-dialog v-model="select_terms_dialog">
            <v-card>
                <v-card-title class="titlelbl uppercase">
                    Select Terms
                    <v-btn icon right absolute @click="select_terms_dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle> All terms are generated through an analysis of your top competitors. </v-card-subtitle>
                <v-card-text>
                    <select-terms @updateKeywords="updateKeywords" :statusNotification="{ message: '' }" :loading="false" :project="project" :topKeywords="topKeywords"></select-terms>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card flat>
            <div clasks="backsheet"></div>

            <v-card-title class="briefing_card_label">
                <!-- Content Terms
                <v-tooltip max-width="250" color="" top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Shows the terms or keywords that should be included in the content of the project and how often they should be used based on the targeted word count. </span>
                </v-tooltip> -->
                <v-btn style="z-index: 9" v-if="!shared_extern && $store.state.content_suite_group.id == 1" color="primary" right absolute top small rounded @click="select_terms_dialog = true"
                    ><v-icon class="mr-1" small>mdi-pencil</v-icon> edit terms</v-btn
                >
            </v-card-title>
            <v-data-table id="bktable" fixed-header height="550px" :headers="headers" :items="keywords" class="elevation-0 comp-table" item-key="id">
                <!-- header slot -->
                <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                    <span :key="index">
                        <span v-bind:style="{ width: header.width }">
                            <!-- Adding tooltips to header -->
                            <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{ header.text }}
                                        <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                    </span>
                                </template>
                                <span>
                                    <v-row no-gutters>
                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                    </v-row>
                                    {{ header.tooltip }}
                                </span>
                            </v-tooltip>
                        </span>
                    </span>
                </template>

                <!-- Table Body -->
                <template v-slot:body="{ items }">
                    <tbody v-if="items.length">
                        <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                            <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="table_td">
                                <!-- ------- -->
                                <!-- keyword -->
                                <!-- ------- -->
                                <div v-if="header.value === 'keyword'" class="briefing_td_keyword">
                                    {{ item.variants ? item.variants.reduce((a, b) => (a.length < b.length ? a : b)) : item.keyword }}
                                </div>

                                <!-- -------------- -->
                                <!---- avgsv ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'avgsv'" style="text-align: center">
                                    <v-chip style="text-align: center" color="primary"> {{ $helpers.formatNumber(item[header.value]) }}</v-chip>
                                </div>

                                <!-- -------------- -->
                                <!---- avgKeywordFrequency ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'avgKeywordFrequency'">
                                    <v-chip color="primary">{{ item[header.value].toFixed(0) }}</v-chip>
                                </div>
                                <!-- -------------- -->
                                <!---- actions ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'actions'">
                                    <v-btn v-if="!shared_extern && $store.state.content_suite_group.id == 1" icon @click="$emit('removeKeyword', item)"
                                        ><v-icon class="removekwbtn">mdi-close</v-icon></v-btn
                                    >
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import SelectTerms from "../../content-suite/setup/SelectTerms.vue"

    export default {
        props: {
            keywords: Array,
            project: Object,
            shared_extern: Boolean
        },
        components: {
            SelectTerms
        },
        data() {
            return {
                select_terms_dialog: false,
                topKeywords: [],
                headers: [
                    {
                        text: "Term",
                        align: "left",
                        sortable: true,
                        value: "keyword"
                    },
                    // {
                    //     text: "Vol.",
                    //     value: "avgsv",
                    //     align: "left",
                    //     sortable: true
                    // },
                    {
                        text: "Targeted Frequency",
                        value: "avgKeywordFrequency",
                        sortable: true
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: true
                    }
                ],
                tblOptions: {
                    "footer-props": {
                        "items-per-page-options": [5, 10, 15]
                    }
                },
                perpage: 10
            }
        },
        methods: {
            async updateKeywords(keywords) {
                const url = process.env.VUE_APP_APIURL + "/cs/update/keywords"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    keywords: keywords,
                    project_id: this.project._id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
                this.$emit("fetchKeywords")
            },
            async fetchKeywords(id) {
                const url = process.env.VUE_APP_APIURL + "/cs/get/keywords"
                if (id) {
                    let post_data = {
                        company_id: localStorage.company_id,
                        user_id: localStorage.id,
                        project_id: id ? id : this.project._id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                    this.topKeywords = response_data.data.keywords
                } else {
                    console.log("no id")
                }
            }
        },
        mounted() {
            this.$router.onReady(() => {
                console.log(this.$route.params.project_id)
                if (this.$route.params.project_id) {
                    console.log(this.$route.params.project_id)
                    this.fetchKeywords(this.$route.params.project_id)
                }
            })
        }
    }
</script>

<style scoped>
    th {
        text-transform: uppercase !important;
        font-weight: 100;
    }
    tr:hover .removekwbtn {
        display: flex !important;
    }
    .removekwbtn {
        display: none;
    }
</style>
