<template>
    <div>
        <v-list two-line>
            <template v-for="(change, index) in editor_history">
                <v-list-item class="my-5" :key="change.date">
                    <v-list-item-avatar>
                        <v-avatar size="35" color="primary" class="white--text">
                            {{ $helpers.getInitials(getDecryptedUser(change.user_name)) }}
                        </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <span small :color="change.type == 'delete' ? 'error' : 'success'">
                                {{ change.type == "delete" ? $helpers.formatNumber(change.count) : "+" + $helpers.formatNumber(change.count) }} Characters</span
                            >
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            <v-icon style="padding-bottom: 2px" x-small>mdi-calendar-blank</v-icon> <span>{{ change.date.substring(0, 10) }}</span>
                            <v-icon class="ml-2" style="padding-bottom: 2px" x-small>mdi-clock-time-eight-outline </v-icon> <span>{{ change.date.substring(11, 16) }}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <!-- <v-list-item-action>
                    <v-btn icon>
                        <v-icon color="grey lighten-1">mdi-information</v-icon>
                    </v-btn>
                </v-list-item-action> -->
                </v-list-item>
                <v-divider v-if="index < editor_history.length - 1" :key="index"></v-divider>
            </template>
        </v-list>
        <!-- <div v-for="(entry, index) in editor_history" :key="index">
            <div class="my-1">
                <div style="font-size: 12px">
                    <v-icon style="padding-bottom: 2px" x-small>mdi-calendar-blank</v-icon>
                    <span>{{ entry.date.substring(0, 10) }}</span>
                </div>
                <div></div>
            </div>
            <v-divider></v-divider>
        </div> -->
        <!-- {{ getDecryptedUser(entry.user_name) }} -->
    </div>
</template>

<script>
    export default {
        props: {
            project_id: String,
            new_data: Number
        },
        data() {
            return {
                editor_history: []
            }
        },
        computed: {},
        watch: {
            new_data: {
                async handler(val) {
                    this.getEditorLog()
                },
                deep: true
            }
        },
        methods: {
            getDecryptedUser(name) {
                console.log(name)
                if (name.includes("Extern")) {
                    return "Extern User"
                } else {
                    return this.CryptoJS.AES.decrypt(name, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                }
            },
            async getEditorLog() {
                // check if correct url

                try {
                    const url = process.env.VUE_APP_APIURL + "/cs/get/editor/history"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.project_id
                    }
                    let response = await this.$helpers.axiosPost(url, post_data)
                    this.editor_history = []
                    response.data.editorData.map((e) => {
                        e.changes.map((c) => {
                            let obj = { ...c, user_name: e.user_name }
                            this.editor_history.push(obj)
                        })
                    })
                    this.editor_history.sort(function (a, b) {
                        return new Date(b.date) - new Date(a.date)
                    })
                    const groupedData = {}

                    this.editor_history.forEach((item) => {
                        const date = new Date(item.date)
                        const day = date.toISOString().split("T")[0]

                        if (!groupedData[day]) {
                            groupedData[day] = []
                        }

                        groupedData[day].push(item)
                    })
                    console.log(groupedData)
                    console.log(this.editor_history)
                } catch (error) {
                    console.log(error)
                }
            }
        },
        mounted() {
            this.getEditorLog()
        }
    }
</script>

<style scoped>
    .close-btn {
        top: 5px;
        right: 5px;
    }
</style>
