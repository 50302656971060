<template>
    <div>
        <v-snackbar top :color="notification.color" v-model="notification.show">
            <v-icon>{{ notification.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ notification.message }}</span>
        </v-snackbar>
        <v-card class="quaroshadow rounded_card">
            <div class="backsheet"></div>

            <v-card-title class="briefing_card_label">
                Briefing Description
                <v-tooltip max-width="250" color="" top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>The briefing description serves as a guide for the writer, ensuring that they understand the expectations and requirements for the project.</span>
                </v-tooltip></v-card-title
            >
            <div id="app" class="pb-5 px-5 description-field">
                <vue-editor v-if="edit" v-model="content" :disabled="shared_extern"></vue-editor>
                <div v-else v-html="content"></div>
            </div>
            <v-card-actions>
                <v-btn
                    class="ml-3 mb-3"
                    v-if="!shared_extern && edit && $store.state.content_suite_group.id == 1"
                    @click="
                        saveDescription()
                        edit = false
                    "
                    outlined
                    color="primary"
                    >save</v-btn
                >
                <v-btn class="ml-3 mb-3" v-if="!shared_extern && !edit && $store.state.content_suite_group.id == 1" @click="edit = true" outlined color="primary">edit</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor"

    export default {
        components: {
            VueEditor
        },

        data() {
            return {
                edit: false,
                notification: {
                    message: null,
                    icon: null,
                    color: null
                },
                content: "<p>A short description that explains to the creator what the article is about.</p>"
            }
        },
        props: {
            shared_extern: Boolean,
            user_id: String,
            company_id: String,
            project: Object
        },
        methods: {
            async saveDescription() {
                try {
                    console.log(this.project)
                    const url = process.env.VUE_APP_APIURL + "/cs/briefing/description/change"
                    console.log(this.company_id)
                    let post_data = {
                        company_id: this.company_id,
                        user_id: this.user_id,
                        project_id: this.project._id,
                        description: this.content
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.notification = this.$helpers.createNotification("The description has been saved successfully!", "mdi-check-circle-outline", "primary")
                } catch (error) {
                    this.notification = this.$helpers.createNotification("Sorry, something went wrong! Please try again later!", "mdi-alert-circle-outline", "#fcba03")
                }
            },
            async getDescription() {
                const url = process.env.VUE_APP_APIURL + "/cs/briefing/description/get"
                let post_data = {
                    company_id: this.company_id,
                    user_id: this.user_id,
                    project_id: this.project._id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.content = response_data.data.briefing_description
            }
        },
        mounted() {
            this.getDescription()
        }
    }
</script>

<style>
    .description-field {
        background-color: #f5f5f5;
        padding: 20px;
        margin: 10px;
        border-radius: 10px;
    }
    .theme--dark > .description-field {
        background-color: black !important;
    }
</style>
