<template>
    <div id="contentcontainter" class="standard-mode">
        <!-- Chart -->
        <v-slide-x-transition>
            <v-card class="my-5 rounded_card quaroshadow">
                <v-toolbar id="projects-tool-bar" flat :color="$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
                    <v-toolbar-items>
                        <v-btn text color="grey darken-2" @click="">
                            Monthly Searches <b class="ml-1">{{ project.domain }}</b></v-btn
                        >
                    </v-toolbar-items>
                    <div class="flex-grow-1"></div>

                    <v-btn
                        rounded
                        class="ml-auto"
                        @click="
                            chartReset++
                            selectedKeywords = []
                        "
                        color="primary"
                        filled>
                        <v-icon>mdi-refresh</v-icon> reset
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <line-chart :project="project" :keywords="selectedKeywords" :reset="chartReset"></line-chart>
                </v-card-text>
            </v-card>
        </v-slide-x-transition>
        <!-- Settings -->
        <!-- Data Table -->

        <v-slide-x-transition>
            <v-card class="mx-auto" style="width: 100%">
                <v-toolbar id="projects-tool-bar" flat :color="$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
                    <v-toolbar-items>
                        <v-btn
                            text
                            color="grey darken-2"
                            @click="
                                fetchData({
                                    sortDesc: sortDesc,
                                    sortBy: sortBy,
                                    page: page,
                                    itemsPerPage: itemsPerPage
                                })
                            ">
                            Keywords <b class="ml-1">{{ project.domain }}</b></v-btn
                        >
                    </v-toolbar-items>
                    <div class="flex-grow-1"></div>
                    <v-menu v-model="datepickerMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                class="mr-2"
                                dense
                                hide-details
                                style="max-width: 300px"
                                v-model="selectedDatesText"
                                rounded
                                single-line
                                filled
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker color="primary" :allowed-dates="existingDates" show-current="false" type="month" range v-model="selectedDates"></v-date-picker>
                    </v-menu>
                    <template v-if="$vuetify.breakpoint.smAndUp">
                        <v-text-field
                            class="pr-5"
                            v-model="search"
                            rounded
                            dense
                            style="max-width: 300px"
                            filled
                            prepend-inner-icon="mdi-magnify"
                            label="search..."
                            single-line
                            hide-details
                            clearable
                            clear-icon="mdi-close"
                            id="kwsearch"></v-text-field>
                        <v-btn rounded class="ml-auto" @click="exportData()" color="primary" filled>
                            <v-icon class="mr-2">mdi-cloud-download-outline</v-icon>
                            export
                        </v-btn>
                    </template>
                    <template v-else>
                        <v-btn rounded class="ml-auto" @click="exportData()" color="primary" filled>
                            <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>
                <v-data-table
                    :headers="headers"
                    :items="keywords"
                    :loading="loading"
                    :sort-desc.sync="sortDesc"
                    :sort-by.sync="sortBy"
                    @click:row="onRowClick"
                    :page.sync="page"
                    :items-per-page.sync="itemsPerPage"
                    :server-items-length="count"
                    :multi-sort="false"
                    :footer-props="footerProps"
                    @update:options="fetchData"
                    fixed-header
                    height="70vh"
                    id="internalSearchesTable"
                    class="elevation-0">
                    <!-- header slot -->
                    <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                        <span :key="index">
                            <span v-bind:style="{ width: header.width }">
                                <!-- Adding tooltips to header -->
                                <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" style="white-space: nowrap; text-transform: uppercase; font-weight: 500">
                                            {{ header.text }}
                                            <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                        </span>
                                    </template>
                                    <span>
                                        <v-row no-gutters>
                                            <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                        </v-row>
                                        {{ header.tooltip }}
                                    </span>
                                </v-tooltip>
                            </span>
                        </span>
                    </template>
                    <template v-slot:item.keyword="{ item }">
                        <div class="py-2 flex" style="padding-left: -5px !important">
                            <span class="rtd pl-2">{{ item.keyword }}</span>
                        </div>
                    </template>
                    <template v-slot:item.search_volume="{ item }">
                        <div class="d-flex justify-end" v-bind="(change = getChange(item, 'search_volume'))">
                            <span class="ml-auto my-auto">{{ $helpers.formatNumber(item.search_volume) }}</span>
                            <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined>
                                <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">
                                    {{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}
                                </v-icon>
                                <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                                <span class="my-auto">{{ change.value }}%</span>
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.pageviews_per_search="{ item }">
                        <div class="d-flex justify-end" v-bind="(change = getChange(item, 'pageviews_per_search'))">
                            <span class="ml-auto my-auto">{{ item.pageviews_per_search.toFixed(2) }}</span>
                            <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined>
                                <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">
                                    {{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}
                                </v-icon>
                                <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                                <span class="my-auto">{{ change.value }}%</span>
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.search_exists="{ item }">
                        <div class="d-flex justify-end" v-bind="(change = getChange(item, 'search_exists'))">
                            <span class="ml-auto my-auto">{{ (item.search_exists * 100).toFixed(2) + "%" }}</span>
                            <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined>
                                <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">
                                    {{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}
                                </v-icon>
                                <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                                <span class="my-auto">{{ change.value }}%</span>
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.search_refinements="{ item }">
                        <div class="d-flex justify-end" v-bind="(change = getChange(item, 'search_refinements'))">
                            <span class="ml-auto my-auto">{{ (item.search_refinements * 100).toFixed(2) + "%" }}</span>
                            <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined>
                                <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">
                                    {{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}
                                </v-icon>
                                <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                                <span class="my-auto">{{ change.value }}%</span>
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.time_after_search="{ item }">
                        <div class="d-flex justify-end" v-bind="(change = getChange(item, 'time_after_search'))">
                            <span class="ml-auto my-auto">{{ item.time_after_search.toFixed(2) + "s" }}</span>
                            <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined>
                                <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">
                                    {{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}
                                </v-icon>
                                <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                                <span class="my-auto">{{ change.value }}%</span>
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.search_depth="{ item }">
                        <div class="d-flex justify-end" v-bind="(change = getChange(item, 'search_depth'))">
                            <span class="ml-auto my-auto">{{ item.search_depth.toFixed(2) }}</span>
                            <v-chip class="ml-2 qchip" :color="change.value == 0 ? '' : change.value < 0 ? 'red' : 'green'" outlined>
                                <v-icon v-if="change.value !== '0.00'" class="mr-1" small :color="change.value < 0 ? 'red' : 'green'">
                                    {{ change.value < 0 ? "mdi-arrow-down" : "mdi-arrow-up" }}
                                </v-icon>
                                <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                                <span class="my-auto">{{ change.value }}%</span>
                            </v-chip>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
        </v-slide-x-transition>
    </div>
</template>

<script>
    import LineChart from "../../components/internal-searches/SearchesLineChart"
    export default {
        components: {
            LineChart
        },
        props: {
            project: Object
        },
        data() {
            return {
                datepickerMenu: false,
                keywords: [],
                selectedKeywords: [],
                selectedDates: [],
                headers: [
                    { text: "Keyword", value: "keyword", width: "250px" },
                    { text: "Search Volume", value: "search_volume", align: "end", tooltip: "Shows the number of search queries for each keyword." },
                    { text: "Results Pageviews / Search", value: "pageviews_per_search", align: "end", tooltip: "Indicates how many page views on the website result from the search." },
                    { text: "Search Exists (%)", value: "search_exists", align: "end", tooltip: "Reveals the percentage of search queries resulting in at least one search result." },
                    { text: "Search Refinements (%)", value: "search_refinements", align: "end", tooltip: "Displays the percentage of search queries leading to search refinements." },
                    { text: "Avg. Time After Search", value: "time_after_search", align: "end", tooltip: "Represents the average time users spend on the website after conducting a search." },
                    { text: "Avg. Search Depth", value: "search_depth", align: "end", tooltip: "Denotes the average number of search operations users perform before finding a satisfactory result." }
                ],
                loading: false,
                count: 0,
                loading: false,
                sortDesc: true,
                sortBy: ["search_volume"],
                page: 1,
                itemsPerPage: 25,
                search: "",
                oldKeywords: [],
                chartReset: 0
            }
        },
        watch: {
            search() {
                // Fetch data when the search query changes
                this.page = 1 // Reset the page to 1 when searching
                this.fetchData({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            },
            selectedDates() {
                this.fetchData(this.tableOptions)
            }
        },
        computed: {
            footerProps() {
                return {
                    itemsPerPageOptions: [10, 25, 50, 100],
                    showFirstLastPage: true
                }
            },
            tableOptions() {
                let options = {
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                }
                return options
            },
            selectedDatesText() {
                let d = JSON.parse(JSON.stringify(this.selectedDates))
                return d.join(" ~ ")
            }
        },
        methods: {
            async fetchData(tableOptions) {
                try {
                    console.log(this.project)
                    this.loading = true
                    const url = process.env.VUE_APP_APIURL + "/internal-searches/project/keywords"
                    if (this.selectedDates.length) {
                        let post_data = {
                            company_id: localStorage.company_id,
                            project_id: this.project._id,
                            selectedDates: this.selectedDates,
                            tableOptions: tableOptions,
                            search: this.search
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        console.log(response_data.data.keywords)
                        this.keywords = response_data.data[this.selectedDates[1]]
                        this.oldKeywords = response_data.data[this.selectedDates[0]]
                        this.count = response_data.data.total_count
                    }
                    this.loading = false
                } catch (error) {
                    this.loading = false
                }
            },
            async exportData() {
                try {
                    let post_data = {
                        keywords: this.keywords,
                        headers: this.headers,
                        selectedDates: this.selectedDates
                    }
                    const url = process.env.VUE_APP_APIURL + "/internal-searches/export/keywords/csv"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    window.location = process.env.VUE_APP_APIURL + "/" + response_data.data
                } catch (error) {
                    console.log(error)
                }
            },
            existingDates(val) {
                return this.project.importDates.includes(val)
            },
            onRowClick(row) {
                console.log(row)
                this.selectedKeywords.push(row.keyword)
                this.selectedKeywords = [...new Set(this.selectedKeywords)]
                console.log(this.selectedKeywords)
            },
            getChange(currentValue, key) {
                let previousValue = this.oldKeywords.find((e) => e.keyword == currentValue.keyword)
                if (!previousValue || previousValue[key] === 0) {
                    return { value: "0.00" }
                }
                const changePercentage = ((currentValue[key] - previousValue[key]) / previousValue[key]) * 100
                return { value: changePercentage.toFixed(2) }
            }
        },
        mounted() {
            if (!this.project) {
                this.$router.push("/quickseo/internal-searches")
            } else {
                this.selectedDates = [this.project.importDates[1], this.project.importDates[0]]
                this.fetchData(this.tableOptions)
            }
        }
    }
</script>

<style>
    #internalSearchesTable th {
        white-space: nowrap;
    }
    #projects-tool-bar .v-toolbar__content {
        padding-left: 0px;
    }
    #internalSearchesTable > div.v-data-table__wrapper > table > tbody > tr:hover {
        cursor: pointer;
    }
</style>
