<template>
    <v-dialog v-model="show" max-width="700px" @click:outside="$emit('close')">
        <v-card>
            <v-card-title>
                <span class=""> Create Category</span>
                <v-btn right absolute @click="$emit('close')" class="dialog-close-btn" icon><v-icon color="primary">mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-card-text>
                <v-autocomplete
                    v-if="categories.length"
                    placeholder="Select parent category..."
                    append-icon="mdi-chevron-down"
                    class="mt-2"
                    v-model="newCategory.parent"
                    :items="categories"
                    rounded
                    filled
                    persistent-hint
                    hint="*optional"
                    item-text="category_id"
                    return-object>
                    <template v-slot:selection="{ item, index }">
                        <span class="">
                            <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                                <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                <v-chip small> {{ cat }}</v-chip>
                            </span>
                        </span>
                    </template>
                    <template v-slot:item="data">
                        <span class="">
                            <span v-for="(cat, index) in data.item.category_id.split(';')" :key="cat">
                                <v-icon v-if="index" small class="px-0">mdi-chevron-right</v-icon>
                                <v-chip small> {{ cat }}</v-chip>
                            </span>
                        </span>
                    </template>
                </v-autocomplete>
                <v-text-field placeholder="Category name..." class="mt-2" v-model="newCategory.name" rounded filled></v-text-field>
            </v-card-text>

            <v-card-actions>
                <v-btn rounded :loading="changeCat" class="mx-auto" color="primary" v-on:click="createCategory()">Create </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            show: Boolean,
            newCategory: Object,
            presetParentId: String
        },
        data() {
            return {
                tab: 0,
                selectedCategory: null,
                categories: [],

                fetchingCats: false,
                changeCat: false,
                success: false
            }
        },
        methods: {
            async fetchCategories() {
                try {
                    this.fetchingCats = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/categories"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.categories = response_data.data.categories.sort((a, b) => a.category_id.localeCompare(b.category_id))
                    console.log(this.categories)
                    if (this.presetParentId.length) {
                        this.newCategory.parent = this.categories.find((c) => c.category_id == this.presetParentId)
                    }
                    this.fetchingCats = false
                } catch (error) {
                    console.log(error)
                }
            },

            async createCategory() {
                console.log(this.newCategory)
                if (this.newCategory.name.length) {
                    let newCat = {
                        category_id: this.newCategory.parent ? this.newCategory.parent.category_id + ";" + this.newCategory.name : this.newCategory.name,
                        categories: this.newCategory.parent ? this.newCategory.parent.categories : {},
                        keyword_count: 0,
                        project_id: this.$route.params.id
                    }
                    newCat.level = newCat.category_id.split(";").length - 1
                    newCat.categories["level" + newCat.level] = this.newCategory.name
                    console.log(newCat)

                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/create/category"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id,
                        newCat: newCat
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                    setTimeout(() => {
                        this.success = true
                        setTimeout(() => {
                            this.$emit("catCreated", newCat)
                        }, 300)
                    }, 300)
                }
            }
        },
        watch: {
            show(val) {
                if (val) {
                    this.success = false
                    this.fetchCategories()
                }
            }
        }
    }
</script>

<style></style>
