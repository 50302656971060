<template>
    <span>
        <v-card class="rounded_card quaroshadow" id="line_chart_card" style="height: 100% !important">
            <div class="backsheet"></div>
            <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" fab color="primary" top small right absolute id="chart_menu">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list rounded dense>
                    <v-list-item class="pl-2" @click="chartDownloadDialog.display = true">
                        <v-icon class="mr-2">mdi-cloud-download</v-icon>
                        <v-list-item-title>Download Chart</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="pl-2" @click="clearChart">
                        <v-icon class="mr-2">mdi-refresh</v-icon>
                        <v-list-item-title>Reset Chart</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="pl-2" @click="chartSettingsDialog.display = true"> <v-icon class="mr-2">mdi-cog</v-icon><v-list-item-title>Settings</v-list-item-title> </v-list-item>
                </v-list>
            </v-menu>
            <v-card-title class="sheet-card-title">
                {{ graphCardData.title }}
            </v-card-title>
            <v-card-subtitle>
                {{ graphCardData.subTitle }}
            </v-card-subtitle>
            <v-slide-y-transition>
                <v-card-text v-if="!hidden">
                    <line-chart :dataChart="dataChart" :countGraph="countGraph" :countGraph2="countGraph2" :options="graphOptions"></line-chart>
                </v-card-text>
            </v-slide-y-transition>
        </v-card>

        <!-- ---------------------- -->
        <!-- Chart Settings Dialog: -->
        <!-- ---------------------- -->

        <!-- Chart settings   -->
        <v-dialog v-model="chartSettingsDialog.display" scrollable max-width="350px" id="downloaddialog">
            <v-card>
                <strong id="title" class="d-inline-block">{{ chartSettingsDialog.title }}</strong>
                <v-card-text class="pt-5">
                    <span class="ma-5" v-for="(slider, i) in settingsSlider" v-bind:key="i">
                        <v-slider v-model="slider.value" class="align-center" :max="slider.max" @change="changeSettings()" :min="slider.min" thumb-label hide-details>
                            <template v-slot:append>
                                <div>{{ slider.value }}</div>
                            </template>
                            <template v-slot:prepend>
                                <div class="labeldiv">{{ slider.label }}</div>
                            </template>
                        </v-slider>
                    </span>
                    <v-divider></v-divider>
                    <v-switch class="" @change="changeSettings()" label="Always start at zero" v-model="beginAtZero"></v-switch>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- ---------------------- -->
        <!-- Chart Download Dialog: -->
        <!-- ---------------------- -->

        <v-dialog v-model="chartDownloadDialog.display" scrollable max-width="350px" id="downloaddialog">
            <v-card>
                <strong id="title" class="d-inline-block">{{ chartDownloadDialog.title }}</strong>
                <v-card-text class="pt-5">
                    <center>
                        <v-btn color="primary" large rounded @click="downloadChart(0)">Download as PDF</v-btn>
                    </center>
                    <br />
                    <center>
                        <v-btn color="primary" large rounded @click="downloadChart(1)">Download as PNG</v-btn>
                    </center>
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
    import LineChart from "./LineChart"
    import canvasToImage from "canvas-to-image"
    import { jsPDF } from "jspdf"

    export default {
        components: {
            LineChart
        },
        name: "KeywordLineChart",
        props: {
            headers: Object,
            keywords: Array,
            graphloaded: Boolean,
            loading: Boolean,
            dataChart: Object,
            countGraph: Number,
            countGraph2: Number,
            render: Number,
            showAfterFirstHalf: Boolean,
            settingsLineChart: Number,
            downloadLineChart: Number,
            resetLineChart: Number,
            hidden: Boolean
        },
        data: () => ({
            beginAtZero: false,
            settingsSlider: [
                {
                    value: 14,
                    min: 10,
                    max: 20,
                    label: "Label Size"
                },
                {
                    value: 14,
                    min: 10,
                    max: 20,
                    label: "Axis Label Size"
                },
                {
                    value: 12,
                    min: 10,
                    max: 20,
                    label: "Legend Label Size"
                }
            ],
            chartSettingsDialog: {
                display: false,
                title: "Chart Settings"
            },
            chartDownloadDialog: {
                display: false,
                title: "Chart Download"
            },
            graphCardData: {
                title: "Google Search Volume",
                subTitle: "Analyze Google Search Volume of past months"
            },
            graphOptions: {
                tooltips: {
                    callbacks: {
                        label: function (tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].label + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toLocaleString()
                        }
                    }
                },
                legend: {
                    display: true,
                    labels: {
                        fontSize: 14
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            datasetBtns: []
        }),
        computed: {
            user_settings() {
                return this.$store.state.user_settings
            }
        },
        watch: {
            render() {
                this.initPieChart(this.keywords, this.headers)
            },
            settingsLineChart() {
                this.chartSettingsDialog.display = true
            },
            downloadLineChart() {
                this.chartDownloadDialog.display = true
            },
            resetLineChart() {
                this.clearChart()
            }
        },
        methods: {
            /**
             * calculates datasets for selected columns
             * @param {*} headers
             * @param {*} keywords
             */
            initLineChart(headers, keywords) {
                // ---------------- filters all possible pie columns //TODO needed ???
                let lineHeaders = headers.modifiedHeaders.filter((header) => (header.custom && header.customOptions.type == "Dropdown") || header.value == "cats")
                // ---------------- filters columns which are set to active
                lineHeaders = lineHeaders.filter((header) => {
                    if (header.chart_options && header.chart_options.line_chart) {
                        return header.chart_options.pie_chart.status.active === true
                    }
                    return false
                })
                // ---------------- return if no colums for pie Charts
                if (lineHeaders.length === 0) return

                this.datasetBtns = lineHeaders.map((header, index) => {
                    let datasetBtn = {}
                    datasetBtn.color = ""
                    datasetBtn.id = index
                    datasetBtn.text = header.modifiedText
                    datasetBtn.value = header.value
                    datasetBtn.isActive = index === 0 ? true : false
                    return datasetBtn
                })
                console.log("this.datasetBtns: ", this.datasetBtns)
                console.log("this.dataChart", this.dataChart)
                // per header build multiplse datsets per attribute
                this.createAllDatasets(keywords, lineHeaders)
                // backgroundColor: CanvasGradient
                // borderColor: "rgba(0, 159, 137, 0.8)"
                // borderWidth: 3.5
                // data: Array(14)
                // hidden: false
                // label: "Sum of all keywords"
                // pointBackgroundColor: "white"
                // pointBorderColor: "grey"
                // this.createAllDatasets(keywords, lineHeaders)
                // this.setActiveDataset(this.datasetBtns[0])
            },
            /** creates and sets all datasets
             * @param {Array} keywords  all keywords rows
             * @param {Array} lineHeaders custom headers with type dropdown
             */
            createAllDatasets(keywords, lineHeaders) {
                console.log(keywords)
                let datasets = lineHeaders.map((header, index) => {
                    let dataset = {}
                    if (header.value == "cats") {
                        // dataset = this.getCategoryDataSet(keywords, header, index)
                    } else {
                        let data = header.customOptions.entries.map((optionEntry) => {
                            let keywordsWithOption = keywords.filter((keyword) => keyword.customs[header.value].text == optionEntry.text)
                            keywordsWithOption = keywordsWithOption.filter((keyword) => keyword)
                            console.log(keywordsWithOption)
                            let graphObjects = this.$helpers.buildGraphObjects([], keywordsWithOption, this.dataChart.datasets, this.dataChart.labels)
                            console.log(graphObjects)
                        })
                        dataset = {
                            id: index,
                            borderWidth: 3,
                            // borderColor: this.borderColors(header),
                            // backgroundColor: this.getBackgroundColors(header),
                            data: this.getDataByOptionEntries(keywords, header),
                            hidden: true

                            // labels: this.getDatasetLabels(header)
                        }
                    }
                    return dataset
                })

                console.log(datasets)
                let sortedDatasets = this.sortDatasets(datasets)
                this.chartData.datasets = datasets
            },
            /**
             * helper for creating Datasets | sums up searchvolume for Entries
             * @param {Array}   keywords
             * @param {Array}   header
             * @returns {Array} data
             */
            getDataByOptionEntries(keywords, header) {
                let data = header.customOptions.entries.map((optionEntry) => {
                    let keywordsWithOption = keywords.filter((keyword) => keyword.customs[header.value].text == optionEntry.text)
                    let sum = this.$helpers.sumUpByAttribute(keywordsWithOption, "annualsv")
                    return sum
                })
                return data
            },
            changeSettings() {
                this.setChartOptions()
                // console.log(JSON.stringify(this.user_settings))
                if (JSON.stringify(this.user_settings) !== "{}") {
                    this.user_settings.line_chart_labelsize = this.settingsSlider[0].value
                    this.user_settings.line_chart_axislabelsize = this.settingsSlider[1].value
                    this.user_settings.line_chart_legendlabelsize = this.settingsSlider[2].value
                    this.user_settings.line_chart_alwaysstartatzero = this.beginAtZero
                    this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
                }
            },
            setChartOptions() {
                console.log("setChartOptions")

                this.graphOptions.scales = {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: this.settingsSlider[0].value
                            },
                            display: true,
                            scaleLabel: {
                                fontSize: this.settingsSlider[1].value,
                                display: true,
                                labelString: "Months"
                            }
                        }
                    ],
                    yAxes: [
                        {
                            ticks: {
                                fontSize: this.settingsSlider[0].value,
                                beginAtZero: this.beginAtZero,
                                callback: function (value, index, values) {
                                    return value.toLocaleString()
                                }
                            },
                            display: true,
                            scaleLabel: {
                                fontSize: this.settingsSlider[1].value,
                                display: true,
                                labelString: "Google Search Volume"
                            }
                        }
                    ]
                }
                this.graphOptions.legend = {
                    display: true,
                    labels: {
                        fontSize: this.settingsSlider[2].value
                    }
                }
                this.$emit("rerender")
            },
            clearChart() {
                this.$emit("clearChart")
            },
            downloadChart(format) {
                /***********
                 * 0 -> PDF
                 * 1 -> PNG
                 **********/
                const canvasEl = document.getElementById("line-chart")
                if (format === 0) {
                    const doc = new jsPDF("landscape")
                    const ratio = canvasEl.width / canvasEl.height
                    const width = doc.internal.pageSize.getWidth()
                    const height = width / ratio
                    doc.addImage(canvasEl, "PNG", 0, height / 2, width, height)
                    doc.save("Google Search Volume.pdf")
                } else if (format === 1) {
                    canvasToImage(canvasEl, {
                        name: "Google Search Volume",
                        type: "png",
                        quality: 1
                    })
                }
            }
        },
        mounted() {
            this.settingsSlider[0].value = this.user_settings.line_chart_labelsize == undefined ? 14 : this.user_settings.line_chart_labelsize
            this.settingsSlider[1].value = this.user_settings.line_chart_axislabelsize == undefined ? 14 : this.user_settings.line_chart_axislabelsize
            this.settingsSlider[2].value = this.user_settings.line_chart_legendlabelsize == undefined ? 12 : this.user_settings.line_chart_legendlabelsize
            this.beginAtZero = this.user_settings.line_chart_alwaysstartatzero == undefined ? false : this.user_settings.line_chart_alwaysstartatzero

            this.setChartOptions()
            // if (this.keywords && this.keywords.length > 0 && this.headers.modifiedHeaders.length > 0) {
            //     this.initLineChart(this.headers, this.keywords)
            // }
        }
    }
</script>

<style scoped>
    #title {
        padding-top: 16px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        color: #212529;
        font-size: 1em;
    }
    .rounded_card {
        border-radius: 10px !important;
    }
    .labeldiv {
        width: 110px;
    }
    .switchlabel {
        width: auto;
    }
</style>
<style lang="scss">
    #line_chart_card > div.v-card__text {
        flex-grow: 1;
        min-height: 0;
        height: 35vh;
        max-height: 400px;
        > div {
            position: relative;
            height: 100%;
        }
    }

    .rounded_card {
        border-radius: 10px !important;
    }

    // @media screen and (max-height: 1173px) {
    //     #linechart_col {
    //         max-height: 40vh;
    //     }
    // }
    // @media screen and (min-height: 1173px) {
    //     #linechart_col {
    //         height: 36vh;
    //     }
    //     #line_chart_card > div.v-card__text {
    //         flex-grow: 1;
    //         min-height: 0;
    //         // height: 85%;
    //         > div {
    //             position: relative;
    //             height: 95%;
    //         }
    //     }
    // }
</style>
